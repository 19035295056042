export default [
  { labelEn: 'Afrikaans', labelRu: 'Африканский', key: 'af' },
  { labelEn: 'Amharic', labelRu: 'Амхарский', key: 'am' },
  { labelEn: 'Arabic', labelRu: 'Арабский', key: 'ar' },
  { labelEn: 'Armenian', labelRu: 'Армянский', key: 'hyAM' },
  { labelEn: 'Azerbaijani', labelRu: 'Азербайджанский', key: 'azAZ' },
  { labelEn: 'Basque', labelRu: 'Баскский', key: 'euES' },
  { labelEn: 'Belarusian', labelRu: 'Белорусский', key: 'be' },
  { labelEn: 'Bengali', labelRu: 'Бенгальский', key: 'bnBD' },
  { labelEn: 'Bulgarian', labelRu: 'Болгарский', key: 'bg' },
  { labelEn: 'Burmese', labelRu: 'Бирманский', key: 'myMM' },
  { labelEn: 'Catalan', labelRu: 'Каталанский', key: 'ca' },
  { labelEn: 'Chinese (Hong Kong)', labelRu: 'Китайский (Гонконг)', key: 'zhHK' },
  { labelEn: 'Chinese (Simplified)', labelRu: 'Китайский (упрощенный)', key: 'zhCN' },
  { labelEn: 'Chinese (Traditional)', labelRu: 'Китайский (традиционный)', key: 'zhTW' },
  { labelEn: 'Croatian', labelRu: 'Хорватский', key: 'hr' },
  { labelEn: 'Czech', labelRu: 'Чешский', key: 'csCZ' },
  { labelEn: 'Danish', labelRu: 'Датский', key: 'daDK' },
  { labelEn: 'Dutch', labelRu: 'Нидерландский', key: 'nlNL' },
  { labelEn: 'English', labelRu: 'Английский', key: 'enAU' },
  { labelEn: 'English', labelRu: 'Английский', key: 'enCA' },
  { labelEn: 'English', labelRu: 'Английский', key: 'enIN' },
  { labelEn: 'English', labelRu: 'Английский', key: 'enSG' },
  { labelEn: 'English (United Kingdom)', labelRu: 'Английский (Великобритания)', key: 'enGB' },
  { labelEn: 'English (United States)', labelRu: 'Английский (США)', key: 'enUS' },
  { labelEn: 'Estonian', labelRu: 'Эстонский', key: 'et' },
  { labelEn: 'Filipino', labelRu: 'Филиппинский', key: 'fil' },
  { labelEn: 'Finnish', labelRu: 'Финский', key: 'fiFI' },
  { labelEn: 'French', labelRu: 'Французский', key: 'frFR' },
  { labelEn: 'French (Canada)', labelRu: 'Французский (Канада)', key: 'frCA' },
  { labelEn: 'Galician', labelRu: 'Галисийский', key: 'glES' },
  { labelEn: 'Georgian', labelRu: 'Грузинский', key: 'kaGE' },
  { labelEn: 'German', labelRu: 'Немецкий', key: 'deDE' },
  { labelEn: 'Greek', labelRu: 'Греческий', key: 'elGR' },
  { labelEn: 'Hebrew', labelRu: 'Иврит', key: 'iwIL' },
  { labelEn: 'Hindi', labelRu: 'Хинди', key: 'hiIN' },
  { labelEn: 'Hungarian', labelRu: 'Венгерский', key: 'huHU' },
  { labelEn: 'Icelandic', labelRu: 'Исландский', key: 'isIS' },
  { labelEn: 'Indonesian', labelRu: 'Индонезийский', key: 'id' },
  { labelEn: 'Italian', labelRu: 'Итальянский', key: 'itIT' },
  { labelEn: 'Japanese', labelRu: 'Японский', key: 'jaJP' },
  { labelEn: 'Kannada', labelRu: 'Каннада', key: 'knIN' },
  { labelEn: 'Khmer', labelRu: 'Кхмерский', key: 'kmKH' },
  { labelEn: 'Korean (South Korea)', labelRu: 'Корейский (Южная Корея)', key: 'koKR' },
  { labelEn: 'Kyrgyz', labelRu: 'Киргизский', key: 'kyKG' },
  { labelEn: 'Lao', labelRu: 'Лаосский', key: 'loLA' },
  { labelEn: 'Latvian', labelRu: 'Латышский', key: 'lv' },
  { labelEn: 'Lithuanian', labelRu: 'Литовский', key: 'lt' },
  { labelEn: 'Macedonian', labelRu: 'Македонский', key: 'mkMK' },
  { labelEn: 'Malay', labelRu: 'Малайский', key: 'ms' },
  { labelEn: 'Malayalam', labelRu: 'Малаялам', key: 'mlIN' },
  { labelEn: 'Marathi', labelRu: 'Маратхи', key: 'mrIN' },
  { labelEn: 'Mongolian', labelRu: 'Монгольский', key: 'mnMN' },
  { labelEn: 'Nepali', labelRu: 'Непальский', key: 'neNP' },
  { labelEn: 'Norwegian', labelRu: 'Норвежский', key: 'noNO' },
  { labelEn: 'Persian', labelRu: 'Персидский', key: 'fa' },
  { labelEn: 'Polish', labelRu: 'Польский', key: 'plPL' },
  { labelEn: 'Portuguese (Brazil)', labelRu: 'Португальский (Бразилия)', key: 'ptBR' },
  { labelEn: 'Portuguese (Portugal)', labelRu: 'Португальский (Португалия)', key: 'ptPT' },
  { labelEn: 'Romanian', labelRu: 'Румынский', key: 'ro' },
  { labelEn: 'Romansh', labelRu: 'Романшский', key: 'rm' },
  { labelEn: 'Russian', labelRu: 'Русский', key: 'ruRU' },
  { labelEn: 'Serbian', labelRu: 'Сербский', key: 'sr' },
  { labelEn: 'Sinhala', labelRu: 'Сингальский', key: 'siLK' },
  { labelEn: 'Slovak', labelRu: 'Словацкий', key: 'sk' },
  { labelEn: 'Slovenian', labelRu: 'Словенский', key: 'sl' },
  { labelEn: 'Spanish (Latin America)', labelRu: 'Испанский (Латинская Америка)', key: 'es419' },
  { labelEn: 'Spanish (Spain)', labelRu: 'Испанский (Испания)', key: 'esES' },
  { labelEn: 'Spanish (United States)', labelRu: 'Испанский (США)', key: 'esUS' },
  { labelEn: 'Swahili', labelRu: 'Суахили', key: 'sw' },
  { labelEn: 'Swedish', labelRu: 'Шведский', key: 'svSE' },
  { labelEn: 'Tamil', labelRu: 'Тамильский', key: 'taIN' },
  { labelEn: 'Telugu', labelRu: 'Телугу', key: 'teIN' },
  { labelEn: 'Thai', labelRu: 'Тайский', key: 'th' },
  { labelEn: 'Turkish', labelRu: 'Турецкий', key: 'trTR' },
  { labelEn: 'Ukrainian', labelRu: 'Украинский', key: 'uk' },
  { labelEn: 'Vietnamese', labelRu: 'Вьетнамский', key: 'vi' },
  { labelEn: 'Zulu ', labelRu: 'Зулу', key: 'zu' },
]

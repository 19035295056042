import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { CurrenciesAppModelType, CurrenciesApiModelType } from './CurrenciesTypes'

class CurrenciesService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.currencies')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: CurrenciesAppModelType = {
    code: '',
    symbol: '',
    name: '',
    rate: 0,
    created: null,
    updated: null,
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.CURRENCIES)
  }

  public getEmptyItem(): CurrenciesAppModelType {
    const data = cloneDeep(this.AppModelPropsDefault)
    return data
  }

  public transformDataForApi(incomingData): CurrenciesApiModelType {
    return incomingData
  }

  public transformDataForApp(incomingData): CurrenciesAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }

  public getRubToUsdt(amount = 0) {
    return this.api.get(`${this.ENDPOINTS.ITEMS}/rub-to-usdt/${amount}`)
  }
}
export default new CurrenciesService()

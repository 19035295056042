
























































































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { SubscriptionAppModelType } from '@/services/api/Subscription/SubscriptionTypes'
import moment from 'moment'

@Component({})
export default class ManagmentPopUpDetails extends Vue {
  // vuex module getter
  @VuexGetter('nBusinessManagment') getPopUpDetailsStatus!: boolean

  @VuexGetter('nBusinessManagment') getSubscriptionListItem!: SubscriptionAppModelType

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  // Data
  money: any

  // Method
  setDateFormatForPopUpDetails(date: string, format: string): string {
    return moment(date).locale(this.$i18n.locale).format(format)
  }

  // Emits
  @Emit('closePopUpDetails')
  closePopUpDetails(): boolean {
    return false
  }

  @Emit('openPopUpUnsubscribe')
  openPopUpUnsubscribe(): boolean {
    return true
  }
}







































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { VuexAction, VuexMutation, VuexGetter } from '@/store/decorators'
import AppPreview from '@/components/appPreview/AppPreview.vue'
import AppSettings from '@/components/AppSettings.vue'
import AppGptSettings from '@/components/AppGptSettings.vue'
import BusinessPageFeature from '@/components/BusinessPageFeature.vue'
import VerificationBar from '@/views/business/verification/components/VerificationBar.vue'
import NotificationProvider from '@/providers/NotificationProvider'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { PublicationModelAppType } from '@/services/api/Publication/PublicationTypes'
import AppsService from '@/services/api/Apps/AppsService'
import { ClientsAppModelType } from '@/services/api/Clients/ClientsTypes'

@Component({
  components: {
    AppPreview,
    AppSettings,
    AppGptSettings,
    BusinessPageFeature,
    VerificationBar,
  },
})
export default class BusinessHome extends Vue {
  // Data
  isShowSubId = false

  subId = ''

  styleForPublication = {
    1: {
      status: 'text-accent-green',
      btn: 'var(--accent-green)',
    },
    2: {
      status: 'text-primary',
      btn: 'primary',
    },
    3: {
      status: 'text-error',
      btn: 'var(--error)',
    },
    4: {
      status: 'text-primary',
      btn: 'primary',
    },
    5: {
      status: 'text-error',
      btn: 'var(--error)',
    },
  }

  // Vuex module getters
  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nApp') getlocale!: string

  @VuexGetter('nApp') getIsGptApp!: boolean

  @VuexGetter('nApp') getAppPreviews!: PostsAppModelType[]

  @VuexGetter('nBusinessPublication') getPublicationListItems!: PublicationModelAppType[]

  @VuexGetter('nApp') getAuthClient!: ClientsAppModelType

  // Vuex module action
  @VuexAction('nApp') fetchBalance!: () => Promise<boolean>

  @VuexAction('nApp') fetchAppPreviews!: () => Promise<boolean>

  @VuexAction('nBusinessPublication') publicationGetItems!: () => Promise<boolean>

  @VuexAction('nBusinessVerification') clientVerificationGetItems!: () => Promise<boolean>

  //  Vuex module mutation
  @VuexMutation('nApp') changeCurrentApp!: (...args) => void

  @VuexMutation('nBusinessPublication') publicationListParamsUpdate!: (...args) => void

  // Computed
  get currentAppLink(): string {
    return `https://${this.getCurrentApp.name.toLowerCase()}.${
      process.env.VUE_APP_INSTA_APP_DOMAIN
    }/${this.getlocale}/?ref=${this.getAuthClient.id}${this.subId ? `&subid=${this.subId}` : ''}`
  }

  get publication(): PublicationModelAppType | null {
    return this.getPublicationListItems[0] ?? null
  }

  async goToApp(): Promise<void> {
    const windowReference: any = window.open()
    const response = await AppsService.toAuthUrl(this.getCurrentApp.id)
    windowReference.location = `${response.url}&pass-install=true`
  }

  // Methods
  copyCurrentAppLink(): string {
    NotificationProvider.add({
      type: 'success',
      message: this.$t('copyToBuffer'),
    })
    return this.currentAppLink
  }

  fetchPublication(): void {
    this.publicationListParamsUpdate({ app: this.getCurrentApp.id })
    this.publicationGetItems()
  }

  // Watchers
  @Watch('getCurrentApp')
  getCurrentAppHandler(): void {
    if (!this.getIsGptApp) {
      this.fetchAppPreviews()
      this.fetchPublication()
      this.fetchBalance()
    }
  }

  // Hooks
  beforeCreate(): void {
    this.$root.$emit('sectionChange', 'business')
  }

  created(): void {
    this.fetchAppPreviews()
    this.clientVerificationGetItems()
    this.fetchPublication()
  }
}

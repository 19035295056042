import { cloneDeep } from 'lodash'
import PublicationService from '@/services/api/Publication/PublicationService'
import { PublicationModelAppType } from '@/services/api/Publication/PublicationTypes'
import { nPublishMutations, nPublishActions, nPublishGetters, nPublishModule } from './types'

const defaultState = {
  lists: {
    publication: {
      items: [],
      params: PublicationService.getDefaultParams(),
    },
  },
}
const actions: nPublishActions = {
  async publicationGetItems({ commit, state }): Promise<boolean> {
    const response = await PublicationService.getItems({
      params: state.lists.publication.params,
    })
    if (response.status) {
      commit('publicationListParamsUpdate', response.params)
      commit('publicationListItemsUpdate', response.data)
    }
    return response.status
  },
  async publicationUpdateItem({ commit }, { data, id }): Promise<boolean> {
    const response = await PublicationService.updateItem({ data, id })
    return response.status
  },
  async publicationCreateItem({ commit }, data): Promise<boolean> {
    const response = await PublicationService.createItem({ data, params: {} })
    return response.status
  },
}

const mutations: nPublishMutations = {
  publicationListParamsUpdate(state, params): void {
    state.lists.publication.params = { ...state.lists.publication.params, ...params }
  },
  publicationListItemsUpdate(state, items: PublicationModelAppType): void {
    state.lists.publication.items = items
  },
}

const getters: nPublishGetters = {
  getPublicationListItems(state): [] | [PublicationModelAppType] {
    return state.lists.publication.items
  },
}

const nPublish: nPublishModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nPublish

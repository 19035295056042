import { cloneDeep } from 'lodash'
import ENDPOINTS from '@/services/api/Endpoints'
import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import i18n from '@/plugins/i18n'
import { PublicationModelAppType, PublicationModelApiType } from './PublicationTypes'

class PublicationService extends BaseApiService {
  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PUBLICATION)
  }

  protected modelName = i18n.t('servicesModelsName.publish')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 15,
    page: 1,
  }

  private usedListParams = ['app', 'itemsPerPage', 'page']

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData): PublicationModelApiType {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData): PublicationModelAppType {
    incomingData.json = JSON.parse(incomingData.json)
    const data = cloneDeep(incomingData)
    return data
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    return params
  }
}
export default new PublicationService()

import Vue from 'vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import * as Sentry from '@sentry/vue'
import { createVPhoneInput } from 'v-phone-input'
import 'v-phone-input/dist/v-phone-input.css'
import 'flag-icons/css/flag-icons.min.css'

import SupsterUi from './SupsterUi'

import i18n from './plugins/i18n'

import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import './plugins/clipboard'
import './plugins/mask'
import './plugins/v-money'
import './plugins/moment'
import './plugins/svgicon'
import './plugins/money.filter'
import './plugins/vue-resize'

import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import '@yzfe/svgicon/lib/svgicon.css'
import './styles/main.scss'

import './registerProviders'

const vPhoneInput = createVPhoneInput({
  invalidMessage: ({ label, example }) => `${label} 232 (example: ${example}).`,
})
Vue.component('VueCustomScrollbar', vueCustomScrollbar)

Vue.use(vPhoneInput)
Vue.config.productionTip = false
if (process.env.VUE_APP_PRODUCTION_MODE === '1') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_API_SENTRY,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['crm', window.location.origin, /^\//],
      }),
      new Sentry.Replay(),
    ],
    tracingOptions: {
      trackComponents: true,
      timeout: 500,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    },
    tracesSampleRate: 1.0,
  })
}

Vue.filter('capitalize', (value) => {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('getCurrencySymbol', (value) => {
  const currencies = {
    USD: '$',
    RUB: '₽',
    EUR: '€',
    UAH: '₴',
  }
  return value.toUpperCase() in currencies ? currencies[value.toUpperCase()] : value
})
Vue.use(SupsterUi)

new Vue({
  name: '',
  router,
  store,
  vuetify,
  i18n,
  data: {
    scrollbarSettings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
    },
  },
  render: (h) => h(App),
}).$mount('#app')

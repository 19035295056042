var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-data-table',{attrs:{"items":_vm.getLogsPostbackListItems,"headers":_vm.tableHeaders,"loading":_vm.getLogsPostbackListLoading,"pages":_vm.getLogsPostbackListParams.pages,"hidePagination":!_vm.getLogsPostbackListItems.length},on:{"input":_vm.changePageTable},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.postback_id)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.request_time,'DD.MM.YYYY'))+" "),_c('span',{staticClass:"text-primary-light"},[_vm._v(_vm._s(_vm.$t('in'))+" "+_vm._s(_vm._f("moment")(item.request_time,'HH:mm')))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.response_status === 200)?_c('div',{staticClass:"d-flex align-center text-accent"},[_c('v-sheet',{staticClass:"mr-2",attrs:{"width":"12","height":"12","rounded":"circle","color":"var(--accent-green)"}}),_vm._v(" "+_vm._s(_vm.$t('postbackLogs.sent'))+" ")],1):_c('div',{staticClass:"d-flex align-center text-error"},[_c('v-sheet',{staticClass:"mr-2",attrs:{"width":"12","height":"12","rounded":"circle","color":"var(--error)"}}),_vm._v(" "+_vm._s(_vm.$t('postbackLogs.error'))+" ")],1)]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.response_status)+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }



























































































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { BillingRequestAppModelType } from '@/services/api/BillingRequest/BillingRequestTypes'
import moment from 'moment'

@Component({})
export default class TransactionsPopUpDetails extends Vue {
  // Data
  money: any

  // vuex module getter
  @VuexGetter('nBusinessTransactions') getTransactionsPopUpDetailsStatus!: boolean

  @VuexGetter('nBusinessTransactions') getTransactionsListItem!: BillingRequestAppModelType

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  // Method
  setDateFormatForPopUpDetails(date: string, format: string): string {
    return moment(date).locale(this.$i18n.locale).format(format)
  }

  findPaymentItem(code: string): number {
    const paymentItem = this.getTransactionsListItem.paymentInfo.find(
      (item) => code === item.pay_type
    )
    if (paymentItem) {
      return paymentItem.sum
    }
    return 0
  }

  // Emits
  @Emit('closePopUpDetails')
  closePopUpDetails(): boolean {
    return false
  }

  @Emit('openPopUpReturn')
  openPopUpReturn(): boolean {
    return true
  }
}

import StatService from '@/services/api/Stat/StatService'
import { cloneDeep } from 'lodash'
import {
  StatsAnalyticsAppModelType,
  StatsCommonAppModelType,
  StatsAppAppModelType,
} from '@/services/api/Stat/StatTypes'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import {
  nBusinessAnalyticsMutations,
  nBusinessAnalyticsActions,
  nBusinessAnalyticsGetters,
  nBusinessAnalyticsModule,
} from './types'

const defaultState = {
  dateRange: [moment().add(-13, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  table: {
    data: null,
    loading: false,
    params: StatService.getDefaultParams(),
  },
  chart: {
    data: null,
    loading: false,
    params: StatService.getDefaultParams(),
  },
  appData: {
    data: null,
    params: StatService.getDefaultParams(),
  },
  commonData: {
    data: null,
    params: StatService.getDefaultParams(),
  },
  filterItems: [
    {
      check: true,
      value: 'total_installs',
      chartLineActive: true,
      color: 'yellow',
    },
    {
      check: true,
      value: 'CR',
      chartLineActive: false,
      isPercent: true,
      color: 'green',
    },
    {
      check: true,
      value: 'total_subscription_activation_count',
      chartLineActive: false,
      color: '#FFE4B5',
    },
    {
      check: true,
      value: 'total_unsubscriptions',
      chartLineActive: false,
      color: 'blue',
    },
    {
      check: true,
      value: 'sales',
      chartLineActive: false,
      color: 'brown',
    },

    {
      check: true,

      value: 'rebill1_success_sum',
      chartLineActive: false,
      color: 'red',
    },

    {
      check: true,
      value: 'rebill2_success_sum',
      chartLineActive: false,
      color: 'purple',
    },
    {
      check: true,
      value: 'rebill3_success_sum',
      chartLineActive: false,
      color: 'lime',
    },
    {
      check: true,
      value: 'rebill4_success_sum',
      chartLineActive: false,
      color: 'pink',
    },
    {
      check: true,
      value: 'rebill5_success_sum',
      chartLineActive: false,
      color: 'orange',
    },
    {
      check: false,
      value: 'rebill6_success_sum',
      chartLineActive: false,
      color: '#2F4F4F',
    },
    {
      check: false,
      value: 'rebill7_success_sum',
      chartLineActive: false,
      color: '#B22222',
    },
    {
      check: false,
      value: 'rebill8_success_sum',
      chartLineActive: false,
      color: '#1E90FF',
    },
    {
      check: false,
      value: 'rebill9_success_sum',
      chartLineActive: false,
      color: '#CD5C5C',
    },
    {
      check: false,
      value: 'rebill10plus_success_sum',
      chartLineActive: false,
      color: '#7CFC00',
    },
    {
      check: true,

      value: 'revenue',
      chartLineActive: false,
      color: '#20B2AA',
    },
  ],
}

const actions: nBusinessAnalyticsActions = {
  async getAnalyticsDataForTable({ commit, state }): Promise<boolean> {
    commit('changeTableLoading', true)
    const response = await StatService.statsAnalyticsGetItems({
      params: state.table.params,
    })
    if (response.status) {
      commit('tableParamsUpdate', response.params)
      commit('tableDataUpdate', response.data)
    }
    commit('changeTableLoading', false)
    return response.status
  },
  async getAnalyticsDataForChart({ commit, state }): Promise<boolean> {
    commit('changeChartLoading', true)
    const response = await StatService.statsAnalyticsGetItems({
      params: state.chart.params,
    })
    if (response.status) {
      commit('chartParamsUpdate', response.params)
      commit('chartDataUpdate', response.data)
    }
    commit('changeChartLoading', false)
    return response.status
  },
  async getAnalyticsCommon({ commit, state }): Promise<boolean> {
    const response = await StatService.statsCommonGetItems({
      params: state.commonData.params,
    })
    if (response.status) {
      commit('commonParamsUpdate', response.params)
      commit('commonDataUpdate', response.data)
    }

    return response.status
  },
  async getAnalyticsApp({ commit, state }): Promise<boolean> {
    const response = await StatService.statsAppGetItems({
      params: state.appData.params,
    })
    if (response.status) {
      commit('appParamsUpdate', response.params)
      commit('appDataUpdate', response.data)
    }

    return response.status
  },
}

const mutations: nBusinessAnalyticsMutations = {
  changeTableLoading(state, status: boolean): void {
    state.table.loading = status
  },
  changeChartLoading(state, status: boolean): void {
    state.chart.loading = status
  },

  tableParamsUpdate(state, params): void {
    state.table.params = { ...state.table.params, ...params }
  },
  chartParamsUpdate(state, params): void {
    state.chart.params = { ...state.chart.params, ...params }
  },
  commonParamsUpdate(state, params): void {
    state.commonData.params = { ...state.commonData.params, ...params }
  },
  appParamsUpdate(state, params): void {
    state.appData.params = { ...state.appData.params, ...params }
  },
  tableDataUpdate(state, data: StatsAnalyticsAppModelType | null): void {
    state.table.data = data
  },
  chartDataUpdate(state, data: StatsAnalyticsAppModelType | null): void {
    state.chart.data = data
  },
  commonDataUpdate(state, data: StatsCommonAppModelType | null): void {
    state.commonData.data = data
  },
  appDataUpdate(state, data: StatsAppAppModelType | null): void {
    state.appData.data = data
  },
  setDateRange(state, date: [string, string]): void {
    state.dateRange = date
  },
}

const getters: nBusinessAnalyticsGetters = {
  getTableLoading(state): boolean {
    return state.table.loading
  },
  getChartLoading(state): boolean {
    return state.chart.loading
  },
  getTableParams(state): any {
    return state.table.params
  },
  getChartParams(state): any {
    return state.chart.params
  },
  getTableData(state): StatsAnalyticsAppModelType | null {
    return state.table.data
  },
  getChartData(state): StatsAnalyticsAppModelType | null {
    return state.chart.data
  },
  getCommonData(state): StatsCommonAppModelType | null {
    return state.commonData.data
  },
  getAppData(state): StatsAppAppModelType | null {
    return state.appData.data
  },
  getFilterItems(state): any {
    return state.filterItems
  },
  getDateRange(state): [string, string] {
    return state.dateRange
  },
}

const nBusinessAnalytics: nBusinessAnalyticsModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nBusinessAnalytics

import Vue from 'vue'

import { StatsMutations } from './types'

const mutations: StatsMutations = {
  setDateRange(state, payload) {
    state.dateRange = payload
  },
  setGroupBy(state, payload) {
    state.groupBy = payload
  },
  setAffiliateStatTable(state, payload) {
    state.affiliateStatTable = payload
  },
  setAnalytics(state, payload) {
    state.analytics = payload
  },
  setCommonStats(state, payload) {
    state.commonStats = payload
  },
  setFilterItems(state, payload) {
    state.filterItems = payload
  },
  setReportsTableStats(state, payload) {
    state.reportsTableStats = payload
  },
  setMetrics(state, payload) {
    state.metrics = payload
  },
  setMenuTab(state, payload) {
    state.menuTab = payload
  },
}

export default mutations
































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import { VuexGetter } from '@/store/decorators'

@Component({})
export default class StreamsPopUpDelete extends Vue {
  loading = false

  @VuexGetter('nAffiliateStreams') getSteamDeletePopUpStatus!: boolean

  @VuexGetter('nAffiliateStreams') getStreamsCurrentItemForTable!: StreamAppModelType

  @Watch('getSteamDeletePopUpStatus')
  getSteamDeletePopUpStatusWatch(): void {
    if (!this.getSteamDeletePopUpStatus) {
      this.loading = false
    }
  }

  // Emit
  @Emit('closeDeletePopUp')
  closeDeletePopUp(): boolean {
    return false
  }

  @Emit('streamDelete')
  streamDelete(): number {
    this.loading = true
    return this.getStreamsCurrentItemForTable.id
  }
}






















import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

@Component
export default class ClientsList extends Vue {
  @VuexGetter('nChats') getClientsListData!: any[]

  @VuexGetter('nChats') getDialog!: any

  @VuexGetter('nChats', 'getClientsNewMessageCounts') clientsNewMessageCounts!: {
    [key: string]: number
  }

  @VuexGetter('nChats') getDrafts!: any

  @VuexGetter('nChats') getDialogControlFormSubmitProccess!: boolean

  @VuexGetter('nChats') getDialogLoading!: any

  @VuexGetter('nChats') getMyClientId!: boolean

  get activeClientId(): number {
    return this.getDialog.client.id
  }

  isHaveDraft(clientId: number): boolean {
    return !this.getDialogControlFormSubmitProccess && clientId in this.getDrafts
  }

  isNewMessages(clientId: number): boolean {
    return (
      !this.getDialogLoading &&
      clientId in this.clientsNewMessageCounts &&
      this.getNewMessageCount(clientId) > 0
    )
  }

  getNewMessageCount(clientId: number): number {
    return this.clientsNewMessageCounts[clientId]
  }
}




















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LocMosaicPreviews from './_LocMosaicPreviews.vue'

@Component({
  components: {
    LocMosaicPreviews,
  },
})
export default class LocMessage extends Vue {
  isOpenGallery = false

  currentSlide = 0

  @Prop() message!: any

  @Prop() messageIndex!: any

  @Prop() clientId!: any

  itsMe(clientId: number): boolean {
    return clientId === this.clientId
  }

  get mediaPhotosAndVideos(): any {
    if (Array.isArray(this.message.media_objects)) {
      return this.message.media_objects.filter(
        (media) =>
          this.detectType(media.content_url) === 'photo' ||
          this.detectType(media.content_url) === 'video'
      )
    }
    return []
  }

  get mediaFiles(): any {
    if (Array.isArray(this.message.media_objects)) {
      return this.message.media_objects.filter(
        (media) => this.detectType(media.content_url) === 'file'
      )
    }
    return []
  }

  get contentPreview() {
    return this.mediaPhotosAndVideos.map((file) => {
      return {
        preview: file.content_url,
        type: this.detectType(file.content_url) === 'photo' ? 'image' : 'video',
      }
    })
  }

  openGallery(index: number) {
    this.currentSlide = index
    this.isOpenGallery = true
  }

  detectType(name): any {
    if (name && name.match(/.(jpg|jpeg|png|gif)$/i)) {
      return 'photo'
    }
    if (name && name.match(/.(mp4|mov|avi|webm)$/i)) {
      return 'video'
    }
    return 'file'
  }
}

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { TariffAppModelType } from './TariffTypes'

class TariffService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.tariffs')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.TARIFF)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private usedListParams = [
    'itemsPerPage',
    'page',
    'app',
    'order[promotionalPages.id]',
    'order[id]',
  ]

  private defaultListParams = {
    itemsPerPage: 30,
    page: 1,
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public sortByPromotionalPage(data: any) {
    return data.sort((a: any, b: any) => {
      let dataSorted
      if (a.promotionalPages && b.promotionalPages) {
        dataSorted = a.promotionalPages[0].id < b.promotionalPages[0].id ? 1 : -1
      }
      return dataSorted
    })

    return data
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData): TariffAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)

    return params
  }
}
export default new TariffService()

export default [
  { id: 1, labelRu: 'Daydream', labelEn: 'Daydream' },
  { id: 2, labelRu: 'Автомобили и транспорт', labelEn: 'Auto & Vehicles' },
  { id: 3, labelRu: 'Бизнес', labelEn: 'Business' },
  { id: 4, labelRu: 'Видеоплееры и редакторы', labelEn: 'Video Players & Editors' },
  { id: 5, labelRu: 'Дополненная реальность', labelEn: 'Augmented reality' },
  { id: 6, labelRu: 'Еда и напитки', labelEn: 'Food & Drink' },
  { id: 7, labelRu: 'Жилье и дом', labelEn: 'House & Home' },
  { id: 8, labelRu: 'Здоровье и фитнес', labelEn: 'Health & Fitness' },
  { id: 9, labelRu: 'Знакомства', labelEn: 'Dating' },
  { id: 10, labelRu: 'Инструменты', labelEn: 'Tools' },
  { id: 11, labelRu: 'Искусство и дизайн', labelEn: 'Art & Design' },
  { id: 12, labelRu: 'Карты и навигация', labelEn: 'Maps & Navigation' },
  { id: 13, labelRu: 'Книги и справочники', labelEn: 'Books & Reference' },
  { id: 14, labelRu: 'Комиксы', labelEn: 'Comics' },
  { id: 15, labelRu: 'Красота', labelEn: 'Beauty' },
  { id: 16, labelRu: 'Персонализация', labelEn: 'Personalization' },
  { id: 17, labelRu: 'Материнство и детство', labelEn: 'Parenting' },
  { id: 18, labelRu: 'Финансы', labelEn: 'Finance' },
  { id: 19, labelRu: 'Стиль жизни', labelEn: 'Lifestyle' },
  { id: 20, labelRu: 'Медицина', labelEn: 'Medical' },
  { id: 21, labelRu: 'Мероприятия', labelEn: 'Events' },
  { id: 22, labelRu: 'Музыка и аудио', labelEn: 'Music & Audio' },
  { id: 23, labelRu: 'Новости и журналы', labelEn: 'News & Magazines' },
  { id: 24, labelRu: 'Образование', labelEn: 'Education' },
  { id: 25, labelRu: 'Погода', labelEn: 'Weather' },
  { id: 26, labelRu: 'Покупки', labelEn: 'Shopping' },
  { id: 27, labelRu: 'Приложения для часов', labelEn: 'Watch apps' },
  { id: 28, labelRu: 'Путешествия', labelEn: 'Travel & Local' },
  { id: 29, labelRu: 'Работа', labelEn: 'Productivity' },
  { id: 30, labelRu: 'Развлечения', labelEn: 'Entertainment' },
  { id: 31, labelRu: 'Разное', labelEn: 'Libraries & Demo' },
  { id: 32, labelRu: 'Связь', labelEn: 'Communication' },
  { id: 33, labelRu: 'Социальные', labelEn: 'Social' },
  { id: 34, labelRu: 'Спорт', labelEn: 'Sports' },
  { id: 35, labelRu: 'Фотография', labelEn: 'Photography' },
  { id: 36, labelRu: 'Циферблаты', labelEn: 'Watch faces' },
]














































import { Component, Vue } from 'vue-property-decorator'
import DocumentsService from '@/services/api/Documents/DocumentsService'
import { DocumentsAppModelType } from '@/services/api/Documents/DocumentsTypes'

@Component({
  components: {},
})
export default class Documents extends Vue {
  isPreloaderShow = false

  top = false

  offsetTop = 0

  documentItem: DocumentsAppModelType | null = null

  async fetchDocument(): Promise<void> {
    this.isPreloaderShow = true
    try {
      const { data, status } = await DocumentsService.getItem({
        id: `slug/${this.$route.params.slug}`,
      })
      if (status) {
        this.documentItem = data
      }
    } catch (error) {}
    this.isPreloaderShow = false
  }

  scrollHander(e): void {
    if (typeof window === 'undefined') {
      return
    }
    const top = window.pageYOffset || e.target.scrollTop || 0
    this.top = top > 20
  }

  toTop(): void {
    this.$vuetify.goTo(0)
  }

  back(): void {
    this.$router.go(-1)
  }

  async created(): Promise<void> {
    await this.fetchDocument()
  }
}

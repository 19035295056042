import { cloneDeep } from 'lodash'
import OfferService from '@/services/api/Offer/OfferService'
import StreamService from '@/services/api/Stream/StreamService'
import StatService from '@/services/api/Stat/StatService'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import {
  StatsAffiliateAppModelType,
  StatsAffiliateTotalAppModelType,
} from '@/services/api/Stat/StatTypes'

import {
  nAffiliateStatisticsMutations,
  nAffiliateStatisticsActions,
  nAffiliateStatisticsGetters,
  nAffiliateStatisticsModule,
} from './types'

const defaultState = {
  offers: {
    items: [],
    params: OfferService.getDefaultParams(),
  },
  streams: {
    items: [],
    params: StreamService.getDefaultParams(),
  },
  statsAffiliate: {
    loading: false,
    data: null,
    params: StatService.getDefaultParams(),
  },
  statsAffiliateTotal: {
    data: null,
  },
}
const actions: nAffiliateStatisticsActions = {
  async offersGetItems({ commit, state }): Promise<boolean> {
    const response = await OfferService.getItems({
      params: state.offers.params,
    })
    if (response.status) {
      commit('offersParamsUpdate', response.params)
      commit('offersItemsUpdate', response.data)
    }
    return response.status
  },
  async streamsGetItems({ commit, state }): Promise<boolean> {
    const response = await StreamService.getItems({
      params: state.streams.params,
    })
    if (response.status) {
      commit('streamsParamsUpdate', response.params)
      commit('streamsItemsUpdate', response.data)
    }
    return response.status
  },
  async statsAffiliateGetItems({ commit, state }): Promise<boolean> {
    commit('statsAffiliateChangeLoadingList', true)
    const response = await StatService.statsAffilateGetItems({
      params: state.statsAffiliate.params,
    })
    if (response.status) {
      commit('statsAffiliateParamsUpdate', response.params)
      commit('statsAffiliateItemsUpdate', response.data)
    }
    commit('statsAffiliateChangeLoadingList', false)
    return response.status
  },
  async statsAffiliateTotalGetItems({ commit }): Promise<boolean> {
    const response = await StatService.statsAffilateTotalGetItems()
    if (response.status) {
      commit('statsAffiliateTotalItemsUpdate', response.data)
    }
    return response.status
  },
}

const mutations: nAffiliateStatisticsMutations = {
  statsAffiliateChangeLoadingList(state, status: boolean): void {
    state.statsAffiliate.loading = status
  },
  offersItemsUpdate(state, items: Array<OfferAppModelType>): void {
    state.offers.items = items
  },
  streamsItemsUpdate(state, items: Array<StreamAppModelType>): void {
    state.streams.items = items
  },
  statsAffiliateItemsUpdate(state, data: StatsAffiliateAppModelType): void {
    state.statsAffiliate.data = data
  },
  statsAffiliateTotalItemsUpdate(state, data: StatsAffiliateTotalAppModelType): void {
    state.statsAffiliateTotal.data = data
  },
  offersParamsUpdate(state, params): void {
    state.offers.params = { ...state.offers.params, ...params }
  },
  streamsParamsUpdate(state, params): void {
    state.streams.params = { ...state.streams.params, ...params }
  },
  statsAffiliateParamsUpdate(state, params): void {
    state.statsAffiliate.params = { ...state.statsAffiliate.params, ...params }
  },
}

const getters: nAffiliateStatisticsGetters = {
  getStatsAffiliateListLoading(state): boolean {
    return state.statsAffiliate.loading
  },
  getOffersItems(state): [] | OfferAppModelType[] {
    return state.offers.items
  },
  getStreamsItems(state): [] | StreamAppModelType[] {
    return state.streams.items
  },
  getStatsAffiliateData(state): StatsAffiliateAppModelType {
    return state.statsAffiliate.data
  },
  getStatsAffiliateTotalData(state): StatsAffiliateTotalAppModelType {
    return state.statsAffiliateTotal.data
  },
  getStatsAffiliateParams(state): unknown {
    return state.statsAffiliate.params
  },
}

const nAffiliateStatistics: nAffiliateStatisticsModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nAffiliateStatistics

import { mapServerSideApp } from '@/utils/api.mapper'
import { getInstanceWithToken } from '@/utils/axios'
import { App, MediaObject, ServerSideApp } from './types'

export const fetchApps = async (): Promise<App[]> => {
  const { data } = await getInstanceWithToken().get<ServerSideApp[]>('api/apps')

  return data.map(mapServerSideApp)
}

export const fetchApp = async (id: number): Promise<App> => {
  const { data } = await getInstanceWithToken().get<ServerSideApp>(`api/apps/${id}`)

  return mapServerSideApp(data)
}

export interface CreateAppRequest {
  name: string
  primaryColor: string
}

export const createApp = async (req: CreateAppRequest): Promise<App> => {
  const { data } = await getInstanceWithToken().post<ServerSideApp>('api/apps', {
    name: req.name,
    primary_color: req.primaryColor,
  })

  return mapServerSideApp(data)
}

export interface UpdateAppRequest {
  id: number
  package?: string
  name?: string
  primaryColor?: string
  description?: string
  image?: MediaObject
  headerImageIds?: number[]
  tariffIds?: number[]
  lang: any
}

export const updateApp = async (req: UpdateAppRequest): Promise<App> => {
  const { data } = await getInstanceWithToken().put<ServerSideApp>(`api/apps/${req.id}`, {
    package: req.package,
    name: req.name,
    primary_color: req.primaryColor,
    image: req.image ? `/api/media_objects/${req.image.id}` : undefined,
    description: req.description,
    header_images: req.headerImageIds?.map((v) => `/api/media_objects/${v}`),
    tariffs: req.tariffIds?.map((v) => `/api/tariffs/${v}`),
    lang: req.lang,
  })

  return mapServerSideApp(data)
}

export default fetchApps






























































































































































































import { Component, Vue, Emit, Ref } from 'vue-property-decorator'
import {
  physicalDataFormType,
  sexTypesType,
  documentTypesType,
} from '@/views/business/verification/types'
import Validation from '@/utils/Validation'
import { TranslateResult } from 'vue-i18n'
import ImageVerifier from '@/views/business/verification/components/ImageVerifier.vue'
import { VuexGetter } from '@/store/decorators'
import { ClientVerificationsAppModelType } from '@/services/api/ClientVerifications/ClientVerificationsTypes'

@Component({
  components: {
    ImageVerifier,
  },
})
export default class PhysicalForm extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Data
  formData: physicalDataFormType = {
    firstName: '',
    lastName: '',
    patronymic: '',
    sex: null,
    country: null,
    documentType: null,
    media_objects: [],
    codeMedia: '',
  }

  confirmChecked = false

  isBtnLoading = false

  rules = {
    lastName: Validation.getRules(['onlyLetters', 'required']),
    firstName: Validation.getRules(['onlyLetters', 'required']),
    patronymic: Validation.getRules(['onlyLetters']),
    sex: Validation.getRules(['required']),
    country: Validation.getRules(['required']),
    documentType: Validation.getRules(['required']),
  }

  step = 1

  // Vuex module getter
  @VuexGetter('nBusinessVerification') getClientVerification!: ClientVerificationsAppModelType

  @VuexGetter('nApp') getCurrentCountry!: any

  // Computed
  get sexTypesList(): sexTypesType[] {
    return [
      {
        text: this.$t('sexMen'),
        value: 1,
      },
      {
        text: this.$t('sexWomen'),
        value: 2,
      },
    ]
  }

  get sellerDocId(): string {
    if (this.getCurrentCountry.country_code === 'RU') {
      return 'russia-dogovor-dlya-prodavcov-ru'
    }
    if (this.getCurrentCountry.country_code === 'IN') {
      return 'india-agreement-for-sellers-en'
    }
    return 'world-agreement-for-suppliers-en'
  }

  get documentTypesList(): documentTypesType[] {
    return [
      {
        text: this.$t('passport'),
        value: 1,
      },
      {
        text: this.$t('idCard'),
        value: 2,
      },
      {
        text: this.$t('driversLicense'),
        value: 3,
      },
    ]
  }

  get imageRequirements(): TranslateResult[] {
    return [
      this.$t('verification.imageIsInColor'),
      this.$t('verification.imageIsClear'),
      this.$t('verification.documentIsClear'),
      this.$t('verification.fileSize'),
      this.$t('verification.imageFormat'),
    ]
  }

  get codeImageRequirements(): TranslateResult[] {
    return [
      this.$t('verification.imageIsInColor'),
      this.$t('verification.imageIsClear'),
      this.$t('verification.photoDigist'),
      this.$t('verification.fileSize'),
      this.$t('verification.imageFormat'),
    ]
  }

  get checkFullFields(): boolean {
    return (
      !this.formData.firstName ||
      !this.formData.lastName ||
      !this.formData.country ||
      !this.formData.sex ||
      !this.formData.documentType
    )
  }

  get checkFullMedias(): boolean {
    return !this.formData.media_objects[0] || !this.formData.media_objects[1]
  }

  get checkFullMediaCode(): boolean {
    return !this.formData.codeMedia
  }

  // Methods
  createVerificaion(): void {
    this.formSubmit('createNew')
    setTimeout(() => {
      this.step++
      this.isBtnLoading = false
    }, 1500)
  }

  updateVerificaion(mode: string): void {
    if (mode === 'old') {
      this.formSubmit('updateOld')
      setTimeout(() => {
        this.step++
        this.isBtnLoading = false
      }, 1500)
    }
    if (mode === 'new') {
      this.step++
      this.formSubmit('updateNew')
    }
  }

  // Emit
  @Emit('formSubmit')
  formSubmit(mode: string): { formData: physicalDataFormType; mode: string } {
    this.isBtnLoading = true
    return { formData: this.formData, mode }
  }
}

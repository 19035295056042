<template>
  <div
    class="supster-ui--checkbox__input"
    @click="change"
    :class="{
      'supster-ui--input__box': boxed,
      _vertical: verticalMode,
      _active: isChecked,
    }"
    :style="style"
  >
    <span
      class="supster-ui--checkbox__field"
      :class="{ _active: isChecked, _vertical: verticalMode }"
    ></span>
    <span
      class="supster-ui--checkbox__label"
      :class="{ _active: isChecked, _vertical: verticalMode }"
      v-if="label && label.length"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'InputCheckbox',
  data: () => ({}),
  props: {
    value: {
      default: '',
    },
    val: {
      default: '',
    },
    label: {
      default: '',
      type: String,
    },
    actions: {
      default: true,
      type: Boolean,
    },
    boxed: {
      default: false,
      type: Boolean,
    },
    width: {
      default: '',
      type: String,
    },
    height: {
      default: '',
      type: String,
    },
    verticalMode: {
      default: false,
      type: Boolean,
    },
    controlChecked: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    isChecked() {
      if (!this.actions) {
        return this.controlChecked
      }
      if (Array.isArray(this.value)) {
        return this.value.includes(this.val)
      }
      return this.value
    },
    style() {
      const style = {}
      if (this.width?.length) {
        style.width = this.width
      }
      if (this.height?.length) {
        style.height = this.height
      }
      return style
    },
  },
  methods: {
    change() {
      if (this.actions) {
        if (Array.isArray(this.value)) {
          const newValue = this.value
          if (this.isChecked) {
            newValue.splice(newValue.indexOf(this.val), 1)
          } else {
            newValue.push(this.val)
          }
          this.$emit('input', newValue)
        } else {
          this.$emit('input', !this.value)
        }
      }
    },
  },
})
</script>
<style lang="scss">
.supster-ui--checkbox__input {
  width: 100%;
}
</style>

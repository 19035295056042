

































































import { Component, Vue, Ref, Prop, Emit, Watch } from 'vue-property-decorator'

@Component({
  name: 'AppDateInput',
})
export default class PayoutSystemFormCard extends Vue {
  // Props
  @Prop(String) readonly label!: string | undefined

  @Prop(String) readonly value!: string | undefined

  @Prop(Array) readonly rules!: []

  // Refs
  @Ref('dayInput') readonly dayInput!: HTMLInputElement

  @Ref('monthInput') readonly monthInput!: HTMLInputElement

  @Ref('yearInput') readonly yearInput!: HTMLInputElement

  // Data
  focus = false

  valid = true

  error = ''

  day = ''

  month = ''

  year = ''

  // Computed
  get checkDayMaxValue(): boolean {
    let result = false
    if (Number(this.day) > 31) {
      this.day = ''
      result = false
    } else {
      result = true
    }
    return result
  }

  get checkMonthMaxValue(): boolean {
    let result = false
    if (Number(this.month) > 12) {
      this.month = ''
      result = false
    } else {
      result = true
    }
    return result
  }

  get dataResultClear(): string {
    return `${this.day}${this.month}${this.year}`
  }

  get dateResult(): string {
    return `${this.day}-${this.month}-${this.year}`
  }

  // Method
  validate(): boolean {
    this.focus = false
    this.valid = false
    this.rules.forEach((item: any): void => {
      this.error = item(this.dataResultClear)
    })
    if (!this.error.length) {
      this.valid = true
    }
    return this.valid
  }

  reset(): void {
    this.day = ''
    this.month = ''
    this.year = ''
  }

  checkYearsMinValue(): void {
    if (Number(this.year) < 1900 && this.year.length === 4) {
      this.year = ''
    }
  }

  // Whatch / Emit
  @Watch('dateResult')
  @Emit()
  input(): string {
    return this.dateResult
  }

  // Hooks
  mounted(): void {
    if (this.value) {
      this.dayInput.value = this.value.slice(0, 2)
      this.monthInput.value = this.value.slice(3, 5)
      this.yearInput.value = this.value.slice(-4)
    }
  }
}

<script>
import VCheckbox from 'vuetify/lib/components/VCheckbox/VCheckbox'

export default {
  name: 'AppVCheckbox',
  extends: VCheckbox,
  props: {
    offIcon: {
      type: String,
      default: '$vuetify.icons.checkboxOff',
    },
    onIcon: {
      type: String,
      default: '$vuetify.icons.checkboxOn',
    },
    dense: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
  },
}
</script>

<style scoped></style>

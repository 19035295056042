<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="auth-label d-flex justify-space-between align-center white--text mb-2 pl-1"
    >
      {{ label }}
      <router-link
        :to="{ name: 'auth.restorePassword' }"
        class="d-flex align-center text-accent text-decoration-none text-hovered"
        v-if="forgotPassLink"
      >
        <span class="icon-important mr-2"> ! </span>
        <span class="text-accent-light">{{ $t('passwordForgot') }}</span>
      </router-link>
    </label>
    <app-v-text-field
      dark
      filled
      background-color="purpleLight"
      :id="id"
      :name="name"
      :type="inputType"
      :rules="rules"
      v-bind:value="value"
      :placeholder="placeholder"
      v-on:input="$emit('input', $event)"
      @keyup="tlc"
      class="auth-input mb-4 rounded-sm"
    >
      <template v-if="type === 'password'" v-slot:append>
        <icon
          v-if="!isShowPassword"
          class="icon-eye"
          @click="isShowPassword = true"
          data="@/assets/icons/eye.svg"
        />
        <icon
          v-else
          class="icon-eye"
          @click="isShowPassword = false"
          data="@/assets/icons/eye-off.svg"
        />
      </template>
    </app-v-text-field>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AuthInput',
  data() {
    return {
      model: '',
      isShowPassword: false,
    }
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    toLowerCase: {
      type: Boolean,
      default: false,
    },
    forgotPassLink: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputType() {
      if (this.type === 'password') {
        return this.isShowPassword ? 'text' : this.type
      }
      return this.type
    },
  },
  methods: {
    tlc() {
      if (this.toLowerCase) {
        this.model = this.model.toLowerCase()
      }
    },
  },
})
</script>

<style lang="scss">
.icon-important {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  font-weight: bold;
  border: 1px solid var(--accent-green);
  border-radius: 100%;
  + span {
    display: block;
    margin-top: 4px;
  }
}

.auth-input {
  input {
    color: var(--white) !important;
  }
  .icon-eye {
    cursor: pointer;
    fill: var(--white);
  }
  &.error--text .v-input__control {
    background-color: var(--v-error-base) !important;
  }
  &.error--text .v-input__slot {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.error--text .v-text-field__details {
    margin: 0;
    padding: 0;
  }
  .v-messages__wrapper {
    padding: 10px 16px 12px 16px;
  }
  .v-messages__message {
    color: var(--white);
    font-weight: 700;
    line-height: 18px;
  }
  input {
    padding: 0 8px 0 8px;
  }
}

.auth-label {
  display: block;
  font-size: 12px;
}
</style>

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import { PromotionalPageAppModelType } from './PromotionalPageTypes'

class PromotionalPageService extends BaseApiService {
  protected modelName = 'Тариф'

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PROMOTIONAL_PAGE)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 30,
    page: 1,
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData): PromotionalPageAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }
}
export default new PromotionalPageService()

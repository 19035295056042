






























































import { Component, Vue, Emit, Ref, Prop, Watch } from 'vue-property-decorator'
import Validation from '@/utils/Validation'
import { VuexGetter } from '@/store/decorators'

@Component({})
export default class PayoutSystemFormTRC extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Props
  @Prop(Boolean) readonly resetForm!: boolean

  @VuexGetter('nApp') getCommission!: number

  // Data
  formData: {
    number: string
    email: string
  } = {
    number: '',
    email: '',
  }

  valid = false

  rules: unknown = {
    number: Validation.getRules(['required', 'TRC20']),
    email: Validation.getRules(['required', 'email']),
  }

  // Emit
  @Emit()
  submit(): boolean | { number: string; email: string } {
    this.form.validate()
    return this.valid ? this.formData : false
  }

  // Watch
  @Watch('resetForm')
  resetFormHandler(): void {
    if (this.resetForm) {
      this.form.reset()
    }
  }
}

<template>
  <div ref="slider">
    <v-row>
      <v-col cols="10">
        <slot></slot>
      </v-col>
      <v-col v-if="imagesArr.length" class="slider-btn-container" cols="2">
        <v-btn
          :disabled="!isSlidableRight"
          @click="translateSlider(singleSlideWidth)"
          color="primary lighten-1"
          class="slider-btn"
        >
          <icon :data="require('@/assets/icons/arrow-left.svg')" />
        </v-btn>
        <v-btn
          :disabled="!isSlidableLeft"
          @click="translateSlider(-singleSlideWidth)"
          color="primary lighten-1"
          class="slider-btn"
        >
          <icon :data="require('@/assets/icons/arrow-right.svg')" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="imagesArr.length" class="slider-image-container ma-0 pt-8">
      <v-img
        v-for="(image, index) in imagesArr"
        :key="`slider-img-${index}`"
        :width="`${width}px`"
        :height="`${height}px`"
        :max-width="`${width}px`"
        :max-height="`${height}px`"
        class="slider-image ma-0 mr-4"
        :src="image.previewImg"
        :style="{
          transform: `translate(${translateVal}px, 0px)`,
        }"
      >
        <v-btn
          @click="deleteImage(index)"
          v-if="deletable"
          color="primary lighten-1"
          class="slider-btn delete-btn"
        >
          <icon :data="require('@/assets/icons/delete-sorting-icon.svg')" />
        </v-btn>
      </v-img>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ImageSlider',
  props: {
    imagesArr: {
      type: Array,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: () => false,
    },
    width: {
      type: Number,
      default: () => 240,
    },
    height: {
      type: Number,
      default: () => 240,
    },
  },
  data: () => ({
    translateVal: 0,
    singleSlideWidth: 256,
    maxSlidableArea: 0,
  }),
  computed: {
    isSlidableLeft() {
      return this.translateVal - this.singleSlideWidth >= -this.maxSlidableArea
    },
    isSlidableRight() {
      return this.translateVal + this.singleSlideWidth <= 0
    },
  },
  watch: {
    imagesArr(val) {
      this.maxSlidableArea = this.$refs.slider
        ? this.imagesArr.length * this.singleSlideWidth - this.$refs.slider.clientWidth
        : -100
    },
  },
  methods: {
    translateSlider(val) {
      this.translateVal += val
    },
    deleteImage(index) {
      this.$emit('deleted', index)
    },
  },
  mounted() {
    this.maxSlidableArea = this.$refs.slider
      ? this.imagesArr.length * this.singleSlideWidth - this.$refs.slider.clientWidth
      : -100
  },
})
</script>

<style scoped lang="scss">
.slider {
  &-btn {
    width: 40px;
    min-width: 0 !important;
    height: 40px;
    margin: 0 8px;
    padding: 0px !important;

    span {
      svg {
        fill: white;
      }
    }

    &-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-image-container {
    flex-wrap: nowrap;
    overflow-x: hidden;
  }

  &-image {
    margin: 8px;
    border-radius: 8px;
    outline: 1px solid #dcd6e7;
    transition: transform 100ms ease-in-out;
  }
}

.delete-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 8px;
}
</style>

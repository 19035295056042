
import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { Line } from 'vue-chartjs'
import moment from 'moment'

@Component({
  extends: Line,
  components: {},
})
export default class CommonLineChart extends Vue {
  renderChart: any

  chartData: any = {
    labels: null,
  }

  options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
      point: {
        borderColor: '#000',
        backgroundColor: '#000',
      },
    },
    layout: {
      padding: {
        right: 12,
        top: 12,
        bottom: 12,
        left: 12,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 5,
          },
          gridLines: {
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  }

  @Prop(String) readonly datasetKey!: any

  @Prop(Array) readonly arrayDatasetKeys!: any

  // Vuex Getters

  @VuexGetter('nBusinessAnalytics') getCommonData!: any

  // Methods
  update(): void {
    this.chartData.labels = this.getCommonData.by_day
      .map((p) => moment(p.date))
      .sort((a, b) => a.valueOf() - b.valueOf())
      .map((p) => p.format('DD.MM.YY'))
    this.chartData.datasets = []

    if (this.arrayDatasetKeys?.length) {
      this.arrayDatasetKeys.forEach((p) => {
        this.chartData.datasets.push({
          data: this.getCommonData.by_day.map((z) => z[p.datasetKey]).reverse(),
          borderColor: p.color,
          pointBackgroundColor: p.color,
          borderWidth: 1,
          fill: false,
          label: p.label,
        })
      })
    } else {
      this.chartData.datasets.push({
        data: this.getCommonData.by_day.map((z) => z[this.datasetKey]).reverse(),
        borderColor: '#27C793',
        borderWidth: 1,
        fill: false,
      })
    }
    this.renderChart(this.chartData, this.options)
  }

  // Watch
  @Watch('getCommonData')
  getCommonDataHandler(): void {
    this.update()
  }

  // Hooks
  mounted(): void {
    if (this.getCommonData) {
      this.update()
    }
  }
}

<template>
  <v-card rounded>
    <v-card-text v-if="title" :class="titleClass">
      <div class="d-flex align-center">
        <icon :data="icon"></icon>
        <h5 class="pl-2">{{ title }}</h5>
      </div>
    </v-card-text>
    <v-divider v-if="title"></v-divider>
    <slot class="card-content"></slot>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Card',
  props: {
    title: {
      type: String,
    },
    titleClass: {
      default: '',
      type: String,
    },
    icon: {
      type: Object,
    },
  },
})
</script>

<style lang="scss" scoped>
.v-card {
  border: 1px solid var(--light-gray);
  box-shadow: 0 8px 20px 0 var(--primary-dark-shadow) !important;

  h5 {
    color: var(--v-primary-base);
    font-weight: bold;
    font-size: 22px;
  }

  svg {
    color: var(--v-primary-base);
  }
}
</style>

import { cloneDeep } from 'lodash'
import PostbackService from '@/services/api/Postback/PostbackService'
import { PostbackLogsAppModelType } from '@/services/api/Postback/PostbackTypes'

import {
  nLogsPostbackMutations,
  nLogsPostbackActions,
  nLogsPostbackGetters,
  nLogsPostbackModule,
} from './types'

const defaultState = {
  lists: {
    logs: {
      loading: false,
      items: [],
      params: PostbackService.getDefaultParams(),
    },
  },
}

const actions: nLogsPostbackActions = {
  async logsPostbackGetItems({ commit, state }): Promise<boolean> {
    commit('logsPostbackChangeLoadingList', true)

    const response = await PostbackService.logsPostbackGetItems({
      params: state.lists.logs.params,
    })
    if (response.status) {
      commit('logsPostbackListParamsUpdate', response.params)
      commit('logsPostbackListItemsUpdate', response.data)
    }
    commit('logsPostbackChangeLoadingList', false)
    return response.status
  },
}

const mutations: nLogsPostbackMutations = {
  logsPostbackChangeLoadingList(state, status: boolean): void {
    state.lists.logs.loading = status
  },
  logsPostbackListParamsUpdate(state, params): void {
    state.lists.logs.params = { ...state.lists.logs.params, ...params }
  },
  logsPostbackListItemsUpdate(state, items: PostbackLogsAppModelType): void {
    state.lists.logs.items = items
  },
}

const getters: nLogsPostbackGetters = {
  getLogsPostbackListLoading(state): boolean {
    return state.lists.logs.loading
  },
  getLogsPostbackListParams(state): unknown {
    return state.lists.logs.params
  },
  getLogsPostbackListItems(state): [] | [PostbackLogsAppModelType] {
    return state.lists.logs.items
  },
}

const nLogsPostback: nLogsPostbackModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nLogsPostback

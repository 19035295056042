import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { MediaObjectsAppModelType } from '@/services/api/MediaObjects/MediaObjectsTypes'

export interface ResponseTypes {
  status: boolean
  data: MediaObjectsAppModelType | Record<string, never>
  params: unknown
  error?: unknown
}

class MediaObjectsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.mediaObject')

  protected ENDPOINTS: EndpointsTypes

  protected api

  constructor() {
    super()
    this.api = HttpApiCrm
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.MEDIA_OBJECTS)
  }

  public async createItem<P, D>({ params, data }: { params: P; data: D }): Promise<ResponseTypes> {
    try {
      const requestUrl = this.addParamsToRequest(this.ENDPOINTS.CREATE, params)
      const response = await this.api.post(requestUrl, this.transformDataForApi(data), {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return {
        status: true,
        data: this.transformDataForApp(response.data),
        params: {},
      }
    } catch (error) {
      return { status: false, data: {}, params: {}, error }
    }
  }

  public transformDataForApi(incomingData) {
    const file = incomingData
    const formData = new FormData()
    formData.append('file', file)
    return formData
  }

  public transformDataForApp(incomingData: MediaObjectsAppModelType) {
    return incomingData
  }

  public transformRequestParams(params: unknown): unknown {
    return params
  }
}
export default new MediaObjectsService()

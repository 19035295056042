





















































































































































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import moment from 'moment'
import TableSortingHeader from '@/components/SupsterUI/TableSortingHeader.vue'
import { cloneDeep } from 'lodash'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: { TableSortingHeader, draggable },
})
export default class StatTable extends Vue {
  searchField = ''

  isSearching = false

  sortParams: any = {
    sortValue: '',
    sortType: false,
  }

  filterData = {
    groupBy: 'day',
    client: '',
    subid1: '',
    subid2: '',
    subid3: '',
    subid4: '',
    subid5: '',
    timeZone: 'Europe/Moscow',
  }

  dialog = false

  columns: any = []

  // Vuex Getters
  @VuexGetter('stats') getReportsTableStats!: any

  @VuexGetter('nBusinessAnalytics') getSortedTableData!: any

  @VuexGetter('nBusinessAnalytics') getFilterItems!: any

  @VuexGetter('nBusinessAnalytics') getTableData!: any

  @VuexGetter('nBusinessAnalytics') getTableLoading!: any

  @VuexGetter('nBusinessAnalytics') getDateRange!: any

  @VuexGetter('nDeskbook') getListCountries!: any

  //  Computed

  get groupByItems(): any {
    return [
      { value: 'day', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.day') },
      { value: 'week', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.week') },
      { value: 'month', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.month') },
      { value: 'client', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.client') },
      { value: 'country', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.country') },
      { value: 'subid1', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.subid1') },
      { value: 'subid2', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.subid2') },
      { value: 'subid3', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.subid3') },
      { value: 'subid4', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.subid4') },
      { value: 'subid5', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.subid5') },
    ]
  }

  get totalRow(): any {
    return this.getTableData?.total ?? []
  }

  get tableData(): any {
    return this.getTableData?.detail ?? []
  }

  // Methods

  firstColumnData(row): any {
    if (row.created) {
      return moment(row.created).format('DD.MM.YYYY')
    }
    if (row.month) {
      return moment(row.month).format('MM.YYYY')
    }
    if (row.numberOfWeek) {
      return `${moment(row.firstDayOfWeek).format('DD.MM.YYYY')} - ${moment(
        row.lastDayOfWeek
      ).format('DD.MM.YYYY')}`
    }
    if (row.client_id) {
      return row.client_id
    }
    if (row.country_id) {
      return this.getListCountries.find((item) => item.id === row.country_id).name
    }
    if (row.subid1) {
      return row.subid1
    }
    if (row.subid2) {
      return row.subid2
    }
    if (row.subid3) {
      return row.subid3
    }
    if (row.subid4) {
      return row.subid4
    }
    if (row.subid5) {
      return row.subid5
    }
    return this.$t('notSpecified')
  }

  // Emit
  @Emit('filter')
  filter() {
    this.sortParams = {
      sortValue: '',
      sortType: null,
    }
    return { ...this.filterData, dateRange: this.getDateRange, sortValue: '', sortType: null }
  }

  @Emit('sort')
  sort() {
    return this.sortParams
  }

  @Watch('sortParams', { deep: true })
  sortParamsHandler(): void {
    if (typeof this.sortParams.sortType === 'boolean') {
      this.sort()
    }
  }

  // Hooks
  created(): void {
    this.columns = cloneDeep(this.getFilterItems)
  }
}

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'

interface ProductBrandsModelAppTypes {
  id?: number
}
interface ProductBrandsModelApiTypes {
  id?: number
}

class ProductBrandsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.productBrands')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ProductBrandsModelAppTypes = {}

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PRODUCT_BRANDS)
  }

  public getEmptyItem(): ProductBrandsModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(data): ProductBrandsModelApiTypes {
    return data
  }

  public transformDataForApp(data): ProductBrandsModelAppTypes {
    return data
  }

  public transformRequestParams(params: unknown): unknown {
    return params
  }
}
export default new ProductBrandsService()

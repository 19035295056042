









































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { BillingRequestAppModelType } from '@/services/api/BillingRequest/BillingRequestTypes'

@Component({})
export default class TransactionsPopUpReturn extends Vue {
  // vuex module getter
  @VuexGetter('nBusinessTransactions') getTransactionsPopUpReturnStatus!: boolean

  @VuexGetter('nBusinessTransactions') getTransactionsListItem!: BillingRequestAppModelType

  // Emits
  @Emit('closePopUpReturn')
  closePopUpReturn(): boolean {
    return false
  }

  @Emit('transactionReturnCancel')
  transactionReturnCancel(): boolean {
    return false
  }

  @Emit('transactionReturn')
  transactionReturn(id: number): number {
    return id
  }
}

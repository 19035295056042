import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=a65b33fe&"
import script from "./Sidebar.vue?vue&type=script&lang=ts&"
export * from "./Sidebar.vue?vue&type=script&lang=ts&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListGroup,VListItem,VListItemTitle})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {ClickOutside,Touch})

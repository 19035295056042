// mixins.ts

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class SectionMixin extends Vue {
  // Props
  @Prop(Number) readonly incomingParrentChapter!: never

  @Prop(Number) readonly incomingChildChapter!: never

  @Prop(String) readonly incomingRef!: never

  // Data
  parrentChapter = null

  childChapter = null

  // Computed
  get target(): any {
    return this.incomingRef ? this.$refs[this.incomingRef] : 0
  }

  // Method
  scrollToChapter(): void {
    setTimeout(() => {
      this.$vuetify.goTo(this.target)
    }, 200)
  }

  clearChildChapter(): void {
    this.childChapter = null
  }

  // Watch
  @Watch('incomingParrentChapter')
  incomingParrentChapterHandler(): void {
    this.parrentChapter = this.incomingParrentChapter
    this.scrollToChapter()
  }

  @Watch('incomingChildChapter')
  childChapterHandler(): void {
    this.childChapter = this.incomingChildChapter
    this.scrollToChapter()
  }

  // Hooks
  mounted(): void {
    this.parrentChapter = this.incomingParrentChapter
    this.childChapter = this.incomingChildChapter
    if (this.incomingParrentChapter || this.incomingChildChapter) {
      this.scrollToChapter()
    }
  }
}

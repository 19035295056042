import SubscriptionService from '@/services/api/Subscription/SubscriptionService'
import TariffService from '@/services/api/Tariff/TariffService'
import { SubscriptionAppModelType } from '@/services/api/Subscription/SubscriptionTypes'
import { TariffAppModelType } from '@/services/api/Tariff/TariffTypes'
import i18n from '@/plugins/i18n'
import { cloneDeep } from 'lodash'
import { TranslateResult } from 'vue-i18n'
import {
  nBusinessManagmentMutations,
  nBusinessManagmentActions,
  nBusinessManagmentGetters,
  nBusinessManagmentModule,
} from './types'

const defaultState = {
  lists: {
    subscription: {
      loading: false,
      items: [],
      item: null,
      params: SubscriptionService.getDefaultParams(),
      isOpenPopUpDetails: false,
      isOpenPopUpUnsubscribe: false,
    },
    tariff: {
      items: [],
      params: TariffService.getDefaultParams(),
    },
  },
}

const actions: nBusinessManagmentActions = {
  async subscriptionGetItems({ commit, state }): Promise<boolean> {
    commit('changeSubscriptionLoadingList', true)
    const response = await SubscriptionService.getItems({
      params: state.lists.subscription.params,
    })
    if (response.status) {
      commit('subscriptionListParamsUpdate', response.params)
      commit('subscriptionListItemsUpdate', response.data)
    }
    commit('changeSubscriptionLoadingList', false)
    return response.status
  },

  async subscriptionGetItem({ commit }, id): Promise<boolean> {
    const response = await SubscriptionService.getItem({
      id,
    })
    if (response.status) {
      commit('subscriptionListItemUpdate', response.data)
    }
    return response.status
  },
  async subscriptionUpdateItem({ commit }, params): Promise<boolean> {
    const response = await SubscriptionService.subscriptionUpdateItemCancel(params)
    return response.status
  },
  async tariffGetItems({ commit, state }): Promise<boolean> {
    const response = await TariffService.getItems({
      params: state.lists.tariff.params,
    })
    if (response.status) {
      commit('tafiffListItemsUpdate', response.data)
    }
    return response.status
  },
}

const mutations: nBusinessManagmentMutations = {
  changeSubscriptionLoadingList(state, status: boolean): void {
    state.lists.subscription.loading = status
  },
  changePopUpDetailsStatus(state, status: boolean): void {
    state.lists.subscription.isOpenPopUpDetails = status
  },
  changePopUpUnsubscribeStatus(state, status: boolean): void {
    state.lists.subscription.isOpenPopUpUnsubscribe = status
  },
  subscriptionListParamsUpdate(state, params): void {
    state.lists.subscription.params = { ...state.lists.subscription.params, ...params }
  },
  tariffListParamsUpdate(state, params): void {
    state.lists.tariff.params = { ...state.lists.tariff.params, ...params }
  },
  subscriptionListItemsUpdate(state, items: Array<SubscriptionAppModelType>): void {
    state.lists.subscription.items = items
  },
  tafiffListItemsUpdate(state, items: Array<TariffAppModelType>): void {
    state.lists.tariff.items = items
  },
  subscriptionListItemUpdate(state, item: SubscriptionAppModelType): void {
    state.lists.subscription.item = item
  },
}

const getters: nBusinessManagmentGetters = {
  getSubscriptionListLoading(state): boolean {
    return state.lists.subscription.loading
  },
  getSubscriptionListParams(state): any {
    return state.lists.subscription.params
  },
  getPopUpDetailsStatus(state): boolean {
    return state.lists.subscription.isOpenPopUpDetails
  },
  getPopUpUnsubscribeStatus(state): boolean {
    return state.lists.subscription.isOpenPopUpUnsubscribe
  },
  getSubscriptionListItems(state): Array<SubscriptionAppModelType> | [] {
    return state.lists.subscription.items
  },
  getTariffListItems(state): Array<TariffAppModelType> | [] {
    return state.lists.tariff.items
  },
  getSubscriptionListItem(state): SubscriptionAppModelType | null {
    return state.lists.subscription.item
  },
  getSubscriptionStatuses(): [{ value: number; name: TranslateResult | string }] | unknown {
    return [
      {
        value: 1,
        name: i18n.t('subscriptionManagment.status.1'),
      },
      {
        value: 2,
        name: i18n.t('subscriptionManagment.status.2'),
      },
      {
        value: 3,
        name: i18n.t('subscriptionManagment.status.3'),
      },
      {
        value: 4,
        name: i18n.t('subscriptionManagment.status.4'),
      },
    ]
  },
}

const nBusinessManagment: nBusinessManagmentModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nBusinessManagment

import { TranslateResult } from 'vue-i18n'

class NotificationProvider {
  static instance: NotificationProvider

  private notifyFunc

  constructor() {
    if (typeof NotificationProvider.instance === 'object') {
      return NotificationProvider.instance
    }
    NotificationProvider.instance = this
    return NotificationProvider.instance
  }

  add({
    type = 'default',
    message = '',
    title = '',
  }: {
    type?: string
    message: string | string[] | TranslateResult
    title?: string
  }): void {
    if (typeof this.notifyFunc === 'function') {
      this.notifyFunc({ type, message, title })
    }
  }

  register(func): void {
    this.notifyFunc = func
  }
}

export default new NotificationProvider()






















import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

@Component
export default class ColClients extends Vue {
  @VuexGetter('nChats') getClientsListLoading!: boolean

  async onScroll(event: { target: HTMLElement }): Promise<void> {
    if (this.getClientsListLoading) {
      return
    }
    const el = event.target
    if ((el.scrollHeight - el.scrollTop) / 2 <= el.offsetHeight) {
      this.$emit('clientsOnScroll')
    }
  }
}

import { cloneDeep } from 'lodash'

import ReferralService from '@/services/api/Referral/ReferralService'

import {
  nBusinessReferralMutations,
  nBusinessReferralActions,
  nBusinessReferralGetters,
  nBusinessReferralModule,
} from './types'

const defaultState = {
  stats: {
    referrals_count: 0,
    active_referrals_count: 0,
    total_referral_bonus: 0,
  },
  pageStats: {},
}

const actions: nBusinessReferralActions = {
  async referralGetStats({ commit }): Promise<void> {
    const response = await ReferralService.getReferralsPage()
    if (response.status) {
      commit('referralSetStats', response.data)
    }
  },
  async referralGetPageStats({ commit }): Promise<void> {
    const response = await ReferralService.getReferralsPageStat()
    if (response.status) {
      commit('referralSetPageStats', response.data)
    }
  },
}

const mutations: nBusinessReferralMutations = {
  referralSetStats(state, stats): void {
    state.stats = stats
  },
  referralSetPageStats(state, pageStats): void {
    state.pageStats = pageStats
  },
}

const getters: nBusinessReferralGetters = {
  getReferralStats(state): any {
    return state.stats
  },
  getReferralPageStats(state): any {
    return state.pageStats
  },
}

const nBusinessReferral: nBusinessReferralModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nBusinessReferral





















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { StreamAppModelType, StreamApiModelType } from '@/services/api/Stream/StreamTypes'
import { VuexGetter, VuexAction, VuexMutation } from '@/store/decorators'
import StreamsListItem from '@/views/affiliate/offers/offersItem/components/StreamsListItem.vue'
import StreamsForm from '@/views/affiliate/offers/offersItem/components/StreamsForm.vue'
import StreamsPopUpDelete from '@/views/affiliate/offers/offersItem/components/StreamsPopUpDelete.vue'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'
import { TrafficbackApiModelType } from '@/services/api/Trafficback/TrafficbackTypes'
import { PostbackApiModelType } from '@/services/api/Postback/PostbackTypes'

@Component({
  components: {
    StreamsListItem,
    StreamsForm,
    StreamsPopUpDelete,
  },
})
export default class StreamsList extends Vue {
  // Refs
  $refs: any

  // Data

  isOpenEditForm: null | number = null

  // // Vuex module action
  @VuexAction('nAffiliateOffers') streamsGetItems!: () => Promise<void>

  @VuexAction('nAffiliateOffers') streamsGetItem!: (...args) => Promise<void>

  @VuexAction('nAffiliateOffers') streamsGetItemForEdit!: (...args) => Promise<void>

  @VuexAction('nAffiliateOffers') streamsCreate!: (...args) => Promise<void>

  @VuexAction('nAffiliateOffers') streamsUpdate!: (...args) => Promise<void>

  @VuexAction('nAffiliateOffers') streamsDeleteItem!: (...args) => Promise<void>

  // Vuex module mutation
  @VuexMutation('nAffiliateOffers') streamsParamsUpdate!: (...args) => void

  @VuexMutation('nAffiliateOffers') streamsDeletePopUpChangeStatus!: (...args) => void

  @VuexMutation('nAffiliateOffers') setIsOpenCreateStreamFormStatus!: (...args) => void

  // Vuex module getter
  @VuexGetter('nAffiliateOffers') getStreamsItems!: StreamAppModelType[]

  @VuexGetter('nAffiliateOffers') getOffersCurrentItem!: OfferAppModelType

  @VuexGetter('nApp') getAuthUser!: any // TODO Старый модуль для получения user

  @VuexGetter('nAffiliateOffers') getIsOpenCreateStreamFormStatus!: boolean

  // Methods
  async streamDelete(id: number): Promise<void> {
    await this.streamsDeleteItem({ id })
    this.$router.push(this.$route.path)
    await this.fetchStreamsGetItems()
    this.closePopUp()
  }

  async streamCreate(data: {
    postbacks: PostbackApiModelType[]
    streamData: StreamApiModelType
    trafficbacks: TrafficbackApiModelType[]
  }): Promise<void> {
    await this.streamsCreate({ data })
    this.setIsOpenCreateStreamFormStatus(false)
    await this.fetchStreamsGetItems()
  }

  async streamUpdate(data: {
    postbacks: PostbackApiModelType[]
    streamData: StreamApiModelType
    trafficbacks: TrafficbackApiModelType[]
    streamId: number
    needDelete: {
      postback: string[]
      trafficback: string[]
    }
  }): Promise<void> {
    await this.streamsUpdate({ data })
    this.isOpenEditForm = null
    await this.fetchStreamsGetItems()
  }

  openCreateForm(): void {
    this.setIsOpenCreateStreamFormStatus(true)
    this.isOpenEditForm = null
    setTimeout(() => {
      this.$vuetify.goTo(this.$refs.createForm)
    }, 50)
  }

  closeCreateForm(): void {
    this.setIsOpenCreateStreamFormStatus(false)
  }

  async openEditForm(id: number): Promise<void> {
    await this.streamsGetItemForEdit(id)
    this.isOpenEditForm = id
    setTimeout(() => {
      this.$vuetify.goTo(this.$refs?.[`editForm${id}`][0])
    }, 50)
  }

  async openDeletePopUp(id: number): Promise<void> {
    await this.streamsGetItem(id)
    this.streamsDeletePopUpChangeStatus(true)
  }

  closePopUp(): void {
    this.streamsDeletePopUpChangeStatus(false)
  }

  async fetchStreamsGetItems(): Promise<void> {
    this.streamsParamsUpdate({
      offer: this.getOffersCurrentItem.id,
      user: this.getAuthUser.id,
      pagination: false,
    })
    await this.streamsGetItems()
  }

  @Watch('getIsOpenCreateStreamFormStatus')
  getIsOpenCreateStreamFormStatusHandler(): void {
    if (this.getIsOpenCreateStreamFormStatus) {
      this.openCreateForm()
    }
  }

  // Hooks
  async created(): Promise<void> {
    await this.fetchStreamsGetItems()
    if (this.$route.query.stream_id) {
      await this.openEditForm(Number(this.$route.query.stream_id))
    }
    this.getIsOpenCreateStreamFormStatusHandler()
  }

  beforeDestroy(): void {
    this.setIsOpenCreateStreamFormStatus(false)
  }
}







































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import moment from 'moment'
import { VuexGetter } from '@/store/decorators'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import { filterDataType, groupByType } from '../types'

@Component({})
export default class AffiliateStatisticsFilter extends Vue {
  // Data
  filterData: filterDataType = {
    groupBy: null,
    offer: null,
    subId1: null,
    subId2: null,
    subId3: null,
    dateRange: [],
    app: null,
    stream: null,
    country: null,
    device: null,
    currencies: null,
  }

  deviceSelectList = [
    { id: 2, name: 'IOS' },
    { id: 3, name: 'Android' },
    { id: 4, name: 'Web' },
  ]

  // Vuex module getters
  @VuexGetter('app') getApps!: [] // TODO Старый модуль для получения приложений

  @VuexGetter('nAffiliateStatistics') getOffersItems!: [] | OfferAppModelType[]

  @VuexGetter('nAffiliateStatistics') getStreamsItems!: [] | StreamAppModelType[]

  @VuexGetter('nAffiliateStatistics') getStatsAffiliateParams!: any

  // Computed
  get groupBySelectList(): groupByType[] {
    return [
      { name: this.$t('pageAffiliateStats.groupByOptions.day'), value: 'created' },
      { name: this.$t('pageAffiliateStats.groupByOptions.offer'), value: 'offer' },
      { name: this.$t('pageAffiliateStats.groupByOptions.stream'), value: 'stream' },
      { name: this.$t('pageAffiliateStats.groupByOptions.app'), value: 'app' },
    ]
  }

  // Methods
  clearForm(): void {
    this.filterData = {
      groupBy: this.groupBySelectList[0],
      offer: null,
      app: null,
      stream: null,
      country: null,
      device: null,
      subId1: null,
      subId2: null,
      subId3: null,
      dateRange: [
        moment().subtract(365, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ],
      currencies: null,
    }
  }

  setSavedFilterData(): void {
    const keysFilterData = Object.keys(this.filterData)
    keysFilterData.forEach((i) => {
      if (this.getStatsAffiliateParams[i]) {
        this.filterData[i] = this.getStatsAffiliateParams[i]
      }
    })
  }

  // Emits
  @Emit('filter')
  filter(): filterDataType {
    return this.filterData
  }

  // Hooks
  created(): void {
    this.setSavedFilterData()
  }
}

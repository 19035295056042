import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { OfferAppModelType } from './OfferTypes'

class OfferService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.offers')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.OFFER)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 30,
    page: 1,
  }

  private usedListParams = [
    'itemsPerPage',
    'page',
    'status',
    'countries',
    'app',
    'offerCategory',
    'isTop',
    'user',
    'pagination',
  ]

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    data.title = incomingData.title
    data.app = `/api/apps/${incomingData.app}`
    data.aboutCompany = incomingData.aboutCompany
    data.benefits = incomingData.benefits
    data.description = `${incomingData.benefits}&#13;${incomingData.aboutCompany}&#13;${incomingData.workingConditions}`
    data.goalType = parseInt(incomingData.goalType)
    data.digitalProductPercent = parseInt(incomingData.digitalProductPercent) || 0
    data.productPercent = parseInt(incomingData.productPercent) || 0
    data.lang = incomingData.lang
    data.menPercent = parseInt(incomingData.menPercent) || 0
    data.workingConditions = incomingData.workingConditions
    data.aboutCompany = incomingData.aboutCompany
    data.countries = incomingData.countries.map((i) => `/api/countries/${i.id}`)
    data.sources = incomingData.sources.map((i) => `/api/offer_sources/${i.id}`)
    data.offerCategory = `/api/offer_categories/${incomingData.offerCategory.id}`
    data.targetAudience = incomingData.targetAudience
    return data
  }

  public async offerTotalGetItems<P>({ params }: { params?: P }): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.OFFER}/total`
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const result = {
        data: response.data,
        params: {
          page: parseInt(response.headers['x-pagination-page']),
          pages: parseInt(response.headers['x-pagination-pages']),
        },
      }
      return { status: true, data: result.data, params: result.params }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, params: {}, error }
    }
  }

  public transformDataForApp(incomingData): OfferAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    if (incomingParams.offerCategory) {
      params.offerCategory = incomingParams.offerCategory.id
    }
    if (incomingParams.group && incomingParams.group.isTop) {
      params.isTop = incomingParams.group.isTop
    }
    if (incomingParams.group && incomingParams.group.user) {
      params.user = incomingParams.group.user
    }
    return params
  }
}
export default new OfferService()



















import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {},
})
export default class ColDialog extends Vue {
  @VuexGetter('nChats', 'getDialog') dialog!: any
}
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class LocMosaicPreviews extends Vue {
  layoutItems: any[] = []

  testData = [
    {
      id: 13151,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/sc/re/screenshot-4-62f132d554890545303338.png',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132d78b878-62f132d78b875235510552.jpg',
      width: 386,
      height: 371,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/png',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13152,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/sc/re/screenshot-5-62f132d5978d0973700493.png',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132d86eed1-62f132d86eec5191733960.jpg',
      width: 494,
      height: 469,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/png',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13153,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/55/f3/55f3257488eda8a242417d9fdc55c247-62f132d5cf393350591475.jpg',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132d943c75-62f132d943c6a688798767.jpg',
      width: 640,
      height: 480,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/jpeg',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13154,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/sc/re/screen-62f132d612c89903260259.png',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132d9c4518-62f132d9c450d983793530.jpg',
      width: 460,
      height: 872,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/png',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13155,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/ip/ho/iphone-12-mini-purple-62f132d6495f2621138450.png',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132da87500-62f132da874f6256456576.jpg',
      width: 500,
      height: 500,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/png',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13153,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/55/f3/55f3257488eda8a242417d9fdc55c247-62f132d5cf393350591475.jpg',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132d943c75-62f132d943c6a688798767.jpg',
      width: 640,
      height: 480,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/jpeg',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13155,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/ip/ho/iphone-12-mini-purple-62f132d6495f2621138450.png',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132da87500-62f132da874f6256456576.jpg',
      width: 500,
      height: 500,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/png',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13151,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/sc/re/screenshot-4-62f132d554890545303338.png',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132d78b878-62f132d78b875235510552.jpg',
      width: 386,
      height: 371,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/png',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13156,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/50/7c/507c6edb64bc14ea16008f72bf180fde-62f132d681d68027023557.jpg',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132db1f754-62f132db1f74a716910510.jpg',
      width: 1024,
      height: 954,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/jpeg',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13157,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/12/97/12978-62f132d6c0999822926744.png',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132dbcb232-62f132dbcb228282290730.jpg',
      width: 740,
      height: 639,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/png',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13158,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/20/-6/20-62f132d70e10f996825711.jpg',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132dca4895-62f132dca488b637020377.jpg',
      width: 1156,
      height: 1400,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/jpeg',
      convert_status: 1,
      client_id: 1000443,
    },
    {
      id: 13159,
      content_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/ph/ot/photo-2022-04-08-11-46-37-62f132d7475a3993059271.jpg',
      preview_url:
        'https://supster-images-dev.s-ed1.cloud.gcore.lu/62/f1/62f132dd9502f-62f132dd95023525690053.jpg',
      width: 1156,
      height: 651,
      duration: null,
      hls: null,
      dash: null,
      mime: 'image/jpeg',
      convert_status: 1,
      client_id: 1000443,
    },
  ]

  checkOrient(width, height) {
    return width > height ? '_horisontal' : '_vertical'
  }

  created() {
    const rows = Math.round(this.testData.length / 4)
    let currentRow = 1
    for (let i = 0; i <= this.testData.length; i++) {
      if (i <= 1) {
        this.layoutItems.push({ cols: 6, rows: 3 })
      } else if (i <= 4) {
        currentRow = 2
        this.layoutItems.push({ cols: 4, rows: 2 })
      } else {
        currentRow = Math.round(i / 4)
        this.layoutItems.push({ cols: 3, rows: 1 })
      }
    }
  }
}

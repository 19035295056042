import { ProductsGetters } from '@/store/modules/products/types'

const getters: ProductsGetters = {
  getProductsList(state) {
    return state.products.list
  },
  getProductsItem(state) {
    return state.products.item
  },
}

export default getters

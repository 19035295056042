<template>
  <div class="verifier-image-container">
    <v-row>
      <v-col class="verifier-image-desc-block" cols="6">
        <div class="verifier-image-common-desc font-weight-bold">
          {{ imageHeader }}
        </div>
        <div v-if="code">
          <div
            class="d-inline-block text-white font-weight-bold bg-primary rounded text-large py-2 px-3 my-2"
          >
            {{ code }}
          </div>
        </div>
        <div v-if="imageSubTitle" class="verifier-image-common-desc font-weight-bold my-6">
          {{ imageSubTitle }}
        </div>
        <div class="verifier-image-common-desc mb-6">
          {{ $t('verification.imageRules') }}
        </div>
        <div v-if="imageRequirements">
          <div
            class="verifier-image-common-desc verifier-image-requirement mb-2"
            :key="'requirement' + index"
            v-for="(requirement, index) in imageRequirements"
          >
            <icon :data="require('@/assets/icons/bp-features/verification/image-req-row.svg')" />
            {{ requirement }}
          </div>
        </div>
        <v-spacer />
        <div v-if="uploadedImg" class="verifier-image-status">
          <icon
            class="verifier-image-uploaded-icon mb-1"
            :data="require('@/assets/icons/bp-features/verification/image-uploaded.svg')"
          />
          {{ $t('requisites.imageOK') }}
        </div>
        <div v-else class="verifier-image-status">
          <div class="verifier-image-not-uploaded-icon">!</div>
          {{ $t('verification.imageNotLoad') }}
        </div>
      </v-col>
      <v-col cols="6">
        <input ref="fileInput" class="d-none" type="file" @change="addImage" />
        <div
          :class="{ 'no-valid': error }"
          class="verifier-image-show-block"
          v-if="!uploadedImg"
          @drop.prevent="addImage"
          @dragover.prevent
        >
          <div class="verifier-image-common-desc mb-4">{{ $t('verification.dragFile') }}</div>
          <v-btn @click="inputClick" color="accent" small class="bp-publish__rule-btn mb-4">
            <icon
              data="@/assets/icons/bp-features/publish/upload-arrow.svg"
              height="12px"
              width="12px"
              class="mr-2"
            />
            {{ $t('verification.selectFile') }}
          </v-btn>
        </div>
        <v-img v-else class="verifier-image-uploaded-img" :src="uploadedImg">
          <v-btn
            @click="inputClick"
            color="var(--accent-green)"
            class="verifier-image-upload-another-btn"
          >
            <icon
              data="@/assets/icons/bp-features/publish/upload-arrow.svg"
              height="12px"
              width="12px"
            />
            {{ $t('pageBusinessPublish.stage3.upload_other_btn') }}
          </v-btn>
          <v-btn @click="deleteImg" color="primary lighten-1" fab class="verifier-image-reset-btn">
            <icon :data="require('@/assets/icons/delete-sorting-icon.svg')" />
          </v-btn>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defaults } from 'lodash'
import Vue from 'vue'

export default Vue.extend({
  name: 'ImageVerifier',
  props: {
    imageHeader: {
      type: String,
      default: '',
    },
    imageSubTitle: {
      type: String,
      default: '',
    },
    imageRequirements: {
      type: Array,
    },
    code: [String, Number],
    value: {
      type: [String, Number, Object, File, Promise],
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    uploadedImg: '',
  }),
  methods: {
    inputClick() {
      this.$refs.fileInput.click()
    },

    addImage(event) {
      let file = ''
      if (event.target.files) {
        file = event.target.files[0]
      } else {
        file = event.dataTransfer.files[0]
      }
      if (file) {
        this.uploadedImg = URL.createObjectURL(file)
        URL.revokeObjectURL(file)
        this.$emit('input', file, this.index)
      }
    },
    // async imageFormChanged(e) {
    //   const file = e.target.files[0]
    //   if (file) {
    //     const mediaRequest = new FormData()
    //     mediaRequest.append('file', file)
    //     this.uploadedImg = URL.createObjectURL(file)
    //     const mediaObjectResponse = await getInstanceWithToken().post(
    //       '/api/media_objects',
    //       mediaRequest
    //     )
    //     URL.revokeObjectURL(file)
    //     this.$emit('imgloaded', `/api/media_objects/${mediaObjectResponse.data.id}`)
    //   } else {
    //     this.uploadedImg = ''
    //   }
    // },
    deleteImg() {
      this.$emit('input', '')
      this.uploadedImg = ''
      this.$refs.fileInput.value = ''
    },
  },
})
</script>















































































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { ClientVerificationsAppModelType } from '@/services/api/ClientVerifications/ClientVerificationsTypes'

@Component({
  components: {},
})
export default class Status3 extends Vue {
  // Vuex module getter
  @VuexGetter('nBusinessVerification') getClientVerification!: ClientVerificationsAppModelType
}

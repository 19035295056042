import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'

interface ProductCategoriesModelAppTypes {
  id?: number
  subCategories: []
  name: string
}
interface ProductCategoriesModelApiTypes {
  id: number
}

class ProductCategoriesService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.productCategories')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ProductCategoriesModelAppTypes = {
    subCategories: [],
    name: '',
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PRODUCT_CATEGORIES)
  }

  public getEmptyItem(): ProductCategoriesModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(data): ProductCategoriesModelApiTypes {
    return data
  }

  public transformDataForApp(data): ProductCategoriesModelAppTypes {
    return data
  }

  public transformRequestParams(params: any): any {
    params['exists[parent]'] = true
    return params
  }
}
export default new ProductCategoriesService()

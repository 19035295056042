










































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { VuexAction, VuexMutation, VuexGetter } from '@/store/decorators'
import Navigation from '@/components/layout/Navigation.vue'
import Sidebar from '@/components/layout/Sidebar.vue'
import MessageNotify from '@/components/chats/MessageNotify.vue'
import Centrifuge from 'centrifuge'
import AuthProvider from '@/providers/AuthProvider'
import WebsocketProvider from './providers/WebsocketProvider'
import { AppsAppModelType } from './services/api/Apps/AppsTypes'
import { UserAppModelType } from './services/api/User/UserTypes'
import { ClientsAppModelType } from './services/api/Clients/ClientsTypes'
import { ProjectIsGPT } from './utils/project'

@Component({
  components: {
    Navigation,
    Sidebar,
    MessageNotify,
  },
})
export default class App extends Vue {
  // Data
  centrifuge = Centrifuge

  notify: any = null

  isHasApps = true

  isLoadedStartData = true

  specificPagesForAdaptation = ['Affiliate_Statistics', 'Business_AnalyticsReports']

  // Vuex module getters
  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nApp') getAppsList!: AppsAppModelType[]

  @VuexGetter('nApp') getAuthUser!: UserAppModelType

  @VuexGetter('nApp') getAuthClient!: ClientsAppModelType

  @VuexGetter('nApp') getNotifications!: any

  @VuexGetter('nApp') getAppReloadStatus!: boolean

  @VuexGetter('nApp') getMobileSidebar!: boolean

  @VuexGetter('nChats') getDialog!: any

  @VuexGetter('snackbar') getSnackbar!: any

  // Vuex module action
  @VuexAction('nApp') fetchBalance!: () => Promise<boolean>

  @VuexAction('nApp') fetchApps!: () => Promise<boolean>

  @VuexAction('nApp') fetchCurrentCountry!: () => Promise<boolean>

  @VuexAction('nDeskbook') fetchCurrencies!: () => Promise<boolean>

  @VuexAction('nDeskbook') fetchCountries!: () => Promise<boolean>

  @VuexAction('nDeskbook') fetchPayoutSystems!: () => Promise<boolean>

  @VuexAction('nChats') channelsGetItemsByNewMessages!: (...args) => Promise<boolean>

  //  Vuex module mutation
  @VuexMutation('nApp') changeMobileSidebar!: (...args) => void

  @VuexMutation('nApp') changeCurrentApp!: (...args) => void

  @VuexMutation('nChats') setMeClientId!: (...args) => void

  // Computed
  get isGPT() {
    return ProjectIsGPT()
  }

  get isShowSidebar(): boolean {
    return (
      this.currentSection === 'business' ||
      this.currentSection === 'affiliate' ||
      this.currentSection === 'profile'
    )
  }

  get style(): string {
    if (this.getAuthUser) {
      if (this.currentSection === 'crm' || this.currentSection === 'newdialogs') {
        return `padding: 80px 0px 0px 0px;`
      }
      let leftOffset = '320px'
      if (this.$vuetify.breakpoint.width < 1600) {
        leftOffset = '255px'
      }
      if (this.$vuetify.breakpoint.width <= 1365 || this.getMobileSidebar) {
        leftOffset = '80px'
      }
      return `padding: 80px 0px 0px ${leftOffset};`
    }
    return 'padding: 0'
  }

  get currentSection(): string {
    return this.$route.path.split('/')[1]
  }

  // Methods
  resizeApp(): void {
    this.changeMobileSidebar(
      window.innerWidth <= 1600 &&
        this.specificPagesForAdaptation.includes(String(this.$route.name))
    )
  }

  loadMainData(): void {
    this.fetchBalance()
    this.fetchCurrencies()
    this.fetchCountries()
    this.fetchPayoutSystems()
    this.setMeClientId(this.getAuthClient.id)
    if (this.getCurrentApp?.id) {
      this.channelsGetItemsByNewMessages({ appId: this.getCurrentApp.id })
    }
  }

  showNotify(message: any, user: any, id: any, clientId: any, channelId: any): void {
    this.notify = { message, userName: user, id, clientId, channelId }
  }

  hideNotify(): void {
    setTimeout(() => {
      this.notify = null
    }, 1000)
  }

  async loadApps(): Promise<void> {
    this.isLoadedStartData = true
    this.isHasApps = true
    await this.fetchApps()
    if (!this.getAppsList.length) {
      await this.$router.push({ name: 'NoApps' })
      // process.env.NODE_ENV === 'production'
      //   ? await (window.location.pathname = '/builder')
      //   : console.log('redirect builder')
      this.isLoadedStartData = false
      this.isHasApps = false
      return
    }
    if (this.$route.name === 'NoApps' && this.getAppsList) {
      await this.$router.push({ name: 'Business_Home' })
    }

    const localApp = JSON.parse(String(localStorage.getItem('APP')))
    this.changeCurrentApp(
      localApp ? this.getAppsList.find((item) => item.id === localApp.id) : this.getAppsList[0]
    )
    this.isLoadedStartData = false
  }

  // Watchers
  @Watch('getAuthUser')
  async getAuthUserHander(): Promise<void> {
    if (this.getAuthUser) {
      await this.loadApps()
      this.loadMainData()
      WebsocketProvider.initBaseActions()
    } else {
      this.isLoadedStartData = false
    }
  }

  @Watch('$route')
  routeHander(): void {
    this.resizeApp()
  }

  sendTokens(): void {
    const tokens = {
      API_TOKEN: AuthProvider.getToken('crm'),
      CHAT_TOKEN: AuthProvider.getToken('pwa'),
    }
    window.parent.postMessage(tokens, '*')
  }

  // Hooks
  async created(): Promise<void> {
    this.sendTokens()
    this.fetchCurrentCountry()
    AuthProvider.loginByQueryToken()
    WebsocketProvider.app_notify = this.showNotify
    if (this.getAuthUser) {
      await this.loadApps()
      this.loadMainData()
      WebsocketProvider.initBaseActions()
    }
  }
}

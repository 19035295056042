



































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexMutation, VuexGetter } from '@/store/decorators'
import PublicationStageOne from '@/views/business/publication/components/PublicationStageOne.vue'
import PublicationStageTwo from '@/views/business/publication/components/PublicationStageTwo.vue'
import PublicationStageThree from '@/views/business/publication/components/PublicationStageThree.vue'
import Status1 from '@/views/business/publication/components/statuses/Status1.vue'
import Status3 from '@/views/business/publication/components/statuses/Status3.vue'
import Status5 from '@/views/business/publication/components/statuses/Status5.vue'
import { PublicationModelAppType } from '@/services/api/Publication/PublicationTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'

@Component({
  components: {
    PublicationStageThree,
    PublicationStageTwo,
    PublicationStageOne,
    Status1,
    Status3,
    Status5,
  },
})
export default class BusinessPublication extends Vue {
  currentStage = 'One'

  moderateStatus = 1

  formMode = 'create'

  isPreloaderShow = false

  // Vuex module getters
  @VuexGetter('nBusinessPublication') getPublicationListItems!: Array<PublicationModelAppType>

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Vuex module action
  @VuexAction('nBusinessPublication') publicationGetItems!: () => Promise<void>

  //  Vuex module mutation
  @VuexMutation('nBusinessPublication') publicationListParamsUpdate!: (...args) => void

  moveForward(nextStage) {
    this.currentStage = nextStage
  }

  changeFormModeOnEdit() {
    this.formMode = 'edit'
    this.currentStage = 'Three'
  }

  async created() {
    this.isPreloaderShow = true
    this.publicationListParamsUpdate({ app: this.getCurrentApp.id })
    await this.publicationGetItems()
    if (this.getPublicationListItems.length) {
      this.moderateStatus = this.getPublicationListItems[0].status
      if (
        this.getPublicationListItems[0].status !== 5 &&
        this.getPublicationListItems[0].status !== 1 &&
        this.getPublicationListItems[0].status !== 3
      ) {
        this.$router.push({
          name: 'Business_Home',
        })
      }
    }
    this.isPreloaderShow = false
  }
}

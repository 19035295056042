











































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { forEach } from 'lodash'

@Component
export default class Sidebar extends Vue {
  // Props
  @Prop(String) readonly section!: string

  // Data
  $refs: any

  $root: any

  swipeHandlerClass = ''

  test = ''

  listGroupStateValue: boolean | null = null

  // Computed
  get adaptiveClass(): string {
    let classResult = ''
    if (this.$vuetify.breakpoint.width <= 1365 || this.getMobileSidebar) {
      classResult = 'mini'
    } else if (this.$vuetify.breakpoint.width < 1600) {
      classResult = 'middle'
    } else if (this.$vuetify.breakpoint.width >= 1600) {
      classResult = 'high'
    }
    return classResult
  }

  get proccessedLinksList(): any {
    const section = this.$route.path.split('/')[1]
    return this.linksList[section]
  }

  get linksList(): any {
    return {
      crm: [
        {
          title: 'Dialogs',
          link: 'Dialogs',
          icon: '',
        },
      ],
      affiliate: [
        {
          title: this.$t('affileateNav.home'),
          link: 'Affiliate_Home',
          icon: require('@/assets/icons/home.svg'),
        },
        {
          title: this.$t('affileateNav.offers'),
          link: 'Affiliate_Offers_List',
          icon: require('@/assets/icons/offers.svg'),
        },
        {
          title: this.$t('affileateNav.statistics'),
          link: 'Affiliate_Statistics',
          icon: require('@/assets/icons/statistics.svg'),
        },
        {
          title: this.$t('affileateNav.streams'),
          link: 'Affiliate_Streams',
          icon: require('@/assets/icons/streams.svg'),
        },
        // {
        //   title: this.$t('busunessNav.analytics.home'),
        //   link: 'Affiliate_Analytics',
        //   icon: require('@/assets/icons/analytics.svg'),
        // },
        {
          title: this.$t('affileateNav.finances.finances'),
          link: 'Affiliate_Finances',
          icon: require('@/assets/icons/finances.svg'),
          children: [
            {
              title: this.$t('affileateNav.finances.requisites'),
              link: 'Affiliate_Requisites',
            },
          ],
        },
        // {
        //   title: this.$t('affileateNav.tools.main'),
        //   link: 'Affiliate_Tools',
        //   icon: require('@/assets/icons/tools.svg'),
        //   children: [
        //     {
        //       title: this.$t('affileateNav.tools.domainParking'),
        //       link: 'Affiliate_Tools_DomainParking',
        //     },
        //     {
        //       title: this.$t('globalPostBack.title'),
        //       link: 'Affiliate_Global_Postback',
        //     },
        //     {
        //       title: this.$t('postbackLogs.title'),
        //       link: 'Affiliate_Tools_PostbackLog',
        //     },
        //     {
        //       title: this.$t('placement.title'),
        //       link: 'Affiliate_Tools_Placement',
        //     },
        //   ],
        // },
        {
          title: this.$t('affileateNav.news'),
          link: 'Affiliate_News',
          icon: require('@/assets/icons/news.svg'),
        },
        {
          title: this.$t('support'),
          link: 'Dialogs',
          query: {
            support: 'affiliate',
          },
          icon: require('@/assets/icons/support.svg'),
        },
        // {
        //   title: this.$t('instructions'),
        //   link: 'Affiliate_Instructions',
        //   icon: require('@/assets/icons/instructions.svg'),
        // },
        {
          title: this.$t('faq.title'),
          link: 'Affiliate_Faq',
          icon: require('@/assets/icons/faq.svg'),
        },
      ],
      business: [
        {
          title: this.$t('busunessNav.home'),
          link: 'Business_Home',
          icon: require('@/assets/icons/home.svg'),
        },

        {
          title: this.$t('busunessNav.subscriptions.home'),
          link: 'Business_Subscriptions',
          icon: require('@/assets/icons/subscriptions.svg'),
          children: [
            {
              title: this.$t('tariffs'),
              link: 'Business_Tariffs',
            },
            {
              title: this.$t('subscriptionManagment.title'),
              link: 'Business_Managment',
            },

            {
              title: this.$t('transactions.title'),
              link: 'Business_Transactions',
            },

            // {
            //   title: this.$t('busunessNav.subscriptions.physical'),
            //   link: 'Business_Physical',
            // },
          ],
        },
        // {
        //   title: this.$t('buttons'),
        //   link: 'Business_Buttons',
        //   icon: require('@/assets/icons/buttons.svg'),
        //
        // },

        {
          title: this.$t('referral'),
          link: 'Business_Referral',
          icon: require('@/assets/icons/referal.svg'),
        },
        // {
        //   title: this.$t('customers'),
        //   link: 'Business_Customers',
        //   icon: require('@/assets/icons/customers.svg'),
        // },
        // {
        //   title: this.$t('autoFunnels'),
        //   link: 'Business_AutoFunnels',
        //   icon: require('@/assets/icons/auto_funnels.svg'),
        //
        // },
        {
          title: this.$t('busunessNav.finances.finances'),
          link: 'Business_Finances',
          icon: require('@/assets/icons/finances.svg'),
          children: [
            {
              title: this.$t('busunessNav.finances.requisites'),
              link: 'Business_Requisites',
            },
            {
              title: this.$t('busunessNav.finances.accruals'),
              link: 'Business_Accruals',
            },
          ],
        },

        {
          title: this.$t('busunessNav.analytics.home'),
          link: 'Business_Analytics',
          icon: require('@/assets/icons/analytics.svg'),
          children: [
            {
              title: this.$t('busunessNav.analytics.dashboard'),
              link: 'Business_AnalyticsDashboard',
            },
            {
              title: this.$t('busunessNav.analytics.reports'),
              link: 'Business_AnalyticsReports',
            },
          ],
        },
        // {
        //   title: this.$t('goodsAndServices'),
        //   link: 'Business_GoodsAndServices',
        //   icon: require('@/assets/icons/goods_and_services.svg'),
        //   children: [
        //     {
        //       title: this.$t('menuProducts'),
        //       link: 'Business_GoodsAndServices.products',
        //     },
        //     {
        //       title: this.$t('menuServices'),
        //       link: 'Business_GoodsAndServices.services',
        //     },
        //     {
        //       title: this.$t('orders'),
        //       link: 'Business_GoodsAndServices.orders',
        //     },
        //     {
        //       title: this.$t('payouts'),
        //       link: 'Business_GoodsAndServices.payments',
        //     },
        //     {
        //       title: this.$t('logistics'),
        //       link: 'Business_GoodsAndServices.logistics',
        //     },
        //     {
        //       title: this.$t('pageAffiliateStats.tableHeader.return'),
        //       link: 'Business_GoodsAndServices.returns',
        //     },
        //     {
        //       title: this.$t('controversy'),
        //       link: 'Business_GoodsAndServices.disputes',
        //     },
        //   ],
        // },
        {
          title: this.$t('orders'),
          link: 'Business_Orders',
          icon: require('@/assets/icons/orders.svg'),
        },
        // {
        //   title: this.$t('specialOffers'),
        //   link: 'Business_SpecialOffers',
        //   icon: require('@/assets/icons/special_offers.svg'),
        //
        // },
        // {
        //   title: this.$t('content'),
        //   link: 'Business_Content',
        //   icon: require('@/assets/icons/content.svg'),
        //
        // },
        // {
        //   title: this.$t('salesChannels'),
        //   link: 'Business_SalesChannels',
        //   icon: require('@/assets/icons/sales_channels.svg'),
        //
        // },
        {
          title: this.$t('busunessNav.affileate_marketing'),
          link: 'Business_AffiliateMarketing',
          icon: require('@/assets/icons/aff-marketing.svg'),
        },
        {
          title: this.$t('support'),
          link: 'Dialogs',
          query: {
            support: 'business',
          },
          icon: require('@/assets/icons/support.svg'),
        },
        {
          title: this.$t('faq.title'),
          link: 'Business_Faq',
          icon: require('@/assets/icons/faq.svg'),
        },
      ],
      profile: [
        {
          title: this.$t('profileEdit'),
          link: 'profile.home',
          icon: require('@/assets/icons/customers.svg'),
        },
        {
          title: this.$t('changePassword'),
          link: 'profile.change_password',
          icon: require('@/assets/icons/customers.svg'),
        },
      ],
    }
  }

  // Vuex module getter
  @VuexGetter('nApp') getMobileSidebar!: boolean

  // Methods
  hideMenu(): void {
    if (this.adaptiveClass === 'mini') {
      this.swipeHandlerClass = 'close'
      this.closeAllDropdown()
    }
  }

  closeAllDropdown(): void {
    this.listGroupStateValue = false
    setTimeout(() => {
      this.listGroupStateValue = null
    }, 50)
  }

  swipeMenu(direction: string): void {
    this.swipeHandlerClass = direction === 'right' ? 'open' : 'close'
  }

  routeCheck(item: any): boolean {
    if (this.$route.name !== item.link) {
      return item.children && item.children.find((p) => this.$route.name === p.link)
        ? true
        : this.$route.path.includes(item.link.toLowerCase())
    }

    return true
  }

  @Watch('section')
  sectionChange(): void {
    this.closeAllDropdown()
  }

  mounted(): void {
    if (this.proccessedLinksList) {
      this.proccessedLinksList.forEach((parent) => {
        if (parent.children) {
          parent.children.forEach((children) => {
            if (children.link === this.$route.name) {
              this.$refs[`group${parent.link}`][0].isActive = true
            }
          })
        }
      })
    }
  }
}

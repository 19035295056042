import { cloneDeep } from 'lodash'
import BalanceService from '@/services/api/Balance/BalanceService'
import AppsService from '@/services/api/Apps/AppsService'
import PostsService from '@/services/api/Posts/PostsService'
import { CurrenciesAppModelType } from '@/services/api/Currencies/CurrenciesTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { BalanceAppModelType } from '@/services/api/Balance/BalanceTypes'
import { HttpApiCrm } from '@/services/HttpClientService'
import LocalizationProvider from '@/providers/LocalizationProvider'
import AuthProvider from '@/providers/AuthProvider'
import ChatGptSystemPromptService from '@/services/api/ChatGptSystemPrompt/ChatGptSystemPromptService'
import { nAppMutations, nAppActions, nAppGetters, nAppModule } from './types'

const defaultAuthData = {
  mode: 'user',
  token: null,
  pwaToken: null,
  user: null,
  client: null,
  gpt_mode: AuthProvider.isGptMode(),
}
const defaultAppsData = {
  list: [],
  currentApp: null,
  appPreviews: [],
}

const actions: nAppActions = {
  async fetchApps({ commit }): Promise<void> {
    const response = await AppsService.getItems({ params: {} })
    if (response.status) {
      commit('updateAppsList', response.data)
    }
  },
  async fetchAppPreviews({ commit, state }, id): Promise<void> {
    const response = await PostsService.getItems({
      params: { app: id ?? state.apps?.currentApp?.id, itemsPerPage: 6 },
    })
    if (response.status) {
      commit('updateAppPreviews', response.data)
    }
  },

  async fetchBalance({ commit }): Promise<void> {
    const params = { page: 1, itemsPerPage: 100 }
    const response = await BalanceService.getItems({ params })
    if (response.status) {
      commit('updateBalance', response.data)
    }
  },
  async appReloadStatus({ commit, dispatch }): Promise<void> {
    commit('setAppReloadStatus', true)
    await dispatch('fetchBalance')
    setTimeout(() => {
      commit('setAppReloadStatus', false)
    }, 350)
  },
  async createNotification(
    { commit },
    { type = 'default', message = '', title = '' }
  ): Promise<void> {
    commit('addNotification', { type, message, title })
    setTimeout(() => {
      commit('removeNotification')
    }, 4500)
  },
  async fetchCurrentCountry({ commit }): Promise<boolean> {
    try {
      const response = await HttpApiCrm.get(`/api/countries/current`)
      if (response?.data) {
        commit('changeCurrentCountry', response.data)
        return true
      }
    } catch (error) {
      return false
    }
    return false
  },
  async authModerator({ commit }, { token, user }): Promise<void> {
    commit('authSaveData', { token, user, mode: 'moderator' })
  },

  async updateApp({ commit }, { data, id }): Promise<any> {
    const response = await AppsService.updateItem({ data, id })
    if (response.status) {
      commit('changeCurrentApp', response.data)
    }
    return response
  },

  async authLogin({ commit }, data): Promise<void> {
    const response = await AuthProvider.login(data)
    if (!AuthProvider.isGptMode()) {
      AuthProvider.checkAuthUser()
    }
    return response
  },
  async authRegister({ commit }, data): Promise<void> {
    const response = await AuthProvider.register(data)
    if (!AuthProvider.isGptMode()) {
      AuthProvider.checkAuthUser()
    }

    return response
  },
  async getAppSystemPromts({ commit }, params) {
    const response = await ChatGptSystemPromptService.getItems({
      params,
    })
    return response
  },
  async createAppSystemPromts({ commit }, data) {
    delete data.id
    const response = await ChatGptSystemPromptService.createItem({ data, params: {} })
    return response
  },
  async updateAppSystemPromts({ commit }, data) {
    const response = await ChatGptSystemPromptService.updateItem({ data, id: data.id })
    return response
  },
}
const mutations: nAppMutations = {
  changeCurrentCountry(state, value: { country_code: string }) {
    state.currentCountry = value
  },
  changeMobileSidebar(state, value: boolean): void {
    state.mobileSidebar = value
  },
  changeLocale(state, value: string): void {
    state.locale = LocalizationProvider.changeLocale(value)
  },
  setLocale(state, value: string): void {
    state.locale = value
  },
  changeCurrentApp(state, app): void {
    localStorage.setItem('APP', JSON.stringify(app))
    state.apps.currentApp = app
  },
  setReferralUserId(state, referralId) {
    if (Number(referralId) > 0) {
      state.referralUserId = Number(referralId)
    }
  },
  setAppReloadStatus(state, status): void {
    state.appReloadStatus = status
  },
  updateBalance(state, balance): void {
    state.balance = balance
  },
  updateAppsList(state, apps): void {
    state.apps.list = apps
  },
  updateAppPreviews(state, previews): void {
    state.apps.appPreviews = previews
  },
  // TODO Доработать нотификации
  addNotification(
    state: { notifications: any[] },
    { type = 'default', message = '', title = '' }
  ): void {
    let color = 'white'
    let textColor = '#000'
    if (type === 'error') {
      color = 'red'
      textColor = 'white'
    } else if (type === 'success') {
      color = 'green'
      textColor = 'white'
    }
    const snackbar = {
      message,
      color,
      text: true,
      title,
      textColor,
      type,
      timeout: 5000,
      opened: true,
      top: true,
      right: true,
      dark: true,
      multiLine: true,
    }
    state.notifications = [snackbar, ...state.notifications]
  },
  removeNotification(state, index): void {
    state.notifications.pop()
  },
  authSaveData(state, { token, pwaToken, user, client, mode = 'user' }): void {
    state.auth.mode = mode
    if (token) {
      state.auth.token = token
    }
    if (pwaToken) {
      state.auth.pwaToken = pwaToken
    }
    if (user) {
      state.auth.user = user
      state.auth.client = user.client
    }
    if (client) {
      state.auth.client = client
    }
  },
  authReset(state) {
    state.auth = cloneDeep(defaultAuthData)
    state.apps = cloneDeep(defaultAppsData)
  },
}
const getters: nAppGetters = {
  getlocale(state): string {
    return state.locale
  },
  getAppsList(state): AppsAppModelType[] {
    return state.apps.list
  },
  getAppPreviews(state): PostsAppModelType[] {
    return state.apps.appPreviews
  },
  getCurrentApp(state): AppsAppModelType | null {
    return state.apps.currentApp
  },
  getAppCurrency(state): CurrenciesAppModelType | null {
    return state.apps.currentApp?.currency ?? null
  },
  getAppCurrencySymbol(state): string {
    return state.apps.currentApp?.currency.symbol ?? ''
  },
  getCurrentCountry(state): { country_code: string } {
    return state.currentCountry
  },
  getAppId(state) {
    return state.apps.currentApp?.id || 0
  },
  getIsGptApp(state): boolean {
    return state.apps?.currentApp?.type === 'chat_gpt'
  },
  getMobileSidebar(state): boolean {
    return state.mobileSidebar
  },
  getBalance(state): BalanceAppModelType | null {
    return state.balance
  },
  getAffilieteAvailableCurrencies(state): CurrenciesAppModelType[] {
    if (state.balance && 'affiliate' in state.balance) {
      return state.balance.affiliate.map((item) => item.currency)
    }
    return []
  },
  getBusinessAvailableCurrencies(state): CurrenciesAppModelType[] {
    if (state.balance && 'apps' in state.balance) {
      return state.balance.apps.map((item) => item.currency)
    }
    return []
  },
  getNotifications(state) {
    return state.notifications
  },
  getReferralUserId(state) {
    return state.referralUserId
  },
  getAppReloadStatus(state): boolean {
    return state.appReloadStatus
  },

  // Auth
  getAuth(state) {
    return state.auth
  },
  getAuthUser(state) {
    return state.auth.user
  },
  getAuthClient(state) {
    return state.auth.client
  },
  getAuthToken(state) {
    return state.auth.token
  },
  getAuthMode(state) {
    return state.auth.mode
  },
  getCommission(state) {
    return state.commission
  },
}

const nApp: nAppModule = {
  state: () => ({
    auth: cloneDeep(defaultAuthData),
    apps: cloneDeep(defaultAppsData),
    locale: 'en',
    notifications: [],
    appReloadStatus: false,
    mobileSidebar: false,
    balance: null,
    referralUserId: false,
    preloaderStatusLoading: false,
    currentCountry: {
      country_code: '',
    },
    commission: 6, // Комиссия в процентах
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nApp






























































































import { Component, Vue } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { StatsAffiliateAppModelType } from '@/services/api/Stat/StatTypes'
import { VuexGetter } from '@/store/decorators'
import { tableHeadersType } from '../types'

@Component({})
export default class AffiliateStatisticsTable extends Vue {
  moment: any

  // Vuex module getter
  @VuexGetter('nAffiliateStatistics') getStatsAffiliateData!: StatsAffiliateAppModelType

  @VuexGetter('nAffiliateStatistics') getStatsAffiliateParams!: any

  @VuexGetter('nAffiliateStatistics') getStatsAffiliateListLoading!: boolean

  // Computed
  get tableHeaders(): tableHeadersType[] {
    return [
      {
        isBordered: true,
        class: 'bordered',
        text: this.changedHeader.text,
        value: this.changedHeader.value,
      },
      { text: this.$t('pageAffiliateStats.tableHeader.hit'), value: 'hit' },
      { text: this.$t('pageAffiliateStats.tableHeader.click'), value: 'click' },
      {
        text: this.$t('pageAffiliateStats.tableHeader.install'),
        value: 'install',
      },
      { text: this.$t('pageAffiliateStats.tableHeader.cr'), value: 'cr' },
      {
        class: 'bordered',
        isBordered: true,
        text: this.$t('pageAffiliateStats.tableHeader.epc'),
        value: 'epc',
      },
      {
        text: this.$t('pageAffiliateStats.tableHeader.activation'),
        value: 'activation',
      },
      {
        text: this.$t('pageAffiliateStats.tableHeader.rebill'),
        value: 'rebill',
      },
      {
        class: 'bordered',
        isBordered: true,
        text: this.$t('pageAffiliateStats.tableHeader.return'),
        value: 'return',
      },
      {
        text: this.$t('pageAffiliateStats.tableHeader.conversion_all'),
        value: 'conversion_all',
      },
      {
        text: this.$t('pageAffiliateStats.tableHeader.conversion_accept'),
        value: 'conversion_accept',
      },

      {
        text: this.$t('pageAffiliateStats.tableHeader.conversion_wait'),
        value: 'conversion_wait',
      },
      {
        class: 'bordered',
        isBordered: true,
        text: this.$t('pageAffiliateStats.tableHeader.conversion_decline'),
        value: 'conversion_decline',
      },
      {
        text: this.$t('pageAffiliateStats.tableHeader.finance_all'),
        value: 'finance_all',
      },
      {
        class: 'bordered',
        isBordered: true,
        text: this.$t('pageAffiliateStats.tableHeader.finance_accept'),
        value: 'finance_accept',
      },
      {
        text: this.$t('pageAffiliateStats.tableHeader.revshare_all'),
        value: 'revshare_all',
      },
      {
        text: this.$t('pageAffiliateStats.tableHeader.revshare_accept'),
        value: 'revshare_accept',
      },
    ]
  }

  get totalHeaders(): any[] {
    const headers = cloneDeep(this.tableHeaders)
    headers.shift()
    return headers
  }

  get changedHeader(): any {
    let header = {}
    if (this.getStatsAffiliateParams.groupBy) {
      switch (this.getStatsAffiliateParams.groupBy.value) {
        case 'created':
          header = {
            text: this.$t('date'),
            value: 'date',
          }
          break
        case 'stream':
          header = {
            text: this.$t('stream'),
            value: 'stream',
          }
          break
        case 'offer':
          header = {
            text: this.$t('offer'),
            value: 'offer',
          }
          break
        case 'app':
          header = {
            text: this.$t('app'),
            value: 'app',
          }
          break
        default:
          header = {
            text: this.$t('date'),
            value: 'date',
          }
      }
    }
    return header
  }
}

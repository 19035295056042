import { HttpApiCrm } from '@/services/HttpClientService'
import {
  BaseApiService,
  EndpointsTypes,
  NotificationTypes,
  ResponseTypes,
} from '@/services/api/BaseApiService'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { CSVAppModelType } from './CSVTypes'

class CSVServise extends BaseApiService {
  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints('')
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {}

  private usedListParams = [
    'date_start',
    'date_end',
    'client',
    'payment_status',
    'app',
    'trackNumber',
  ]

  public async getCSVData({
    type,
    params,
    notification = this.notificationDefaultSettings,
  }: {
    type: string
    params: any
    notification?: NotificationTypes
  }): Promise<ResponseTypes> {
    try {
      const requestUrl = `/api/${type}/export`
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      return {
        status: true,
        data: this.transformDataForApp(response.data),
        params: {},
      }
    } catch (error) {
      if (notification.enableError) {
        this.addBadRequestNotification(
          error,
          notification.errorText ?? `${i18n.t('allertMessage.getFileError')}`
        )
      }
      return { status: false, data: {}, params: {}, error }
    }
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData): CSVAppModelType {
    const uint8 = new Uint8Array(incomingData.length)
    for (let i = 0; i < incomingData.length; i++) {
      let x = incomingData.charCodeAt(i)
      if (x >= 1040 && x <= 1103) {
        x -= 848
      } else if (x === 1025) {
        x = 168
      } else if (x === 1105) {
        x = 184
      }
      uint8[i] = x
    }
    const file = new Blob([uint8], { type: 'text/csv' })
    const url = URL.createObjectURL(file)
    return url
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    if (incomingParams.created?.length > 0) {
      params.date_start = incomingParams.created[0]
      params.date_end = incomingParams.created[1]
    }
    return params
  }
}
export default new CSVServise()

import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { Snackbar } from '@/store/types'

@Module({ namespaced: true, name: 'snackbar' })
export default class SnackbarStore extends VuexModule {
  snackbar = {
    text: '',
    color: 'red',
    timeout: 4000,
    opened: false,
    top: true,
    right: true,
    dark: true,
    multiLine: true,
  }

  get getSnackbar(): Snackbar {
    return this.snackbar
  }

  @Mutation
  callSnackbar(payload: Snackbar) {
    this.snackbar = { ...this.snackbar, ...payload }
    this.snackbar.opened = true
  }
}


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { VuexGetter, VuexAction } from '@/store/decorators'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { ChannelsAppModelType } from '@/services/api2/Channels/ChannelsTypes'
import { ResponseTypes } from '@/services/api/BaseApiService'

@Component({})
export default class ChannelItem extends Vue {
  // Props
  @Prop(Object) readonly channel!: ChannelsAppModelType

  // Data
  loading = false

  error = false

  check = false

  // vuex module action
  @VuexAction('nTariffs') channelUpdateItem!: (...args) => Promise<ResponseTypes>

  // vuex module getter
  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Computed
  get checked(): boolean {
    return this.channel.promotional_page_id === this.getPromoPageItem?.id
  }

  get disabled(): boolean | null | 0 {
    return (
      this.channel.promotional_page_id &&
      this.channel.promotional_page_id !== this.getPromoPageItem?.id
    )
  }

  // Methods
  async pickChannelHandler(): Promise<void> {
    this.loading = true
    const { status } = await this.channelUpdateItem({
      id: this.channel.id,
      data: {
        promotional_page_id: this.channel.promotional_page_id ? null : this.getPromoPageItem?.id,
      },
    })
    if (!status) {
      this.check = this.checked
      this.error = true
      setTimeout(() => {
        this.error = false
      }, 2000)
    }
    this.loading = false
  }

  created(): void {
    this.check = this.checked
  }
}

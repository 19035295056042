





import { Component, Vue, Ref } from 'vue-property-decorator'
import { VuexAction, VuexGetter } from '@/store/decorators'
import AuthDefaultContainer from '@/views/auth/components/AuthDefaultContainer.vue'

import { UserAppModelType } from '@/services/api/User/UserTypes'
import AuthProvider from '@/providers/AuthProvider'

@Component({
  components: {
    AuthDefaultContainer,
  },
})
export default class GptPage extends Vue {
  @VuexGetter('nApp') getAuthUser!: UserAppModelType

  async created() {
    console.log('this is GPT')
    if (this.getAuthUser) {
      const tokens = {
        API_TOKEN: AuthProvider.getToken('crm'),
        CHAT_TOKEN: AuthProvider.getToken('pwa'),
      }
      // TODO Отправляю данные в билдер
      window.parent.postMessage(tokens, '*')
      // await Promise.all([AuthProvider.logout()])
    } else {
      this.$router.push({ name: 'auth.register' })
    }
  }
}

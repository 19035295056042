































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { VuexAction, VuexGetter } from '@/store/decorators'
import PostsPicker from '@/components/PostsPicker.vue'
import AppPreview from '@/components/appPreview/AppPreview.vue'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { cloneDeep } from 'lodash'
import AppVTextField from './app/AppVTextField.vue'
import AppVRadioGroup from './app/AppVRadioGroup.vue'

@Component({
  components: { PostsPicker, AppPreview, AppVTextField, AppVRadioGroup },
})
export default class AppSettings extends Vue {
  // Props
  @Prop([String] || String) readonly classList!: string[] | string

  // Data
  dialog = false

  mode = ''

  selectedPosts: PostsAppModelType[] = []

  index = 0

  loading = false

  data: {
    avatar: PostsAppModelType | null
    posts: PostsAppModelType[]
  } = {
    avatar: null,
    posts: [],
  }

  dataGpt: {
    emptyBotChatMessage: string
    prompts: string[]
    botLimit: number | null
    botLimitType: number | null
  } = {
    emptyBotChatMessage: '',
    prompts: [],
    botLimit: null,
    botLimitType: null,
  }

  //  Vuex module getters
  @VuexGetter('nApp') getCurrentApp!: any

  @VuexGetter('nApp') getAppPreviews!: PostsAppModelType[]

  @VuexGetter('nApp') getIsGptApp!: boolean

  @VuexAction('nApp') updateApp!: (...args) => Promise<void>

  get valid(): boolean {
    if (this.getIsGptApp) {
      return (
        !!this.dataGpt.emptyBotChatMessage &&
        !!this.dataGpt.botLimit &&
        this.dataGpt.botLimit > 0 &&
        this.dataGpt.botLimit <= 10
      )
    }
    return true
  }
  // Methods

  selectPost({ item, index }: { item: PostsAppModelType; index: number }): void {
    this.data.posts.splice(index, 1, item)
    this.selectedPosts.splice(0, 1, item)
    this.index = index
  }

  setMode(): void {
    this.mode = this.getIsGptApp ? 'message' : 'avatar'
  }

  clear(): void {
    this.selectedPosts = []
    this.data.avatar = null
    this.data.posts = cloneDeep(this.getAppPreviews)
    this.setDataForGpt()
  }

  async saveChanges(): Promise<void> {
    this.loading = true
    await this.updateApp({
      data: this.dataGpt,
      id: this.getCurrentApp.id,
    })
    this.dialog = false
    this.loading = false
  }

  @Watch('selectedPosts')
  selectedPostsHandler(): void {
    if (this.mode === 'avatar' && this.selectedPosts[0]) {
      this.data.avatar = this.selectedPosts[0]
    }
    if (this.mode === 'posts' && this.selectedPosts[0]) {
      this.data.posts.splice(this.index, 1, this.selectedPosts[0])
    }
  }

  @Watch('mode')
  modeHandler(): void {
    this.selectedPosts = []
    if (this.mode === 'avatar' && this.data.avatar) {
      this.selectedPosts[0] = this.data.avatar
    }
  }

  @Watch('dialog')
  dialogHandler(): void {
    if (!this.dialog) {
      this.clear()
    } else {
      this.setMode()
      this.setDataForGpt()
      this.data.posts = cloneDeep(this.getAppPreviews)
    }
  }

  setDataForGpt(): void {
    this.dataGpt.emptyBotChatMessage = this.getCurrentApp?.emptyBotChatMessage ?? ''
    this.dataGpt.prompts = cloneDeep(this.getCurrentApp?.prompts)
    this.dataGpt.botLimit = this.getCurrentApp?.botLimit ?? null
    this.dataGpt.botLimitType = this.getCurrentApp?.botLimitType ?? null
  }
}

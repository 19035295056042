





































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { StatsAffiliateTotalAppModelType } from '@/services/api/Stat/StatTypes'

@Component({})
export default class AffiliateStatisticsTopCards extends Vue {
  // Vuex module getter
  @VuexGetter('nAffiliateStatistics') getStatsAffiliateTotalData!: StatsAffiliateTotalAppModelType
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-data-table',{staticClass:"transactions-table",attrs:{"pages":_vm.getTransactionsListParams.pages,"currentPage":_vm.getTransactionsListParams.page,"items":_vm.getTransactionsListItems,"headers":_vm.tableHeaders,"loading":_vm.getTransactionsListLoading},on:{"input":_vm.tableChangePage},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.setDateFormatForTable(item.created, 'DD MMMM YYYY')))]),_c('div',{staticClass:"transactions-table__time"},[_vm._v(" "+_vm._s(_vm.setDateFormatForTable(item.created, 'HH:mm'))+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v("№"+_vm._s(item.id))]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.client.id ? item.client.id : ("" + (_vm.$t('notSpecified')))))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.order)?[_vm._v(" "+_vm._s(item.order.sum)+" "+_vm._s(_vm.getAppCurrencySymbol)+" ")]:[_vm._v(" - ")]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("transactions.status." + (item.status))))+" ")]}},{key:"item.error_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.errorCode ? ((_vm.$t('error')) + " " + (item.errorCode)) : '-')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"actions align-center"},[_c('v-col',{staticClass:"text-center",on:{"click":function($event){return _vm.openPopUpDetails(item.id)}}},[_c('icon',{attrs:{"data":require("@/assets/icons/sorting-icon.svg")}})],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
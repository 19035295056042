
















import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'

@Component({})
export default class PromoPagePopUpView extends Vue {
  // vuex module getter
  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @Emit('closePromoPagePopUpView')
  closePromoPagePopUpView(): boolean {
    return false
  }
}






















































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class PayoutSystemsTable extends Vue {
  // Props
  @Prop({ required: true, type: Array }) readonly tableItems!: []

  @Prop(Boolean) readonly loading!: boolean

  // Data
  dialog = {}

  // Computed
  get headers(): { value: string }[] {
    return [{ value: 'id' }, { value: 'name' }, { value: 'description' }, { value: 'action' }]
  }

  // Emits
  @Emit()
  deleteAccount(id: number): number {
    return id
  }
}

import { render, staticRenderFns } from "./ServicesList.vue?vue&type=template&id=7da3f8d4&"
import script from "./ServicesList.vue?vue&type=script&lang=js&"
export * from "./ServicesList.vue?vue&type=script&lang=js&"
import style0 from "./ServicesList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VRow})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})

import { MediaObjectsActions } from '@/store/modules/mediaObjects/types'
import MediaObjectsService from '@/services/api/MediaObjects/MediaObjectsService'

const actions: MediaObjectsActions = {
  async createMediaObject({ commit }, { data = {}, params = {} }) {
    const response = await MediaObjectsService.createItem({ data, params })
    return response
  },
}

export default actions





















import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexMutation, VuexGetter } from '@/store/decorators'
import { filterDataType } from '@/views/affiliate/streams/types'
import StreamsFilter from '@/views/affiliate/streams/components/StreamsFilter.vue'
import StreamsTable from '@/views/affiliate/streams/components/StreamsTable.vue'
import StreamsPopUpDelete from '@/views/affiliate/streams/components/StreamsPopUpDelete.vue'

@Component({
  components: {
    StreamsFilter,
    StreamsTable,
    StreamsPopUpDelete,
  },
})
export default class Streams extends Vue {
  // Data
  isPreloaderShow = false

  // Vuex module action
  @VuexAction('nAffiliateStreams') streamsGetItemsForTable!: () => Promise<void>

  @VuexAction('nAffiliateStreams') streamsGetItemsForFilter!: () => Promise<void>

  @VuexAction('nAffiliateStreams') streamsGetItem!: (...args) => Promise<void>

  @VuexAction('nAffiliateStreams') streamsDeleteItem!: (...args) => Promise<void>

  // Vuex module mutation
  @VuexMutation('nAffiliateStreams') streamsForTableParamsUpdate!: (...args) => void

  @VuexMutation('nAffiliateStreams') streamsForFilterParamsUpdate!: (...args) => void

  @VuexMutation('nAffiliateStreams') streamsDeletePopUpChangeStatus!: (...args) => void

  // Vuex module getters
  @VuexGetter('nApp') getAuthUser!: any // TODO Старый модуль для получения user

  // Methods
  tableChangePage(page: number): void {
    this.streamsForTableParamsUpdate({ page })
    this.streamsGetItemsForTable()
  }

  async filter(filterData: filterDataType): Promise<void> {
    this.streamsForTableParamsUpdate({ ...filterData, page: 1 })
    this.streamsGetItemsForTable()
  }

  async streamDelete(id: number): Promise<void> {
    await this.streamsDeleteItem({ id })
    this.streamsGetItemsForTable()
    this.streamsGetItemsForFilter()
    this.streamsDeletePopUpChangeStatus(false)
  }

  async openDeletePopUp(id: number): Promise<void> {
    await this.streamsGetItem(id)
    this.streamsDeletePopUpChangeStatus(true)
  }

  closeDeletePopUp(): void {
    this.streamsDeletePopUpChangeStatus(false)
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    this.streamsForTableParamsUpdate({ user: this.getAuthUser.id })
    await this.streamsGetItemsForTable()
    this.streamsForFilterParamsUpdate({ user: this.getAuthUser.id, pagination: false })
    await this.streamsGetItemsForFilter()
    this.isPreloaderShow = false
  }
}

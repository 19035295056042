











































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { filterDataType } from '@/views/affiliate/offers/types'
import { AppsHasOfferAppModelType } from '@/services/api/Apps/AppsTypes'
import { ОfferCategoriesAppModelType } from '@/services/api/ОfferCategories/ОfferCategoriesTypes'
import { OfferTotalAppModelType } from '@/services/api/Offer/OfferTypes'

@Component({})
export default class OffersListFilter extends Vue {
  // Data
  filterData: filterDataType = {
    group: 'all',
    app: null,
    countries: null,
    offerCategory: null,
    revshare: null,
  }

  @VuexGetter('nAffiliateOffers') getOffersParams!: any

  @VuexGetter('nApp') getAuthUser!: any // TODO Старый модуль для получения user

  @VuexGetter('nAffiliateOffers') getAppsHasOfferItems!: [] | AppsHasOfferAppModelType[]

  @VuexGetter('nAffiliateOffers') getOfferCategoriesItems!: [] | ОfferCategoriesAppModelType[]

  @VuexGetter('nAffiliateOffers') getOffersTotal!: OfferTotalAppModelType

  // Methods
  setSavedFilterData(): void {
    const keysFilterData = Object.keys(this.filterData)
    keysFilterData.forEach((i) => {
      if (this.getOffersParams[i]) {
        this.filterData[i] = this.getOffersParams[i]
      }
    })
  }

  filterHandler(): void {
    setTimeout(() => {
      this.filter()
    }, 100)
  }

  // Hooks
  created(): void {
    this.setSavedFilterData()
  }

  // Emit
  @Emit()
  filter(): filterDataType {
    return this.filterData
  }
}

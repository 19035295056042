import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { PaymentSystemsAppModelType, PaymentSystemsApiModelType } from './PaymentSystemsTypes'

class PaymentSystemsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.paymentSystems')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: PaymentSystemsAppModelType = {
    id: 0,
    name: '',
    description: '',
    status: 0,
    created: '',
    updated: '',
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PAYMENT_SYSTEMS)
  }

  public getEmptyItem(): PaymentSystemsAppModelType {
    const data = cloneDeep(this.AppModelPropsDefault)
    return data
  }

  public transformDataForApi(incomingData): PaymentSystemsApiModelType {
    return incomingData
  }

  public transformDataForApp(incomingData): PaymentSystemsAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }
}
export default new PaymentSystemsService()




































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { PostbackLogsAppModelType } from '@/services/api/Postback/PostbackTypes'

@Component({})
export default class PostbackLogTable extends Vue {
  // Data
  moment: any

  // Computed
  get tableHeaders(): any {
    return [
      { text: 'ID', value: 'id' },
      { text: this.$t('postbackLogs.dateReuest'), value: 'created' },
      { text: this.$t('status'), value: 'status' },
      { text: this.$t('postbackLogs.responceCode'), value: 'code' },
      { text: 'URL', value: 'url' },
    ]
  }

  // vuex module getter
  @VuexGetter('nLogsPostback') getLogsPostbackListItems!: Array<PostbackLogsAppModelType>

  @VuexGetter('nLogsPostback') getLogsPostbackListLoading!: boolean

  @VuexGetter('nLogsPostback') getLogsPostbackListParams!: any

  // Emits
  @Emit('changePageTable')
  changePageTable(page: number): number {
    return page
  }
}

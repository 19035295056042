
































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { TariffAppModelType } from '@/services/api/Tariff/TariffTypes'

import { filterDataType } from '../types'

@Component({})
export default class ManagmentFilter extends Vue {
  money: any

  // Data
  filterData: filterDataType = {
    status: null,
    orderId: null,
    client: null,
    tariff: null,
    created: [],
  }

  // vuex module getter
  @VuexGetter('nBusinessManagment') getSubscriptionStatuses!: boolean

  @VuexGetter('nBusinessManagment') getSubscriptionListParams!: any

  @VuexGetter('nBusinessManagment') getTariffListItems!: Array<TariffAppModelType>

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  // Methods
  setSavedFilterData(): void {
    const keysFilterData = Object.keys(this.filterData)
    keysFilterData.forEach((i) => {
      if (this.getSubscriptionListParams[i]) {
        this.filterData[i] = this.getSubscriptionListParams[i]
      }
    })
  }

  filterHandler(): void {
    setTimeout(() => {
      this.filter()
    }, 100)
  }

  // Hooks
  created(): void {
    this.setSavedFilterData()
  }

  // Emit
  @Emit()
  filter(): filterDataType {
    return this.filterData
  }
}

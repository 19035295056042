














































import { Component, Watch, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'

import ColClients from './components/ColClients.vue'
import ColDialog from './components/ColDialog.vue'
import ColInfo from './components/ColInfo.vue'

import ClientsHeader from './components/ClientsHeader.vue'
import ClientsFilter from './components/ClientsFilter.vue'
import ClientsList from './components/ClientsList.vue'
import GroupChannels from './components/GroupsChannels.vue'

import DialogHeader from './components/DialogHeader.vue'
import DialogMessages from './components/DialogMessages.vue'
import DialogControlPanel from './components/DialogControlPanel.vue'

import InfoHeader from './components/InfoHeader.vue'
import InfoContent from './components/InfoContent.vue'

// import LocMosaicPreviews from './components/_LocMosaicPreviews.vue' // TODO для теста

@Component({
  components: {
    ColClients,
    ColDialog,
    ColInfo,

    ClientsHeader,
    ClientsFilter,
    ClientsList,
    GroupChannels,

    DialogHeader,
    DialogMessages,
    DialogControlPanel,

    InfoHeader,
    InfoContent,

    // LocMosaicPreviews, // TODO для теста
  },
})
export default class Dialogs extends Vue {
  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nApp') getAuthClient!: any

  @VuexGetter('nChats', 'getDialog') dialog!: any

  @VuexGetter('nApp') getAppId!: boolean

  @VuexGetter('nChats') getSupportChannel!: any

  @VuexMutation('nChats') clientsListUpdateParams!: (any) => void

  @VuexMutation('nChats') clientsListSetNextPage!: () => void

  @VuexMutation('nChats') dialogControlFormAddMedia!: (any) => void

  @VuexMutation('nChats') dialogControlFormRemoveMedia!: (index) => void

  @VuexAction('auth') fetchClient!: any // TODO Старый модуль для получения своего clientId

  @VuexAction('nChats') channelsGetItemsByNewMessages!: (any) => Promise<boolean>

  @VuexAction('nChats') createSupportChannel!: () => Promise<boolean>

  @VuexAction('nChats') chatsInit!: (any) => Promise<boolean>

  @VuexAction('nChats') clientsGetItems!: () => Promise<boolean>

  @VuexAction('nChats') clientsGetItem!: (id) => Promise<boolean>

  @VuexAction('nChats') clientsChangeFilter!: () => Promise<boolean>

  @VuexAction('nChats') loadDialog!: (fn: {
    channel?: any
    client?: string
    support?: string
  }) => Promise<boolean>

  @VuexAction('nChats') messageCreate!: () => Promise<boolean>

  @VuexAction('nChats') messageDelete!: () => Promise<boolean>

  @VuexAction('nChats') messagesGetItemsNextPage!: () => Promise<boolean>

  async onScrollLoadClients(): Promise<void> {
    this.clientsListSetNextPage()
    await this.clientsGetItems()
  }

  async onScrollLoadMessages(): Promise<void> {
    await this.messagesGetItemsNextPage()
  }

  async openDialogByQueryClientId(): Promise<void> {
    if ('client_id' in this.$route.query) {
      const clientId = this.$route.query.client_id
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$router.replace({ query: null })
      await this.clientsGetItem(clientId)
      await this.loadDialog({ client: this.dialog.client })
    }
  }

  async openDialogIfQuerySupport(): Promise<void> {
    if ('support' in this.$route.query && typeof this.$route.query.support === 'string') {
      const { support } = this.$route.query
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$router.replace({ query: null })
      if (!this.getSupportChannel) {
        await this.createSupportChannel()
      }
      await this.loadDialog({ channel: this.getSupportChannel, support })
    }
  }

  @Watch('getCurrentApp', { immediate: true, deep: true })
  scrollEndAfterSubmit(value, oldValue): void {
    if (oldValue !== undefined && oldValue.id !== value.id) {
      this.chatsInit({ appId: this.getCurrentApp?.id, meClientId: this.getAuthClient.id })
    }
  }

  async created(): Promise<void> {
    this.$root.$emit('sectionChange', 'crm')

    await this.chatsInit({ appId: this.getCurrentApp?.id, meClientId: this.getAuthClient.id })
    await this.openDialogByQueryClientId()
    await this.openDialogIfQuerySupport()
  }
}





























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { VuexGetter, VuexAction } from '@/store/decorators'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import MediaObjectsService from '@/services/api/MediaObjects/MediaObjectsService'

@Component({})
export default class ChannelCreate extends Vue {
  // Props
  @Prop(Boolean) readonly isOpenCreateForm!: boolean

  // Data

  dataForCreateChannel = {
    name: '',
    img: null,
  }

  isChannelCreateLoading = false

  // vuex module action
  @VuexAction('nTariffs') channelCreateItem!: (...args) => Promise<boolean>

  // vuex module getter
  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Methods

  async createNewChannel(): Promise<void> {
    this.isChannelCreateLoading = true
    const mediaObject: any = await MediaObjectsService.createItem({
      params: {},
      data: this.dataForCreateChannel.img,
    })
    if (mediaObject.status) {
      await this.channelCreateItem({
        data: {
          app_id: this.getCurrentApp.id,
          image: `/api/media_objects/${mediaObject.data.id}`,
          name: this.dataForCreateChannel.name,
        },
      })
    }
    this.isChannelCreateLoading = false
    this.dataForCreateChannel.name = ''
    this.dataForCreateChannel.img = null
    this.closeFormHandler()
  }

  @Emit('closeForm')
  closeFormHandler(): boolean {
    return false
  }
}

<template>
  <article v-show="!getPreloaderLoadingStatus" class="page--products-create">
    <page-header extra>
      <h4 class="d-inline-flex align-center">
        <icon
          class="mr-8"
          color="black"
          data="@/assets/icons/chevron-left.svg"
          height="32"
          width="16"
          @click="$router.push(`/business/goods_and_services/products`)"
        />
        {{ $t('product.addTitle') }}
      </h4>
    </page-header>
    <products-form mode="create" />
  </article>
</template>
<script>
import Vue from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import ProductsForm from '@/views/business/goodsAndServices/products/components/ProductsForm.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'ProductsCreate',
  components: { PageHeader, ProductsForm },
  data: () => ({}),
  computed: {
    ...mapGetters('nApp', ['getPreloaderLoadingStatus']),
  },
})
</script>

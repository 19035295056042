import { getInstanceWithToken } from '@/utils/axios'
import { VerificationActions } from './types'

const actions: VerificationActions = {
  async sendVerification({ commit }, payload) {
    try {
      const response = await getInstanceWithToken().post('/api/user_verifications', payload)
    } catch (error) {
      console.log(error)
    }
  },
  async updateVerification({ commit }, { id, data, status }) {
    try {
      const response = await getInstanceWithToken().put(`/api/user_verifications/${id}`, {
        data,
        status,
      })
      commit('setVerification', response.data)
    } catch (error) {
      console.log(error)
    }
  },
  async getVerifications({ commit }, id) {
    try {
      const response = await getInstanceWithToken().get(
        `/api/user_verifications?user=${id}&page=1&itemsPerPage=30`
      )
      commit('setVerification', response.data[0] ? response.data[0] : null)
    } catch (error) {
      console.log(error)
    }
  },
}

export default actions











































import { Component, Vue, Ref } from 'vue-property-decorator'
import { VuexGetter, VuexMutation, VuexAction } from '@/store/decorators'
import { ChannelsAppModelType } from '@/services/api2/Channels/ChannelsTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import ChannelItem from './components/ChannelItem.vue'
import ChannelCreate from './components/ChannelCreate.vue'

@Component({
  components: {
    ChannelItem,
    ChannelCreate,
  },
})
export default class Step4 extends Vue {
  // Ref
  @Ref('scroll') readonly scroll!: any

  // Data

  isChannelsFetchLoading = false

  isOpenCreateForm = false

  page = 1

  // vuex module action
  @VuexAction('nTariffs') channelsGetItems!: () => Promise<boolean>

  // vuex module mutation

  @VuexMutation('nTariffs') channelsParamsUpdate!: (...args) => void

  // vuex module getter
  @VuexGetter('nTariffs') getFormData!: any

  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nTariffs') getStopFetchChannels!: boolean

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nTariffs') getChannelsItems!: ChannelsAppModelType[]

  // Methods

  openCreateForm(): void {
    this.isOpenCreateForm = true
    const el = this.scroll.$el
    setTimeout(() => {
      el.scrollTop = el.scrollHeight
    }, 200)
  }

  closeCreateForm(): void {
    this.isOpenCreateForm = false
  }

  async fetchChannels(): Promise<void> {
    if (!this.getStopFetchChannels) {
      this.page++
      this.isChannelsFetchLoading = true
      this.channelsParamsUpdate({
        page: this.page,
      })
      await this.channelsGetItems()
      this.isChannelsFetchLoading = false
    }
  }

  // Hooks
  async created(): Promise<void> {
    this.channelsParamsUpdate({
      limit: 30,
      app_id: this.getCurrentApp.id,
      type: [1, 2],
    })
    await this.channelsGetItems()
  }
}
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AppPage extends Vue {
  @Prop({ default: '' }) readonly name!: string

  @Prop({ default: true }) readonly contentOffset!: boolean

  @Prop({ default: false }) readonly isPreloaderShow!: boolean

  @Prop({ default: '' }) readonly headerClass!: string
}
























































































































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { CurrenciesAppModelType } from '@/services/api/Currencies/CurrenciesTypes'

@Component
export default class StatTable extends Vue {
  // VueX Getters
  @VuexGetter('nBusinessReferral') getReferralPageStats!: any

  @VuexGetter('nDeskbook') getListCurrencies!: CurrenciesAppModelType[]

  // Computed
  get referalCountForNextLevel(): number {
    return this.getReferralPageStats?.next_level_rules?.active_referrals ?? 0
  }

  get nextLevel(): number {
    return this.getReferralPageStats?.levels?.length + 1 ?? 0
  }
  // Methods

  getCurrentCurrency(id: number): string {
    return this.getListCurrencies.find((item) => item.id === id)?.symbol ?? '-'
  }

  getFieldValue(index: number, field: string): string | number {
    return this.getReferralPageStats?.levels[index - 1]?.[field] ?? '-'
  }

  getLocked(index: number): boolean {
    return index - 1 > this.getReferralPageStats.levels.length
  }
}

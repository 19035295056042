import Centrifuge from 'centrifuge'

const getCentrifugeInstance = (options: Centrifuge.Options = {}): Centrifuge => {
  const instance = new Centrifuge(process.env.VUE_APP_SOCKET_URL || '', options)
  instance.setToken(<string>localStorage.getItem('CHAT_TOKEN'))

  return instance
}

export default getCentrifugeInstance

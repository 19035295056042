import ОfferService from '@/services/api/Offer/OfferService'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'
import ОfferSourcesService from '@/services/api/ОfferSources/ОfferSourcesService'
import { ОfferSourcesAppModelType } from '@/services/api/ОfferSources/ОfferSourcesTypes'
import ОfferCategoriesService from '@/services/api/ОfferCategories/ОfferCategoriesService'
import { ОfferCategoriesAppModelType } from '@/services/api/ОfferCategories/ОfferCategoriesTypes'
import i18n from '@/plugins/i18n'
import { TranslateResult } from 'vue-i18n'
import {
  nAffiliateMarketingMutations,
  nAffiliateMarketingActions,
  nAffiliateMarketingGetters,
  nAffiliateMarketingModule,
} from './types'

const actions: nAffiliateMarketingActions = {
  async offerGetItems({ commit, state }): Promise<boolean> {
    const response = await ОfferService.getItems({
      params: state.lists.offer.params,
    })
    if (response.status) {
      commit('offerListItemsUpdate', response.data)
    }
    return response.status
  },
  async offerSourcesGetItems({ commit, state }): Promise<boolean> {
    const response = await ОfferSourcesService.getItems({
      params: state.lists.offerSources.params,
    })
    if (response.status) {
      commit('offerSourcesListItemsUpdate', response.data)
    }
    return response.status
  },
  async offerCategoriesGetItems({ commit, state }): Promise<boolean> {
    const response = await ОfferCategoriesService.getItems({
      params: state.lists.offerCategories.params,
    })
    if (response.status) {
      commit('offerCategoriesListItemsUpdate', response.data)
    }
    return response.status
  },
  async offerUpdateItem({ commit }, { data, id }): Promise<boolean> {
    const response = await ОfferService.updateItem({ data, id })
    return response.status
  },
  async offerCreateItem({ commit }, data): Promise<boolean> {
    const response = await ОfferService.createItem({ data, params: {} })
    return response.status
  },
}

const mutations: nAffiliateMarketingMutations = {
  offerListParamsUpdate(state, params): void {
    state.lists.offer.params = { ...state.lists.offer.params, ...params }
  },
  offerListItemsUpdate(state, items: OfferAppModelType): void {
    state.lists.offer.items = items
  },
  offerSourcesListParamsUpdate(state, params): void {
    state.lists.offerSources.params = { ...state.lists.offerSources.params, ...params }
  },
  offerSourcesListItemsUpdate(state, items: ОfferSourcesAppModelType): void {
    state.lists.offerSources.items = items
  },
  offerCategoriesListParamsUpdate(state, params): void {
    state.lists.offerCategories.params = { ...state.lists.offerCategories.params, ...params }
  },
  offerCategoriesListItemsUpdate(state, items: ОfferCategoriesAppModelType): void {
    state.lists.offerCategories.items = items
  },
}

const getters: nAffiliateMarketingGetters = {
  getOfferListItems(state): [] | [OfferAppModelType] {
    return state.lists.offer.items
  },
  getOfferSourcesListItems(state): [] | [ОfferSourcesAppModelType] {
    return state.lists.offerSources.items
  },
  getOfferCategoriesListItems(state): [] | [ОfferCategoriesAppModelType] {
    return state.lists.offerCategories.items
  },
  getLangs(): [{ value: string; name: TranslateResult | string }] | unknown {
    return [
      { text: i18n.t('russian'), value: 'ru' },
      { text: i18n.t('english'), value: 'en' },
      { text: i18n.t('german'), value: 'de' },
    ]
  },
  getTargetAudience(): [{ value: string; name: string }] | unknown {
    return [
      { name: '< 18', value: '' },
      { name: '18-24', value: '' },
      { name: '25-40', value: '' },
      { name: '41-60', value: '' },
      { name: '60+', value: '' },
    ]
  },
}

const nAffiliateMarketing: nAffiliateMarketingModule = {
  state: () => ({
    lists: {
      offer: {
        items: [],
        params: ОfferService.getDefaultParams(),
      },
      offerSources: {
        items: [],
        params: ОfferSourcesService.getDefaultParams(),
      },
      offerCategories: {
        items: [],
        params: ОfferCategoriesService.getDefaultParams(),
      },
    },
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nAffiliateMarketing

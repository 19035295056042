<template>
  <section class="numbered-title">
    <div class="section-number d-flex align-center align-self-stretch justify-center">
      {{ number }}
    </div>
    <div :style="{ width: width }" class="desc-block">
      <div
        class="text--primary-base font-weight-bold offer-section__title mb-1 d-flex align-center"
      >
        {{ title }}
        <v-tooltip v-if="withHelper" color="primary" top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <span class="help-circle ml-2">?</span>
            </div>
          </template>
          <span class="text-xsmall">{{ helperText }}</span>
        </v-tooltip>
      </div>
      <div class="d-flex justify-space-between desc-block">
        <div class="text-subtitle-2 font-weight-regular text--primary-base">
          {{ text }}
        </div>
        <div class="float-right text-subtitle-2 font-weight-regular addition">
          {{ additionText }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import i18n from '@/plugins/i18n'

export default Vue.extend({
  name: 'AppNumberedTitle',
  props: {
    number: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    additionText: {
      type: String,
    },
    withHelper: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    helperText: {
      type: String,
      default: i18n.t('soon'),
    },
  },
})
</script>
<style lang="scss">
.numbered-title {
  display: flex;
  align-items: center;
  padding: 24px 40px;
  background: var(--ground-light);
  box-shadow: inset 0px -1px 0px var(--light-gray), inset 0px 1px 0px var(--light-gray) !important;

  &__title {
    font-size: 22px;
    line-height: 28px;
  }
  &._bt-none {
    box-shadow: inset 0px -1px 0px var(--light-gray) !important;
  }
  &.br {
    box-shadow: inset 0px -1px 0px var(--light-gray), inset 0px 1px 0px var(--light-gray),
      inset -1px 0px 0px var(--light-gray) !important;
  }

  &.first {
    box-shadow: inset 0px -1px 0px var(--light-gray) !important;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  .section-number {
    min-width: 24px;
    margin-right: 16px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background: var(--v-primary-base);
    border-radius: 8px;
  }

  .desc-block {
    width: 100%;
  }

  .addition {
    color: var(--primary-light);
  }
}
</style>







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'

@Component({
  components: {},
})
export default class PostPreview extends Vue {
  // Props
  @Prop({ required: true, type: Object }) readonly post!: PostsAppModelType | null

  @Prop({ required: true, type: Object }) readonly app!: AppsAppModelType

  @Prop({ default: '100%', type: String }) readonly width!: string

  @Prop({ default: '100%', type: String }) readonly height!: string

  @Prop({ default: false, type: Boolean }) readonly counters!: boolean

  @Prop({ default: false, type: Boolean }) readonly showAll!: boolean

  get isCommerce(): null | boolean {
    return this.post?.commerce ?? null
  }

  get blurContentUrl(): string | null {
    return this.post?.media_objects[0]?.contentBlurUrl ?? null
  }

  get blurPreviewUrl(): string | null {
    return this.post?.media_objects[0]?.previewBlurUrl ?? null
  }

  get anyCommerceUrl(): string {
    return this.blurPreviewUrl ?? this.blurContentUrl ?? '//placehold.jp/200x200.png'
  }

  get contentUrl(): string | null {
    return this.post?.media_objects[0]?.content_url ?? null
  }

  get isMulti(): boolean | undefined {
    return this.post?.media_objects?.length && this.post?.media_objects?.length > 1
  }

  get anyUrl(): string {
    return this.previewUrl ?? this.contentUrl ?? '//placehold.jp/200x200.png'
  }

  get isVideo(): boolean {
    return this.post?.media_objects?.some((media) => media.mime.includes('video')) ?? false
  }

  get isConvert(): boolean {
    return (
      this.post?.media_objects?.some((media) => media.convert_status && media.preview_url) ?? false
    )
  }

  get color(): string {
    return this.app?.primary_color ?? '#EDD031'
  }

  get previewUrl(): string | null {
    return this.post?.media_objects[0]?.preview_url ?? null
  }

  get comments(): number {
    return this.post?.comment_count ?? 0
  }

  get likes(): number {
    return this.post?.like_count ?? 0
  }
}

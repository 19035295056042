var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-v-card',[_c('div',{staticClass:"pa-6 d-flex justify-space-between"},[_c('div',{},[_c('h3',{staticClass:"mb-2 text-large text-primary lh-28"},[_vm._v(" "+_vm._s(_vm.$t('pageBusinessDigital.promo.header'))+" ")]),_c('span',{staticClass:"mr-4 text-primary lh-22"},[_vm._v(_vm._s(_vm.$t('pageBusinessDigital.promo.desc')))])]),_c('v-btn',{staticClass:"text-white align-self-end",attrs:{"color":"var(--accent-green)"},on:{"click":_vm.openPromoPagePopUpForCreate}},[_vm._v(" "+_vm._s(_vm.$t('pageBusinessDigital.promo.addBtn'))+" ")])],1),_c('app-data-table',{staticClass:"promo-pages-table",attrs:{"items":_vm.getPromoPagesItems,"headers":_vm.tableHeaders,"loading":_vm.getPromoPageLoading,"pages":_vm.getPromoPagesParams.pages,"currentPage":_vm.getPromoPagesParams.page},on:{"input":_vm.tableChangePage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name ? item.name : ((_vm.$t('promoPage')) + "# " + (item.id))))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" "+_vm._s(_vm.getAppCurrencySymbol)+" ")]}},{key:"item.tariffs",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"promo-pages-table__tariffs"},_vm._l((item.tariffs.slice().reverse()),function(tariff,i){return _c('span',{key:i},[_vm._v(_vm._s(tariff.amount)+" "+_vm._s(_vm.getAppCurrencySymbol)+" / "+_vm._s(_vm.$t(("pageBusinessDigital.tariffNew." + (tariff.period))))+" ")])}),0)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(item.active ? item.active : 0)+" ")])]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-end"},[(item.sum)?_c('b',[_vm._v(_vm._s(item.sum)+" "+_vm._s(_vm.getAppCurrencySymbol))]):_c('b',[_vm._v(_vm._s(0)+" "+_vm._s(_vm.getAppCurrencySymbol))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex pr-0"},[_c('div',{staticClass:"d-flex align-center justify-center flex-grow-1 _bl",on:{"click":function($event){return _vm.openPromoPagePopUpView(item)}}},[_c('icon',{attrs:{"color":"var(--accent-green)","data":require("@/assets/icons/eye.svg")}})],1),_c('div',{staticClass:"d-flex align-center justify-center flex-grow-1 _bl",on:{"click":function($event){return _vm.openPromoPagePopUpForEdit(item.id)}}},[(_vm.getPromoPageLoadingItem && _vm.selectedItemId === item.id)?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","width":"2","size":16}}):_c('icon',{attrs:{"color":"var(--accent-green)","data":require("@/assets/icons/edit-pencil.svg")}})],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
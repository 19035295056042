














































import { Component, Emit, Vue } from 'vue-property-decorator'
import { tableHeadersType } from '@/views/affiliate/streams/types'
import { VuexGetter } from '@/store/decorators'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import NotificationProvider from '@/providers/NotificationProvider'

@Component({})
export default class StreamsTable extends Vue {
  money: any

  // Vuex module getter
  @VuexGetter('nAffiliateStreams') getStreamsForTableItems!: [] | StreamAppModelType[]

  @VuexGetter('nAffiliateStreams') getStreamsForTableParams!: any

  @VuexGetter('nAffiliateStreams') getStreamsListLoading!: boolean

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  // Computed
  get tableHeaders(): Array<tableHeadersType> {
    return [
      { text: `ID ${this.$t('flow')}`, value: 'id' },
      { text: this.$t('offer'), value: 'offerName' },
      { text: this.$t('streamName'), value: 'streamName' },
      { text: this.$t('link'), value: 'link' },
      { text: this.$t('income'), value: 'income' },
      { text: '', value: 'actions' },
    ]
  }

  copyLink(): void {
    NotificationProvider.add({
      type: 'success',
      message: this.$t('copyToBuffer'),
    })
  }

  goToEditStream(item: StreamAppModelType): void {
    this.$router.push({
      path: `/affiliate/offers/${item.offer.id}`,
      query: { stream_id: `${item.id}` },
    })
  }

  // Emit
  @Emit('tableChangePage')
  tableChangePage(page: number): number {
    return page
  }

  @Emit('openDeletePopUp')
  openDeletePopUp(id: number): number {
    return id
  }
}

import StoresService from '@/services/api/Stores/StoresService'
import DeliveryMethodsService from '@/services/api/DeliveryMethods/DeliveryMethodsService'
import DeliveryRegionsService from '@/services/api/DeliveryRegions/DeliveryRegionsService'
import DeliveryServicesService from '@/services/api/DeliveryServices/DeliveryServicesService'
import { StoresActions } from '@/store/modules/stores/types'

const actions: StoresActions = {
  loadStoreFormProps({ commit }): void {
    commit('setStoreItem', { data: StoresService.getEmptyItem() })
  },
  async loadStores({ commit }, { params = {} }) {
    const response = await StoresService.getItems({ params })
    if (response.status) {
      commit('setStoresListItems', { response })
    } else {
      commit('test', 'error')
    }
    return response
  },
  async loadStore({ commit }, { id = 0, params = {} }) {
    const response = await StoresService.getItem({ id })
    if (response) {
      commit('setStoreItem', { data: response.data })
    } else {
      commit('test', 'error')
    }
    return response
  },
  async createStore({ commit }, { data = {}, params = {} }) {
    const response = await StoresService.createItem({ data, params })
    return response
  },
  async updateStore({ commit }, { data = {}, params = {} }) {
    const response = await StoresService.updateItem({ data, id: data.id })
    return response
  },
  async loadDeliveryMethods({ commit }, { params = {} }) {
    const response = await DeliveryMethodsService.getItems({ params })
    return response
  },
  async loadDeliveryServices({ commit }, { params = {} }) {
    const response = await DeliveryServicesService.getItems({ params })
    return response
  },
  async loadDeliveryRegions({ commit }, { params = {} }) {
    const response = await DeliveryRegionsService.getItems({ params })
    return response
  },
}

export default actions

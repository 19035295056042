import InputText from '@/components/SupsterUI/InputText.vue'
import InputSelect from '@/components/SupsterUI/InputSelect.vue'
import InputSelectItems from '@/components/SupsterUI/InputSelectItems.vue'
import InputCompositeSelect from '@/components/SupsterUI/InputCompositeSelect.vue'
import InputRadio from '@/components/SupsterUI/InputRadio.vue'
import InputCheckbox from '@/components/SupsterUI/InputCheckbox.vue'
import InputCheckboxesGroup from '@/components/SupsterUI/InputCheckboxesGroup.vue'
import StepBreadcrumbs from '@/components/SupsterUI/StepBreadcrumbs.vue'
import Toggle from '@/components/SupsterUI/Toggle.vue'
import Preloader from '@/components/SupsterUI/Preloader.vue'
import CountryFlag from '@/components/CountryFlag.vue'

import AppVAutocomplete from '@/components/app/autocomplete/AppVAutocomplete.vue'
import AppVAutocompleteLoadData from '@/components/app/autocomplete/AppVAutocompleteLoadData.vue'
import AppAutocompleteCountries from '@/components/app/autocomplete/AppAutocompleteCountries.vue'
import AppInfinityLoading from '@/components/app/AppInfinityLoading.vue'
import AppVTextField from '@/components/app/AppVTextField.vue'
import AppVSelect from '@/components/app/AppVSelect.vue'

import AppVTextarea from '@/components/app/AppVTextarea.vue'
import AppVCheckbox from '@/components/app/AppVCheckbox.vue'
import AppVSimpleCheckbox from '@/components/app/AppVSimpleCheckbox.vue'
import AppVFileInput from '@/components/app/AppVFileInput.vue'
import AppVRadio from '@/components/app/AppVRadio.vue'
import AppVRadioGroup from '@/components/app/AppVRadioGroup.vue'
import AppVCard from '@/components/app/AppVCard.vue'
import AppVDialog from '@/components/app/AppVDialog.vue'
import AppPage from '@/components/app/AppPage.vue'
import AppDataTable from '@/components/app/AppDataTable.vue'
import AppDatePicker from '@/components/app/AppDatePicker.vue'
import AppDateInput from '@/components/app/AppDateInput.vue'
import AppPhoneInput from '@/components/app/AppPhoneInput.vue'
import AppDataTableActionDropdown from '@/components/app/AppDataTableActionDropdown.vue'
import AppBadge from '@/components/app/AppBadge.vue'
import AppStatisticsInfoCard from '@/components/app/AppStatisticsInfoCard.vue'
import AppVTextFieldCurrency from '@/components/app/AppVTextFieldCurrency.vue'
import AppDialogGallery from '@/components/app/AppDialogGallery.vue'
import AppPreloader from '@/components/app/AppPreloader.vue'
import AppCheckboxesGroup from '@/components/app/AppCheckboxesGroup.vue'
import AppNumberedTitle from '@/components/app/AppNumberedTitle.vue'
import AppMultipleSelectItem from '@/components/app/AppMultipleSelectItem.vue'
import AppSelectTree from '@/components/app/AppSelectTree.vue'

export function install(Vue): boolean {
  Vue.component('InputText', InputText)
  Vue.component('InputSelect', InputSelect)
  Vue.component('InputSelectItems', InputSelectItems)
  Vue.component('InputCompositeSelect', InputCompositeSelect)
  Vue.component('InputRadio', InputRadio)
  Vue.component('InputCheckbox', InputCheckbox)
  Vue.component('InputCheckboxesGroup', InputCheckboxesGroup)
  Vue.component('StepBreadcrumbs', StepBreadcrumbs)
  Vue.component('Toggle', Toggle)
  Vue.component('Preloader', Preloader)
  Vue.component('CountryFlag', CountryFlag)
  Vue.component('AppVAutocomplete', AppVAutocomplete)
  Vue.component('AppVAutocompleteLoadData', AppVAutocompleteLoadData)
  Vue.component('AppAutocompleteCountries', AppAutocompleteCountries)
  Vue.component('AppInfinityLoading', AppInfinityLoading)
  Vue.component('AppVTextField', AppVTextField)
  Vue.component('AppVSelect', AppVSelect)

  Vue.component('AppVTextarea', AppVTextarea)
  Vue.component('AppVCheckbox', AppVCheckbox)
  Vue.component('AppVSimpleCheckbox', AppVSimpleCheckbox)
  Vue.component('AppVFileInput', AppVFileInput)
  Vue.component('AppVRadio', AppVRadio)
  Vue.component('AppVRadioGroup', AppVRadioGroup)
  Vue.component('AppVCard', AppVCard)
  Vue.component('AppVDialog', AppVDialog)
  Vue.component('AppPage', AppPage)
  Vue.component('AppDataTable', AppDataTable)
  Vue.component('AppDatePicker', AppDatePicker)
  Vue.component('AppDateInput', AppDateInput)
  Vue.component('AppPhoneInput', AppPhoneInput)
  Vue.component('AppDataTableActionDropdown', AppDataTableActionDropdown)
  Vue.component('AppBadge', AppBadge)
  Vue.component('AppStatisticsInfoCard', AppStatisticsInfoCard)
  Vue.component('AppVTextFieldCurrency', AppVTextFieldCurrency)
  Vue.component('AppDialogGallery', AppDialogGallery)
  Vue.component('AppPreloader', AppPreloader)
  Vue.component('AppCheckboxesGroup', AppCheckboxesGroup)
  Vue.component('AppNumberedTitle', AppNumberedTitle)
  Vue.component('AppMultipleSelectItem', AppMultipleSelectItem)
  Vue.component('AppSelectTree', AppSelectTree)
  return true
}

const plugin = {
  install,
}
export default plugin

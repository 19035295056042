












































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter, VuexAction } from '@/store/decorators'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import NotificationProvider from '@/providers/NotificationProvider'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import Step1 from './steps/Step1.vue'
import Step2 from './steps/Step2.vue'
import Step3 from './steps/Step3/Step3.vue'
import Step4 from './steps/Step4/Step4.vue'
import Step5 from './steps/Step5.vue'
import { priceForTariff } from '../../settings'

@Component({
  components: { Step1, Step2, Step3, Step4, Step5 },
})
export default class PromoPagePopUp extends Vue {
  // Data
  step = 1

  mode = 'create'

  // vuex module action
  @VuexAction('nTariffs') createPromoPage!: () => Promise<void>

  @VuexAction('nTariffs') updatePromoPage!: () => Promise<void>

  @VuexAction('nTariffs') updateTariffs!: () => Promise<void>

  // vuex module getter

  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nTariffs') getIsLoading!: boolean

  @VuexGetter('nTariffs') getFormData!: any

  @VuexGetter('nApp') getIsGptApp!: boolean

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  get isDisableBtnNext(): boolean {
    if (this.getFormData) {
      if (this.step === 1) {
        return this.getFormData?.name && !this.getFormData?.designType
      }
      if (this.step === 2) {
        if (this.getIsGptApp) {
          return false
        }
        return this.getFormData?.tariffs?.some((item) => item.amount <= 0 || !item.amount.length)
      }
      if (this.step === 3) {
        return false
      }
      if (this.step === 4) {
        return false
      }
    }
    return true
  }

  async stepsActions(): Promise<void> {
    if (this.step === 1 && this.getPromoPageItem) {
      await this.updatePromoPage()
      this.step++
      return
    }
    if (this.step === 2) {
      if (!this.validateMinPrice()) {
        return
      }
      this.getPromoPageItem ? await this.updateTariffs() : await this.createPromoPage()
      this.step++
    } else {
      this.step++
    }
  }

  validateMinPrice(): boolean {
    for (let i = 0; i < this.getFormData.tariffs.length; i++) {
      const { minPrice } =
        priceForTariff[this.getCurrentApp.type][this.getFormData.tariffs[i].period][
          this.getCurrentApp.currency.symbol
        ]
      if (Number(this.getFormData.tariffs[i].amount) < minPrice) {
        NotificationProvider.add({
          type: 'error',
          title: `${this.$t('error')}`,
          message: `${this.$t('valiationMessages.tariffMinPrice', {
            tariff: `"${this.$t(
              `pageBusinessDigital.tariffNew.${this.getFormData.tariffs[i].period}`
            )}"`,
            minPrice,
            currency: this.getCurrentApp.currency.symbol,
          })}`,
        })
        return false
      }
    }
    return true
  }

  // Emit
  @Emit('closePromoPagePopUp')
  closePromoPagePopUp(): boolean {
    return false
  }

  mounted(): void {
    if (this.getPromoPageItem) {
      this.mode = 'edit'
    }
  }
}

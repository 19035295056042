




























import { Component, Vue, Watch, Emit } from 'vue-property-decorator'
import { DomainAppModelType } from '@/services/api/Domains/DomainsTypes'
import { VuexGetter } from '@/store/decorators'

@Component({})
export default class DomainPopUpDelete extends Vue {
  // Data
  loading = false

  // vuex module getter
  @VuexGetter('nBusinessDomainParking') getDomainsDeletePopUpStatus!: boolean

  @VuexGetter('nBusinessDomainParking') getDomainsCurrentItem!: DomainAppModelType

  // Watch
  @Watch('getDomainDeletePopUpStatus')
  getDomainDeletePopUpStatusWatch(): void {
    if (!this.getDomainsDeletePopUpStatus) {
      this.loading = false
    }
  }

  // Emit
  @Emit('closeDeletePopUp')
  closeDeletePopUp(): boolean {
    return false
  }

  @Emit('domainDelete')
  domainDelete(): number {
    this.loading = true
    return this.getDomainsCurrentItem.id
  }
}

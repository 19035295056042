<template>
  <article class="page-logistics-list">
    <page-header extra>
      <h4 class="d-inline-flex align-center">{{ $t('storages.myStorages') }}</h4>
      <v-btn
        v-if="storesList.items.length"
        color="accent"
        :to="{ name: 'Business_GoodsAndServices.logistics.create' }"
      >
        {{ $t('storages.addStorage') }}
      </v-btn>
    </page-header>
    <v-row class="mt-4 px-4 mx-n2 pb-4" v-if="!loading">
      <v-col class="py-0 my-0 px-2 mb-4" cols="12" v-if="!storesList.items.length">
        <card class="mt-0 mb-6 pa-8">
          <div class="d-flex justify-center flex-column align-center">
            <div>
              <span class="supster-ui__text _primary-light mt-0 text-xsmall">{{
                $t('storages.dontHaveStorage')
              }}</span>
            </div>
            <div>
              <v-btn
                color="accent"
                small
                :to="{ name: 'Business_GoodsAndServices.logistics.create' }"
              >
                {{ $t('storages.createStorage') }}
              </v-btn>
            </div>
          </div>
        </card>
      </v-col>
      <v-col
        class="d-flex py-0 my-0 px-2 mb-4"
        cols="6"
        v-for="(item, index) in storesList.items"
        :key="index"
      >
        <card class="">
          <v-card-title class="orders-card-title py-0 px-10">
            <h2 class="supster-ui--form__title mb-0 pr-6">{{ item.city }} {{ item.name }}</h2>
            <v-icon
              @click="$router.push(`/business/goods_and_services/logistics/${item.id}`)"
              color="accent"
              medium
              >mdi-pencil</v-icon
            >
          </v-card-title>
          <v-card-text class="orders-card-text py-6 px-10">
            <v-row class="my-0">
              <v-col class="py-0" cols="12">
                <label class="supster-ui--input__label">{{ $t('storages.adress') }}</label>
                <p class="supster-ui__text _bolder mt-0">
                  {{ item.region }}, {{ item.city }}, {{ item.street }}, {{ item.house }}, к.
                  {{ item.housing }}, {{ item.postcode }}
                </p>
              </v-col>
              <v-col class="py-0" cols="auto">
                <label class="supster-ui--input__label">{{ $t('storages.workDays') }}</label>
                <p class="supster-ui__text _bolder mt-0">
                  {{
                    item.workDays.length
                      ? item.workDays.map((num) => $t(`workDays.${num}`)).join(',')
                      : $t('notSpecified')
                  }}
                </p>
              </v-col>
              <v-col class="py-0" cols="6">
                <label class="supster-ui--input__label">{{ $t('storages.sendingTime') }}</label>
                <p class="supster-ui__text _bolder mt-0">
                  {{ item.deliveryDelay }} {{ $t('hours') }}
                </p>
              </v-col>
              <v-col class="py-0" cols="12">
                <label class="supster-ui--input__label">{{ $t('storages.deliveryMethods') }}</label>
                <p class="supster-ui__text _bolder mt-0">
                  {{
                    item.deliveryMethods.length
                      ? item.deliveryMethods[0]['name']
                      : $t('notSpecified')
                  }}
                </p>
              </v-col>
              <v-col class="py-0" cols="12">
                <v-btn
                  dark
                  small
                  color="accent"
                  @click="$router.push(`/business/goods_and_services/logistics/${item.id}`)"
                  >{{ $t('storages.addShipingMethod') }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </card>
      </v-col>
    </v-row>
  </article>
</template>
<script>
import Vue from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'LogisticsList',
  components: { PageHeader, Card },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters('app', { app: 'getSelectedApp' }),
    ...mapGetters('stores', {
      storesList: 'getStoresList',
    }),
    workDays() {
      return {}
    },
  },
  methods: {
    ...mapActions('stores', ['loadStores']),
  },
  async created() {
    await this.loadStores({ params: { app: this.app.id } })
    this.loading = false
  },
})
</script>
<style lang="scss">
.page-logistics-list {
  .supster-ui__text {
    line-height: 18px;
  }
  .orders-card-title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    min-height: 92px;
    border-bottom: 1px solid var(--light-gray);
  }

  .header-button-create {
    width: 144px;
    height: 40px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0;
    text-transform: none;
  }
}
</style>

import { ProductsModule } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const Products: ProductsModule = {
  state: () => ({
    test: {},
    isLoading: false,
    products: {
      list: {
        items: [],
        params: {
          pages: 1,
          page: 1,
          itemsPerPage: 5,
          name: false,
          productCategory: false,
          app: false,
          status: false,
        },
      },
      item: {},
    },
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default Products

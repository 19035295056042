


















































































































































































































































































































































































import { Component, Ref, Watch, Vue } from 'vue-property-decorator'
import ImageSlider from '@/views/business/publication/components/ImageSlider.vue'
import { App, User } from '@/store/types'
import googlePlayCategories from '@/constants/googlePlayCategories'
import googleLanguages from '@/constants/googleLanguages'
import { VuexAction, VuexMutation, VuexGetter } from '@/store/decorators'
import { PublicationModelAppType } from '@/services/api/Publication/PublicationTypes'
import { cloneDeep } from 'lodash'
import NotificationProvider from '@/providers/NotificationProvider'
import MediaObjectsService from '@/services/api/MediaObjects/MediaObjectsService'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'

@Component({
  components: { ImageSlider },
})
export default class PublishStageThree extends Vue {
  // Refs
  @Ref('faviconInput') readonly faviconInput!: any

  @Ref('bannerInput') readonly bannerInput!: any

  @Ref('galleryInput') readonly galleryInput!: any

  @Ref('form') readonly form!: any

  // Data
  appsList: any = []

  currentAppLang = 0

  selectedAdditionalLang = null

  currentAppData: any = null

  generalAppLang: { id: number; labelEn: string; labelRu: string } | null = null

  generalAppCategory: { id: number; labelEn: string; labelRu: string } | null = null

  scrollAppContainer = false

  formValid = false

  loading = false

  languagesList = googleLanguages

  nameMaxLength = 50

  descShortMaxLength = 80

  descFullMaxLength = 4000

  isPreloaderShow = false

  // Vuex module getters
  @VuexGetter('nBusinessPublication') getPublicationListItems!: Array<PublicationModelAppType>

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nApp') getAuthUser!: User

  // Vuex module action
  @VuexAction('nBusinessPublication') publicationGetItems!: () => Promise<void>

  @VuexAction('nBusinessPublication') publicationCreateItem!: (...args) => Promise<void>

  @VuexAction('nBusinessPublication') publicationUpdateItem!: (...args) => Promise<void>

  //  Vuex module mutation
  @VuexMutation('nBusinessPublication') publicationListParamsUpdate!: (...args) => void

  // Computed
  get defaultData() {
    return {
      appLang:
        this.languagesList.find((i) => i.key.includes(this.getCurrentApp.lang)) ||
        this.languagesList[60],
      name: this.getCurrentApp.name || '',
      author: this.getAuthUser.name || '',
      descShort: this.getCurrentApp.description || '',
      descFull: this.getCurrentApp.description || '',
      faviconUrl: null,
      bannerUrl: null,
      youtubeUrl: null,
      sliderImagesList: [],
    }
  }

  get offerSectionWidth() {
    return this.$vuetify.breakpoint.width < 1462 ? '480px' : '533px'
  }

  get generalAppLangList() {
    return [{ id: 1, labelEn: 'Russian', labelRu: 'Русский' }]
  }

  get sortedLangList() {
    const keys = this.appsList.map((app) => app.appLang.key)
    return this.languagesList.filter((item) => !keys.includes(item.key))
  }

  get generalAppCategoryList() {
    return googlePlayCategories
  }

  get nameCounterLabel() {
    return `${this.currentAppData.name.length} / ${this.nameMaxLength}`
  }

  get shortDescCounterLabel() {
    return `${this.currentAppData.descShort.length} / ${this.descShortMaxLength}`
  }

  get fullDescCounterLabel() {
    return `${this.currentAppData.descFull.length} / ${this.descFullMaxLength}`
  }

  // Methods
  addSelectedLanguageToArr(): void {
    const data: any = cloneDeep(this.defaultData)
    data.appLang = this.selectedAdditionalLang
    this.appsList.push(data)
    this.selectedAdditionalLang = null
  }

  imageFormChanged(e): void {
    const file = e.target.files[0]
    if (file) {
      this.currentAppData[e.target.id] = {
        mediaObject: file,
        previewImg: URL.createObjectURL(file),
      }
      URL.revokeObjectURL(file)
    } else {
      this.currentAppData[e.target.id] = null
    }
  }

  addImageToGallery(e): void {
    const file = e.target.files[0]
    if (file) {
      this.currentAppData.sliderImagesList.push({
        mediaObject: file,
        previewImg: URL.createObjectURL(file),
      })
      URL.revokeObjectURL(file)
    }
  }

  sliderImageDeletedHandler(index: number): void {
    this.currentAppData.sliderImagesList.splice(index, 1)
  }

  inputClick(input: string): void {
    this[input].value = ''
    this[input].click()
  }

  deleteImage(key: string): void {
    this.currentAppData[`${key}Url`] = null
    this.currentAppData[key] = null
  }

  onScroll(e) {
    this.scrollAppContainer = e && e.path[1].scrollY > 80
  }

  async getImageForUpload(): Promise<void> {
    await Promise.all(
      this.appsList.map(async (app) => {
        app.sliderImagesList = await Promise.all(
          app.sliderImagesList.map(async (item) => {
            let result
            if (typeof item.mediaObject === 'object') {
              result = await this.createImage(item.mediaObject)
            } else {
              result = item.mediaObject
            }
            return result
          })
        )
        if (app.faviconUrl) {
          if (typeof app.faviconUrl.mediaObject === 'object') {
            app.faviconUrl = await this.createImage(app.faviconUrl.mediaObject)
          } else {
            app.faviconUrl = app.faviconUrl.mediaObject
          }
        } else {
          app.faviconUrl = ''
        }

        if (app.bannerUrl) {
          if (typeof app.bannerUrl.mediaObject === 'object') {
            app.bannerUrl = await this.createImage(app.bannerUrl.mediaObject)
          } else {
            app.bannerUrl = app.bannerUrl.mediaObject
          }
        } else {
          app.bannerUrl = ''
        }
      })
    )
  }

  async sendToModeration(): Promise<void> {
    if (this.currentAppData.sliderImagesList.length < 2) {
      NotificationProvider.add({
        type: 'error',
        message: this.$t('allertMessage.min2Image'),
      })
      return
    }
    this.loading = true
    await this.getImageForUpload()
    const sendData = {
      json: JSON.stringify({
        localizationData: this.appsList,
        category: this.generalAppCategory,
        language: this.generalAppLang,
      }),
      app: `/api/apps/${this.getCurrentApp.id}`,
      platform: 1,
    }
    if (!this.getPublicationListItems.length) {
      await this.publicationCreateItem(sendData)
    } else {
      await this.publicationUpdateItem({
        data: { ...sendData, status: 2 },
        id: this.getPublicationListItems[0].id,
      })
    }
    this.loading = false
    this.$router.push({ name: 'Business_Home' })
  }

  async getImage(mediaObject: string): Promise<string> {
    const id = Number(mediaObject.replace(/[^0-9]/g, ''))
    const response = await MediaObjectsService.getItem({ id })
    return response.data.content_url
  }

  async createImage(file): Promise<string> {
    let imageUrl = ''
    if (file) {
      const response: any = await MediaObjectsService.createItem({
        data: file,
        params: {},
      })
      imageUrl = `/api/media_objects/${response.data.id}`
    }
    return imageUrl
  }

  async getImages(): Promise<void> {
    await Promise.all(
      this.appsList.map(async (app) => {
        app.sliderImagesList = await Promise.all(
          app.sliderImagesList.map(async (item) => {
            const response = await this.getImage(item)
            return {
              mediaObject: item,
              previewImg: response,
            }
          })
        )
        if (app.faviconUrl) {
          app.faviconUrl = {
            mediaObject: app.faviconUrl,
            previewImg: await this.getImage(app.faviconUrl),
          }
        }
        if (app.bannerUrl) {
          app.bannerUrl = {
            mediaObject: app.bannerUrl,
            previewImg: await this.getImage(app.bannerUrl),
          }
        }
      })
    )
  }

  // Watch
  @Watch('currentAppLang')
  currentAppLangWatch(): void {
    this.currentAppData = this.appsList[this.currentAppLang]
  }

  // Hooks
  async created(): Promise<void> {
    if (this.getPublicationListItems.length) {
      this.isPreloaderShow = true
      this.appsList = this.getPublicationListItems[0].json.localizationData
      this.currentAppData = this.getPublicationListItems[0].json.localizationData[0]
      this.generalAppLang = this.getPublicationListItems[0].json.language
      this.generalAppCategory = this.getPublicationListItems[0].json.category
      await this.getImages()
      this.isPreloaderShow = false
    } else {
      this.appsList.push(cloneDeep(this.defaultData))
      this.currentAppData = this.appsList[0]
      this.generalAppLang = this.generalAppLangList[0]
      this.generalAppCategory = this.generalAppCategoryList[0]
    }
  }
}

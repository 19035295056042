import store from '@/store'
import i18n from '@/plugins/i18n'
import router from '@/router'
// Services
import localizationService from '@/services/LocalizationService'
import AuthService from '@/services/AuthService'
import WebsocketInstance from '@/services/WebsocketService'

// Providers
import AuthProvider from './providers/AuthProvider'
import NotificationProvider from './providers/NotificationProvider'
import LocalizationProvider from './providers/LocalizationProvider'
import WebsocketProvider from './providers/WebsocketProvider'

// Register providers
NotificationProvider.register(({ type = 'default', message = '', title = '' }) => {
  store.dispatch('nApp/createNotification', { type, message, title })
})

LocalizationProvider.registerPlugin(i18n)
LocalizationProvider.registerService(localizationService)
LocalizationProvider.registerStore(store)

AuthProvider.registerRouter(router)
AuthProvider.registerStore(store)
AuthProvider.registerService(AuthService)

WebsocketProvider.registerService(WebsocketInstance)
WebsocketProvider.registerStore(store)
WebsocketProvider.registerRouter(router)

// Use providers
AuthProvider.findReferral()
AuthProvider.checkAuthUser()
AuthProvider.checkAuthModerator()
AuthProvider.checkGPTMode()
LocalizationProvider.initLocale()

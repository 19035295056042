






































































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import Validation from '@/utils/Validation'
import { VuexAction, VuexGetter } from '@/store/decorators'
import { PostbackAppModelType } from '@/services/api/Postback/PostbackTypes'

@Component({})
export default class GlobalPostback extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Data
  listChanges = {
    method: '',
    url: '',
    eventOrder: false,
    eventOrderConfirm: false,
    eventOrderDecline: false,
    eventSubscription: false,
    eventSubscriptionRepeat: false,
    eventInstall: false,
  }

  rules: any = {
    method: Validation.getRules(['required']),
    url: Validation.getRules(['required']),
  }

  isFormValid = false

  isPreloaderShow = false

  // vuex module action
  @VuexAction('nGlobalPostback') globalPostbackCreate!: (...args) => void

  @VuexAction('nGlobalPostback') globalPostbackUpdate!: (...args) => void

  @VuexAction('nGlobalPostback') globalPostbackGetItem!: (...args) => void

  @VuexAction('nGlobalPostback') userMeGetItem!: (...args) => void

  // vuex module getter
  @VuexGetter('nGlobalPostback') getGlobalPostbackItem!: PostbackAppModelType

  @VuexGetter('nGlobalPostback') getUserMe!: any

  @VuexGetter('nGlobalPostback') getMethodsList!: Array<{ name: string; value: string }>

  // Methods
  checkAllOther(value: boolean): void {
    if (value) {
      this.listChanges.eventInstall = true
    } else {
      this.listChanges.eventInstall = false
    }
  }

  submitForm(): void {
    this.form.validate()
    if (this.isFormValid) {
      this.getUserMe.global_post_back ? this.updateGlobalPostback() : this.createGlobalPostBack()
    }
  }

  async createGlobalPostBack(): Promise<void> {
    await this.globalPostbackCreate({ ...this.listChanges })
  }

  async updateGlobalPostback(): Promise<void> {
    await this.globalPostbackUpdate({
      data: this.listChanges,
      id: this.getUserMe.global_post_back.id,
    })
  }

  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.userMeGetItem()
    if (this.getUserMe.global_post_back) {
      await this.globalPostbackGetItem(this.getUserMe.global_post_back.id)
      this.listChanges.method = this.getGlobalPostbackItem.method
      this.listChanges.url = this.getGlobalPostbackItem.url
      this.listChanges.eventOrder = this.getGlobalPostbackItem.eventOrder
      this.listChanges.eventOrderConfirm = this.getGlobalPostbackItem.eventOrderConfirm
      this.listChanges.eventOrderDecline = this.getGlobalPostbackItem.eventOrderDecline
      this.listChanges.eventSubscription = this.getGlobalPostbackItem.eventSubscription
      this.listChanges.eventSubscriptionRepeat = this.getGlobalPostbackItem.eventSubscriptionRepeat
      this.listChanges.eventInstall = this.getGlobalPostbackItem.eventInstall
    }
    this.isPreloaderShow = false
  }
}

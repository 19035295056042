import PromotionalPageService from '@/services/api/PromotionalPage/PromotionalPageService'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import TariffService from '@/services/api/Tariff/TariffService'
import PostsService from '@/services/api/Posts/PostsService'
import { TariffAppModelType } from '@/services/api/Tariff/TariffTypes'
import ChannelsService from '@/services/api2/Channels/ChannelsService'
import { ChannelsAppModelType } from '@/services/api2/Channels/ChannelsTypes'
import { cloneDeep } from 'lodash'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { ResponseTypes } from '@/services/api/BaseApiService'
import { nTariffsMutations, nTariffsActions, nTariffsGetters, nTariffsModule } from './types'

const defaultState = {
  formData: null,
  isLoading: false,
  promoPages: {
    loading: false,
    items: [],
    item: null,
    loadingItem: false,
    params: PromotionalPageService.getDefaultParams(),
    isOpenPopUpPromoPageCreate: false,
    isOpenPromoPagePopUp: false,
    isOpenPromoPagePopUpView: false,
  },
  tariffs: {
    items: [],
    loadingItem: false,
    item: null,
    params: TariffService.getDefaultParams(),
    loading: false,
    isOpenTariffPopUpEdit: false,
  },
  channels: {
    items: [],
    params: ChannelsService.getDefaultParams(),
    stopFetch: false,
  },
  posts: {
    items: [],
    params: PostsService.getDefaultParams(),
    stopFetch: false,
  },
}
const actions: nTariffsActions = {
  async promoPagesGetItems({ commit, state }): Promise<boolean> {
    commit('changePromoPagesLoading', true)
    const response = await PromotionalPageService.getItems({
      params: state.promoPages.params,
    })
    if (response.status) {
      commit('promoPagesParamsUpdate', response.params)
      commit('promoPagesItemsUpdate', response.data)
    }
    commit('changePromoPagesLoading', false)
    return response.status
  },
  async promoPageGetItem({ commit }, id): Promise<boolean> {
    commit('changePromoPageItemLoading', true)
    const { data, status } = await PromotionalPageService.getItem({
      id,
    })
    if (status) {
      commit('promoPageItemUpdate', data)
      commit('setFormData', {
        name: data.name,
        designType: data.designType,
        tariffs: data.tariffs.slice().reverse(),
      })
      commit('changePromoPageItemLoading', false)
    }
    return status
  },
  async promoPageCreateItem({ commit, rootGetters, state }): Promise<boolean> {
    const response = await PromotionalPageService.createItem({
      params: {},
      data: {
        app: `api/apps/${rootGetters['nApp/getCurrentApp'].id}`,
        tariffs: state.formData.tariffs.map((item) => `api/tariffs/${item.id}`),
        designType: state.formData.designType,
        name: state.formData.name,
      },
    })
    if (response.status) {
      commit('promoPageItemUpdate', response.data)
    }
    return response.status
  },
  async promoPageUpdateItem({ state }): Promise<boolean> {
    const response = await PromotionalPageService.updateItem({
      id: state.promoPages.item?.id ?? 0,
      data: {
        designType: state.formData.designType,
        name: state.formData.name,
      },
    })
    return response.status
  },
  async tariffsGetItems({ commit, state }): Promise<boolean> {
    commit('changeTariffsLoading', true)
    const response = await TariffService.getItems({
      params: state.tariffs.params,
    })
    if (response.status) {
      const sortedTariffs = TariffService.sortByPromotionalPage(response.data)
      commit('tariffsParamsUpdate', response.params)
      commit('tariffsItemsUpdate', sortedTariffs)
    }
    commit('changeTariffsLoading', false)
    return response.status
  },
  async tariffCreateItems({ state, rootGetters }): Promise<void> {
    if (state.formData.trialPromoPageIsEnable) {
      state.formData.tariffs[0].trial_period = 7
      state.formData.tariffs[0].trial_amount = 1
    }
    for (const tariff of state.formData.tariffs) {
      const response = await TariffService.createItem({
        params: {},
        data: {
          app: `api/apps/${rootGetters['nApp/getCurrentApp'].id}`,
          ...tariff,
        },
      })
      tariff.id = response.data.id
    }
  },
  async tariffGetItem({ commit }, id): Promise<boolean> {
    commit('changeTariffItemLoading', true)
    const response = await TariffService.getItem({
      id,
    })
    if (response.status) {
      commit('tariffItemUpdate', response.data)
    }
    commit('changeTariffItemLoading', false)
    return response.status
  },
  async tariffUpdateItem({ commit }, params): Promise<boolean> {
    const response = await TariffService.updateItem(params)
    return response.status
  },
  async tariffsUpdateItems({ state }, trialMode): Promise<void> {
    for (const item of trialMode ? [state.formData.tariffs[0]] : state.formData.tariffs) {
      await TariffService.updateItem({
        id: item.id,
        data: {
          amount: String(item.amount),
        },
      })
    }
  },
  async trialToogle({ state }): Promise<void> {
    await TariffService.updateItem({
      id: state.formData.tariffs[0].id,
      data: {
        trial_period: state.formData.trialPromoPageIsEnable ? 7 : null,
        trial_amount: state.formData.trialPromoPageIsEnable ? 1 : null,
      },
    })
  },
  async sendTrialRequest({ dispatch, rootGetters }) {
    await dispatch(
      'nApp/updateApp',
      {
        id: rootGetters['nApp/getCurrentApp'].id,
        data: {
          trialPeriodPermission: 1,
        },
      },
      { root: true }
    )
  },

  async postsGetItems({ commit, state }): Promise<boolean> {
    const response = await PostsService.getItems({
      params: state.posts.params,
    })
    if (response.status) {
      commit('postsParamsUpdate', response.params)
      commit('postsItemsUpdate', response.data)
      if (!response.data.length) {
        commit('setStopFetchPosts', true)
      }
    }
    return response.status
  },
  async postUpdateItem({ state }, { id, data }): Promise<ResponseTypes> {
    const response = await PostsService.updateItem({
      id,
      data,
    })
    if (response.status) {
      state.posts.items = state.posts.items.map((item: PostsAppModelType) => {
        if (item.id === response.data.id) {
          item = response.data
        }
        return item
      })
    }
    return response
  },
  async channelsGetItems({ commit, state }): Promise<boolean> {
    const response = await ChannelsService.getItems({
      params: state.channels.params,
    })
    if (response.status) {
      commit('channelsParamsUpdate', response.params)
      commit('channelsItemsUpdate', response.data)
      if (!response.data.length) {
        commit('setStopFetchChannels', true)
      }
    }
    return response.status
  },
  async channelCreateItem({ commit }, { data }): Promise<boolean> {
    const response = await ChannelsService.createItem({
      params: {},
      data,
    })
    if (response.status) {
      commit('channelsItemsUpdate', [response.data])
    }
    return response.status
  },
  async channelUpdateItem({ state }, { id, data }): Promise<ResponseTypes> {
    const response = await ChannelsService.updateItem({
      id,
      data,
    })
    if (response.status) {
      state.channels.items = state.channels.items.map((item: ChannelsAppModelType) => {
        if (item.id === response.data.data.id) {
          item = response.data.data
        }
        return item
      })
    }
    return response
  },

  async createPromoPage({ state, dispatch }) {
    state.isLoading = true
    await dispatch('tariffCreateItems')
    await dispatch('promoPageCreateItem')
    dispatch('promoPagesGetItems')
    dispatch('tariffsGetItems')
    state.isLoading = false
  },

  async updatePromoPage({ state, dispatch }) {
    state.isLoading = true
    if (state.formData.isFixedPromoPageChanges) {
      await dispatch('promoPageUpdateItem')
      dispatch('promoPagesGetItems')
    }
    state.isLoading = false
  },

  async updateTariffs({ state, dispatch }) {
    state.isLoading = true
    if (state.formData.isFixedTariffChanges) {
      await dispatch('tariffsUpdateItems')
      dispatch('tariffsGetItems')
      dispatch('promoPagesGetItems')
    }
    state.isLoading = false
  },
}

const mutations: nTariffsMutations = {
  resetForm(state) {
    Object.assign(state.channels, cloneDeep(defaultState.channels))
    Object.assign(state.posts, cloneDeep(defaultState.posts))
    state.formData = null
  },
  setFormData(state, data): void {
    state.formData = { ...state.formData, ...data }
  },
  setStopFetchChannels(state, status): void {
    state.channels.stopFetch = status
  },
  setStopFetchPosts(state, status): void {
    state.posts.stopFetch = status
  },
  changeTariffsLoading(state, status: boolean): void {
    state.tariffs.loading = status
  },
  changeTariffItemLoading(state, status: boolean): void {
    state.tariffs.loadingItem = status
  },
  changePromoPageItemLoading(state, status: boolean): void {
    state.promoPages.loadingItem = status
  },
  changePromoPagesLoading(state, status: boolean): void {
    state.promoPages.loading = status
  },
  changeTariffPopUpEditStatus(state, status: boolean): void {
    state.tariffs.isOpenTariffPopUpEdit = status
  },
  changePromoPagePopUpStatus(state, status: boolean): void {
    state.promoPages.isOpenPromoPagePopUp = status
  },
  changePromoPagePopUpViewStatus(state, status: boolean): void {
    state.promoPages.isOpenPromoPagePopUpView = status
  },
  tariffsParamsUpdate(state, params): void {
    state.tariffs.params = { ...state.tariffs.params, ...params }
  },
  promoPagesParamsUpdate(state, params): void {
    state.promoPages.params = { ...state.promoPages.params, ...params }
  },
  tariffsItemsUpdate(state, items: TariffAppModelType[]): void {
    state.tariffs.items = items
  },
  channelsParamsUpdate(state, params): void {
    state.channels.params = { ...state.channels.params, ...params }
  },
  postsParamsUpdate(state, params): void {
    state.posts.params = { ...state.posts.params, ...params }
  },
  channelsItemsUpdate(state, items: ChannelsAppModelType[]): void {
    state.channels.items = [...state.channels.items, ...items]
  },
  postsItemsUpdate(state, items: PostsAppModelType[]): void {
    state.posts.items = [...state.posts.items, ...items]
  },
  promoPagesItemsUpdate(state, items: PromotionalPageAppModelType[]): void {
    state.promoPages.items = items
  },
  promoPageItemUpdate(state, item: PromotionalPageAppModelType): void {
    state.promoPages.item = item
  },
  tariffItemUpdate(state, item: TariffAppModelType): void {
    state.tariffs.item = item
  },
}

const getters: nTariffsGetters = {
  getFormData(state): boolean {
    return state.formData
  },
  getIsLoading(state): boolean {
    return state.isLoading
  },
  getStopFetchChannels(state): boolean {
    return state.channels.stopFetch
  },
  getStopFetchPosts(state): boolean {
    return state.posts.stopFetch
  },
  getTariffsLoading(state): boolean {
    return state.tariffs.loading
  },
  getTariffLoadingItem(state): boolean {
    return state.tariffs.loadingItem
  },
  getPromoPageLoadingItem(state): boolean {
    return state.promoPages.loadingItem
  },
  getTariffsParams(state): unknown {
    return state.tariffs.params
  },
  getTariffsItems(state): TariffAppModelType[] | [] {
    return state.tariffs.items
  },
  getTariffItem(state): TariffAppModelType | null {
    return state.tariffs.item
  },
  getPromoPagesLoading(state): boolean {
    return state.promoPages.loading
  },
  getPromoPagesParams(state): unknown {
    return state.promoPages.params
  },
  getPromoPagesItems(state): PromotionalPageAppModelType[] | [] {
    return state.promoPages.items
  },
  getPromoPageItem(state): PromotionalPageAppModelType | null {
    return state.promoPages.item
  },
  getChannelsItems(state): ChannelsAppModelType[] | [] {
    return state.channels.items
  },
  getPostsItems(state): PostsAppModelType[] | [] {
    return state.posts.items
  },
  getChannelsParams(state): unknown {
    return state.channels.params
  },
  isOpenTariffPopUpEdit(state): boolean {
    return state.tariffs.isOpenTariffPopUpEdit
  },
  isOpenPromoPagePopUp(state): boolean {
    return state.promoPages.isOpenPromoPagePopUp
  },
  isOpenPromoPagePopUpView(state): boolean {
    return state.promoPages.isOpenPromoPagePopUpView
  },
}

const nTariffs: nTariffsModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nTariffs

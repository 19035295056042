



























import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import ManagmentFilter from '@/views/business/subscriptions/Managment/components/ManagmentFilter.vue'
import ManagmentTable from '@/views/business/subscriptions/Managment/components/ManagmentTable.vue'
import ManagmentPopUpDetails from '@/views/business/subscriptions/Managment/components/ManagmentPopUpDetails.vue'
import ManagmentPopUpUnsubscribe from '@/views/business/subscriptions/Managment/components/ManagmentPopUpUnsubscribe.vue'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import moment from 'moment'
import { filterDataType } from './types'

@Component({
  components: {
    ManagmentFilter,
    ManagmentTable,
    ManagmentPopUpDetails,
    ManagmentPopUpUnsubscribe,
  },
})
export default class Managment extends Vue {
  // Data
  isPreloaderShow = false

  // vuex module action
  @VuexAction('nBusinessManagment') subscriptionGetItems!: () => Promise<boolean>

  @VuexAction('nBusinessManagment') tariffGetItems!: () => Promise<boolean>

  @VuexAction('nBusinessManagment') subscriptionGetItem!: (...args) => void

  @VuexAction('nBusinessManagment') subscriptionUpdateItem!: (...args) => Promise<boolean>

  // vuex module mutation
  @VuexMutation('nBusinessManagment') subscriptionListParamsUpdate!: (...args) => void

  @VuexMutation('nBusinessManagment') tariffListParamsUpdate!: (...args) => void

  @VuexMutation('nBusinessManagment') changePopUpDetailsStatus!: (...args) => void

  @VuexMutation('nBusinessManagment') changePopUpUnsubscribeStatus!: (...args) => void

  // vuex module getter
  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Methods

  tableChangePage(page: number): void {
    this.subscriptionListParamsUpdate({ page })
    this.subscriptionGetItems()
  }

  closePopUpDetails(): void {
    this.changePopUpDetailsStatus(false)
  }

  openPopUpUnsubscribe(): void {
    this.closePopUpDetails()
    this.changePopUpUnsubscribeStatus(true)
  }

  closePopUpUnsubscribe(): void {
    this.changePopUpUnsubscribeStatus(false)
  }

  leaveActiveSubscription(): void {
    this.changePopUpUnsubscribeStatus(false)
    this.changePopUpDetailsStatus(true)
  }

  async unsubscribe(id: number): Promise<void> {
    await this.subscriptionUpdateItem({ id, type: 3 })
    await this.tableLoadItems()
    this.changePopUpUnsubscribeStatus(false)
  }

  async filter(filterData: filterDataType): Promise<void> {
    this.subscriptionListParamsUpdate({ app: this.getCurrentApp.id, ...filterData, page: 1 })
    await this.subscriptionGetItems()
  }

  async openPopUpDetails(id): Promise<void> {
    await this.subscriptionGetItem(id)
    this.changePopUpDetailsStatus(true)
  }

  async tableLoadItems(): Promise<void> {
    this.subscriptionListParamsUpdate({
      app: this.getCurrentApp.id,
      created: [moment().subtract(13, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    })
    await this.subscriptionGetItems()
  }

  async tariffLoadItems(): Promise<void> {
    this.tariffListParamsUpdate({ app: this.getCurrentApp.id, pagination: false })
    await this.tariffGetItems()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.tableLoadItems()
    await this.tariffLoadItems()
    this.isPreloaderShow = false
  }
}

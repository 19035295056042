





























































































































import { Component, Ref, Mixins, Prop } from 'vue-property-decorator'
import FaqAccordionPanel from '@/views/faq/components/FaqAccordionPanel.vue'
import SectionMixin from '@/views/faq/mixins/SectionMixin'

@Component({
  components: {
    FaqAccordionPanel,
  },
})
export default class SectionAccountManagement extends Mixins(SectionMixin) {
  // Refs
  @Ref('chapterZero') readonly chapterZero!: HTMLElement

  @Ref('chapterOne') readonly chapterOne!: HTMLElement

  @Ref('chapterTwo') readonly chapterTwo!: HTMLElement

  @Ref('chapterThree') readonly chapterThree!: HTMLElement

  @Ref('chapterFour') readonly chapterFour!: HTMLElement

  @Ref('chapterThreeZero') readonly chapterThreeZero!: HTMLElement

  @Ref('chapterThreeOne') readonly chapterThreeOne!: HTMLElement

  @Ref('chapterThreeTwo') readonly chapterThreeTwo!: HTMLElement

  // Props
  @Prop(String) readonly locale!: string
}

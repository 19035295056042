












































































































































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter } from '@/store/decorators'
import AppPreview from '@/components/appPreview/AppPreview.vue'
import Toggle from '@/components/SupsterUI/Toggle.vue'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'

@Component({
  components: {
    AppPreview,
    Toggle,
  },
})
export default class BusinessSubscriptions extends Vue {
  // Data
  isPreloaderShow = false

  toggleVal = 'digital'

  // vuex module action
  @VuexAction('nApp') fetchAppPreviews!: () => Promise<boolean>

  // vuex module getter
  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nApp') getCurrentCountry!: any

  @VuexGetter('nApp') getAppPreviews!: PostsAppModelType[]

  // Computed
  get userAgreementDocId(): string {
    if (this.getCurrentCountry.country_code === 'RU') {
      return 'russia-polzovatelskoe-soglashenie-ru'
    }
    if (this.getCurrentCountry.country_code === 'IN') {
      return 'india-user-agreement-en'
    }
    return 'world-user-agreement-en'
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.fetchAppPreviews()
    this.isPreloaderShow = false
  }
}

import { cloneDeep } from 'lodash'
import moment from 'moment'
import i18n from '@/plugins/i18n'
import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'

import { ReferralAppModelType, ReferralApiModelType } from './ReferralTypes'

class ReferralService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.referrals')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ReferralAppModelType = {
    id: 0,
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.REFERRAL)
  }

  public getEmptyItem(): ReferralAppModelType {
    const data = cloneDeep(this.AppModelPropsDefault)
    return data
  }

  public transformDataForApi(incomingData): ReferralApiModelType {
    return incomingData
  }

  public transformDataForApp(incomingData): ReferralAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }

  public async getReferralsPage(): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.REFERRAL}/page`
      const response = await this.api.get(requestUrl)
      return { status: true, data: response.data, params: {} }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, params: {}, error }
    }
  }

  public async getReferralsPageStat(): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.REFERRAL}/page-stat`
      const response = await this.api.get(requestUrl)
      const testData = {
        levels: [
          {
            level: 1,
            all_refferals: 5,
            active_refferals: 5,
            incoming_money: {
              currency_id: 5,
              sum: 550,
            },
            processing_money: {
              currency_id: 5,
              sum: 1500,
            },
          },
          {
            level: 2,
            all_refferals: 13,
            active_refferals: 3,
            incoming_money: {
              currency_id: 5,
              sum: 1330,
            },
            processing_money: {
              currency_id: 5,
              sum: 7200,
            },
          },
        ],
        max_level: 8,
        next_level_rules: {
          active_referrals: 5,
        },
        result: {
          all_referrals: 10,
          active_referrals: 15,
          incoming_money: {
            currency_id: 5,
            sum: 17500,
          },
          processing_money: {
            currency_id: 5,
            sum: 63000,
          },
        },
      }

      return { status: true, data: response.data, params: {} }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, params: {}, error }
    }
  }
}
export default new ReferralService()




























import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexMutation, VuexGetter } from '@/store/decorators'
import DomainParkingForm from '@/views/business/tools/domainParking/components/DomainParkingForm.vue'
import DomainParkingTable from '@/views/business/tools/domainParking/components/DomainParkingTable.vue'
import DomainPopUpDelete from '@/views/business/tools/domainParking/components/DomainPopUpDelete.vue'
import { formDataType } from './types'

@Component({
  components: {
    DomainParkingForm,
    DomainParkingTable,
    DomainPopUpDelete,
  },
})
export default class OffersList extends Vue {
  // Data
  isPreloaderShow = false

  // Vuex module action
  @VuexAction('nBusinessDomainParking') domainsGetItems!: () => Promise<void>

  @VuexAction('nBusinessDomainParking') domainsGetItem!: (...args) => Promise<void>

  @VuexAction('nBusinessDomainParking') domainsCreateItem!: (...args) => Promise<void>

  @VuexAction('nBusinessDomainParking') domainsDeleteItem!: (...args) => Promise<void>

  // Vuex module mutation
  @VuexMutation('nBusinessDomainParking') domainsParamsUpdate!: (...args) => void

  @VuexMutation('nBusinessDomainParking') domainsDeletePopUpChangeStatus!: (status: boolean) => void

  @VuexMutation('nBusinessDomainParking') createDomainsLoadingChangeStatus!: (
    status: boolean
  ) => void

  @VuexMutation('nBusinessDomainParking') domainsCreateChangeStatus!: (status: boolean) => void

  // Vuex module getters
  @VuexGetter('nApp') getAuthUser!: any // TODO Старый модуль для получения user

  // Methods
  tableChangePage(page: number): void {
    this.domainsParamsUpdate({ page })
    this.domainsGetItems()
  }

  async domainDelete(id: number): Promise<void> {
    await this.domainsDeleteItem({ id })
    this.domainsGetItems()
    this.domainsDeletePopUpChangeStatus(false)
  }

  async domainCreate(formData: formDataType): Promise<void> {
    this.createDomainsLoadingChangeStatus(true)
    await this.domainsCreateItem(formData)
    await this.domainsGetItems()
    this.createDomainsLoadingChangeStatus(false)
  }

  async openDeletePopUp(id: number): Promise<void> {
    await this.domainsGetItem(id)
    this.domainsDeletePopUpChangeStatus(true)
  }

  closeDeletePopUp(): void {
    this.domainsDeletePopUpChangeStatus(false)
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.domainsGetItems()
    this.isPreloaderShow = false
  }
}

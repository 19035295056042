import { getInstanceWithToken } from '@/utils/axios'
import { StatsActions } from '@/store/modules/stats/types'
import moment from 'moment'

const actions: StatsActions = {
  async fetchMenuTab({ commit }, payload) {
    commit('setMenuTab', payload)
  },
  async fetchDateRange({ commit }, payload) {
    commit('setDateRange', payload)
  },
  async fetchGroupBy({ commit }, payload) {
    commit('setGroupBy', payload)
  },
  async fetchAffiliateStatTable({ commit }, payload) {
    const response = await getInstanceWithToken().get(
      `/api/stat/affiliate?date_start=${payload.dateRange[0]}&date_end=${payload.dateRange[1]}&group_by=${payload.groupBy.value}`
    )
    response.data.detail.forEach((p) => {
      p.date = moment(p.date)
    })
    commit('setAffiliateStatTable', response.data)
  },
  async fetchAnalytics({ state, commit }, payload) {
    if (state.dateRange) {
      const response = await getInstanceWithToken().get(
        `/api/stat/analytics?app_id=${payload.app_id}&date_start=${state.dateRange[0]}&date_end=${state.dateRange[1]}&group_by=${state.groupBy?.value}`
      )
      commit('setAnalytics', response.data)
    }
  },
  async fetchCommonStats({ state, commit }, payload) {
    if (state.dateRange) {
      const response = await getInstanceWithToken().get(
        `/api/stat/common?app_id=${payload.app_id}&date_start=${state.dateRange[0]}&date_end=${state.dateRange[1]}`
      )

      response.data.by_day = Object.values(response.data.by_day)
      commit('setCommonStats', response.data)
    }
  },

  async fetchReportsTableStats({ state, commit }, payload) {
    try {
      if (state.dateRange) {
        const response = await getInstanceWithToken().get(
          `/api/stat/app?app_id=${payload.app_id}&date_start=${state.dateRange[0]}&date_end=${state.dateRange[1]}&group_by[]=${state.menuTab}`
        )
        response.data.detail.forEach((p) => {
          if (!p.advertiser) {
            p.advertiser = { name: 'Не определено' }
          }
        })
        if (response.data.detail[0]?.date) {
          response.data.detail = response.data.detail.sort(
            (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
          )
        }
        commit('setReportsTableStats', response.data)
      }
    } catch (error) {
      console.log(error)
    }
  },

  async fetchMetrics({ commit }) {
    const response = await getInstanceWithToken().get(`/api/stat/metrics?page=1&itemsPerPage=30`)

    response.data.forEach((p) => {
      p.value = false
      p.color = ''
    })

    commit('setMetrics', response.data)
  },
}

export default actions

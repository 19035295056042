class AuthProvider {
  static instance: AuthProvider

  $router: any = false

  $store: any = false

  authService: any = false

  gptMode = false

  constructor() {
    if (typeof AuthProvider.instance === 'object') {
      return AuthProvider.instance
    }
    AuthProvider.instance = this
    return AuthProvider.instance
  }

  public registerRouter(router): void {
    this.$router = router
  }

  public registerStore(store): void {
    this.$store = store
  }

  public registerService(service): void {
    this.authService = service
  }

  public async logout() {
    this.$store.commit('nApp/authReset')
    this.$store.dispatch('auth/logout')
    this.$store.dispatch('app/unsetApps')
    await this.$router.replace({ name: 'auth.login' })
  }

  public async clearDataForGPT() {
    this.$store.commit('nApp/authReset')
    this.$store.dispatch('auth/logout')
    this.$store.dispatch('app/unsetApps')
  }

  public findReferral() {
    const referralId = this.authService.findReferralByQueryParam()
    this.authService.saveReferralToLocalStorage(referralId)
    const ref = this.authService.getReferal()
    this.$store.commit('nApp/setReferralUserId', ref)
  }

  public getToken(tokenType = 'crm') {
    let storeAuth = this.authService.getTokenCRM()
    if (this.$store.getters) {
      storeAuth = this.$store.getters['nApp/getAuth']
    }
    if (tokenType === 'pwa') {
      return storeAuth.pwaToken
    }
    return storeAuth.token
  }

  public async login(data): Promise<void> {
    const response = await this.authService.login(data)
    if (response.status) {
      this.authService.saveTokenCRM(response.data.access_token)
      this.authService.saveTokenPWA(response.data.client_access_token)
      this.authService.saveUser(response.data.user)
      this.authService.saveClient(response.data.user.client.id)
    }

    return response
  }

  public async loginByQueryToken(): Promise<void> {
    const queryParams = new URL(window.location.href).searchParams
    const token = queryParams.get('auth_token')
    const pwaToken = queryParams.get('chat_token')
    if (token && pwaToken) {
      this.$store.commit('nApp/authSaveData', { token, pwaToken })
      this.authService.saveTokenCRM(token)
      this.authService.saveTokenPWA(pwaToken)
      const response = await this.authService.apiUsersMe()
      if (response.status) {
        this.authService.saveUser(response.data)
        this.authService.saveClient(response.data.client.id)
        this.$store.commit('nApp/authSaveData', { user: response.data })
        this.$router.push({ name: 'Business_Home' })
      }
    }
  }

  public async register(data): Promise<void> {
    const confirmResponse = await this.authService.registerConfirmPhone(data)

    if (confirmResponse.status) {
      this.authService.saveTokenCRM(confirmResponse.data.access_token)
      this.authService.saveTokenPWA(confirmResponse.data.client_access_token)

      this.addTokensToStore()

      const userMeResponse = await this.authService.apiUsersMe()

      if (userMeResponse.status) {
        this.authService.saveUser(userMeResponse.data)
        this.authService.saveClient(userMeResponse.data.client.id)
      }
      return userMeResponse
    }
    return confirmResponse
  }

  public async addTokensToStore(): Promise<void> {
    const token = this.authService.getTokenCRM()
    const pwaToken = this.authService.getTokenPWA()
    if (token && pwaToken) {
      this.$store.commit('nApp/authSaveData', { token, pwaToken })
    }
  }

  public async checkAuthUser(): Promise<void> {
    const token = this.authService.getTokenCRM()
    const pwaToken = this.authService.getTokenPWA()
    const user = this.authService.getUser()
    if (token && pwaToken && user) {
      this.$store.commit('nApp/authSaveData', { token, pwaToken, user })
    }
  }

  public async checkAuthModerator(): Promise<void> {
    const result = this.authService.checkAuthModerator()
    if (Number(result.moderator) === 1) {
      this.$store.dispatch('auth/logout')
      this.$store.dispatch('app/unsetApps')
      this.$store.dispatch('nApp/authModerator', {
        token: result.token,
      })

      const response = await this.authService.apiUsersMe()

      this.$store.dispatch('nApp/authModerator', {
        user: response.status ? response.data : null,
      })

      this.$router.push({ name: 'Business_Home' })
    }
  }

  public checkGPTMode(): void {
    const mode = this.authService.checkGPTMode()
    this.gptMode = mode
  }

  public isGptMode(): boolean {
    return this.gptMode
  }
}

export default new AuthProvider()

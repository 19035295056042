







































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { MoneyIncomesTotalModelAppType } from '@/services/api/MoneyIncomes/MoneyIncomesTypes'

@Component({})
export default class AccrualsTopCards extends Vue {
  money: any

  @VuexGetter('nBusinessAccruals') getMoneyIncomesTotal!: MoneyIncomesTotalModelAppType | any

  @VuexGetter('nApp') getAppCurrencySymbol!: string
}



























































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { TariffAppModelType } from '@/services/api/Tariff/TariffTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import Validation from '@/utils/Validation'
import { TranslateResult } from 'vue-i18n'
import { priceForTariff } from '../settings'

@Component({})
export default class TariffPopUpEdit extends Vue {
  // Data
  formData = {
    amount: '',
    period: 0,
    duration: 0,
  }

  loading = false

  // vuex module getter
  @VuexGetter('nTariffs') getTariffItem!: TariffAppModelType

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nApp') getIsGptApp!: boolean

  // Computed
  get periods(): Array<{ name: TranslateResult; value: number }> {
    return [
      { name: this.$t(`pageBusinessDigital.tariffNew.2`), value: 2 },
      { name: this.$t(`pageBusinessDigital.tariffNew.3`), value: 3 },
      { name: this.$t(`pageBusinessDigital.tariffNew.5`), value: 5 },
      { name: this.$t(`pageBusinessDigital.tariffNew.6`), value: 6 },
    ]
  }

  get validatePrice(): boolean {
    return Number(this.formData.amount) < this.minPrice
  }

  get minPrice(): number {
    return priceForTariff[this.getCurrentApp.type][this.getTariffItem.period][
      this.getCurrentApp.currency.symbol
    ].minPrice
  }

  get rules(): any {
    return {
      price: Validation.getRules([`nummin:${this.minPrice}`, 'required']),
    }
  }

  get durations(): Array<{ name: TranslateResult; value: number }> {
    return [{ name: this.$t(`promoPopUp.duration`), value: 1 }]
  }

  // Emit
  @Emit('updateTariff')
  updateTariff(): any {
    this.loading = true
    return {
      id: this.getTariffItem.id,
      data: this.formData,
    }
  }

  @Emit('closeTariffPopUpEdit')
  closeTariffPopUpEdit(): boolean {
    return false
  }

  mounted(): void {
    this.formData.amount = String(this.getTariffItem?.amount)
    this.formData.period = this.getTariffItem?.period
    this.formData.duration = this.durations[0].value
  }
}

import { cloneDeep } from 'lodash'
import OfferService from '@/services/api/Offer/OfferService'
import ОfferSourcesService from '@/services/api/ОfferSources/ОfferSourcesService'
import ОfferCategoriesService from '@/services/api/ОfferCategories/ОfferCategoriesService'
import StreamService from '@/services/api/Stream/StreamService'
import AppsService from '@/services/api/Apps/AppsService'
import TrafficbackService from '@/services/api/Trafficback/TrafficbackService'
import PostbackService from '@/services/api/Postback/PostbackService'
import { OfferAppModelType, OfferTotalAppModelType } from '@/services/api/Offer/OfferTypes'
import { ОfferSourcesAppModelType } from '@/services/api/ОfferSources/ОfferSourcesTypes'
import { AppsHasOfferAppModelType } from '@/services/api/Apps/AppsTypes'
import { ОfferCategoriesAppModelType } from '@/services/api/ОfferCategories/ОfferCategoriesTypes'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import { TrafficbackAppModelType } from '@/services/api/Trafficback/TrafficbackTypes'
import { PostbackAppModelType } from '@/services/api/Postback/PostbackTypes'
import {
  nAffiliateOffersMutations,
  nAffiliateOffersActions,
  nAffiliateOffersGetters,
  nAffiliateOffersModule,
} from './types'

const defaultState = {
  pageList: {
    offers: {
      loading: false,
      items: [],
      params: OfferService.getDefaultParams(),
    },
    offersTotal: {
      total: null,
      params: OfferService.getDefaultParams(),
    },
    offerCategories: {
      items: [],
      params: ОfferCategoriesService.getDefaultParams(),
    },
    offerSources: {
      items: [],
    },
    appsHasOffer: {
      items: [],
    },
  },
  pageItem: {
    offers: {
      currentItem: null,
    },
    streams: {
      items: [],
      currentItem: null,
      params: StreamService.getDefaultParams(),
      currentCreatedStream: {},
    },
    postbacks: {
      items: [],
      params: PostbackService.getDefaultParams(),
    },
    trafficbacks: {
      items: [],
      params: TrafficbackService.getDefaultParams(),
    },
    deletePopUpStatus: false,
    isOpenCreateStreamForm: false,
  },
}
const actions: nAffiliateOffersActions = {
  async offersGetItems({ commit, state }): Promise<boolean> {
    commit('offersChangeLoadingList', true)
    const response = await OfferService.getItems({
      params: state.pageList.offers.params,
    })
    if (response.status) {
      commit('offersParamsUpdate', response.params)
      commit('offersItemsUpdate', response.data)
    }
    commit('offersChangeLoadingList', false)
    return response.status
  },
  async offersGetItem({ commit }, id): Promise<boolean> {
    const response = await OfferService.getItem({
      id,
    })
    if (response.status) {
      commit('offersCurrentItemUpdate', response.data)
    }
    return response.status
  },
  async offerCategoriesGetItems({ commit, state }): Promise<boolean> {
    const response = await ОfferCategoriesService.getItems({
      params: state.pageList.offerCategories.params,
    })
    if (response.status) {
      commit('offerCategoriesParamsUpdate', response.params)
      commit('offerCategoriesItemsUpdate', response.data)
    }
    return response.status
  },
  async offerTotalGetItems({ commit, state }): Promise<boolean> {
    const response = await OfferService.offerTotalGetItems({
      params: state.pageList.offersTotal.params,
    })
    if (response.status) {
      commit('offersTotalParamsUpdate', response.params)
      commit('offersTotalUpdate', response.data)
    }
    return response.status
  },
  async appsHasOfferGetItems({ commit }): Promise<boolean> {
    const response = await AppsService.appsHasOffer({
      params: {},
    })
    if (response.status) {
      commit('appsHasOfferItemsUpdate', response.data)
    }
    return response.status
  },

  async offerSourcesGetItems({ commit }): Promise<boolean> {
    const response = await ОfferSourcesService.getItems({
      params: {},
    })
    if (response.status) {
      commit('offerSourcesItemsUpdate', response.data)
    }
    return response.status
  },
  async streamsGetItems({ commit, state }): Promise<boolean> {
    const response = await StreamService.getItems({
      params: state.pageItem.streams.params,
    })
    if (response.status) {
      commit('streamsParamsUpdate', response.params)
      commit('streamsItemsUpdate', response.data)
    }
    return response.status
  },
  async streamsGetItem({ commit }, id): Promise<boolean> {
    const response = await StreamService.getItem({ id })
    if (response.status) {
      commit('streamsCurrentItemUpdate', response.data)
    }
    return response.status
  },

  async streamsDeleteItem({ commit }, id): Promise<void> {
    await StreamService.deleteItem(id)
  },

  async streamsCreateItem({ commit }, data): Promise<boolean> {
    const response = await StreamService.createItem({
      data,
      params: {},
    })
    if (response.status) {
      commit('setCurrentCreatedStream', response.data)
    }
    return response.status
  },

  async streamsUpdateItem({ commit, state }, { data }): Promise<boolean> {
    const response = await StreamService.updateItem({
      data,
      id: state.pageItem.streams.currentItem.id,
    })
    return response.status
  },

  async postbacksGetItems({ commit, state }): Promise<boolean> {
    const response = await PostbackService.getItems({
      params: state.pageItem.postbacks.params,
    })
    if (response.status) {
      commit('postbacksParamsUpdate', response.params)
      commit('postbacksItemsUpdate', response.data)
    }
    return response.status
  },

  async postbacksCreateItems({ commit, state }, data): Promise<void> {
    for (let i = 0; i < data.length; i++) {
      const postback = {
        eventInstall: data[i].eventInstall,
        eventOrder: data[i].eventOrder,
        eventOrderConfirm: data[i].eventOrderConfirm,
        eventOrderDecline: data[i].eventOrderDecline,
        eventSubscription: data[i].eventSubscription,
        eventSubscriptionRepeat: data[i].eventSubscriptionRepeat,
        method: data[i].method,
        stream: `/api/streams/${
          state.pageItem.streams.currentCreatedStream.id || state.pageItem.streams.currentItem.id
        }`,
        url: data[i].url,
      }
      if (postback.url.length > 0) {
        await PostbackService.createItem({ data: postback, params: {} })
      }
    }
  },
  async postbacksUpdateItem({ commit }, { data, id }): Promise<boolean> {
    const response = await PostbackService.updateItem({ data, id })
    return response.status
  },
  async postbacksDeleteItem({ commit }, id): Promise<void> {
    await PostbackService.deleteItem({ id })
  },
  async trafficbacksGetItems({ commit, state }): Promise<boolean> {
    const response = await TrafficbackService.getItems({
      params: state.pageItem.trafficbacks.params,
    })
    if (response.status) {
      commit('trafficbacksParamsUpdate', response.params)
      commit('trafficbacksItemsUpdate', response.data)
    }
    return response.status
  },
  async trafficbacksCreateItems({ commit, state }, data): Promise<void> {
    for (let i = 0; i < data.length; i++) {
      const trafficback = {
        stream: `/api/streams/${
          state.pageItem.streams.currentCreatedStream.id || state.pageItem.streams.currentItem.id
        }`,
        url: data[i].url,
        countries: `[${data[i].countries}]`,
      }
      if (trafficback.url.length > 0) {
        await TrafficbackService.createItem({ data: trafficback, params: {} })
      }
    }
  },
  async trafficbacksUpdateItem({ commit }, { data, id }): Promise<boolean> {
    const response = await TrafficbackService.updateItem({ data, id })
    return response.status
  },
  async trafficbacksDeleteItem({ commit }, id): Promise<void> {
    await TrafficbackService.deleteItem({ id })
  },

  async streamsGetItemForEdit({ commit, dispatch, state }, id): Promise<void> {
    await dispatch('streamsGetItem', id)
    commit('postbacksParamsUpdate', {
      stream: state.pageItem.streams.currentItem.id,
      pagination: false,
    })
    await dispatch('postbacksGetItems')
    commit('trafficbacksParamsUpdate', {
      stream: state.pageItem.streams.currentItem.id,
      pagination: false,
    })
    await dispatch('trafficbacksGetItems')
  },

  async streamsCreate({ commit, dispatch }, { data }): Promise<void> {
    await dispatch('streamsCreateItem', data.streamData)
    await dispatch('postbacksCreateItems', data.postbacks)
    await dispatch('trafficbacksCreateItems', data.trafficbacks)
    commit('setCurrentCreatedStream', {})
  },

  async streamsUpdate({ dispatch }, { data }): Promise<void> {
    await dispatch('streamsUpdateItem', { data: data.streamData })
    for (let i = 0; i < data.postbacks.length; i++) {
      if ('id' in data.postbacks[i]) {
        await dispatch('postbacksUpdateItem', {
          data: data.postbacks[i],
          id: data.postbacks[i].id,
        })
      } else {
        await dispatch('postbacksCreateItems', [data.postbacks[i]])
      }
    }
    for (let i = 0; i < data.needDelete.postback.length; i++) {
      await dispatch('postbacksDeleteItem', data.needDelete.postback[i])
    }
    for (let i = 0; i < data.trafficbacks.length; i++) {
      if ('id' in data.trafficbacks[i]) {
        data.trafficbacks[i].countries = `[${data.trafficbacks[i].countries}]`
        await dispatch('trafficbacksUpdateItem', {
          data: data.trafficbacks[i],
          id: data.trafficbacks[i].id,
        })
      } else {
        await dispatch('trafficbacksCreateItems', [data.trafficbacks[i]])
      }
    }
    for (let i = 0; i < data.needDelete.trafficback.length; i++) {
      await dispatch('trafficbacksDeleteItem', data.needDelete.trafficback[i])
    }
  },
}

const mutations: nAffiliateOffersMutations = {
  offersChangeLoadingList(state, status: boolean): void {
    state.pageList.offers.loading = status
  },
  offersItemsUpdate(state, items: Array<OfferAppModelType>): void {
    state.pageList.offers.items = items
  },
  offersCurrentItemUpdate(state, item: OfferAppModelType): void {
    state.pageItem.offers.currentItem = item
  },
  appsHasOfferItemsUpdate(state, items: Array<AppsHasOfferAppModelType>): void {
    state.pageList.appsHasOffer.items = items
  },
  offersTotalUpdate(state, item: OfferTotalAppModelType): void {
    state.pageList.offersTotal.total = item
  },
  offerSourcesItemsUpdate(state, items: Array<ОfferSourcesAppModelType>): void {
    state.pageList.offerSources.items = items
  },
  offersParamsUpdate(state, params): void {
    state.pageList.offers.params = { ...state.pageList.offers.params, ...params }
  },
  offersTotalParamsUpdate(state, params): void {
    state.pageList.offersTotal.params = { ...state.pageList.offersTotal.params, ...params }
  },
  streamsParamsUpdate(state, params): void {
    state.pageItem.streams.params = { ...state.pageItem.streams.params, ...params }
  },
  offerCategoriesParamsUpdate(state, params): void {
    state.pageList.offerCategories.params = { ...state.pageList.offerCategories.params, ...params }
  },
  postbacksParamsUpdate(state, params): void {
    state.pageItem.postbacks.params = { ...state.pageItem.postbacks.params, ...params }
  },
  trafficbacksParamsUpdate(state, params): void {
    state.pageItem.trafficbacks.params = { ...state.pageItem.trafficbacks.params, ...params }
  },
  offerCategoriesItemsUpdate(state, items: ОfferCategoriesAppModelType[]): void {
    state.pageList.offerCategories.items = items
  },
  streamsItemsUpdate(state, items: StreamAppModelType[]): void {
    state.pageItem.streams.items = items
  },
  postbacksItemsUpdate(state, items: PostbackAppModelType[]): void {
    state.pageItem.postbacks.items = items
  },
  trafficbacksItemsUpdate(state, items: TrafficbackAppModelType[]): void {
    state.pageItem.trafficbacks.items = items
  },
  streamsCurrentItemUpdate(state, item: StreamAppModelType): void {
    state.pageItem.streams.currentItem = item
  },
  streamsDeletePopUpChangeStatus(state, status: boolean): void {
    state.pageItem.deletePopUpStatus = status
  },
  setCurrentCreatedStream(state, data) {
    state.pageItem.streams.currentCreatedStream = data
  },
  setIsOpenCreateStreamFormStatus(state, status) {
    state.pageItem.isOpenCreateStreamForm = status
  },
}

const getters: nAffiliateOffersGetters = {
  getOffersListLoading(state): boolean {
    return state.pageList.offers.loading
  },
  getOffersItems(state): [] | OfferAppModelType[] {
    return state.pageList.offers.items
  },
  getOffersTotal(state): [] | OfferTotalAppModelType[] {
    return state.pageList.offersTotal.total
  },
  getOffersCurrentItem(state): OfferAppModelType | null {
    return state.pageItem.offers.currentItem
  },
  getAppsHasOfferItems(state): [] | AppsHasOfferAppModelType[] {
    return state.pageList.appsHasOffer.items
  },
  getOfferSoucesItems(state): [] | ОfferSourcesAppModelType[] {
    return state.pageList.offerSources.items
  },
  getPostbacksItems(state): [] | PostbackAppModelType[] {
    return state.pageItem.postbacks.items
  },
  getTrafficbacksItems(state): [] | TrafficbackAppModelType[] {
    return state.pageItem.trafficbacks.items
  },
  getStreamsItems(state): [] | StreamAppModelType[] {
    return state.pageItem.streams.items
  },
  getStreamsCurrentItem(state): [] | StreamAppModelType {
    return state.pageItem.streams.currentItem
  },
  getOffersParams(state): unknown {
    return state.pageList.offers.params
  },
  getOfferCategoriesItems(state): [] | ОfferCategoriesAppModelType[] {
    return state.pageList.offerCategories.items
  },
  getSteamDeletePopUpStatus(state): boolean {
    return state.pageItem.deletePopUpStatus
  },
  getIsOpenCreateStreamFormStatus(state): boolean {
    return state.pageItem.isOpenCreateStreamForm
  },
}

const nAffiliateOffers: nAffiliateOffersModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nAffiliateOffers

<template>
  <div class="pa-4 d-flex justify-center" v-if="pagination.page < countTotalPages && !loading">
    <v-progress-circular indeterminate color="primary" v-intersect="afterScrollEnd" />
  </div>
</template>

<script>
export default {
  name: 'AppInfinityLoading',
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    countTotalPages() {
      return Math.ceil(this.pagination.total / this.pagination.rowsPerPage)
    },
  },
  watch: {
    pagination() {
      this.loading = false
    },
  },
  methods: {
    afterScrollEnd(entries, observer, isIntersecting) {
      if (this.pagination.page < this.countTotalPages && isIntersecting) {
        this.loading = true
        this.$emit('next-page', this.pagination.page + 1)
        this.$emit('load-list')
      }
    },
  },
}
</script>

<style scoped></style>

import { StoresGetters } from './types'

const getters: StoresGetters = {
  getTest(state) {
    return state.test
  },
  getStoresList(state) {
    return state.stores.list
  },
  getStoreItem(state) {
    return state.stores.item
  },
}

export default getters

<script>
import VTextField from 'vuetify/lib/components/VTextField/VTextField'

export default {
  name: 'AppVTextField',
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    backgroundColor: {
      type: String,
      default: 'grey lighten-2',
    },
    persistentPlaceholder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      return {
        ...VTextField.options.computed.classes.call(this),
        'is-show-label': this.showLabel,
        'is-prepend-inner-icon': this.prependInnerIcon,
        'is-append-slot': !!this.$slots.append,
      }
    },
  },
}
</script>

<style scoped></style>

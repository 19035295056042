























import { Component, Vue, Emit } from 'vue-property-decorator'

@Component({})
export default class PayoutTypes extends Vue {
  // Computed
  get paymentTypes(): any[] {
    return [
      {
        title: 'RUB',
        subTitle: this.$t('requisites.tabs.1.subtitle'),
        type: 1,
      },
    ]
  }

  // Emits
  @Emit()
  input(value: number): number | null {
    return value
  }
}























import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AffiliateInstructions',
})
export default class AffiliateInstructions extends Vue {}

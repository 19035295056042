import { cloneDeep } from 'lodash'
import UserService from '@/services/api/User/UserService'
import { setUser } from '@/utils/user'
import { ResponseTypes } from '@/services/api/BaseApiService'
import { nProfileMutations, nProfileActions, nProfileGetters, nProfileModule } from './types'

const defaultState = {
  empty: null,
}
const actions: nProfileActions = {
  async updateUser({ commit }, { id, data, notification }): Promise<ResponseTypes> {
    const response = await UserService.updateItem({
      id,
      data,
      notification,
    })
    if (response.status) {
      commit('nApp/authSaveData', { user: response.data }, { root: true })
      setUser(response.data)
    }
    return response
  },
}

const mutations: nProfileMutations = {}

const getters: nProfileGetters = {}

const nProfile: nProfileModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nProfile

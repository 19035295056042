






import { Component, Prop, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class MoneyOutcomeTableColStatus extends Vue {
  @Prop(Number) readonly status: number | undefined

  get settings(): {
    iconClass?: string
    textClass?: string
    textValue?: TranslateResult
  } {
    // default status = 1
    let result = {
      iconClass: 'deep-purple lighten-5',
      textClass: 'deep-purple--text text--lighten-1',
      textValue: this.$t('pending'),
    }
    if (this.status === 2) {
      result = {
        iconClass: 'teal accent-3',
        textClass: 'teal--text text--accent-3',
        textValue: this.$t('completed'),
      }
    }
    if (this.status === 3) {
      result = {
        iconClass: 'deep-purple darken-4',
        textClass: 'deep-purple--text text--darken-4',
        textValue: this.$t('rejected'),
      }
    }
    return result
  }
}

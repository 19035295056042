








































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

import AffiliatePageFeature from '@/components/AffiliatePageFeature.vue'
import ProfitSlider from '@/components/ProfitSlider.vue'

@Component({
  components: {
    AffiliatePageFeature,

    ProfitSlider,
  },
})
export default class AffiliateHome extends Vue {
  // Data

  price = 6500

  followers = 5000

  // Vuex module getter

  @VuexGetter('nApp') getlocale!: string

  // Computed
  get profit(): {
    advertiseMonth: number
    advertiseYear: number
    webmasterMonth: number
    webmasterYear: number
  } {
    return {
      advertiseMonth: this.followers * this.price,
      advertiseYear: this.followers * this.price * 12,
      webmasterMonth: this.followers * this.price * 0.5,
      webmasterYear: this.followers * this.price * 0.5 * 12,
    }
  }

  get getCurrencySymbol(): string {
    return this.getlocale === 'ru' ? '₽' : '$'
  }

  // Methods
  setPriceForLocale(): void {
    if (this.getlocale === 'ru') {
      this.price = 6500
    } else {
      this.price = 60
    }
  }

  // Watchers
  @Watch('getlocale')
  getlocaleHander(): void {
    this.setPriceForLocale()
  }

  // Hooks
  mounted(): void {
    this.setPriceForLocale()
  }
}

import CurrenciesService from '@/services/api/Currencies/CurrenciesService'
import CountriesService from '@/services/api/Countries/CountriesService'
import PaymentSystemsService from '@/services/api/PaymentSystems/PaymentSystemsService'
import PayoutSystemsService from '@/services/api/PayoutSystems/PayoutSystemsService'
import { CountriesAppModelType } from '@/services/api/Countries/CountriesTypes'
import { CurrenciesAppModelType } from '@/services/api/Currencies/CurrenciesTypes'
import { PaymentSystemsAppModelType } from '@/services/api/PaymentSystems/PaymentSystemsTypes'
import { PayoutSystemsAppModelType } from '@/services/api/PayoutSystems/PayoutSystemsType'
import { nDeskbookMutations, nDeskbookActions, nDeskbookGetters, nDeskbookModule } from './types'

const actions: nDeskbookActions = {
  async fetchCurrencies({ commit }): Promise<void> {
    const response = await CurrenciesService.getItems({})
    commit('saveData', {
      data: response.status ? response.data : [],
      model: 'currencies',
    })
  },
  async fetchCountries({ commit, getters }): Promise<void> {
    if (!getters.getListCountries.length) {
      const response = await CountriesService.getItems({})
      commit('saveData', {
        data: response.status ? response.data : [],
        model: 'countries',
      })
    }
  },
  async fetchPaymentSystems({ commit }): Promise<void> {
    const response = await PaymentSystemsService.getItems({})
    commit('saveData', {
      data: response.status ? response.data : [],
      model: 'paymentSystems',
    })
  },
  async fetchPayoutSystems({ commit }): Promise<void> {
    const response = await PayoutSystemsService.getItems({})
    commit('saveData', {
      data: response.status ? response.data : [],
      model: 'payoutSystems',
    })
  },
}

const mutations: nDeskbookMutations = {
  saveData<D>(state, options: { data: D; model: string }): void {
    state.models[options.model] = options.data
  },
}

const getters: nDeskbookGetters = {
  getListCurrencies(state): [CurrenciesAppModelType] | [] {
    return state.models.currencies
  },
  getListCountries(state): [CountriesAppModelType] | [] {
    return state.models.countries
  },
  getListPaymentSystems(state): [PaymentSystemsAppModelType] | [] {
    return state.models.paymentSystems
  },
  getListPayoutSystems(state): [PayoutSystemsAppModelType] | [] {
    return state.models.payoutSystems
  },
}

const nDeskbook: nDeskbookModule = {
  state: () => ({
    models: {
      countries: [],
      currencies: [],
      paymentSystems: [],
      payoutSystems: [],
    },
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nDeskbook

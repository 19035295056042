<template>
  <div class="supster-ui--composite__wrapper">
    <draggable class="row" v-model="selected" handle=".handle">
      <v-col
        class="drag-on"
        :cols="cols"
        v-for="(itemSelect, index) in selected"
        :key="`drag-${index}`"
      >
        <div class="d-flex align-end">
          <div>
            <label class="supster-ui--input__label ml-2"> {{ label }} {{ index + 1 }} </label>
            <input-select
              v-model="selected[index]"
              :items="resultList"
              :selectProp="selectProp"
              :itemText="itemText"
              :itemValue="itemValue"
              :width="width"
              :readonly="itemSelectDisable(itemSelect)"
            ></input-select>
          </div>
          <div
            v-for="(param, paramIndex) in itemValues"
            :key="`${param.name}-${paramIndex}`"
            class="ml-1"
          >
            <label class="supster-ui--input__label ml-2"> {{ param.label }} </label>
            <app-v-text-field-currency
              :moneySuffix="param.money ? param.money : ''"
              v-model="selected[index][param.name]"
              :width="param.width"
              v-mask="param.mask || ''"
            ></app-v-text-field-currency>
          </div>
          <v-btn
            v-if="!itemSelectDisable(itemSelect)"
            class="ml-1 rounded-md"
            color="primary lighten-1"
            min-width="40"
            width="40"
            @click="removeItem(index)"
          >
            <icon data="@/assets/icons/close.svg" width="12px" />
          </v-btn>
          <v-btn
            class="handle rounded-md ml-1"
            color="ground"
            min-width="40"
            width="40"
            v-if="draggable"
          >
            <icon data="@/assets/icons/drag.svg" width="16px" />
          </v-btn>
        </div>
      </v-col>
      <v-col :cols="cols">
        <label class="supster-ui--input__label ml-2"> {{ label }} {{ selected.length + 1 }} </label>
        <input-select
          :items="resultList"
          :selectProp="selectProp"
          :itemText="itemText"
          :itemValue="itemValue"
          :holdValue="true"
          @input="addItem"
          :placeholder="placeholder"
          :width="width"
        ></input-select>
      </v-col>
    </draggable>
  </div>
</template>

<script>
import Vue from 'vue'
import draggable from 'vuedraggable'
import i18n from '@/plugins/i18n'

export default Vue.extend({
  name: 'InputCompositeSelect',
  components: { draggable },
  data: () => ({
    selected: [],
  }),
  props: {
    value: {
      default: '',
    },
    items: {
      default: false,
    },
    selectProp: {
      default: false,
    },
    itemText: {
      default: 'label',
    },
    itemValue: {
      default: 'value',
    },
    placeholder: {
      default: i18n.t('chooseFromSelect'),
    },
    label: {
      default: '',
    },
    holdValue: {
      default: false,
    },
    width: {
      default: false,
    },
    cols: {
      default: 12,
    },
    draggable: {
      default: true,
    },
    itemValues: {
      default() {
        return []
      },
    },
    itemSelectDisable: {
      default: (item = true) => item,
    },
  },
  computed: {
    usedItems() {
      const selectedItems = this.selected.map((item) => this.getModelValue(item))
      return this.items.filter((item) => selectedItems.includes(this.getModelValue(item)))
    },
    resultList() {
      const selectedItems = this.selected.map((item) => this.getModelValue(item))
      return this.items.filter((item) => !selectedItems.includes(this.getModelValue(item)))
    },
  },
  methods: {
    addItem(item) {
      this.selected.push(item)
      this.updateValue()
    },
    removeItem(index) {
      this.$emit('removedItem', this.selected[index])
      this.selected.splice(index, 1)
      this.updateValue()
    },
    updateValue() {
      this.$emit('change', this.selected)
      this.$emit('input', this.selected)
    },
    getModelLabel(item) {
      return this.selectProp ? item[this.selectProp][this.itemText] : item[this.itemText]
    },
    getModelValue(item) {
      return this.selectProp ? item[this.selectProp][this.itemValue] : item[this.itemValue]
    },
  },
  async created() {
    // if (this.items[0]) {
    // this.addItem(this.items[0])
    // }
    if (this.value.length > this.selected.length) {
      this.selected = this.value
    }
    const unwatch = this.$watch('value', (val) => {
      if (val.length > this.selected.length) {
        this.selected = val
      }
      unwatch()
    })
  },
  watch: {
    // items(value) {
    // },
  },
})
</script>
<style lang="scss"></style>

























import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { ArticlesAppModelType } from '@/services/api/Articles/ArticlesTypes'

@Component({})
export default class News extends Vue {
  // Props
  @Prop(Object) readonly article!: ArticlesAppModelType

  // Data
  expanded = false

  // Methods
  parseDate(date: string): string[] {
    return [moment(date).locale(this.$i18n.locale).format('DD MMMM'), moment(date).format('YYYY')]
  }
}

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
// import moment from 'moment'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'

interface OrdersModelAppTypes {
  id?: number
  order: {
    id?: number
  }
}
interface OrdersModelApiTypes {
  id?: number
}

class OrderPositionsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.orderPosition')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: OrdersModelAppTypes = {
    order: {},
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.ORDER_POSITIONS)
  }

  public getEmptyItem(): OrdersModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(data): OrdersModelApiTypes {
    return data
  }

  public transformDataForApp(incomingData, action): OrdersModelAppTypes {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }
}
export default new OrderPositionsService()

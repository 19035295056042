import { cloneDeep } from 'lodash'
import ArticlesService from '@/services/api/Articles/ArticlesService'
import { ArticlesAppModelType } from '@/services/api/Articles/ArticlesTypes'

import {
  nAffiliateNewsMutations,
  nAffiliateNewsActions,
  nAffiliateNewsGetters,
  nAffiliateNewsModule,
} from './types'

const defaultState = {
  articles: {
    loading: false,
    items: [],
    params: ArticlesService.getDefaultParams(),
  },
}
const actions: nAffiliateNewsActions = {
  async articlesGetItems({ commit, state }): Promise<boolean> {
    commit('articlesChangeLoadingList', true)
    const response = await ArticlesService.getItems({
      params: state.articles.params,
    })
    if (response.status) {
      commit('articlesParamsUpdate', response.params)
      commit('articlesItemsUpdate', response.data)
    }
    commit('articlesChangeLoadingList', false)
    return response.status
  },
}

const mutations: nAffiliateNewsMutations = {
  articlesChangeLoadingList(state, status: boolean): void {
    state.articles.loading = status
  },
  articlesItemsUpdate(state, items: Array<ArticlesAppModelType>): void {
    state.articles.items = items
  },
  articlesParamsUpdate(state, params): void {
    state.articles.params = { ...state.articles.params, ...params }
  },
}

const getters: nAffiliateNewsGetters = {
  getArticlesItems(state): [] | ArticlesAppModelType[] {
    return state.articles.items
  },
  getArticlesParams(state): any {
    return state.articles.params
  },
  getArticlesListLoading(state): boolean {
    return state.articles.loading
  },
}

const nAffiliateNews: nAffiliateNewsModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nAffiliateNews

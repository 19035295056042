








































































import { Component, Vue, Emit, Ref } from 'vue-property-decorator'
import Validation from '@/utils/Validation'
import { VuexGetter } from '@/store/decorators'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { formDataType } from '../types'

@Component({})
export default class DomainParkingForm extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Data
  formData: formDataType = {
    name: '',
    app: null,
    description: '',
  }

  rules: any = {
    name: Validation.getRules(['required', 'hostName']),
  }

  // vuex module getter
  @VuexGetter('nBusinessDomainParking') getCreateDomainsLoadingStatus!: boolean

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Methods
  formValidate(): void {
    if (!this.form.validate()) {
      return
    }
    this.domainCreate()
  }

  // Emit
  @Emit('domainCreate')
  domainCreate(): formDataType {
    console.log(this.getCurrentApp)
    this.formData.app = this.getCurrentApp.id.toString()
    return this.formData
  }
}

<template>
  <v-dialog
    overlay-opacity="0.8"
    :transition="false"
    class="com-popup-form"
    v-model="dialog"
    persistent
    :max-width="maxWidth"
    :content-class="contentClass"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator">
        <v-btn
          v-if="!noActivator"
          class="float-md-right mt-0"
          color="#27C793"
          dark
          small
          v-bind="attrs"
          v-on="on"
        >
          {{ title }}
        </v-btn>
      </slot>
    </template>
    <template v-slot:default="val">
      <v-card v-if="dialog" :style="{ padding: padding }">
        <button class="popup-form__close-button" @click="closePopup">
          <v-icon class="popup-form__close-icon">mdi-close</v-icon>
        </button>
        <v-card-title class="px-0 pt-0 pb-10" v-if="$slots.header || title.length > 0">
          <slot name="header">
            <h3 class="popup-form__title">{{ title }}</h3>
          </slot>
        </v-card-title>
        <v-card-text class="px-0 py-0" v-if="$slots.content">
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions class="px-0 py-0">
          <slot name="footer" v-bind:dialog="val"></slot>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'PopupForm',
  props: {
    title: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
    noActivator: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '600',
    },
    padding: {
      type: String,
      default: '40px',
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    closePopup() {
      this.dialog = false
    },
    openPopup() {
      this.dialog = true
    },
  },
})
</script>

<style lang="scss">
.popup-form__close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
}

.popup-form__close-icon {
  color: var(--v-primary-base);
}

.popup-form__title {
  color: var(--v-primary-base);
  font-weight: 800;
  font-size: 24px;
}
</style>

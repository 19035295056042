































import Vue from 'vue'
import AuthDefaultContainer from '@/views/auth/components/AuthDefaultContainer.vue'

export default Vue.extend({
  name: 'Terms',
  components: {
    AuthDefaultContainer,
  },
})





























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import DefaultAppPreview from './components/DefaultAppPreview.vue'
import GptAppPreview from './components/GptAppPreview.vue'

@Component({
  components: { DefaultAppPreview, GptAppPreview },
})
export default class AppPreview extends Vue {
  //  Props
  @Prop({ required: true, type: Object }) readonly app!: AppsAppModelType

  @Prop({ type: Object }) readonly avatar!: any

  @Prop({ type: Array }) readonly posts!: PostsAppModelType[]

  @Prop({ default: false, type: Boolean }) readonly edit!: boolean

  @Prop(String) readonly pageType!: string

  @Prop(String) readonly color!: string

  @Prop(String) readonly editMode!: string

  @Prop(String) readonly message!: string

  @Prop(Array) readonly prompts!: string[]

  @Prop({ type: Object }) readonly systemPromt!: any

  @Prop({ type: Object }) readonly defaultChatLogo!: any

  get isGptApp(): boolean {
    return this.app?.type === 'chat_gpt'
  }

  @Emit('selectPost')
  selectPost(post: { item: PostsAppModelType; index: number }): {
    item: PostsAppModelType
    index: number
  } {
    return post
  }
}

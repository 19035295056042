<script>
import VRadio from 'vuetify/lib/components/VRadioGroup/VRadio'

export default {
  name: 'AppVRadio',
  extends: VRadio,
  props: {
    offIcon: {
      type: String,
      default: '$vuetify.icons.radioOff',
    },
    onIcon: {
      type: String,
      default: '$vuetify.icons.radioOn',
    },
  },
}
</script>

<style scoped></style>

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { PayoutAccountApiModelType, PayoutAccountAppModelType } from './PayoutAccountType'

class PayoutAccountService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.payoutAccounts')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PAYOUT_ACCOUNT)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  public transformDataForApi(incomingData): PayoutAccountApiModelType {
    const data = cloneDeep(incomingData)
    data.payoutSystem = `${ENDPOINTS.PAYOUT_SYSTEMS}/${data.payoutSystem}`
    if (data.data.number) {
      data.data.number = data.data.number.split(' ').join('')
    }

    return data
  }

  public transformDataForApp(incomingData): PayoutAccountAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }
}
export default new PayoutAccountService()




































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import moment from 'moment'
import { SubscriptionAppModelType } from '@/services/api/Subscription/SubscriptionTypes'
import { tableHeadersType } from '../types'

@Component({})
export default class ManagmentTable extends Vue {
  // Data
  money: any

  // Computed
  get tableHeaders(): tableHeadersType[] {
    return [
      { text: this.$t('dateAndTime'), value: 'created' },
      { text: this.$t('clientID'), value: 'clientId' },
      { text: this.$t('tariff'), value: 'tariff' },
      { text: this.$t('subscriptionManagment.lastTry'), value: 'lastPaymentDate' },
      { text: this.$t('subscriptionManagment.nextTry'), value: 'next_payment_date' },
      { text: this.$t('subscriptionManagment.paidTo'), value: 'paidTo' },
      { text: this.$t('status'), value: 'status' },
      { text: '', value: 'actions', width: '49px' },
    ]
  }

  // vuex module getter
  @VuexGetter('nBusinessManagment') getSubscriptionListItems!: Array<SubscriptionAppModelType>

  @VuexGetter('nBusinessManagment') getSubscriptionListLoading!: boolean

  @VuexGetter('nBusinessManagment') getSubscriptionListParams!: any

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  // Methods
  setDateFormatForTable(date: string, format: string): string {
    return moment(date).locale(this.$i18n.locale).format(format)
  }

  // Emit
  @Emit('openPopUpDetails')
  openPopUpDetails(id: number): number {
    return id
  }

  @Emit('tableChangePage')
  tableChangePage(page: number): number {
    return page
  }
}

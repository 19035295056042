


























import { Component, Watch, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

import LocMessage from './_LocMessage.vue'

@Component({
  components: {
    LocMessage,
  },
})
export default class DialogMessages extends Vue {
  $refs!: {
    end: HTMLInputElement
    messages: HTMLInputElement
  }

  @VuexGetter('nChats') getDialogLoading!: any

  @VuexGetter('nChats') getDialogMessages!: any

  @VuexGetter('nChats') getDialogControlFormSubmitProccess!: boolean

  @VuexGetter('nChats') getMyClientId!: boolean

  async onScroll(event: { target: HTMLElement }): Promise<void> {
    const { params } = this.getDialogMessages
    if (
      this.getDialogMessages.params.loading ||
      params.page >= params.pages ||
      params.total === this.getDialogMessages.data.length
    ) {
      return
    }
    const el = event.target
    const stopScrollMessage = params.page * params.limit - 1
    if (el.scrollTop < 500) {
      await this.$emit('messagesOnScroll')
      setTimeout(() => {
        this.scrollToElement(this.$refs.messages[stopScrollMessage].$el)
      }, 100)
    }
  }

  scrollToElement(element: any): void {
    const el = element || this.$refs.end
    if (el) {
      el.scrollIntoView({ behavior: 'auto', alignToTop: false, block: 'start' })
    }
  }

  @Watch('getDialogControlFormSubmitProccess', { immediate: true, deep: true })
  scrollEndAfterSubmit(value: boolean): void {
    if (value === false) {
      setTimeout(() => {
        this.scrollToElement(this.$refs.end)
      }, 10)
    }
  }

  @Watch('getDialogLoading', { immediate: true, deep: true })
  scrollEndWitchChangeClient(value: boolean): void {
    if (value === false) {
      setTimeout(() => {
        this.scrollToElement(this.$refs.end)
      }, 10)
    }
  }

  mounted(): void {
    this.scrollToElement(this.$refs.end)
  }
}

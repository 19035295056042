




































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { filterDataType, filterButtonsType } from '../types'

@Component({})
export default class AccrualsFilter extends Vue {
  // Data
  filterData: filterDataType = {
    created: [],
    group: ['all'],
  }

  // Vuex module getters
  @VuexGetter('nBusinessAccruals') getMoneyIncomesListItems!: []

  @VuexGetter('nBusinessAccruals') getMoneyIncomesListParams!: any

  // Computed
  get filterButtons(): filterButtonsType[] {
    return [
      {
        value: 'all',
        name: this.$t('accrualsPage.filterButtons.1'),
      },
      {
        value: 'app_buy_product',
        name: this.$t('accrualsPage.filterButtons.2'),
      },
      {
        value: 'subscriptions',
        name: this.$t('accrualsPage.filterButtons.3'),
      },
      {
        value: 'affilate_bonus',
        name: this.$t('accrualsPage.filterButtons.4'),
      },
      {
        value: 'referral_bonus',
        name: this.$t('accrualsPage.filterButtons.5'),
      },
    ]
  }

  // Methods
  sortableHandler(event: string[]): void {
    if (event.includes('all') && event.length > 1) {
      this.filterData.group = this.filterData.group.filter((i) => i !== 'all')
    }
    if (event[event.length - 1] === 'all') {
      this.filterData.group = ['all']
    }
    if (this.filterData.group.length === 0) {
      this.filterData.group = ['all']
    }
    this.filterHandler()
  }

  setSavedFilterData(): void {
    const keysFilterData = Object.keys(this.filterData)
    keysFilterData.forEach((i) => {
      if (this.getMoneyIncomesListParams[i]) {
        this.filterData[i] = this.getMoneyIncomesListParams[i]
      }
    })
  }

  filterHandler(): void {
    setTimeout(() => {
      this.filter()
    }, 100)
  }

  // Hooks
  created(): void {
    this.setSavedFilterData()
  }

  // Emit
  @Emit()
  filter(): filterDataType {
    return this.filterData
  }
}

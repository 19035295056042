var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-v-autocomplete',_vm._b({attrs:{"value":_vm.value,"items":_vm.countries,"return-object":_vm.returnObject,"placeholder":_vm.placeholder,"multiple":_vm.multiple,"hide-no-data":false,"label":_vm.label,"error":_vm.error,"background-color":"grey lighten-2"},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('div',{staticClass:"d-flex mr-2 font-weight-bold align-center"},[_c('country-flag',{staticClass:"mr-2",attrs:{"code":item.code}}),_c('span',[_vm._v(_vm._s(item.name))])],1):_vm._e(),(index === 1 && _vm.multiple)?_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(" (+"+_vm._s(_vm.value.length - 1)+") ")]):_vm._e()]}},{key:"label",fn:function(){return [_vm._t("label")]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(_vm.multiple)?_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"ripple":false,"value":active}})],1):_vm._e(),_c('v-list-item-content',[_c('div',{staticClass:"d-flex align-center"},[_c('country-flag',{staticClass:"mr-2",attrs:{"code":item.code}}),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true)},'app-v-autocomplete',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }
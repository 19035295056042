<template>
  <auth-container ref="top" :mini="mini" :light="light">
    <template #header>
      <div class="d-flex justify-space-between align-center w-full">
        <button class="d-flex align-center" @click="goHome">
          <img :src="logoImgPath" />
        </button>
        <!-- <button
          @click="goHome"
          class="d-flex align-center text-hovered text-decoration-none text-xsmall"
          :class="commonLinkColorClass"
        >
          <icon data="@/assets/icons/arrow-left.svg" width="8" height="16" class="mr-4 mt-1 mb-1" />
          <span class="text-xsmall">{{ $t('toHome') }}</span>
        </button> -->
      </div>
    </template>
    <slot />
    <template #footer>
      <span class="text-xsmall" :class="copyrightLinkColorClass">
        © {{ getCurrentYear() }}. Supster.me
      </span>
      <!-- <div class="">
        <router-link
          v-if="$route.name !== 'Terms'"
          class="text-hovered text-decoration-none text-xsmall"
          :class="commonLinkColorClass"
          to="/terms"
        >
          {{ $t('terms') }}
        </router-link>
      </div> -->
    </template>
  </auth-container>
</template>

<script>
import Vue from 'vue'
import { ProjectGetBaseURL } from '@/utils/project'
import AuthContainer from './AuthContainer.vue'

export default Vue.extend({
  name: 'AuthDefaultContainer',
  props: {
    mini: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AuthContainer,
  },

  computed: {
    logoImgPath() {
      return require(this.light ? '@/assets/images/dark-logo.svg' : '@/assets/images/logo.svg')
    },
    backArrowImgPath() {
      return require(this.light
        ? '@/assets/images/green-back-arrow.svg'
        : '@/assets/images/back-arrow.svg')
    },
    commonLinkColorClass() {
      return this.light ? 'text-primary' : 'text-accent-light'
    },
    copyrightLinkColorClass() {
      return this.light ? 'text-primary' : 'text-white'
    },
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear()
    },

    goHome() {
      window.location.href = ProjectGetBaseURL()
      // this.$router.push(`/`)
    },
  },
  mounted() {
    this.$vuetify.goTo(this.$refs.top)
  },
})
</script>

<style lang="scss" scoped></style>























import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'BusinessAutoFunnels',
})
export default class BusinessAutoFunnels extends Vue {}

import { StoresMutations } from './types'

const mutations: StoresMutations = {
  test(state, { data }) {
    state.test = data
  },
  setStoresListItems(state, { response }) {
    state.stores.list.items = response.data
    state.stores.list.params = response.params
  },
  setStoreItem(state, { data }) {
    state.stores.item = data
  },
}

export default mutations

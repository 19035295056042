










































import { Component, Vue, Prop } from 'vue-property-decorator'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'

@Component({
  components: {},
})
export default class CommonChartCard extends Vue {
  money: any

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  @Prop({ required: true, type: String }) readonly title!: any

  @Prop(Array) readonly additionalData!: any

  @Prop(Boolean) readonly income!: any

  @Prop(String) readonly type!: any

  @Prop({ default: 192, type: Number }) readonly heightSize!: any
}

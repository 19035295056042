<template>
  <v-app-bar
    :app="$vuetify.breakpoint.width > 1360"
    :absolute="$vuetify.breakpoint.width < 1360"
    clipped-left
    color="rgb(39, 20, 96)"
    dark
    height="80px"
  >
    <div class="d-flex align-center">
      <v-badge color="error" content="BETA" offset-y="-13px" offset-x="20px" />
      <v-img
        :src="require('@/assets/images/logo.svg')"
        class="shrink mr-2"
        contain
        height="28px"
        transition="false"
        width="92px"
      />
      <v-badge color="rgb(61, 255, 192)" content="CRM" offset-y="-3px" />
    </div>
    <nav class="main-nav">
      <div :class="{ active: currentSection === 'business' }" @click="sectionChange('business')">
        {{ $t('mainNav.business') }}
      </div>
      <div :class="{ active: currentSection === 'affiliate' }" @click="sectionChange('affiliate')">
        {{ $t('mainNav.affileate') }}
      </div>
      <div :class="{ active: currentSection === 'crm' }" @click="sectionChange('crm')">
        {{ $t('mainNav.crm') }}
        <span class="chats-counter" v-if="getCountChatsNotReadMessages > 0">{{
          getCountChatsNotReadMessages
        }}</span>
      </div>
    </nav>
    <v-spacer></v-spacer>
    <div class="user pointer" @click="sectionChange('profile')">
      <icon
        v-if="authMode === 'moderator'"
        class="user-avatar-moderator"
        :color="'#fff'"
        :data="require('@/assets/icons/alert-round.svg')"
      />
      <v-avatar>
        <template v-if="user.avatar">
          <img :src="user.avatar.content_url ? user.avatar.content_url : user.avatar" />
        </template>
        <template v-else>
          <v-icon color="white" large>mdi-account-circle-outline</v-icon>
        </template>
      </v-avatar>

      <span>
        <b class="username">{{ user.name }}</b>
        <!-- <icon data="@/assets/icons/white-down.svg"/> -->
      </span>
    </div>
    <div
      v-if="getBalance && getCurrentApp"
      class="dropdown-balance-button"
      color="rgb(61, 255, 192)"
    >
      <div v-if="currentSection === 'affiliate'">
        <span
          v-if="getBalance.affiliate[0] && getBalance.affiliate.length"
          class="dropdown-balance-text"
        >
          <i class="balance-title">{{ $t('balance') }}:</i>
          <b v-if="$i18n.locale === 'ru'">
            {{ getBalance.affiliate[0].available ? getBalance.affiliate[0].available : 0 }}
            {{ getBalance.affiliate[0].currency.symbol }}
          </b>
          <b v-else>
            {{ getBalance.affiliate[0].currency.symbol }}
            {{ getBalance.affiliate[0].available ? getBalance.affiliate[0].available : 0 }}
          </b>
          <v-icon class="dropdown-balance-icon" small v-if="getBalance.affiliate.length > 1">
            mdi-arrow-down-drop-circle-outline
          </v-icon>
        </span>
        <span v-else class="dropdown-balance-text">
          <i class="balance-title">{{ $t('balance') }}: 0</i>
        </span>
        <div class="dropdown-balance-items" v-if="getBalance.affiliate.length > 1">
          <div
            class="dropdown-balance-item"
            v-for="balance in getBalance.affiliate"
            :key="balance.currency_id"
          >
            <span v-if="$i18n.locale === 'ru'"
              >{{ balance.available ? balance.available : 0 }}
              <b>{{ balance.currency.symbol }}</b></span
            >
            <span v-else
              ><b>{{ balance.currency.symbol }}</b> {{ balance.available ? balance.available : 0 }}
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <span class="dropdown-balance-text">
          <i class="balance-title">{{ $t('balance') }}:</i>
          <b v-if="balanceCurrentApp"
            >{{ balanceCurrentApp.available }}
            {{ balanceCurrentApp.currency.symbol }}
          </b>
          <b v-else>0</b>
        </span>
      </div>
    </div>
    <button
      :class="{ active: showDropdown === 'app', disabled: currentSection === 'affiliate' }"
      class="dropdown dropdown-app"
      tabindex="0"
      @click="showDropdown = 'app'"
      @focusout="hideDropdown"
    >
      <span
        >App:
        <div>{{ getCurrentApp && cutAppName(getCurrentApp.name) }}</div>
      </span>

      <icon data="@/assets/icons/dropdown-arrow.svg" />
      <div
        v-if="getAppsList.length"
        v-show="showDropdown === 'app'"
        class="dropdown-button-content"
      >
        <vue-custom-scrollbar class="scroll-area" :settings="$root.$data.scrollbarSettings">
          <ul>
            <li v-for="app in getAppsList" :key="app.id" @click="appSelect(app)">
              {{ app.name }}
            </li>
          </ul>
        </vue-custom-scrollbar>
      </div>
    </button>
    <button
      class="sub with-text dropdown dropdown-lang"
      @click="showDropdown = 'lang'"
      :class="{ active: showDropdown === 'lang' }"
      @focusout="hideDropdown"
    >
      {{ $i18n.locale }}
      <icon data="@/assets/icons/lang-icon.svg" />
      <div v-show="showDropdown === 'lang'" class="dropdown-button-content">
        <ul>
          <li v-for="lang in langs" :key="lang" @click="onLanguageClick(lang)">
            {{ lang }}
          </li>
        </ul>
      </div>
    </button>
    <button class="sub" @click="onLogout">
      <icon data="@/assets/icons/logout-icon.svg" />
    </button>
  </v-app-bar>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AuthProvider from '@/providers/AuthProvider'

export default Vue.extend({
  name: 'Navigation',
  components: {},
  data: () => ({
    showDropdown: 0,
    language: 'En',
    langs: ['en', 'ru'],
    balance: 0,
    section: '',
  }),
  methods: {
    ...mapActions('nApp', ['appReloadStatus']),
    ...mapMutations('nApp', ['changeCurrentApp', 'changeLocale']),
    ...mapActions('nChats', ['channelsGetItemsByNewMessages']),

    hideDropdown() {
      this.showDropdown = 0
    },
    async onLanguageClick(value) {
      this.changeLocale(value)
      setTimeout(() => {
        this.showDropdown = false
      }, 10)
    },
    async onLogout() {
      await Promise.all([AuthProvider.logout()])

      await this.$router.push({ name: 'auth.login' })
    },
    appSelect(app) {
      this.changeCurrentApp(app)
      this.channelsGetItemsByNewMessages(app.id)
      this.$root.$emit('appChange', app)
      if (this.$route.path !== '/business') {
        this.appReloadStatus()
      }
      setTimeout(() => {
        this.showDropdown = false
      }, 10)
    },
    sectionChange(section) {
      this.section = section
      this.$root.$emit('sectionChange', section)
      this.$router.push(`/${section}`)
    },
    cutAppName(name) {
      if (name.length >= 10) {
        return `${name.substring(0, 10)}..`
      }
      return name
    },
  },
  computed: {
    ...mapGetters('nApp', { user: 'getAuthUser', authMode: 'getAuthMode' }),
    ...mapGetters('nApp', ['getAppsList', 'getBalance', 'getCurrentApp']),
    ...mapGetters('nChats', ['getCountChatsNotReadMessages']),
    currentSection() {
      return this.$route.path.split('/')[1]
    },
    balanceCurrentApp() {
      return this.getBalance && this.getCurrentApp
        ? this.getBalance.apps.find((item) => item.app_id === this.getCurrentApp.id)
        : {}
    },
  },

  async created() {
    this.$root.$on('sectionChange', (section) => {
      this.section = section
    })
  },
})
</script>

<style lang="scss">
.user-avatar-moderator {
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 1;
  width: 26px !important;
  height: 26px !important;
  background: #e7aa21;
  border-radius: 100%;
}

.v-application > .v-application--wrap > header > .v-toolbar__content {
  padding: 0 2rem;

  .v-badge__badge {
    height: auto !important;
    padding: 0.25rem !important;
    color: var(--v-primary-base) !important;
    font-weight: 700;
    font-size: 9px !important;
    border-radius: 0.125rem !important;

    &.error {
      color: white !important;
    }
  }

  > nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 64px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      padding: 0 1.75rem;
      color: white;
      font-weight: 700;
      font-size: 18px;
      white-space: nowrap;
      text-align: center;
      border-bottom: 3px solid transparent;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: rgba(135, 135, 135, 0.07);
        border-bottom-color: var(--accent-green-light);
      }
    }
  }

  .user {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 16px 0 1rem;

    span {
      position: relative;
      margin-left: 0.75rem;
      font-weight: 700;
      cursor: pointer;

      svg {
        position: absolute;
        top: 50%;
        width: 10px;
        margin-left: 8px;
        transform: translateY(-50%);
      }
      .username {
        @media screen and (max-width: 1462px) {
          display: none;
        }
      }

      // &:hover {
      //   opacity: 0.7;
      // }
    }
  }

  .dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    padding: 0.25rem 0.5rem;
    color: var(--accent-green-light);
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    background-color: var(--v-primary-base);
    border: 1px solid var(--accent-green-light);
    border-radius: 0.375rem;
    cursor: pointer;

    &.active {
      border-radius: 0.375rem 0.375rem 0 0;
    }
    &.disabled {
      pointer-events: none;
    }

    > span {
      width: 150px;

      > div {
        display: inline;
        font-weight: bold;
      }
    }

    > svg {
      position: absolute;
      right: 0.875rem;
      width: 10px;
      fill: var(--accent-green-light);
    }

    .dropdown-button-content {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 40;
      display: flex;
      flex-direction: column;
      max-height: 18rem;
      margin: 0 -1px;
      background-color: var(--v-primary-base);
      border: 1px solid var(--accent-green-light);
      border-top-width: 0;
      border-radius: 0 0 0.375rem 0.375rem;

      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--accent-green-light);
        border-radius: 3px;
      }

      ul {
        padding: 0;
        text-align: left;
        list-style-type: none;

        li {
          width: 100%;
          max-width: 100%;
          padding: 0.75rem 0.5rem;
          overflow: hidden;
          color: var(--accent-green-light);
          font-weight: 500;
          font-size: 14px;
          border-top: 1px solid rgba(63, 72, 101, 1);
          cursor: pointer;
        }

        li:hover {
          opacity: 0.7;
        }
      }
    }

    &.dropdown-lang {
      justify-content: flex-start;
      min-width: 66px;
      text-transform: capitalize;

      .dropdown-button-content {
        border: 1px solid rgba(63, 72, 101, 1);
        border-top: 0;

        ul {
          li {
            padding-left: 16px;
            color: rgba(251, 250, 252, 1);
            text-align: left;
          }
        }
      }
    }
  }
  .dropdown-balance-button {
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    height: 32px;
    height: 32px;
    padding: 0 14px 0 14px;
    padding: 0 14px 0 14px;
    color: var(--v-primary-base);
    color: var(--v-primary-base);
    background: var(--accent-green-light);
    background: var(--accent-green-light);
    border-radius: 6px;
    border-radius: 6px;
    cursor: pointer;
    & > div {
      display: flex;
    }
    &:hover {
      .dropdown-balance-items {
        display: block;
        max-height: 200px;
      }
    }
    .balance-title {
      @media screen and (max-width: 1462px) {
        display: none;
      }
    }
  }
  .dropdown-balance-icon {
    margin: 0px -4px 0 4px;
    color: var(--v-primary-base);
  }
  .dropdown-balance-text {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    i {
      font-size: inherit;
      font-style: normal;
    }
    b {
      font-size: inherit;
      font-style: normal;
    }
  }
  .dropdown-balance-items {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    color: var(--v-primary-base);
    background: #000;
    background: #fff;
    border: 1px solid var(--accent-green-light);
    border-radius: 6px;

    .dropdown-balance-item {
      padding: 8px 0px 8px 20px;
      font-size: 14px;
      border-bottom: 1px solid var(--accent-green-light);

      /* &:first-child {
        display: none;
      } */

      &:last-child {
        border: 0;
      }
    }
  }
  .dropdown-app {
    width: 200px;
  }
  .dropdown-lang {
    min-width: 64px !important;
  }
  .sub {
    display: inline-flex;
    align-items: center;
    margin: 0 0.5rem;
    padding: 0.25rem 0.875rem;
    color: rgba(251, 250, 252, 1);
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    border: 1px solid rgba(63, 72, 101, 1);
    border-radius: 0.375rem;
    cursor: pointer;

    &:not(.with-text) {
      padding: 0.4rem;

      > svg {
        fill: #897caf;
      }
    }

    &.with-text > svg {
      width: 8px;
      margin-left: 0.75rem;
      fill: #897caf;
    }

    // &:hover {
    //   opacity: 0.7;
    // }
  }
  .main-nav .chats-counter {
    display: block;
    min-width: 24px;
    height: 22px;
    margin: 0px 0 0 10px;
    padding: 2px 0px;
    color: var(--primary-dark);
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    background: var(--accent-green-light);
    border-radius: 90px;
  }
}
</style>

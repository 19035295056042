





















import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AffiliateAnalitics',
})
export default class AffiliateAnalitics extends Vue {}

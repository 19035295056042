import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'

interface ProductPropertiesModelAppTypes {
  id?: number
}
interface ProductPropertiesModelApiTypes {
  id?: number
}

class ProductPropertiesService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.productProperties')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ProductPropertiesModelAppTypes = {}

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PRODUCT_PROPERTIES)
  }

  public getEmptyItem(): ProductPropertiesModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(data): ProductPropertiesModelApiTypes {
    return data
  }

  public transformDataForApp(data): ProductPropertiesModelAppTypes {
    return data
  }

  public transformRequestParams(params: unknown): unknown {
    return params
  }
}
export default new ProductPropertiesService()

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"page--gas-orders-list"},[_c('page-header',{attrs:{"extra":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h4',{staticClass:"d-inline-flex align-center"},[_vm._v(_vm._s(_vm.$t('ordres.myOrders')))])]),_c('v-col',[_c('div',{staticClass:"d-flex mr-2 pl-6"},[_c('v-btn',{staticClass:"page--gas-orders-list__btn px-3 mr-xl-3 mr-2 text-primary font-weight-regular text-middle",class:_vm.pageTable.filter.status === '' ? 'border-grey font-weight-bold bg-white shadow' : '',on:{"click":function($event){return _vm.changeFilterOrderStatus('')}}},[_vm._v(" "+_vm._s(_vm.$t('ordres.allOrders'))+" ")]),_c('v-btn',{staticClass:"page--gas-orders-list__btn px-3 mr-xl-3 mr-2 text-primary font-weight-regular text-middle",class:_vm.pageTable.filter.status === 'new'
                ? 'border-grey font-weight-bold bg-white shadow'
                : '',on:{"click":function($event){return _vm.changeFilterOrderStatus('new')}}},[_vm._v(" "+_vm._s(_vm.$t('ordres.newOrders'))+" ")]),_c('v-btn',{staticClass:"page--gas-orders-list__btn px-3 mr-xl-3 mr-2 text-primary font-weight-regular text-middle",class:_vm.pageTable.filter.status === 'delivery_process'
                ? 'border-grey font-weight-bold shadow bg-white'
                : '',on:{"click":function($event){return _vm.changeFilterOrderStatus('delivery_process')}}},[_vm._v(" "+_vm._s(_vm.$t('ordres.inProgress'))+" ")]),_c('v-btn',{staticClass:"page--gas-orders-list__btn px-3 mr-xl-3 mr-2 text-primary font-weight-regular text-middle",class:_vm.pageTable.filter.status === 'delivered'
                ? 'border-grey font-weight-bold bg-white shadow'
                : '',on:{"click":function($event){return _vm.changeFilterOrderStatus('delivered')}}},[_vm._v(" "+_vm._s(_vm.$t('ordres.delivered'))+" ")]),_c('v-btn',{staticClass:"page--gas-orders-list__btn px-3 text-primary font-weight-regular text-middle",class:_vm.pageTable.filter.status === 'cancel'
                ? 'border-grey font-weight-bold bg-white shadow'
                : '',on:{"click":function($event){return _vm.changeFilterOrderStatus('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('ordres.canceled'))+" ")])],1)]),_c('div',[_c('v-btn',{attrs:{"max-height":"32","max-width":"130","dark":"","color":"var(--primary-light)"}},[_vm._v(_vm._s(_vm.$t('exportToCSV')))])],1)],1)],1),_c('card',{staticClass:"mt-4 mb-6 mx-4 pa-8 px-0 py-0"},[_c('div',{staticClass:"px-6 pt-6"},[_c('v-row',{staticClass:"mx-n3 align-center pb-6 my-0"},[_c('v-col',{staticClass:"py-0 px-3",attrs:{"cols":"3"}},[_c('app-v-text-field',{attrs:{"label":_vm.$t('searchByName'),"prepend-inner-icon":"mdi-magnify"},on:{"change":_vm.tableLoadItems},model:{value:(_vm.pageTable.data.params.positionsName),callback:function ($$v) {_vm.$set(_vm.pageTable.data.params, "positionsName", $$v)},expression:"pageTable.data.params.positionsName"}})],1),_c('v-col',{staticClass:"py-0 px-3",attrs:{"cols":"9"}},[_c('app-date-picker',{attrs:{"type":"range","filterOut":"","label":_vm.$t('pageAffiliateStats.filterLabel.range'),"initDefault":"true"},on:{"change":_vm.tableLoadItems},model:{value:(_vm.pageTable.filter.created),callback:function ($$v) {_vm.$set(_vm.pageTable.filter, "created", $$v)},expression:"pageTable.filter.created"}})],1)],1)],1),_c('div',{},[_c('data-table',{ref:"pageTable",staticClass:"supster-ui--data-table__wrapper",attrs:{"headers":_vm.headers,"items":_vm.pageTable.data.items,"itemsPerPage":_vm.pageTable.data.params.itemsPerPage,"length":_vm.pageTable.data.params.pages,"disableSort":true,"loading":_vm.pageTable.loading},on:{"input":_vm.tableChangePage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('p',{staticClass:"supster-ui__text my-0"},[_vm._v("ID: "+_vm._s(item.id))]),_c('p',{staticClass:"supster-ui__text _s-12 my-0"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created,'DD.MM.YYYY'))+" ("+_vm._s(_vm._f("moment")(item.created,'hh:mm:ss'))+") ")])])]}},{key:"item.product",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex py-4 align-center pl-2"},[(item.positions.length)?_c('div',{staticClass:"product-item-prop-image mr-3",style:({
                'background-image': ("url(" + (item.positions[0].product.mainImage.preview_url ||
                  item.positions[0].product.mainImage.content_url) + " )"),
              })}):_vm._e(),(item.positions.length)?_c('span',{staticClass:"product-item-prop-name"},[_vm._v(" "+_vm._s(item.positions[0].product.name)+" ")]):_vm._e()])]}},{key:"item.sum",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.sum))+" ")]}},{key:"item.count",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" ")]}},{key:"item.client",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(((item.recipientName) + " " + (item.recipientSurname)))+" ")]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOrderStatusName(item))+" ")]}},{key:"item.confirm",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.$router.push(("/business/goods_and_services/orders/" + (item.id) + "/confirm"))}}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"com-dropdown-action__wrapper"},[_c('app-data-table-action-dropdown',{attrs:{"left":"","nudgeLeft":-9,"nudgeTop":28,"backgroundColor":"#271460","dividerColor":"#493B74","divider":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('icon',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
                    handler: function () {
                      _vm.pageTable.popup.actions = false
                    },
                  }),expression:"{\n                    handler: () => {\n                      pageTable.popup.actions = false\n                    },\n                  }"}],staticClass:"com-dropdown-action__icon",attrs:{"data":require("@/assets/icons/dots-vertical.svg"),"color":_vm.pageTable.popup.actions === item.id ? 'var(--accent-green)' : 'primary'},on:{"click":function($event){return _vm.tableItemShowActions(item.id)}}})]},proxy:true},{key:"content",fn:function(){return [_c('v-btn',{staticClass:"com-dropdown-action__btn justify-start font-weight-regular",attrs:{"block":"","color":"primary","tile":""},on:{"click":function($event){return _vm.$router.push(("orders/" + (item.id)))}}},[_vm._v(_vm._s(_vm.$t('orderDetails')))])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { isServer } from '@/utils/isServer'
import { merge } from 'lodash'
import { getToken } from '@/utils/token'
import AuthProvider from '@/providers/AuthProvider'
import { ProjectGetBaseURL } from '@/utils/project'
import * as Sentry from '@sentry/vue'

type RequestInterceptor = (response: AxiosRequestConfig) => AxiosRequestConfig

class HttpClientServise {
  private tokenType = ''

  defaultConfig: AxiosRequestConfig = {
    baseURL: '',
    withCredentials: false,
  }

  config: AxiosRequestConfig

  instance!: AxiosInstance

  constructor(config: AxiosRequestConfig = {}, tokenType: string) {
    this.tokenType = tokenType
    this.config = config
    this.createInstance()
    this.addInterceptors(this.instance)
  }

  public getInstance(): AxiosInstance {
    // this.addHeaderToken()
    return this.instance
  }

  private createInstance() {
    this.instance = axios.create(merge(this.defaultConfig, this.config))
  }

  private addInterceptors(instance) {
    instance.interceptors.request.use(this.withRequestSubDomain)
    instance.interceptors.request.use(
      (item) => {
        if (AuthProvider.getToken(this.tokenType)) {
          item.headers.Authorization = `Bearer ${AuthProvider.getToken(this.tokenType)}`
        }
        return item
      },
      (err) => {
        Sentry.setTag('n-type', 'request')
        Sentry.captureException(err)
        return Promise.reject(err)
      }
    )
    instance.interceptors.response.use(
      (res) => {
        return res
      },
      (err) => {
        Sentry.setTag('n-type', 'response')
        Sentry.captureException(err)
        if (err?.response?.status === 401) {
          // TODO: Данный логаут затирает входящие токены
          // this.logout()
        }
        return Promise.reject(err)
      }
    )
  }

  // private addHeaderToken() {
  // console.log(AuthProvider.getToken())
  // if (AuthProvider.getToken()) {
  //   this.instance.defaults.headers.Authorization = `Bearer ${AuthProvider.getToken()}`
  // }
  // }

  private withRequestSubDomain(request): RequestInterceptor {
    const { baseURL } = request
    const parts = isServer() ? [] : window.location.host.split('.')

    if (!baseURL) {
      return request
    }

    if (parts.length < 2) {
      return { ...request, baseURL: baseURL.replace('*', 'crm') }
    }

    return { ...request, baseURL: baseURL.replace('*', parts[0]) }
  }

  private logout() {
    AuthProvider.logout()
  }
}

const CrmServise = new HttpClientServise(
  {
    baseURL: ProjectGetBaseURL(),
  },
  'crm'
)

const PwaServise = new HttpClientServise(
  {
    baseURL: ProjectGetBaseURL(),
  },
  'pwa'
)

export const HttpApiCrm: AxiosInstance = CrmServise.getInstance()

export const HttpApiPwa: AxiosInstance = PwaServise.getInstance()

import Vue from 'vue'

// import { OrdersMutations, OrdersFilteredTypes } from './types'
import { OrdersMutations } from './types'

const mutations: OrdersMutations = {
  setOrders(state, { items, pages, page }) {
    state.orders.list.items = items
    state.orders.list.params.pages = pages
    state.orders.list.params.page = page
  },
  ordersListChangePage(state, newPage) {
    state.orders.list.params.page = newPage
  },
  changeOrdersListParams(state, params) {
    state.orders.list.params = { ...state.orders.list.params, ...params }
  },
  // setMoreOrders(state, { Orders, media }: OrdersFilteredTypes) {
  //   if (media) {
  //     Vue.set(state[media], 'data', [...state[media].data, ...Orders])
  //     Vue.set(state[media], 'page', state[media].page + 1)
  //     return
  //   }
  //   state.Orders = [...state.Orders, ...Orders]
  //   state.page += 1
  // },
  // setIsLoadedOrders(state, isLoaded: boolean) {
  //   state.isLoaded = isLoaded
  // },
  // incrementCommentsCountInPost(state, id: number | string) {
  //   const post = state.Orders.find(({ id: postId }) => postId === id)
  //   if (post?.comment_count || post?.comment_count === 0) {
  //     Vue.set(post, 'comment_count', post.comment_count + 1)
  //   }
  // },
  // resetOrders(state) {
  //   state.Orders = []
  //   state.page = 1
  //   state.isLoaded = false
  // },
  // resetProfileOrders(state) {
  //   Vue.set(state.image, 'data', [])
  //   Vue.set(state.video, 'data', [])
  //   Vue.set(state.image, 'page', 1)
  //   Vue.set(state.video, 'page', 1)
  // },
}

export default mutations

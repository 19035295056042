export default abstract class BaseProvider {
  static instance: BaseProvider

  $router: any = false

  $store: any = false

  constructor() {
    if (typeof BaseProvider.instance === 'object') {
      return BaseProvider.instance
    }
    BaseProvider.instance = this
    return BaseProvider.instance
  }

  public registerRouter(router: any): void {
    this.$router = router
  }

  public registerStore(store: any): void {
    this.$store = store
  }
}

































































































import { Component, Emit, Vue } from 'vue-property-decorator'
import { tableHeadersType } from '@/views/affiliate/offers/types'
import { VuexGetter, VuexMutation } from '@/store/decorators'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'

@Component({})
export default class OffersListTable extends Vue {
  // Vuex module mutation
  @VuexMutation('nAffiliateOffers') setIsOpenCreateStreamFormStatus!: (...args) => void

  // Vuex module getter
  @VuexGetter('nAffiliateOffers') getOffersItems!: Array<OfferAppModelType>

  @VuexGetter('nAffiliateOffers') getOffersParams!: any

  @VuexGetter('nAffiliateOffers') getOffersListLoading!: boolean

  // Computed
  get tableHeaders(): Array<tableHeadersType> {
    return [
      { text: this.$t('page'), value: 'app' },
      { text: this.$t('geo'), value: 'countries' },
      { text: this.$t('conditions'), value: 'rules' },
      { text: this.$t('category'), value: 'offerCategory' },
      { text: this.$t('followers'), value: 'subscribers' },
    ]
  }

  // Methods
  createStream(item: OfferAppModelType): void {
    this.setIsOpenCreateStreamFormStatus(true)
    this.$router.push({
      name: 'Affiliate_Offers_Item',
      params: {
        id: item.id,
      },
    })
  }

  openOffer(item: OfferAppModelType): void {
    this.$router.push({
      name: 'Affiliate_Offers_Item',
      params: {
        id: item.id,
      },
    })
  }

  // Emit
  @Emit('tableChangePage')
  tableChangePage(page: number): number {
    return page
  }
}

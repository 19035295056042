import { App } from '@/services/types'

const APP_KEY = 'APP'

export const getApp = (): App | null => {
  const app = localStorage.getItem(APP_KEY)

  try {
    return app ? JSON.parse(app) : null
  } catch (e) {
    return null
  }
}

export const setApp = (app: App): void => localStorage.setItem(APP_KEY, JSON.stringify(app))

export const unsetApp = (): void => localStorage.removeItem(APP_KEY)

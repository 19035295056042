<template>
  <header
    :class="{ bordered: bordered, plain: plain, top: top, extra: extra }"
    class="d-flex flex-row align-center justify-space-between"
  >
    <h4 v-if="title">
      <span>{{ titlePrefix }}</span> {{ title }}
    </h4>
    <slot></slot>
  </header>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'PageHeader',
  props: {
    titlePrefix: {
      type: String,
      default: '',
    },
    title: {
      type: String,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    extra: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
header {
  height: 104px;
  min-height: 104px;
  padding: 0.75rem 3rem;
  h4 {
    color: var(--v-primary-base);
    font-size: 40px;
    line-height: 40px;
    > span {
      font-weight: 400;
    }
  }

  &:not(.plain):not(.top) {
    border-bottom: 1px solid var(--light-gray);
  }

  &.top {
    border-top: 1px solid var(--light-gray);
  }

  &.bordered {
    border-top: 1px solid var(--light-gray);
    border-bottom: 1px solid var(--light-gray);
  }

  &.font-weight-bold:not(.extra) {
    h4 {
      font-weight: bold !important;
    }
  }

  &.extra {
    h4 {
      font-weight: 800 !important;
    }
  }

  .font-weight-extra {
    font-weight: 800 !important;
  }
}
</style>

<template>
  <div class="country-flag">
    <svg class="country-flag__image" :class="size">
      <use :xlink:href="flagLink"></use>
    </svg>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'CountryFlag',
  data: () => ({}),
  computed: {
    flagLink() {
      return `${require('../assets/flags/flags.svg')}#flag-${this.code.toLowerCase()}`
    },
  },
  props: {
    code: {
      type: String,
      default: 'unknown',
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
  methods: {},
})
</script>

<style lang="scss">
.country-flag {
  display: flex;
  flex: 0 0 auto;
  align-content: center;
  align-items: center;
  width: 20px;
  height: 14px;
  overflow: hidden;
  border: 1px solid var(--light-gray);

  &__image {
    display: block;
    width: 20px;
    height: 22px;
  }
}
</style>

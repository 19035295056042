import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'

import navigation from '@/router/routes/crm-pages'
import only_auth_navigation from '@/router/routes/authPages'
import AuthProvider from '@/providers/AuthProvider'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const DEFAULT_TITLE = 'Supster'
const queryParams = new URL(window.location.href).searchParams
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...(queryParams.get('gpt_auth') === '1' ? only_auth_navigation : navigation)],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.afterEach(({ meta }: Route) => {
  Vue.nextTick(() => {
    const isToken = AuthProvider.getToken('crm')
    const metaViewport = document.querySelector('meta[name="viewport"]')
    let viewportContent =
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
    if (meta) {
      document.title = `${DEFAULT_TITLE} - ${meta.title}` || DEFAULT_TITLE
    }
    if (isToken) {
      viewportContent = 'width=device-width, initial-scale=1.0, maximum-scale=1.0'
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    metaViewport.content = viewportContent
  })
})

router.beforeEach(({ matched }, _, next) => {
  const isRequireAuth = matched.some((record) => record.meta?.isRequireAuth)
  const documents = matched.some((record) => record.meta?.documents)
  const isToken = AuthProvider.getToken('crm')

  if (documents) {
    return next()
  }
  if (isRequireAuth && !isToken) {
    return AuthProvider.logout()
  }

  if (!isRequireAuth && isToken) {
    return next({ name: 'Business_Home' })
  }

  return next()
})

export default router

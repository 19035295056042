import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { DocumentsAppModelType } from './DocumentsTypes'

class DocumentsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.documents')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.DOCUMENTS)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {}

  private usedListParams = []

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData): DocumentsAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    return params
  }
}
export default new DocumentsService()

<template>
  <card class="message-notify" :style="animation" @click="goToChat">
    <div @click="goToChat">
      <h5 class="message-notify-title font-weight-bold text--primary-base">
        {{ $t('newMessage') }}
      </h5>
      <p class="mb-0 mt-1px">
        <span class="font-weight-bold">{{ userName }}: </span>{{ cuttedMessage }}
      </p>
    </div>
  </card>
</template>

<script>
import Vue from 'vue'
import Card from '@/components/Card.vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'MessageNotify',
  components: { Card },
  props: {
    message: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    notify: {
      default: {},
    },
  },
  data: () => ({
    animation: ``,
  }),
  computed: {
    ...mapGetters('nApp', ['getCurrentApp', 'getAuthClient']),
    cuttedMessage() {
      if (this.$props.message) {
        if (this.$props.message.length > 20) {
          return `${this.$props.message.slice(0, 20)}...`
        }
        return this.$props.message
      }
      return '[Media]'
    },
  },
  methods: {
    ...mapActions('nChats', ['openDialogByNotify']),
    goToChat() {
      if (!this.$route.fullPath.includes(`?chat=${this.$props.userId}`)) {
        this.$router.push({ name: 'Dialogs', query: { chat: this.$props.userId } })
        this.$root.$emit('sectionChange', 'crm')
        this.openDialogByNotify({
          appId: this.getCurrentApp.id,
          clientId: this.notify.clientId,
          channelId: this.notify.channelId,
          meClientId: this.getAuthClient.id,
        })
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.animation = `animation: slide-out 1s forwards;`
      this.$emit('close')
    }, 5000)
  },
})
</script>

<style lang="scss" scoped>
.message-notify {
  position: fixed;
  top: 100px;
  right: -30vw;
  display: flex;
  align-items: center;
  width: 30vw;
  height: 10vh;
  padding: 0 1rem;
  cursor: pointer;
  animation: slide 0.5s forwards;
}

@keyframes slide {
  100% {
    right: 8px;
  }
}

@keyframes slide-out {
  100% {
    right: -30vw;
  }
}
</style>

import { DialoguesGetters } from './types'

const getters = {
  clientsList(state) {
    return state.clients.list
  },
  dialog(state) {
    return state.dialog
  },
  groupChatList(state) {
    return state.groupChats.list.items.data
  },
}

export default getters

const subdomen = process.env.VUE_APP_GPT_SUBDOMAIN_NAME
const locationUrl = window.location.hostname.split('.')[0]
export const ProjectIsGPT = (): boolean =>
  locationUrl === subdomen || localStorage.getItem('GPT') === '1'

export const ProjectGetBaseURL = (): string => {
  if (ProjectIsGPT()) {
    return process.env.VUE_APP_GPT_BASE_URL || 'empty'
  }
  return process.env.VUE_APP_API_BASE_URL || 'empty'
}

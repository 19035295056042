





















import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Payments',
})
export default class Payments extends Vue {}

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { BalanceAppModelType, BalanceApiModelType } from './BalanceTypes'

class BalanceService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.balance')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: BalanceAppModelType = {
    apps: [],
    affiliate: [],
    balance: {},
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.BALANCE)
  }

  public getEmptyItem(): BalanceAppModelType {
    const data = cloneDeep(this.AppModelPropsDefault)
    return data
  }

  public transformDataForApi(incomingData): BalanceApiModelType {
    return incomingData
  }

  public transformDataForApp(incomingData): BalanceAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }

  public async getItems<P>({ params }: { params?: P }): Promise<any> {
    try {
      const requestUrl = this.ENDPOINTS.ITEMS
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const result = {
        data: response.data,
        params: {},
      }
      return { status: true, data: result.data, params: result.params }
    } catch (error) {
      return { status: false, data: {}, params: {}, error }
    }
  }
}
export default new BalanceService()

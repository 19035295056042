<template>
  <v-row class="mb-0">
    <v-col cols="12" class="pb-0">
      <step-breadcrumbs class="ma-4 storages-page__breadcrumbs" v-model="form.step" />
      <card class="mt-4 mb-6 mx-4">
        <div class="supster-ui--form__wrapper">
          <div class="supster-ui--form__content">
            <div class="supster-ui--form__step-items">
              <div class="supster-ui--form__step-item step-1" v-if="form.step.current === 0">
                <h2 class="supster-ui--form__title">{{ $t('storages.generalInfo') }}</h2>
                <v-row class="mx-n3">
                  <v-col cols="6" class="px-3 d-flex flex-wrap">
                    <v-row>
                      <div class="col col-12">
                        <app-v-text-field
                          v-model="form.data.name"
                          :error="checkPropError('name')"
                          :label="$t('storages.storeName')"
                          placeholder=". . ."
                        />
                      </div>
                      <div class="col col-6">
                        <app-v-text-field
                          v-model="form.data.region"
                          :error="checkPropError('region')"
                          :label="$t('region')"
                          placeholder=". . ."
                        />
                      </div>
                      <div class="col col-6">
                        <app-v-text-field
                          v-model="form.data.city"
                          :error="checkPropError('city')"
                          :label="$t('city')"
                          placeholder=". . ."
                        />
                      </div>
                      <div class="col col-6">
                        <app-v-text-field
                          v-model="form.data.street"
                          :error="checkPropError('street')"
                          :label="$t('street')"
                          placeholder=". . ."
                        />
                      </div>
                      <div class="col col-6">
                        <div class="row mx-n1">
                          <div class="col col-4 px-1">
                            <app-v-text-field
                              v-model="form.data.house"
                              :error="checkPropError('house')"
                              v-mask="'#########'"
                              :label="$t('storages.houseNumber')"
                              placeholder=". . ."
                            />
                          </div>
                          <div class="col col-4 px-1">
                            <app-v-text-field
                              v-model="form.data.housing"
                              :error="checkPropError('housing')"
                              v-mask="'XXXXXXXX'"
                              :label="$t('korpus')"
                              placeholder=". . ."
                            />
                          </div>
                          <div class="col col-4 px-1">
                            <app-v-text-field
                              v-model="form.data.postcode"
                              :error="checkPropError('postcode')"
                              v-mask="'#########'"
                              :label="$t('index')"
                              placeholder=". . ."
                            />
                          </div>
                        </div>
                      </div>
                      <label class="supster-ui--input__label mt-4 ml-3">
                        <i class="supster-ui--badge__block mr-1">!</i>
                        {{ $t('storages.inCaseOfReturn') }}
                      </label>
                    </v-row>
                  </v-col>
                  <v-col class="px-3" cols="6">
                    <v-row>
                      <div class="col col-12">
                        <app-v-textarea
                          v-model="form.data.refundInstruction"
                          rows="4"
                          no-resize
                          :error="checkPropError('refundInstruction')"
                          height="130px"
                          class="storages-page__textarea"
                          placeholder=". . ."
                        >
                          <template #label>
                            {{ $t('storages.returnInstruction') }}
                            <span class="help-circle help-circle--large ml-1">?</span>
                          </template>
                        </app-v-textarea>
                      </div>
                      <div class="col col-6">
                        <app-v-text-field
                          v-model="form.data.phone"
                          :error="checkPropError('phone')"
                          v-mask="'8(###)###-##-##'"
                          placeholder="8(999)999-99-99"
                          :label="$t('phoneHumber')"
                        />
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
                <hr class="supster-ui--form__separator mx-n10 my-8" />
                <h2 class="supster-ui--form__title mt-4">{{ $t('delivery') }}</h2>
                <v-row class="mx-n3">
                  <v-col class="d-flex flex-wrap" cols="6">
                    <div class="supster-ui--input__wrapper">
                      <label class="supster-ui--input__label">
                        {{ $t('storages.timeToSend') }}
                      </label>
                      <div class="supster-ui--input__box" :style="{ height: '74px' }">
                        <input-range
                          v-model="form.data.deliveryDelay"
                          :min="1"
                          :max="72"
                          :hint="`${form.data.deliveryDelay} ${$t('hours')}`"
                        />
                      </div>
                      <label class="supster-ui--input__label mt-6 mb-0">
                        <i class="supster-ui--badge__block mr-1">!</i>
                        {{ $t('storages.specifyTheTime') }}
                      </label>
                    </div>
                  </v-col>
                  <v-col class="" cols="6">
                    <div>
                      <label class="supster-ui--input__label">
                        {{ $t('storages.workDays5') }}
                        <i class="supster-ui--badge__block _accent ml-1">?</i>
                      </label>
                    </div>
                    <div class="d-flex flex-nowrap ml-0">
                      <v-sheet
                        class="storages-page__workday-item d-flex flex-column align-center mr-2 py-3 flex-grow-1 rounded border-grey flex-shrink-1"
                        v-for="(item, index) in form.lists.workDays"
                        height="74"
                        max-width="74"
                        :key="item.value"
                        :class="{
                          active: form.data.workDays
                            ? form.data.workDays.includes(item.value)
                            : false,
                          errors: checkPropError('workDays'),
                        }"
                      >
                        <app-v-checkbox
                          :value="item.value"
                          v-model="form.data.workDays"
                          class="ml-2"
                        />
                        <span class="text-small mt-2">{{ $t(`workDays.${index + 1}`) }}</span>
                      </v-sheet>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="supster-ui--form__step-item step-2" v-else-if="form.step.current === 1">
                <h2 class="supster-ui--form__title">{{ $t('storages.chooseDeliveryService') }}</h2>
                <v-row>
                  <v-col cols="6">
                    <app-v-radio-group
                      :mandatory="mode === 'edit'"
                      v-model="form.data.deliveryServices"
                      :error="checkPropError('deliveryServices')"
                    >
                      <v-row>
                        <v-col v-for="item in checkedDeliveryServices" :key="item.name" cols="6">
                          <v-sheet
                            class="delivery-form__radio border rounded flex-grow-1 border-grey"
                          >
                            <app-v-radio
                              :label="item.name"
                              row
                              :value="item"
                              :disabled="item.disabled"
                              :color="!item.disabled ? 'grey lighten-1' : ''"
                            />
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </app-v-radio-group>
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex ml-5 mt-0">
                      <div><i class="supster-ui--badge__block _v2 mr-4">i</i></div>
                      <div>
                        <p class="supster-ui__text font-weight-regular mt-0">
                          {{ $t('storages.deliveryInfo.1.1') }}
                          <b>{{ $t('storages.deliveryInfo.1.2') }}</b>
                          {{ $t('storages.deliveryInfo.1.3') }}
                          <b>{{ $t('storages.deliveryInfo.1.4') }}</b>
                          {{ $t('storages.deliveryInfo.1.5') }}
                        </p>
                        <p class="supster-ui__text font-weight-regular">
                          {{ $t('storages.deliveryInfo.1.6') }}
                        </p>
                        <v-btn href="#" x-small color="accent" :disabled="true">{{
                          $t('storages.deliveryInfo.1.7')
                        }}</v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="supster-ui--form__step-item step-3" v-else-if="form.step.current === 2">
                <h2 class="supster-ui--form__title">{{ $t('storages.choseDelivetyMethod') }}</h2>
                <v-row class="">
                  <v-col cols="6">
                    <app-v-checkbox
                      v-for="item in form.lists.deliveryMethods"
                      :key="item.value"
                      class="delivery-form__checkbox d-flex mr-2 flex-grow-1 rounded border-grey mb-6"
                      :value="item"
                      v-model="form.data.deliveryMethods"
                      :error="checkPropError('deliveryMethods')"
                      :label="item.name"
                    />
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex ml-5 mt-0">
                      <div><i class="supster-ui--badge__block _v2 mr-4">i</i></div>
                      <div>
                        <p class="supster-ui__text font-weight-regular mt-0">
                          <b>{{ $t('storages.deliveryInfo.3.1') }}</b>
                          {{ $t('storages.deliveryInfo.3.2') }}
                        </p>
                        <a
                          class="supster-ui__button mb-6 _small _accent mt-1"
                          href="https://www.pochta.ru/support/post-rules/tariffs"
                          target="_blank"
                          :style="{ width: 'auto', color: '#fff' }"
                          >{{ $t('storages.tariffRusPost') }}
                        </a>
                      </div>
                    </div>
                    <div class="d-flex ml-5 mt-2">
                      <div><i class="supster-ui--badge__block _v2 mr-4">i</i></div>
                      <div>
                        <p class="supster-ui__text font-weight-regular mt-0">
                          {{ $t('storages.deliveryInfo.4') }}
                          <br />
                        </p>
                        <a
                          href="https://www.cdek.ru/ru"
                          target="_blank"
                          class="supster-ui__link _accent mt-6 d-block"
                        >
                          <icon data="@/assets/icons/link.svg" class="mr-1 mb-1" />
                          {{ $t('storages.goToCDEK') }}
                        </a>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="supster-ui--form__step-item step-4" v-else-if="form.step.current === 3">
                <h2 class="supster-ui--form__title">{{ $t('storages.chooseDelivetyRegion') }}</h2>
                <v-row>
                  <v-col cols="6">
                    <app-checkboxes-group
                      :error="checkPropError('deliveryRegions')"
                      v-model="form.data.deliveryRegions"
                      :list="form.lists.deliveryRegions"
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="supster-ui--form__step-item step-4" v-else-if="form.step.current === 4">
                <h2 class="supster-ui--form__title" v-if="this.mode === 'create'">
                  {{ $t('storages.created.1') }}<br />{{ $t('storages.created.2') }}
                </h2>
                <h2 class="supster-ui--form__title" v-else>
                  {{ $t('storages.storeEdited') }}<br />{{ $t('storages.created.2') }}
                </h2>
                <v-row>
                  <v-col cols="6">
                    <v-row class="my-0">
                      <v-col class="py-0" cols="4">
                        <label class="supster-ui--input__label"
                          >{{ $t('logisticsCreate.steps.1') }}:</label
                        >
                        <p class="supster-ui__text _bolder mt-0">
                          {{ form.data.deliveryServices.name }}
                        </p>
                      </v-col>
                      <v-col class="py-0" cols="4">
                        <label class="supster-ui--input__label"
                          >{{ $t('storages.sendingTime') }}:</label
                        >
                        <p class="supster-ui__text _bolder mt-0">
                          {{ form.data.deliveryDelay }}
                        </p>
                      </v-col>
                      <v-col class="py-0" cols="4">
                        <label class="supster-ui--input__label"
                          >{{ $t('storages.workDays') }}:</label
                        >
                        <p class="supster-ui__text _bolder mt-0">
                          {{
                            form.data.workDays.length
                              ? form.data.workDays.map((num) => $t(`workDays.${num}`)).join(',')
                              : $t('notSpecified')
                          }}
                        </p>
                      </v-col>

                      <v-col class="py-0" cols="12">
                        <label class="supster-ui--input__label"
                          >{{ $t('storages.tariffAndMethodDelivery') }}:</label
                        >
                        <p
                          class="supster-ui__text _bolder mt-0 mb-0"
                          v-for="(item, index) in showDeliveryMethods"
                          :key="index"
                        >
                          {{ item }}.
                        </p>
                      </v-col>
                      <v-col class="py-0 mt-4" cols="12">
                        <label class="supster-ui--input__label"
                          >{{ $t('storages.regionsDelivery') }}:</label
                        >
                        <p
                          class="supster-ui__text _bolder mt-0 mb-0"
                          v-for="(item, index) in showRegions"
                          :key="index"
                        >
                          {{ index + 1 }}. {{ item }}.
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex ml-5 mt-0">
                      <div><i class="supster-ui--badge__block _v2 mr-4">i</i></div>
                      <div>
                        <p class="supster-ui__text font-weight-regular mt-0">
                          {{ $t('storages.deliveryInfo.5.1') }}
                          <b>
                            {{ $t('storages.deliveryInfo.5.2') }}
                            {{ form.data.deliveryDelay }} {{ $t('hours') }}
                          </b>
                          {{ $t('storages.deliveryInfo.5.3') }}
                        </p>
                        <p class="supster-ui__text font-weight-regular">
                          {{ $t('storages.deliveryInfo.5.4') }}
                          <b>{{ $t('storages.deliveryInfo.5.5') }}</b>
                          {{ $t('storages.deliveryInfo.5.6') }}
                        </p>
                        <p class="supster-ui__text font-weight-regular">
                          {{ $t('storages.deliveryInfo.5.7') }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <div class="supster-ui--form__footer">
            <div class="supster-ui--form__footer-description">
              <div
                class="supster-ui--form__footer-description-text col col-7 px-0 py-0"
                v-if="form.step.current === 1"
              >
                {{ $t('storages.deliveryInfo.2') }}
              </div>
            </div>
            <v-btn
              v-if="form.step.current > 0 && form.step.current < 4"
              color="primary lighten-1"
              @click="formStepPrev"
              class="mr-2"
              min-width="120"
            >
              {{ $t('back') }}
            </v-btn>
            <v-btn color="accent" @click="formStepNext">
              {{ form.step.current >= form.step.max ? $t('complete') : $t('continue') }}
            </v-btn>
          </div>
        </div>
      </card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Card from '@/components/Card.vue'
import InputRange from '@/components/SupsterUI/InputRange.vue'

export default Vue.extend({
  name: 'LogisticsForm',
  components: {
    Card,
    InputRange,
  },
  props: {
    mode: {
      type: String,
      default: 'create',
      required: true,
      validator(value) {
        return ['create', 'edit'].indexOf(value) !== -1
      },
    },
  },
  data: () => ({
    form: {
      submited: false,
      data: {},
      validateErrors: {},
      showErrors: false,
      step: {
        current: 0,
        max: 4,
        labels: [],
      },
      lists: {
        deliveryMethods: [],
        deliveryRegions: [],
        deliveryServices: [],
        workDays: [],
      },
    },
  }),
  computed: {
    ...mapGetters('app', { app: 'getSelectedApp' }),
    ...mapGetters('auth', { user: 'getUser' }),
    ...mapGetters('stores', { getStoreItem: 'getStoreItem' }),
    listStepLabels() {
      return [
        this.$t('logisticsCreate.steps.0'),
        this.$t('logisticsCreate.steps.1'),
        this.$t('logisticsCreate.steps.2'),
        this.$t('logisticsCreate.steps.3'),
      ]
    },
    listWorkDays() {
      const workDays = [1, 2, 3, 4, 5, 6, 7].map((num) => {
        return { value: num, label: this.$t(`workDays.${num}`) }
      })
      return workDays
    },
    showRegions() {
      const result = []
      if (this.form.data.deliveryRegions.length) {
        this.form.data.deliveryRegions.map((currentItem) => {
          this.form.lists.deliveryRegions.filter((o) => {
            if (currentItem.id === o.id) {
              result.push(o.name)
            }
            return false
          })
          return true
        })
        return result
      }
      return ['Не указано']
    },
    showDeliveryMethods() {
      const result = []
      if (this.form.data.deliveryMethods.length) {
        this.form.data.deliveryMethods.map((currentItem) => {
          this.form.lists.deliveryMethods.filter((o) => {
            if (currentItem.id === o.id) {
              result.push(o.name)
            }
            return false
          })
          return true
        })
        return result
      }
      return ['Не указано']
    },
    checkedDeliveryServices() {
      return this.form.lists.deliveryServices.map((item) => {
        const newItem = { ...item }
        newItem.disabled = !this.form.lists.deliveryMethods.filter(
          (method) => item.id === method.deliveryService.id
        ).length
        return newItem
      })
    },
  },
  methods: {
    ...mapActions('stores', [
      'loadStore',
      'createStore',
      'updateStore',
      'loadDeliveryMethods',
      'loadDeliveryServices',
      'loadDeliveryRegions',
      'loadStoreFormProps',
    ]),
    ...mapMutations('snackbar', ['callSnackbar']),
    async formStepNext() {
      if (this.form.submited) {
        this.$router.push(`/business/goods_and_services/logistics`)
      }
      const { max } = this.form.step
      let { current } = this.form.step
      if (this.validateStep(current)) {
        current++
      }

      if (current === max && !this.form.submited) {
        const result = await this.formDataSubmit()
        this.form.submited = result
      }
      this.form.step.current = current
    },
    formStepPrev() {
      const { current } = this.form.step
      this.form.step.current = current - 1 < 1 ? 0 : current - 1
    },
    validateStep(step) {
      const errors = {}
      if (step === 0) {
        errors.name = this.form.data.name.length > 2
        errors.region = this.form.data.region.length > 2
        errors.city = this.form.data.city.length > 2
        errors.street = this.form.data.street.length > 2
        errors.house = this.form.data.house.length > 0
        // errors.housing = this.form.data.housing.length > 0
        errors.postcode = this.form.data.postcode.length > 4
        errors.phone = this.form.data.phone.length > 10
        errors.refundInstruction = this.form.data.refundInstruction.length > 1
        errors.workDays = this.form.data.workDays.length > 4
      } else if (step === 1) {
        errors.deliveryServices =
          this.form.data.deliveryServices > 0 || typeof this.form.data.deliveryServices === 'object'
      } else if (step === 2) {
        errors.deliveryMethods = this.form.data.deliveryMethods.length > 0
      } else if (step === 3) {
        errors.deliveryRegions = this.form.data.deliveryRegions.length > 0
      }
      if (Object.values(errors).includes(false)) {
        this.form.validateErrors = errors
        this.form.showErrors = true
        this.callSnackbar({
          color: 'red',
          text: this.$t('allertMessage.stepsError'),
        })
        return false
      }
      this.form.showErrors = false

      return true
    },
    checkPropError(propName) {
      return (
        propName in this.form.validateErrors &&
        !this.form.validateErrors[propName] &&
        this.form.showErrors
      )
    },
    async formDataSubmit() {
      let result
      if (this.mode === 'create') {
        result = await this.createStore({ data: this.form.data })
      } else {
        result = await this.updateStore({ data: this.form.data })
      }

      return result.status
    },
  },
  async created() {
    this.loadStoreFormProps()

    this.form.step.labels = this.listStepLabels
    this.form.lists.workDays = this.listWorkDays

    if (this.mode === 'edit') {
      await this.loadStore({ id: this.$route.params.id })
    }

    this.form.data = this.getStoreItem

    const deliveryMethods = await this.loadDeliveryMethods({})
    this.form.lists.deliveryMethods = deliveryMethods.status ? deliveryMethods.data : []
    const deliveryRegions = await this.loadDeliveryRegions({})
    this.form.lists.deliveryRegions = deliveryRegions.status ? deliveryRegions.data : []
    const deliveryServices = await this.loadDeliveryServices({})
    this.form.lists.deliveryServices = deliveryServices.status ? deliveryServices.data : []

    this.form.data.user = this.user.id
    this.form.data.app = this.app.id
  },
})
</script>
<style lang="scss">
.delivery-form {
  &__checkbox,
  &__radio {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px 9px 0px 9px;
    background: var(--ground-light) !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
    &.v-input--is-label-active {
      background: var(--white) !important;
    }
  }
}

.storages-page {
  &__textarea {
    textarea {
      color: var(--primary-light) !important;
    }
  }
  &__breadcrumbs {
    .supster-ui--step-breadcrumbs__item {
      justify-content: start !important;
    }
  }
  &__workday-item {
    color: var(--v-primary-base) !important;
    background: var(--ground-light) !important;

    &.active {
      font-weight: bold;
      background: var(--white) !important;
    }
    &.errors {
      border: 1px solid var(--v-error-base) !important;
      outline: 1px solid var(--v-error-base) !important;
    }
  }
}
</style>

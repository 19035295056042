



















































































import { Component, Watch, Mixins } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import SectionAccountManagement from '@/views/faq/components/sections/SectionAccountManagement.vue'
import SectionCreatingAnApplication from '@/views/faq/components/sections/SectionCreatingAnApplication.vue'
import SectionSubscriptions from '@/views/faq/components/sections/SectionSubscriptions.vue'
import SectionGoodsAndServices from '@/views/faq/components/sections/SectionGoodsAndServices.vue'
import SectionAnalytics from '@/views/faq/components/sections/SectionAnalytics.vue'
import SectionAffiliateProgram from '@/views/faq/components/sections/SectionAffiliateProgram.vue'
import ChaptersList from '@/views/faq/mixins/ChaptersList'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {
    SectionAccountManagement,
    SectionCreatingAnApplication,
    SectionSubscriptions,
    SectionGoodsAndServices,
    SectionAnalytics,
    SectionAffiliateProgram,
  },
})
export default class Faq extends Mixins(ChaptersList) {
  // Data
  sectionId: number | undefined = 0

  foundChapter: {
    chapterName: TranslateResult
    parrentCategoryId: number
    chapterId: number
    childChapterId?: number
    parrentCategoryName: TranslateResult
    ref: string
  } | null = null

  chapterRef: string | undefined = ''

  searchValue: string | null = null

  parrentChapterId: number | null | undefined = null

  childChapterId: number | null | undefined = null

  foundChapters: Array<{
    chapterName: TranslateResult
    parrentCategoryId: number
    chapterId: number
    childChapterId?: number
    parrentCategoryName: TranslateResult
    ref: string
  }> = []

  // Vuex Getters
  @VuexGetter('nApp') getlocale!: string

  // Computed
  get sections(): Array<{
    id: number
    name: TranslateResult
    component: string
    disabled?: boolean
  }> {
    return [
      {
        id: 0,
        name: this.$t('faq.sections.0.title'),
        component: 'SectionAccountManagement',
      },
      {
        id: 1,
        name: this.$t('faq.sections.1.title'),
        component: 'SectionCreatingAnApplication',
      },
      {
        id: 2,
        name: this.$t('faq.sections.2.title'),
        component: 'SectionSubscriptions',
      },
      {
        id: 3,
        name: this.$t('faq.sections.3.title'),
        component: 'SectionGoodsAndServices',
        disabled: true,
      },
      {
        id: 4,
        name: this.$t('faq.sections.4.title'),
        component: 'SectionAnalytics',
      },
      {
        id: 5,
        name: this.$t('faq.sections.5.title'),
        component: 'SectionAffiliateProgram',
      },
    ]
  }

  get mainSection(): any {
    return this.sections.find((item) => item.id === this.sectionId)
  }

  // Methods
  searchFilter(v: string): any {
    this.foundChapters = this.chapterslist.filter((e) => {
      return (e.chapterName.toString() || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
    })
  }

  scrollToFoundChapter(): void {
    if (this.chapterRef) {
      this.childChapterId = null
      this.parrentChapterId = null
      setTimeout(() => {
        this.childChapterId = this.foundChapter?.childChapterId
        this.parrentChapterId = this.foundChapter?.chapterId
      }, 50)
    }
  }

  clearfoundChapter(): void {
    this.foundChapter = null
  }

  // Watch
  @Watch('searchValue')
  searchValueHandler(val: string): void {
    val = val && this.searchFilter(val)
    if (!this.searchValue) {
      this.foundChapters = []
    }
  }

  @Watch('foundChapter', { deep: true })
  foundChapterHandler(): void {
    if (this.foundChapter) {
      // this.searchValue = null
      setTimeout(() => {
        this.chapterRef = this.foundChapter?.ref
        this.parrentChapterId = this.foundChapter?.chapterId
        this.childChapterId = this.foundChapter?.childChapterId
        this.sectionId = this.foundChapter?.parrentCategoryId
      }, 100)
    } else {
      this.parrentChapterId = null
      this.childChapterId = null
      this.chapterRef = ''
    }
  }

  // Hooks
  mounted(): void {
    if (this.$route.name === 'Affiliate_Faq') {
      this.sectionId = 5
    }
  }
}

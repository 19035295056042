import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { UserAppModelType, UserApiModelType } from './UserTypes'

class UserService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.users')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: UserAppModelType = {}

  private defaultListParams = {
    itemsPerPage: 50,
    page: 1,
    pages: 1,
    pageLoaded: 0,
    loading: false,
  }

  private usedListParams = ['itemsPerPage', 'page', 'has_message', 'app_id', 'order[has_message]']

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.USER)
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public async userMe(): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.USER}/me`
      const response = await this.api.get(requestUrl)
      const result = {
        data: response.data,
      }
      return { status: true, data: result.data }
    } catch (error) {
      return { status: false, data: {}, error }
    }
  }

  public transformDataForApi(incomingData) {
    return incomingData
  }

  public transformDataForApp(incomingData): UserAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    return params
  }
}
export default new UserService()
































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import TransactionsFilter from '@/views/business/subscriptions/transactions/components/TransactionsFilter.vue'
import TransactionsTable from '@/views/business/subscriptions/transactions/components/TransactionsTable.vue'
import TransactionsPopUpDetails from '@/views/business/subscriptions/transactions/components/TransactionsPopUpDetails.vue'
import TransactionsPopUpReturn from '@/views/business/subscriptions/transactions/components/TransactionsPopUpReturn.vue'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import moment from 'moment'
import { filterDataType } from './types'

@Component({
  components: {
    TransactionsFilter,
    TransactionsTable,
    TransactionsPopUpDetails,
    TransactionsPopUpReturn,
  },
})
export default class Transactions extends Vue {
  // Data
  isPreloaderShow = false

  // vuex module action
  @VuexAction('nBusinessTransactions') transactionsGetItems!: () => Promise<boolean>

  @VuexAction('nBusinessTransactions') transactionsGetItem!: (...args) => void

  @VuexAction('nBusinessTransactions') transactionsUpdateItem!: (...args) => void

  @VuexAction('nApp') fetchBalance!: (...args) => void

  // vuex module mutation
  @VuexMutation('nBusinessTransactions') transactionsListParamsUpdate!: (...args) => void

  @VuexMutation('nBusinessTransactions') transactionsChangePopUpDetailsStatus!: (...args) => void

  @VuexMutation('nBusinessTransactions') transactionsChangePopUpReturnStatus!: (...args) => void

  // vuex module getter
  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Methods

  tableChangePage(page: number): void {
    this.transactionsListParamsUpdate({ page })
    this.transactionsGetItems()
  }

  closePopUpDetails(): void {
    this.transactionsChangePopUpDetailsStatus(false)
  }

  openPopUpReturn(): void {
    this.closePopUpDetails()
    this.transactionsChangePopUpReturnStatus(true)
  }

  closePopUpReturn(): void {
    this.transactionsChangePopUpReturnStatus(false)
  }

  transactionReturnCancel(): void {
    this.transactionsChangePopUpReturnStatus(false)
    this.transactionsChangePopUpDetailsStatus(true)
  }

  async transactionReturn(id: number): Promise<void> {
    await this.transactionsUpdateItem({ id, type: 3 })
    await this.tableLoadItems()
    await this.fetchBalance()
    this.transactionsChangePopUpReturnStatus(false)
  }

  async filter(filterData: filterDataType): Promise<void> {
    this.transactionsListParamsUpdate({ app: this.getCurrentApp.id, ...filterData, page: 1 })
    await this.transactionsGetItems()
  }

  async openPopUpDetails(id: number): Promise<void> {
    await this.transactionsGetItem(id)
    this.transactionsChangePopUpDetailsStatus(true)
  }

  async tableLoadItems(): Promise<void> {
    this.transactionsListParamsUpdate({
      app: this.getCurrentApp.id,
      created: [moment().subtract(13, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    })
    await this.transactionsGetItems()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.tableLoadItems()
    this.isPreloaderShow = false
  }
}

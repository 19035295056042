import Vue from 'vue'

Vue.filter('money', (value: number, currency = 'RUB', fractions = 0) => {
  const formatter = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: fractions,
  })
  return formatter.format(value)
})

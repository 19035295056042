<template>
  <div class="supter-component input-check-select" :class="type">
    <v-row v-if="type === 'radio'">
      <v-col
        :cols="cols"
        v-for="(item, index) in filteredList"
        :key="index"
        :class="`my-${itemPaddingY} ${item.disabled ? '_disabled' : ''}`"
      >
        <div
          class="supster-ui--input__box"
          @click="changeRadio(item)"
          :class="{
            _active: item[params.value] === selectedRadio[params.value],
            _disabled: item.disabled,
          }"
        >
          <input-radio
            v-model="selectedRadio[params.value]"
            :label="item[params.label]"
            :val="item[params.value]"
            :actions="false"
            :class="{
              _disabled: item.disabled,
            }"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-else-if="type === 'checkbox'">
      <v-col
        class="py-0"
        :cols="cols"
        v-for="(item, index) in filteredList"
        :key="index"
        :class="`my-${itemPaddingY} ${item.disabled ? '_disabled' : ''}`"
      >
        <div
          class="supster-ui--input__box"
          @click="changeCheckbox(item)"
          :class="{ _active: itemIsChecked(item) }"
        >
          <input-checkbox
            :label="item[params.label]"
            :actions="false"
            :val="item[params.value]"
            :controlChecked="itemIsChecked(item)"
            :class="{
              _disabled: item.disabled,
            }"
          />
          <span
            class="supster-ui--input__select-icon"
            v-if="item[params.children] && item[params.children].length"
          >
            <icon data="@/assets/icons/chevron-down.svg" />
          </span>
        </div>
        <div
          class="mt-4 ml-8"
          v-if="item[params.children] && item[params.children].length && itemIsChecked(item)"
        >
          <input-checkboxes-group
            type="checkbox"
            :list="item[params.children]"
            :params="{
              value: 'id',
              label: 'name',
              children: 'subRegions',
            }"
            v-model="selectedCheckboxes"
            itemPaddingY="1"
          ></input-checkboxes-group>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'InputCheckboxesGroup',
  components: {},
  data: () => ({
    selectedRadio: '',
    selectedCheckboxes: [],
  }),
  props: {
    value: {
      default: '',
    },
    type: {
      type: String,
      default: 'radio',
      validator(value) {
        return ['radio', 'checkbox'].indexOf(value) !== -1
      },
    },
    params: {
      type: Object,
      default() {
        return { value: 'value', label: 'label', filter: false, children: 'children' }
      },
    },
    list: {
      type: Array,
      default() {
        return []
      },
    },
    cols: {
      type: String,
      default: '12',
    },
    itemPaddingY: {
      type: String,
      default: '0',
    },
  },
  computed: {
    filteredList() {
      if (this.params.filter) {
        const mainProp = this.params.value
        const filterProp = this.params.filter.prop
        const filterValue = this.params.filter.value
        return this.list.filter((item) => item[filterProp][mainProp] === filterValue[mainProp])
      }
      return this.list
    },
  },
  methods: {
    changeRadio(item) {
      if (!item.disabled) {
        this.selectedRadio = item
        this.$emit('input', this.selectedRadio)
      }
    },
    changeCheckbox(item) {
      const propValue = this.params.value
      if (!item.disabled) {
        if (this.itemIsChecked(item)) {
          const deleteItemIndex = this.selectedCheckboxes.findIndex(
            (selected) => selected[propValue] === item[propValue]
          )
          this.selectedCheckboxes.splice(deleteItemIndex, 1)
        } else {
          this.selectedCheckboxes.push(item)
        }
        this.$emit('input', this.selectedCheckboxes)
      }
    },
    itemIsChecked(item) {
      const propValue = this.params.value
      return !!this.selectedCheckboxes.filter((i) => i[propValue] === item[propValue]).length
    },
  },
  watch: {
    value(newValue) {
      if (this.type === 'radio') {
        this.selectedRadio = newValue
      } else {
        this.selectedCheckboxes = newValue
      }
    },
  },
  created() {
    if (this.type === 'radio') {
      this.selectedRadio = this.value
    } else {
      this.selectedCheckboxes = this.value
    }
  },
})
</script>
<style lang="scss">
.supter-component.input-check-select {
  .supster-ui--input__box {
    cursor: pointer;
  }
  &._error {
    .supster-ui--input__box {
      border-color: var(--v-error-base);
    }
  }
}
</style>

import { mapServerSidePost } from '@/utils/api.mapper'
import { getInstanceWithToken } from '@/utils/axios'
import { Post, ServerSidePost } from './types'

export interface FetchPostRequest {
  appId?: number
}

export const fetchPosts = async (req: FetchPostRequest): Promise<Post[]> => {
  const { data } = await getInstanceWithToken().get<ServerSidePost[]>('api/posts', {
    params: {
      app: req.appId ? `/api/apps/${req.appId}` : undefined,
    },
  })

  return data.map(mapServerSidePost)
}

export interface CreatePostRequest {
  appId: number
  body: string
  attachmentIds: number[]
  tariffIds: number[]
}

export const createPost = async (req: CreatePostRequest): Promise<Post> => {
  const { data } = await getInstanceWithToken().post<ServerSidePost>('api/posts', {
    app: req.appId ? `/api/apps/${req.appId}` : undefined,
    body: req.body,
    media_objects: req.attachmentIds.map((v) => `/api/media_objects/${v}`),
    tariffs: req.tariffIds?.map((v) => `/api/tariffs/${v}`),
  })

  return mapServerSidePost(data)
}

export interface UpdatePostRequest {
  id: number
  body?: string
  attachmentIds?: number[]
  tariffIds?: number[]
}

export const updatePost = async (req: UpdatePostRequest): Promise<Post> => {
  const { data } = await getInstanceWithToken().put<ServerSidePost>(`api/posts/${req.id}`, {
    body: req.body,
    media_objects: req.attachmentIds?.map((v) => `/api/media_objects/${v}`),
    tariffs: req.tariffIds?.map((v) => `/api/tariffs/${v}`),
  })

  return mapServerSidePost(data)
}

export const deletePost = async (id: number): Promise<void> => {
  await getInstanceWithToken().delete<void>(`api/posts/${id}`)
}

export default fetchPosts

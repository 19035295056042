





















import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'BusinessSalesChannels',
})
export default class BusinessSalesChannels extends Vue {}

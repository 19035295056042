import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { ClientVerificationsAppModelType } from './ClientVerificationsTypes'

class ClientVerificationsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.verification')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.CLIENT_VERIFICATIONS)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {}

  private usedListParams = ['client']

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    if (incomingData.country) {
      data.country = `/api/countries/${incomingData.country}`
    }
    if (incomingData.client) {
      data.client = `/api/clients/${incomingData.client}`
    }
    if (incomingData.media_objects) {
      data.media_objects = incomingData.media_objects.map((item) => `/api/media_objects/${item}`)
    }
    if (incomingData.codeMedia) {
      data.codeMedia = `/api/media_objects/${incomingData.codeMedia}`
    } else {
      delete data.codeMedia
    }
    return data
  }

  public transformDataForApp(incomingData): ClientVerificationsAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)

    return params
  }
}
export default new ClientVerificationsService()



































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { PlacementAppModelType } from '@/services/api/Placement/PlacementTypes'

@Component({})
export default class PlacementPopUpDelete extends Vue {
  // vuex module getter
  @VuexGetter('nPlacements') getPopUpDeleteStatus!: boolean

  @VuexGetter('nPlacements') getPlacementsListItem!: PlacementAppModelType

  // Emits
  @Emit('closePopUpDelete')
  closePopUpDelete(): boolean {
    return false
  }

  @Emit('deleteItem')
  deleteItem(id: number): number {
    return id
  }
}

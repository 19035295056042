




































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Adjust from '@/utils/color'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'

@Component({
  components: {},
})
export default class GptAppPreview extends Vue {
  //  Props
  @Prop({ required: true, type: Object }) readonly app!: AppsAppModelType

  @Prop({ default: false, type: Boolean }) readonly edit!: boolean

  @Prop(String) readonly pageType!: string

  @Prop(String) readonly editMode!: string

  @Prop(String) readonly appColor!: string

  @Prop(String) readonly message!: string

  @Prop(Array) readonly prompts!: string[]

  @Prop({ type: Object }) readonly systemPromt!: any

  @Prop({ type: Object }) readonly defaultChatLogo!: any

  @Prop({ type: Object }) readonly avatar!: any

  // Data
  adjust = Adjust

  get isGptApp(): boolean {
    return this.app?.type === 'chat_gpt'
  }

  get color(): string {
    return this.appColor ?? this.app?.primary_color
  }

  get messageFormatter(): string {
    const message = this.edit ? this.message : this.app.emptyBotChatMessage
    return message
  }

  get avatarSrc(): string | null {
    if (this.editMode === 'systemPromts') {
      return (
        this.systemPromt?.image?.blob ??
        this.systemPromt?.image?.content_url ??
        this.systemPromt?.image?.preview_url
      )
    }
    if (this.edit) {
      return this.avatar?.blob ?? this.avatar?.preview_url ?? this.avatar?.content_url
    }
    return this.app?.emptyBotChatLogo.preview_url ?? this.app?.emptyBotChatLogo.content_url
  }

  get isHideMessage(): boolean {
    return this.editMode === 'limits' || this.editMode === 'examples' || this.editMode === 'avatar'
  }

  get isHideExamples(): boolean {
    return this.editMode === 'limits' || this.editMode === 'message' || this.editMode === 'avatar'
  }

  get isHideLimits(): boolean {
    return this.editMode === 'message' || this.editMode === 'examples' || this.editMode === 'avatar'
  }

  get isShowMessage() {
    if (this.edit) {
      return this.message?.length
    }
    return this.app.emptyBotChatMessage?.length
  }

  get isShowExamples() {
    if (this.edit) {
      return this.prompts?.length
    }
    return this.app.prompts?.length
  }

  get domain() {
    return process.env.VUE_APP_INSTA_APP_DOMAIN
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/store/autoimport'
import Orders from './modules/orders'

import Stats from './modules/stats'
import Dialogues from './modules/dialogues'
import Verification from './modules/verification'
import Delivery from './modules/delivery'
import Stores from './modules/stores'
import Products from './modules/products'
import MediaObjects from './modules/mediaObjects'

// new modules
import nAffiliateFinances from './newModules/nAffiliateFinances'
import nAffiliateMarketing from './newModules/nAffiliateMarketing'
import nAffiliateOffers from './newModules/nAffiliateOffers'
import nAffiliateStatistics from './newModules/nAffiliateStatistics'
import nAffiliateStreams from './newModules/nAffiliateStreams'
import nAffiliateDomainParking from './newModules/nAffiliateDomainParking'
import nAffiliateNews from './newModules/nAffiliateNews'
import nBusinessFinances from './newModules/nBusinessFinances'
import nBusinessManagment from './newModules/nBusinessManagment'
import nBusinessTransactions from './newModules/nBusinessTransactions'
import nBusinessPublication from './newModules/nBusinessPublication'
import nBusinessAccruals from './newModules/nBusinessAccruals'
import nBusinessReferral from './newModules/nBusinessReferral'
import nBusinessVerification from './newModules/nBusinessVerification'
import nBusinessAnalytics from './newModules/nBusinessAnalytics'
import nBusinessDomainParking from './newModules/nBusinessDomainParking'
import nChats from './newModules/nChats'
import nGlobalPostback from './newModules/nGlobalPostback'
import nPlacements from './newModules/nPlacements'
import nLogsPostback from './newModules/nLogsPostback'
import nApp from './newModules/nApp'
import nDeskbook from './newModules/nDeskbook'
import nTariffs from './newModules/nTariffs'
import nProfile from './newModules/nProfile'

modules.orders = Orders
modules.nProfile = nProfile
modules.verification = Verification
modules.stats = Stats
modules.delivery = Delivery
modules.stores = Stores
modules.products = Products
modules.mediaObjects = MediaObjects
modules.dialogues = Dialogues

// new modules

modules.nAffiliateFinances = nAffiliateFinances
modules.nAffiliateMarketing = nAffiliateMarketing
modules.nAffiliateOffers = nAffiliateOffers
modules.nAffiliateStatistics = nAffiliateStatistics
modules.nAffiliateStreams = nAffiliateStreams
modules.nAffiliateDomainParking = nAffiliateDomainParking
modules.nAffiliateNews = nAffiliateNews
modules.nBusinessFinances = nBusinessFinances
modules.nBusinessManagment = nBusinessManagment
modules.nBusinessTransactions = nBusinessTransactions
modules.nBusinessPublication = nBusinessPublication
modules.nBusinessAccruals = nBusinessAccruals
modules.nBusinessReferral = nBusinessReferral
modules.nBusinessVerification = nBusinessVerification
modules.nBusinessAnalytics = nBusinessAnalytics
modules.nBusinessDomainParking = nBusinessDomainParking
modules.nChats = nChats
modules.nGlobalPostback = nGlobalPostback
modules.nPlacements = nPlacements
modules.nLogsPostback = nLogsPostback
modules.nApp = nApp
modules.nDeskbook = nDeskbook
modules.nTariffs = nTariffs

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
})

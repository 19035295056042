




















































































































































































































































































































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import Validation from '@/utils/Validation'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'
import { StreamAppModelType, StreamApiModelType } from '@/services/api/Stream/StreamTypes'
import {
  TrafficbackAppModelType,
  TrafficbackApiModelType,
} from '@/services/api/Trafficback/TrafficbackTypes'
import { PostbackAppModelType, PostbackApiModelType } from '@/services/api/Postback/PostbackTypes'
import NotificationProvider from '@/providers/NotificationProvider'

@Component({})
export default class StreamsForm extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Props
  @Prop({ default: 'create', type: String }) readonly mode!: string

  // Data
  validform = false

  loading = false

  rules = {
    name: Validation.getRules(['strmin:3']),
  }

  needDelete: {
    postback: Array<string | number | null>
    trafficback: Array<string | number | null>
  } = {
    postback: [],
    trafficback: [],
  }

  selects = {
    methods: [
      {
        name: 'GET',
        value: 'get',
      },
      {
        name: 'POST',
        value: 'post',
      },
      {
        name: 'PUT',
        value: 'put',
      },
    ],
    area: [],
    domainStream: [],
    countries: [],
    linkRedirect: [],
  }

  formData: {
    postback: Array<PostbackApiModelType> | Array<PostbackAppModelType> | any
    trafficback: Array<TrafficbackApiModelType> | Array<TrafficbackAppModelType> | any
    stream: StreamApiModelType | StreamAppModelType
  } = {
    postback: [],
    trafficback: [],
    stream: {
      offer: '',
      title: '',
      subId: '',
      pixelFacebook: '',
      pixelTiktok: '',
      pixelGoogleAnalytics: '',
      pixelGoogleAds: '',
      pixelVkontakte: '',
      pixelGoogleTag: '',
    },
  }

  // Vuex module getter
  @VuexGetter('nAffiliateOffers') getStreamsCurrentItem!: StreamAppModelType

  @VuexGetter('nAffiliateOffers') getPostbacksItems!: PostbackAppModelType[]

  @VuexGetter('nAffiliateOffers') getTrafficbacksItems!: TrafficbackAppModelType[]

  @VuexGetter('nAffiliateOffers') getOffersCurrentItem!: OfferAppModelType

  @VuexGetter('nDeskbook') getListCountries!: any

  // Computed
  get offerAppLink(): string {
    const app = this.getOffersCurrentItem.app.name
    const domain: any = process.env.VUE_APP_API_BASE_URL
    let url = domain.replace('my.', app ? `${app.toLowerCase()}.` : 'example.')
    url = `${url}?stream={ID}`
    return url.replace('.com', '.me')
  }

  get streamLink(): string {
    const link = this.formData.stream.link || this.offerAppLink
    const subId = this.formData.stream.subId ? `&subid=${this.formData.stream.subId}` : ``
    return `${link}${subId}`.toLowerCase()
  }

  // Methods
  addItemPostback(): boolean {
    this.formData.postback.push({
      stream: '',
      method: 'get',
      url: '',
      events: true,
      eventOrder: true,
      eventOrderConfirm: true,
      eventOrderDecline: true,
      eventSubscription: true,
      eventSubscriptionRepeat: true,
      eventInstall: true,
    })
    return true
  }

  addItemTrafficback(): boolean {
    this.formData.trafficback.push({
      stream: '',
      url: '',
      countries: '',
    })
    return true
  }

  removeItemPostback(index: number): boolean {
    if ('id' in this.formData.postback[index]) {
      this.needDelete.postback.push(this.formData.postback[index].id)
    }
    this.formData.postback.splice(index, 1)
    return true
  }

  removeItemTrafficback(index: number): boolean {
    if ('id' in this.formData.trafficback[index]) {
      this.needDelete.trafficback.push(this.formData.trafficback[index].id)
    }
    this.formData.trafficback.splice(index, 1)
    return true
  }

  postbackEventOrderChange(postback: PostbackApiModelType, i: number): void {
    if (postback.events) {
      postback.eventOrder = true
      postback.eventOrderDecline = true
      postback.eventOrderConfirm = true
    } else {
      postback.eventOrder = false
      postback.eventOrderDecline = false
      postback.eventOrderConfirm = false
    }
    this.formData.postback[i] = postback
  }

  posbackOneEventchange(i: number): void {
    this.formData.postback[i].events =
      this.formData.postback[i].eventOrder &&
      this.formData.postback[i].eventOrderDecline &&
      this.formData.postback[i].eventOrderConfirm
  }

  async formSubmit(): Promise<void> {
    this.form.validate()
    if (!this.validform) {
      NotificationProvider.add({
        type: 'error',
        message: this.$t('fillRequiredFields'),
      })
      return
    }
    this.loading = true
    const data = {
      offer: `/api/offers/${this.getOffersCurrentItem.id}`,
      title: this.formData.stream.title,
      subId: this.formData.stream.subId,
      pixelFacebook: this.formData.stream.pixelFacebook,
      pixelTiktok: this.formData.stream.pixelTiktok,
      pixelGoogleAnalytics: this.formData.stream.pixelGoogleAnalytics,
      pixelGoogleAds: this.formData.stream.pixelGoogleAds,
      pixelVkontakte: this.formData.stream.pixelVkontakte,
      pixelGoogleTag: this.formData.stream.pixelGoogleTag,
    }
    if (this.mode === 'create') {
      this.$emit('streamCreate', {
        streamData: data,
        postbacks: this.formData.postback,
        trafficbacks: this.formData.trafficback,
      })
    } else if (this.mode === 'edit') {
      this.$emit('streamUpdate', {
        streamId: this.getStreamsCurrentItem.id,
        streamData: data,
        postbacks: this.formData.postback,
        trafficbacks: this.formData.trafficback,
        needDelete: this.needDelete,
      })
    }
  }

  // Hooks
  created(): void {
    if (this.mode === 'edit') {
      this.formData.stream = this.getStreamsCurrentItem
      if (this.getPostbacksItems.length) {
        this.formData.postback = this.getPostbacksItems
        this.formData.postback.forEach((item, index: number) => {
          this.posbackOneEventchange(index)
        })
      } else {
        this.addItemPostback()
      }
      if (this.getTrafficbacksItems.length) {
        this.formData.trafficback = this.getTrafficbacksItems
        this.formData.trafficback = this.formData.trafficback.map((item) => {
          item.countries = parseInt(item.countries.replace(/[^\d]/g, ''))
          return item
        })
      } else {
        this.addItemTrafficback()
      }
    } else {
      this.addItemPostback()
      this.addItemTrafficback()
    }
  }
}

<template>
  <div class="pa-6 feature">
    <v-row class="justify-space-between align-center">
      <v-col>
        <icon :data="icon"></icon>
      </v-col>
      <v-col class="align-self-start text-right">
        <icon data="@/assets/icons/bp-features/dots.svg"></icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>{{ text }}</v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BusinessPageFeature',
  props: {
    text: {
      type: String,
    },
    icon: {
      required: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.feature {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 140px;
  background: white;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  box-shadow: 0px 8px 20px rgba(39, 20, 96, 0.07);
}

.row {
  position: relative;
}

.row > .col {
  &:first-of-type > svg {
    width: 40px;
    height: 40px;
    fill: var(--accent-green);
  }

  &:last-of-type > svg {
    align-self: flex-end;
    justify-self: right;
    text-align: right;
    fill: var(--v-primary-base);
  }
}

.row:last-of-type {
  color: var(--v-primary-base);
  font-size: 12px;
  line-height: 18px;
}
</style>

import { cloneDeep } from 'lodash'
import PlacementService from '@/services/api/Placement/PlacementService'
import PlacementTypeService from '@/services/api/PlacementType/PlacementTypeService'
import { PlacementAppModelType } from '@/services/api/Placement/PlacementTypes'
import { PlacementTypeAppModelType } from '@/services/api/PlacementType/PlacementTypeTypes'
import {
  nPlacementMutations,
  nPlacementActions,
  nPlacementGetters,
  nPlacementModule,
} from './types'

const defaultState = {
  lists: {
    placements: {
      items: [],
      item: {},
      params: PlacementService.getDefaultParams(),
      loading: false,
    },
    placementsTypes: {
      items: [],
      params: PlacementTypeService.getDefaultParams(),
    },
    isOpenPopUpDelete: false,
    isOpenPopUpEdit: false,
  },
}

const actions: nPlacementActions = {
  async placementsGetItems({ commit, state }) {
    commit('placementsTableChangeLoadingStatus', true)
    const response = await PlacementService.getItems({
      params: state.lists.placements.params,
    })
    if (response.status) {
      commit('placementsListParamsUpdate', response.params)
      commit('placementsListItemsUpdate', response.data)
    }
    commit('placementsTableChangeLoadingStatus', false)
    return response.status
  },
  async placementsGetItem({ commit }, id) {
    const response = await PlacementService.getItem({
      id,
    })
    if (response.status) {
      commit('placementsListItemUpdate', response.data)
    }
    return response.status
  },
  async placementsUpdateItem({ dispatch, state }, data) {
    const response = await PlacementService.updateItem({
      id: state.lists.placements.item.id,
      data,
    })
    if (response.status) {
      dispatch('placementsGetItems')
    }
    return response.status
  },
  async placementsCreateItem({ dispatch }, data) {
    const response = await PlacementService.createItem({
      data,
      params: {},
    })
    if (response.status) {
      dispatch('placementsGetItems')
    }
    return response.status
  },
  async placementsDeleteItem({ dispatch }, id) {
    const response = await PlacementService.deleteItem({ id })
    if (response.status) {
      dispatch('placementsGetItems')
    }
    return response.status
  },
  async placementsTypesGetItems({ commit, state }) {
    const response = await PlacementTypeService.getItems({
      params: state.lists.placements.params,
    })
    if (response.status) {
      commit('placementsTypesListItemsUpdate', response.data)
    }
    return response.status
  },
}

const mutations: nPlacementMutations = {
  stateReset(state) {
    Object.assign(state, cloneDeep(defaultState))
  },
  placementsListItemsUpdate(state, items) {
    state.lists.placements.items = items
  },
  placementsListItemUpdate(state, item) {
    state.lists.placements.item = item
  },
  placementsTableChangeLoadingStatus(state, status) {
    state.lists.placements.loading = status
  },
  placementsTypesListItemsUpdate(state, items) {
    state.lists.placementsTypes.items = items
  },
  changePopUpDeleteStatus(state, status: boolean): void {
    state.lists.isOpenPopUpDelete = status
  },
  changePopUpEditStatus(state, status: boolean): void {
    state.lists.isOpenPopUpEdit = status
  },
  placementsListParamsUpdate(state, params): void {
    state.lists.placements.params = { ...state.lists.placements.params, ...params }
  },
}

const getters: nPlacementGetters = {
  getPlacementsListItems(state): Array<PlacementAppModelType> {
    return state.lists.placements.items
  },
  getPlacementsListItem(state): PlacementAppModelType {
    return state.lists.placements.item
  },
  getPlacementsTableLoadingStatus(state): boolean {
    return state.lists.placements.loading
  },
  getPlacementsTypesListItems(state): Array<PlacementTypeAppModelType> {
    return state.lists.placementsTypes.items
  },
  getPopUpDeleteStatus(state): boolean {
    return state.lists.isOpenPopUpDelete
  },
  getPopUpEditStatus(state): boolean {
    return state.lists.isOpenPopUpEdit
  },
  getPlacementsListParams(state): unknown {
    return state.lists.placements.params
  },
}

const nPlacement: nPlacementModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nPlacement



















































import { Component, Vue } from 'vue-property-decorator'
import TariffsTable from '@/views/business/subscriptions/Tariffs/components/TariffsTable.vue'
import PromoPagesTable from '@/views/business/subscriptions/Tariffs/components/PromoPagesTable.vue'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import PromoPagePopUpView from '@/views/business/subscriptions/Tariffs/components/PromoPagePopUpView.vue'
import PromoPagePopUp from '@/views/business/subscriptions/Tariffs/components/PromoPagePopUp/PromoPagePopUp.vue'
import { TariffAppModelType } from '@/services/api/Tariff/TariffTypes'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import TariffPopUpEdit from './components/TariffPopUpEdit.vue'

@Component({
  components: {
    TariffsTable,
    PromoPagesTable,
    PromoPagePopUpView,
    PromoPagePopUp,
    TariffPopUpEdit,
  },
})
export default class Tariffs extends Vue {
  // Data
  isPreloaderShow = false

  // vuex module action
  @VuexAction('nTariffs') tariffsGetItems!: () => Promise<boolean>

  @VuexAction('nTariffs') tariffGetItem!: (...args) => Promise<boolean>

  @VuexAction('nTariffs') tariffUpdateItem!: (...args) => Promise<boolean>

  @VuexAction('nTariffs') promoPagesGetItems!: () => Promise<boolean>

  @VuexAction('nTariffs') promoPageGetItem!: (...args) => Promise<boolean>

  // vuex module mutation
  @VuexMutation('nTariffs') tariffsParamsUpdate!: (...args) => void

  @VuexMutation('nTariffs') promoPagesParamsUpdate!: (...args) => void

  @VuexMutation('nTariffs') promoPageItemUpdate!: (...args) => void

  @VuexMutation('nTariffs') changePromoPagePopUpViewStatus!: (...args) => void

  @VuexMutation('nTariffs') changePromoPagePopUpStatus!: (...args) => void

  @VuexMutation('nTariffs') changeTariffPopUpEditStatus!: (...args) => void

  @VuexMutation('nTariffs') resetForm!: () => void

  // vuex module getter
  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nTariffs') getTariffItem!: TariffAppModelType

  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nTariffs') isOpenTariffPopUpEdit!: boolean

  @VuexGetter('nTariffs') isOpenPromoPagePopUpView!: boolean

  @VuexGetter('nTariffs') isOpenPromoPagePopUp!: boolean

  // Methods
  async tableTariffsLoadItems(): Promise<void> {
    this.tariffsParamsUpdate({
      app: this.getCurrentApp?.id,
      itemsPerPage: 9,
    })
    await this.tariffsGetItems()
  }

  tableTariffsChangePage(page: number): void {
    this.tariffsParamsUpdate({ page })
    this.tariffsGetItems()
  }

  async openTariffPopUpEdit(id: number): Promise<void> {
    await this.tariffGetItem(id)
    this.changeTariffPopUpEditStatus(true)
  }

  closeTariffPopUpEdit(): void {
    this.changeTariffPopUpEditStatus(false)
  }

  async updateTariff(formData): Promise<void> {
    await this.tariffUpdateItem(formData)
    await this.tableTariffsLoadItems()
    await this.tablePromoPagesLoadItems()
    this.closeTariffPopUpEdit()
  }

  async tablePromoPagesLoadItems(): Promise<void> {
    this.promoPagesParamsUpdate({
      app: this.getCurrentApp?.id,
      itemsPerPage: 10,
    })
    await this.promoPagesGetItems()
  }

  tablePromoPagesChangePage(page: number): void {
    this.promoPagesParamsUpdate({ page })
    this.promoPagesGetItems()
  }

  openPromoPagePopUpForCreate(): void {
    this.changePromoPagePopUpStatus(true)
  }

  async openPromoPagePopUpForEdit(id: number): Promise<void> {
    await this.promoPageGetItem(id)
    this.openPromoPagePopUpForCreate()
  }

  closePromoPagePopUp(): void {
    this.promoPageItemUpdate(null)
    this.resetForm()
    this.changePromoPagePopUpStatus(false)
  }

  openPromoPagePopUpView(item: PromotionalPageAppModelType): void {
    this.promoPageItemUpdate(item)
    this.changePromoPagePopUpViewStatus(true)
  }

  closePromoPagePopUpView(): void {
    this.changePromoPagePopUpViewStatus(false)
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    if (this.getCurrentApp) {
      await this.tableTariffsLoadItems()
      await this.tablePromoPagesLoadItems()
    }
    this.isPreloaderShow = false
  }
}

import { cloneDeep } from 'lodash'
import PostbackService from '@/services/api/Postback/PostbackService'
import { PostbackAppModelType, PostbackApiModelType } from '@/services/api/Postback/PostbackTypes'
import UserService from '@/services/api/User/UserService'
import {
  nGlobalPostbackMutations,
  nGlobalPostbackActions,
  nGlobalPostbackGetters,
  nGlobalPostbackModule,
} from './types'

const defaultState = {
  item: {},
  user: {},
}

const actions: nGlobalPostbackActions = {
  async globalPostbackCreate({ commit }, data: PostbackApiModelType): Promise<boolean> {
    const response = await PostbackService.createGlobalPostback(data)
    if (response.data) {
      localStorage.setItem('GLOBAL_POST_BACK', response.data.id)
    }
    return response.status
  },

  async globalPostbackGetItem({ commit }, id: number): Promise<boolean> {
    const response = await PostbackService.getItem({
      id,
    })
    if (response.status) {
      commit('globalPostbackItemUpdate', response.data)
    }
    return response.status
  },
  async userMeGetItem({ commit }): Promise<boolean> {
    const response = await UserService.userMe()
    if (response.status) {
      commit('userMeGetItemUpdate', response.data)
    }
    return response.status
  },
  async globalPostbackUpdate({ commit }, params): Promise<boolean> {
    const response = await PostbackService.updateItem(params)
    return response.status
  },
}
const mutations: nGlobalPostbackMutations = {
  globalPostbackItemUpdate(state, item: PostbackAppModelType) {
    state.item = item
  },
  userMeGetItemUpdate(state, item: any) {
    state.user = item
  },
}

const getters: nGlobalPostbackGetters = {
  getGlobalPostbackItem(state): PostbackAppModelType {
    return state.item
  },
  getUserMe(state): any {
    return state.user
  },
  getMethodsList(): Array<{ name: string; value: string }> {
    return [
      {
        name: 'GET',
        value: 'get',
      },
      {
        name: 'POST',
        value: 'post',
      },
      {
        name: 'PUT',
        value: 'put',
      },
    ]
  },
}

const nGlobalPostback: nGlobalPostbackModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nGlobalPostback



















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class AppVTextFieldCurrency extends Vue {
  // Props
  @Prop(String) readonly label!: string

  @Prop(String) readonly placeholder!: string

  @Prop(String) readonly mask!: string

  @Prop(String) readonly moneySuffix!: string

  @Prop({ default: '' }) readonly thousands!: string

  @Prop([String, Number]) readonly width!: string | number

  @Prop([String, Number]) readonly value!: string | number

  @Prop(Boolean) readonly error!: boolean

  // Data
  money = {
    decimal: '',
    thousands: this.thousands,
    suffix: ` ${this.moneySuffix}`,
    precision: 0,
  }

  // Emit
  @Emit('')
  input(value) {
    const reg = /[^\da-zA-Zа-яёА-ЯЁ]/g
    return value.replace(reg, '')
  }
}

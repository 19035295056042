import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'

interface ServiceCategoriesModelAppTypes {
  id?: number
  subCategories: []
  name: string
}
interface ServiceCategoriesModelApiTypes {
  id: number
}

class ServiceCategoriesService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.secviceCategories')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ServiceCategoriesModelAppTypes = {
    subCategories: [],
    name: '',
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.SERVICE_CATEGORIES)
  }

  public getEmptyItem(): ServiceCategoriesModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(data): ServiceCategoriesModelApiTypes {
    return data
  }

  public transformDataForApp(data): ServiceCategoriesModelAppTypes {
    return data
  }

  public transformRequestParams(params: any): unknown {
    // params['exists[parent]'] = true
    return params
  }
}
export default new ServiceCategoriesService()





















































import { Component, Vue, Watch } from 'vue-property-decorator'
import PayoutSystems from '@/views/business/finances/Requisites/components/PayoutSystems.vue'
import PayoutTypes from '@/views/business/finances/Requisites/components/PayoutTypes.vue'
import PayoutSystemsTable from '@/views/business/finances/Requisites/components/PayoutSystemsTable.vue'
import PayoutDialogConfirm from '@/views/business/finances/Requisites/components/PayoutDialogConfirm.vue'
import PayoutSystemFormCard from '@/views/business/finances/Requisites/components/forms/PayoutSystemFormCard.vue'
import PayoutSystemFormEmpty from '@/views/business/finances/Requisites/components/forms/PayoutSystemFormEmpty.vue'
import PayoutSystemsService from '@/services/api/PayoutSystems/PayoutSystemsService'
import PayoutAccountService from '@/services/api/PayoutAccount/PayoutAccountService'
import PhoneConfirmDataService from '@/services/api/PhoneConfirmData/PhoneConfirmDataService'
import { PayoutSystemsAppModelType } from '@/services/api/PayoutSystems/PayoutSystemsType'
import { PayoutAccountAppModelType } from '@/services/api/PayoutAccount/PayoutAccountType'
import { UserVerificationModelAppType } from '@/services/api/UserVerification/UserVerificationTypes'
import NotificationProvider from '@/providers/NotificationProvider'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {
    PayoutSystems,
    PayoutTypes,
    PayoutSystemsTable,
    PayoutSystemFormCard,
    PayoutSystemFormEmpty,
    PayoutDialogConfirm,
  },
})
export default class BusinessRequisites extends Vue {
  // Data
  payoutSystems: Array<PayoutSystemsAppModelType> = []

  payoutAccounts: Array<PayoutAccountAppModelType> = []

  userVerification: Array<UserVerificationModelAppType> = []

  currentPayoutType = 0

  currentPayoutSystem = 0

  resetForm = false

  tableLoading = false

  isOpenDialogConfirm = false

  secret = ''

  formData = {}

  errorEnteredCode = false

  // Computed
  get getCurrentSystems(): PayoutSystemsAppModelType[] {
    return this.payoutSystems.filter((item) => item.id === 1)
  }

  get filteredPayoutAccountForAffiliate() {
    return this.payoutAccounts.filter((item) => item.type === 1)
  }

  get currentForm(): string {
    let currentForm = ''
    switch (this.currentPayoutSystem) {
      case 1:
        currentForm = 'PayoutSystemFormCard'
        break
      default:
        currentForm = 'PayoutSystemFormEmpty'
    }
    return currentForm
  }

  // Methods

  async payoutSystemsGetItems(): Promise<void> {
    const response = await PayoutSystemsService.getItems({})
    this.payoutSystems = response.data
  }

  async payoutAccountsGetItems(): Promise<void> {
    this.tableLoading = true
    const response = await PayoutAccountService.getItems({})
    if (response.status) {
      this.payoutAccounts = response.data
      this.tableLoading = false
    }
  }

  async phoneConfirmDataCreate(formData: PayoutAccountAppModelType): Promise<void> {
    if (formData) {
      this.formData = formData
      const response = await PhoneConfirmDataService.create({
        data: {
          data: { data: this.formData, payout_system: this.currentPayoutSystem, type: 1 },
          type: 2,
        },
      })
      if (response.status) {
        this.isOpenDialogConfirm = true
        this.secret = response.data.secret
      }
    }
  }

  async phoneConfirmDataAdd(code: string): Promise<void> {
    if (code) {
      this.errorEnteredCode = false
      const response = await PhoneConfirmDataService.add({
        data: { secret: this.secret, code, type: 2 },
      })
      if (response.status) {
        this.currentPayoutType = 0
        this.isOpenDialogConfirm = false
        this.formData = {}
        this.payoutAccountsGetItems()
      } else {
        this.errorEnteredCode = true
        NotificationProvider.add({
          type: 'error',
          message: this.$t('allertMessage.errorConfirmCode'),
        })
      }
    }
  }

  async payoutAccountDeleteItem(id: number): Promise<void> {
    if (id) {
      const response = await PayoutAccountService.deleteItem({ id })
      if (response.status) {
        this.payoutAccountsGetItems()
      }
    }
  }

  // Watch
  @Watch('currentPayoutType')
  resetCurrentPayoutSystem(): void {
    this.currentPayoutSystem = 0
  }

  @Watch('isOpenDialogConfirm')
  isOpenDialogConfirmHandler() {
    if (!this.isOpenDialogConfirm) {
      this.errorEnteredCode = false
    }
  }

  // Hooks

  async created(): Promise<void> {
    await this.payoutSystemsGetItems()
    await this.payoutAccountsGetItems()
  }
}

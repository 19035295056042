











































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Status3 extends Vue {}

import { cloneDeep } from 'lodash'
import ClientVerificationService from '@/services/api/ClientVerifications/ClientVerificationsService'
import { ClientVerificationsAppModelType } from '@/services/api/ClientVerifications/ClientVerificationsTypes'
import {
  nBusinessVerificationMutations,
  nBusinessVerificationActions,
  nBusinessVerificationGetters,
  nBusinessVerificationModule,
} from './types'

const defaultState = {
  clientVerification: {
    currentItem: null,
    params: ClientVerificationService.getDefaultParams(),
  },
}
const actions: nBusinessVerificationActions = {
  async clientVerificationGetItems({ commit, state }): Promise<boolean> {
    const response = await ClientVerificationService.getItems({
      params: state.clientVerification.params,
    })
    if (response.status) {
      commit('clientVerificationParamsUpdate', response.params)
      commit('clientVerificationUpdate', response.data)
    }
    return response.status
  },
  async clientVerificationCreateItem({ commit, dispatch }, data): Promise<boolean> {
    const response = await ClientVerificationService.createItem({
      data,
      params: {},
    })
    dispatch('clientVerificationGetItems')
    return response.status
  },
  async clientVerificationUpdateItem(
    { commit, dispatch },
    { data, id, notification }
  ): Promise<boolean> {
    const response = await ClientVerificationService.updateItem({
      data,
      id,
      notification,
    })
    dispatch('clientVerificationGetItems')
    return response.status
  },
}

const mutations: nBusinessVerificationMutations = {
  clientVerificationParamsUpdate(state, params): void {
    state.clientVerification.params = { ...state.clientVerification.params, ...params }
  },
  clientVerificationUpdate(state, items: ClientVerificationsAppModelType[]): void {
    state.clientVerification.currentItem = items[0]
  },
}

const getters: nBusinessVerificationGetters = {
  getClientVerification(state): ClientVerificationsAppModelType {
    return state.clientVerification.currentItem
  },
}

const nBusinessVerification: nBusinessVerificationModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nBusinessVerification

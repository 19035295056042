import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
//  @typescript-eslint/ban-ts-comment
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/extensions
import ru from 'vuetify/src/locale/ru.ts'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css'
import IconCheckboxOn from '@/components/iconsForVuetify/IconCheckboxOn.vue'
import IconCheckboxOff from '@/components/iconsForVuetify/IconCheckboxOff.vue'
import IconRadioOn from '@/components/iconsForVuetify/IconRadioOn.vue'
import IconRadioOff from '@/components/iconsForVuetify/IconRadioOff.vue'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru',
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 1200,
      md: 1400,
      lg: 1600,
    },
    scrollBarWidth: 24,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#271460',
          lighten1: '#73649e',
        },
        grey: {
          base: '#9E9E9E',
          lighten1: '#dcd6e7',
          lighten2: '#fbfafc',
        },
        accent: '#27C793',
        white: '#ffffff',
        red: '#f87171',
        purple: '#dcd6e7',
        purpleLight: '#73649e',
        error: 'e46969',
        ground: '#f4f2f7',
        groundLight: 'fbfafc',
      },
      dark: {
        primary: '#271460',
        accent: '#27C793',
        white: '#ffffff',
        red: '#f87171',
        purple: '#dcd6e7',
        purpleLight: '#73649e',
        error: 'e46969',
        groundLight: 'fbfafc',
      },
    },
  },
  icons: {
    values: {
      photo:
        'M10 0H4C1.79083 0 0 1.79083 0 4V10C0 12.2091 1.79083 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79083 12.2091 0 10 0ZM13 10C13 11.6542 11.6542 13 10 13H4C2.34576 13 1 11.6542 1 10V4C1 2.34576 2.34576 1 4 1H10C11.6542 1 13 2.34576 13 4V10ZM7 4C5.34314 4 4 5.34314 4 7C4 8.65686 5.34314 10 7 10C8.6568 10 10 8.65686 10 7C10 5.34314 8.6568 4 7 4ZM7 9C5.89722 9 5 8.10278 5 7C5 5.89716 5.89722 5 7 5C8.10278 5 9 5.89716 9 7C9 8.10278 8.10278 9 7 9Z',
      video:
        'M10 0H4C1.79083 0 0 1.79083 0 4V10C0 12.2091 1.79083 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79083 12.2091 0 10 0ZM13 10C13 11.6542 11.6542 13 10 13H4C2.34576 13 1 11.6542 1 10V4C1 2.34576 2.34576 1 4 1H10C11.6542 1 13 2.34576 13 4V10ZM5 10L10 7L5 4V10ZM6 5.76617L8.05634 7L6 8.23376V5.76617Z',
      file: 'M11.8602 7.17994L6.39484 12.8598C5.68664 13.5952 4.74399 14 3.74078 14C2.7366 14 1.79395 13.5952 1.08575 12.8598C-0.361917 11.3544 -0.361917 8.90461 1.08575 7.39918L3.33247 5.06463L7.47912 0.756374C7.948 0.268564 8.57318 0 9.23938 0C9.24035 0 9.24035 0 9.24035 0C9.90656 0 10.5327 0.268564 11.0026 0.756374C11.9589 1.75055 11.9589 3.36779 11.0026 4.36197L4.98136 10.6185C4.8827 10.7206 4.75181 10.7719 4.62091 10.7719C4.49588 10.7719 4.37084 10.7255 4.27413 10.6322C4.07486 10.4408 4.069 10.1244 4.26046 9.92515L10.2817 3.66858C10.8697 3.05675 10.8697 2.0616 10.2817 1.44976C10.0023 1.15971 9.63206 1.00003 9.24035 1.00003C8.84864 1.00003 8.4794 1.15971 8.20003 1.44976L4.05337 5.75801L1.80665 8.09257C0.726275 9.21565 0.726275 11.0434 1.80665 12.1664C2.84112 13.2402 4.63752 13.2422 5.67394 12.1664L11.1393 6.48655C11.3327 6.28733 11.6492 6.28293 11.8465 6.47288C12.0458 6.66429 12.0517 6.98071 11.8602 7.17994Z',
      checkboxOn: {
        component: IconCheckboxOn,
      },
      checkboxOff: {
        component: IconCheckboxOff,
      },
      radioOn: {
        component: IconRadioOn,
      },
      radioOff: {
        component: IconRadioOff,
      },
    },
  },
})

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { PostbackAppModelType, PostbackApiModelType } from './PostbackTypes'

class PostbackService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.postback')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.POSTBACK)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 30,
    page: 1,
  }

  private usedListParams = [
    'itemsPerPage',
    'page',
    'stream',
    'date_start',
    'date_end',
    'url',
    'pagination',
  ]

  public async createGlobalPostback(data: PostbackApiModelType): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.POSTBACK}/create-global`
      const response = await this.api.post(requestUrl, data)
      const result = {
        data: response.data,
      }
      this.addNotification({
        message: `${i18n.t('allertMessage.recordCreateSuccess', { model: this.modelName })}`,
      })
      return { status: true, data: result.data }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordCreateError', { model: this.modelName })}`
      )
      return { status: false, data: {}, error }
    }
  }

  public async logsPostbackGetItems<P>({ params }: { params?: P }): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.POSTBACK}/sent`
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const result = {
        data: response.data,
        params: {
          page: parseInt(response.headers['x-pagination-page']),
          pages: parseInt(response.headers['x-pagination-pages']),
        },
      }
      return { status: true, data: result.data, params: result.params }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, params: {}, error }
    }
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData): PostbackAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    delete params.created
    if (incomingParams.created && incomingParams.created.length > 0) {
      params.date_start = incomingParams.created[0]
      params.date_end = incomingParams.created[1]
    }
    return params
  }
}
export default new PostbackService()

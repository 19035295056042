var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-v-card',{staticClass:"mb-4"},[_c('div',{staticClass:"pa-6"},[_c('h3',{staticClass:"mb-2 text-large text-primary lh-28"},[_vm._v(" "+_vm._s(_vm.$t('pageBusinessDigital.tariff.header'))+" ")]),_c('div',[_c('span',{staticClass:"mr-4 text-primary lh-22"},[_vm._v(_vm._s(_vm.$t('pageBusinessDigital.tariff.desc')))]),_c('span',{staticClass:"text-accent-green"},[_c('icon',{staticClass:"mr-2",attrs:{"data":require('@/assets/icons/bp-features/publish/link-out.svg')}}),_c('a',{staticClass:"text-accent-green font-weight-600 tdn",attrs:{"href":"javascript:void(0)"}},[_vm._v(_vm._s(_vm.$t('pageBusinessDigital.tariff.link')))])],1)])]),_c('app-data-table',{staticClass:"tariff-table",attrs:{"pages":_vm.getTariffsParams.pages,"currentPage":_vm.getTariffsParams.page,"headers":_vm.tableHeaders,"loading":_vm.getTariffsLoading,"items":_vm.getTariffsItems},on:{"input":_vm.tableChangePage},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" "+_vm._s(_vm.getAppCurrencySymbol)+" ")]}},{key:"item.promoPage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.promotionalPage ? item.promotionalPage.name : '-')+" ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-end"},[(item.sum)?_c('b',[_vm._v(_vm._s(item.sum)+" "+_vm._s(_vm.getAppCurrencySymbol))]):_c('b',[_vm._v(_vm._s(0)+" "+_vm._s(_vm.getAppCurrencySymbol))])])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(item.active || 0)+" ")])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("pageBusinessDigital.tariffNew." + (item.period))))+" ")]}},{key:"item.duration",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('promoPopUp.duration'))+" ")]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"_bl",on:{"click":function($event){return _vm.openTariffPopUpEdit(item.id)}}},[(_vm.getTariffLoadingItem && _vm.selectedItemId === item.id)?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","width":"2","size":16}}):_c('icon',{attrs:{"color":"var(--accent-green)","data":require("@/assets/icons/edit-pencil.svg")}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
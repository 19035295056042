import { render, staticRenderFns } from "./IconRadioOn.vue?vue&type=template&id=3522d9a5&scoped=true&functional=true&"
import script from "./IconRadioOn.vue?vue&type=script&lang=js&"
export * from "./IconRadioOn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "3522d9a5",
  null
  
)

export default component.exports
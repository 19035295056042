import { StatsGetters } from './types'

const getters: StatsGetters = {
  getDateRange(state) {
    return state.dateRange
  },
  getGroupBy(state) {
    return state.groupBy
  },
  getAffiliateStatTable(state) {
    return state.affiliateStatTable
  },
  getAnalytics(state) {
    return state.analytics
  },
  getCommonStats(state) {
    return state.commonStats
  },
  getFilterItems(state) {
    return state.filterItems
  },
  getReportsTableStats(state) {
    return state.reportsTableStats
  },
  getMetrics(state) {
    return state.metrics
  },
  getSortedReportsTableStats: (state) => (sort) => {
    if (sort.param) {
      return sort.isDescending
        ? state.analytics.detail.sort((a, b) => a[sort.param] - b[sort.param]) || []
        : state.analytics.detail.sort((a, b) => b[sort.param] - a[sort.param]) || []
    }
    return state.analytics.detail
  },
  getMenuTab(state) {
    return state.menuTab
  },
}

export default getters

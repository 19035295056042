//  "periods": {
//     "1": "Day",
//     "2": "Week",
//     "3": "Month",
//     "4": "3 Month",
//     "5": "6 Month",
//     "6": "Year"
//   }
// eslint-disable-next-line import/prefer-default-export
export const priceForTariff = {
  chat_gpt: {
    2: {
      $: {
        minPrice: 7,
        defaultPrice: 7,
      },
      '₽': {
        minPrice: 690,
        defaultPrice: 690,
      },
    },
    3: {
      $: {
        minPrice: 21,
        defaultPrice: 21,
      },
      '₽': {
        minPrice: 2100,
        defaultPrice: 2100,
      },
    },
    5: {
      $: {
        minPrice: 79,
        defaultPrice: 79,
      },
      '₽': {
        minPrice: 7990,
        defaultPrice: 7990,
      },
    },
  },
  default: {
    2: {
      $: {
        minPrice: 0,
        defaultPrice: 0,
      },
      '₽': {
        minPrice: 100,
        defaultPrice: 199,
      },
    },
    3: {
      $: {
        minPrice: 0,
        defaultPrice: 0,
      },
      '₽': {
        minPrice: 100,
        defaultPrice: 499,
      },
    },
    5: {
      $: {
        minPrice: 0,
        defaultPrice: 0,
      },
      '₽': {
        minPrice: 100,
        defaultPrice: 1499,
      },
    },
  },
}

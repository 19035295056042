<script>
import VFileInput from 'vuetify/lib/components/VFileInput/VFileInput'

export default {
  name: 'AppVFileInput',
  extends: VFileInput,
  props: {
    prependIcon: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    backgroundColor: {
      type: String,
      default: 'grey lighten-2',
    },
  },
  computed: {
    classes() {
      return {
        ...VFileInput.options.computed.classes.call(this),
        'is-show-label': this.showLabel,
        'is-prepend-inner-icon': this.prependInnerIcon,
      }
    },
  },
}
</script>

<style scoped></style>

import Centrifuge from 'centrifuge'
import getCentrifugeInstance from '@/utils/centrifuge'
import { getSocketKey } from '@/utils/user'

type eventType = 'created' | 'updated' | 'removed' | 'read' | 'paid' | 'not_paid' | 'converted'

type entityType =
  | 'story'
  | 'channel'
  | 'channel_message'
  | 'access_token'
  | 'order'
  | 'media_object'

export type handlerType = {
  event_type: eventType
  entity_type: entityType
  action: (any) => void
}

export type WebsocketType = {
  init(): void
  addHandler(handler: handlerType): void
}

class WebsocketServise implements WebsocketType {
  PRODUCTION_MODE: boolean

  websocketInstance!: any

  eventHandlers: any = {}

  constructor() {
    this.PRODUCTION_MODE = process.env.VUE_APP_PRODUCTION_MODE === '1'
    // this.createInstance()
    // this.init()
  }

  private createInstance() {
    this.websocketInstance = getCentrifugeInstance()
  }

  private WsOnConnect() {
    this.websocketInstance.on('connect', (ctx) => {
      console.log('WS Connected', ctx)
    })
  }

  private WsOnDisconnect() {
    this.websocketInstance.on('disconnect', (ctx) => {
      console.log('WS Disconnected', ctx)
    })
  }

  private async WsSubscribe(socketKey) {
    this.websocketInstance.subscribe(socketKey, (ctx) => {
      const signal = ctx.data
      let handlerActions = false
      if (this.eventHandlers[signal.entity_type]) {
        handlerActions = this.eventHandlers[signal.entity_type][signal.event_type]
      }
      // use handlerActions
      if (Array.isArray(handlerActions)) {
        handlerActions.forEach((handler) => {
          handler(ctx.data)
        })
      }
    })
  }

  private async WsConnect() {
    this.websocketInstance.connect()
  }

  private async WsDisconect() {
    this.websocketInstance.disconnect()
  }

  public async init() {
    const socketKey = await getSocketKey()
    this.createInstance()
    this.WsOnConnect()
    this.WsOnDisconnect()
    this.WsSubscribe(socketKey)
    this.WsConnect()
  }

  public async destroy() {
    this.WsDisconect()
  }

  public getInstance() {
    // this.addHeaderToken()
    return this.websocketInstance
  }

  public addHandler(handler: handlerType) {
    if (this.eventHandlers[handler.entity_type] === undefined) {
      this.eventHandlers[handler.entity_type] = {}
    }
    if (this.eventHandlers[handler.entity_type][handler.event_type] === undefined) {
      this.eventHandlers[handler.entity_type][handler.event_type] = []
    }

    this.eventHandlers[handler.entity_type][handler.event_type].push(handler.action)
  }
}
const WebSocket = new WebsocketServise()

export default WebSocket

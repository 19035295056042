import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'

interface ProductTnvedsModelAppTypes {
  id?: number
}
interface ProductTnvedsModelApiTypes {
  id?: number
}

class ProductTnvedsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.productsTNVED')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ProductTnvedsModelAppTypes = {}

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PRODUCT_TNVEDS)
  }

  public getEmptyItem(): ProductTnvedsModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(data): ProductTnvedsModelApiTypes {
    return data
  }

  public transformDataForApp(data): ProductTnvedsModelAppTypes {
    let { fullName } = data
    fullName = fullName.trim()
    data.fullName = fullName[0].toUpperCase() + fullName.toLowerCase().slice(1)
    return data
  }

  public transformRequestParams(params: unknown): unknown {
    return params
  }
}
export default new ProductTnvedsService()

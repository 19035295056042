import Vue from 'vue'

import { VerificationMutations } from './types'

const mutations: VerificationMutations = {
  setVerification(state, payload) {
    state.verification = payload
  },
}

export default mutations

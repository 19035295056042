











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AppBadge extends Vue {
  // Props
  @Prop(String) readonly title!: string

  @Prop([String, Number]) readonly value!: string
}

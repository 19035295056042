



































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import moment from 'moment'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import NotificationProvider from '@/providers/NotificationProvider'

@Component({})
export default class StreamsListItem extends Vue {
  // Props
  @Prop(Object) readonly stream!: StreamAppModelType

  // Methods
  setDate(date: string): string {
    return moment(date).locale('ru').format('DD MMMM YYYY')
  }

  copyLink(): void {
    NotificationProvider.add({
      type: 'success',
      message: this.$t('copyToBuffer'),
    })
  }

  // Emit
  @Emit('streamEdit')
  streamEdit(): number {
    return this.stream.id
  }

  @Emit('openDeletePopUp')
  openDeletePopUp(): number {
    return this.stream.id
  }
}

import { VerificationGetters } from './types'

const getters: VerificationGetters = {
  getVerification(state) {
    return state.verification
  },
  getVerificationStatus(state) {
    return state.verification ? state.verification.status : -1
  },
}

export default getters

import ProductsService from '@/services/api/Products/ProductsService'
import ProductStoresService from '@/services/api/ProductStores/ProductStoresService'
import { ProductsActions } from '@/store/modules/products/types'
import ProductCategoriesService from '@/services/api/ProductCategories/ProductCategoriesService'
import ProductBrandsService from '@/services/api/ProductBrands/ProductBrandsService'
import ProductPropertiesService from '@/services/api/ProductProperties/ProductPropertiesService'
import ProductTnvedsService from '@/services/api/ProductTnveds/ProductTnvedsService'
import ServiceCategoriesService from '@/services/api/ServiceCategories/ServiceCategoriesService'

const actions: ProductsActions = {
  loadProductFormProps({ commit }): void {
    commit('setProductsItem', { data: ProductsService.getEmptyItem() })
  },
  async loadProducts({ commit }, { params = {} }) {
    const response = await ProductsService.getItems({ params })
    if (response.status) {
      commit('setProductsListItems', { response })
    } else {
      console.log('error setProductsListItems')
    }
    return response
  },
  async loadProduct({ commit }, { id = 0, params = {} }) {
    const response = await ProductsService.getItem({ id })
    if (response.status) {
      commit('setProductsItem', { data: response.data })
    } else {
      console.log('error setStoreItem')
    }
    return response
  },
  async createProduct({ commit }, { data = {}, params = {} }) {
    const response = await ProductsService.createItem({ data, params })
    return response
  },
  async updateProduct({ commit }, { data = {}, params = {} }) {
    const response = await ProductsService.updateItem({ data, id: data.id })
    return response
  },
  async deleteProduct({ commit }, { id = 0 }) {
    const response = await ProductsService.deleteItem({ id })
    return response
  },

  async getItemsroductStores({ commit }, { params = {}, data = {} }) {
    const response = await ProductStoresService.getItems({ params })
    return response.status ? response.data : []
  },
  async createProductStores({ commit }, { params = {}, data = {} }) {
    const response = await ProductStoresService.createItem({ data, params })
    return response
  },
  async updateProductStores({ commit }, { id = 0, data = {}, params = {} }) {
    const response = await ProductStoresService.updateItem({ id, data })
    return response
  },
  async deleteProductStores({ commit }, { id = 0, params = {} }) {
    const response = await ProductStoresService.deleteItem({ id })
    return response
  },

  async loadProductCategories({ commit }, { params = {} }) {
    const response = await ProductCategoriesService.getItems({ params })
    return response
  },
  async loadProductBrands({ commit }, { params = {} }) {
    const response = await ProductBrandsService.getItems({ params })
    return response
  },
  async loadProductTnveds({ commit }, { params = {} }) {
    const response = await ProductTnvedsService.getItems({ params })
    return response
  },
  async loadProductProperties({ commit }, { params = {} }) {
    const response = await ProductPropertiesService.getItems({ params })
    return response
  },
  async loadServiceCategories({ commit }, { params = {} }) {
    const response = await ServiceCategoriesService.getItems({ params })
    return response
  },
}

export default actions

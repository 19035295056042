import { Module, MutationAction, VuexModule, Mutation } from 'vuex-module-decorators'
import { getApp, setApp, unsetApp } from '@/utils/app'
import { fetchApp, fetchApps } from '@/services/app'
import { fetchPosts } from '@/services/posts'
import { App } from '@/services/types'
import { getInstanceWithToken } from '@/utils/axios'

@Module({ namespaced: true, name: 'app' })
export default class AppStore extends VuexModule {
  apps = null

  appLoadStatus = false

  selectedAppPosts = []

  selectedApp = getApp()

  mobileSidebar = false

  appsHasOffers = []

  get getApps() {
    return this.apps
  }

  get getAppsHasOffers() {
    return this.appsHasOffers
  }

  get isAppReload() {
    return this.appLoadStatus
  }

  get getSelectedAppPosts() {
    return this.selectedAppPosts
  }

  get getSelectedApp() {
    return this.selectedApp
  }

  get getMobileSidebar() {
    return this.mobileSidebar
  }

  @Mutation
  appLoadSetStatus(status: boolean) {
    this.appLoadStatus = status
  }

  @MutationAction({ mutate: ['selectedAppPosts', 'selectedApp'] })
  async initApp(appId: number) {
    const [app, posts] = await Promise.all([fetchApp(appId), fetchPosts({ appId })])
    setApp(app)
    return {
      selectedAppPosts: posts,
      selectedApp: app,
    }
  }

  @MutationAction({ mutate: ['apps'] })
  async fetchApps() {
    const apps = await fetchApps()

    return { apps }
  }

  @MutationAction({ mutate: ['appsHasOffers'] })
  async fetchAppsHasOffers() {
    const response = await getInstanceWithToken().get('/api/apps/has_offer')
    return { appsHasOffers: response.data }
  }

  @MutationAction({ mutate: ['selectedApp'] }) // TODO: selectedAppPosts SUP-1423
  async selectApp(app: App) {
    setApp(app)

    return {
      // selectedAppPosts: await fetchPosts({ appId: app.id }), // TODO: selectedAppPosts SUP-1423
      selectedApp: app,
    }
  }

  @MutationAction({ mutate: ['selectedAppPosts', 'selectedApp', 'apps'] })
  async unsetApps() {
    unsetApp()
    return {
      selectedAppPosts: [],
      apps: null,
      selectedApp: null,
    }
  }

  @Mutation
  changeMobileSidebar(value: boolean): void {
    this.mobileSidebar = value
  }
}





































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'AppDataTableActionDropdown',
})
export default class AppDataTableActionDropdown extends Vue {
  // Props
  @Prop({ default: 15 }) readonly nudgeLeft!: number

  @Prop(Boolean) readonly left!: boolean

  @Prop(Boolean) readonly divider!: boolean

  @Prop({ default: '#dcd6e7' }) readonly dividerColor!: string

  @Prop(String) readonly backgroundColor!: string

  @Prop({ default: 0 }) readonly nudgeRight!: number

  @Prop({ default: 35 }) readonly nudgeTop!: number

  @Prop({ default: 278 }) readonly nudgeWidth!: number

  @Prop({ default: 278 }) readonly maxWidth!: number

  // Computed
  get classes() {
    const classes: string[] = []
    this.left ? classes.push('left', 'mr-10') : classes.push('ml-10')
    this.divider ? classes.push('divider') : classes.push('')
    return classes
  }
}





























import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {},
})
export default class Step5 extends Vue {
  @VuexGetter('nTariffs') getFormData!: any
}

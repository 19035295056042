import i18n from '@/plugins/i18n'
import { cloneDeep } from 'lodash'
import { TranslateResult } from 'vue-i18n'
import MoneyIncomesService from '@/services/api/MoneyIncomes/MoneyIncomesService'
import {
  MoneyIncomesModelAppType,
  MoneyIncomesTotalModelAppType,
} from '@/services/api/MoneyIncomes/MoneyIncomesTypes'
import {
  nBusinessAccrualsMutations,
  nBusinessAccrualsActions,
  nBusinessAccrualsGetters,
  nBusinessAccrualsModule,
} from './types'

const defaultState = {
  lists: {
    moneyIncomes: {
      loading: false,
      items: [],
      params: MoneyIncomesService.getDefaultParams(),
      total: null,
    },
  },
}
const actions: nBusinessAccrualsActions = {
  async moneyIncomesGetItems({ commit, state }): Promise<boolean> {
    commit('moneyIncomesChangeLoadingList', true)
    const response = await MoneyIncomesService.getItems({
      params: state.lists.moneyIncomes.params,
    })
    if (response.status) {
      commit('moneyIncomesListParamsUpdate', response.params)
      commit('moneyIncomesListItemsUpdate', response.data)
    }
    commit('moneyIncomesChangeLoadingList', false)
    return response.status
  },
  async moneyIncomesGetTotal({ commit, state }, params): Promise<boolean> {
    const response = await MoneyIncomesService.getTotal(params)
    if (response.status) {
      commit('moneyIncomesTotalUpdate', response.data)
    }

    return response.status
  },
}

const mutations: nBusinessAccrualsMutations = {
  moneyIncomesChangeLoadingList(state, status: boolean): void {
    state.lists.moneyIncomes.loading = status
  },
  moneyIncomesListParamsUpdate(state, params): void {
    state.lists.moneyIncomes.params = { ...state.lists.moneyIncomes.params, ...params }
  },
  moneyIncomesTotalListParamsUpdate(state, params): void {
    state.lists.moneyIncomes.params = { ...state.lists.moneyIncomes.params, ...params }
  },
  moneyIncomesListItemsUpdate(state, items: MoneyIncomesModelAppType): void {
    state.lists.moneyIncomes.items = items
  },
  moneyIncomesTotalUpdate(state, item: MoneyIncomesTotalModelAppType): void {
    state.lists.moneyIncomes.total = item
  },
}

const getters: nBusinessAccrualsGetters = {
  getMoneyIncomesListLoading(state): boolean {
    return state.lists.moneyIncomes.loading
  },
  getMoneyIncomesListParams(state): unknown {
    return state.lists.moneyIncomes.params
  },
  getMoneyIncomesListItems(state): [] | [MoneyIncomesModelAppType] {
    return state.lists.moneyIncomes.items
  },
  getMoneyIncomesTotal(state): [] | MoneyIncomesTotalModelAppType {
    return state.lists.moneyIncomes.total
  },
}

const nBusinessAccruals: nBusinessAccrualsModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nBusinessAccruals









































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { SubscriptionAppModelType } from '@/services/api/Subscription/SubscriptionTypes'

@Component({})
export default class ManagmentPopUpUnsubscribe extends Vue {
  // vuex module getter
  @VuexGetter('nBusinessManagment') getPopUpUnsubscribeStatus!: boolean

  @VuexGetter('nBusinessManagment') getSubscriptionListItem!: SubscriptionAppModelType

  // Emit
  @Emit('closePopUpUnsubscribe')
  closePopUpUnsubscribe(): boolean {
    return false
  }

  @Emit('leaveActiveSubscription')
  leaveActiveSubscription(): boolean {
    return false
  }

  @Emit('unsubscribe')
  unsubscribe(id: number): number {
    return id
  }
}










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AppDataTable extends Vue {
  // $refs
  $refs!: {
    defaultTable: HTMLTableElement
  }

  // data
  pageCount = 0

  page = 1

  // Props
  @Prop({ default: false }) readonly bordered!: boolean

  @Prop({ default: false }) readonly rounded!: boolean

  @Prop({ default: [] }) readonly headers!: []

  @Prop({ default: [] }) readonly items!: []

  @Prop({ default: 30 }) readonly itemsPerPage!: number

  @Prop({ default: false }) readonly search!: boolean

  @Prop({ default: 1 }) readonly pages!: number

  @Prop({ default: 0 }) readonly currentPage!: number

  @Prop({ default: '' }) readonly section!: string

  @Prop({ default: false }) readonly hidePagination!: boolean

  @Prop({ default: true }) readonly disableSort!: boolean

  @Prop({ default: false }) readonly loading!: boolean

  // methods
  resetPagination(): void {
    this.page = 1
  }

  nextPage(): void {
    this.$emit('nextPage')
  }

  previousPage(): void {
    this.$emit('previousPage')
  }

  pageInput(): void {
    this.$emit('input', this.page)
    this.$vuetify.goTo(this.$refs.defaultTable)
  }

  @Watch('currentPage')
  currentPageHandler() {
    this.page = this.currentPage
  }

  // hooks
  mounted() {
    if (this.currentPage > 0) {
      this.page = this.currentPage
    }
  }
}

<template>
  <div class="profit-slider">
    <div class="profit-slider-label">
      <div v-for="label in checkType.labels" :key="label">
        {{ label }}
      </div>
    </div>
    <v-slider
      :value="value"
      min="0"
      :max="checkType.max"
      :step="checkType.step"
      track-color="#DCD6E7"
      @change="onChange"
    >
    </v-slider>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ProfitSlider',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    dataType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('nApp', ['getlocale']),

    checkType() {
      return this.dataType === 'dataForSubscription'
        ? this.dataForSubscription
        : this.dataForFollowers
    },

    dataForFollowers() {
      return {
        labels: ['0', '25 000', '50 000', '75 000', '100 000'],
        step: '1000',
        max: '100000',
      }
    },
    dataForSubscription() {
      return this.getlocale === 'ru'
        ? {
            labels: ['0', '2 500', '5 000', '7 500', '10 000'],
            step: '500',
            max: '10000',
          }
        : {
            labels: ['0', '25', '50', '75', '100'],
            step: '5',
            max: '100',
          }
    },
  },
  methods: {
    onChange(value) {
      this.$emit('change', value)
    },
  },
})
</script>

<style lang="scss">
.profit-slider {
  margin: 0px;

  .v-slider {
    margin: 0 !important;
    padding: 0 !important;
  }

  .profit-slider-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: var(--v-primary-base);
    font-size: 12px;
  }

  .v-input__slot {
    padding: initial !important;
    background: none !important;
    border: 0 !important;
    border-radius: 0 !important;
  }

  .v-slider__track-container {
    height: 4px !important;
    overflow: hidden;
    border-radius: 8px !important;
  }

  .v-slider__thumb-label {
    top: 100% !important;
    bottom: auto !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .v-slider__thumb {
    width: 16px;
    height: 16px;

    &:hover,
    &:focus,
    &:active {
      &:before {
        transform: translate(-50%, -50%) !important;
      }
    }
  }

  .v-slider__thumb:before {
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    background: url('data:image/svg+xml,%3Csvg width="6" height="6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 0h1v1H0V0zM5 0h1v1H5V0zM0 5h1v1H0V5zM5 5h1v1H5V5z" fill="%23fff"/%3E%3C/svg%3E')
      center;
    background-size: cover;
    border-radius: 0 !important;
    transform: translate(-50%, -50%);
    opacity: 1 !important;
  }

  .v-slider__thumb::after {
    top: 100%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: auto;
    margin-top: 8px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    transform: translateX(-52%);
    content: var(--content);
  }
  .v-slider__thumb {
    border-radius: 4px;
  }
}
</style>

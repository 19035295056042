<template>
  <div @click="onSort" class="header-cell">
    <div>{{ title }}</div>
    <icon
      class="header-cell-icon"
      :class="{ active: isColumnSort }"
      :data="require('@/assets/icons/sorting-arrows.svg')"
    />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'TableSortingHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    sortValue: {
      type: String,
    },
    value: {
      type: Object,
    },
  },
  computed: {
    isColumnSort() {
      return this.value.sortValue === this.sortValue
    },
  },
  data: () => ({
    sortType: false,
  }),
  methods: {
    onSort() {
      this.sortType = !this.sortType
      this.$emit('input', { sortValue: this.sortValue, sortType: this.sortType })
    },
  },
})
</script>

<style lang="scss" scoped>
.header-cell {
  display: flex;
  align-items: center;
  cursor: pointer;
  &-icon {
    min-width: 8px;
    min-height: 12px;
    margin-left: 6px;
    fill: #73649e;
    &.active {
      fill: white;
    }
  }
}
</style>

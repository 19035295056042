









































import { Component, Vue, Ref } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { PlacementTypeAppModelType } from '@/services/api/PlacementType/PlacementTypeTypes'
import { FormData } from '@/views/affiliate/tools/placement/Placement.vue'
import Validation from '@/utils/Validation'

@Component({})
export default class PlacementCreateForm extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Data
  formData: FormData = {
    name: null,
    url: null,
    type: null,
  }

  rules: {
    name: string
    url: string
    type: string
  } = {
    name: Validation.getRules(['required']),
    url: Validation.getRules(['required']),
    type: Validation.getRules(['required']),
  }

  isValidateForm = false

  // vuex module getter
  @VuexGetter('nPlacements') getPlacementsTypesListItems!: Array<PlacementTypeAppModelType>

  // Methods
  createItem(): void {
    this.form.validate()
    if (this.isValidateForm) {
      this.$emit('createItem', this.formData)
      this.form.reset()
    }
  }
}

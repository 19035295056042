



















import { Component, Vue, Prop, Watch, Emit, Ref } from 'vue-property-decorator'

@Component({})
export default class AppCheckboxesGroup extends Vue {
  // Data
  checkedList = []

  // Props
  @Prop(Array) readonly list!: any

  @Prop(Array) readonly value!: any

  @Prop(Boolean) readonly error!: boolean

  // Whatch / Emit
  @Watch('checkedList')
  @Emit()
  input(): any {
    return this.checkedList
  }

  // Hooks
  mounted() {
    this.checkedList = this.value
  }
}

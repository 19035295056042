













































































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Status1 extends Vue {
  @Prop(Object) readonly publicationItem!: any
}






























































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import ImageSlider from '@/views/business/publication/components/ImageSlider.vue'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: { ImageSlider },
})
export default class PublishStageOne extends Vue {
  // Data
  rulesCheckVal = false

  designCheckVal = false

  // Computed
  get sliderImagesArr() {
    return [1, 2, 3, 4, 5].map((image) => {
      return { previewImg: require(`@/assets/images/publish/app-examples/${image}.png`) }
    })
  }

  get linksToRules(): Array<{ link: string; label: TranslateResult }> {
    return [
      {
        link: 'https://support.google.com/googleplay/android-developer/answer/9878809',
        label: this.$t('pageBusinessPublish.stage1.rulesLinks.childRights'),
      },
      {
        link: 'https://support.google.com/googleplay/android-developer/answer/9878810',
        label: this.$t('pageBusinessPublish.stage1.rulesLinks.content'),
      },
      {
        link: 'https://support.google.com/googleplay/android-developer/answer/9876821',
        label: this.$t('pageBusinessPublish.stage1.rulesLinks.financeService'),
      },
      {
        link: 'https://support.google.com/googleplay/android-developer/answer/9878877',
        label: this.$t('pageBusinessPublish.stage1.rulesLinks.actions'),
      },
      {
        link: 'https://support.google.com/googleplay/android-developer/answer/9877032',
        label: this.$t('pageBusinessPublish.stage1.rulesLinks.gamble'),
      },
      {
        link: 'https://support.google.com/googleplay/android-developer/answer/9876937',
        label: this.$t('pageBusinessPublish.stage1.rulesLinks.userContent'),
      },
      {
        link: 'https://support.google.com/googleplay/android-developer/answer/9878878',
        label: this.$t('pageBusinessPublish.stage1.rulesLinks.substance'),
      },
    ]
  }

  @Emit('continue')
  moveForward(): string {
    return 'Two'
  }
}

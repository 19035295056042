<template>
  <article>
    <page-header extra :title="$route.meta.title" />
    <v-row>
      <v-col cols="5">
        <card class="mt-4 mb-6 mx-4 pa-8">
          <span class="supster-ui__text">{{ $t('SectionUnderConstruction') }}</span>
        </card>
      </v-col>
    </v-row>
  </article>
</template>
<script>
import Vue from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'

export default Vue.extend({
  name: 'EmptyPage',
  components: { PageHeader, Card },
  data: () => ({}),
  computed: {},
  methods: {},
})
</script>




















































import { Component, Ref, Mixins } from 'vue-property-decorator'
import FaqAccordionPanel from '@/views/faq/components/FaqAccordionPanel.vue'
import SectionMixin from '@/views/faq/mixins/SectionMixin'

@Component({
  components: {
    FaqAccordionPanel,
  },
})
export default class SectionGoodsAndServices extends Mixins(SectionMixin) {
  // Refs
  @Ref('chapterZero') readonly chapterZero!: HTMLElement

  @Ref('chapterOne') readonly chapterOne!: HTMLElement

  @Ref('chapterTwo') readonly chapterTwo!: HTMLElement

  @Ref('chapterThree') readonly chapterThree!: HTMLElement

  @Ref('chapterZeroZero') readonly chapterZeroZero!: HTMLElement

  @Ref('chapterZeroOne') readonly chapterZeroOne!: HTMLElement

  @Ref('chapterZeroTwo') readonly chapterZeroTwo!: HTMLElement
}























































































import { Component, Vue, Emit, Ref } from 'vue-property-decorator'
import Validation from '@/utils/Validation'
import { VuexGetter } from '@/store/decorators'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'

import { formDataType } from '../types'

@Component({})
export default class DomainParkingForm extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Data
  formData: formDataType = {
    app: null,
    name: '',
    description: '',
  }

  rules: any = {
    app: Validation.getRules(['required']),
    name: Validation.getRules(['required', 'hostName']),
  }

  // vuex module getter
  @VuexGetter('nAffiliateDomainParking') getOffersItems!: OfferAppModelType[] | []

  @VuexGetter('nAffiliateDomainParking') getCreateDomainsLoadingStatus!: boolean

  // Methods
  formValidate(): void {
    if (!this.form.validate()) {
      return
    }
    this.domainCreate()
  }

  // Emit
  @Emit('domainCreate')
  domainCreate(): formDataType {
    return this.formData
  }
}

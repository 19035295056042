import { cloneDeep } from 'lodash'
import DomainsService from '@/services/api/Domains/DomainsService'
import { DomainAppModelType } from '@/services/api/Domains/DomainsTypes'
import {
  nBusinessDomainParkingMutations,
  nBusinessDomainParkingActions,
  nBusinessDomainParkingGetters,
  nBusinessDomainParkingModule,
} from './types'

const defaultState = {
  domains: {
    loading: false,
    items: [],
    currentItem: null,
    params: DomainsService.getDefaultParams(),
  },

  deletePopUpStatus: false,
  createDomainLoadingStatus: false,
}
const actions: nBusinessDomainParkingActions = {
  async domainsGetItems({ commit, state }): Promise<boolean> {
    commit('domainsChangeLoadingList', true)
    const response = await DomainsService.getItems({
      params: state.domains.params,
    })
    if (response.status) {
      commit('domainsParamsUpdate', response.params)
      commit('domainsItemsUpdate', response.data)
    }
    commit('domainsChangeLoadingList', false)
    return response.status
  },
  async domainsGetItem({ commit }, id): Promise<boolean> {
    const response = await DomainsService.getItem({ id })
    if (response.status) {
      commit('domainsCurrentItemUpdate', response.data)
    }
    return response.status
  },
  async domainsCreateItem({ commit }, data): Promise<void> {
    await DomainsService.createItem({ data, params: {} })
  },
  async domainsDeleteItem({ commit }, id): Promise<void> {
    await DomainsService.deleteItem(id)
  },
}

const mutations: nBusinessDomainParkingMutations = {
  domainsChangeLoadingList(state, status: boolean): void {
    state.domains.loading = status
  },
  domainsItemsUpdate(state, items: Array<DomainAppModelType>): void {
    state.domains.items = items
  },
  domainsCurrentItemUpdate(state, item: DomainAppModelType): void {
    state.domains.currentItem = item
  },
  domainsParamsUpdate(state, params): void {
    state.domains.params = { ...state.domains.params, ...params }
  },
  domainsDeletePopUpChangeStatus(state, status: boolean): void {
    state.deletePopUpStatus = status
  },
  createDomainsLoadingChangeStatus(state, status: boolean): void {
    state.createDomainLoadingStatus = status
  },
}

const getters: nBusinessDomainParkingGetters = {
  getDomainsListLoading(state): boolean {
    return state.domains.loading
  },
  getDomainsItems(state): [] | DomainAppModelType[] {
    return state.domains.items
  },
  getDomainsParams(state): any {
    return state.domains.params
  },
  getDomainsCurrentItem(state): DomainAppModelType {
    return state.domains.currentItem
  },
  getCreateDomainsLoadingStatus(state): boolean {
    return state.createDomainLoadingStatus
  },
  getDomainsDeletePopUpStatus(state): boolean {
    return state.deletePopUpStatus
  },
}

const nBusinessDomainParking: nBusinessDomainParkingModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nBusinessDomainParking




































































































































import { Component, Watch, Ref, Vue, Emit } from 'vue-property-decorator'
import MetricChart from '@/views/business/analytics/components/charts/MetricChart.vue'
import { cloneDeep } from 'lodash'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: { MetricChart },
})
export default class MetricChartContainer extends Vue {
  @Ref('chart') readonly chart!: any

  isChartVisible = false

  filterData = {
    groupBy: 'day',
  }

  dialog = false

  buttons = []

  // Vuex Getters
  @VuexGetter('nBusinessAnalytics') getFilterItems!: any

  @VuexGetter('nBusinessAnalytics') getChartData!: any

  @VuexGetter('nBusinessAnalytics') getDateRange!: any

  // Computed
  get filterSelectedButtons(): any {
    return this.buttons.filter((p: any) => p.chartLineActive)
  }

  get groupByItems(): any {
    return [
      { value: 'day', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.day') },
      { value: 'week', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.week') },
      { value: 'month', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.month') },
      { value: 'app', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.app') },
      { value: 'client', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.client') },
      { value: 'subid1', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.sub1') },
      { value: 'subid2', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.sub2') },
      { value: 'subid3', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.sub3') },
      { value: 'subid4', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.sub4') },
      { value: 'subid5', name: this.$t('pageAnalyticsReports.metricContainer.groupBy.sub5') },
    ]
  }

  // Methods
  async checkBtn(item): Promise<void> {
    this.buttons.map((p: any) => {
      if (item.value === p.value) {
        p.chartLineActive = !p.chartLineActive
      }
      return p
    })
    await this.chart.update()
  }

  resetMetrics(): void {
    this.buttons.forEach((p: any) => {
      p.chartLineActive = false
    })
  }

  // Emit
  @Emit('filter')
  filter() {
    return { ...this.filterData, dateRange: this.getDateRange }
  }

  // Watch
  @Watch('isChartVisible')
  async isChartVisibleHandler(): Promise<void> {
    if (this.chart) {
      await this.chart.update()
    }
  }

  // Hooks
  created(): void {
    this.buttons = cloneDeep(this.getFilterItems)
  }
}

import { RouteConfig } from 'vue-router'
import i18n from '@/plugins/i18n'
import GptPage from '@/views/GptPage.vue'
// import Finances from '@/views/Finances.vue'

import Auth from '@/views/auth/Auth.vue'
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'
import RestorePassword from '@/views/auth/RestorePassword.vue'

const navigation: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: GptPage,
    meta: {
      isRequireAuth: false,
      title: 'Home',
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      isRequireAuth: false,
    },
    component: Auth,
    redirect: { name: 'auth.login' },
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: Login,
        meta: {
          title: i18n.t('authorization'),
        },
      },
      {
        path: 'register',
        name: 'auth.register',
        component: Register,
        meta: {
          title: i18n.t('registration'),
        },
      },
      {
        path: 'restore_password',
        name: 'auth.restorePassword',
        component: RestorePassword,
        meta: {
          title: i18n.t('restorPassword.restor'),
        },
      },
    ],
  },
]

export default navigation

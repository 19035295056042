






























import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter, VuexAction } from '@/store/decorators'
import { ClientVerificationsAppModelType } from '@/services/api/ClientVerifications/ClientVerificationsTypes'
import Status1 from '@/views/business/verification/verificationStatus/components/Status1.vue'
import Status3 from '@/views/business/verification/verificationStatus/components/Status3.vue'

@Component({
  components: {
    Status1,
    Status3,
  },
})
export default class VerificationStatus extends Vue {
  // Data

  isPreloaderShow = false

  // Vuex module action
  @VuexAction('nBusinessVerification') clientVerificationGetItems!: () => void

  // Vuex module getter
  @VuexGetter('nBusinessVerification') getClientVerification!: ClientVerificationsAppModelType

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.clientVerificationGetItems()
    this.isPreloaderShow = false
  }
}

import { getInstanceWithToken } from '@/utils/axios'
import { DeliveryActions } from './types'

const actions: DeliveryActions = {
  async fetchDeliveryServices({ commit }) {
    try {
      const response = await getInstanceWithToken().get(
        `/api/delivery_services?page=1&itemsPerPage=30`
      )
      commit('setDeliveryServices', response.data)
    } catch (error) {
      console.log(error)
    }
  },
}

export default actions

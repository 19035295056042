
















































































import { Component, Vue, Prop, PropSync, Emit, Watch } from 'vue-property-decorator'

@Component({})
export default class AppDialogGallery extends Vue {
  $refs!: {
    videos: HTMLInputElement | any
  }

  dialog = false

  //  Props
  @Prop([Array, Object]) readonly content!: any

  @Prop(Boolean) readonly value!: boolean

  @PropSync('incomingCurrentSlide', { type: Number, default: 0 }) currentSlide!: number

  stopVideos() {
    if (Array.isArray(this.$refs.videos)) {
      for (const htmlVideo of this.$refs.videos) {
        htmlVideo.pause()
      }
    }
  }

  changeCurrentSlide(index) {
    this.stopVideos()
    this.currentSlide = index
  }

  @Watch('value')
  valueHandler(value) {
    // console.log(value)
  }

  // Emit
  @Emit('')
  input() {
    return false
  }
}
























































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Information extends Vue {
  open = false
}



























































































import Vue from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'

import OrdersService from '@/services/api/Orders/OrdersService'
import DeliveryMethodsService from '@/services/api/DeliveryMethods/DeliveryMethodsService'

export default Vue.extend({
  name: 'OrdersConfirm',
  components: { PageHeader, Card },
  data() {
    return {
      EmptyLine: this.$t('noDataAvaiable'),
      page: {
        Order: {
          data: {
            id: 0,
            trackNumber: '',
            site: '',
          },
        },
        DeliveryMethod: {},
      },
    }
  },
  methods: {
    async formSubmit() {
      await OrdersService.updateItem({
        id: this.page.Order.data.id,
        data: {
          trackNumber: this.page.Order.data.trackNumber,
        },
      })
      this.$router.push('/business/goods_and_services/orders')
    },
  },
  async created() {
    const orderResponse = await OrdersService.getItem({
      id: Number(this.$route.params.id),
    })
    this.page.Order = orderResponse

    this.page.DeliveryMethod = await DeliveryMethodsService.getItem({
      id: orderResponse.data.deliveryMethod,
    })
  },
})

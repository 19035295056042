import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'
import { cloneDeep } from 'lodash'
import {
  DefaultStats,
  StatsAffiliateTotalAppModelType,
  StatsAffiliateAppModelType,
} from './StatTypes'

class StatServices extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.stat')

  protected ENDPOINTS: EndpointsTypes

  protected api

  constructor() {
    super()
    this.api = HttpApiCrm
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.STAT)
  }

  private defaultListParams = {}

  private usedListParams = [
    'app_id',
    'date_start',
    'date_end',
    'group_by',
    'offer_id',
    'stream_id',
    'client_id',
    'sub_id_1',
    'sub_id_2',
    'sub_id_3',
    'subid1',
    'subid2',
    'subid3',
    'subid4',
    'subid5',
    'country_id',
    'device',
    'order_by',
    'order',
    'time_zone',
  ]

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public async statsAffilateGetItems<P>({ params }: { params?: P }): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.STAT}/affiliate`
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const result = {
        data: response.data,
      }
      return { status: true, data: result.data }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, error }
    }
  }

  public async statsAnalyticsGetItems<P>({ params }: { params?: P }): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.STAT}/analytics`
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const result = {
        data: response.data,
      }
      return { status: true, data: result.data }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, error }
    }
  }

  public async statsCommonGetItems<P>({ params }: { params?: P }): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.STAT}/common`
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const result = {
        data: response.data,
      }
      return { status: true, data: result.data }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, error }
    }
  }

  public async statsAppGetItems<P>({ params }: { params?: P }): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.STAT}/app`
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const result = {
        data: response.data,
      }
      return { status: true, data: result.data }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, error }
    }
  }

  public async statsAffilateTotalGetItems(): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.STAT}/affiliate/total`
      const response = await this.api.get(requestUrl)
      const result = {
        data: response.data,
      }
      return { status: true, data: result.data }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, error }
    }
  }

  public transformDataForApi(incomingdata) {
    return incomingdata
  }

  public transformDataForApp(incomingdata) {
    return incomingdata
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    if (incomingParams.dateRange && incomingParams.dateRange.length > 0) {
      params.date_start = incomingParams.dateRange[0]
      params.date_end = incomingParams.dateRange[1]
    }
    if (incomingParams.app) {
      params.app_id = incomingParams.app
    }
    if (incomingParams.groupBy) {
      params.group_by =
        typeof incomingParams.groupBy === 'object'
          ? [incomingParams.groupBy.value]
          : incomingParams.groupBy
    }
    if (incomingParams.clientId) {
      params.client_id = incomingParams.clientId
    }
    if (incomingParams.offer) {
      params.offer_id = incomingParams.offer
    }
    if (incomingParams.stream) {
      params.stream_id = incomingParams.stream
    }
    if (incomingParams.subId1) {
      params.sub_id_1 = incomingParams.subId1
    }
    if (incomingParams.subId2) {
      params.sub_id_2 = incomingParams.subId2
    }
    if (incomingParams.subId3) {
      params.sub_id_3 = incomingParams.subId3
    }

    if (incomingParams.timeZone) {
      params.time_zone = incomingParams.timeZone
    }
    if (incomingParams.country) {
      params.country_id = incomingParams.country
    }
    if (incomingParams.device) {
      params.device = incomingParams.device
    }
    if (incomingParams.sortValue) {
      params.order_by = incomingParams.sortValue
    }
    if (typeof incomingParams.sortType === 'boolean') {
      params.order = incomingParams.sortType ? 'desc' : 'asc'
    }
    return params
  }
}

export default new StatServices()

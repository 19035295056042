









































































































































































































import { Component, Ref, Mixins, Prop } from 'vue-property-decorator'
import FaqAccordionPanel from '@/views/faq/components/FaqAccordionPanel.vue'
import SectionMixin from '@/views/faq/mixins/SectionMixin'

@Component({
  components: {
    FaqAccordionPanel,
  },
})
export default class SectionAnalytics extends Mixins(SectionMixin) {
  // Refs
  @Ref('chapterZero') readonly chapterZero!: HTMLElement

  @Ref('chapterOne') readonly chapterOne!: HTMLElement

  @Ref('chapterZeroZero') readonly chapterZeroZero!: HTMLElement

  @Ref('chapterZeroOne') readonly chapterZeroOne!: HTMLElement

  @Ref('chapterZeroTwo') readonly chapterZeroTwo!: HTMLElement

  @Ref('chapterZeroThree') readonly chapterZeroThree!: HTMLElement

  @Ref('chapterZeroFour') readonly chapterZeroFour!: HTMLElement

  @Ref('chapterZeroFive') readonly chapterZeroFive!: HTMLElement

  @Ref('chapterZeroSix') readonly chapterZeroSix!: HTMLElement

  @Ref('chapterZeroSeven') readonly chapterZeroSeven!: HTMLElement

  @Ref('chapterZeroEight') readonly chapterZeroEight!: HTMLElement

  // Props
  @Prop(String) readonly locale!: string
}

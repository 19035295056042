





















































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'

@Component({
  components: {},
})
export default class AnalyticsHeader extends Vue {
  @Prop(String) readonly pageType!: string

  rangeDate: any = []

  openedTab: any = null

  // Vuex Actions
  @VuexAction('nBusinessAnalytics') getAnalyticsCommon!: (...args) => Promise<boolean>

  @VuexAction('nBusinessAnalytics') getAnalyticsDataForTable!: (...args) => Promise<boolean>

  @VuexAction('nBusinessAnalytics') getAnalyticsDataForChart!: (...args) => Promise<boolean>

  @VuexAction('nBusinessAnalytics') getAnalyticsApp!: (...args) => Promise<boolean>

  @VuexMutation('nBusinessAnalytics') appParamsUpdate!: (...args) => Promise<boolean>

  @VuexMutation('nBusinessAnalytics') commonParamsUpdate!: (...args) => Promise<boolean>

  @VuexMutation('nBusinessAnalytics') tableParamsUpdate!: (...args) => Promise<boolean>

  @VuexMutation('nBusinessAnalytics') chartParamsUpdate!: (...args) => Promise<boolean>

  @VuexMutation('nBusinessAnalytics') setDateRange!: (...args) => Promise<boolean>

  // Vuex Getters
  @VuexGetter('nBusinessAnalytics') getAppData!: any

  @VuexGetter('nBusinessAnalytics') getCommonData!: any

  @VuexGetter('nBusinessAnalytics') getDateRange!: any

  @VuexGetter('nApp') getCurrentApp!: any

  @VuexGetter('nApp') getBalance!: any

  @VuexGetter('nApp') getAppCurrencySymbol!: string
  // Computed

  get balanceCurrentApp(): any {
    return this.getBalance
      ? this.getBalance.apps.find((item) => item.app_id === this.getCurrentApp.id)
      : {}
  }

  get appData() {
    return this.getAppData?.total ?? []
  }

  get commonData() {
    return this.getAppData?.total ?? []
  }

  // Methods
  async updateData(): Promise<void> {
    this.setDateRange(this.rangeDate)
    this.commonParamsUpdate({
      app_id: this.getCurrentApp.id,
      dateRange: this.getDateRange,
    })
    this.appParamsUpdate({
      app_id: this.getCurrentApp.id,
      dateRange: this.getDateRange,
      groupBy: {
        value: 'created',
      },
    })
    if (this.pageType === 'reports') {
      this.tableParamsUpdate({
        dateRange: this.getDateRange,
        app_id: this.getCurrentApp.id,
        sortValue: '',
        sortType: null,
      })
      this.chartParamsUpdate({
        dateRange: this.getDateRange,
        app_id: this.getCurrentApp.id,
      })
      this.getAnalyticsDataForTable()
      this.getAnalyticsDataForChart()
    }

    this.getAnalyticsCommon()
    this.getAnalyticsApp()
  }

  created(): void {
    this.rangeDate = this.getDateRange
    this.tableParamsUpdate({
      groupBy: 'day',
      timeZone: 'Europe/Moscow',
    })
    this.chartParamsUpdate({
      groupBy: 'day',
      timeZone: 'Europe/Moscow',
    })
    this.updateData()
  }
}

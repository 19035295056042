import { cloneDeep } from 'lodash'
import StreamService from '@/services/api/Stream/StreamService'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import {
  nAffiliateStreamsMutations,
  nAffiliateStreamsActions,
  nAffiliateStreamsGetters,
  nAffiliateStreamsModule,
} from './types'

const defaultState = {
  streams: {
    forTable: {
      loading: false,
      items: [],
      currentItem: null,
      params: StreamService.getDefaultParams(),
    },
    forFilter: {
      items: [],
      params: StreamService.getDefaultParams(),
    },
  },
  deletePopUpStatus: false,
}
const actions: nAffiliateStreamsActions = {
  async streamsGetItemsForTable({ commit, state }): Promise<boolean> {
    commit('streamsChangeLoadingList', true)
    const response = await StreamService.getItems({
      params: state.streams.forTable.params,
    })
    if (response.status) {
      commit('streamsForTableParamsUpdate', response.params)
      commit('streamsForTableItemsUpdate', response.data)
    }
    commit('streamsChangeLoadingList', false)
    return response.status
  },
  async streamsGetItemsForFilter({ commit, state }): Promise<boolean> {
    const response = await StreamService.getItems({
      params: state.streams.forFilter.params,
    })
    if (response.status) {
      commit('streamsForFilterParamsUpdate', response.params)
      commit('streamsForFilterItemsUpdate', response.data)
    }
    return response.status
  },

  async streamsGetItem({ commit }, id): Promise<boolean> {
    const response = await StreamService.getItem({ id })
    if (response.status) {
      commit('streamsCurrentItemForTableUpdate', response.data)
    }
    return response.status
  },

  async streamsDeleteItem({ commit }, id): Promise<void> {
    await StreamService.deleteItem(id)
  },
}

const mutations: nAffiliateStreamsMutations = {
  streamsChangeLoadingList(state, status: boolean): void {
    state.streams.forTable.loading = status
  },
  streamsForTableItemsUpdate(state, items: Array<StreamAppModelType>): void {
    state.streams.forTable.items = items
  },
  streamsCurrentItemForTableUpdate(state, item: StreamAppModelType): void {
    state.streams.forTable.currentItem = item
  },
  streamsForFilterItemsUpdate(state, items: Array<StreamAppModelType>): void {
    state.streams.forFilter.items = items
  },
  streamsForTableParamsUpdate(state, params): void {
    state.streams.forTable.params = { ...state.streams.forTable.params, ...params }
  },
  streamsForFilterParamsUpdate(state, params): void {
    state.streams.forFilter.params = { ...state.streams.forFilter.params, ...params }
  },
  streamsDeletePopUpChangeStatus(state, status: boolean): void {
    state.deletePopUpStatus = status
  },
}

const getters: nAffiliateStreamsGetters = {
  getStreamsListLoading(state): boolean {
    return state.streams.forTable.loading
  },
  getStreamsForTableItems(state): [] | StreamAppModelType[] {
    return state.streams.forTable.items
  },
  getStreamsCurrentItemForTable(state): StreamAppModelType {
    return state.streams.forTable.currentItem
  },
  getStreamsForFilterItems(state): [] | StreamAppModelType[] {
    return state.streams.forFilter.items
  },
  getStreamsForTableParams(state): unknown {
    return state.streams.forTable.params
  },
  getSteamDeletePopUpStatus(state): boolean {
    return state.deletePopUpStatus
  },
}

const nAffiliateStreams: nAffiliateStreamsModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nAffiliateStreams

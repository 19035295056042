




























































































































































































































































































































































































































































































































import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import { Component, Vue, Ref } from 'vue-property-decorator'
import CountryFlag from '@/components/CountryFlag.vue'
import NotificationProvider from '@/providers/NotificationProvider'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'
import { ОfferSourcesAppModelType } from '@/services/api/ОfferSources/ОfferSourcesTypes'
import { ОfferCategoriesAppModelType } from '@/services/api/ОfferCategories/ОfferCategoriesTypes'
import Status1 from '@/views/business/affiliateMarketing/components/statuses/Status1.vue'
import Status5 from '@/views/business/affiliateMarketing/components/statuses/Status5.vue'
import Status3 from '@/views/business/affiliateMarketing/components/statuses/Status3.vue'
import Status6 from '@/views/business/affiliateMarketing/components/statuses/Status6.vue'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import OfferSlider from './components/OfferSlider.vue'

@Component({
  components: { CountryFlag, OfferSlider, Status1, Status5, Status3, Status6 },
})
export default class AffiliateMarketing extends Vue {
  // Refs
  @Ref('phone') readonly phone!: any

  // Data

  formMode = 'new'

  productRadio = null

  bufferOfferCategory: any = null

  updateLoadingStatus = false

  formValidStatus = false

  errors: Array<string> = []

  moderateStatus: any = 1

  isPreloaderShow = false

  formData: any = {
    app: '',
    title: '',
    goalType: 1,
    productPercent: 0,
    digitalProductPercent: null,
    countries: [],
    sources: [],
    lang: 'ru',
    offerCategory: null,
    aboutCompany: '',
    benefits: '',
    menPercent: 50,
    targetAudience: [],
    workingConditions: '',
    phone: '',
  }

  // Vuex Actions
  @VuexAction('nAffiliateMarketing') offerGetItems!: () => Promise<boolean>

  @VuexAction('nAffiliateMarketing') offerSourcesGetItems!: () => Promise<boolean>

  @VuexAction('nAffiliateMarketing') offerCategoriesGetItems!: () => Promise<boolean>

  @VuexAction('nAffiliateMarketing') offerCreateItem!: (...args) => void

  @VuexAction('nAffiliateMarketing') offerUpdateItem!: (...args) => void

  // Vuex Mutations
  @VuexMutation('nAffiliateMarketing') offerListParamsUpdate!: (...args) => void

  @VuexMutation('nAffiliateMarketing') offerCategoriesListParamsUpdate!: (...args) => void

  // Vuex Getters
  @VuexGetter('nAffiliateMarketing') getOfferListItems!: Array<OfferAppModelType>

  @VuexGetter('nAffiliateMarketing') getOfferSourcesListItems!: Array<ОfferSourcesAppModelType>

  @VuexGetter('nAffiliateMarketing')
  getOfferCategoriesListItems!: Array<ОfferCategoriesAppModelType>

  @VuexGetter('nAffiliateMarketing') getLangs!: Array<{ text: string; value: string }>

  @VuexGetter('nAffiliateMarketing') getTargetAudience!: Array<{ name: string; value: string }>

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Computed
  get checkAmountPercent(): number {
    let sum = 0
    this.formData.targetAudience.forEach((i: any) => {
      sum += Number(i.value)
    })
    return sum
  }

  // Methods
  async fetchOffersCategories(): Promise<void> {
    this.offerCategoriesListParamsUpdate({ 'exists[parent]': false })
    await this.offerCategoriesGetItems()
  }

  async fetchOffers(): Promise<void> {
    this.offerListParamsUpdate({ app: this.getCurrentApp.id })
    await this.offerGetItems()
  }

  setOfferDataForUpdate() {
    const offer = this.getOfferListItems[0]
    this.formData.title = offer.title
    this.formData.goalType = offer.goalType
    this.formData.productPercent = offer.productPercent
    this.formData.digitalProductPercent = offer.digitalProductPercent
    this.formData.countries = offer.countries
    this.formData.sources = offer.sources
    this.formData.lang = offer.lang
    this.bufferOfferCategory =
      this.getOfferCategoriesListItems.find(
        (i) => i.id === this.getOfferListItems[0]?.offerCategory?.parentId
      ) || []
    this.formData.offerCategory = offer.offerCategory
    this.formData.aboutCompany = offer.aboutCompany
    this.formData.benefits = offer.benefits
    this.formData.menPercent = offer.menPercent
    this.formData.targetAudience = offer.targetAudience
    this.formData.workingConditions = offer.workingConditions
    this.formData.phone = offer.phone
  }

  setDefaultData() {
    this.formData.title = this.getCurrentApp.name
    this.formData.app = this.getCurrentApp.id
    this.formData.targetAudience = this.getTargetAudience
  }

  validateForm(): boolean {
    let result = true
    let text = `${this.$t('fillRequiredFields')}:`
    if (!this.formData.digitalProductPercent || !this.formData.productPercent) {
      text += `<div>[2] ${this.$t('pageBusinessOffersNew.step2_title_webmasterReward')};</div>`
      result = false
      this.errors.push('2')
    }
    if (this.formData.countries.length === 0) {
      result = false
      text += `<div>[3] ${this.$t('pageBusinessOffersNew.step3_title_geoActivity')};</div>`
      this.errors.push('3')
    }
    if (
      this.checkAmountPercent > 100 ||
      (this.checkAmountPercent < 100 && this.checkAmountPercent > 0)
    ) {
      result = false
      text += `<div>${this.$t('amount100')}</div>`
      this.errors.push('amount')
    }
    if (this.formData.sources.length === 0) {
      text += `<div>[4] ${this.$t('pageBusinessOffersNew.step4_title_traffic')};</div>`
      result = false
      this.errors.push('4')
    }
    if (this.formData.lang === null) {
      text += `<div>[5] ${this.$t('applicationLanguage ')};</div>`
      result = false
      this.errors.push('5')
    }
    if (!this.bufferOfferCategory || this.formData.offerCategory === null) {
      text += `<div>[6] ${this.$t('pageBusinessOffersNew.step6_title_category')};</div>`
      result = false
      this.errors.push('6')
    }
    if (this.formData.aboutCompany.length === 0) {
      text += `<div>[7] ${this.$t('aboutCompany')};</div>`
      result = false
      this.errors.push('7')
    }
    if (this.formData.benefits.length === 0) {
      text += `<div>[8] ${this.$t('advantages')};</div>`
      result = false
      this.errors.push('8')
    }
    if (this.formData.workingConditions.length === 0) {
      text += `<div>[10] ${this.$t('pageBusinessOffersNew.step10_title_conditions')};</div>`
      result = false
      this.errors.push('10')
    }
    if (!this.phone.validate()) {
      text += `<div>${this.$t('pageBusinessOffersNew.youPhone')};</div>`
      result = false
      this.errors.push('phone')
    }
    if (!result) {
      NotificationProvider.add({
        type: 'error',
        message: text,
      })
    }
    return result
  }

  checkErrors(error: string): boolean {
    return this.errors.includes(error)
  }

  clearErrors(error: string): void {
    if (error === 'phone' && this.phone) {
      this.phone.validate(true)
    }
    this.errors = this.errors.filter((item) => item !== error)
  }

  deleteSelectedCountry(code: string) {
    this.formData.countries = this.formData.countries.filter((item: any) => item.code !== code)
  }

  deleteSelectedSource(id: number) {
    this.formData.sources = this.formData.sources.filter((item: any) => item.id !== id)
  }

  async submitForm(): Promise<void> {
    if (!this.validateForm()) {
      return
    }
    if (this.formMode === 'new') {
      this.isPreloaderShow = true
      await this.offerCreateItem(this.formData)
      await this.fetchOffers()
      this.formMode = 'moderate'
      this.moderateStatus = this.getOfferListItems[0].status
      this.isPreloaderShow = false
    } else {
      this.isPreloaderShow = true
      await this.offerUpdateItem({
        data: { ...this.formData, status: 6 },
        id: this.getOfferListItems[0].id,
      })
      await this.fetchOffers()
      this.moderateStatus = this.getOfferListItems[0].status
      this.formMode = 'moderate'
      this.isPreloaderShow = false
    }
  }

  changeFormModeOnEdit() {
    this.formMode = 'edit'
    this.setOfferDataForUpdate()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    this.setDefaultData()
    await this.fetchOffersCategories()
    await this.fetchOffers()
    if (this.getOfferListItems.length) {
      this.formMode = 'moderate'
      this.moderateStatus = this.getOfferListItems[0].status
    }
    this.isPreloaderShow = false
    this.offerSourcesGetItems()
  }
}

import { getChatInstanceWithToken, getInstanceWithToken } from '@/utils/axios'

import { DialoguesActions } from './types'

const actions: DialoguesActions = {
  // Channels
  loadChannelItems({ commit, dispatch, state, rootState }, id) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const appId = rootState.nApp.apps.currentApp.id
      const requestParams = `?app_id=${appId}&limit=${'150'}&page=${'1'}&type[]=1&type[]=2&type[]=4`
      getInstanceWithToken()
        .get(`/api/app/v1/channels${requestParams}`)
        .then((response) => {
          commit('setGroupChatsListItems', { items: response.data })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  loadChannelItemsByClient({ commit, dispatch, state }, client_id) {
    return new Promise((resolve, reject) => {
      getInstanceWithToken()
        .get(`/api/app/v1/channels/by_client/${client_id}`)
        .then((response) => {
          commit('setChannelListItems', { items: response.data })
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createChannel({ commit }, { client_id, type }) {
    return new Promise((resolve, reject) => {
      getInstanceWithToken()
        .post('/api/app/v1/channels', { client_id, type: 3 })
        .then((response) => {
          commit('dialogUpdateChannel', { channel: response.data })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // Clients
  loadClientItems({ commit, state, rootState }, { page = 1 }) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const appId = rootState.nApp.apps.currentApp.id
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const listParams = state.clients.list.params
    // let requestParams = `?itemsPerPage=${listParams.itemsPerPage}&page=${page}&app_id=${appId}&chatted&order[has_message]=desc`
    let requestParams = `?itemsPerPage=${listParams.itemsPerPage}&app_id=${appId}&order[has_message]=desc&page=${page}`
    if (listParams.has_message) {
      requestParams += '&has_message'
    }
    if (page > listParams.pages) {
      return false
    }
    return new Promise((resolve, reject) => {
      commit('clientListLoad', true)
      getInstanceWithToken()
        .get(`/api/clients${requestParams}`)
        .then((response) => {
          commit('setClientListItems', {
            items: response.data,
            page: parseInt(response.headers['x-pagination-page']),
            pages: parseInt(response.headers['x-pagination-pages']),
          })
          commit('clientListLoad', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('clientListLoad', false)
          reject(error)
        })
    })
  },

  loadChannelMessageItems(
    { commit, dispatch, state },
    { channel_id = 0, page = 1, update = false }
  ) {
    return new Promise((resolve, reject) => {
      commit('dialogMessageLoading', true)
      getChatInstanceWithToken()
        .get(`/api/app/v1/channels/${channel_id}/channel_messages?page=${page}`)
        .then((response) => {
          if (update) {
            commit('dialogUpdateMessages', {
              messages: response.data,
              page,
            })
          }
          commit('dialogMessageLoading', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('dialogMessageLoading', false)
          reject(error)
        })
    })
  },
  removeMessage({ commit, dispatch, state }, message_id) {
    return new Promise((resolve, reject) => {
      getChatInstanceWithToken()
        .delete(`/api/app/v1/channel_messages/${message_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createMessage(
    { commit, dispatch, state },
    { channel_id = '', message = '', name = '', media_objects = null }
  ) {
    return new Promise((resolve, reject) => {
      const requestParams = {
        channel_id,
        body: message,
        name,
        media_objects,
      }
      getInstanceWithToken()
        .post(`/api/app/v1/channel_messages`, requestParams)
        .then((response) => {
          // commit('setClientListItems', { items: response.data })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default actions

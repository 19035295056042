






















































































































































































































































































































































































































































import { Component, Ref, Mixins, Prop } from 'vue-property-decorator'
import FaqAccordionPanel from '@/views/faq/components/FaqAccordionPanel.vue'
import SectionMixin from '@/views/faq/mixins/SectionMixin'

@Component({
  components: {
    FaqAccordionPanel,
  },
})
export default class SectionAffiliateProgram extends Mixins(SectionMixin) {
  // Refs
  @Ref('chapterZero') readonly chapterZero!: HTMLElement

  @Ref('chapterOne') readonly chapterOne!: HTMLElement

  @Ref('chapterTwo') readonly chapterTwo!: HTMLElement

  @Ref('chapterThree') readonly chapterThree!: HTMLElement

  @Ref('chapterFour') readonly chapterFour!: HTMLElement

  @Ref('chapterFive') readonly chapterFive!: HTMLElement

  @Ref('chapterOneZero') readonly chapterOneZero!: HTMLElement

  @Ref('chapterOneOne') readonly chapterOneOne!: HTMLElement

  @Ref('chapterTwoZero') readonly chapterTwoZero!: HTMLElement

  @Ref('chapterThreeZero') readonly chapterThreeZero!: HTMLElement

  @Ref('chapterThreeOne') readonly chapterThreeOne!: HTMLElement

  @Ref('chapterFourZero') readonly chapterFourZero!: HTMLElement

  @Ref('chapterFourOne') readonly chapterFourOne!: HTMLElement

  @Ref('chapterFourTwo') readonly chapterFourTwo!: HTMLElement

  @Ref('chapterFiveZero') readonly chapterFiveZero!: HTMLElement

  @Ref('chapterFiveOne') readonly chapterFiveOne!: HTMLElement

  @Ref('chapterFiveTwo') readonly chapterFiveTwo!: HTMLElement

  // Props
  @Prop(String) readonly locale!: string
}


import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import { Line } from 'vue-chartjs'
import moment from 'moment'
import i18n from '@/plugins/i18n'

@Component({
  extends: Line,
  components: {},
})
export default class MetricChart extends Vue {
  renderChart: any

  chartData: any = {
    labels: null,
    datasets: [
      {
        label: i18n.t('salesPerDay'),
        data: [],
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)'],
        borderWidth: 3,
      },
    ],
  }

  options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  @Prop(Array) readonly pickedButtons!: any

  @Prop(Array) readonly passedData!: any

  // Methods
  setlabels() {
    this.chartData.labels = this.passedData
      .map((p) => {
        if (p.created) {
          return moment(p.created).format('DD.MM.YY')
        }
        if (p.month) {
          return moment(p.month).format('MM.YYYY')
        }
        return `${moment(p.firstDayOfWeek).format('DD.MM.YY')} - ${moment(p.lastDayOfWeek).format(
          'DD.MM.YY'
        )} `
      })
      .reverse()
  }

  async update() {
    this.setlabels()
    this.chartData.datasets = []
    const firstVals = this.pickedButtons
      .map((p) => this.passedData.map((z) => z[p.value])[0])
      .sort((a, b) => b - a)
      .filter((p) => p)

    // const isNeededPercentage = firstVals[0] / firstVals[firstVals.length - 1] > 10
    const isNeededPercentage = false

    for (const p of this.pickedButtons) {
      const data = isNeededPercentage
        ? await this.aggregateDataArrayToPercent(this.passedData.map((z) => z[p.value]))
        : this.passedData.map((z) => z[p.value]).reverse()
      this.chartData.datasets.push({
        label: p.label,
        data,
        borderColor: p.color,
        borderWidth: 2,
        fill: false,
      })
    }

    this.renderChart(this.chartData, this.options)
  }

  async aggregateDataArrayToPercent(data) {
    const max = Math.max(...data)
    return data.map((p) => {
      return Math.floor((p / max) * 100)
    })
  }

  // Watch
  @Watch('passedData')
  async passedDataHandler(): Promise<void> {
    await this.update()
  }

  @Watch('pickedButtons')
  async pickedButtonsHandler(): Promise<void> {
    await this.update()
  }

  // Hooks
  mounted(): void {
    this.setlabels()
    this.update()
  }
}

/* eslint-disable camelcase */
// import i18n from '@/plugins/i18n'
// import OrdersApi from '@/services/Orders'
import { getInstanceWithToken } from '@/utils/axios'
import OrdersService from '@/services/api/Orders/OrdersService'
// import { OrdersActions, OrdersLoadTypes, SendPostTypes } from '@/store/Orders/types'
import { OrdersActions } from '@/store/modules/orders/types'
import moment from 'moment'

const actions: OrdersActions = {
  async loadOrders({ commit }, { params = {} }) {
    const response = await OrdersService.getItems({ params })
    if (response.status) {
      commit('setOrders', {
        items: response.data,
        page: response.params.page,
        pages: response.params.pages,
      })
    }
    return response
  },
  async updateOrder({ dispatch }, { id = 0, data = {}, params = {} }) {
    const response = await OrdersService.updateItem({ id: data.id, data })
    return response
  },
}

export default actions

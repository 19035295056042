import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'
import { cloneDeep } from 'lodash'
import { ArticlesAppModelType } from './ArticlesTypes'

class ArticlesService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.articles')

  protected ENDPOINTS: EndpointsTypes

  protected api

  constructor() {
    super()
    this.api = HttpApiCrm
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.ARTICLES)
  }

  private defaultListParams = {
    itemsPerPage: 5,
    page: 1,
  }

  private usedListParams = ['itemsPerPage', 'page']

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData): ArticlesAppModelType[] {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)

    return params
  }
}
export default new ArticlesService()

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'

class DeliveryRegionsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.deliveryRegions')

  protected ENDPOINTS: EndpointsTypes

  protected api

  constructor() {
    super()
    this.api = HttpApiCrm
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.DELIVERY_REGIONS)
  }

  public transformDataForApi(data) {
    return data
  }

  public transformDataForApp(data) {
    return data
  }

  public transformRequestParams(params: unknown): unknown {
    return params
  }
}
export default new DeliveryRegionsService()

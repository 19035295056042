
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AppPhoneInput extends Vue {
  @Prop({ default: 'ground' }) overlayColor!: string

  @Prop({ default: '1' }) overlayOpacity!: string | number

  @Prop({ default: '4' }) width!: string | number

  @Prop({ default: 'primary' }) spinerColor!: string

  @Prop({ default: '60' }) spinerSize!: string | number

  @Prop({ default: false }) readonly isPreloaderShow!: boolean

  @Prop({ default: false }) readonly absolute!: boolean
}

<template>
  <div class="supster-ui--range__input" v-bind:style="{ height }">
    <v-slider
      v-model="inputVal"
      :min="min"
      :max="max"
      track-color="#DCD6E7"
      thumb-label="always"
      hide-details
    >
      <template v-slot:thumb-label>
        <div>{{ hint }}</div>
      </template>
    </v-slider>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'InputRange',
  data: () => ({}),
  props: {
    value: {
      default: '',
    },
    label: {
      default: '',
      type: String,
    },
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: 0,
      type: Number,
    },
    hint: {
      default: '',
      type: String,
    },
    height: {
      default: 'auto',
      type: String,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {},
})
</script>
<style lang="scss">
.supster-ui--range__input {
  .v-slider__thumb {
    width: 16px;
    height: 16px;
    border-radius: 4px;

    &:hover,
    &:focus,
    &:active {
      &:before {
        transform: translate(-50%, -50%) scale(1) !important;
        transition-delay: 0s;
      }
    }
  }

  .v-slider__thumb:before {
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    background: url('data:image/svg+xml,%3Csvg width="6" height="6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 0h1v1H0V0zM5 0h1v1H5V0zM0 5h1v1H0V5zM5 5h1v1H5V5z" fill="%23fff"/%3E%3C/svg%3E')
      center;
    background-size: cover;
    border-radius: 0 !important;
    transform: translate(-50%, -50%);
    opacity: 1 !important;
  }

  .v-slider__thumb::after {
    top: 100%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: auto;
    margin-top: 8px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    transform: translateX(-52%);
    content: var(--content);
  }
}
</style>

import { OrdersModule } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const Orders: OrdersModule = {
  state: () => ({
    orders: {
      list: {
        items: [],
        params: {
          itemsPerPage: 30,
          page: 1,
          pages: 1,
          created: [],
        },
      },
    },
    page: 1,
    isLoaded: false,
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default Orders

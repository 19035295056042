








































































































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'

@Component({
  components: {},
})
export default class InfoContent extends Vue {
  @VuexGetter('nChats', 'getDialog') dialog!: any

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-data-table',{staticClass:"subscription-managment-table",attrs:{"pages":_vm.getSubscriptionListParams.pages,"currentPage":_vm.getSubscriptionListParams.page,"items":_vm.getSubscriptionListItems,"headers":_vm.tableHeaders,"loading":_vm.getSubscriptionListLoading},on:{"input":_vm.tableChangePage},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.setDateFormatForTable(item.created, 'DD MMMM YYYY')))]),_c('div',{staticClass:"subscription-managment-table__time"},[_vm._v(" "+_vm._s(_vm.setDateFormatForTable(item.created, 'HH:mm'))+" ")])]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.id)+" ")]}},{key:"item.tariff",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tariff.amount)+" "+_vm._s(_vm.getAppCurrencySymbol)+"/ "+_vm._s(_vm.$t(("pageBusinessDigital.tariffNew." + (item.tariff.period))))+" ")]}},{key:"item.lastPaymentDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.setDateFormatForTable(item.lastPaymentDate, 'DD MMMM YYYY')))]),_c('div',{staticClass:"subscription-managment-table__time"},[_vm._v(" "+_vm._s(_vm.setDateFormatForTable(item.lastPaymentDate, 'HH:mm'))+" ")])]}},{key:"item.next_payment_date",fn:function(ref){
var item = ref.item;
return [(item.next_payment_date)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.setDateFormatForTable(item.next_payment_date, 'DD MMMM YYYY')))]),_c('div',{staticClass:"subscription-managment-table__time"},[_vm._v(" "+_vm._s(_vm.setDateFormatForTable(item.next_payment_date, 'HH:mm'))+" ")])]):_c('div',[_vm._v("-")])]}},{key:"item.paidTo",fn:function(ref){
var item = ref.item;
return [(item.paidToDate)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.setDateFormatForTable(item.paidToDate, 'DD MMMM YYYY')))]),_c('div',{staticClass:"subscription-managment-table__time"},[_vm._v(" "+_vm._s(_vm.setDateFormatForTable(item.paidToDate, 'HH:mm'))+" ")])]):_c('div',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.renewMode === 0)?[_vm._v(" "+_vm._s(_vm.$t("subscriptionManagment.status.3"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t(("subscriptionManagment.status." + (item.status))))+" ")]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"actions align-center"},[_c('v-col',{staticClass:"text-center",on:{"click":function($event){return _vm.openPopUpDetails(item.id)}}},[_c('icon',{attrs:{"data":require("@/assets/icons/sorting-icon.svg")}})],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }






















import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AffiliateTools',
})
export default class AffiliateTools extends Vue {}

import { LocaleMessages } from 'vue-i18n'

class LocalizationServise {
  defaultLocale = 'en'

  locales = ['en', 'ru']

  queryParam = 'lang'

  public initFallbackLocale(): string {
    return this.getDefaultLocale()
  }

  public initLocale(): string {
    const sources: Array<string | boolean> = []

    sources.push(this.getQueryParamLocale())
    sources.push(this.getLocalStorageLocale())
    sources.push(this.getBrowserLocale())
    sources.push(this.getConfigLocale())
    sources.push(this.getDefaultLocale())

    const locale = this.prioritizeLocale(sources)

    this.setLocalStorageLocale(locale)

    return locale
  }

  public changeLocale(value: string): string {
    const locale = this.isAvialbleLocale(value)
    if (typeof locale === 'string') {
      this.setLocalStorageLocale(locale)
      return locale
    }
    return this.initLocale()
  }

  public loadLocaleMessages(): LocaleMessages {
    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages: LocaleMessages = {}
    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
    return messages
  }

  private getQueryParamLocale(): string | boolean {
    const queryParams = new URL(window.location.href).searchParams
    const locale = queryParams.get(this.queryParam)
    return this.isAvialbleLocale(locale)
  }

  private getLocalStorageLocale(): string | boolean {
    const locale = localStorage.getItem('locale')
    return this.isAvialbleLocale(locale)
  }

  private getBrowserLocale(): string | boolean {
    const locale = window.navigator.language
    return this.isAvialbleLocale(locale)
  }

  private getConfigLocale(): string | boolean {
    const locale = process.env.VUE_APP_I18N_FALLBACK_LOCALE
    return this.isAvialbleLocale(locale)
  }

  private getDefaultLocale(): string {
    const locale = this.defaultLocale
    return locale
  }

  private prioritizeLocale(findedLocales: Array<string | boolean>): string {
    const result = findedLocales.filter((l) => l)[0]
    return typeof result === 'string' ? result : ''
  }

  private isAvialbleLocale(locale: string | null | undefined): string | boolean {
    if (typeof locale === 'string') {
      const lowerLocale = locale.toLowerCase()
      return this.locales.includes(lowerLocale) ? lowerLocale : false
    }
    return false
  }

  private setLocalStorageLocale(value: string): void {
    localStorage.setItem('locale', value)
  }
}
export default new LocalizationServise()

<template>
  <article>
    <page-header extra :title="$t('ordres.title')">
      <v-row>
        <v-col cols="8" class="d-flex align-center">
          <button
            class="com-page-header__button mx-6"
            @click="reloadList({ paymentStatus: 2 })"
            v-bind:class="{ active: tableDataFilter.payment_status === 2 }"
          >
            {{ $t('ordres.paid') }}
          </button>
          <button
            class="com-page-header__button"
            @click="reloadList({ paymentStatus: 1 })"
            v-bind:class="{ active: tableDataFilter.payment_status === 1 }"
          >
            {{ $t('ordres.pending') }}
          </button>
        </v-col>
        <v-col cols="4" class="d-flex justify-end align-center">
          <v-btn
            v-if="csv.length"
            min-width="134"
            :download="`${orders.list.params.app.name} orders for ${tableDataFilter.created[0]} — ${tableDataFilter.created[1]}.csv`"
            color="primary lighten-1"
            elevation="0"
            :href="csv"
            >{{ $t('download') }}</v-btn
          >
          <v-btn
            v-else
            min-width="134"
            :loading="isCsvLoading"
            @click="getCSV"
            color="primary lighten-1"
            elevation="0"
            >{{ $t('exportToCSV') }}</v-btn
          >
        </v-col>
      </v-row>
    </page-header>
    <card class="mt-4 mb-6 mx-4 pa-8 px-0 py-0">
      <div class="px-6 pt-6">
        <v-row class="mx-n3 align-center pb-6 my-0">
          <v-col class="py-0 px-3" cols="2">
            <app-v-select
              v-model="tableDataFilter.filter.type"
              :items="typeList"
              itemText="name"
              itemValue="id"
              :placeholder="$t('chooseFilter')"
              @change="changeFilterType"
              :label="$t('filtr')"
            ></app-v-select>
          </v-col>
          <v-col class="py-0 px-3" cols="2">
            <app-v-text-field
              v-model="tableDataFilter.filter.value"
              @change="loadDataTable"
              clearable
              :label="tableDataFilter.filter.type.name"
            ></app-v-text-field>
          </v-col>
          <v-col class="py-0 px-3" cols="6">
            <app-date-picker
              @change="loadDataTable"
              v-model="tableDataFilter.created"
              type="range"
              filterOut
              :label="$t('pageAffiliateStats.filterLabel.range')"
              :clearable="false"
            />
          </v-col>
        </v-row>
      </div>

      <v-row class="mt-0">
        <v-col>
          <app-data-table
            ref="dataTable"
            :headers="tableHeaders"
            :items="orders.list.items"
            :pages="orders.list.params.pages"
            :itemsPerPage="orders.list.params.itemsPerPage"
            @input="changePage"
          >
            <template v-slot:[`item.created`]="{ item }">
              <div class="text-xsmall bp-orders__table__date-cell">
                <span>
                  {{ item.created | moment('DD/MM/YYYY') }}
                </span>
                <span class="bp-orders__table__date-cell-time">
                  {{ item.created | moment('HH:mm') }}
                </span>
              </div>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <span class="bp-orders__table__number-cell font-weight-bold">
                {{ item.id }}
              </span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <span>{{ $t(`pageBusinessOrders.orderTypes.${item.type}`) }}</span>
            </template>
            <template v-slot:[`item.trackNumber`]="{ item }">
              <span @click="openPopup(item)">
                <span class="d-flex align-center" v-if="item.type === 1">
                  <icon
                    class="bp-orders__track-edit-icon mr-2"
                    data="@/assets/icons/bp-features/orders/track-number-edit.svg"
                  />
                  <span v-if="item.trackNumber">{{ item.trackNumber }}</span>
                  <span v-else class="text-accent-green">{{ $t('add') }}</span>
                </span>
              </span>
            </template>
            <template v-slot:[`item.product`]="{ item }">
              <span>{{ $t(`pageBusinessOrders.productTypes.${item.type}`) }}</span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span> {{ item.price }}Р </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span>{{
                tableDataFilter.payment_status === 1
                  ? $t(`pageBusinessOrders.orderStatuses.${item.status}`)
                  : $t(`pageBusinessOrders.paymentStatuses.${item.payment_status}`)
              }}</span>
            </template>
          </app-data-table>
        </v-col>
      </v-row>
    </card>
    <popup-form no-activator ref="popupTrack">
      <template #header>
        <h2>{{ $t('ordres.addTrackNumber') }}</h2>
      </template>
      <template #content>
        <v-row>
          <v-col cols="6">
            <div class="bp-verify-input-container">
              <app-v-select
                :items="deliveryServices"
                v-model="editedOrder.delivery_service"
                return-object
                clearable
                :label="$t('ordres.deliveryCompany')"
                :attach="false"
              />
            </div>
          </v-col>
          <v-col cols="6">
            <div>
              <app-v-text-field
                :rules="rules.trackNumber"
                v-model="editedOrder.trackNumber"
                :label="$t('trackNumber')"
              />
            </div>
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <v-btn @click="updateTrackNumber" color="#271460" class="popup-track__confirm mt-9">
          {{ $t('add') }}
        </v-btn>
      </template>
    </popup-form>
  </article>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Validation from '@/utils/Validation'
import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'
import PopupForm from '@/components/PopupForm.vue'
import CSVService from '@/services/api/CSV/CSVService'
import moment from 'moment'

import NotificationProvider from '@/providers/NotificationProvider'

export default Vue.extend({
  name: 'Orders',
  components: { PageHeader, Card, PopupForm },
  data() {
    return {
      tableDataFilter: {
        status: 1,
        payment_status: 1,
        filter: {
          type: {
            type: 'trackNumber',
            name: this.$t('trackNumber'),
          },
          value: '',
        },
        created: [],
      },
      rules: {
        trackNumber: Validation.getRules(['noSymbols']),
      },
      editedOrder: null,
      csv: '',
      isCsvLoading: false,
    }
  },
  computed: {
    ...mapGetters('nApp', ['getCurrentApp']),
    ...mapGetters('orders', {
      orders: 'getOrders',
    }),
    ...mapGetters('delivery', {
      getDeliveryServices: 'getDeliveryServices',
    }),
    deliveryServices() {
      return this.getDeliveryServices || []
    },
    typeList() {
      return [
        {
          type: 'trackNumber',
          name: this.$t('trackNumber'),
        },
        {
          type: 'client',
          name: this.$t('clientID'),
        },
        {
          type: 'app',
          name: this.$t('appID'),
        },
      ]
    },
    tableHeaders() {
      return [
        { text: this.$t('date'), value: 'created', sortable: false },
        { text: this.$t('orderNumder'), value: 'id', sortable: false },
        { text: this.$t('orderType'), value: 'type', sortable: false },
        { text: this.$t('product.type'), value: 'product', sortable: false },
        { text: this.$t('clientID'), value: 'client.id', sortable: false },
        { text: this.$t('trackNumber'), value: 'trackNumber', sortable: false, width: '200px' },
        { text: this.$t('orderDetails'), value: 'note', sortable: false, width: '20%' },
        { text: this.$t('price'), value: 'sum', sortable: false },
        {
          text:
            this.tableDataFilter.payment_status === 1
              ? this.$t('orderStatus')
              : this.$t('paymentStatus'),
          value: 'status',
        },
      ]
    },
  },
  methods: {
    ...mapActions('orders', ['loadOrders', 'updateOrder']),
    ...mapActions('delivery', ['fetchDeliveryServices']),
    ...mapMutations('orders', ['changeOrdersListParams', 'ordersListChangePage']),

    openPopup(order) {
      this.editedOrder = order
      this.$refs.popupTrack.openPopup()
    },

    async getCSV() {
      this.isCsvLoading = true
      const responce = await CSVService.getCSVData({
        type: 'orders',
        params: {
          ...this.tableDataFilter,
          [this.tableDataFilter.filter.type.type]: this.tableDataFilter.filter.value,
        },
      })
      if (responce.status) {
        this.csv = responce.data
      } else {
        NotificationProvider.add({
          type: 'error',
          title: this.$t('allertMessage.periodError'),
          message: this.$t('allertMessage.periodLength'),
        })
      }

      this.isCsvLoading = false
    },

    reloadList({ paymentStatus = 1 }) {
      this.tableDataFilter.payment_status = paymentStatus

      this.loadDataTable()
    },
    updateTrackNumber() {
      this.updateOrder({ data: this.editedOrder })
      this.editedOrder = null
      this.$refs.popupTrack.closePopup()
    },
    changeFilterType() {
      this.tableDataFilter.filter.value = ''
      this.loadDataTable()
    },
    async changePage(value) {
      this.ordersListChangePage(value)
      await this.loadOrders({ params: this.orders.list.params })
    },
    async loadDataTable() {
      const filter = {
        page: 1,
        app: this.getCurrentApp,
        payment_status: 1,
        created: this.tableDataFilter.created,
        client: '',
        trackNumber: '',
      }

      this.csv = ''
      filter[this.tableDataFilter.filter.type.type] = this.tableDataFilter.filter.value
      if (Number(this.tableDataFilter.payment_status) === 2) {
        filter.payment_status = 2
      }
      if (this.$refs.dataTable) {
        this.$refs.dataTable.resetPagination()
      }

      this.changeOrdersListParams(filter)
      await this.loadOrders({ params: this.orders.list.params })
    },
  },
  created() {
    this.tableDataFilter.created = [
      moment().subtract(13, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ]
    this.loadDataTable()
    this.fetchDeliveryServices()
  },
})
</script>

<style lang="scss">
.popup-track__confirm {
  width: 100px;
  height: 40px;
  padding: 16px;
  border-radius: 8px;

  > span {
    color: white;
  }
}
</style>

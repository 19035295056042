



















import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import Links from './components/Links.vue'
import StatTable from './components/StatTable.vue'
import Info from './components/Info.vue'

@Component({
  components: { Links, StatTable, Info },
})
export default class BusinessReferral extends Vue {
  @VuexAction('nBusinessReferral') referralGetStats!: () => Promise<boolean>

  @VuexAction('nBusinessReferral') referralGetPageStats!: () => Promise<boolean>

  created(): void {
    this.referralGetStats()
    this.referralGetPageStats()
  }
}

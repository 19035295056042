



















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { VuexGetter, VuexMutation, VuexAction } from '@/store/decorators'
import { TariffApiModelType } from '@/services/api/Tariff/TariffTypes'
import { TranslateResult } from 'vue-i18n'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import { priceForTariff } from '../../../settings'

@Component({})
export default class Step2 extends Vue {
  // Data
  formData: any = {
    trialGlobalUnlock: false,
    trialPromoPageIsEnable: false,
    tariffs: [],
    isFixedTariffChanges: false,
  }

  // vuex module mutation
  @VuexAction('nTariffs') sendTrialRequest!: () => Promise<void>

  @VuexAction('nTariffs') trialToogle!: () => Promise<void>

  // vuex module mutation
  @VuexMutation('nTariffs') setFormData!: (...args) => void

  // vuex module getter
  @VuexGetter('nTariffs') getFormData!: any

  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  // Computed
  get tableHeaders(): Array<{
    value: string
    text: TranslateResult
    class?: string
  }> {
    return [
      { value: 'name', text: this.$t('pageBusinessOrders.orderTypes.2'), class: 'pl-3' },
      { value: 'amount', text: `${this.$t('price')}, ${this.getAppCurrencySymbol}` },
      { value: 'period', text: this.$t('frequency') },
      { value: 'duration', text: this.$t('duration') },
    ]
  }

  get isTrialOnModeration(): boolean {
    return this.formData.trialGlobalUnlock || this.getCurrentApp.trialPeriodPermission === 1
  }

  get tariffsDemo(): TariffApiModelType[] {
    return [
      {
        type: 1,
        name: this.$t('pageBusinessDigital.tariffNew.2'),
        amount: this.getDefaultPrice(2),
        period: 2,
        duration: 1,
      },
      {
        type: 1,
        name: this.$t('pageBusinessDigital.tariffNew.3'),
        amount: this.getDefaultPrice(3),
        period: 3,
        duration: 1,
      },
      {
        type: 1,
        name: this.$t('pageBusinessDigital.tariffNew.5'),
        amount: this.getDefaultPrice(5),
        period: 5,
        duration: 1,
      },
    ]
  }

  // Method

  getDefaultPrice(period: number): number {
    return priceForTariff[this.getCurrentApp.type][period][this.getAppCurrencySymbol].defaultPrice
  }

  enableTrial(): void {
    if (this.getPromoPageItem) {
      this.formData.trialPromoPageIsEnable = !this.formData.trialPromoPageIsEnable
      this.setFormData(this.formData)
      this.trialToogle()
    } else {
      this.formData.trialPromoPageIsEnable = !this.formData.trialPromoPageIsEnable
    }
  }

  // Watch
  @Watch('formData', { deep: true })
  formDataHandler(): void {
    this.setFormData(this.formData)
  }

  // Hooks
  mounted(): void {
    this.formData.tariffs = this.getFormData?.tariffs ?? this.tariffsDemo
    if (this.formData.tariffs[0].trial_period || this.formData.tariffs[0].trial_amount) {
      this.formData.trialPromoPageIsEnable = true
    }
  }
}










































import { Component, Vue, Prop, Emit, Ref, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class AppVDialog extends Vue {
  // Refs
  @Ref('content') readonly content!: any

  @Ref('popup') readonly popup!: any

  @Ref('scroll') readonly scroll!: any

  @Ref('slotContent') readonly slotContent!: any

  // Data

  // Props
  @Prop(Boolean) readonly value!: boolean

  @Prop({ default: 'max-content', type: String }) readonly width!: string

  @Prop({ default: 'max-content', type: String }) readonly maxWidth!: string

  @Prop({ default: true, type: Boolean }) readonly iconClose!: boolean

  @Prop(String) readonly contentClass!: string

  // Data
  dialog = false

  isScroll = false

  slotContentHeight = 0

  scrollHeight = 0

  contentHeight = 0

  popupHeight = 0

  resize(): void {
    this.popupHeight = this.popup?.$refs.dialog?.clientHeight
    this.scrollHeight = this.scroll?.clientHeight

    this.contentHeight = this.content?.$el?.clientHeight
    this.slotContentHeight = this.slotContent?.clientHeight

    if (this.contentHeight > this.popupHeight) {
      this.isScroll = true
      return
    }
    if (this.slotContentHeight === this.scrollHeight) {
      this.isScroll = false
    }
  }

  handleResize(): void {
    this.resize()
  }

  // Emit
  @Emit('close')
  close(): void {
    this.dialog = false
  }
}

import { Action, Module, MutationAction, VuexModule } from 'vuex-module-decorators'
import { getToken, setToken, unsetToken } from '@/utils/token'
import { getUser, getUserClient, setChatToken, setUser, unsetUser } from '@/utils/user'
import { User } from '@/services/types'
import { login, LoginRequest } from '@/services/auth'
import {
  createUser,
  CreateUserRequest,
  fetchUser,
  updateUser,
  updateUserPassword,
  UpdateUserPasswordRequest,
  UpdateUserRequest,
} from '@/services/user'
import { getChatInstanceWithToken, getInstance } from '@/utils/axios'
import WebsocketService from '@/services/WebsocketService'

@Module({ namespaced: true, name: 'auth' })
export default class Auth extends VuexModule {
  accessToken = getToken()

  user = getUser()

  client = null

  get getAccessToken(): string | null {
    return this.accessToken
  }

  get getUser(): User | null {
    return this.user
  }

  get getUserId(): User | null {
    return this.user?.id
  }

  get getUserClient() {
    return this.client
  }

  @MutationAction({ mutate: ['accessToken', 'user'] })
  async login(payload: LoginRequest) {
    const response = await login(payload)
    setToken(response.token.accessToken)
    setUser(response.user)
    setChatToken(response.client_access_token)
    return {
      accessToken: response.token.accessToken,
      user: response.user,
    }
  }

  @MutationAction({ mutate: ['accessToken'] })
  async authorize(authData) {
    setToken(authData.access_token)
    setChatToken(authData.client_access_token)
    return {
      accessToken: authData.accessToken,
    }
  }

  @MutationAction({ mutate: ['user'] })
  async getUserMe() {
    const userResponse = await getChatInstanceWithToken().get('/api/users/me')
    setUser(userResponse.data)
    return {
      user: userResponse.data,
    }
  }

  @MutationAction({ mutate: ['user'] })
  async register(payload: CreateUserRequest) {
    await createUser(payload)
    const response = await login({ email: payload.email, password: payload.password })
    return {
      user: response.user,
    }
  }

  @MutationAction({ mutate: ['accessToken', 'user'] })
  async logout() {
    WebsocketService.destroy()
    unsetToken()
    unsetUser()
    return {
      accessToken: null,
      user: null,
    }
  }

  @Action
  async restorePassword(params) {
    let result
    if (params.method === 'confirm_tokens') {
      const { data } = await getInstance().post(`/api/confirm_tokens`, params.data)
      result = data
    }
    if (params.method === 'check_secret') {
      const { data } = await getInstance().post(
        `/api/confirm_tokens/${params.data.id}/check_secret`,
        {
          secret_key: params.data.secret_key,
          secret: params.data.secret,
        }
      )
      result = data
    }
    return { token: 123 }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @MutationAction({ mutate: ['accessToken', 'user'] })
  async fetchIfAuthenticated() {
    const user = getUser()
    const token = getToken()

    if (!user || !token) {
      return false
    }

    try {
      const response = await fetchUser({ id: user.id })

      setUser(response)
      return {
        user: response,
      }
    } catch (error) {
      unsetToken()
      unsetUser()

      return {
        accessToken: null,
        user: null,
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @MutationAction({ mutate: ['user'] })
  async updateUser(payload: UpdateUserRequest) {
    if (!this.user) {
      return false
    }

    const user = await updateUser({ ...payload, id: this.user.id })

    setUser(user)
    return {
      user,
    }
  }

  @Action
  async updateUserPassword(payload: UpdateUserPasswordRequest): Promise<void> {
    if (!this.user) {
      return
    }

    await updateUserPassword({ ...payload, id: this.user.id })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @MutationAction({ mutate: ['client'] })
  async fetchClient() {
    const id = await getUserClient()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return { client: parseInt(id) }
  }
}

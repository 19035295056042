


































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {},
})
export default class ClientsHeader extends Vue {
  @VuexGetter('nChats') getCountChatsNotReadMessages!: number

  @VuexGetter('nChats') getClientsListLoading!: boolean

  @VuexGetter('nChats') getAllChatsCount!: any[]
}

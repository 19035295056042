



























































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { filterDataType } from '../types'

@Component({})
export default class TransactionsFilter extends Vue {
  // Data
  filterData: filterDataType = {
    status: null,
    id: null,
    client: null,
    errorCode: null,
    created: [],
  }

  // vuex module getter
  @VuexGetter('nBusinessTransactions') getTransactionsStatuses!: boolean

  @VuexGetter('nBusinessTransactions') getTransactionsListParams!: any

  // Methods
  setSavedFilterData(): void {
    const keysFilterData = Object.keys(this.filterData)
    keysFilterData.forEach((i) => {
      if (this.getTransactionsListParams[i]) {
        this.filterData[i] = this.getTransactionsListParams[i]
      }
    })
  }

  filterHandler(): void {
    setTimeout(() => {
      this.filter()
    }, 100)
  }

  // Hooks
  created(): void {
    this.setSavedFilterData()
  }

  // Emit
  @Emit()
  filter(): filterDataType {
    return this.filterData
  }
}

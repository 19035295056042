




















































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { VuexAction, VuexGetter } from '@/store/decorators'
import { UserAppModelType } from '@/services/api/User/UserTypes'
import MediaObjectsService from '@/services/api/MediaObjects/MediaObjectsService'
import NotificationProvider from '@/providers/NotificationProvider'
import { ResponseTypes } from '@/services/api/BaseApiService'

@Component({})
export default class Profile extends Vue {
  @Ref('avatar') readonly avatar!: HTMLInputElement

  formData: {
    name: string
    email: string
    telegram: string
    phone: string
    avatar: any
    personalChatMode: null | number
  } = {
    name: '',
    email: '',
    telegram: '',
    phone: '',
    avatar: null,
    personalChatMode: null,
  }

  @VuexGetter('nApp') getAuthUser!: UserAppModelType

  // Vuex module action
  @VuexAction('nProfile') updateUser!: (...args) => Promise<ResponseTypes>

  get avatarUrl(): string {
    return this.formData?.avatar?.blob ?? this.formData?.avatar?.content_url ?? null
  }

  get disabledBtn() {
    return (
      this.formData.telegram && this.formData.email && this.formData.phone && this.formData.name
    )
  }

  setNewAvatar(e): void {
    this.formData.avatar = {
      file: e.target.files[0],
      blob: URL.createObjectURL(e.target.files[0]),
    }
  }

  async saveData(): Promise<void> {
    if (this.formData.avatar?.file) {
      const { data } = await MediaObjectsService.createItem({
        params: {},
        data: this.formData.avatar.file,
      })
      this.formData.avatar = `/api/media_objects/${data?.id}`
    } else if (this.formData.avatar !== null) {
      delete this.formData.avatar
    }
    const response = await this.updateUser({
      id: this.getAuthUser.id,
      data: this.formData,
      notification: {
        enableError: false,
        enableSuccess: true,
        successText: this.$t('allertMessage.changesSaved'),
      },
    })
    if (!response.status) {
      if (response.error.response.data.detail.includes('phone')) {
        NotificationProvider.add({
          type: 'error',
          message: this.$t('allertMessage.phoneAlreadyUse'),
        })
      }
      if (response.error.response.data.detail.includes('email')) {
        NotificationProvider.add({
          type: 'error',
          message: this.$t('allertMessage.emailAlreadyUse'),
        })
      }
      if (response.error.response.data.detail.includes('telegram')) {
        NotificationProvider.add({
          type: 'error',
          message: this.$t('allertMessage.telegramRules'),
        })
      }
    }
  }

  created(): void {
    Object.keys(this.formData).forEach((i) => {
      this.formData[i] = this.getAuthUser[i]
    })
  }
}

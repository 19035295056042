























import { Component, Vue, Emit } from 'vue-property-decorator'

@Component({})
export default class PayoutTypes extends Vue {
  // Computed
  get paymentTypes(): any[] {
    return [
      {
        title: 'RUB',
        subTitle: this.$t('requisites.tabs.1.subtitle'),
        type: 1,
      },
      // {
      //   title: 'USD',
      //   subTitle: this.$t('requisites.tabs.2.subtitle'),
      //   type: 2,
      // },
      // {
      //   title: this.$t('requisites.tabs.3.title'),
      //   subTitle: this.$t('requisites.tabs.3.subtitle'),
      //   type: 3,
      // },
      {
        title: this.$t('requisites.tabs.4.title'),
        subTitle: this.$t('requisites.tabs.4.subtitle'),
        type: 4,
      },
    ]
  }

  // Emits
  @Emit()
  input(value: number): number | null {
    return value
  }
}

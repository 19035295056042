
































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { TariffAppModelType } from '@/services/api/Tariff/TariffTypes'

@Component({})
export default class TariffsTable extends Vue {
  // Data
  selectedItemId = 0

  money: any

  // Computed
  get tableHeaders(): any {
    return [
      { text: '№', value: 'id' },
      { text: this.$t('price'), value: 'amount' },
      { text: this.$t('frequency'), value: 'period' },
      { text: this.$t('duration'), value: 'duration' },
      { text: this.$t('promoPage'), value: 'promoPage' },
      {
        text: this.$t('pageAffiliateStats.header.activeSubscribes'),
        value: 'active',
        class: 'text-end',
      },
      { text: this.$t('totalIncome'), value: 'sum', class: 'text-end' },
      { text: '', value: '' },
      { text: '', value: 'actions', sortable: false, width: '50px' },
    ]
  }

  // vuex module getter
  @VuexGetter('nTariffs') getTariffsItems!: TariffAppModelType[]

  @VuexGetter('nTariffs') getTariffsLoading!: boolean

  @VuexGetter('nTariffs') getTariffLoadingItem!: boolean

  @VuexGetter('nTariffs') getTariffsParams!: any

  @VuexGetter('nApp') getAppCurrencySymbol!: any

  // Emit
  @Emit('openTariffPopUpEdit')
  openTariffPopUpEdit(id: number): number {
    this.selectedItemId = id
    return id
  }

  @Emit('tableChangePage')
  tableChangePage(page: number): number {
    return page
  }
}

<template>
  <article class="page--services-list">
    <page-header extra>
      <v-row class="align-center">
        <v-col cols="5">
          <h4 class="d-inline-flex align-center">{{ $t('services.myServices') }}</h4>
        </v-col>
        <v-col cols="7">
          <div class="d-flex justify-end">
            <v-btn
              class="ml-8"
              color="accent"
              :to="{ name: 'Business_GoodsAndServices.services.create' }"
            >
              {{ $t('services.addService') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </page-header>
    <card class="mt-4 mb-6 mx-4 pa-8 px-0 py-0">
      <div class="px-6 pt-6">
        <v-row class="mx-n3 align-center pb-6 my-0">
          <v-col class="py-0 px-3" cols="3">
            <app-select-tree
              v-model="dataTable.filter.serviceCategories"
              :items="dataTable.lists.serviceCategories"
              :itemText="'name'"
              :itemValue="'id'"
              :placeholder="$t('selectCategory')"
              :search="true"
              treeProp="subCategories"
              @input="loadDataTable"
              :label="$t('categories')"
            />
          </v-col>
          <v-col class="py-0 px-3" cols="3">
            <app-v-text-field
              v-model="dataTable.filter.name"
              @change="loadDataTable"
              :label="$t('searchByName')"
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
          <v-col class="py-0 pt-7 px-3" cols="6">
            <div class="d-flex justify-end product-radio-group">
              <app-v-radio-group
                v-model="dataTable.filter.productsOnlyStore"
                @change="loadDataTable"
                row
              >
                <app-v-radio :value="false" :label="$t('services.showAll')" />
                <app-v-radio :value="true" :label="$t('services.showOnlyInStore')" />
              </app-v-radio-group>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="">
        <data-table
          ref="dataTable"
          :headers="dataTable.headers"
          :items="productsList.items"
          :itemsPerPage="productsList.params.itemsPerPage"
          :length="productsList.params.pages"
          @input="changePage"
        >
          <template v-slot:[`header.name`]>
            <div class="pl-2 d-inline-block">
              {{ $t('products.productPhoto.1') }}<br />{{ $t('products.productPhoto.2') }}
            </div>
          </template>
          <template #[`header.status`]>
            {{ $t('status') }}
          </template>

          <template #[`header.oldPrice`]>
            <span> {{ $t('products.oldPrice') }} </span>
          </template>
          <template #[`header.discount`]>
            <span> {{ $t('discount') }} </span>
          </template>
          <template #[`header.price`]>
            <span>{{ $t('products.discountPrice') }} </span>
          </template>
          <template #[`header.commission`]>
            <span>{{ $t('products.comision') }} </span>
          </template>
          <template #[`header.uploadToStore`]>
            <span>{{ $t('products.downloadInStore') }} </span>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex py-4 align-center pl-2">
              <div
                v-if="item.mainImage"
                class="product-item-prop-image mr-3"
                :style="{
                  'background-image': `url(${
                    item.preview_url ? item.preview_url : item.mainImage.content_url
                  })`,
                }"
              ></div>
              <span class="product-item-prop-name">{{ item.name }}</span>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ productstatusNames[item.status] }}
          </template>
          <template v-slot:[`item.oldPrice`]="{ item }">
            <div class="d-flex align-center">
              <app-data-table-action-dropdown divider>
                <template #activator>
                  <icon
                    class="mr-2 accent--text"
                    data="@/assets/icons/pen.svg"
                    @click="showPriceForm(item)"
                  />
                </template>
                <template #content>
                  <div class="px-4">
                    <div class="pt-4">
                      <app-v-text-field
                        v-model="dataTable.forms.productPricesEdit.data.oldPrice"
                        dense
                        :label="$t('products.oldPrice')"
                        type="number"
                        :error="checkPropError('oldPrice')"
                      />
                    </div>
                    <div class="pt-4">
                      <app-v-text-field
                        v-model="dataTable.forms.productPricesEdit.data.price"
                        dense
                        :label="$t('products.discountPrice')"
                        type="number"
                        :error="checkPropError('price')"
                      />
                    </div>
                    <div class="mt-1">
                      <v-btn
                        class="mt-3 mb-4"
                        color="accent"
                        small
                        width="100%"
                        @click="editProductPrices(item)"
                        :loading="dataTable.forms.productPricesEdit.loaded"
                      >
                        {{ $t('save') }}
                      </v-btn>
                    </div>
                  </div>
                </template>
              </app-data-table-action-dropdown>
              {{ item.oldPrice ? `${item.oldPrice} ₽` : '—' }}
            </div>
          </template>
          <template v-slot:[`item.discount`]="{ item }"
            >{{ findDiscount(item.oldPrice, item.price) }}%
          </template>
          <template v-slot:[`item.price`]="{ item }">
            {{ item.price ? `${item.price} ₽` : '—' }}
          </template>
          <template v-slot:[`item.commission`]="{ item }">
            10% <br />
            {{ ((10 / 100) * item.price).toFixed(2) }} ₽
          </template>
          <template v-slot:[`item.uploadToStore`]="{ item }">
            <div class="d-flex">
              <toggle
                class="mx-2"
                v-model="item.status"
                :false-value="2"
                :true-value="1"
                @change="updateProductStatus(item)"
              />
              {{ item.status === 1 ? $t('yes') : $t('no') }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="com-dropdown-action__wrapper">
              <app-data-table-action-dropdown
                left
                :nudgeLeft="-9"
                backgroundColor="#271460"
                dividerColor="#493B74"
                divider
              >
                <template #activator>
                  <icon
                    class="com-dropdown-action__icon"
                    data="@/assets/icons/dots-vertical.svg"
                    @click="showActionsList(item.id)"
                    :color="dataTable.forms.actions === item.id ? 'var(--accent-green)' : 'primary'"
                    v-click-outside="{
                      handler: () => {
                        dataTable.forms.actions = false
                      },
                    }"
                  />
                </template>
                <template #content>
                  <v-btn
                    class="com-dropdown-action__btn justify-start font-weight-regular"
                    block
                    @click="$router.push(`services/${item.id}`)"
                    color="primary"
                    tile
                    >{{ $t('services.serviceEdit') }}</v-btn
                  >
                  <v-btn
                    class="com-dropdown-action__btn justify-start font-weight-regular"
                    block
                    @click="removeProduct(item.id)"
                    color="primary"
                    tile
                    >{{ $t('services.deteteService') }}</v-btn
                  >
                </template>
              </app-data-table-action-dropdown>
            </div>
          </template>
        </data-table>
      </div>
    </card>
  </article>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'
import DataTable from '@/components/DataTable.vue'
import AppSelectTree from '@/components/app/AppSelectTree.vue'
import Validation from '@/utils/Validation'

export default Vue.extend({
  name: 'ServicesList',
  components: { PageHeader, Card, DataTable, AppSelectTree },
  data: () => ({
    dataTable: {
      headers: [
        { value: 'name', width: '30%', sortable: false },
        { value: 'status', width: '160px', sortable: false },
        { value: 'oldPrice', width: 'auto', sortable: false },
        { value: 'discount', width: 'auto', sortable: false },
        { value: 'price', width: 'auto', sortable: false },
        { value: 'commission', width: 'auto', sortable: false },
        { value: 'uploadToStore', width: '120px', sortable: false },
        { value: 'actions', width: '48px', align: 'center', sortable: false },
      ],
      filter: {
        serviceCategories: null,
        name: '',
        productsOnlyStore: false,
      },
      lists: {
        serviceCategories: [],
        stores: [],
      },
      forms: {
        actions: false,
        productPricesEdit: {
          open: false,
          loaded: false,
          data: {
            price: 0,
            oldPrice: 0,
          },
        },
        productRestShow: false,
        newProductStore: {
          data: null,
          loaded: false,
        },
        validateErrors: {},
        showErrors: false,
      },
    },
  }),
  computed: {
    ...mapGetters('app', { app: 'getSelectedApp' }),
    ...mapGetters('products', {
      productsList: 'getProductsList',
    }),
    productstatusNames() {
      return {
        0: this.$t('removed'),
        1: this.$t('uploadInStore'),
        2: this.$t('createdBy'),
      }
    },
  },
  methods: {
    ...mapActions('products', [
      'loadProducts',
      'loadProduct',
      'updateProduct',
      'deleteProduct',
      'createProductStores',
      'updateProductStores',
      'loadServiceCategories',
    ]),
    ...mapActions('stores', ['loadStores']),
    ...mapMutations('products', [
      'productsListChangePage',
      'productsListChangeParams',
      'updateProductListParams',
    ]),
    ...mapMutations('snackbar', ['callSnackbar']),
    checkPropError(propName) {
      return (
        propName in this.dataTable.forms.validateErrors &&
        !this.dataTable.forms.validateErrors[propName] &&
        this.dataTable.forms.showErrors
      )
    },
    async changePage(value) {
      this.productsListChangePage(value)
      await this.loadProducts({ params: this.productsList.params })
    },
    async removeProduct(productId) {
      this.dataTable.forms.actions = false
      await this.deleteProduct({ id: productId })
      await this.loadProducts({ params: this.productsList.params })
    },
    async updateProductStatus(item) {
      const data = {
        id: item.id,
        status: item.status,
      }
      this.updateProduct({ data })
    },
    async loadDataTable() {
      const filter = {
        page: 1,
        name: this.dataTable.filter.name,
        status: false,
        serviceCategory: null,
        type: 2,
      }
      const activeCategory = this.dataTable.filter.serviceCategories
      if (activeCategory) {
        filter.serviceCategory = activeCategory.id
      }
      if (this.dataTable.filter.productsOnlyStore) {
        filter.status = 1
      }
      this.$refs.dataTable.resetPagination()
      this.productsListChangeParams(filter)
      await this.loadProducts({ params: this.productsList.params })
    },
    async editProductPrices(productItem) {
      const errors = {}
      const productIndex = this.productsList.items.findIndex((item) => item.id === productItem.id)
      const maxPrice = 100500
      const newPrices = {
        price: String(this.dataTable.forms.productPricesEdit.data.price),
        oldPrice: String(this.dataTable.forms.productPricesEdit.data.oldPrice),
      }
      if (newPrices.oldPrice.length && Number(newPrices.price) >= Number(newPrices.oldPrice)) {
        errors.price =
          !newPrices.oldPrice.length && Number(newPrices.price) <= Number(newPrices.oldPrice)
        this.dataTable.forms.validateErrors = errors
        this.dataTable.forms.showErrors = true
        this.callSnackbar({
          color: 'red',
          text: this.$t('allertMessage.priceValidation.1'),
        })
        return
      }

      if (
        (newPrices.oldPrice.length && Number(newPrices.oldPrice) > maxPrice) ||
        (newPrices.price.length && Number(newPrices.price) > maxPrice)
      ) {
        errors.price = newPrices.price.length && Number(newPrices.price) < maxPrice
        errors.oldPrice = newPrices.oldPrice.length && Number(newPrices.oldPrice) < maxPrice

        this.dataTable.forms.validateErrors = errors
        this.dataTable.forms.showErrors = true
        this.callSnackbar({
          color: 'red',
          text: `${this.$t('allertMessage.priceValidation.2')} ${maxPrice}`,
        })
        return
      }
      if (newPrices.oldPrice.length === 0 && newPrices.price.length === 0) {
        errors.price = false
        this.dataTable.forms.validateErrors = errors
        this.dataTable.forms.showErrors = true
        this.callSnackbar({
          color: 'red',
          text: this.$t('allertMessage.fieldRequired'),
        })
        return
      }
      if (newPrices.oldPrice.length === 0 && newPrices.price === '0') {
        errors.price = false
        this.dataTable.forms.validateErrors = errors
        this.dataTable.forms.showErrors = true
        this.callSnackbar({
          color: 'red',
          text: this.$t('allertMessage.priceValidation.3'),
        })
        return
      }
      this.dataTable.forms.showErrors = false
      this.dataTable.forms.productPricesEdit.loaded = true

      const data = {
        id: productItem.id,
        price: newPrices.price,
        oldPrice: newPrices.oldPrice,
      }

      if (data.oldPrice.length === 0) {
        data.oldPrice = '0'
      }
      await this.updateProduct({ data })

      this.dataTable.forms.productPricesEdit.loaded = false
      this.dataTable.forms.productPricesEdit.open = false
      this.productsList.items[productIndex].price = newPrices.price
      this.productsList.items[productIndex].oldPrice = newPrices.oldPrice
      this.callSnackbar({ color: 'green', text: this.$t('allertMessage.priceUpdateSuccess.3') })
    },
    showStoreList(itemId) {
      setTimeout(() => {
        this.dataTable.forms.newProductStore.data = null
        this.dataTable.forms.productRestShow = itemId
      }, 40)
    },
    showPriceForm(item) {
      setTimeout(() => {
        this.dataTable.forms.productPricesEdit.data.price = item.price
        this.dataTable.forms.productPricesEdit.data.oldPrice = item.oldPrice
        this.dataTable.forms.productPricesEdit.open = item.id
      }, 40)
    },
    showActionsList(itemId) {
      setTimeout(() => {
        this.dataTable.forms.actions = itemId
      }, 40)
    },
    showActionList(itemId) {
      this.dataTable.itemAction = itemId
    },
    checkProductRestCount(stores) {
      const restCount = stores.reduce((a, b) => {
        return Number(a) + Number(b.rest)
      }, 0)

      return restCount
    },
    findDiscount(oldPrice, price) {
      if (price && oldPrice > 0) {
        return (100 - (price / oldPrice) * 100).toFixed(0)
      }
      return 0
    },
  },
  async created() {
    this.productsListChangeParams({
      app: this.app.id,
      status: false,
      type: 2,
      productCategory: null,
    })

    await this.loadProducts({ params: this.productsList.params })

    const stores = await this.loadStores({ params: { app: this.app.id } })
    this.dataTable.lists.stores = stores.status ? stores.data : []
    const serviceCategories = await this.loadServiceCategories({ params: { itemsPerPage: 1000 } })
    this.dataTable.lists.serviceCategories = (
      serviceCategories.status ? serviceCategories.data : []
    ).filter((item) => item.subCategories.length > 0)
  },
})
</script>
<style lang="scss">
.com-dropdown-action {
  &__wrapper {
    padding: 14px 0px 14px 16px;
    box-shadow: inset 1px 0px 0px #dcd6e7;
  }
  &__btn {
    box-shadow: inset 0px -1px 0px #493b74 !important;
  }
}

.page--services-list {
  .product-item-prop-image {
    display: block;
    width: 44px;
    min-width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid var(--light-gray);
    border-radius: 2px;
    box-shadow: 0 0px 1px 1px white inset;
  }
  .product-item-prop-name {
    font-weight: 600;
    word-break: break-all;
  }
  .product-radio-group {
    .v-radio.v-item--active .v-label {
      font-weight: normal !important;
    }
  }
  .v-data-table__wrapper {
    overflow: inherit;
  }
}
</style>





















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'AppStatisticsInfoCard',
})
export default class AppStatisticsInfoCard extends Vue {
  @Prop(Object) readonly icon!: unknown

  @Prop({ default: 44 }) readonly iconWidth!: string | number

  @Prop({ default: 44 }) readonly iconHeight!: string | number

  @Prop({ default: '24px 24px 24px 24px' }) readonly padding!: string | number

  @Prop({ default: 'var(--accent-green)' }) readonly iconColor!: string

  @Prop([String, Number]) readonly title!: string | number

  @Prop([String]) readonly subTitle!: string

  @Prop([String, Number]) readonly badgeContent!: string | number
}

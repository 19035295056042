import md5 from 'md5'
import { User } from '@/services/types'
import { getChatInstanceWithToken, getInstanceWithToken } from '@/utils/axios'

const USER_KEY = 'USER'

export const getUser = () => {
  const user = localStorage.getItem(USER_KEY)

  try {
    return user ? JSON.parse(user) : null
  } catch (e) {
    return null
  }
}

export function setUser(user: User) {
  localStorage.setItem(USER_KEY, JSON.stringify(user))
  getChatInstanceWithToken()
    .get('/api/users/me')
    .then((res) => {
      localStorage.setItem('SOCKET_KEY', md5(`${res.data.client.id}`))
      localStorage.setItem('CLIENT', res.data.client.id)
    })
}

export function setChatToken(token: string) {
  localStorage.setItem('CHAT_TOKEN', token)
}

export const getChatToken = () => {
  return localStorage.getItem('CHAT_TOKEN')
}

export function getUserClient() {
  const client = localStorage.getItem('CLIENT')
  return new Promise((resolve, reject) => {
    if (client) {
      resolve(client)
    } else {
      getInstanceWithToken()
        .get('/api/users/me')
        .then((res) => {
          localStorage.setItem('SOCKET_KEY', md5(`${res.data.client.id}`))
          localStorage.setItem('CLIENT', res.data.client.id)
          resolve(md5(`${res.data.client.id}`))
        })
        .catch((err) => {
          reject(err)
        })
    }
  })
}

export const unsetUser = () => {
  localStorage.removeItem(USER_KEY)
  localStorage.removeItem('SOCKET_KEY')
  localStorage.removeItem('CHAT_TOKEN')
  localStorage.removeItem('CLIENT')
  localStorage.removeItem('GLOBAL_POST_BACK')
}

export const getSocketKey = () => {
  const key = localStorage.getItem('SOCKET_KEY')
  return new Promise((resolve, reject) => {
    if (key) {
      resolve(key)
    } else {
      getInstanceWithToken()
        .get('/api/users/me')
        .then((res) => {
          localStorage.setItem('SOCKET_KEY', md5(`${res.data.client.id}`))
          localStorage.setItem('CLIENT', res.data.client.id)
          resolve(md5(`${res.data.client.id}`))
        })
        .catch((err) => {
          reject(err)
        })
    }
  })
}
































































import { Component, Vue, Emit, Ref, Watch } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { PlacementTypeAppModelType } from '@/services/api/PlacementType/PlacementTypeTypes'
import { PlacementAppModelType } from '@/services/api/Placement/PlacementTypes'
import { FormData } from '@/views/affiliate/tools/placement/Placement.vue'
import Validation from '@/utils/Validation'

@Component({})
export default class PlacementPopUpEdit extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Data
  formData: FormData = {
    name: null,
    url: null,
    type: null,
  }

  rules: {
    name: string
    url: string
    type: string
  } = {
    name: Validation.getRules(['required']),
    url: Validation.getRules(['required']),
    type: Validation.getRules(['required']),
  }

  isValidateForm = false

  // vuex module getter
  @VuexGetter('nPlacements') getPlacementsTypesListItems!: Array<PlacementTypeAppModelType>

  @VuexGetter('nPlacements') getPlacementsListItem!: PlacementAppModelType

  @VuexGetter('nPlacements') getPopUpEditStatus!: boolean

  // Methods
  editItem(): void {
    this.form.validate()
    if (this.isValidateForm) {
      this.$emit('editItem', this.formData)
      this.form.reset()
    }
  }

  // Emit
  @Emit('closePopUpEdit')
  closePopUpEdit(): boolean {
    return false
  }

  // Watch
  @Watch('getPlacementsListItem', { deep: true })
  setFormData(): void {
    this.formData.name = this.getPlacementsListItem.name
    this.formData.url = this.getPlacementsListItem.url
    this.formData.type = this.getPlacementsListItem.type.id
  }
}

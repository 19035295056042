

























import { Component, Vue, Prop } from 'vue-property-decorator'
import { VuexGetter, VuexAction } from '@/store/decorators'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import PostPreview from '@/components/PostPreview.vue'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { ResponseTypes } from '@/services/api/BaseApiService'

@Component({
  components: { PostPreview },
})
export default class PostItem extends Vue {
  // Props
  @Prop(Object) readonly post!: PostsAppModelType

  // Data
  loading = false

  error = false

  // vuex module action
  @VuexAction('nTariffs') postUpdateItem!: (...args) => Promise<ResponseTypes>

  // vuex module getter
  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Computed
  get checked(): boolean {
    return this.post.promotionalPage?.id === this.getPromoPageItem?.id
  }

  get disabled(): boolean | null {
    return this.post.promotionalPage && this.post.promotionalPage?.id !== this.getPromoPageItem?.id
  }

  // Methods
  async pickPostHandler(): Promise<void> {
    this.loading = true
    const { status } = await this.postUpdateItem({
      id: this.post.id,
      data: {
        promotionalPage: this.post.promotionalPage
          ? null
          : `api/promotional_pages/${this.getPromoPageItem?.id}`,
      },
    })
    if (!status) {
      this.error = true
      setTimeout(() => {
        this.error = false
      }, 2000)
    }
    this.loading = false
  }
}

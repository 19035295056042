<template>
  <div class="auth-container px-8 pb-8 pt-9" :class="[mini && 'mini', light && 'light']">
    <div class="auth-container__header mx-sm-auto px-0 px-md-10">
      <slot name="header"></slot>
    </div>
    <div class="auth-container__content mx-sm-auto px-0 px-md-10">
      <slot></slot>
    </div>
    <div class="auth-container__footer pt-2 px-0 px-md-10">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AuthContainer',
  props: {
    mini: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.auth-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // height: 100%;
  // min-height: 100vh;
  background-color: var(--v-primary-base);
  background-image: url('../../../assets/images/backgrounds/S.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;

  &.light {
    background-color: var(--v-purple-base);
    background-image: unset;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__content {
    width: 100%;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &.mini {
    @media screen and (min-width: 0px) {
      .auth-container__header,
      .auth-container__content,
      .auth-container__footer {
        max-width: 400px;
      }
    }
  }
}
</style>

<template>
  <div class="page-part-component--products-form">
    <v-row class="mt-2">
      <v-col cols="12" class="py-2" v-if="form.step.current === 0 && mode === 'create'">
        <card class="mt-0 mb-0 mx-4 px-10 py-10" :style="{ background: '#FBFAFC' }">
          <h2 class="supster-ui--form__title">{{ $t('product.chooseAddMethod') }}</h2>
          <v-row class="align-center">
            <v-col cols="5">
              <div class="supster-ui--info-item__wrapper _accent">
                <div class="supster-ui--info-item__info">
                  <h3 class="supster-ui--info-item__title">
                    {{ $t('product.newItem') }}
                    <i class="supster-ui--badge__block _accent ml-1">?</i>
                  </h3>
                  <p class="supster-ui--info-item__description">{{ $t('product.createNew') }}</p>
                </div>
                <div class="supster-ui--info-item__actions">
                  <v-btn color="accent" @click="nextStep" fab>
                    <icon :data="require('@/assets/icons/arrow-right.svg')" />
                  </v-btn>
                </div>
              </div>
              <div class="supster-ui--info-item__wrapper _accent">
                <div class="supster-ui--info-item__info">
                  <h3 class="supster-ui--info-item__title">
                    API
                    <i class="supster-ui--badge__block _accent ml-1">?</i>
                  </h3>
                  <p class="supster-ui--info-item__description">
                    {{ $t('product.uploadCreatedProduct') }}
                  </p>
                </div>
                <div class="supster-ui--info-item__actions">
                  <v-btn color="accent" fab>
                    <icon :data="require('@/assets/icons/arrow-right.svg')" />
                  </v-btn>
                </div>
              </div>
              <div class="supster-ui--info-item__wrapper _accent">
                <div class="supster-ui--info-item__info">
                  <h3 class="supster-ui--info-item__title">
                    {{ $t('import') }}
                    <i class="supster-ui--badge__block _accent ml-1">?</i>
                  </h3>
                  <p class="supster-ui--info-item__description">{{ $t('uploadFromTable') }}</p>
                </div>
                <div class="supster-ui--info-item__actions">
                  <v-btn color="accent" fab>
                    <icon :data="require('@/assets/icons/arrow-right.svg')" />
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="4" offset="1">
              <div class="d-flex ml-5 mt-2">
                <div><i class="supster-ui--badge__block _v2 mr-4">i</i></div>
                <div>
                  <p class="supster-ui__text font-weight-regular mt-0">
                    {{ $t('product.help.1') }}
                  </p>
                  <v-btn x-small color="accent">{{
                    $t('pageBusinessPublish.stage3.read_btn')
                  }}</v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </card>
      </v-col>
      <v-col cols="12" class="py-2" v-if="form.step.current > 0 || mode === 'edit'">
        <card class="mt-0 mb-0 mx-4 px-0 py-10">
          <h2 class="supster-ui--form__title px-10">{{ $t('product.nameAndCategory') }}</h2>
          <v-row class="flex-column px-10">
            <div class="supster-ui--input__wrapper col col-6">
              <app-v-text-field v-model="form.data.name" :error="checkPropError('name')">
                <template #label>
                  <span class="app-label-dot">
                    {{ $t('product.name') }}
                    <span class="help-circle help-circle--large ml-1">?</span>
                  </span>
                </template>
              </app-v-text-field>
            </div>
            <div class="supster-ui--input__wrapper col col-6">
              <app-v-select
                v-model="form.extraData.productCategory.level1"
                @change="selectCategory(1, $event)"
                :items="lists.productCategories"
                :error="checkPropError('productCategory')"
                :placeholder="$t('chooseFromSelect')"
                prepend-inner-icon="mdi-magnify"
              >
                <template #label>
                  <span class="app-label-dot"> {{ $t('product.type') }} </span>
                </template>
              </app-v-select>
            </div>
            <div
              class="supster-ui--input__wrapper col col-6"
              v-if="
                Object.keys(form.extraData.productCategory.level1).length &&
                form.extraData.productCategory.level1.subCategories.length
              "
            >
              <app-v-select
                v-model="form.extraData.productCategory.level2"
                @change="selectCategory(2, $event)"
                :items="form.extraData.productCategory.level1.subCategories"
                :error="checkPropError('productCategory')"
                :placeholder="$t('chooseFromSelect')"
                prepend-inner-icon="mdi-magnify"
              >
                <template #label>
                  <span class="app-label-dot">{{ $t('product.caterogy') }}</span>
                </template>
              </app-v-select>
            </div>
            <div
              class="supster-ui--input__wrapper col col-6"
              v-if="
                Object.keys(form.extraData.productCategory.level2).length &&
                form.extraData.productCategory.level2.subCategories.length
              "
            >
              <app-v-select
                v-model="form.extraData.productCategory.level3"
                @change="selectCategory(3, $event)"
                :items="form.extraData.productCategory.level2.subCategories"
                :error="checkPropError('productCategory')"
                :placeholder="$t('chooseFromSelect')"
                prepend-inner-icon="mdi-magnify"
              >
                <template #label>
                  <span class="app-label-dot"> {{ $t('product.subCategory') }} </span>
                </template>
              </app-v-select>
            </div>
          </v-row>
          <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" />
          <h2 class="supster-ui--form__title px-10">{{ $t('product.mainData') }}</h2>
          <v-row class="flex-column px-10">
            <div class="supster-ui--input__wrapper col col-6">
              <app-autocomplete-countries
                v-model="form.data.country"
                :error="checkPropError('country')"
              >
                <template #label>
                  <span class="app-label-dot">{{ $t('product.country') }}</span>
                </template>
              </app-autocomplete-countries>
            </div>
            <v-row class="mx-0">
              <v-col cols="6" class="pa-0">
                <div class="supster-ui--input__wrapper col col-12">
                  <app-v-autocomplete-load-data
                    v-model="form.data.brand"
                    :loadData="searchBrand"
                    :error="checkPropError('brand')"
                  >
                    <template #label>
                      <span class="app-label-dot">{{ $t('product.brand') }}</span>
                    </template>
                  </app-v-autocomplete-load-data>
                </div>
              </v-col>
              <!-- <v-col cols="5">
                <input-checkbox
                  class="mt-8"
                  label="Без бренда"
                  v-model="form.extraData.noBrand"
                  @input="removeBrand"
                />
              </v-col> -->
            </v-row>
            <div class="supster-ui--input__wrapper col col-6">
              <label
                class="supster-ui--input__label _required ml-2"
                :class="{ 'text--error': checkPropError('tnvedCode') }"
                >{{ $t('product.TNVD') }}</label
              >
              <!-- <app-v-autocomplete-load-data
                v-model="form.data.tnvedCode"
                :items="lists.productTnveds"
                :itemText="(item) => `${item.code} - ${item.fullName}`"
                :itemValue="'id'"
                :error="checkPropError('tnvedCode')"
                :load-data="searchTnvedsHandler"
              /> -->
              <input-select
                v-model="form.data.tnvedCode"
                :items="lists.productTnveds"
                :itemText="'name'"
                :itemExtendedText="(item) => `${item.code} - ${item.fullName}`"
                :itemValue="'id'"
                :search="true"
                :dropIcon="false"
                :placeholder="$t('searchText')"
                @searchHandler="searchProductTnvedsHandler"
                :error="checkPropError('tnvedCode')"
              />
            </div>
            <div class="supster-ui--input__wrapper col col-6">
              <app-v-select
                v-model="form.data.nds"
                :items="lists.nds"
                :error="checkPropError('nds')"
                :placeholder="$t('chooseFromSelect')"
              >
                <template #label>
                  <span class="app-label-dot">{{ $t('product.NDS') }}</span>
                </template>
              </app-v-select>
            </div>
            <v-row class="mx-0">
              <v-col cols="6" class="px-0">
                <div class="supster-ui--input__wrapper col col-12">
                  <app-v-text-field
                    v-model="form.data.vendorCode"
                    :error="checkPropError('vendorCode')"
                  >
                    <template #label>
                      <span class="app-label-dot">
                        {{ $t('product.SKU') }}
                        <span class="help-circle help-circle--large ml-1">?</span>
                      </span>
                    </template>
                  </app-v-text-field>
                </div>
                <div class="supster-ui--input__wrapper col col-12">
                  <app-v-text-field v-model="form.data.barcode" :error="checkPropError('barcode')">
                    <template #label>
                      <span class="app-label-dot">
                        {{ $t('product.barcode') }}
                        <span class="help-circle help-circle--large ml-1">?</span>
                      </span>
                    </template>
                  </app-v-text-field>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="d-flex ml-5 mt-9">
                  <div><i class="supster-ui--badge__block _v2 mr-4">i</i></div>
                  <div>
                    <p class="supster-ui__text font-weight-regular mt-0">
                      {{ $t('product.help.2') }}
                    </p>
                    <v-btn x-small color="accent">{{
                      $t('pageBusinessPublish.stage3.read_btn')
                    }}</v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-row>
          <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" v-if="form.step.current === 1" />
          <v-row class="px-10 justify-end" v-if="form.step.current === 1">
            <div class="col col-auto">
              <v-btn color="accent" @click="nextStep">{{ $t('next') }}</v-btn>
            </div>
          </v-row>
        </card>
      </v-col>
      <v-col cols="12" class="py-2" v-if="form.step.current > 1 || mode === 'edit'">
        <card class="mt-0 mb-0 mx-4 px-0 py-10">
          <!-- <h2 class="supster-ui--form__title px-10">Доступные размеры</h2>
                <v-row class="flex-column px-10">
                    <div class="supster-ui--input__wrapper col col-4">
                    <label
                        class="supster-ui--input__label _required"
                        :class="{ _error: checkPropError('name') }"
                    >
                        Система размеров
                    </label>
                    <div class="d-flex align-center">
                        <drop-select
                        class="mt-0"
                        v-model="form.data.sizeType"
                        :placeholder="$t('choosePlaceholder')"
                        :list="[{ id: 1, name: 'Rus' }]"
                        labelParam="name"

                        />
                        <a href="#" class="supster-ui__link _accent mt-2 ml-4">
                        <icon data="@/assets/icons/link.svg" class="mr-1 mb-1" />
                        Таблица размеров
                        </a>
                    </div>
                    </div>
                    <div class="supster-ui--input__wrapper col col-6">
                    <div class="d-flex flex-nowrap ml-0">
                        <input-checkbox
                        class=""
                        v-for="(item, index) in lists.sizes"
                        :key="index"
                        v-model="form.data.sizes"
                        :val="item.id"
                        :label="item.name"
                        :boxed="true"
                        :verticalMode="true"
                        width="56px"
                        height="74px"
                        :style="{ 'margin-right': '9px', 'margin-bottom': '9px' }"
                        :class="{ _error: checkPropError('workDays') }"
                        />
                    </div>
                    </div>
                </v-row>
                <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" />
                <h2 class="supster-ui--form__title px-10">Доступные цвета</h2>
                <v-row class="flex-column px-10">
                    <div class="supster-ui--input__wrapper col col-6">
                    <input-composite-select
                        v-model="form.data.colors"
                        :items="lists.colors"
                        itemValue="id"
                        itemText="name"
                        placeholder="..."
                        label="Цвет"
                        width="186px"
                        cols="auto"
                    />
                    </div>
                </v-row>
                <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" />
                <h2 class="supster-ui--form__title px-10">Состав и материалы</h2>
                <v-row class="flex-column px-10">
                    <div class="supster-ui--input__wrapper col col-6">
                    <input-composite-select
                        v-model="form.data.materials"
                        :items="lists.materials"
                        :itemValues="[{ name: 'percent', label: '% материала', width: '120px' }]"
                        itemValue="id"
                        itemText="name"
                        placeholder="..."
                        label="Материал"
                        width="360px"
                        cols="12"
                    />
                    </div>
                </v-row>
                <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" /> -->
          <h2 class="supster-ui--form__title px-10">{{ $t('product.productPhoto') }}</h2>

          <v-row class="flex-column px-10">
            <div class="supster-ui--input__wrapper col col-12">
              <div class="d-flex align-center">
                <v-btn color="accent" class="btn-image-uploader">
                  <icon data="@/assets/icons/upload.svg" class="mr-2 mb-0" width="12px" />
                  {{ $t('product.uploadImage') }}
                  <input
                    class="product-photos__btn-upload-input"
                    accept="image/png, image/jpeg, image/bmp"
                    type="file"
                    @change="addPhotos"
                    multiple
                  />
                </v-btn>
                <span class="supster-ui__text _s-12 ma-0 ml-4"
                  >{{ $t('product.uploadImageParams') }}
                </span>
                <div class="d-flex flex-grow-1 justify-end">
                  <v-btn
                    min-width="40"
                    width="40"
                    color="primary lighten-1"
                    class="br-8 mr-4 rounded-md"
                    @click="photosChangeSlide('prev')"
                  >
                    <icon data="@/assets/icons/arrow-left.svg" width="6px" />
                  </v-btn>
                  <v-btn
                    min-width="40"
                    width="40"
                    class="rounded-md"
                    color="primary lighten-1"
                    @click="photosChangeSlide('next')"
                  >
                    <icon data="@/assets/icons/arrow-right.svg" width="6px" />
                  </v-btn>
                </div>
              </div>
              <div
                @drop.prevent="addPhotos"
                @dragover.prevent
                class="product-photos__corusel"
                ref="photos-corusel"
              >
                <div
                  class="product-photos__corusel-inner"
                  ref="photos-container"
                  :style="{ left: `${-photosCorusel.offsetWidth}px` }"
                >
                  <draggable
                    class="product-photos__row"
                    v-model="form.extraData.productImages"
                    handle=".photo-handle"
                  >
                    <div
                      class="product-photos__col"
                      v-for="(n, index) in 20"
                      :key="`product-photo-${index}`"
                      ref="photos-col"
                    >
                      <div
                        class="product-photos__item"
                        :class="{ _error: !form.validateErrors.productImages && form.showErrors }"
                      >
                        <template v-if="form.extraData.productImages[index]">
                          <span
                            class="product-photos__item-image"
                            :style="{ 'background-image': `url(${imagesPreview[index]})` }"
                          ></span>
                          <v-btn
                            v-if="index !== 0"
                            color="accent"
                            fab
                            class="product-photos__item-btn-drag photo-handle"
                          >
                            <icon data="@/assets/icons/drag-file.svg" width="20px" />
                          </v-btn>

                          <v-btn
                            fab
                            color="primary lighten-1"
                            @click="removePhoto(index)"
                            class="product-photos__item-btn-remove"
                          >
                            <icon data="@/assets/icons/close.svg" width="12px" />
                          </v-btn>
                          <v-btn
                            class="product-photos__item-btn-setmain"
                            size="small"
                            color="accent"
                            :outlined="index === 0"
                            x-small
                            width="100%"
                            @click="setMainPhoto(index)"
                          >
                            {{
                              index === 0 ? $t('product.mainPhoto') : $t('product.makeMainPhoto')
                            }}
                          </v-btn>
                        </template>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </v-row>

          <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" v-if="form.step.current === 2" />
          <v-row class="px-10 justify-end" v-if="form.step.current === 2">
            <div class="col col-auto">
              <v-btn color="accent" @click="nextStep">{{ $t('next') }}</v-btn>
            </div>
          </v-row>
        </card>
      </v-col>
      <v-col cols="12" class="py-2" v-if="form.step.current > 2 || mode === 'edit'">
        <card class="mt-0 mb-0 mx-4 px-0 py-10">
          <h2 class="supster-ui--form__title px-10">{{ $t('product.descrAndPrice') }}</h2>
          <v-row class="flex-column px-10">
            <div class="supster-ui--input__wrapper col col-6">
              <app-v-textarea
                v-model="form.data.description"
                :error="checkPropError('description')"
              >
                <template #label>
                  <span class="app-label-dot">
                    {{ $t('product.descr') }}
                    <span class="help-circle help-circle--large ml-1">?</span>
                  </span>
                </template>
              </app-v-textarea>
            </div>
            <div class="supster-ui--input__wrapper col col-6">
              <label
                class="supster-ui--input__label _required ml-2"
                :class="{ _error: checkPropError('keyWords') }"
              >
                {{ $t('product.keyWords') }}
                <i class="supster-ui--badge__block _accent ml-1">?</i>
              </label>
              <input-text
                class="align-stretch pl-2"
                v-model="form.extraData.keyWord"
                :error="checkPropError('keyWords')"
                @keyup="addKeyword"
                @change="setKeyword"
                height="auto"
                minHeight="40px"
              >
                <template v-slot:before>
                  <div class="keywords-wrapper mr-n2">
                    <v-chip
                      v-for="(keyword, index) in form.extraData.keyWordsArray"
                      :key="`keyword-${index}`"
                      @click:close="removeKeyword(index)"
                      close
                      close-icon="$close"
                      color="primary"
                      label
                      small
                      class="mr-1 mb-1"
                    >
                      <span>{{ keyword }}</span>
                    </v-chip>
                  </div>
                </template>
              </input-text>
            </div>
            <div class="supster-ui--input__wrapper col col-12">
              <v-row>
                <v-col cols="3">
                  <app-v-text-field-currency
                    v-model.lazy="form.data.oldPrice"
                    :error="checkPropError('oldPrice')"
                    moneySuffix="₽"
                    mask="'######'"
                  >
                    <template #label>
                      <span class="">
                        {{ $t('products.oldPrice') }}
                        <span class="help-circle help-circle--large ml-1">?</span>
                      </span>
                    </template>
                  </app-v-text-field-currency>
                </v-col>
                <v-col cols="3">
                  <app-v-text-field-currency
                    v-model.lazy="form.data.price"
                    :error="checkPropError('price')"
                    moneySuffix="₽"
                    v-mask="'######'"
                  >
                    <template #label>
                      <span class="app-label-dot">
                        {{ $t('products.discountPrice') }}
                        <span class="help-circle help-circle--large ml-1">?</span>
                      </span>
                    </template>
                  </app-v-text-field-currency>
                </v-col>
                <v-col cols="3">
                  <div class="d-flex ml-5 mt-5 align-center">
                    <div><i class="supster-ui--badge__block _v3 _danger mr-4">!</i></div>
                    <div>
                      <p class="supster-ui__text _s-12 mb-0 mt-1 py-0">
                        {{ $t('product.priceRule') }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-row>
          <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" />
          <h2 class="supster-ui--form__title px-10">{{ $t('product.storagesAndDelivery') }}</h2>
          <v-row class="flex-column px-10">
            <div class="supster-ui--input__wrapper col col-6">
              <input-composite-select
                v-model="form.extraData.stores"
                :items="lists.stores"
                :itemValues="[
                  {
                    name: 'price',
                    label: $t('product.deliveryPrice'),
                    width: '118px',
                    mask: '#######',
                    money: '₽',
                  },
                  {
                    name: 'rest',
                    label: $t('product.availability'),
                    width: '118px',
                    mask: '#####',
                  },
                ]"
                @removedItem="storeItemAddForDeletion"
                :itemSelectDisable="(item) => false"
                selectProp="store"
                itemValue="id"
                itemText="name"
                placeholder="..."
                :label="$t('storage')"
                width="284px"
                cols="12"
                :draggable="false"
              />
            </div>
          </v-row>
          <v-row class="flex-column px-10">
            <div class="supster-ui--input__wrapper col col-6"></div>
          </v-row>
          <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" />
          <!-- <h2 class="supster-ui--form__title px-10">Параметры упаковки</h2>
        <v-row class="flex-column px-10">
            <div class="supster-ui--input__wrapper col col-6">
            <v-row>
                <div class="supster-ui--input__wrapper col col-6">
                <label
                    class="supster-ui--input__label _required"
                    :class="{ _error: checkPropError('name') }"
                >
                    Вес с упаковкой, г <i class="supster-ui--badge__block _accent ml-1">?</i>
                </label>
                <input-text
                    :class="{ _error: checkPropError('name') }"
                    v-model="form.data.name"
                    :error="true"

                />
                </div>
                <div class="supster-ui--input__wrapper col col-6">
                <label
                    class="supster-ui--input__label _required"
                    :class="{ _error: checkPropError('name') }"
                >
                    Длина упаковки, мм <i class="supster-ui--badge__block _accent ml-1">?</i>
                </label>
                <input-text
                    :class="{ _error: checkPropError('name') }"
                    v-model="form.data.name"
                    :error="true"

                />
                </div>
                <div class="supster-ui--input__wrapper col col-6">
                <label
                    class="supster-ui--input__label _required"
                    :class="{ _error: checkPropError('name') }"
                >
                    Ширина упаковки, мм <i class="supster-ui--badge__block _accent ml-1">?</i>
                </label>
                <input-text
                    :class="{ _error: checkPropError('name') }"
                    v-model="form.data.name"
                    :error="true"

                />
                </div>
                <div class="supster-ui--input__wrapper col col-6">
                <label
                    class="supster-ui--input__label _required"
                    :class="{ _error: checkPropError('name') }"
                >
                    Высота упаковки, мм<i class="supster-ui--badge__block _accent ml-1">?</i>
                </label>
                <input-text
                    :class="{ _error: checkPropError('name') }"
                    v-model="form.data.name"
                    :error="true"

                />
                </div>
            </v-row>
            </div>
        </v-row> -->
          <!-- <hr class="supster-ui--form__separator mx-0 mt-10 mb-10" /> -->
          <v-row class="px-10 justify-end">
            <div class="col col-auto">
              <v-btn color="accent" @click="submitForm" :loading="formLoaded">
                {{ mode === 'create' ? $t('product.createCard') : $t('product.editCard') }}
              </v-btn>
            </div>
          </v-row>
        </card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Card from '@/components/Card.vue'
import draggable from 'vuedraggable'
import AppAutocompleteCountries from '@/components/app/autocomplete/AppAutocompleteCountries.vue'

export default Vue.extend({
  name: 'ProductsForm',
  components: {
    Card,
    draggable,
    AppAutocompleteCountries,
  },
  props: {
    mode: {
      type: String,
      default: 'create',
      required: true,
      validator(value) {
        return ['create', 'edit'].indexOf(value) !== -1
      },
    },
  },
  data: () => ({
    formLoaded: false,
    photosCorusel: {
      containerWidth: '',
      offsetWidth: 0,
    },
    form: {
      extraData: {
        needDeleteStores: [],
        keyWord: '',
        keyWordsArray: [],
        productCategory: {
          level1: {},
          level2: {},
          level3: {},
        },
        productImages: [],
        noBrand: false,
        stores: [],
      },
      data: {},
      step: {
        current: 0,
        labels: [],
      },
      validateErrors: {},
      showErrors: false,
    },
    lists: {
      stores: [],
      productCategories: [],
      productTnveds: [],
      productProperties: [],
      nds: [
        {
          value: 0,
          name: 'Не облагается',
        },
        {
          value: 10,
          name: '10%',
        },
        {
          value: 20,
          name: '20%',
        },
      ],
      colors: [
        {
          id: 1,
          name: 'Красный',
        },
      ],
      materials: [
        {
          id: 1,
          name: 'Хлопок',
        },
      ],
      sizes: [
        {
          id: 1,
          name: 'XXS',
        },
      ],
      countries: [],
    },
  }),
  computed: {
    ...mapGetters('products', ['getProductsItem']),
    ...mapGetters('nDeskbook', ['getListCountries']),
    ...mapGetters('app', { app: 'getSelectedApp' }),
    imagesPreview() {
      return this.form.extraData.productImages.map((file) => {
        if (typeof file === 'object' && file.type === 'new') {
          return URL.createObjectURL(file.media_object)
        }
        return file.media_object.content_url
      })
    },
  },
  methods: {
    ...mapMutations('snackbar', ['callSnackbar']),

    ...mapActions('stores', ['loadStores']),
    ...mapActions('products', [
      'createProduct',
      'updateProduct',
      'loadProduct',
      'loadProductCategories',
      'loadProductFormProps',
      'loadProductTnveds',
      'loadProductProperties',
      'loadProductBrands',
      'createProductStores',
      'updateProductStores',
      'getItemsroductStores',
      'deleteProductStores',
    ]),
    ...mapActions({
      loadCountries: 'nDeskbook/fetchCountries',
    }),
    ...mapActions('mediaObjects', ['createMediaObject']),
    checkPropError(propName) {
      return (
        propName in this.form.validateErrors &&
        !this.form.validateErrors[propName] &&
        this.form.showErrors
      )
    },

    nextStep() {
      if (this.validateStep(this.form.step.current)) {
        this.form.step.current++
      }
    },
    removeBrand(event) {
      if (event) {
        this.form.data.brand = ''
      }
    },
    storeItemAddForDeletion(item) {
      if ('id' in item) {
        this.form.extraData.needDeleteStores.push(item)
      }
    },
    selectCategory(level, item) {
      switch (level) {
        case 1:
          this.form.extraData.productCategory.level1 = item
          this.form.extraData.productCategory.level2 = {}
          this.form.extraData.productCategory.level3 = {}
          break
        case 2:
          this.form.extraData.productCategory.level2 = item
          this.form.extraData.productCategory.level3 = {}
          break
        case 3:
          this.form.extraData.productCategory.level3 = item
          break

        default:
          this.form.extraData.productCategory.level1 = {}
          this.form.extraData.productCategory.level2 = {}
          this.form.extraData.productCategory.level3 = {}
          break
      }
      this.form.data.productCategory = item
    },
    addKeyword(event) {
      if (event.target.value.indexOf(',') >= 0) {
        if (event.target.value.length > 1) {
          this.form.extraData.keyWordsArray.push(event.target.value.replace(',', ''))
        }
        this.form.extraData.keyWord = ''
      }
      this.form.data.keyWords = this.form.extraData.keyWordsArray.join(',')
    },
    setKeyword(event) {
      this.form.extraData.keyWordsArray.push(event.target.value)
      event.target.value = ''
    },
    removeKeyword(index) {
      this.form.extraData.keyWordsArray.splice(index, 1)
      this.form.data.keyWords = this.form.extraData.keyWordsArray.join(',')
    },
    addPhotos(event) {
      const newFiles = Object.entries(event.target.files || event.dataTransfer.files).map(
        (file) => {
          return { type: 'new', media_object: file[1] }
        }
      )
      this.form.extraData.productImages = [...this.form.extraData.productImages, ...newFiles]
    },
    removePhoto(index) {
      this.form.extraData.productImages.splice(index, 1)
      this.photosChangeSlide('end')
    },
    setMainPhoto(index) {
      const newMainPhoto = this.form.extraData.productImages.splice(index, 1)
      this.form.extraData.productImages.unshift(newMainPhoto[0])
      this.photosChangeSlide('start')
    },
    photosChangeSlide(side) {
      //   const elCorusel = this.$refs['photos-corusel']
      const elContainer = this.$refs['photos-container']
      const elCol = this.$refs['photos-col']
      const itemsCount = this.form.extraData.productImages.length
      const maxWidth = elCol[0].clientWidth * itemsCount
      const stepOffset = elCol[0].clientWidth * 2
      const countViewedCols = Math.round(elContainer.clientWidth / elCol[0].clientWidth)
      let { offsetWidth } = this.photosCorusel

      if (countViewedCols >= itemsCount) {
        this.photosCorusel.offsetWidth = 0
        return
      }

      if (side === 'next') {
        offsetWidth += stepOffset

        if (offsetWidth + elContainer.clientWidth - stepOffset < maxWidth) {
          this.photosCorusel.offsetWidth = offsetWidth
          return
        }
      } else if (side === 'prev') {
        offsetWidth -= stepOffset
        if (offsetWidth >= 0) {
          this.photosCorusel.offsetWidth = offsetWidth
          return
        }
        if (this.photosCorusel.offsetWidth !== 0) {
          this.photosCorusel.offsetWidth = 0
          return
        }
        this.photosCorusel.offsetWidth = maxWidth - elCol[0].clientWidth * countViewedCols
        return
      } else if (side === 'end') {
        if (offsetWidth + elContainer.clientWidth > maxWidth) {
          this.photosCorusel.offsetWidth = maxWidth - elCol[0].clientWidth * countViewedCols
          return
        }
      } else if (side === 'start') {
        this.photosCorusel.offsetWidth = 0
      }
      this.photosCorusel.offsetWidth = 0
    },

    validateStep(step) {
      const errors = {}
      if (step === 0) {
        return true
      }

      if (step > 0) {
        errors.name = this.form.data.name.length > 1
        errors.vendorCode = this.form.data.vendorCode.length > 2
        errors.productCategory =
          !!this.form.data.productCategory &&
          !!this.form.data.productCategory.subCategories &&
          this.form.data.productCategory.subCategories.length === 0
        errors.country =
          typeof this.form.data.country === 'object' &&
          Object.keys(this.form.data.country).length > 0
        errors.brand =
          (typeof this.form.data.brand === 'object' &&
            Object.keys(this.form.data.brand).length > 0) ||
          this.form.extraData.noBrand
        errors.tnvedCode =
          typeof this.form.data.tnvedCode === 'object' &&
          Object.keys(this.form.data.tnvedCode).length > 0
        errors.nds =
          typeof this.form.data.nds === 'object' && Object.keys(this.form.data.nds).length > 0
      }
      if (step > 1) {
        errors.productImages = this.form.extraData.productImages.length > 0
      }
      if (step > 2) {
        errors.description = this.form.data.description.length > 0
        errors.keyWords = this.form.data.keyWords.length > 0
        const maxPrice = 100500
        const newPrices = {
          price: this.form.data.price,
          oldPrice: this.form.data.oldPrice,
        }
        if (newPrices.oldPrice !== '0') {
          if (newPrices.oldPrice.length && Number(newPrices.price) >= Number(newPrices.oldPrice)) {
            errors.price =
              !newPrices.oldPrice.length && Number(newPrices.price) <= Number(newPrices.oldPrice)
            this.form.validateErrors = errors
            this.form.showErrors = true
            this.callSnackbar({
              color: 'red',
              text: this.$t('allertMessage.priceValidation.1'),
            })
            return false
          }
        }

        if (
          (newPrices.oldPrice.length && Number(newPrices.oldPrice) > maxPrice) ||
          (newPrices.price.length && Number(newPrices.price) > maxPrice)
        ) {
          errors.price = newPrices.price.length && Number(newPrices.price) < maxPrice
          errors.oldPrice = newPrices.oldPrice.length && Number(newPrices.oldPrice) < maxPrice
          this.form.validateErrors = errors
          this.form.showErrors = true
          this.callSnackbar({
            color: 'red',
            text: `${this.$t('allertMessage.priceValidation.2')} ${maxPrice}`,
          })
          return false
        }
        errors.price = Number(newPrices.price) > 0
      }

      if (Object.values(errors).includes(false)) {
        this.form.validateErrors = errors
        this.form.showErrors = true
        this.callSnackbar({
          color: 'red',
          text: this.$t('allertMessage.stepsError'),
        })
        return false
      }
      this.form.showErrors = false

      return true
    },
    async submitForm() {
      if (!this.validateStep(this.form.step.current) || this.formLoaded) {
        return
      }
      this.formLoaded = true

      const mediaObjects = []
      for (const item of this.form.extraData.productImages) {
        if (item.type === 'new') {
          const response = await this.createMediaObject({ data: item.media_object })
          if (response.status) {
            mediaObjects.push(response.data)
          }
        } else {
          mediaObjects.push(item.media_object)
        }
      }

      this.form.data.media_objects = mediaObjects

      let productResponse = {}
      if (this.mode === 'create') {
        productResponse = await this.createProduct({ data: this.form.data })
      } else {
        productResponse = await this.updateProduct({ data: this.form.data })
      }

      if (productResponse.status) {
        for (const productStore of this.form.extraData.stores) {
          productStore.product = productResponse.data.id
          if (productStore.id) {
            await this.updateProductStores({ id: productStore.id, data: productStore })
          } else {
            await this.createProductStores({ data: productStore })
          }
        }
        for (const store of this.form.extraData.needDeleteStores) {
          await this.deleteProductStores({ id: store.id })
        }
      }

      this.formLoaded = false
      this.$router.push(`/business/goods_and_services/products`)
    },
    searchCategoryTree(arr, findId, result = {}, level = 0) {
      level++
      for (let i = 0; i < arr.length; i++) {
        result[`level${level}`] = arr[i]
        if (arr[i].id === findId) {
          return true
        }
        if (arr[i].subCategories.length) {
          if (this.searchCategoryTree(arr[i].subCategories, findId, result, level) === true) {
            break
          }
        }
      }
      return result
    },
    async searchProductTnvedsHandler(value) {
      const findParam = Number.isInteger(Number(value)) ? 'code' : 'name'
      const params = { itemsPerPage: 100 }
      params[findParam] = value
      const productTnveds = await this.loadProductTnveds({ params })
      this.lists.productTnveds = productTnveds.status ? productTnveds.data : []
    },
    async searchTnvedsHandler(value) {
      const findParam = Number.isInteger(Number(value)) ? 'code' : 'name'
      const params = { itemsPerPage: 100 }
      params[findParam] = value
      const data = await this.loadProductTnveds({ params })
      return data
    },
    async searchBrand(val) {
      if (typeof val === 'object') {
        val = val.name
      }
      const data = await this.loadProductBrands({
        params: { name: val, pgae: 1, itemsPerPage: 250 },
      })
      return data
    },
    async initEditData() {
      if (this.mode === 'edit') {
        await this.loadProduct({ id: this.$route.params.id })
        this.form.data = this.getProductsItem

        this.form.step.current = 3

        if (this.form.data.productCategory) {
          const categories = this.searchCategoryTree(
            this.lists.productCategories,
            this.form.data.productCategory.id
          )
          if ('level1' in categories) {
            this.selectCategory(1, categories.level1)
          }
          if ('level2' in categories) {
            this.selectCategory(2, categories.level2)
          }
          if ('level3' in categories) {
            this.selectCategory(3, categories.level3)
          }
        }

        if (Number(this.form.data.nds) === 0) {
          this.form.data.nds = this.lists.nds[0]
        } else if (Number(this.form.data.nds) === 10) {
          this.form.data.nds = this.lists.nds[1]
        } else {
          this.form.data.nds = this.lists.nds[2]
        }

        this.form.extraData.keyWordsArray = this.form.data.keyWords.split(',')
        this.form.extraData.productImages = this.form.data.media_objects.map((item) => {
          return { type: 'edit', media_object: item }
        })
        this.form.extraData.stores = await this.getItemsroductStores({
          params: { product: this.form.data.id },
        })
      }
    },
  },
  async created() {
   

    this.loadProductFormProps()
    this.form.data = this.getProductsItem
    this.form.data.app = this.app

    const stores = await this.loadStores({ params: { app: this.app.id } })

    if (stores.status) {
      this.lists.stores = stores.data.map((store) => {
        return {
          store: {
            id: store.id,
            name: store.name,
          },
          product: '',
          price: 0,
          rest: 0,
        }
      })
    }

    const productCategories = await this.loadProductCategories({ params: { itemsPerPage: 1000 } })
    this.lists.productCategories = (productCategories.status ? productCategories.data : []).filter(
      (item) => item.subCategories.length > 0
    )

    const productProperties = await this.loadProductProperties({})
    this.lists.productProperties = productProperties.status ? productProperties.data : []

    await this.loadCountries()
    this.lists.countries = this.getListCountries

    await this.initEditData()

   
  },


})
</script>
<style lang="scss">
.page-part-component--products-form {
  .btn-image-uploader {
    .v-btn__content {
      position: static;
    }
  }
  .keywords-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    max-width: 70%;
    padding: 10px 0 6px;
  }
  .product-photos {
    &__corusel {
      position: relative;
      z-index: 3;
      overflow: hidden;
    }
    &__corusel-inner {
      position: relative;
      left: 0;
      transition: all 0.3s ease;
    }
    &__btn-upload {
      position: relative;
    }

    &__btn-upload-input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
      opacity: 0;
    }
    &__row {
      position: relative;
      display: flex;
      width: 100%;
      margin: 0 -8px;
      margin-top: 33px;
    }
    &__col {
      padding: 0 8px;
      padding-bottom: 40px;
    }
    &__item {
      position: relative;
      z-index: 50;
      width: 220px;
      max-width: 100%;
      height: 220px;
      background: #fff;
      border: 1px dashed var(--light-gray);
      border-radius: 8px;
      &._active {
        border: 1px solid var(--light-gray);
      }
      &._error {
        border-color: var(--v-error-base);
      }
    }
    &__item-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 50;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    &__item-btn-drag {
      position: absolute;
      bottom: 8px;
      left: 8px;
      z-index: 60;
    }
    &__item-btn-remove {
      position: absolute;
      right: 8px;
      bottom: 8px;
      z-index: 60;
    }
    &__item-btn-setmain {
      position: absolute;
      bottom: -36px;
      left: 0;
    }
  }
}
</style>










































































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { ClientVerificationsAppModelType } from '@/services/api/ClientVerifications/ClientVerificationsTypes'
import { barDataType } from '../types'

@Component({})
export default class BusinessVerification extends Vue {
  // vuex module getter
  @VuexGetter('nBusinessVerification') getClientVerification!: ClientVerificationsAppModelType

  @VuexGetter('nApp') getIsGptApp!: boolean

  // Computed
  get barData(): barDataType {
    let result: barDataType = {
      label: this.$t('verification.activated'),
      buttonLabel: this.$t('verification.goToVerification'),
      color: 'var(--v-primary-base)',
      buttonColor: 'accent',
      link: 'Business_Verification',
    }

    if (this.getClientVerification) {
      switch (this.getClientVerification.status) {
        case 1:
          result = {
            label: this.$t('verification.vaitModerate'),
            buttonLabel: this.$t('verification.viewStatus'),
            color: 'var(--primary-light)',
            link: 'Business_VerificationStatus',
            buttonColor: 'primary',
          }
          break
        case 2:
          result = {
            label: this.$t('verification.verificationOK'),
            // buttonLabel: this.$t('verification.viewData'),
            color: 'var(--accent-green)',
            link: 'modal',
            buttonColor: 'primary',
          }
          break
        case 3:
          result = {
            label: this.$t('verification.requestRejected'),
            buttonLabel: this.$t('verification.specifyTheReason'),
            color: 'var(--error)',
            link: 'Business_VerificationStatus',
            buttonColor: 'primary',
          }
          break
        default:
          break
      }
    }
    if (this.getIsGptApp) {
      result = {
        label: this.$t('verification.verificationGptOK'),
        // buttonLabel: this.$t('verification.viewData'),
        color: 'var(--accent-green)',
        link: 'modal',
        buttonColor: 'primary',
      }
    }
    return result
  }
}






































import { Component, Vue } from 'vue-property-decorator'
import AffiliateStatisticsTopCards from '@/views/affiliate/statistics/components/AffiliateStatisticsTopCards.vue'
import AffiliateStatisticsFilter from '@/views/affiliate/statistics/components/AffiliateStatisticsFilter.vue'
import AffiliateStatisticsTable from '@/views/affiliate/statistics/components/AffiliateStatisticsTable.vue'
import { VuexGetter, VuexAction, VuexMutation } from '@/store/decorators'
import moment from 'moment'
import { filterDataType } from './types'

@Component({
  components: {
    AffiliateStatisticsTopCards,
    AffiliateStatisticsFilter,
    AffiliateStatisticsTable,
  },
})
export default class AffiliateStatistics extends Vue {
  // Data

  isPreloaderShow = false

  // Vuex module actions
  @VuexAction('nAffiliateStatistics') statsAffiliateTotalGetItems!: () => Promise<void>

  @VuexAction('nAffiliateStatistics') statsAffiliateGetItems!: () => Promise<void>

  @VuexAction('nAffiliateStatistics') offersGetItems!: () => Promise<void>

  @VuexAction('nAffiliateStatistics') streamsGetItems!: () => Promise<void>

  //  Vuex module mutation
  @VuexMutation('nAffiliateStatistics') offersParamsUpdate!: (...args) => void

  @VuexMutation('nAffiliateStatistics') streamsParamsUpdate!: (...args) => void

  @VuexMutation('nAffiliateStatistics') statsAffiliateParamsUpdate!: (...args) => void

  // Vuex module getters

  @VuexGetter('nApp') getAuthUser!: any // TODO Старый модуль для получения user

  @VuexGetter('nAffiliateStatistics') getStatsAffiliateParams!: any

  // Methods

  async filter(filterData: filterDataType): Promise<void> {
    this.statsAffiliateParamsUpdate({ ...filterData })
    await this.statsAffiliateGetItems()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.statsAffiliateTotalGetItems()
    this.statsAffiliateParamsUpdate({
      groupBy: {
        name: this.getStatsAffiliateParams.groupBy
          ? this.getStatsAffiliateParams.groupBy.name
          : this.$t('pageAffiliateStats.groupByOptions.day'),
        value: this.getStatsAffiliateParams.groupBy
          ? this.getStatsAffiliateParams.groupBy.value
          : 'created',
      },
      dateRange: [
        moment().subtract(13, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ],
    })
    await this.statsAffiliateGetItems()
    this.streamsParamsUpdate({ user: this.getAuthUser.id, pagination: false })
    await this.streamsGetItems()
    this.offersParamsUpdate({ user: this.getAuthUser.id, pagination: false })
    await this.offersGetItems()
    this.isPreloaderShow = false
  }
}























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CountryFlag from '@/components/CountryFlag.vue'
import {
  getExampleNumber,
  getCountries,
  getCountryCallingCode,
  AsYouType,
  CountryCode,
  parsePhoneNumber,
  PhoneNumber,
  isValidPhoneNumber,
  Metadata,
  formatNumber,
} from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { TranslateResult } from 'vue-i18n'

import AppVSelect from './AppVSelect.vue'

@Component({
  components: {
    CountryFlag,
    AppVSelect,
  },
})
export default class AppPhoneInput extends Vue {
  defaultCoutry: CountryCode = 'RU'

  selectedCountry: CountryCode = this.defaultCoutry

  focused = false

  dataError = ''

  valid = true

  test = ''

  inputData: {
    countryCode: CountryCode
    countryCallingCode: string
    example: any
    mask: string
    maxPhoneLength: number
    minValue: string
    value: string
    modelValue: string
  } = {
    countryCode: this.defaultCoutry,
    countryCallingCode: '',
    example: {},
    mask: '',
    maxPhoneLength: 10,
    minValue: '',
    value: '',
    modelValue: '',
  }

  @Prop() value!: string

  @Prop(Boolean) required!: boolean

  @Prop(Array) readonly rules!: []

  @Prop(String) readonly type!: string

  @Prop(String) readonly label!: string

  createCountryItem(countryCode): any {
    // const example = getExampleNumber(countryCode, examples)
    return {
      name: this.$t(`countries.${countryCode}`),
      code: countryCode,
      countryCode,
      countryCallingCode: getCountryCallingCode(countryCode),
      maxPhoneLength: this.getMaxPhoneLengthForCountry(countryCode),
      // example,
    }
  }

  get countriesList(): any[] {
    const result: any[] = []
    for (const countryCode of this.phoneLibjsCountries) {
      if (countryCode === 'TA' || countryCode === 'AC') {
        continue
      }
      result.push(this.createCountryItem(countryCode))
    }
    return result
  }

  get phoneLibjsCountries(): CountryCode[] {
    return getCountries()
  }

  setInputData(data): void {
    this.inputData = { ...this.inputData, ...data }
  }

  setCountry(countryCode: CountryCode): void {
    const example = getExampleNumber(countryCode, examples)
    this.setInputData({
      countryCode,
      countryCallingCode: getCountryCallingCode(countryCode),
      maxPhoneLength: this.getMaxPhoneLengthForCountry(countryCode),
      example,
      minValue: `+${getCountryCallingCode(countryCode)}`,
      value: `+${getCountryCallingCode(countryCode)}`,
    })
  }

  changeCountry(value: { code: CountryCode }): void {
    this.setCountry(value.code)
  }

  validate(value?) {
    if (value) {
      this.valid = value
      return value
    }
    this.valid = false
    const rules: string | TranslateResult[] = this.rules ? [...this.rules] : []
    if (
      !isValidPhoneNumber(this.inputData.modelValue, this.inputData.countryCode) &&
      this.required
    ) {
      rules.push(this.$t('valiationMessages.phoneIncorrect'))
    }
    rules.forEach((item: any): void => {
      this.dataError = item
    })
    if (rules.length) {
      this.valid = false
    } else {
      this.valid = true
      this.dataError = ''
    }

    return this.valid
  }

  validateAndMaskPhone(value: string): string {
    const asYouType = new AsYouType(this.inputData.countryCode)
    asYouType.input(value)
    const phone = asYouType.getNumber()

    if (value.length <= this.inputData.minValue.length) {
      return this.inputData.minValue
    }
    if (phone && phone.number.length > this.inputData.maxPhoneLength) {
      return phone.number.substr(0, this.inputData.maxPhoneLength)
    }
    return phone?.number || this.inputData.minValue
  }

  async updateInputPhone(e: { target: { value: string } }): Promise<void> {
    const result = this.validateAndMaskPhone(e.target.value)
    e.target.value = result
    this.updateValue(result)
  }

  updateValue(value: string): void {
    const asYouType = new AsYouType(this.inputData.countryCode)
    asYouType.input(value)

    const number = asYouType.getNumber()
    if (number && isValidPhoneNumber(number.number, this.inputData.countryCode)) {
      this.inputData.modelValue = number.number.replace('+', '')
    } else {
      this.inputData.modelValue = ''
    }

    this.inputData.value = value
    this.$emit('input', this.inputData.modelValue)
  }

  updateCountryByPhone(value: string): void {
    const asYouType = new AsYouType()
    asYouType.input(`+${value}`)
    if (asYouType.country) {
      this.selectedCountry = asYouType.country
      this.setCountry(asYouType.country)
    } else if (value.length >= 3) {
      this.selectedCountry = 'CA' // дефолтное для +1 - Канада
      this.setCountry('CA') // дефолтное для +1 - Канада
    }
  }

  getMaxPhoneLengthForCountry(countryCode) {
    const metadata = new Metadata()
    metadata.selectNumberingPlan(countryCode)
    const countryCodeLength = 1 + getCountryCallingCode(countryCode).length ?? 0
    const possibleLengths = metadata?.numberingPlan?.possibleLengths() ?? [0]
    return Math.max(...possibleLengths) + countryCodeLength
  }

  initPhone(value: string): void {
    this.updateCountryByPhone(value)
    const result = this.validateAndMaskPhone(value)
    this.updateValue(result)
  }

  @Watch('rules')
  rulesHandler() {
    if (this.rules.length) {
      this.validate()
    }
  }

  @Watch('value')
  initValue(val: string): void {
    if (val !== this.inputData.modelValue) {
      this.initPhone(val)
    }
    if (this.inputData.modelValue.length === this.inputData.maxPhoneLength) {
      this.dataError = ''
      this.valid = true
    }
  }

  created(): void {
    this.setCountry(this.defaultCoutry)
    this.initPhone(this.value)
  }
}

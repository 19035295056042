




























import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import { VuexGetter } from '@/store/decorators'

@Component({})
export default class StreamsPopUpDelete extends Vue {
  loading = false

  // Vuex module getter
  @VuexGetter('nAffiliateOffers') getSteamDeletePopUpStatus!: boolean

  @VuexGetter('nAffiliateOffers') getStreamsCurrentItem!: StreamAppModelType

  // Watch
  @Watch('getSteamDeletePopUpStatus')
  getSteamDeletePopUpStatusWatch(): void {
    if (!this.getSteamDeletePopUpStatus) {
      this.loading = false
    }
  }

  // Emit
  @Emit('streamDelete')
  streamDelete(): number {
    this.loading = true
    return this.getStreamsCurrentItem.id
  }

  @Emit('closePopUp')
  closePopUp(): boolean {
    return false
  }
}

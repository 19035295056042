





















































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import {
  tableHeadersType,
  digitalStatusesType,
  physicalStatusesType,
  tableNameType,
} from '../types'

@Component({})
export default class AccrualsTable extends Vue {
  moment: any

  // Vuex getters
  @VuexGetter('nBusinessAccruals') getMoneyIncomesListItems!: []

  @VuexGetter('nBusinessAccruals') getMoneyIncomesListLoading!: boolean

  @VuexGetter('nBusinessAccruals') getMoneyIncomesListParams!: any

  // Computed
  get headers(): tableHeadersType[] {
    return [
      { text: this.$t('transactions.transactionNumber'), value: 'paymentId' },
      { text: this.$t('dateOfAccrual'), value: 'created' },
      { text: this.$t('status'), value: 'status' },
      { text: this.$t('title'), value: 'name' },
      { text: this.$t('cost'), value: 'income' },
      { text: this.$t('pageAccurals.header.commisions'), value: 'supster_commission' },
      { text: this.$t('pageAccurals.header.affiliateProgram'), value: 'affilate_bonus' },
      { text: this.$t('pageAccurals.header.total'), value: 'amount' },
    ]
  }

  get tableDigitalStatuses(): digitalStatusesType[] {
    return [
      {
        value: 1,
        name: this.$t('accrualsPage.digitalStatus.1'),
      },
      {
        value: 2,
        name: this.$t('accrualsPage.digitalStatus.2'),
      },
      {
        value: 3,
        name: this.$t('accrualsPage.digitalStatus.3'),
      },
      {
        value: 4,
        name: this.$t('accrualsPage.digitalStatus.4'),
      },
    ]
  }

  get tablePhysicalStatuses(): physicalStatusesType[] {
    return [
      {
        value: 1,
        name: this.$t('inProcessing'),
      },
      {
        value: 2,
        name: this.$t('transactions.status.6'),
      },
      {
        value: 3,
        name: this.$t('transactions.status.4'),
      },
    ]
  }

  get tableName(): tableNameType[] {
    return [
      {
        value: 'app_subscription',
        name: this.$t('accrualsPage.tableName.1'),
      },
      {
        value: 'app_subscription_renewed',
        name: this.$t('accrualsPage.tableName.2'),
      },
      {
        value: 'app_install',
        name: this.$t('accrualsPage.tableName.3'),
      },
      {
        value: 'supster_commision',
        name: this.$t('accrualsPage.tableName.4'),
      },
      {
        value: 'affilate_bonus',
        name: this.$t('accrualsPage.tableName.5'),
      },
      {
        value: 'referal_bonus',
        name: this.$t('accrualsPage.tableName.6'),
      },
    ]
  }

  // Emit
  @Emit('tableChangePage')
  tableChangePage(page: number): number {
    return page
  }
}

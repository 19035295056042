
import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { Bar } from 'vue-chartjs'
import moment from 'moment'

@Component({
  extends: Bar,
  components: {},
})
export default class CommonBarChart extends Vue {
  renderChart: any

  chartData: any = {
    labels: null,
  }

  options = {
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          return `${tooltipItem.value}%`
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        right: 12,
        top: 12,
        bottom: 12,
        left: 12,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 5,
            callback(value, index, ticks) {
              return `${value}%`
            },
          },
          gridLines: {
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  }

  @Prop({ required: true, type: String }) readonly datasetKey!: any

  // Vuex Getters

  @VuexGetter('nBusinessAnalytics') getCommonData!: any

  // Methods
  update() {
    this.chartData.labels = this.getCommonData.by_day.map((p) => moment(p.date).format('DD.MM.YY'))

    this.chartData.datasets = []

    this.chartData.datasets.push({
      data: this.getCommonData.by_day.map((z) => z[this.datasetKey]).reverse(),
      borderWidth: 2,
      backgroundColor: '#27C793',
      borderColor: '#27C793',
    })

    this.renderChart(this.chartData, this.options)
  }

  // Watch
  @Watch('getCommonData')
  getCommonDataHandler(): void {
    this.update()
  }

  // Hooks
  mounted() {
    if (this.getCommonData) {
      this.update()
    }
  }
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import LocalizationServise from '@/services/LocalizationService'

Vue.use(VueI18n)

export default new VueI18n({
  locale: LocalizationServise.initLocale(),
  fallbackLocale: LocalizationServise.initFallbackLocale(),
  messages: LocalizationServise.loadLocaleMessages(),
})

































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexMutation } from '@/store/decorators'
import OffersListFilter from '@/views/affiliate/offers/offersList/components/OffersListFilter.vue'
import OffersListTable from '@/views/affiliate/offers/offersList/components/OffersListTable.vue'
import { filterDataType } from '@/views/affiliate/offers/types'

@Component({
  components: {
    OffersListFilter,
    OffersListTable,
  },
})
export default class OffersList extends Vue {
  // Data
  isPreloaderShow = false

  // Vuex module action
  @VuexAction('nAffiliateOffers') offersGetItems!: () => Promise<void>

  @VuexAction('nAffiliateOffers') offerTotalGetItems!: () => Promise<void>

  @VuexAction('nAffiliateOffers') appsHasOfferGetItems!: () => Promise<void>

  @VuexAction('nAffiliateOffers') offerCategoriesGetItems!: () => Promise<void>

  // Vuex module mutation
  @VuexMutation('nAffiliateOffers') offersParamsUpdate!: (...args) => void

  @VuexMutation('nAffiliateOffers') offersTotalParamsUpdate!: (...args) => void

  @VuexMutation('nAffiliateOffers') offerCategoriesParamsUpdate!: (...args) => void

  // Methods
  tableChangePage(page: number): void {
    this.offersParamsUpdate({ page })
    this.offersGetItems()
  }

  async filter(filterData: filterDataType): Promise<void> {
    this.offersParamsUpdate({ ...filterData, page: 1 })
    this.offersGetItems()
    this.offersTotalParamsUpdate({
      app: filterData.app,
      offerCategory: filterData.offerCategory,
      countries: filterData.countries,
    })
    this.offerTotalGetItems()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    this.offerCategoriesParamsUpdate({ 'exists[parent]': false })
    this.offerCategoriesGetItems()
    this.appsHasOfferGetItems()
    this.offersParamsUpdate({ status: 1 })
    await this.offersGetItems()
    await this.offerTotalGetItems()
    this.isPreloaderShow = false
  }
}

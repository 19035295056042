




































import { Component, Vue, Watch } from 'vue-property-decorator'
import { VuexGetter, VuexMutation } from '@/store/decorators'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'

@Component({})
export default class Step1 extends Vue {
  // Data
  formData = {
    name: '',
    designType: 1,
    isFixedPromoPageChanges: false,
  }

  // vuex module mutation
  @VuexMutation('nTariffs') setFormData!: (...args) => void

  // vuex module getter
  @VuexGetter('nTariffs') getFormData!: any

  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  // Watch
  @Watch('formData', { deep: true })
  formDataHandler(): void {
    this.setFormData(this.formData)
  }

  mounted(): void {
    this.formData.name = this.getFormData?.name ?? ''
    this.formData.designType = this.getFormData?.designType ?? 1
  }
}

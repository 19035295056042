// import NotificationProvider from '@/providers/NotificationProvider'
import { WebsocketType, handlerType } from '@/services/WebsocketService'
import BaseProvider from './BaseProvider'

class WebsocketProvider extends BaseProvider {
  private websocketService!: WebsocketType

  private actionsList = {
    channelMessageCreated: () => {
      return (ctx) => {
        // console.log('message created', ctx)
        const signalData = ctx.entities[0]
        const name = signalData?.client.name || `#${signalData.client_id}`

        this.$store.dispatch('nChats/updateDialogByCreatedNotify', {
          appId: this.$store.getters['nApp/getCurrentApp'].id,
          channelId: signalData.channel.id,
          message: signalData,
        })

        if (this.$router.currentRoute.name === 'Dialogs') {
          this.$store.dispatch('nChats/clientsListUpdateByNotify', signalData.client)
        }
        if (this.$store.getters['nApp/getAuthClient'].id !== signalData?.client?.id) {
          this.app_notify(
            signalData.body,
            name,
            `${signalData.id}`,
            signalData.client.id,
            signalData.channel.id
          )
        }
      }
    },
    channelMessageRemoved: () => {
      return (ctx) => {
        // console.log('message removed', ctx)
        const signalData = ctx.entities[0]
        this.$store.dispatch('nChats/updateDialogByRemovedNotify', {
          appId: this.$store.getters['nApp/getCurrentApp'].id,
          channelId: signalData.channel.id,
          message: signalData,
        })
      }
    },
    channelMessageUpdated: () => {
      return (ctx) => {
        // console.log('message updated', ctx)
        const signalData = ctx.entities[0]
        this.$store.dispatch('nChats/updateDialogByUpdatedNotify', {
          appId: this.$store.getters['nApp/getCurrentApp'].id,
          channelId: signalData.channel.id,
          message: signalData,
        })
      }
    },
    channelMessageRead: () => {
      return (ctx) => {
        // console.log('message read', ctx)
        const signalData = ctx.entities[0]
        if (this.$store.getters['nApp/getAuthClient'].id !== signalData?.client?.id) {
          this.$store.dispatch('nChats/clientsListReadByNotify', { message: signalData })
        }
      }
    },
  }

  // TODO ВРЕМЕННЫЙ КОСТЫЛЬ! ОБЯЗАТЕЛЬНО ПЕРЕДЕЛАТЬ В СКОРОМ ВРЕМЕНИ  https://supsterme.atlassian.net/browse/SUP-2736
  public app_notify: any = (data) => {
    return '1'
  }

  public registerService(service: WebsocketType): void {
    this.websocketService = service
  }

  private getAction(name: string) {
    return this.actionsList[name]()
  }

  public addActionToHandler(handler: handlerType) {
    this.websocketService.addHandler(handler)
  }

  public async initBaseActions() {
    await this.websocketService.init()
    if (this.websocketService) {
      this.addActionToHandler({
        event_type: 'created',
        entity_type: 'channel_message',
        action: this.getAction('channelMessageCreated'),
      })
      this.addActionToHandler({
        event_type: 'updated',
        entity_type: 'channel_message',
        action: this.getAction('channelMessageUpdated'),
      })
      this.addActionToHandler({
        event_type: 'removed',
        entity_type: 'channel_message',
        action: this.getAction('channelMessageRemoved'),
      })
      this.addActionToHandler({
        event_type: 'read',
        entity_type: 'channel_message',
        action: this.getAction('channelMessageRead'),
      })
    }
  }
}

export default new WebsocketProvider()

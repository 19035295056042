import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'

interface RequestParamsTypes {
  app?: [] | [string] | string | number
  name?: [] | [string] | string | number | null
  productCategory?: [] | [string] | string | number | null
  serviceCategory?: [] | [string] | string | number | null
  page?: string | number
  pages?: string | number
  itemsPerPage?: string | number
  pagination?: string | number
  status?: number | null
}

interface ProductsModelAppTypes {
  id?: number
  mainImage?: string
  properties: [] | [string]
  country: string
  brand: string
  tnvedCode: string
  vendorCode: string
  barcode: string
  keyWords: string
  name: string
  description: string
  delivery: string
  productCategory?: string
  serviceCategory?: string
  media_objects?: [] | [string]
  price: string
  oldPrice: string
  app: string | number
  status: number
  nds: number
  type: number
}
interface ProductsModelApiTypes {
  id?: number
  mainImage?: string
  properties: [] | [string]
  country: string
  brand: string
  tnvedCode: string
  vendorCode: string
  barcode: string
  keyWords: string
  name: string
  description: string
  delivery: string
  productCategory: string
  serviceCategory: string
  media_objects?: [] | [string]
  price: string
  oldPrice: string
  app: string
  status: number
  nds: number
  type: number
}

class ProductsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.products')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ProductsModelAppTypes = {
    properties: [],
    country: '',
    brand: '',
    tnvedCode: '',
    vendorCode: '',
    barcode: '',
    keyWords: '',
    name: '',
    description: '',
    delivery: '',
    productCategory: '',
    serviceCategory: '',
    mainImage: '',
    media_objects: [],
    price: '',
    oldPrice: '',
    app: 0,
    status: 2,
    nds: 0,
    type: 1,
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PRODUCTS)
  }

  public getEmptyItem(): ProductsModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(incomingData): ProductsModelApiTypes {
    const data = cloneDeep(incomingData)

    delete data.app
    delete data.country
    delete data.brand
    delete data.tnvedCode
    delete data.productCategory
    delete data.serviceCategory
    delete data.media_objects
    delete data.mainImage
    delete data.nds
    delete data.price
    delete data.oldPrice

    if ('app' in incomingData) {
      data.app = `${ENDPOINTS.APPS}/${incomingData.app.id}`
    }
    if ('country' in incomingData && incomingData.country) {
      data.country = `${ENDPOINTS.COUNTRIES}/${incomingData.country.id}`
    }
    if ('brand' in incomingData && incomingData.brand) {
      data.brand = `${ENDPOINTS.PRODUCT_BRANDS}/${incomingData.brand.id}`
    }
    if ('tnvedCode' in incomingData && incomingData.tnvedCode) {
      data.tnvedCode = `${ENDPOINTS.PRODUCT_TNVEDS}/${incomingData.tnvedCode.id}`
    }
    if ('productCategory' in incomingData && incomingData.productCategory) {
      data.productCategory = `${ENDPOINTS.PRODUCT_CATEGORIES}/${incomingData.productCategory.id}`
    }
    if ('serviceCategory' in incomingData && incomingData.serviceCategory) {
      data.serviceCategory = `${ENDPOINTS.SERVICE_CATEGORIES}/${incomingData.serviceCategory.id}`
    }
    if ('media_objects' in incomingData) {
      data.media_objects = incomingData.media_objects.map(
        (item) => `${ENDPOINTS.MEDIA_OBJECTS}/${item.id}`
      )
    }
    if ('mainImage' in incomingData) {
      data.mainImage = data.media_objects[0]
    }
    if ('nds' in incomingData && incomingData.nds) {
      data.nds = Number(incomingData.nds.value)
    }
    if ('price' in incomingData && incomingData.price) {
      data.price = incomingData.price.toString()
    }
    if ('oldPrice' in incomingData && incomingData.oldPrice) {
      data.oldPrice = incomingData.oldPrice.toString()
    }

    return data
  }

  public transformDataForApp(data): ProductsModelAppTypes {
    data.oldPrice = data.oldPrice || ''
    data.price = data.price || ''
    return data
  }

  public transformRequestParams(incomingParams: RequestParamsTypes): RequestParamsTypes {
    const params = cloneDeep(incomingParams)

    if (incomingParams.productCategory) {
      params.productCategory = incomingParams.productCategory
    } else {
      delete params.productCategory
    }
    if (incomingParams.app) {
      params.app = incomingParams.app
    } else {
      delete params.app
    }
    if (incomingParams.name) {
      params.name = incomingParams.name
    } else {
      delete params.name
    }
    if (incomingParams.status) {
      params.status = incomingParams.status
    } else {
      delete params.status
    }
    return params
  }
}
export default new ProductsService()

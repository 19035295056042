<template>
  <v-form ref="form" v-model="isValidForm" lazy-validation @submit.prevent="handleSubmit">
    <h2 class="mb-8 auth-form-title">{{ $t('registration') }}</h2>
    <div v-if="step === 1">
      <div>
        <auth-input
          id="email"
          :label="$t('email')"
          :rules="emailRules"
          name="email"
          type="email"
          @input="emailRules = []"
          v-model="form.email"
          :toLowerCase="true"
        />
        <auth-input
          id="email"
          label="Telegram"
          :rules="telegramRules"
          name="telegram"
          @input="telegramRules = []"
          v-model="form.telegram"
        />
        <auth-input
          id="name"
          :label="$t('name')"
          :rules="nameRules"
          name="name"
          type="text"
          @input="nameRules = []"
          v-model="form.name"
        />
        <auth-input
          id="password"
          :label="$t('password')"
          :rules="passwordRules"
          name="password"
          type="password"
          @input=";(passwordRepeatRules = []), (passwordRules = [])"
          v-model="form.password"
        />
        <auth-input
          id="passwordRepeat"
          :label="$t('passwordRepeat')"
          :rules="passwordRepeatRules"
          name="passwordRepeat"
          type="password"
          @input=";(passwordRepeatRules = []), (passwordRules = [])"
          v-model="form.passwordRepeat"
        />
        <app-phone-input
          required
          ref="phone"
          type="registration"
          :label="$t('phone')"
          @input="phoneRules = []"
          v-model="form.phone"
          :rules="phoneRules"
        />
        <!-- <auth-input
          id="phone"
          :label="$t('phone')"
          :rules="phoneRules"
          v-mask="'+############'"
          name="phone"
          type="text"
          @input="phoneRules = []"
          v-model="form.phone"
        /> -->
      </div>
      <div>
        <div class="pt-8 d-flex align-center justify-space-between mb-8">
          <router-link
            :to="{ name: 'auth.login' }"
            class="d-flex align-center text-accent text-small text-decoration-none"
          >
            <img :src="require('@/assets/images/back-to-login.svg')" class="mr-2" />
            <span class="text-xsmall text-accent-light">{{ $t('backToLogin') }}</span>
          </router-link>
          <v-btn
            :disabled="!isValidForm"
            class="rounded-sm bg-accent-light text-hovered"
            dark
            type="submit"
            rounded
            :min-width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 146 : 158"
          >
            <span class="primary--text text-base">{{ $t('next') }}</span>
          </v-btn>
        </div>

        <div class="text-center text-white text-xsmall">
          {{ $t('registrationPolicy.1') }}
          <router-link
            target="_blank"
            class="font-weight-bold text-green-dark"
            :to="{
              name: 'Documents',
              params: {
                slug: privacyDocId,
              },
            }"
          >
            {{ $t('registrationPolicy.2') }}
          </router-link>
          <br />
          {{ $t('registrationPolicy.3') }}
          <router-link
            target="_blank"
            class="font-weight-bold text-green-dark"
            :to="{
              name: 'Documents',
              params: {
                slug: userAgreementDocId,
              },
            }"
          >
            {{ $t('registrationPolicy.4') }}
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="step === 2">
      <h3 class="mb-8 auth-form-title text-green-dark text-24">
        {{ $t('emailConfirm') }}
      </h3>
      <div class="view-phone-block py-2 px-4 mb-6">
        <span class="phone-value text-white">{{ form.email }}</span>
        <span class="change-phone-btn text-green-dark" @click="goPrevStep">
          <i class="change-phone-icon"></i>
          <b class="change-phone-btn-value text-green-dark ml-1">{{ $t('changeEmail') }}</b>
        </span>
      </div>
      <span class="auth-label d-flex justify-space-between align-center text-white pl-1">
        {{ $t('emailConfirmInfo') }}
      </span>

      <auth-input
        id="confirmPhoneCode"
        name="confirmPhoneCode"
        type="confirmPhoneCode"
        :rules="confirmPhoneCodeRules"
        @input="confirmPhoneCodeRules = []"
        v-model="form.confirmPhoneCode"
        :placeholder="$t('phoneLastNumber')"
        :toLowerCase="true"
        class="input-confirm my-4 mb-2"
      />
      <span v-if="currentTime" class="repeat-send-sms text-white pl-1 mb-2">
        {{ $t('registrationRepeatSendSms') }} {{ currentTime }} {{ $t('seconds') }}
      </span>
      <span
        @click="sendFormDataForGetConfirmCode"
        v-else
        class="repeat-send-sms pointer text-green-dark pl-1 mb-2"
        >{{ $t('registrationResendSms') }}</span
      >
      <div>
        <div class="pt-4 d-flex align-center justify-space-between">
          <router-link
            :to="{ name: 'auth.login' }"
            class="d-flex align-center text-green-dark text-small text-decoration-none"
          >
            <img :src="require('@/assets/images/back-to-login.svg')" class="mr-2" />
            <span class="text-xsmall text-green-dark">{{ $t('backToLogin') }}</span>
          </router-link>
          <v-btn
            :disabled="!isValidForm"
            class="rounded-sm bg-accent-light text-hovered"
            dark
            type="submit"
            rounded
            :loading="!isLoaded"
            :min-width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 146 : 158"
          >
            <span
              @click="confirmPhoneCodeRules = [rules.required]"
              class="primary--text text-base"
              >{{ $t('register') }}</span
            >
          </v-btn>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { rules } from '@/services/auth'
import AuthInput from '@/views/auth/components/AuthInput.vue'
import { getInstanceWithToken } from '@/utils/axios'
import AuthProvider from '@/providers/AuthProvider'

export default Vue.extend({
  name: 'Register',
  components: { AuthInput },
  data: () => ({
    step: 1,
    form: {
      email: '',
      telegram: '',
      name: '',
      password: '',
      passwordRepeat: '',
      phone: '',
      confirmPhoneCode: '',
    },
    isLoaded: true,
    isValidForm: true,
    rules,
    confirmPhoneSecret: '',
    emailRules: [],
    telegramRules: [],
    nameRules: [],
    passwordRules: [],
    passwordRepeatRules: [],
    phoneRules: [],
    confirmPhoneCodeRules: [],
    startTimer: false,
    currentTime: 60,
    timer: null,
    checkChangedEmail: '',
  }),
  watch: {
    startTimer(value) {
      if (value) {
        this.timer = setTimeout(() => {
          this.currentTime--
        }, 1000)
      }
    },
    currentTime() {
      if (this.currentTime > 0 && this.startTimer) {
        this.timer = setTimeout(() => {
          this.currentTime--
        }, 1000)
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.form.password === this.form.passwordRepeat ||
        this.form.passwordRepeat.length === 0 ||
        this.$t('passwordsDontMatch')
      )
    },
    privacyDocId() {
      if (this.getCurrentCountry.country_code === 'RU') {
        return 'russia-politica-confidencialnosty-ru'
      }
      if (this.getCurrentCountry.country_code === 'IN') {
        return 'india-privacy-policy-en'
      }
      return 'world-privacy-policy-en'
    },
    userAgreementDocId() {
      if (this.getCurrentCountry.country_code === 'RU') {
        return 'russia-polzovatelskoe-soglashenie-ru'
      }
      if (this.getCurrentCountry.country_code === 'IN') {
        return 'india-user-agreement-en'
      }
      return 'world-user-agreement-en'
    },
    ...mapGetters('nApp', ['getReferralUserId', 'getCurrentCountry']),

    ...mapGetters('nDeskbook', ['getListCountries']),
  },
  methods: {
    ...mapActions('auth', ['authorize', 'getUserMe']),
    ...mapActions('nDeskbook', ['fetchCountries']),
    ...mapActions('nApp', ['authLogin', 'authRegister']),
    ...mapMutations('snackbar', ['callSnackbar']),

    goPrevStep() {
      this.checkChangedEmail = this.form.email
      // this.form.confirmPhoneCode = ''
      this.step = 1
    },

    async sendFormDataForGetConfirmCode() {
      const data = {
        phone: this.form.phone,
        email: this.form.email,
        telegram: this.form.telegram,
        name: this.form.name,
        password: this.form.password,
      }
      const refferal = this.getReferralUserId
      if (refferal) {
        data.ref = refferal
      }
      const response = await getInstanceWithToken().post(`/api/phone_confirm_data`, {
        data,
        type: 1,
      })
      this.confirmPhoneSecret = response.data.secret
      this.$refs.form.resetValidation()
      this.callSnackbar({
        color: 'success',
        text: this.$t('allertMessage.smsConfirmSuccess'),
      })
      clearTimeout(this.timer)
      this.currentTime = 60
      this.startTimer = true

      this.step = 2
      return response
    },
    async handleSubmit() {
      this.isLoaded = false
      this.emailRules = [rules.required, rules.email]
      this.telegramRules = [rules.required, rules.telegramLength, rules.engLetterAndNumbers]
      this.nameRules = [rules.required, rules.nameLength]
      this.passwordRules = [
        rules.required,
        rules.passwordLength,
        this.passwordConfirmationRule,
        rules.trimSpaces,
      ]
      this.passwordRepeatRules = [
        rules.required,
        rules.passwordLength,
        this.passwordConfirmationRule,
        rules.trimSpaces,
      ]

      if (this.step === 1) {
        await this.$refs.phone.validate()
      }
      await this.$refs.form.validate()

      if (!this.isValidForm) {
        return
      }
      if (this.step === 1) {
        if ((await this.$refs.phone.validate()) === false) {
          return
        }
      }

      if (this.step === 1) {
        try {
          if (this.checkChangedEmail !== this.form.email) {
            await this.sendFormDataForGetConfirmCode()
          } else {
            this.step = 2
          }
        } catch (error) {
          if (error.response.data.message.includes('email')) {
            this.emailRules.push(this.$t('valiationMessages.emailAlreadyUse'))
          } else if (error.response.data.message.includes('phone')) {
            this.phoneRules.push(this.$t('valiationMessages.phoneAlreadyUse'))
          } else if (error.response.data.message.includes('occurred ')) {
            this.phoneRules.push(this.$t('valiationMessages.noValidPhone'))
          } else {
            this.callSnackbar({
              color: 'red',
              text: `status: ${error?.response?.status}, ${error?.response?.data?.message}`,
            })
          }
          await !this.$refs.phone.validate()
          await this.$refs.form.validate()
        }
      } else if (this.step === 2) {
        try {
          const data = {
            secret: this.confirmPhoneSecret,
            code: this.form.confirmPhoneCode,
            type: 1,
          }
          const response = await this.authRegister(data)

          if (response.status) {
            if (AuthProvider.isGptMode()) {
              const tokens = {
                API_TOKEN: AuthProvider.getToken('crm'),
                CHAT_TOKEN: AuthProvider.getToken('pwa'),
              }
              console.log(tokens)
              window.parent.postMessage(tokens, '*')
              await Promise.all([AuthProvider.clearDataForGPT()])
              return
            }
            this.$router.push({ name: 'Business_Home' })
          } else {
            this.confirmPhoneCodeRules.push(this.$t('valiationMessages.noValidConfirmCode'))
            await this.$refs.form.validate()
          }
        } catch (error) {
          this.confirmPhoneCodeRules.push(this.$t('valiationMessages.noValidConfirmCode'))
          await this.$refs.form.validate()
        }
      }
      this.isLoaded = true
    },
  },
  async created() {
    if (!this.getListCountries.length) {
      await this.fetchCountries()
    }
  },
})
</script>

<style lang="scss">
.auth-button {
  color: var(--v-primary-base) !important;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0 !important;
  text-transform: unset;
  border-radius: 20px !important;
}

.phone-value {
  display: block;
  width: 196px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.auth-form-title {
  color: var(--v-white-base);
  font-weight: 800;
}

.input-confirm {
  .v-input {
    margin-bottom: 8px !important;
    input::placeholder {
      color: #fff !important;
    }
  }
}

.repeat-send-sms {
  display: inline-block;
  color: #fff;
  font-size: 12px;

  &._success {
    color: var(--accent-green-light);
    cursor: pointer;
  }
}

.view-phone-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed rgb(255 255 255 / 28%);
  border-radius: 8px;
  .change-phone-btn {
    display: flex;
    color: var(--accent-green);
    font-size: 12px;
    cursor: pointer;
  }
  .change-phone-icon {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    color: var(--v-primary-base);
    font-size: 22px;
    font-style: normal;
    background: var(--accent-green);
    border-radius: 90px;
    &:after {
      position: absolute;
      top: -1px;
      left: 0;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      content: '<';
    }
  }
}
</style>

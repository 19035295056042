





























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { PayoutSystemsApiModelType } from '@/services/api/PayoutSystems/PayoutSystemsType'

@Component({})
export default class PayoutSystems extends Vue {
  // Props
  @Prop({ type: Array }) readonly payoutSystems!: PayoutSystemsApiModelType[]

  // Emits
  @Emit()
  input(value: number): number {
    return value
  }
}

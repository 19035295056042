







































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import { MoneyOutcomesModelAppType } from '@/services/api/MoneyOutcomes/MoneyOutcomesTypes'

import moment from 'moment'
import MoneyOutcomeTableColStatus from './components/MoneyOutcomeTableColStatus.vue'
import MoneyOutcomePopupForm from './components/MoneyOutcomePopupForm.vue'

@Component({
  components: {
    MoneyOutcomeTableColStatus,
    MoneyOutcomePopupForm,
  },
})
export default class Finances extends Vue {
  $refs!: {
    appDataTable: HTMLInputElement | any
  }

  // Vuex
  @VuexAction('nAffiliateFinances') moneyOutcomesGetItems!: () => Promise<boolean>

  @VuexAction('nAffiliateFinances') payoutAccountsGetItems!: () => Promise<boolean>

  @VuexMutation('nAffiliateFinances') moneyOutcomesListParamsUpdate!: (...args) => void

  @VuexGetter('nApp') getBalance!: any

  @VuexGetter('nApp') getAuthUser!: any

  @VuexGetter('nAffiliateFinances') getMoneyOutcomesListLoading!: boolean

  @VuexGetter('nAffiliateFinances') getMoneyOutcomesListParams!: any

  @VuexGetter('nAffiliateFinances') getMoneyOutcomesStatuses!: [{ value: number; name: string }]

  @VuexGetter('nAffiliateFinances') getMoneyOutcomesListItems!: [MoneyOutcomesModelAppType] | []

  // Data

  tableFilter: {
    created: string[] | []
    status: number | null
    requisites: string | null
    app?: number
    user?: number
    type?: number
  } = {
    created: [],
    status: null,
    requisites: null,
    type: 2,
  }

  dialog = false

  moment: any

  comment = ''

  getCurrencySymbol: any

  isPreloaderShow = false

  // Computed
  get pageTable(): {
    items: [MoneyOutcomesModelAppType] | []
    params: any
    loading: boolean
    settings: any
  } {
    return {
      items: this.getMoneyOutcomesListItems,
      params: this.getMoneyOutcomesListParams,
      loading: this.getMoneyOutcomesListLoading,
      settings: this.pageTableSettings,
    }
  }

  get pageTableSettings(): any {
    return {
      headers: [
        { text: this.$t('date'), value: 'created', width: '150px' },
        { text: this.$t('amount'), value: 'amount' },
        { text: this.$t('payoutAmount'), value: 'payoutAmount' },
        { text: this.$t('finances.payoutSystem'), value: 'payoutSystem' },
        { text: this.$t('finances.requisites'), value: 'requisites' },
        { text: this.$t('status'), value: 'status' },
        { text: this.$t('comment'), value: 'comment' },
      ],
    }
  }

  get balanceAvaible(): any {
    return this.getBalance?.balance?.affiliate?.available || []
  }

  get balanceHold(): any {
    return this.getBalance?.balance?.affiliate?.hold || []
  }

  get balancePaidOut(): any {
    return this.getBalance?.balance?.affiliate?.paid_out || []
  }

  get balanceData(): any {
    const available = this.getBalance?.balance?.affiliate?.available || []
    const hold = this.getBalance?.balance?.affiliate?.hold || []
    const paidOut = this.getBalance?.balance?.affiliate?.paid_out || []

    if (this.getBalance?.affiliate.length) {
      const baseCurrencies = this.getBalance.affiliate
      const result = baseCurrencies.map((item) => {
        return {
          hold: this.getTypeBalanceAmount(hold, item),
          paidOut: this.getTypeBalanceAmount(paidOut, item),
          available: this.getTypeBalanceAmount(available, item),
          currency: item.currency,
        }
      })
      return result
    }
    return []
  }

  // Methods
  getTypeBalanceAmount(findAmount, balance): number | string {
    const result = findAmount.filter((item) => item?.currency.id === balance.currency.id)
    if (result.length > 0) {
      return result[0].amount
    }
    return 0
  }

  reloadAfterSubmit(): void {
    this.tableLoadItems()
  }

  tableChangePage(page: number): void {
    this.moneyOutcomesListParamsUpdate({ page })
    this.tableLoadItems()
  }

  tableFilterUpdate(): void {
    this.moneyOutcomesListParamsUpdate({ ...this.tableFilter, page: 1 })
    this.$refs.appDataTable.resetPagination()
    this.tableLoadItems()
  }

  async tableLoadItems(): Promise<void> {
    this.tableFilter.user = this.getAuthUser?.id
    this.moneyOutcomesListParamsUpdate(this.tableFilter)
    await this.moneyOutcomesGetItems()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    this.tableFilter.created = [
      moment().subtract(13, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ]
    this.moneyOutcomesListParamsUpdate({ ...this.tableFilter, page: 1 })
    await this.payoutAccountsGetItems()
    await this.tableLoadItems()
    this.isPreloaderShow = false
  }

  showCurrency(item) {
    if (item.payoutAccount.payoutSystem.code === 'usdt_trc20') {
      return 'USDT'
    }
    return item.currency.code || this.getCurrencySymbol
  }
}

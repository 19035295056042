<template>
  <v-form ref="form" v-model="isValidForm" lazy-validation @submit.prevent="handleSubmit">
    <h2 class="mb-8 auth-form-title">{{ $t('authorization') }}</h2>
    <auth-input
      id="login"
      name="login"
      :label="$t('loginLabel')"
      :toLowerCase="true"
      @input=";(passwordRules = []), (loginRules = [])"
      v-model="form.login"
      :rules="loginRules"
      v-mask="checkPhone() ? '###############' : 'X'.repeat(100)"
    />
    <auth-input
      id="password"
      :label="$t('password')"
      :rules="passwordRules"
      :forgotPassLink="true"
      name="password"
      type="password"
      @input=";(passwordRules = []), (loginRules = [])"
      v-model="form.password"
    />
    <div>
      <div class="pt-4 d-flex align-center justify-space-between">
        <router-link
          v-if="isGptMode"
          :to="{ name: 'auth.register' }"
          class="d-flex align-center text-accent text-decoration-none text-xsmall"
        >
          <img :src="require('@/assets/images/register-icon.svg')" class="mr-2" />
          <span class="text-xsmall text-accent-light">{{ $t('registration') }}</span>
        </router-link>

        <a
          v-else
          class="d-flex align-center text-accent text-decoration-none text-xsmall"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdjkuTYhUfEAFX5Cc_e9sDCF1eVX7S2drU7UPogCopghkqQ8A/viewform"
        >
          <img :src="require('@/assets/images/register-icon.svg')" class="mr-2" />
          {{ $t('registration') }}
        </a>
        <v-btn
          :disabled="!isValidForm"
          :loading="!isLoaded || isBtnLoading"
          dark
          :min-width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 146 : 158"
          type="submit"
          class="rounded-sm bg-accent-light text-hovered"
        >
          <span class="primary--text text-base">{{ $t('login') }}</span>
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { rules } from '@/services/auth'
import AuthInput from '@/views/auth/components/AuthInput.vue'
import AuthProvider from '@/providers/AuthProvider'

export default Vue.extend({
  name: 'Login',
  components: { AuthInput },
  data: () => ({
    isLoaded: true,
    isValidForm: true,
    form: {
      login: '',
      password: '',
    },
    rules,
    loginRules: [],
    passwordRules: [],
    isBtnLoading: false,
  }),
  computed: {
    isGptMode() {
      return AuthProvider.isGptMode()
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('nApp', ['authLogin', 'authRegister']),
    ...mapMutations('snackbar', ['callSnackbar']),

    async handleSubmit() {
      await this.validateForm()
      if (!this.isValidForm) {
        return
      }

      this.isLoaded = false
      const data = {
        password: this.form.password,
        email: !this.checkPhone() ? this.form.login : undefined,
        phone: this.checkPhone() ? this.form.login : undefined,
      }
      const response = await this.authLogin(data)
      if (response.status) {
        if (AuthProvider.isGptMode()) {
          const tokens = {
            API_TOKEN: response.data.access_token,
            CHAT_TOKEN: response.data.client_access_token,
          }

          this.isBtnLoading = true
          window.parent.postMessage(tokens, '*')
          await Promise.all([AuthProvider.clearDataForGPT()])
          return
        }
        this.$router.push({ name: 'Business_Home' })
      } else {
        this.loginRules.push(this.$t('loginError1'))
        this.passwordRules.push(this.$t('loginError1'))
        this.$refs.form.validate()
      }

      this.isLoaded = true
    },
    async validateForm() {
      if (!this.checkPhone()) {
        this.loginRules = [rules.required, rules.email]
      } else {
        this.loginRules = [rules.required, rules.phone]
      }
      this.passwordRules = [rules.required, rules.passwordLength]
      await this.$refs.form.validate()
    },
    checkPhone() {
      return /^([0-9+()]{1,})$/.test(this.form.login)
    },
    // TODO Принимаю вродящие данные из билдера
  },
})
</script>

<style lang="scss" scoped>
.auth-button {
  color: var(--v-primary-base) !important;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0 !important;
  text-transform: unset;
  border-radius: 20px !important;
}

.auth-form-title {
  color: var(--v-white-base);
  font-weight: 800;
}
</style>

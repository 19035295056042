<template>
  <div
    class="d-flex align-center justify-space-between rounded px-4 py-1 white flex-grow-1 text-wrap-all"
  >
    <slot></slot>
    <div class="flex-grow-0 ml-2">
      <icon
        data="@/assets/icons/delete-sorting-icon.svg"
        fill="#73649E"
        height="8px"
        width="8px"
        class="pointer"
        @click="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMultipleSelectItem',
}
</script>

<style></style>
























































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexMutation, VuexGetter } from '@/store/decorators'
import { ArticlesAppModelType } from '@/services/api/Articles/ArticlesTypes'
import NewsItem from './components/NewsItem.vue'

@Component({
  components: {
    NewsItem,
  },
})
export default class News extends Vue {
  // Data
  isPreloaderShow = false

  // Vuex module action
  @VuexAction('nAffiliateNews') articlesGetItems!: () => Promise<void>

  // Vuex module mutation
  @VuexMutation('nAffiliateNews') articlesParamsUpdate!: (...args) => void

  // Vuex module getters
  @VuexGetter('nAffiliateNews') getArticlesItems!: ArticlesAppModelType[] | []

  @VuexGetter('nAffiliateNews') getArticlesParams!: any

  @VuexGetter('nAffiliateNews') getArticlesListLoading!: any

  // Methods
  tableChangePage(page: number): void {
    this.articlesParamsUpdate({ page })
    this.articlesGetItems()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.articlesGetItems()
    this.isPreloaderShow = false
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-v-card',[_c('app-data-table',{staticClass:"rounded",attrs:{"items":_vm.getDomainsItems,"headers":_vm.tableHeaders,"loading":_vm.getDomainsListLoading,"pages":_vm.getDomainsParams.pages,"currentPage":_vm.getDomainsParams.page},on:{"input":_vm.tableChangePage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("pageDomainParking.domainStatuses." + (item.status))))+" ")]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created,'DD.MM.YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pr-0"},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"px-4 py-3 _bl pointer",on:{"click":function($event){return _vm.openDeletePopUp(item.id)}}},[_c('icon',{attrs:{"color":"var(--v-primary-base)","data":require("@/assets/icons/delete-sorting-icon.svg")}})],1)])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }


















import Vue from 'vue'
import AuthDefaultContainer from '@/views/auth/components/AuthDefaultContainer.vue'
import AuthProvider from '@/providers/AuthProvider'

export default Vue.extend({
  // eslint-disable-next-line vue/match-component-file-name
  name: 'NoApps',
  components: {
    AuthDefaultContainer,
  },
  methods: {
    async onLogout() {
      await Promise.all([AuthProvider.logout()])

      await this.$router.push({ name: 'auth.login' })
    },
    createGPTApp() {
      window.open(process.env.VUE_APP_GPT_DOMAIN)
    },
  },
})

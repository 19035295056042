import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import i18n from '@/plugins/i18n'

class DeliveryServicesService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.deliveryServices')

  protected ENDPOINTS: EndpointsTypes

  protected api

  constructor() {
    super()
    this.api = HttpApiCrm
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.DELIVERY_SERVICES)
  }

  public transformDataForApi(data) {
    data.user = `/api/users/${data.user}`
    data.workDays = JSON.stringify(data.workDays)
    data.deliveryDelay = Number(data.deliveryDelay)
    data.postcode = Number(data.postcode)
    return data
  }

  public transformDataForApp(data) {
    data.workDays = this.jsonParse(data.workDays)
    data.workDays = Array.isArray(data.workDays) ? data.workDays : []
    return data
  }

  public transformRequestParams(params: unknown): unknown {
    return params
  }
}
export default new DeliveryServicesService()

import { createDecorator } from 'vue-class-component'

export const VuexAction = (moduleName: string): any => {
  return createDecorator((options: any, key: string) => {
    if (!options.methods) {
      options.methods = {}
    }
    options.methods[key] = function wrapperMethod(...args: any[]) {
      return this.$store._actions[`${moduleName}/${key}`][0](...args)
    }
  })
}

export const VuexMutation = (moduleName: string): any => {
  return createDecorator((options: any, key: string) => {
    if (!options.methods) {
      options.methods = {}
    }
    options.methods[key] = function wrapperMethod(...args: any[]) {
      return this.$store._mutations[`${moduleName}/${key}`][0](...args)
    }
  })
}

export const VuexGetter = (moduleName: string, getterName: boolean | string = false): any => {
  return createDecorator((options: any, key: string) => {
    if (!options.computed) {
      options.computed = {}
    }
    if (typeof getterName === 'string') {
      options.computed[key] = function wrapperMethod() {
        return this.$store.getters[`${moduleName}/${getterName}`]
      }
    } else {
      options.computed[key] = function wrapperMethod() {
        return this.$store.getters[`${moduleName}/${key}`]
      }
    }
  })
}

export default {}

<template>
  <div
    ref="select-wrapper"
    class="supster-ui--select__wrapper"
    v-click-outside="close()"
    :style="style"
    :class="{ _disabled: disabled, _error: error, _readonly: readonly }"
  >
    <input-text
      class="_pointer _search-select"
      :class="{ _error: error }"
      :value="search && searchFocus ? searchValue : getModelLabel(selected)"
      :placeholder="placeholder"
      :readonly="!search"
      :height="height"
      :width="width"
      @click="open"
      @input="searchHandler"
      @blur="
        searchFocus = false
        debounceLoading = false
      "
      @focus="searchFocus = true"
    >
      <template v-if="search" v-slot:before>
        <icon class="ml-3 mr-n2" width="14px" data="@/assets/icons/search-icon.svg" />
      </template>
      <template v-slot:after>
        <preloader class="mr-2" v-if="debounceLoading" :size="20" color="#73649e" />
        <icon
          v-if="clearButton && value"
          @click.stop="clearValue"
          class="mr-2"
          data="@/assets/icons/close.svg"
          width="12px"
          height="12px"
        />
        <icon
          class="mr-4"
          data="@/assets/icons/dropdown-arrow.svg"
          width="10px"
          height="6px"
          v-if="!readonly && dropIcon"
        />
      </template>
    </input-text>

    <div
      ref="select-dropdown"
      class="supster-ui--select__dropdown-wrapper"
      :class="[mode, show]"
      v-if="showList"
    >
      <vue-custom-scrollbar class="supster-ui--select__custom-scroll" :settings="scrollbarSettings">
        <input-select-items
          :items="listItems"
          :itemText="itemText"
          :itemExtendedText="itemExtendedText"
          :itemValue="itemValue"
          :treeProp="treeProp"
          :selectProp="selectProp"
          :level="0"
          @selectItem="change"
        />
        <div v-if="!listItems.length" class="supster-ui--select__dropdown-item _empty px-4">
          Нет данных
        </div>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import debounce from 'lodash/debounce'
import InputSelectItems from '@/components/SupsterUI/InputSelectItems.vue'
import i18n from '@/plugins/i18n'

export default Vue.extend({
  name: 'InputSelect',
  components: { InputSelectItems },
  data: () => ({
    mode: '_bottom',
    show: '_close',
    selected: '',
    searchValue: '',
    searchFocus: false,
    scrollbarSettings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
    },
    showList: false,
    debounceLoading: false,
  }),
  props: {
    value: {
      default: '',
    },
    items: {
      default() {
        return []
      },
    },
    selectProp: {
      default: false,
    },
    itemText: {
      default: 'label',
    },
    itemValue: {
      default: 'value',
    },
    placeholder: {
      default: i18n.t('chooseFromSelect'),
    },
    holdValue: {
      default: false,
    },
    width: {
      default: false,
    },
    height: {
      default: false,
    },
    disabled: {
      default: false,
    },
    readonly: {
      default: false,
    },
    error: {
      default: false,
    },
    clearButton: {
      default: false,
    },
    excludeItems: {
      default() {
        return []
      },
    },
    search: {
      default: false,
    },
    dropIcon: {
      default: true,
    },
    itemExtendedText: {
      default: false,
    },
    treeProp: {
      default: false,
    },
  },
  computed: {
    style() {
      const style = {}
      if (this.width) {
        style.width = this.width
      }
      if (this.height) {
        style.height = this.height
      }
      return style
    },
    listItems() {
      if (this.search && this.searchValue.length > 0) {
        return this.treeSearch(this.items, [])
      }
      if (this.excludeItems.length > 0) {
        return this.items.filter((item) => !this.excludeItems.includes(this.getModelValue(item)))
      }
      return this.items
    },
  },
  methods: {
    change(value) {
      if (this.disabled || this.readonly) {
        return
      }
      this.$emit('change', value)
      this.$emit('input', value)
      if (!this.holdValue) {
        this.selected = value
      }
      this.showList = false
      this.searchValue = ''
    },
    pageHeight() {
      const { body } = document
      const html = document.documentElement
      return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      )
    },
    open() {
      if (this.disabled || this.readonly) {
        return
      }
      this.showList = true
      setTimeout(() => {
        const itemWrapper = this.$refs['select-wrapper']
        const itemDropdown = this.$refs['select-dropdown']
        const offsetTopOfPage = itemWrapper.getBoundingClientRect().top + window.scrollY
        const documentHeight = this.pageHeight()
        if (
          itemDropdown.clientHeight + offsetTopOfPage + itemWrapper.clientHeight >=
          documentHeight
        ) {
          this.mode = '_top'
        } else {
          this.mode = '_bottom'
        }
        this.show = '_open'
      }, 30)
    },
    close() {
      return {
        handler: () => {
          this.show = '_close'
          this.showList = false
        },
      }
    },
    treeSearch(items, result) {
      items.filter((item) => {
        if (this.getModelLabel(item).toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0) {
          result.push(item)
          return true
        }
        if (this.treeProp && item[this.treeProp] && item[this.treeProp].length) {
          this.treeSearch(item[this.treeProp], result)
          return true
        }
        return false
      })
      return result
    },
    // eslint-disable-next-line func-names
    searchHandler(eventValue) {
      this.debounceLoading = true
      this.searchValue = eventValue
    },
    getModelLabel(item) {
      if (!item) {
        return ''
      }
      if (this.itemExtendedText) {
        return this.itemExtendedText(item)
      }
      return this.selectProp ? item[this.selectProp][this.itemText] : item[this.itemText]
    },
    getModelValue(item) {
      if (!item) {
        return ''
      }
      return this.selectProp ? item[this.selectProp][this.itemValue] : item[this.itemValue]
    },
    clearValue() {
      this.change(false)
    },
  },
  watch: {
    value(val) {
      this.selected = val || ''
    },
    // eslint-disable-next-line func-names
    searchValue: debounce(function (val) {
      this.debounceLoading = false
      this.$emit('searchHandler', val)
    }, 400),
  },
  created() {
    this.selected = this.value || ''
  },
})
</script>
<style lang="scss"></style>

<template>
  <div class="pa-6 feature" :class="`feature-${size}`">
    <v-row class="justify-space-between align-center ma-0 mb-4">
      <v-col>
        <icon :data="icon"></icon>
      </v-col>
      <v-col class="align-self-start text-right"> </v-col>
    </v-row>
    <v-row v-if="title" class="mb-2">
      <span class="ml-3 f-title">{{ title }}</span>
    </v-row>
    <v-row class="mt-0">
      <span class="ml-3">{{ text }}</span>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AffiliatePageFeature',
  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      required: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
})
</script>

<style lang="scss" scoped>
.feature {
  align-self: start;
  box-sizing: border-box;
  width: 100%;
  max-width: 290px;
  padding: 24px;
  background: white;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  box-shadow: 0px 8px 20px rgba(39, 20, 96, 0.07);
}

.feature-md {
  min-height: 176px;
}

.feature-lg {
  min-height: 196px;
}

.row {
  position: relative;
}

.row > .col {
  &:first-of-type > svg {
    width: 40px;
    height: 40px;
    fill: var(--accent-green);
  }

  &:last-of-type > svg {
    align-self: flex-end;
    justify-self: right;
    text-align: right;
    fill: var(--v-primary-base);
  }
}

.row:last-of-type {
  color: var(--v-primary-base);
  font-size: 12px;
  line-height: 18px;
}

.f-title {
  color: var(--v-primary-base);
  font-weight: bold;
  font-size: 14px;
}
</style>














import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {},
})
export default class ClientsFilter extends Vue {
  @VuexGetter('nChats') getClientsFilter!: any

  @VuexGetter('nChats') getClientsListLoading!: boolean

  changeFilter(e) {
    if (!this.getClientsListLoading) {
      this.$emit('changeFilter', e)
    }
  }
}

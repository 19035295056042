<template>
  <v-form ref="form" v-model="isValidForm" @submit.prevent="handleSubmit">
    <h2 class="mb-8 auth-form-title">{{ $t('restorPassword.restoreAccess') }}</h2>
    <div v-if="step === 0">
      <p class="auth-text my-8">
        {{ $t('restorPassword.inputEmail') }}
      </p>
      <auth-input
        id="email"
        :label="$t('email')"
        :rules="emailRules"
        name="email"
        type="email"
        @input="emailRules = []"
        v-model="form.email"
        :toLowerCase="true"
      />

      <span v-if="startTimer" class="repeat-send-sms pl-1 mb-2"
        >{{ $t('registrationRepeatSendSmsOnEmail') }} {{ currentTime }} {{ $t('seconds') }}</span
      >
    </div>
    <div v-if="step === 1">
      <p v-html="$t('restorPassword.sms.email', { email: form.email })" class="auth-text my-8"></p>
      <auth-input
        id="secret"
        :label="$t('restorPassword.inputCode')"
        :rules="secretRules"
        v-model.trim="form.secret"
        name="secret"
        type="text"
        @input="secretRules = []"
      />
    </div>
    <div v-if="step === 2">
      <p class="auth-text my-8">{{ $t('restorPassword.inputNewPass') }}</p>
      <auth-input
        id="new_password"
        :label="$t('password')"
        :rules="newPasswordRules"
        v-model="form.new_password"
        name="new_password"
        type="password"
        @input="newPasswordRules = []"
      />
    </div>
    <div v-if="step === 3">
      <p class="auth-text my-8">{{ $t('restorPassword.changeSuccess') }}</p>
    </div>
    <div>
      <div class="pt-4 d-flex align-center justify-space-between">
        <router-link
          :to="{ name: 'auth.login' }"
          class="d-flex align-center text-accent text-decoration-none text-small text-hovered"
        >
          <img :src="require('@/assets/images/back-to-login.svg')" class="mr-2" />
          <span class="text-xsmall text-accent-light"> {{ $t('restorPassword.backToEnter') }}</span>
        </router-link>
        <v-btn
          v-if="step !== 3"
          :disabled="!isValidForm || (step === 0 && startTimer)"
          :loading="!isLoaded"
          dark
          rounded
          type="submit"
          class="rounded-sm bg-accent-light text-hovered"
          :min-width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 146 : 158"
        >
          <span class="primary--text text-base"> {{ $t('send') }}</span>
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { rules } from '@/services/auth'
import AuthInput from '@/views/auth/components/AuthInput.vue'
import { getInstance } from '@/utils/axios'
import NotificationProvider from '@/providers/NotificationProvider'

export default Vue.extend({
  name: 'RestorePassword',
  components: { AuthInput },
  data: () => ({
    isLoaded: true,
    isValidForm: true,
    step: 0,
    form: {
      new_password: '',
      id: '',
      email: '',
      secret_key: '',
      secret: '',
      token: '',
    },
    rules,
    emailRules: [],
    secretRules: [],
    newPasswordRules: [],
    startTimer: false,
    currentTime: 60,
    timer: null,
  }),
  computed: {
    ...mapGetters('nDeskbook', ['getListCountries']),
  },
  methods: {
    ...mapActions('auth', ['restorePassword']),
    ...mapMutations('snackbar', ['callSnackbar']),
    ...mapActions('nDeskbook', ['fetchCountries']),
    async handleSubmit() {
      if (this.step === 0) {
        this.emailRules = [rules.required, rules.email]
      } else if (this.step === 1) {
        this.secretRules = [rules.required]
      } else if (this.step === 2) {
        this.newPasswordRules = [rules.required, rules.passwordLength, rules.trimSpaces]
      }

      await this.$refs.form.validate()
      if (!this.isValidForm) {
        return
      }

      this.isLoaded = false
      if (this.step === 0) {
        await getInstance()
          .post(`/api/confirm_tokens`, {
            action: 'change-password',
            type: 2,
            identifier: this.form.email,
          })
          .then((response) => {
            this.step = 1
            this.form.id = response.data.id
            this.form.secret_key = response.data.secret_key
            NotificationProvider.add({
              type: 'success',
              message: this.$t('allertMessage.emailConfirmSuccess'),
            })
          })
          .catch((e) => {
            if (e.response.data.detail === 'Email not found') {
              console.log(e.response)
              this.emailRules = [this.$t('valiationMessages.emailNotFound')]
              this.isLoaded = false
              return
            }
            clearTimeout(this.timer)
            this.currentTime = 60
            this.startTimer = true
          })
      } else if (this.step === 1) {
        await getInstance()
          .post(`/api/confirm_tokens/${this.form.id}/check_secret`, {
            secret: this.form.secret,
            secret_key: this.form.secret_key,
          })
          .then((response) => {
            if (response.data.token) {
              this.step = 2
              this.form.token = response.data.token
            } else {
              this.secretRules.push(this.$t('allertMessage.entryCodeError'))

              this.$refs.form.validate()
            }
          })
          .catch((e) => {
            this.callSnackbar({ color: 'red', text: e })
          })
      } else if (this.step === 2) {
        await getInstance()
          .post(`/api/users/restore_password`, {
            secret_key: this.form.secret_key,
            token: this.form.token,
            token_type: 1,
            new_password: this.form.new_password,
          })
          .then((response) => {
            console.log(response)
            if (response) {
              this.step = 3
            } else {
              this.$refs.form.validate()
            }
          })
          .catch((e) => {
            this.callSnackbar({ color: 'red', text: e })
          })
      }

      this.isLoaded = true
    },
  },
  watch: {
    startTimer(value) {
      if (value) {
        this.timer = setTimeout(() => {
          this.currentTime--
        }, 1000)
      }
    },
    currentTime() {
      if (this.currentTime > 0 && this.startTimer) {
        this.timer = setTimeout(() => {
          this.currentTime--
        }, 1000)
      } else {
        this.startTimer = false
      }
    },
  },
  async created() {
    if (!this.getListCountries.length) {
      await this.fetchCountries()
    }
  },
})
</script>

<style lang="scss" scoped>
.auth-button {
  width: 120px;
  color: var(--v-primary-base) !important;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0 !important;
  text-transform: unset;
  border-radius: 20px !important;
}

.auth-form-title {
  color: var(--v-white-base);
  font-weight: 800;
}

.auth-text {
  display: block;
  color: var(--v-white-base);
  font-size: 14px;
  line-height: 22px;
}
</style>

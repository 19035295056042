import { MediaObjectsModule } from './types'

import actions from './actions'

const MediaObjects: MediaObjectsModule = {
  state: () => ({
    isLoading: false,
  }),
  actions,
  namespaced: true,
}

export default MediaObjects

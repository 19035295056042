<template>
  <article class="page--gas-orders-list">
    <page-header extra>
      <v-row class="align-center">
        <v-col cols="auto">
          <h4 class="d-inline-flex align-center">{{ $t('ordres.myOrders') }}</h4>
        </v-col>
        <v-col>
          <div class="d-flex mr-2 pl-6">
            <v-btn
              class="page--gas-orders-list__btn px-3 mr-xl-3 mr-2 text-primary font-weight-regular text-middle"
              :class="
                pageTable.filter.status === '' ? 'border-grey font-weight-bold bg-white shadow' : ''
              "
              @click="changeFilterOrderStatus('')"
            >
              {{ $t('ordres.allOrders') }}
            </v-btn>

            <v-btn
              class="page--gas-orders-list__btn px-3 mr-xl-3 mr-2 text-primary font-weight-regular text-middle"
              :class="
                pageTable.filter.status === 'new'
                  ? 'border-grey font-weight-bold bg-white shadow'
                  : ''
              "
              @click="changeFilterOrderStatus('new')"
            >
              {{ $t('ordres.newOrders') }}
            </v-btn>
            <v-btn
              class="page--gas-orders-list__btn px-3 mr-xl-3 mr-2 text-primary font-weight-regular text-middle"
              :class="
                pageTable.filter.status === 'delivery_process'
                  ? 'border-grey font-weight-bold shadow bg-white'
                  : ''
              "
              @click="changeFilterOrderStatus('delivery_process')"
            >
              {{ $t('ordres.inProgress') }}
            </v-btn>
            <v-btn
              class="page--gas-orders-list__btn px-3 mr-xl-3 mr-2 text-primary font-weight-regular text-middle"
              :class="
                pageTable.filter.status === 'delivered'
                  ? 'border-grey font-weight-bold bg-white shadow'
                  : ''
              "
              @click="changeFilterOrderStatus('delivered')"
            >
              {{ $t('ordres.delivered') }}
            </v-btn>
            <v-btn
              class="page--gas-orders-list__btn px-3 text-primary font-weight-regular text-middle"
              :class="
                pageTable.filter.status === 'cancel'
                  ? 'border-grey font-weight-bold bg-white shadow'
                  : ''
              "
              @click="changeFilterOrderStatus('cancel')"
            >
              {{ $t('ordres.canceled') }}
            </v-btn>
          </div>
        </v-col>
        <div>
          <v-btn max-height="32" max-width="130" dark color="var(--primary-light)">{{
            $t('exportToCSV')
          }}</v-btn>
        </div>
      </v-row>
    </page-header>
    <card class="mt-4 mb-6 mx-4 pa-8 px-0 py-0">
      <div class="px-6 pt-6">
        <v-row class="mx-n3 align-center pb-6 my-0">
          <v-col class="py-0 px-3" cols="3">
            <app-v-text-field
              v-model="pageTable.data.params.positionsName"
              @change="tableLoadItems"
              :label="$t('searchByName')"
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
          <v-col class="py-0 px-3" cols="9">
            <app-date-picker
              @change="tableLoadItems"
              v-model="pageTable.filter.created"
              type="range"
              filterOut
              :label="$t('pageAffiliateStats.filterLabel.range')"
              initDefault="true"
            />
          </v-col>
        </v-row>
      </div>
      <div class="">
        <data-table
          class="supster-ui--data-table__wrapper"
          ref="pageTable"
          :headers="headers"
          :items="pageTable.data.items"
          :itemsPerPage="pageTable.data.params.itemsPerPage"
          :length="pageTable.data.params.pages"
          @input="tableChangePage"
          :disableSort="true"
          :loading="pageTable.loading"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="py-2">
              <p class="supster-ui__text my-0">ID: {{ item.id }}</p>
              <p class="supster-ui__text _s-12 my-0">
                {{ item.created | moment('DD.MM.YYYY') }} ({{ item.created | moment('hh:mm:ss') }})
              </p>
            </div>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <div class="d-flex py-4 align-center pl-2">
              <div
                v-if="item.positions.length"
                class="product-item-prop-image mr-3"
                :style="{
                  'background-image': `url(${
                    item.positions[0].product.mainImage.preview_url ||
                    item.positions[0].product.mainImage.content_url
                  } )`,
                }"
              ></div>
              <span class="product-item-prop-name" v-if="item.positions.length">
                {{ item.positions[0].product.name }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.sum`]="{ item }"> {{ item.sum | money }} </template>
          <template v-slot:[`item.count`]="{ item }">
            {{ item.count }}
          </template>
          <template v-slot:[`item.client`]="{ item }">
            {{ `${item.recipientName} ${item.recipientSurname}` }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ getOrderStatusName(item) }}
          </template>
          <template v-slot:[`item.confirm`]="{ item }">
            <v-btn
              @click="$router.push(`/business/goods_and_services/orders/${item.id}/confirm`)"
              color="accent"
            >
              {{ $t('confirm') }}
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="com-dropdown-action__wrapper">
              <app-data-table-action-dropdown
                left
                :nudgeLeft="-9"
                :nudgeTop="28"
                backgroundColor="#271460"
                dividerColor="#493B74"
                divider
              >
                <template #activator>
                  <icon
                    class="com-dropdown-action__icon"
                    data="@/assets/icons/dots-vertical.svg"
                    @click="tableItemShowActions(item.id)"
                    :color="pageTable.popup.actions === item.id ? 'var(--accent-green)' : 'primary'"
                    v-click-outside="{
                      handler: () => {
                        pageTable.popup.actions = false
                      },
                    }"
                  />
                </template>
                <template #content>
                  <v-btn
                    class="com-dropdown-action__btn justify-start font-weight-regular"
                    block
                    @click="$router.push(`orders/${item.id}`)"
                    color="primary"
                    tile
                    >{{ $t('orderDetails') }}</v-btn
                  >
                </template>
              </app-data-table-action-dropdown>
            </div>
            <!-- <div class="com-table-dropdown__wrapper _actions relative">
              <div
                class="com-table-dropdown__button _actions"
                :class="{ _active: pageTable.popup.actions === item.id }"
                @click="tableItemShowActions(item.id)"
              >
                <icon
                  data="@/assets/icons/dots-vertical.svg"
                  class="com-table-dropdown__icon ml-2"
                  @click="showActionsList(item.id)"
                />
                <div
                  class="com-table-dropdown__dropped-block _actions"
                  v-if="pageTable.popup.actions === item.id"
                  v-click-outside="{
                    handler: () => {
                      pageTable.popup.actions = false
                    },
                  }"
                >
                  <div class="com-table-dropdown__dropped-inner _actions">
                    <div class="com-table-dropdown__dropped-nav">
                      <div class="com-table-dropdown__dropped-nav-item">
                        <button
                          class="supster-ui--data-table__dropdown-dropped-nav-button"
                          @click="$router.push(`orders/${item.id}`)"
                        >
                          {{ $t('orderDetails') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </template>
        </data-table>
      </div>
    </card>
  </article>
</template>

<script>
import Vue from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTable from '@/components/DataTable.vue'

import OrdersService from '@/services/api/Orders/OrdersService'
import OrderPositionsService from '@/services/api/OrderPositions/OrderPositionsService'

export default Vue.extend({
  name: 'OrdersList',
  components: { PageHeader, DataTable, Card },
  data: () => ({
    pageTable: {
      loading: false,
      data: {
        items: [],
        params: {
          page: 1,
          pages: 1,
          itemsPerPage: 5,
          app: null,
          status: '',
          delivery: '',
          created: null,
          positionsExists: true,
          positionsName: '',
          type: 1,
        },
      },
      filter: {
        status: '',
        created: [],
      },

      popup: {
        actions: false,
      },
    },
    lists: {
      ordersPositions: [],
    },
    forms: {},
  }),
  computed: {
    ...mapGetters('app', { app: 'getSelectedApp' }),
    headers() {
      return [
        { text: this.$t('order'), value: 'id' },
        { text: this.$t('products.product'), value: 'product', width: 'auto' },
        { text: this.$t('cost'), value: 'sum' },
        { text: this.$t('quantity'), value: 'count' },
        { text: this.$t('buyer'), value: 'client' },
        { text: this.$t('orderStatus'), value: 'status' },
        { text: '', value: 'confirm', width: '120px' },
        { text: '', value: 'actions', width: '44px' },
      ]
    },
  },
  methods: {
    ...mapActions('orders', ['loadOrders']),
    ...mapMutations('orders', ['changeOrdersListParams']),

    async tableLoadItems() {
      this.pageTable.loading = true
      this.pageTable.data.params.app = this.app
      this.pageTable.data.params.created = this.pageTable.filter.created
      const orderResponse = await OrdersService.getItems({
        params: this.pageTable.data.params,
      })

      const orders = orderResponse.data
      const ordersId = orders.map((item) => item.id)
      if (ordersId.length) {
        this.lists.ordersPositions = await this.loadOrdersPositions(ordersId)
      }
      this.pageTable.data.items = orders.map((item) => {
        const positions = this.getPositionByOrderId(item.id)
        item.positions = positions.length ? positions : []
        return item
      })

      this.pageTable.data.params.page = orderResponse.params.page
      this.pageTable.data.params.pages = orderResponse.params.pages
      this.pageTable.loading = false
    },
    async loadOrdersPositions(ids) {
      const response = await OrderPositionsService.getItems({
        params: { order: ids },
      })
      return response.status ? response.data : []
    },
    tableChangePage(page) {
      this.pageTable.data.params.page = page
      this.tableLoadItems()
    },
    tableItemShowActions(itemId) {
      setTimeout(() => {
        this.pageTable.popup.actions = itemId
      }, 40)
    },
    getPositionByOrderId(orderId) {
      return this.lists.ordersPositions.filter((item) => item.order.id === orderId)
    },
    getOrderStatusName(order) {
      if (order.status === 1) {
        return this.$t('newby')
      }
      return order.status
    },
    changeFilterOrderStatus(status) {
      this.pageTable.filter.status = status
      switch (status) {
        case 'new':
          this.pageTable.data.params.status = '1'
          this.pageTable.data.params.delivery = ''
          break
        case 'delivery_process':
          this.pageTable.data.params.status = '1'
          this.pageTable.data.params.delivery = '3'
          break
        case 'delivered':
          this.pageTable.data.params.status = '1'
          this.pageTable.data.params.delivery = '4'
          break
        case 'cancel':
          this.pageTable.data.params.status = '0'
          this.pageTable.data.params.delivery = ''
          break
        default:
          this.pageTable.data.params.status = ''
          this.pageTable.data.params.delivery = ''
      }
      this.tableLoadItems()
    },
  },
  // async created() {
  // await this.tableLoadItems()
  // },
  async mounted() {
    await this.tableLoadItems()
  },
})
</script>

<style lang="scss">
.page--gas-orders-list {
  &__btn {
    @media only screen and (max-width: 1600px) {
      font-size: 14px !important;
    }
  }
  .product-item-prop-image {
    display: block;
    width: 44px;
    min-width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid var(--light-gray);
    border-radius: 2px;
    box-shadow: 0 0px 1px 1px white inset;
  }
  .com-table-dropdown {
    &__scroll-area {
      max-height: 170px;
      margin-right: -16px;
      padding-right: 16px;
    }
    &__wrapper {
      &._actions {
        padding-top: 8px;
        padding-bottom: 12px;
        padding-left: 8px;
        border-left: 1px solid var(--light-gray);
      }
      ._active {
        .icon {
          z-index: 7 !important;
        }
        .com-table-dropdown__dropped-block {
          z-index: 6;
        }
      }
    }
    &__dropped-block {
      position: absolute;
      top: 0;
      left: -7px;
      z-index: 4;
      width: 278px;
      max-height: 332px;
      padding: 0px 16px 0px 42px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 8px 20px 0px #2714602e;

      &._actions {
        right: 0;
        left: inherit;
        padding-right: 42px;
        padding-left: 0px;
        overflow: hidden;
        background: var(--v-primary-base);
      }
    }
    &__dropped-count {
      display: block;
      display: flex;
      justify-content: center;
      width: 46.42px;
    }
    &__dropped-inner {
      padding-top: 4px;
      padding-bottom: 16px;
      padding-left: 14px;
      border-left: 1px solid var(--light-gray);

      &._actions {
        padding: 0px;
        border-right: 1px solid #493b74;
        border-left: 0px;
        border-radius: 8px;
      }
    }
    &__icon {
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
    &__button._active {
      z-index: 50;
      .com-table-dropdown__icon {
        z-index: 110;
      }
      &._actions {
        .com-table-dropdown__icon {
          color: var(--accent-green);
        }
      }
    }
    &__dropped-nav-item {
      display: flex;
      border-bottom: 1px solid #493b74;
      &:last-child {
        border-bottom: 0px;
      }
    }
    &__dropped-nav-button {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 40px;
      padding: 0 16px;
      color: #fff;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      border: 0px;
      outline: none;
      &:hover {
        background: #493b74;
      }
    }
    &__dropped-item {
      display: flex;
      align-content: center;
      align-items: center;
      height: 56px;
      border-bottom: 1px solid var(--light-gray);
    }
    &__dropped-input {
      width: 48px;
      height: 32px;
      margin: 0 !important;
      padding: 0 0px !important;
      .supster-ui--textfield__field {
        padding: 0;
        text-align: center;
      }
    }
    &__dropped-text {
      display: block;
      width: 80%;
      font-weight: normal;
      text-align: left;
      &._bold {
        font-weight: bold;
      }
    }
  }
  .product-item-prop-name {
    font-weight: 600;
    word-break: break-all;
  }
}
</style>

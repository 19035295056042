import { getChatInstanceWithToken } from '@/utils/axios'
import { BaseApi2Service, EndpointsTypes } from '@/services/api2/BaseApi2Service'
import ENDPOINTS from '@/services/api2/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { HttpApiPwa } from '@/services/HttpClientService'
import { ChannelMessagesAppModelType, ChannelMessagesApiModelType } from './ChannelMessagesTypes'

class ChennelMessagesService extends BaseApi2Service {
  protected modelName = i18n.t('servicesModelsName.channelsMessage')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ChannelMessagesAppModelType = {}

  private defaultListParams = {
    limit: 20,
    page: 1,
    pages: 1,
    loading: false,
  }

  private usedListParams = ['page', 'limit', 'app_id']

  constructor() {
    super()
    this.api = HttpApiPwa
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.CHANNEL_MESSAGES)
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    return incomingData
  }

  public transformDataForApp(incomingData): ChannelMessagesAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    return params
  }

  public async getItemsByChannel(id, params: any = {}) {
    try {
      const requestUrl = `${ENDPOINTS.CHANNELS}/${id}/channel_messages`
      const requestParams = { params: this.transformRequestParams(params) }

      const response = await this.api.get(requestUrl, requestParams)
      const responseParams = this.calculatePagination({
        total: response.data.message_total,
        limit: params.limit || this.defaultListParams.limit,
        page: params.page,
      })
      return {
        status: true,
        data: response.data.data,
        params: responseParams,
      }
    } catch (error) {
      this.addBadRequestNotification(error, `Ошибка получения записи[${this.modelName}] ${id}!`)
      return { status: false, data: {}, params: {}, error }
    }
  }

  public calculatePagination({ total, limit, page = 1 }) {
    let pages = Math.round(total / limit)
    pages = pages * limit < total ? pages + 1 : pages
    if (page > pages) {
      page = pages
    }
    return { pages, page, total, limit }
  }

  public async readAllByChannel(id) {
    try {
      const requestUrl = `${ENDPOINTS.CHANNELS}/${id}/read_all`
      const response = await this.api.post(requestUrl)
      return {
        status: true,
        data: response.data.data,
        params: {},
      }
    } catch (error) {
      this.addBadRequestNotification(error, `Ошибка прочтения [${this.modelName}] ${id}!`)
      return { status: false, data: {}, params: {}, error }
    }
  }
}
export default new ChennelMessagesService()





















































































































































































































































































































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { VuexAction, VuexGetter } from '@/store/decorators'
import PostsPicker from '@/components/PostsPicker.vue'
import AppPreview from '@/components/appPreview/AppPreview.vue'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { cloneDeep } from 'lodash'
import MediaObjectsService from '@/services/api/MediaObjects/MediaObjectsService'
import NotificationProvider from '@/providers/NotificationProvider'
import { Slider } from 'vue-color'
import AppVTextField from './app/AppVTextField.vue'
import AppVRadioGroup from './app/AppVRadioGroup.vue'

@Component({
  components: { PostsPicker, AppPreview, AppVTextField, AppVRadioGroup, Slider },
})
export default class AppGptSettings extends Vue {
  @Ref('systemPromtAvatarRef') readonly avatar!: HTMLInputElement

  @Ref('appAvatar') readonly appAvatar!: HTMLInputElement

  // Props
  @Prop([String] || String) readonly classList!: string[] | string

  // Data
  dialog = false

  chats = [
    {
      name: 'testname',
      code: 'testcode',
    },
    {
      name: 'testname2',
      code: 'testcode2',
    },
    {
      name: '+ создать новый чат',
      code: 'createNew',
    },
  ]

  mode = ''

  selectedPosts: PostsAppModelType[] = []

  index = 0

  loading = false

  data: {
    avatar: PostsAppModelType | null
    posts: PostsAppModelType[]
  } = {
    avatar: null,
    posts: [],
  }

  editingSystemPromtId = 0

  editingSystemPromt: any = {
    name: '',
    image: null,
    description: '',
    contenxt: '',
    id: -1,
    app: 0,
    emptyBotChatMessage: '',
    prompts: [],
    model: 'gpt-3,5 turbo 16k',
  }

  app: any = {
    avatar: null,
    color: '',
  }

  dataSystemPromts = []

  dataGpt: {
    primaryColor: string
    emptyBotChatLogo: any
    emptyBotChatMessage: string
    prompts: string[]
    botLimit: number | null
    botLimitType: number | null
    model: string
  } = {
    primaryColor: '',
    emptyBotChatLogo: null,
    emptyBotChatMessage: '',
    prompts: [],
    botLimit: null,
    botLimitType: null,
    model: '',
  }

  systemPromtsModels = [
    {
      name: 'Midjourney',
      value: 'midjourney',
    },
    {
      name: 'GPT-3,5 turbo 16k',
      value: '3.5-turbo',
    },
    // {
    //   name: 'GPT-4',
    //   value: '4',
    // },
  ]

  //  Vuex module getters
  @VuexGetter('nApp') getCurrentApp!: any

  @VuexGetter('nApp') getAppPreviews!: PostsAppModelType[]

  @VuexGetter('nApp') getIsGptApp!: boolean

  @VuexAction('nApp') updateApp!: (...args) => Promise<any>

  @VuexAction('nApp') getAppSystemPromts!: (...args) => Promise<any>

  @VuexAction('nApp') createAppSystemPromts!: (...args) => Promise<any>

  @VuexAction('nApp') updateAppSystemPromts!: (...args) => Promise<any>

  get valid(): boolean {
    if (this.mode === 'systemPromts') {
      return (
        this.editingSystemPromt.id >= 0 &&
        this.editingSystemPromt.name.length > 0 &&
        this.editingSystemPromt.context.length > 0
      )
    }
    if (this.getIsGptApp) {
      return (
        !!this.dataGpt.emptyBotChatMessage &&
        !!this.dataGpt.botLimit &&
        this.dataGpt.botLimit > 0 &&
        this.dataGpt.botLimit <= 10
      )
    }
    return true
  }

  get descriptionMaxLenth() {
    const delimiter = '\n'
    if (this.dataGpt.emptyBotChatMessage) {
      const count = this.countOccurrences(this.dataGpt.emptyBotChatMessage, delimiter)
      // return 255 - count * 50
      return this.dataGpt.emptyBotChatMessage.split(delimiter)
      // return count
    }

    return 300
  }

  get systemPromtsSelect(): any[] {
    const data: any = this.dataSystemPromts
    data.unshift({
      name: this.$t('appMediaEdit.chooseChat'),
      image: this.getCurrentApp.emptyBotChatLogo,
      description: '',
      context: '',
      id: -1,
      emptyBotChatMessage: '',
      prompts: [],
    })
    // data = [...data, ]
    data.push({
      name: this.$t('appMediaEdit.createChat'),
      image: this.getCurrentApp.emptyBotChatLogo,
      description: '',
      context: '',
      id: 0,
      emptyBotChatMessage: '',
      prompts: [],
    })

    return this.dataSystemPromts
  }

  get systemPromtAvatarUrl(): string {
    return (
      this.editingSystemPromt?.image?.blob ?? this.editingSystemPromt?.image?.content_url ?? null
    )
  }

  formatDescription(event) {
    const delimiter = '\n'

    // 1. Limit to 255 characters
    if (event.target.value.length > 255) {
      event.target.value = event.target.value.substring(0, 255)
    }
    // 2. Limit line breaks
    let lines = event.target.value.split(delimiter)

    // Ensure no more than 3 line breaks
    lines = lines.slice(0, 5)
    event.target.value = lines.join(delimiter)
  }

  // Methods
  countOccurrences(str, subStr) {
    // Используем регулярное выражение с флагом 'g' для поиска всех совпадений
    const regex = new RegExp(subStr, 'g')
    // Метод match возвращает массив с найденными совпадениями
    const matches = str.match(regex)

    // Если совпадения не найдены, возвращаем 0
    if (!matches) {
      return 0
    }

    // Возвращаем количество совпадений
    return matches.length
  }

  handleEnter(event) {
    const delimiter = '\n'
    const lines = event.target.value.split(delimiter)
    if (lines.length >= 4) {
      event.preventDefault()
    }
  }

  changeSystemPromtChat(id) {
    const index = this.systemPromtsSelect.findIndex((item) => item.id === id)
    this.editingSystemPromt = cloneDeep(this.systemPromtsSelect[index])
    this.editingSystemPromtId = this.editingSystemPromt.id
    this.editingSystemPromt.app = this.getCurrentApp.id
    if (this.editingSystemPromt.emptyBotChatMessage === null) {
      this.editingSystemPromt.emptyBotChatMessage = ''
    }
    if (this.editingSystemPromtId <= 0) {
      this.editingSystemPromt.name = ''
    }
    if (this.editingSystemPromtId === 0) {
      this.editingSystemPromt.model = '3.5-turbo'
    }
  }

  selectPost({ item, index }: { item: PostsAppModelType; index: number }): void {
    this.data.posts.splice(index, 1, item)
    this.selectedPosts.splice(0, 1, item)
    this.index = index
  }

  setDataToEditSystemPromt(id) {
    this.editingSystemPromt = this.systemPromtsSelect.find((item) => item.id === id)
  }

  setNewAvatar(e): void {
    if (e.target.files) {
      this.editingSystemPromt.image = {
        file: e.target.files[0],
        blob: URL.createObjectURL(e.target.files[0]),
      }
    }
  }

  setNewEmptyChatLogoAvatar(e): void {
    if (e.target.files) {
      this.dataGpt.emptyBotChatLogo = {
        file: e.target.files[0],
        blob: URL.createObjectURL(e.target.files[0]),
      }
    }
  }

  setMode(): void {
    this.mode = 'avatar'
  }

  updateColor(e) {
    this.dataGpt.primaryColor = e.hex
  }

  clear(): void {
    this.selectedPosts = []
    this.data.avatar = null
    this.data.posts = cloneDeep(this.getAppPreviews)
    this.setDataForGpt()
  }

  async saveChanges(): Promise<void> {
    this.loading = true
    if (this.mode === 'systemPromts' && this.editingSystemPromtId >= 0) {
      if (this.editingSystemPromt?.image?.blob) {
        const { data } = await MediaObjectsService.createItem({
          params: {},
          data: this.editingSystemPromt.image.file,
        })
        this.editingSystemPromt.image = data
      }
      await this.saveSystemPromts()
      this.loadSystemPromts()
    } else {
      if (this.dataGpt.emptyBotChatLogo?.blob) {
        const { data } = await MediaObjectsService.createItem({
          params: {},
          data: this.dataGpt.emptyBotChatLogo.file,
        })
        this.dataGpt.emptyBotChatLogo = data
      }
      const response = await this.updateApp({
        data: this.dataGpt,
        id: this.getCurrentApp.id,
      })
      if (response.status) {
        NotificationProvider.add({
          type: 'success',
          message: this.$t('allertMessage.updateApp'),
        })
      } else {
        NotificationProvider.add({
          type: 'error',
          message: this.$t('allertMessage.updateAppError'),
        })
      }
    }

    this.editingSystemPromtId = -1
    this.editingSystemPromt.image = this.getCurrentApp.emptyBotChatLogo
    // this.dialog = false
    this.loading = false
  }

  @Watch('selectedPosts')
  selectedPostsHandler(): void {
    if (this.mode === 'avatar' && this.selectedPosts[0]) {
      this.data.avatar = this.selectedPosts[0]
    }
    if (this.mode === 'posts' && this.selectedPosts[0]) {
      this.data.posts.splice(this.index, 1, this.selectedPosts[0])
    }
  }

  @Watch('mode')
  modeHandler(): void {
    this.selectedPosts = []
    if (this.mode === 'avatar' && this.data.avatar) {
      this.selectedPosts[0] = this.data.avatar
    }
  }

  @Watch('dialog')
  dialogHandler(): void {
    if (!this.dialog) {
      this.clear()
    } else {
      this.loadSystemPromts()
      this.changeSystemPromtChat(-1)
      this.setMode()
      this.setDataForGpt()
      this.data.posts = cloneDeep(this.getAppPreviews)
    }
  }

  setDataForGpt(): void {
    this.dataGpt.emptyBotChatMessage = this.getCurrentApp?.emptyBotChatMessage ?? ''
    this.dataGpt.prompts = cloneDeep(this.getCurrentApp?.prompts)
    this.dataGpt.botLimit = this.getCurrentApp?.botLimit ?? null
    this.dataGpt.botLimitType = this.getCurrentApp?.botLimitType ?? null
    this.dataGpt.emptyBotChatLogo = this.getCurrentApp.emptyBotChatLogo ?? ''
    this.dataGpt.primaryColor = this.getCurrentApp.primary_color ?? ''
  }

  async loadSystemPromts() {
    const response = await this.getAppSystemPromts({
      app: this.getCurrentApp.id,
      itemsPerPage: 200,
    })
    this.dataSystemPromts = response.data
  }

  async saveSystemPromts() {
    let response
    if (this.editingSystemPromt.id === 0) {
      response = await this.createAppSystemPromts(this.editingSystemPromt)
    } else {
      response = await this.updateAppSystemPromts(this.editingSystemPromt)
      if (response.status) {
        NotificationProvider.add({
          type: 'success',
          message: this.$t('allertMessage.updateSystemPromptSuccess', { name: response.data.name }),
        })
      } else {
        NotificationProvider.add({
          type: 'error',
          message: this.$t('allertMessage.updateSystemPromptError'),
        })
      }
    }

    return response
  }

  created(): void {
    this.dataGpt.emptyBotChatLogo = this.getCurrentApp.emptyBotChatLogo
    this.dataGpt.primaryColor = this.getCurrentApp.primary_color
    this.changeSystemPromtChat(-1)
  }
}

import { ProductsMutations } from './types'

const mutations: ProductsMutations = {
  setProductsListItems(state, { response }) {
    state.products.list.items = response.data
    state.products.list.params = { ...state.products.list.params, ...response.params }
  },
  productsListChangePage(state, newPage) {
    state.products.list.params.page = newPage
  },
  productsListChangeParams(state, params) {
    state.products.list.params = { ...state.products.list.params, ...params }
  },
  setProductsItem(state, { data }) {
    state.products.item = data
  },
}

export default mutations

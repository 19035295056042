






















import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexMutation } from '@/store/decorators'
import PlacementCreateForm from '@/views/affiliate/tools/placement/components/PlacementCreateForm.vue'
import PlacementTable from '@/views/affiliate/tools/placement/components/PlacementTable.vue'
import PlacementPopUpDelete from '@/views/affiliate/tools/placement/components/PlacementPopUpDelete.vue'
import PlacementPopUpEdit from '@/views/affiliate/tools/placement/components/PlacementPopUpEdit.vue'

export type FormData = {
  name: null | string
  url: null | string
  type: null | number
}
@Component({
  components: {
    PlacementCreateForm,
    PlacementTable,
    PlacementPopUpEdit,
    PlacementPopUpDelete,
  },
})
export default class Placement extends Vue {
  // Data
  isPreloaderShow = false

  // vuex module action
  @VuexAction('nPlacements') placementsGetItems!: (...args) => Promise<void>

  @VuexAction('nPlacements') placementsGetItem!: (...args) => Promise<void>

  @VuexAction('nPlacements') placementsCreateItem!: (...args) => Promise<void>

  @VuexAction('nPlacements') placementsUpdateItem!: (...args) => Promise<void>

  @VuexAction('nPlacements') placementsDeleteItem!: (...args) => Promise<void>

  @VuexAction('nPlacements') placementsTypesGetItems!: (...args) => Promise<void>

  // vuex module mutation
  @VuexMutation('nPlacements') changePopUpDeleteStatus!: (...args) => void

  @VuexMutation('nPlacements') changePopUpEditStatus!: (...args) => void

  @VuexMutation('nPlacements') placementsListParamsUpdate!: (...args) => void

  // Methods
  async createItem(data: FormData): Promise<void> {
    if (data) {
      await this.placementsCreateItem(data)
    }
  }

  async deleteItem(id: number): Promise<void> {
    await this.placementsDeleteItem(id)
    this.changePopUpDeleteStatus(false)
  }

  async updateItem(data: FormData): Promise<void> {
    if (data) {
      await this.placementsUpdateItem(data)
    }
    this.changePopUpEditStatus(false)
  }

  async openPopUpDelete(id: number): Promise<void> {
    await this.placementsGetItem(id)
    this.changePopUpDeleteStatus(true)
  }

  closePopUpDelete(): void {
    this.changePopUpDeleteStatus(false)
  }

  async openPopUpEdit(id: number): Promise<void> {
    await this.placementsGetItem(id)
    this.changePopUpEditStatus(true)
  }

  closePopUpEdit(): void {
    this.changePopUpEditStatus(false)
  }

  async changePageTable(page: number): Promise<void> {
    this.placementsListParamsUpdate({ page })
    await this.placementsGetItems()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.placementsTypesGetItems()
    await this.placementsGetItems()
    this.isPreloaderShow = false
  }
}




























































































import { Component, Watch, Vue } from 'vue-property-decorator'
import { VuexGetter, VuexAction } from '@/store/decorators'
import MoneyOutcomesService from '@/services/api/MoneyOutcomes/MoneyOutcomesService'
import Validation from '@/utils/Validation'
import { cloneDeep } from 'lodash'
import { MoneyOutcomesFormCreateDataType } from '@/services/api/MoneyOutcomes/MoneyOutcomesTypes'
import { CurrenciesAppModelType } from '@/services/api/Currencies/CurrenciesTypes'
import { PayoutSystemsAppModelType } from '@/services/api/PayoutSystems/PayoutSystemsType'
import { PayoutAccountAppModelType } from '@/services/api/PayoutAccount/PayoutAccountType'
import { BalanceAppModelType } from '@/services/api/Balance/BalanceTypes'

@Component
export default class MoneyOutcomePopupForm extends Vue {
  $refs!: {
    vform: HTMLInputElement | any
  }

  dialog = false

  defaultData: MoneyOutcomesFormCreateDataType = {
    payoutAccount: null,
    amount: null,
    payout_system: null,
    currency: null,
    type: 1,
  }

  form: {
    loading: boolean
    valid: boolean
    data: MoneyOutcomesFormCreateDataType | any
    rules: any
  } = {
    loading: false,
    valid: false,
    data: cloneDeep(this.defaultData),
    rules: {
      payoutAccount: Validation.getRules(['required']),
      amount: [...Validation.getRules(['required', 'digits']), this.ruleMaxAmount()],
      payout_system: Validation.getRules(['required']),
      currency: Validation.getRules(['required']),
    },
  }

  @VuexGetter('nApp', 'getBusinessAvailableCurrencies')
  listCurrencies!: CurrenciesAppModelType[]

  @VuexGetter('nDeskbook', 'getListPayoutSystems')
  listPayoutSystems!: PayoutSystemsAppModelType[]

  @VuexGetter('nApp') getCurrentApp!: any

  @VuexGetter('nBusinessFinances', 'getPayoutAccountsListItems')
  listPayoutAccounts!: PayoutAccountAppModelType[]

  @VuexGetter('nApp', 'getBalance')
  getBalance!: BalanceAppModelType

  @VuexAction('nApp') 'createNotification'!: (args) => Promise<boolean>

  @VuexAction('nApp') 'fetchBalance'!: () => Promise<boolean>

  @VuexAction('nDeskbook') 'fetchPayoutSystems'!: () => Promise<boolean>

  get activeCurrencyCode(): string {
    return this.form.data.currency?.code || ''
  }

  get filteredPayoutAccountForSelectedPayoutSystem(): any {
    return this.form.data.payout_system
      ? this.listPayoutAccounts.filter(
          (item) => this.form.data.payout_system.id === item.payoutSystem.id
        )
      : []
  }

  get filteredAccessiblePayoutSystems(): any {
    const IDs = this.listPayoutAccounts.map((item) => item.payoutSystem.id)
    return this.listPayoutSystems.filter((item) => IDs.includes(item.id))
  }

  get maxAmountPerCurrency(): number {
    const currentBallance = this.getBalance?.apps?.find(
      (item) => item.app_id === this.getCurrentApp.id
    )
    return currentBallance?.available && currentBallance?.available > 0
      ? currentBallance?.available
      : 0
  }

  ruleMaxAmount() {
    return (value) => {
      if (this.maxAmountPerCurrency === 0) {
        return this.$t('selectCurrency')
      }
      return (
        Number(value) <= this.maxAmountPerCurrency ||
        `${this.$t('maxAmount')} : ${this.maxAmountPerCurrency}`
      )
    }
  }

  dialogClose(): void {
    this.dialog = false
  }

  async formSubmit(): Promise<void> {
    if (this.formValidate()) {
      this.form.loading = true
      const response = await MoneyOutcomesService.createItem({
        data: { ...this.form.data, app: this.getCurrentApp.id },
        params: {},
      })
      this.form.loading = false
      if (response.status) {
        this.$emit('submitSuccess')
        await this.fetchBalance()
        this.dialogClose()
      }
    }
  }

  formValidate(): any {
    return this.$refs.vform.validate()
  }

  formReset(): void {
    this.form.data = cloneDeep(this.defaultData)
    setTimeout(() => this.$refs.vform.resetValidation(), 100)
  }

  async formInit(): Promise<void> {
    this.formReset()
    await this.fetchBalance()
    if (!this.listPayoutSystems.length) {
      await this.fetchPayoutSystems()
    }
  }

  @Watch('dialog')
  dialogHandler(value: boolean): void {
    if (value) {
      this.formInit()
    }
  }
}

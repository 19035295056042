





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PostsService from '@/services/api/Posts/PostsService'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import AppVCheckbox from '@/components/app/AppVCheckbox.vue'
import PostPreview from '@/components/PostPreview.vue'

@Component({
  components: { AppVCheckbox, PostPreview },
})
export default class PostsPicker extends Vue {
  // Props
  @Prop(Array) readonly value!: PostsAppModelType[]

  @Prop(Array) readonly disabledItems!: PostsAppModelType[]

  @Prop({ default: true, type: Boolean }) readonly single!: boolean

  @Prop({ default: false, type: Boolean }) readonly showAll!: boolean

  @Prop(Object) readonly app!: AppsAppModelType | null

  @Prop(Number) readonly promoPageId!: number

  // Data
  selectedPosts: PostsAppModelType[] = []

  posts: PostsAppModelType[] = []

  page = 1

  pages = 0

  loading = false

  error = false

  // Methods
  selectPost(post: PostsAppModelType): void {
    if (this.single) {
      this.selectedPosts = [post]
    } else if (this.checkPost(post.id)) {
      this.selectedPosts = this.selectedPosts.filter((item) => item.id !== post.id)
    } else {
      this.selectedPosts.push(post)
    }
  }

  infinityLoading(): void {
    if (!this.loading && !this.error) {
      this.getPosts()
    }
  }

  get modifiedPosts(): PostsAppModelType[] {
    if (this.disabledItems?.length > 1) {
      const ids = this.disabledItems.map((item) => item.id)
      return this.posts.map((item) => {
        return { ...item, disabled: ids.includes(item.id) && !this.checkPost(item.id) }
      })
    }
    if (this.promoPageId || this.promoPageId === null) {
      return this.posts.map((item) => {
        return {
          ...item,
          disabled: item.promotionalPage !== null && item.promotionalPage.id !== this.promoPageId,
        }
      })
    }

    return this.posts
  }

  checkPost(id: number): boolean {
    return this.selectedPosts.some((item) => item.id === id)
  }

  async getPosts(): Promise<void> {
    if (this.pages && this.page > this.pages) {
      return
    }
    this.loading = true
    const paramsList = {
      app: this.app?.id,
      page: this.page,
    }
    if (this.promoPageId) {
      paramsList['exists[products]'] = false
    } else if (this.promoPageId === null) {
      paramsList['exists[products]'] = false
    } else {
      paramsList['exists[promotionalPage]'] = false
    }
    const { data, params } = await PostsService.getItems({
      params: paramsList,
    })
    if (data?.length) {
      this.posts = [...this.posts, ...data]
      if (this.promoPageId) {
        this.checkPromoPost()
      }
      this.pages = params.pages
      this.page++
    } else {
      this.error = true
    }
    this.loading = false
  }

  checkPromoPost(): void {
    const ids = this.selectedPosts.map((item) => item.id)
    const promoPosts = this.posts.filter(
      (item) =>
        item.promotionalPage &&
        item.promotionalPage.id === this.promoPageId &&
        !ids.includes(item.id)
    )
    this.selectedPosts = [...this.selectedPosts, ...promoPosts]
  }

  // Watch
  @Watch('selectedPosts')
  selectedPostsHandler(): void {
    this.$emit('input', this.selectedPosts)
  }

  @Watch('value')
  valueHandler(): void {
    this.selectedPosts = this.value
  }

  // Hooks
  async created(): Promise<void> {
    this.getPosts()
  }
}

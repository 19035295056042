































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { PlacementAppModelType } from '@/services/api/Placement/PlacementTypes'

@Component({})
export default class PlacementTable extends Vue {
  // Computed
  get tableHeaders(): any {
    return [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('type'), value: 'type' },
      { text: 'URL', value: 'url', width: '700px' },
      { text: '', value: 'actions', width: '104px' },
    ]
  }

  // vuex module getter
  @VuexGetter('nPlacements') getPlacementsListItems!: Array<PlacementAppModelType>

  @VuexGetter('nPlacements') getPlacementsTableLoadingStatus!: boolean

  @VuexGetter('nPlacements') getPlacementsListParams!: any

  // Emits
  @Emit('openPopUpEdit')
  openPopUpEdit(id: number): number {
    return id
  }

  @Emit('openPopUpDelete')
  openPopUpDelete(id: number): number {
    return id
  }

  @Emit('changePageTable')
  changePageTable(page: number): number {
    return page
  }
}

<template>
  <article>
    <page-header extra>
      <h4 class="d-inline-flex align-center">
        <icon
          class="mr-8"
          color="black"
          data="@/assets/icons/chevron-left.svg"
          height="32"
          width="16"
          @click="$router.push(`/business/goods_and_services/logistics`)"
        />
        {{ $t('storages.createNewStorage') }}
      </h4>
    </page-header>
    <logistics-form mode="create" />
  </article>
</template>
<script>
import Vue from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import LogisticsForm from '@/views/business/goodsAndServices/logistics/components/LogisticsForm.vue'

export default Vue.extend({
  name: 'LogisticsCreate',
  components: { PageHeader, LogisticsForm },
  data: () => ({}),
})
</script>

<template>
  <div class="supster-ui--step-breadcrumbs__wrapper">
    <span
      v-for="(item, index) in labels"
      :key="index"
      class="supster-ui--step-breadcrumbs__item br justify-center"
      :style="{ width: `${stepWidth}%` }"
      :class="{ _checked: current >= index, _active: current === index, _centered: alignCenter }"
    >
      <icon
        v-if="current > index"
        data="@/assets/icons/checked.svg"
        class="supster-ui--step-breadcrumbs__item-icon mr-2"
      />
      <span
        class="supster-ui--step-breadcrumbs__item-text"
        :class="{ _active: current === index }"
        >{{ item }}</span
      >
    </span>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'StepBreadcrumbs',
  props: {
    alignCenter: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return { current: 0, labels: [] }
      },
    },
  },
  data: () => ({}),
  computed: {
    current() {
      return this.value.current || 0
    },
    labels() {
      return this.value.labels || []
    },
    stepWidth() {
      return 100 / this.value.labels || 0
    },
  },
})
</script>

<style scoped lang="scss">
.br {
  box-shadow: inset 1px 0px 0px #73649e;
}
</style>

import MoneyOutcomesService from '@/services/api/MoneyOutcomes/MoneyOutcomesService'
import { MoneyOutcomesModelAppType } from '@/services/api/MoneyOutcomes/MoneyOutcomesTypes'
import PayoutAccountService from '@/services/api/PayoutAccount/PayoutAccountService'
import { PayoutAccountAppModelType } from '@/services/api/PayoutAccount/PayoutAccountType'
import i18n from '@/plugins/i18n'
import { TranslateResult } from 'vue-i18n'
import {
  nAffiliateFinancesMutations,
  nAffiliateFinancesActions,
  nAffiliateFinancesGetters,
  nAffiliateFinancesModule,
} from './types'

const actions: nAffiliateFinancesActions = {
  async moneyOutcomesGetItems({ commit, state }): Promise<boolean> {
    commit('moneyOutcomesChangeLoadingList', true)
    const response = await MoneyOutcomesService.getItems({
      params: state.lists.moneyOutcomes.params,
    })
    if (response.status) {
      commit('moneyOutcomesListParamsUpdate', response.params)
      commit('moneyOutcomesListItemsUpdate', response.data)
    }
    commit('moneyOutcomesChangeLoadingList', false)
    return response.status
  },
  async payoutAccountsGetItems({ commit, state }): Promise<boolean> {
    const response = await PayoutAccountService.getItems({
      params: { type: 2 },
    })
    if (response.status) {
      commit('payoutAccountsListItemsUpdate', response.data)
    }
    return response.status
  },
}

const mutations: nAffiliateFinancesMutations = {
  moneyOutcomesChangeLoadingList(state, status: boolean): void {
    state.lists.moneyOutcomes.loading = status
  },
  moneyOutcomesListParamsUpdate(state, params): void {
    state.lists.moneyOutcomes.params = { ...state.lists.moneyOutcomes.params, ...params }
  },
  moneyOutcomesListItemsUpdate(state, items: MoneyOutcomesModelAppType): void {
    state.lists.moneyOutcomes.items = items
  },
  payoutAccountsListItemsUpdate(state, items: PayoutAccountAppModelType): void {
    state.lists.payoutAccounts.items = items
  },
}

const getters: nAffiliateFinancesGetters = {
  getMoneyOutcomesListLoading(state): boolean {
    return state.lists.moneyOutcomes.loading
  },
  getMoneyOutcomesListParams(state): unknown {
    return state.lists.moneyOutcomes.params
  },
  getMoneyOutcomesListItems(state): [] | [MoneyOutcomesModelAppType] {
    return state.lists.moneyOutcomes.items
  },
  getPayoutAccountsListItems(state): [] | [PayoutAccountAppModelType] {
    return state.lists.payoutAccounts.items
  },
  getMoneyOutcomesStatuses(): [{ value: number; name: TranslateResult | string }] | unknown {
    return [
      {
        value: 1,
        name: i18n.t('pending'),
      },
      {
        value: 2,
        name: i18n.t('completed'),
      },
      {
        value: 3,
        name: i18n.t('rejected'),
      },
    ]
  },
}

const nAffiliateFinances: nAffiliateFinancesModule = {
  state: () => ({
    lists: {
      moneyOutcomes: {
        loading: false,
        items: [],
        params: MoneyOutcomesService.getDefaultParams(),
      },
      payoutAccounts: {
        items: [],
      },
    },
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nAffiliateFinances










































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import moment from 'moment'
import { BillingRequestAppModelType } from '@/services/api/BillingRequest/BillingRequestTypes'
import { tableHeadersType } from '../types'

@Component({})
export default class TransactionsTable extends Vue {
  // Data
  money: any

  // Computed
  get tableHeaders(): tableHeadersType[] {
    return [
      { text: this.$t('dateAndTime'), value: 'created' },
      { text: this.$t('transactions.transactionNumber'), value: 'id' },
      { text: this.$t('clientID'), value: 'client' },
      { text: this.$t('amount'), value: 'amount' },
      { text: this.$t('status'), value: 'status' },
      { text: this.$t('transactions.codeError'), value: 'error_code' },
      { text: '', value: 'actions', width: '49px' },
    ]
  }

  // vuex module getter
  @VuexGetter('nBusinessTransactions') getTransactionsListItems!: Array<BillingRequestAppModelType>

  @VuexGetter('nBusinessTransactions') getTransactionsListLoading!: boolean

  @VuexGetter('nBusinessTransactions') getTransactionsListParams!: any

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  // Methods
  setDateFormatForTable(date: string, format: string): string {
    return moment(date).locale(this.$i18n.locale).format(format)
  }

  // Emits
  @Emit('openPopUpDetails')
  openPopUpDetails(id: number): number {
    return id
  }

  @Emit('tableChangePage')
  tableChangePage(page: number): number {
    return page
  }
}

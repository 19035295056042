var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-data-table',{attrs:{"pages":_vm.getOffersParams.pages,"currentPage":_vm.getOffersParams.page,"items":_vm.getOffersItems,"headers":_vm.tableHeaders,"loading":_vm.getOffersListLoading},on:{"input":_vm.tableChangePage,"click:row":_vm.openOffer},scopedSlots:_vm._u([{key:"header.rules",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(" "+_vm._s(header.text))]),_c('v-tooltip',{attrs:{"nudge-top":"20","min-width":"600","color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 text-base",attrs:{"color":"var(--accent-green-light)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('offerPage.tooltip.revshareCPA'))}})])]}},{key:"item.app",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-4"},[_c('div',{staticClass:"pr-2"},[_c('v-avatar',{attrs:{"size":"42"}},[(item.app.image && item.app.image.preview_url)?[_c('img',{attrs:{"src":item.app.image.preview_url}})]:[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-image-remove")])]],2)],1),_c('div',{staticClass:"pl-0"},[_c('div',{staticClass:"font-weight-bold lh-22"},[_vm._v(_vm._s(item.app.name))]),_c('v-btn',{staticClass:"text-xx-small",attrs:{"color":"accent","x-small":"","max-height":"16"},on:{"click":function($event){return _vm.createStream(item)}}},[_vm._v(_vm._s(_vm.$t('getStream')))])],1)])]}},{key:"item.countries",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4 _max-content"},[_vm._l((item.countries),function(country,index){return _c('div',{key:country.id,staticClass:"d-flex align-center"},[(index < 3)?_c('div',{staticClass:"d-flex align-center"},[_c('country-flag',{attrs:{"code":country.code}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(country.name))])],1):_vm._e()])}),(item.countries.length > 3)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex align-center pt-1"},[_c('icon',{staticClass:"mr-2",attrs:{"width":"10px","height":"6px","data":require("@/assets/icons/carret-dropdown.svg")}}),_c('span',_vm._g(_vm._b({staticClass:"text-primary-light text-xsmall"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('more'))+" "+_vm._s(item.countries.length - 3)+" "+_vm._s(_vm.$t('country.2'))+" ")])],1)]}}],null,true)},[_c('v-list',_vm._l((item.countries.slice(3)),function(country){return _c('v-list-item',{key:country.id},[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('country-flag',{attrs:{"code":country.code}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(country.name))])],1)],1)}),1)],1):_vm._e()],2)]}},{key:"item.rules",fn:function(ref){
var item = ref.item;
return [(!item.rules.length)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('revshare')))]):_vm._e()]}},{key:"item.offerCategory",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.offerCategory.name))])]}},{key:"item.subscribers",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.app.follower_count || '0'))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
import VRadioGroup from 'vuetify/lib/components/VRadioGroup/VRadioGroup'

export default {
  name: 'AppVRadioGroup',
  extends: VRadioGroup,
  props: {
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
  },
}
</script>

<style lang="scss">
.v-input--radio-group .v-label {
  color: var(--primary-light) !important;
}

.v-input--radio-group .v-item--active .v-label {
  color: var(--v-primary-base) !important;
}
</style>

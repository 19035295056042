import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import { ChatGptSystemPromptAppModelType } from './ChatGptSystemPromptTypes'

class ChatGptSystemPromptService extends BaseApiService {
  protected modelName = 'Системные промты'

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.CHAT_GPT_SYSTEM_PROMTS)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 30,
    page: 1,
  }

  private usedListParams = ['app', 'itemsPerPage']

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    data.app = `${ENDPOINTS.APPS}/${data.app}`
    data.image = `/api/media_objects/${data.image.id}`
    return data
  }

  public transformDataForApp(incomingData): ChatGptSystemPromptAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    return params
  }
}
export default new ChatGptSystemPromptService()

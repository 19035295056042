import { mapServerSideUser } from '@/utils/api.mapper'
import { getInstance, getInstanceWithToken } from '@/utils/axios'
import { ServerSideMediaObject, ServerSideUser, User } from './types'

export interface FetchUserRequest {
  id: number
}

export const fetchUser = async ({ id }: FetchUserRequest): Promise<User> => {
  const { data } = await getInstanceWithToken().get<{ user: ServerSideUser }>(`/api/users/${id}`)

  return mapServerSideUser(data.user)
}

export interface UpdateUserRequest {
  id: number
  name: string
  email: string
  avatar?: File
}

export const updateUser = async ({ id, name, email, avatar }: UpdateUserRequest): Promise<User> => {
  let mediaObject: ServerSideMediaObject | null = null

  if (avatar) {
    const mediaRequest = new FormData()
    mediaRequest.append('file', avatar)

    const mediaObjectResponse = await getInstanceWithToken().post<ServerSideMediaObject>(
      '/api/media_objects',
      mediaRequest
    )

    mediaObject = mediaObjectResponse.data
  }

  const { data } = await getInstanceWithToken().put(`/api/users/${id}`, {
    id,
    name,
    email,
    avatar: mediaObject ? `/api/media_objects/${mediaObject.id}` : undefined,
  })

  return mapServerSideUser(data)
}

export interface CreateUserRequest {
  name: string
  email: string
  password: string
}

export const createUser = async ({ name, email, password }: CreateUserRequest): Promise<User> => {
  const { data } = await getInstance().post<ServerSideUser>(`/api/users`, {
    name,
    email,
    plainpassword: password,
  })

  return mapServerSideUser(data)
}

export interface UpdateUserPasswordRequest {
  id: number
  password: string
}

export const updateUserPassword = async (request: UpdateUserPasswordRequest): Promise<void> => {
  await getInstanceWithToken().put(`/api/users/${request.id}`, {
    plainPassword: request.password,
  })
}

/*
  This script imports all modules from subdirectories.
  Module main file name must be [name].store.ts
*/
const requireModule = require.context('.', true, /\.store\.ts$/)
const modules: Record<string, any> = {}
requireModule.keys().forEach((filename) => {
  const moduleName: string = filename.replace(/(\.\/|\.store\.ts)/g, '').replace(/.*\//, '')
  modules[moduleName] = requireModule(filename).default || requireModule(filename)
})

export default modules























import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Returns',
})
export default class Returns extends Vue {}

<template>
  <app-v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :items="countries"
    :return-object="returnObject"
    :placeholder="placeholder"
    :multiple="multiple"
    :hide-no-data="false"
    v-bind="$attrs"
    :label="label"
    :error="error"
    background-color="grey lighten-2"
  >
    <template v-slot:selection="{ item, index }">
      <div class="d-flex mr-2 font-weight-bold align-center" v-if="index === 0">
        <country-flag class="mr-2" :code="item.code" />
        <span>{{ item.name }}</span>
      </div>
      <span class="font-weight-bold mr-2" v-if="index === 1 && multiple">
        (+{{ value.length - 1 }})
      </span>
    </template>
    <template #label>
      <slot name="label"></slot>
    </template>
    <template #item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action v-if="multiple">
          <v-simple-checkbox :ripple="false" :value="active"></v-simple-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <div class="d-flex align-center">
            <country-flag class="mr-2" :code="item.code" />
            <v-list-item-title class="font-weight-bold">
              {{ item.name }}
            </v-list-item-title>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
  </app-v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CountryFlag from '@/components/CountryFlag.vue'

export default {
  name: 'AppAutocompleteCountries',
  components: { CountryFlag },
  props: {
    value: {
      type: [Array, Object, String, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: [String, Object, Boolean],
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: [],
      searchAutocomplete: '',
    }
  },
  methods: {
    ...mapActions('nDeskbook', ['fetchCountries']),
  },
  computed: {
    ...mapGetters('nDeskbook', { countries: 'getListCountries' }),
  },

  async created() {
    await this.fetchCountries()
  },
}
</script>

<style scoped></style>

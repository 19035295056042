export default {
  // base API
  STORES: '/api/stores',
  DELIVERY_METHODS: '/api/delivery_methods',
  DELIVERY_SERVICES: '/api/delivery_services',
  DELIVERY_REGIONS: '/api/delivery_regions',

  PRODUCTS: '/api/products',
  PRODUCT_BRANDS: '/api/product_brands',
  PRODUCT_CATEGORIES: '/api/product_categories',
  PRODUCT_PROPERTIES: '/api/product_properties',
  PRODUCT_TNVEDS: '/api/product_tnveds',
  PRODUCT_STORES: '/api/product_stores',
  SERVICE_CATEGORIES: '/api/service_categories',

  USERS: 'api/users',
  APPS: 'api/apps',
  BALANCE: 'api/balance',
  COUNTRIES: '/api/countries',
  DOMAINS: '/api/domains',
  MEDIA_OBJECTS: '/api/media_objects',
  ORDERS: '/api/orders',
  ORDER_POSITIONS: '/api/order_positions',
  CHANNELS: '/api/channels',
  CHANNEL_MESSAGES: '/api/channel_messages',
  CHANNEL_SUBSCRIPTIONS: '/api/channel_subscriptions',
  CLIENTS: '/api/clients',
  COMMENT_LIKES: 'comments_likes',

  STAT: '/api/stat',

  MONEY_OUTCOMES: '/api/money_outcomes',
  MONEY_INCOMES: '/api/money_incomes',
  CURRENCIES: '/api/currencies',
  COUNTEIES: '/api/countries',
  PAYMENT_SYSTEMS: '/api/payment_systems',
  PAYOUT_SYSTEMS: '/api/payout_systems',
  PAYOUT_ACCOUNT: '/api/payout_accounts',
  OFFER: '/api/offers',
  STREAM: '/api/streams',
  PHONE_CONFIRM_DATA: '/api/phone_confirm_data',
  REFERRAL: '/api/referrals',
  SUBSCRIPTION: '/api/subscriptions',
  TARIFF: '/api/tariffs',
  BILLING_REQUEST: '/api/billing_requests',
  PROMOTIONAL_PAGE: '/api/promotional_pages',
  PLACEMENT_TYPE: '/api/placement_types',
  PLACEMENT: '/api/placements',
  POSTBACK: '/api/postbacks',
  USER: '/api/users',
  USER_VERIFICATION: '/api/user_verifications',
  OFFER_SOURSES: '/api/offer_sources',
  OFFER_CATEGORIES: '/api/offer_categories',
  PUBLICATION: '/api/app_publications',
  TRAFFICBACK: '/api/trafficbacks',
  CLIENT_VERIFICATIONS: '/api/client_verifications',
  ARTICLES: '/api/articles',
  DOCUMENTS: '/api/documents',
  POSTS: '/api/posts',
  CHAT_GPT_SYSTEM_PROMTS: '/api/chat_gpt_system_prompts',
}

import { cloneDeep } from 'lodash'

import ClientsService from '@/services/api/Clients/ClientsService'
import ChannelsService from '@/services/api2/Channels/ChannelsService'
import ChannelMessagesService from '@/services/api2/ChannelMessages/ChannelMessagesService'
import MediaObjectsService from '@/services/api/MediaObjects/MediaObjectsService'

import { nChatsMutations, nChatsActions, nChatsGetters, nChatsModule } from './types'

const defaultState = {
  appId: false,
  meClientId: false,
  lists: {
    clients: {
      filter: {
        has_message: false,
      },
      params: ClientsService.getDefaultParams(),
      data: [],
      newMessageCounts: {},
      chatsCountNotReadMessages: 0,
    },
    channels: {
      params: ChannelsService.getDefaultParams(),
      data: [],
    },
  },
  drafts: {},
  dialog: {
    open: false,
    loading: false,
    client: false,
    channel: false,
    messages: {
      data: [],
      params: {},
    },
    controlForm: {
      submitProccess: false,
      message: '',
      media: [],
      mediaUploaded: [],
    },
  },
}

const actions: nChatsActions = {
  async chatsInit({ commit, dispatch }, { appId, meClientId }) {
    commit('stateReset')
    commit('setAppId', appId)
    commit('setMeClientId', meClientId)
    commit('clientsListUpdateParams', {
      app_id: appId,
    })
    commit('channelsListUpdateParams', {
      app_id: appId,
      limit: 200,
      type: [1, 2, 5],
    })

    await dispatch('channelsGetItemsByNewMessages')
    await dispatch('channelsGetItems')
    await dispatch('clientsGetItems')
  },

  async loadDialog(
    { commit, state },
    { client = false, channel = false, support = null }
  ): Promise<void> {
    // TODO Нужно будет отрефакторить метод
    commit('dialogChangeLoading', true)
    commit('draftSave')
    commit('dialogControlFormClear')
    commit('dialogSetClient', client)
    commit('dialogControlFormGetDraftMessage')
    try {
      const resultData: any = {
        client,
        channel: false,
        messages: {
          data: [],
          params: {},
        },
      }
      let channelResponse
      if (!channel) {
        channelResponse = await ChannelsService.getItemByClient(client.id)
      } else {
        channelResponse = { data: channel }
      }

      if (channelResponse.data) {
        const { id } = channelResponse.data
        const messagesResponse = await ChannelMessagesService.getItemsByChannel(id, {
          page: 1,
          limit: 50,
        })
        ChannelMessagesService.readAllByChannel(id)
        resultData.channel = channelResponse.data
        resultData.messages = messagesResponse
      }

      commit('dialogInit', resultData)
      commit('clientClearNewMessageCounts')
      commit('clientsSetChatsCountByNewMessages')
    } catch (error) {}

    commit('dialogChangeLoading', false)
  },

  async clientsGetItems({ commit, state }, actionParams = { processing: true }): Promise<void> {
    const { params } = state.lists.clients
    params['order[has_message]'] = 'desc'
    if (params.pageLoaded === params.pages) {
      return
    }

    commit('clientsListChangeLoading', actionParams.processing)
    const response = await ClientsService.getItems({ params })
    if (response.status) {
      commit('clientsListAddData', response.data)
      commit('clientsListUpdateParams', {
        pages: response.params.pages,
        pageLoaded: response.params.page,
      })
    }
    commit('clientsListChangeLoading', false)
  },
  async clientsGetItem({ commit }, client_id): Promise<void> {
    const response = await ClientsService.getItem({ id: client_id })
    if (response.status) {
      commit('dialogSetClient', response.data)
    }
  },

  async clientsChangeFilter({ commit, state, dispatch }): Promise<void> {
    commit('clientsListReset')
    commit('clientsSwitchFilter')
    commit('clientsListUpdateParams', {
      app_id: state.appId,
    })
    dispatch('clientsGetItems')
  },
  async clientsListUpdateByNotify({ commit, state, dispatch }, client): Promise<void> {
    if (state.lists.clients.data.find((el) => el.id === client.id)) {
      return
    }
    commit('clientsListReset')
    commit('clientsListUpdateParams', {
      app_id: state.appId,
    })
    dispatch('clientsGetItems', { processing: false })
  },
  async channelsGetItems({ commit, state }): Promise<void> {
    const response = await ChannelsService.getItems({ params: state.lists.channels.params })
    commit('channelsListSetData', response.data)
  },

  async channelsGetItemsByNewMessages({ commit, state }, { appId } = {}): Promise<void> {
    const params = {
      app_id: appId || state.appId,
    }
    const response = await ChannelsService.getItemsByNewMessages(params)
    commit('clientsListSetNewMessageCounts', response.data)
    commit('clientsSetChatsCountByNewMessages')
  },
  async createSupportChannel({ dispatch, commit, state }) {
    const channelResponse = await ChannelsService.createItem({
      data: { client_id: state.dialog.client.id, type: 5 },
    })
    if (channelResponse.status) {
      commit('dialogSetChannel', { channel: channelResponse.data })
      await dispatch('channelsGetItems')
    }
  },
  // TODO Требуется доработка метода
  async messageCreate({ commit, dispatch, state }) {
    commit('dialogControlFormChangeSubmitProccess', true)
    try {
      if (state.dialog.channel === false) {
        const channelResponse = await ChannelsService.createItem({
          data: { client_id: state.dialog.client.id, type: 3 },
        })
        if (channelResponse.status) {
          commit('dialogSetChannel', { channel: channelResponse.data })
          const messagesResponse = await ChannelMessagesService.getItemsByChannel(
            state.dialog.channel.id,
            {
              page: 1,
              limit: 50,
            }
          )
          commit('dialogSetMessages', messagesResponse)
        }
      }
      if (!state.dialog.channel) {
        throw new Error('dialog not have a channel')
      }
      await dispatch('uploadMediaObjects')

      const data = {
        channel_id: state.dialog.channel.id,
        name: state.dialog.client.name,
        body: state.dialog.controlForm.message,
        media_objects: state.dialog.controlForm.mediaUploaded,
      }
      await ChannelMessagesService.createItem({ data })
      // commit('dialogMessagesAddNew', [messageResponse.data])
      commit('dialogControlFormClear')
      commit('draftClear')
      // commit('dialogMessagesRemoveLast')
      // commit('dialogMessagesRecalculatePagination', 1)
    } catch (error) {
      console.error(error)
    }
    commit('dialogControlFormChangeSubmitProccess', false)
  },

  async messageDelete({ commit }, { index, id }) {
    try {
      const response = await ChannelMessagesService.deleteItem({ id })
      commit('dialogMessagesRemoveOne', index)
      commit('dialogMessagesRecalculatePagination', -1)
    } catch (error) {}
  },

  async messagesGetItemsNextPage({ commit, state }) {
    try {
      commit('dialogMessagesChangeLoading', true)
      commit('dialogMessagesSetNextPage')
      const { params } = state.dialog.messages
      const { id } = state.dialog.channel
      const response = await ChannelMessagesService.getItemsByChannel(id, params)

      commit('dialogMessagesAddPage', response)
    } catch (error) {}

    commit('dialogMessagesChangeLoading', false)
  },

  async uploadMediaObjects({ commit, state }) {
    try {
      const mediaObjects: any = []
      for (const item of state.dialog.controlForm.media) {
        const response = await MediaObjectsService.createItem({
          data: item.file,
          params: {},
        })
        if (response.status) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mediaObjects.push(response.data.id)
        }
      }
      commit('setUploadedMediaObjects', mediaObjects)
    } catch (error) {}
  },

  async openDialogByNotify({ dispatch }, { appId, clientId, channelId, meClientId }) {
    try {
      const clientResponse = await ClientsService.getItem({ id: clientId })
      const channelResponse = await ChannelsService.getItem({ id: channelId })

      dispatch('chatsInit', { appId, meClientId })
      dispatch('loadDialog', { client: clientResponse.data, channel: channelResponse.data })
    } catch (error) {}
  },

  async updateDialogByCreatedNotify(
    { commit, dispatch, state },
    { appId, channelId, message } = {}
  ): Promise<void> {
    commit('dialogChangeLoading', true)
    try {
      if (channelId === state.dialog.channel.id) {
        commit('dialogMessagesAddNew', [message])
        commit('dialogMessagesRecalculatePagination', 1)
        await ChannelMessagesService.readAllByChannel(channelId)
      } else {
        /*  
          TODO ОЧЕНЬ ВАЖНО до Релиза 1.0 Убрать лишний запрос, и обновлять данные из вебсокета. 
          сейчас при кадом сигнале о новом сообщении выполняется
          запрос на /channels, это очень может повысить нагрузку на сервер. 
        */
        dispatch('channelsGetItemsByNewMessages', { appId })
      }
    } catch (error) {}
    commit('dialogChangeLoading', false)
  },
  async updateDialogByRemovedNotify(
    { commit, dispatch, state },
    { appId, channelId, message } = {}
  ): Promise<void> {
    try {
      if (channelId === state.dialog.channel.id) {
        commit('dialogMessagesRemoveMessageById', message)
        commit('dialogMessagesRecalculatePagination', 1)
      }
    } catch (error) {}
  },
  async updateDialogByUpdatedNotify(
    { commit, dispatch, state },
    { appId, channelId, message } = {}
  ): Promise<void> {
    try {
      if (channelId === state.dialog.channel.id) {
        commit('dialogMessagesUpdateMessageById', message)
        commit('dialogMessagesRecalculatePagination', 1)
      }
    } catch (error) {}
  },
  async clientsListReadByNotify(
    { commit, dispatch, state },
    { appId, channelId, message } = {}
  ): Promise<void> {
    try {
      commit('clientClearNewMessageCounts', message.client)
      commit('clientsSetChatsCountByNewMessages')
    } catch (error) {}
  },
}

const mutations: nChatsMutations = {
  stateReset(state) {
    Object.assign(state, cloneDeep(defaultState))
  },
  setAppId(state, appId) {
    state.appId = appId
  },
  setMeClientId(state, me) {
    state.meClientId = me
  },
  clientsListAddData(state, loadedClients): void {
    state.lists.clients.data = [...state.lists.clients.data, ...loadedClients]
  },
  clientsListSetNextPage(state) {
    const { params } = state.lists.clients
    state.lists.clients.params.page += params.page === params.pages ? 0 : 1
  },
  clientsListReset(state) {
    state.lists.clients.params = ClientsService.getDefaultParams()
    state.lists.clients.data = []
  },
  clientsListUpdateParams(state, params) {
    const oldParams = state.lists.clients.params
    state.lists.clients.params = { ...oldParams, ...params }
  },
  clientsListChangeLoading(state, status: boolean) {
    state.lists.clients.params.loading = status
  },
  clientsSwitchFilter(state) {
    const newValue = !state.lists.clients.filter.has_message
    state.lists.clients.filter.has_message = newValue
    if (newValue) {
      state.lists.clients.params.has_message = 1
    } else {
      delete state.lists.clients.params.has_message
    }
  },

  channelsListSetData(state, channels) {
    state.lists.channels.data = channels
  },
  channelsListUpdateParams(state, params) {
    const oldParams = state.lists.channels.params
    state.lists.channels.params = { ...oldParams, ...params }
  },
  clientsListSetNewMessageCounts(state, channels) {
    const newMessages = {}
    if (Array.isArray(channels)) {
      for (const channel of channels) {
        const { id } = channel.client
        const { new_message_count } = channel.channel_subscription
        if (state.meClientId !== id) {
          newMessages[id] = new_message_count
        }
      }
    }
    state.lists.clients.newMessageCounts = newMessages
  },
  clientsSetChatsCountByNewMessages(state) {
    const count = Object.keys(state.lists.clients.newMessageCounts).length
    state.lists.clients.chatsCountNotReadMessages = count
  },
  clientClearNewMessageCounts(state, client = false) {
    const clientId = client.id ?? state.dialog.client.id
    if (clientId in state.lists.clients.newMessageCounts) {
      state.lists.clients.newMessageCounts[clientId] = 0
      delete state.lists.clients.newMessageCounts[clientId]
    }
  },
  dialogChangeLoading(state, status: boolean): void {
    state.dialog.loading = status
  },
  dialogSetClient(state, client) {
    state.dialog.client = client || false
  },
  dialogSetMessages(state, messages) {
    state.dialog.messages.data = messages.data || []
    state.dialog.messages.params = ChannelMessagesService.getDefaultParams()
    state.dialog.messages.params = { ...state.dialog.messages.params, ...messages.params }
  },
  dialogInit(state, { client, channel, messages }) {
    state.dialog.open = true
    state.dialog.client = client || false
    state.dialog.channel = channel
    state.dialog.messages.data = messages.data || []

    state.dialog.messages.params = ChannelMessagesService.getDefaultParams()
    state.dialog.messages.params = { ...state.dialog.messages.params, ...messages.params }
  },
  dialogSetChannel(state, { channel }) {
    state.dialog.channel = channel
  },
  dialogMessagesAddPage(state, { data, params = {} }) {
    state.dialog.messages.data = [...state.dialog.messages.data, ...data]
    state.dialog.messages.params = { ...state.dialog.messages.params, ...params }
  },
  dialogMessagesSetNextPage(state) {
    const { params } = state.dialog.messages
    state.dialog.messages.params.page += params.page === params.pages ? 0 : 1
  },
  dialogMessagesChangeLoading(state, status) {
    state.dialog.messages.params.loading = status
  },
  dialogMessagesAddNew(state, newMessages) {
    state.dialog.messages.data = [...newMessages, ...state.dialog.messages.data]
  },
  dialogMessagesRemoveMessageById(state, message) {
    const finded = state.dialog.messages.data.findIndex((el) => el.id === Number(message.id))
    if (finded >= 0) {
      state.dialog.messages.data.splice(finded, 1)
    }
  },
  dialogMessagesUpdateMessageById(state, message) {
    const finded = state.dialog.messages.data.findIndex((el) => el.id === Number(message.id))
    if (finded >= 0) {
      state.dialog.messages.data[finded].body = message.body
    }
  },
  dialogMessagesRemoveOne(state, index) {
    state.dialog.messages.data.splice(index, 1)
  },
  dialogMessagesRemoveLast(state) {
    if (
      state.dialog.messages.data &&
      state.dialog.messages.data.length < state.dialog.messages.params.total
    ) {
      state.dialog.messages.data.pop()
    }
  },
  dialogMessagesRecalculatePagination(state, value = 0) {
    const params = {
      total: state.dialog.messages.params.total + value,
      limit: state.dialog.messages.params.limit,
    }
    const recalculated = ChannelMessagesService.calculatePagination(params)
    state.dialog.messages.params = { ...state.dialog.messages.params, ...recalculated }
  },

  dialogControlFormAddMedia(state, newMedia) {
    const currentMedia = state.dialog.controlForm.media
    state.dialog.controlForm.media = [...currentMedia, ...newMedia].map((item, i) => {
      item.index = i
      return item
    })
  },
  dialogControlFormRemoveMedia(state, index) {
    state.dialog.controlForm.media.splice(index, 1)
    state.dialog.controlForm.media = state.dialog.controlForm.media.map((item, i) => {
      item.index = i
      return item
    })
  },
  dialogControlFormBind(state, formData) {
    state.dialog.controlForm = formData
  },
  dialogControlFormClear(state) {
    state.dialog.controlForm = {
      message: '',
      media: [],
      mediaUploaded: [],
      submitProccess: false,
    }
  },
  dialogControlFormChangeSubmitProccess(state, status: boolean) {
    state.dialog.controlForm.submitProccess = status
  },
  dialogControlFormGetDraftMessage(state) {
    const { client } = state.dialog
    if (client.id in state.drafts) {
      state.dialog.controlForm = cloneDeep(state.drafts[client.id])
    }
  },

  draftSave(state) {
    const { controlForm, client } = state.dialog
    if (client && (controlForm.message.length > 0 || controlForm.media.length > 0)) {
      state.drafts[client.id.toString()] = cloneDeep(controlForm)
    }
  },
  draftClear(state) {
    const { client } = state.dialog
    if (client.id in state.drafts) {
      delete state.drafts[client.id]
    }
  },
  setUploadedMediaObjects(state, mediaObjects) {
    state.dialog.controlForm.mediaUploaded = mediaObjects
  },
}

const getters: nChatsGetters = {
  getAllChatsCount(state): number {
    const clientsParams = state.lists.clients.params
    const channelsCount = state.lists.channels.data.length
    if (clientsParams.page === clientsParams.pages) {
      return state.lists.clients.data.length + channelsCount - 1
    }
    return Math.round(clientsParams.pages * clientsParams.itemsPerPage + channelsCount - 1)
  },
  getCountChatsNotReadMessages(state): number {
    return state.lists.clients.chatsCountNotReadMessages
  },
  getClientsListData(state): boolean {
    return state.lists.clients.data
  },
  getClientsNewMessageCounts(state): any {
    return state.lists.clients.newMessageCounts
  },
  getClientsListParams(state): boolean {
    return state.lists.clients.params
  },
  getClientsListCount(state): boolean {
    return state.lists.clients.data.length
  },

  getClientsListFilter(state) {
    return state.lists.clients.filter
  },
  getClientsListLoading(state) {
    return state.lists.clients.params.loading
  },
  getClientsFilter(state) {
    return state.lists.clients.filter
  },

  getSupportChannel(state) {
    return state.lists.channels.data.find((item) => item.type === 5)
  },

  getChannelsListData(state) {
    return state.lists.channels.data
  },
  getDialog(state) {
    return state.dialog
  },
  getDialogMessages(state) {
    return state.dialog.messages
  },
  getDialogControlForm(state) {
    return state.dialog.controlForm
  },
  getDialogControlFormSubmitProccess(state) {
    return state.dialog.controlForm.submitProccess
  },
  getDialogClient(state) {
    return state.dialog.client
  },
  getDialogLoading(state) {
    return state.dialog.loading
  },

  getDrafts(state) {
    return state.drafts
  },
  getMyClientId(state) {
    return state.meClientId
  },
}

const nChats: nChatsModule = {
  state: () => cloneDeep(defaultState),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nChats











import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {},
})
export default class DialogHeader extends Vue {
  @VuexGetter('nChats') getDialogMessages!: any
}

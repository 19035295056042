







































































import { Component, Vue, Emit } from 'vue-property-decorator'

@Component({})
export default class PublishStageTwo extends Vue {
  @Emit('continue')
  moveForward(): string {
    return 'Three'
  }
}



























import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexMutation, VuexGetter } from '@/store/decorators'
import AccrualsTable from '@/views/business/finances/accruals/components/AccrualsTable.vue'
import AccrualsTopCards from '@/views/business/finances/accruals/components/AccrualsTopCards.vue'
import AccrualsFilter from '@/views/business/finances/accruals/components/AccrualsFilter.vue'
import moment from 'moment'
import { filterDataType } from './types'

@Component({
  components: {
    AccrualsTopCards,
    AccrualsFilter,
    AccrualsTable,
  },
})
export default class Accruals extends Vue {
  // Data
  isPreloaderShow = false

  // Vuex module actions
  @VuexAction('nBusinessAccruals') moneyIncomesGetItems!: () => Promise<void>

  @VuexAction('nBusinessAccruals') moneyIncomesGetTotal!: (...args) => Promise<void>

  //  Vuex module mutation
  @VuexMutation('nBusinessAccruals') moneyIncomesListParamsUpdate!: (...args) => void

  //  Vuex module getters
  @VuexGetter('nApp') getAppId!: number

  // Methods
  tableChangePage(page: number): void {
    this.moneyIncomesListParamsUpdate({ page })
    this.moneyIncomesGetItems()
  }

  async filter(filterData: filterDataType): Promise<void> {
    this.moneyIncomesListParamsUpdate({
      ...filterData,
      page: 1,
    })
    await this.moneyIncomesGetItems()
  }

  async created(): Promise<void> {
    this.isPreloaderShow = true
    this.moneyIncomesListParamsUpdate({
      app: this.getAppId,
      created: [moment().subtract(13, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    })
    await this.moneyIncomesGetItems()
    await this.moneyIncomesGetTotal({ app: this.getAppId })
    this.isPreloaderShow = false
  }
}

<template>
  <div class="offer-slider">
    <component :is="`style`"> :root { --content: "{{ label }}" }</component>
    <div class="offer-slider-label">
      <div>{{ $t('men') }}</div>
      <div>{{ $t('women') }}</div>
    </div>
    <v-slider v-model="inputVal" max="100" min="0" track-color="#DCD6E7"> </v-slider>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'OfferSlider',
  data: () => ({}),
  props: {
    value: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    label() {
      return `${this.value}% / ${100 - this.value}%`
    },
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    onChange(val) {
      this.$emit('input', val)
    },
  },
})
</script>

<style lang="scss">
.offer-slider {
  padding: 24px;
  background: white;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  box-shadow: 0px 8px 20px var(--primary-dark-shadow);

  .offer-slider-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: var(--v-primary-base);
    font-size: 12px;
  }

  .v-input__slot {
    padding: initial !important;
    background: none !important;
    border: 0 !important;
    border-radius: 0 !important;
  }

  .v-slider__track-container {
    height: 4px !important;
  }

  .v-slider__thumb-label {
    top: 100% !important;
    bottom: auto !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .v-slider__thumb {
    width: 16px;
    height: 16px;
    border-radius: 4px;

    &:hover,
    &:focus,
    &:active {
      &:before {
        transform: translate(-50%, -50%) !important;
      }
    }
  }

  .v-slider__thumb:before {
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    background: url('data:image/svg+xml,%3Csvg width="6" height="6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 0h1v1H0V0zM5 0h1v1H5V0zM0 5h1v1H0V5zM5 5h1v1H5V5z" fill="%23fff"/%3E%3C/svg%3E')
      center;
    background-size: cover;
    border-radius: 0 !important;
    transform: translate(-50%, -50%);
    opacity: 1 !important;
  }

  .v-slider__thumb::after {
    top: 100%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: auto;
    margin-top: 8px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    transform: translateX(-52%);
    content: var(--content);
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"promo-popup-step-2"},[_c('img',{staticClass:"promo-popup__img mb-4",attrs:{"src":require('@/assets/images/subscriptions/stage2.png')}}),_c('div',{staticClass:"d-flex flex-column"},[_c('h4',{staticClass:"mb-6 text-primary"},[_vm._v(" "+_vm._s(_vm.$t('promoPopUp.createStockPage.step3.title'))+" ")]),_c('span',{staticClass:"mb-6 text-primary text-small lh-22"},[_vm._v(" "+_vm._s(_vm.$t('promoPopUp.createStockPage.step2.textTop.part1'))+" "),_c('strong',[_vm._v(_vm._s(_vm.$t('promoPopUp.createStockPage.step2.textTop.part2')))]),_vm._v(" "+_vm._s(_vm.$t('promoPopUp.createStockPage.step2.textTop.part3'))+" ")]),_c('app-v-card',{staticClass:"mb-6"},[_c('v-data-table',{staticClass:"promo-popup-step-2__table rounded",attrs:{"headers":_vm.tableHeaders,"items":_vm.formData.tariffs,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-sheet',{staticClass:"d-flex align-center",attrs:{"width":"200"}},[_c('icon',{staticClass:"mr-3 pl-1",attrs:{"color":"var(--accent-green)","data":require('@/assets/icons/bp-features/subscriptions/lock.svg')}}),(_vm.getAppCurrencySymbol === '₽')?_c('b',{staticClass:"mr-2 text-primary"},[_vm._v(_vm._s(_vm.$t('promoPopUp.3daysFree'))+" 1 "+_vm._s(_vm.getAppCurrencySymbol))]):_c('b',{staticClass:"mr-2 text-primary"},[_vm._v(_vm._s(_vm.$t('promoPopUp.3daysFreeV2'))+" ")]),_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"text-white d-flex align-center justify-center text-xx-small",attrs:{"rounded":"circle","width":"16","height":"16","color":"primary"}},'v-sheet',attrs,false),on),[_vm._v("?")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('soon')))])])],1)],1),_c('td',[(_vm.getCurrentApp.trialPeriodPermission !== 2)?_c('v-btn',{staticClass:"promo-popup-step-2__unlock-btn px-3",attrs:{"disabled":_vm.isTrialOnModeration,"width":"100%","color":"accent"},on:{"click":_vm.sendTrialRequest}},[_vm._v(" "+_vm._s(_vm.isTrialOnModeration ? _vm.$t('moderate') : _vm.$t('unlock'))+" ")]):_c('v-btn',{staticClass:"promo-popup-step-2__unlock-btn px-3",attrs:{"disabled":_vm.getAppCurrencySymbol === '$',"width":"100%","color":_vm.formData.trialPromoPageIsEnable ? 'var(--error)' : 'accent'},on:{"click":_vm.enableTrial}},[_vm._v(" "+_vm._s(_vm.formData.trialPromoPageIsEnable ? _vm.$t('trialOff') : _vm.$t('triallOn'))+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.$t('promoPopUp.3days')))]),_c('td',[_vm._v(_vm._s(_vm.$t('promoPopUp.duration')))])])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t(("pageBusinessDigital.tariffNew." + (item.period)))))])]}},{key:"item.amount",fn:function(ref){
var index = ref.index;
return [_c('app-v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#'.repeat(7)),expression:"'#'.repeat(7)"}],staticClass:"promo-popup-step-2__input",on:{"change":function($event){_vm.formData.isFixedTariffChanges = true}},model:{value:(_vm.formData.tariffs[index].amount),callback:function ($$v) {_vm.$set(_vm.formData.tariffs[index], "amount", $$v)},expression:"formData.tariffs[index].amount"}})]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("pageBusinessDigital.tariffNew." + (item.period))))+" ")]}},{key:"item.duration",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('promoPopUp.duration'))+" ")]},proxy:true}],null,true)})],1),_c('div',{staticClass:"d-flex align-center mb-10"},[_c('v-sheet',{staticClass:"mr-2 d-flex align-center justify-center text-white text-large rounded",attrs:{"min-width":"24","height":"54","color":"var(--accent-green)"}},[_c('b',[_vm._v("i")])]),_c('div',{staticClass:"text-primary text-xsmall"},[_vm._v(" "+_vm._s(_vm.$t('promoPopUp.createStockPage.step2.textBottom.part1'))+" "),_c('b',[_vm._v(" "+_vm._s(_vm.$t('promoPopUp.createStockPage.step2.textBottom.part2'))+" ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
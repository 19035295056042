











































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class PayoutSystemFormCard extends Vue {
  // Props
  @Prop(Boolean) readonly value!: boolean

  @Prop(Boolean) readonly errorEnteredCode!: boolean

  @Prop(Object) readonly formData!: any

  // Data
  code = ''

  // Watch
  @Watch('value')
  valueHandler(): void {
    if (!this.value) {
      this.code = ''
    }
  }

  @Watch('errorEnteredCode')
  errorEnteredCodeHandler(): void {
    if (this.errorEnteredCode) {
      this.code = ''
    }
  }

  // Emit
  @Emit()
  submit(): number | string {
    return this.code
  }

  @Emit()
  input(): boolean {
    return false
  }

  @Emit()
  repeatCall(): any {
    return this.formData
  }
}

<template>
  <div class="supster-ui--select__dropdown-items" :class="{ _sub: level > 0 }">
    <div
      class="supster-ui--select__dropdown-item"
      :class="{ _sub: level > 0 }"
      v-for="(item, index) in items"
      :key="`${level}-${index}`"
    >
      <div
        class="supster-ui--select__dropdown-item-text justify-space-between"
        :class="{ _sub: level > 0 }"
        @click="change(item, index)"
      >
        <div class="d-flex flex-column">
          <span class="supster-ui--select__dropdown-item-name">{{ getModelLabel(item) }}</span>
          <span
            v-if="item.parentId && level === 0"
            class="text-xsmall text-primary-light supster-ui--select__dropdown-item-name"
            >{{ getModelParrent(item) }}</span
          >
        </div>
        <icon
          v-if="haveSubItems(item) && isOpenSubItems(index)"
          class="supster-ui--select__dropdown-icon"
          data="@/assets/icons/chevron-down.svg"
        />
        <icon
          v-else-if="haveSubItems(item)"
          class="supster-ui--select__dropdown-icon"
          data="@/assets/icons/arrow-left.svg"
        />
      </div>
      <input-select-items
        v-if="haveSubItems(item) && isOpenSubItems(index)"
        :items="item[treeProp]"
        :itemText="itemText"
        :itemValue="itemValue"
        :treeProp="treeProp"
        :level="level + 1"
        @selectItem="change"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'InputSelectItems',
  data: () => ({
    openItems: [],
  }),
  props: {
    value: {
      default: '',
    },
    itemText: {
      default: 'label',
    },
    itemExtendedText: {
      default: false,
    },
    itemValue: {
      default: 'value',
    },
    items: {
      default() {
        return {}
      },
    },
    treeProp: {
      default: false,
    },
    selectProp: {
      default: false,
    },
    level: {
      default: 0,
    },
    parentCategory: {
      default: () => [],
    },
  },
  methods: {
    haveSubItems(item) {
      return item[this.treeProp] && item[this.treeProp].length
    },
    isOpenSubItems(index) {
      return this.openItems.includes(index)
    },
    openSubItems(index) {
      if (this.isOpenSubItems(index)) {
        const openIntex = this.openItems.indexOf(index)
        this.openItems.splice(openIntex, 1)
      } else {
        this.openItems.push(index)
      }
    },
    getModelLabel(item) {
      if (this.itemExtendedText) {
        return this.itemExtendedText(item)
      }
      return this.selectProp ? item[this.selectProp][this.itemText] : item[this.itemText]
    },
    getModelParrent(item) {
      const result = this.parentCategory.filter((category) => item.parentId === category.id)
      return result[0].name
    },
    change(item, index) {
      if (this.haveSubItems(item)) {
        this.openSubItems(index)
      } else {
        this.$emit('selectItem', item)
      }
    },
  },
})
</script>

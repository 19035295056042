<template>
  <v-dialog
    :max-width="type === 'range' ? 744 : 420"
    overlay-opacity="0.8"
    content-class="com-datepicker pa-10"
    :transition="false"
  >
    <template v-slot:activator="{ on }">
      <div
        class="com-datepicker__input"
        :class="{ 'with-days d-flex align-baseline flex-nowrap': filterOut, disabled: disabled }"
      >
        <app-v-text-field
          :disabled="disabled"
          :rules="rules"
          v-on="on"
          v-model="inputText"
          role="button"
          readonly
          :label="label"
          :placeholder="$t('chooseDate')"
          :clearable="clearable"
          @click:clear="clear"
        >
          <template v-slot:append>
            <icon
              color="var(--primary-dark)"
              v-on="on"
              class="cursor-pointer com-datepicker__input-icon-clear"
              data="@/assets/icons/date-picker.svg"
            ></icon>
          </template>
          <template #label>
            <slot name="label"></slot>
          </template>
        </app-v-text-field>
        <div v-if="filterOut" class="d-flex align-center com-datepicker__filter ml-4">
          <v-btn
            v-for="btn in filterButtons"
            :key="btn.days"
            x-small
            @click="filterChangeDates(btn.days, 'out')"
            class="com-datepicker__filter-btn mr-2"
            :color="filterButtonsVisibity === btn.days && inputText ? 'primary' : 'white'"
            height="20"
            >{{ btn.text }}</v-btn
          >
        </div>
      </div>
    </template>
    <template v-slot:default="dialog">
      <div class="com-datepicker__header mb-8">
        <div v-if="type !== 'single'" class="d-flex align-center com-datepicker__filter mb-4">
          <span class="com-datepicker__filter-title mr-2">{{ $t('showForThePeriod') }}</span>
          <v-btn
            v-for="btn in filterButtons"
            :key="btn.days"
            x-small
            @click="filterChangeDates(btn.days, 'in')"
            class="com-datepicker__filter-btn mr-2"
            :color="filterButtonsVisibity === btn.days ? 'primary' : 'primary'"
            :outlined="filterButtonsVisibity !== btn.days"
            height="20"
            >{{ btn.text }}</v-btn
          >
        </div>

        <h2 class="com-datepicker__title">{{ titleText }}</h2>
      </div>
      <div
        :class="type === 'single' ? 'justify-center' : 'justify-space-between'"
        class="com-datepicker__body d-flex align-center mb-8"
      >
        <v-date-picker
          v-model="startDate"
          first-day-of-week="1"
          color="var(--v-primary-base)"
          :locale="locale"
          scrollable
          no-title
          width="310"
          :max="currentDate"
          :events="type === 'range' ? rangeDates : null"
        >
        </v-date-picker>
        <icon v-if="type === 'range'" data="@/assets/icons/arrow-right.svg"></icon>
        <v-date-picker
          v-if="type === 'range'"
          v-model="endDate"
          first-day-of-week="1"
          color="var(--v-primary-base)"
          :locale="locale"
          scrollable
          no-title
          width="310"
          :max="currentDate"
          :events="rangeDates"
        >
        </v-date-picker>
      </div>
      <div class="com-datepicker__footer">
        <v-btn
          :class="type === 'single' ? 'ml-3' : ''"
          min-width="125"
          class="px-3"
          color="primary"
          @click="confirm(), closePopup(dialog)"
        >
          {{ $t('confirm') }}
        </v-btn>
        <icon
          class="com-datepicker__close"
          data="@/assets/icons/close.svg"
          @click="closePopup(dialog)"
        ></icon>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'AppDatePicker',
  props: {
    type: {
      type: String,
      default: 'single', // single, range
    },
    filterOut: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Array],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    initDefault: {
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  data: () => ({
    startDate: '',
    endDate: '',
    apiFormatDate: 'YYYY-MM-DD',
    inputFormatDate: 'DD.MM.YYYY',
    titleFormatDate: 'DD MMM YYYY',
    inputText: '',
    bufferDate: [],
  }),

  computed: {
    rangeDates() {
      const dates = []
      let date = moment(this.startDate).subtract(1, 'days').format(this.apiFormatDate)
      while (date === this.endDate || moment(date).isBefore(this.endDate)) {
        date = moment(date).add(1, 'days').format(this.apiFormatDate)
        dates.push(date)
      }
      dates.pop()
      return dates
    },

    titleText() {
      if (this.type === 'range') {
        return `${moment(this.startDate)
          .locale(this.locale)
          .format(this.titleFormatDate)} - ${moment(this.endDate)
          .locale(this.locale)
          .format(this.titleFormatDate)}
      `
      }
      return `${moment(this.startDate).locale(this.locale).format(this.titleFormatDate)}`
    },
    currentDate() {
      return moment().format(this.apiFormatDate)
    },
    locale() {
      return this.$i18n.locale === 'en' ? 'en' : 'ru'
    },
    filterButtonsVisibity() {
      return moment(this.endDate).diff(this.startDate, 'days')
    },
    filterButtons() {
      return [
        {
          days: 0,
          text: this.$t('dateFilterText.today'),
        },
        {
          days: 2,
          text: `3 ${this.$t('dateFilterText.days')}`,
        },
        {
          days: 6,
          text: this.$t('dateFilterText.week'),
        },
        {
          days: 13,
          text: `2 ${this.$t('dateFilterText.weeks')}`,
        },
      ]
    },
    dateResult() {
      let dateResult = []
      if (this.type === 'range') {
        dateResult.push(this.startDate)
        dateResult.push(this.endDate)
      } else {
        dateResult = this.startDate
      }
      return dateResult
    },
  },
  methods: {
    clear() {
      this.inputText = ''
      this.bufferDate = []
      this.$emit('input', [])
      this.$emit('change')
    },
    setInputText() {
      if (this.type === 'range') {
        this.inputText = `${moment(this.startDate).format(this.inputFormatDate)} - ${moment(
          this.endDate
        ).format(this.inputFormatDate)}
      `
        this.bufferDate[0] = this.startDate
        this.bufferDate[1] = this.endDate
      } else {
        this.inputText = `${moment(this.startDate).format(this.inputFormatDate)}`
      }
    },
    filterChangeDates(value, type) {
      if (value === 0) {
        this.startDate = this.currentDate
        this.endDate = this.currentDate
      }
      this.startDate = moment(this.currentDate).subtract(value, 'days').format(this.apiFormatDate)
      this.endDate = moment(this.startDate).add(value, 'days').format(this.apiFormatDate)
      if (this.filterOut && type === 'out') {
        this.confirm()
      }
    },
    closePopup(dialog) {
      this.startDate = this.bufferDate[0]
      this.endDate = this.bufferDate[1]
      dialog.value = false
    },
    confirm() {
      this.setInputText()
      this.$emit('input', this.dateResult)
      this.$emit('change')
    },
  },
  watch: {
    value() {
      if (this.type === 'single') {
        this.startDate = moment(this.value).format(this.apiFormatDate)
      } else {
        this.startDate = moment(this.value[0]).format(this.apiFormatDate)
        this.endDate = moment(this.value[1]).format(this.apiFormatDate)
      }
      this.setInputText()
    },
    endDate() {
      if (moment(this.endDate).isBefore(this.startDate)) {
        this.endDate = this.startDate
      }
    },
    startDate() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.endDate = this.startDate
      }
    },
  },
  mounted() {
    if (this.value.length) {
      if (this.type === 'single') {
        this.startDate = moment(this.value).format(this.apiFormatDate)
      } else {
        this.startDate = moment(this.value[0]).format(this.apiFormatDate)
        this.endDate = moment(this.value[1]).format(this.apiFormatDate)
      }
      this.setInputText()
    } else {
      this.startDate = this.currentDate
      this.endDate = this.currentDate
    }
    if (this.initDefault) {
      this.$emit('input', this.dateResult)
    }
  },
})
</script>

<style lang="scss">
.v-overlay__scrim {
  background: rgba(39, 20, 96, 0.8) !important;
  backdrop-filter: blur(4px) !important;
}

.com-datepicker {
  position: relative;
  background: var(--white);
  &__input {
    input::placeholder {
      color: var(--primary-dark) !important;
    }
    &.disabled {
      cursor: default;
      pointer-events: none;
    }
  }
  &__input-icon-clear {
    position: static !important;
    margin-top: 11px;
    margin-right: 4px;
  }
  &__input.with-days {
    & > .v-input {
      max-width: 256px;
    }
  }
  &__title {
    color: var(--v-primary-base);
    font-weight: 800;
    line-height: 30px;
  }
  &__filter {
    &-title {
      color: var(--primary-light);
      font-size: 12px;
    }
    &-btn.primary--text {
      font-weight: normal !important;
    }
    &-btn.default {
      color: var(--v-primary-base) !important;
      font-weight: normal !important;
      background: var(--white) !important;
      border: 1px solid #271460 !important;
    }
    &-btn.white {
      color: var(--v-primary-base) !important;
      font-weight: normal !important;
    }
  }
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    fill: var(--v-primary-base) !important;
  }
  .v-picker {
    border: 1px solid var(--light-gray);
    &__body {
      & > div {
        background: var(--ground-light);
      }
    }
  }
  .v-date-picker-header {
    padding: 3px 16px 4px 16px;
    background: transparent !important;
    box-shadow: inset 0px -1px 0px #dcd6e7;
    &__value {
      button {
        color: inherit !important;
        font-weight: 700 !important;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
  .v-date-picker-table {
    height: 282px;
    padding: 0 16px;
    padding-bottom: 15px;
    background: transparent !important;
    & table {
      border-collapse: collapse;
      th {
        width: 39px;
        height: 39px;
        color: var(--primary-light) !important;
        font-weight: 400 !important;
      }
      td {
        width: 40px;
        height: 40px;
        .v-btn {
          width: 39px;
          height: 39px;
          color: var(--v-primary-base) !important;
          border-radius: 4px;
          &:focus {
            &::before {
              opacity: 0 !important;
            }
          }
          .v-btn__content {
            position: relative;
            z-index: 10;
            font-weight: normal;
            background: transparent;
          }

          &.v-date-picker-table__current {
            border: 1px solid var(--v-primary-base);
            .v-date-picker-table__events {
              z-index: -1;
            }
          }
          &.v-btn--flat {
            .v-btn__content {
              color: var(--light-gray) !important;
            }
          }
          &.v-btn--active {
            color: var(--primary-light) !important;
            background: var(--v-primary-base);

            .v-btn__content {
              color: var(--white);
              font-weight: bold !important;
            }
            .v-date-picker-table__events {
              z-index: -1;
            }
          }
        }
      }
    }
  }
  .v-date-picker-table--month,
  .v-date-picker-table--table {
    display: flex;
  }
  .v-date-picker-table__events {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: var(--ground);
    border: 1px solid var(--ground);
    border-radius: 4px;
    .warning {
      display: none;
    }
  }
  .v-date-picker-years li {
    color: var(--v-primary-base);
  }
}
</style>

// mixins.ts

import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class ChaptersList extends Vue {
  get chapterslist(): Array<{
    chapterName: TranslateResult
    parrentCategoryId: number
    chapterId: number
    childChapterId?: number
    parrentCategoryName: TranslateResult
    ref: string
  }> {
    return [
      {
        chapterName: this.$t('faq.sections.0.chapter.0.title'),
        parrentCategoryId: 0,
        chapterId: 0,
        parrentCategoryName: this.$t('faq.sections.0.title'),
        ref: 'chapterZero',
      },
      {
        chapterName: this.$t('faq.sections.0.chapter.1.title'),
        parrentCategoryId: 0,
        chapterId: 1,
        parrentCategoryName: this.$t('faq.sections.0.title'),
        ref: 'chapterOne',
      },
      {
        chapterName: this.$t('faq.sections.0.chapter.2.title'),
        parrentCategoryId: 0,
        chapterId: 2,
        parrentCategoryName: this.$t('faq.sections.0.title'),
        ref: 'chapterTwo',
      },
      {
        chapterName: this.$t('faq.sections.0.chapter.3.title'),
        parrentCategoryId: 0,
        chapterId: 3,
        parrentCategoryName: this.$t('faq.sections.0.title'),
        ref: 'chapterThree',
      },
      {
        chapterName: this.$t('faq.sections.0.chapter.3.childChapter.0.title'),
        parrentCategoryId: 0,
        chapterId: 3,
        childChapterId: 0,
        parrentCategoryName: this.$t('faq.sections.0.title'),
        ref: 'chapterThreeZero',
      },
      {
        chapterName: this.$t('faq.sections.0.chapter.3.childChapter.1.title'),
        parrentCategoryId: 0,
        chapterId: 3,
        childChapterId: 1,
        parrentCategoryName: this.$t('faq.sections.0.title'),
        ref: 'chapterThreeOne',
      },
      {
        chapterName: this.$t('faq.sections.0.chapter.3.childChapter.2.title'),
        parrentCategoryId: 0,
        chapterId: 3,
        childChapterId: 2,
        parrentCategoryName: this.$t('faq.sections.0.title'),
        ref: 'chapterThreeTwo',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.0.title'),
        parrentCategoryId: 1,
        chapterId: 0,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterZero',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.1.title'),
        parrentCategoryId: 1,
        chapterId: 1,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterOne',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.2.title'),
        parrentCategoryId: 1,
        chapterId: 2,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterTwo',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.3.title'),
        parrentCategoryId: 1,
        chapterId: 3,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterThree',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.4.title'),
        parrentCategoryId: 1,
        chapterId: 4,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterFour',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.5.title'),
        parrentCategoryId: 1,
        chapterId: 5,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterFive',
      },
      {
        chapterName: 'ASO',
        parrentCategoryId: 1,
        chapterId: 6,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterSix',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.6.childChapter.0.title'),
        parrentCategoryId: 1,
        chapterId: 6,
        childChapterId: 0,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterSixZero',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.6.childChapter.1.title'),
        parrentCategoryId: 1,
        chapterId: 6,
        childChapterId: 1,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterSixOne',
      },
      {
        chapterName: this.$t('faq.sections.1.chapter.6.childChapter.2.title'),
        parrentCategoryId: 1,
        chapterId: 6,
        childChapterId: 2,
        parrentCategoryName: this.$t('faq.sections.1.title'),
        ref: 'chapterSixTwo',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.0.title'),
        parrentCategoryId: 2,
        chapterId: 0,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterZero',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.1.title'),
        parrentCategoryId: 2,
        chapterId: 1,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterOne',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.2.title'),
        parrentCategoryId: 2,
        chapterId: 2,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterTwo',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.3.title'),
        parrentCategoryId: 2,
        chapterId: 3,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterThree',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.4.title'),
        parrentCategoryId: 2,
        chapterId: 4,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterFour',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.5.title'),
        parrentCategoryId: 2,
        chapterId: 5,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterFive',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.6.title'),
        parrentCategoryId: 2,
        chapterId: 6,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterSix',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.7.title'),
        parrentCategoryId: 2,
        chapterId: 7,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterSeven',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.8.title'),
        parrentCategoryId: 2,
        chapterId: 8,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterEight',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.9.title'),
        parrentCategoryId: 2,
        chapterId: 9,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterNine',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.10.title'),
        parrentCategoryId: 2,
        chapterId: 10,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterTen',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.11.title'),
        parrentCategoryId: 2,
        chapterId: 11,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterEleven',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.12.title'),
        parrentCategoryId: 2,
        chapterId: 12,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterTwelve',
      },
      {
        chapterName: this.$t('faq.sections.2.chapter.13.title'),
        parrentCategoryId: 2,
        chapterId: 13,
        parrentCategoryName: this.$t('faq.sections.2.title'),
        ref: 'chapterThirteen',
      },
      // {
      //   chapterName: this.$t('faq.sections.3.chapter.0.title'),
      //   parrentCategoryId: 3,
      //   chapterId: 0,
      //   parrentCategoryName: this.$t('faq.sections.3.title'),
      //   ref: 'chapterZero',
      // },
      // {
      //   chapterName: this.$t('faq.sections.3.chapter.0.childChapter.0.title'),
      //   parrentCategoryId: 3,
      //   chapterId: 0,
      //   childChapterId: 0,
      //   parrentCategoryName: this.$t('faq.sections.3.title'),
      //   ref: 'chapterZeroZero',
      // },
      // {
      //   chapterName: this.$t('faq.sections.3.chapter.0.childChapter.1.title'),
      //   parrentCategoryId: 3,
      //   chapterId: 0,
      //   childChapterId: 1,
      //   parrentCategoryName: this.$t('faq.sections.3.title'),
      //   ref: 'chapterZeroOne',
      // },
      // {
      //   chapterName: this.$t('faq.sections.3.chapter.0.childChapter.2.title'),
      //   parrentCategoryId: 3,
      //   chapterId: 0,
      //   childChapterId: 2,
      //   parrentCategoryName: this.$t('faq.sections.3.title'),
      //   ref: 'chapterZeroTwo',
      // },
      // {
      //   chapterName: this.$t('faq.sections.3.chapter.1.title'),
      //   parrentCategoryId: 3,
      //   chapterId: 1,
      //   parrentCategoryName: this.$t('faq.sections.3.title'),
      //   ref: 'chapterOne',
      // },
      // {
      //   chapterName: this.$t('faq.sections.3.chapter.2.title'),
      //   parrentCategoryId: 3,
      //   chapterId: 2,
      //   parrentCategoryName: this.$t('faq.sections.3.title'),
      //   ref: 'chapterTwo',
      // },
      // {
      //   chapterName: this.$t('faq.sections.3.chapter.3.title'),
      //   parrentCategoryId: 3,
      //   chapterId: 3,
      //   parrentCategoryName: this.$t('faq.sections.3.title'),
      //   ref: 'chapterThree',
      // },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZero',
      },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.childChapter.0.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        childChapterId: 0,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZeroZero',
      },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.childChapter.1.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        childChapterId: 1,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZeroOne',
      },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.childChapter.2.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        childChapterId: 2,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZeroOne',
      },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.childChapter.3.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        childChapterId: 3,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZeroThree',
      },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.childChapter.4.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        childChapterId: 4,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZeroZero',
      },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.childChapter.5.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        childChapterId: 5,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZeroFive',
      },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.childChapter.6.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        childChapterId: 6,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZeroSix',
      },
      // {
      //   chapterName: this.$t('faq.sections.4.chapter.0.childChapter.7.title'),
      //   parrentCategoryId: 4,
      //   chapterId: 0,
      //   childChapterId: 7,
      //   parrentCategoryName: this.$t('faq.sections.4.title'),
      //   ref: 'chapterZeroSeven',
      // },
      {
        chapterName: this.$t('faq.sections.4.chapter.0.childChapter.8.title'),
        parrentCategoryId: 4,
        chapterId: 0,
        childChapterId: 8,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterZeroEight',
      },
      {
        chapterName: this.$t('faq.sections.4.chapter.1.title'),
        parrentCategoryId: 4,
        chapterId: 1,
        parrentCategoryName: this.$t('faq.sections.4.title'),
        ref: 'chapterOne',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.0.title'),
        parrentCategoryId: 5,
        chapterId: 0,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterZero',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.1.title'),
        parrentCategoryId: 5,
        chapterId: 1,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterOne',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.1.childChapter.0.title'),
        parrentCategoryId: 5,
        chapterId: 1,
        childChapterId: 0,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterOneZero',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.1.childChapter.1.title'),
        parrentCategoryId: 5,
        chapterId: 1,
        childChapterId: 1,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterOneOne',
      },

      {
        chapterName: this.$t('faq.sections.5.chapter.2.title'),
        parrentCategoryId: 5,
        chapterId: 2,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterTwo',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.2.childChapter.0.title'),
        parrentCategoryId: 5,
        chapterId: 2,
        childChapterId: 0,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterTwoZero',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.3.title'),
        parrentCategoryId: 5,
        chapterId: 3,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterThree',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.3.childChapter.0.title'),
        parrentCategoryId: 5,
        chapterId: 3,
        childChapterId: 0,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterThreeZero',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.3.childChapter.1.title'),
        parrentCategoryId: 5,
        chapterId: 3,
        childChapterId: 1,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterThreeOne',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.4.title'),
        parrentCategoryId: 5,
        chapterId: 4,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterFour',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.4.childChapter.0.title'),
        parrentCategoryId: 5,
        chapterId: 4,
        childChapterId: 0,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterFourZero',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.4.childChapter.1.title'),
        parrentCategoryId: 5,
        chapterId: 4,
        childChapterId: 1,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterFourOne',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.4.childChapter.2.title'),
        parrentCategoryId: 5,
        chapterId: 4,
        childChapterId: 2,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterFourTwo',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.5.title'),
        parrentCategoryId: 5,
        chapterId: 5,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterFive',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.5.childChapter.0.title'),
        parrentCategoryId: 5,
        chapterId: 5,
        childChapterId: 0,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterFiveZero',
      },
      {
        chapterName: this.$t('faq.sections.5.chapter.5.childChapter.1.title'),
        parrentCategoryId: 5,
        chapterId: 5,
        childChapterId: 1,
        parrentCategoryName: this.$t('faq.sections.5.title'),
        ref: 'chapterFiveOne',
      },
      // {
      //   chapterName: this.$t('faq.sections.5.chapter.5.childChapter.2.title'),
      //   parrentCategoryId: 5,
      //   chapterId: 5,
      //   childChapterId: 2,
      //   parrentCategoryName: this.$t('faq.sections.5.title'),
      //   ref: 'chapterFiveTwo',
      // },
    ]
  }
}















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import Adjust from '@/utils/color'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { TranslateResult } from 'vue-i18n'
import PostPreview from '@/components/PostPreview.vue'

@Component({
  components: { PostPreview },
})
export default class DefaultAppPreview extends Vue {
  //  Props
  @Prop({ required: true, type: Object }) readonly app!: AppsAppModelType

  @Prop({ type: Object }) readonly avatar!: any

  @Prop({ type: Array }) readonly posts!: PostsAppModelType[]

  @Prop({ default: false, type: Boolean }) readonly edit!: boolean

  @Prop(String) readonly pageType!: string

  @Prop(String) readonly editMode!: string

  // Data
  adjust = Adjust

  selectedPostIndex = 0

  get isGptApp(): boolean {
    return this.app?.type === 'chat_gpt'
  }

  get color(): string {
    return this.app?.primary_color ?? '#EDD031'
  }

  get avatarSrc(): string {
    return this.avatar
      ? this.avatar?.media_objects[0]?.preview_url ?? this.avatar?.media_objects[0]?.content_url
      : this.app?.image?.preview_url ?? this.app?.image?.content_url
  }

  get description(): string {
    if (this.app.description) {
      return this.app.description.split(' ').length > 1
        ? `${this.app.description.slice(0, 51)}...`
        : `${this.app.description.slice(0, 40)}...`
    }
    return ''
  }

  get isEditModeAvatar(): boolean {
    return this.editMode === 'avatar'
  }

  get isEditModePosts(): boolean {
    return this.editMode === 'posts'
  }

  get generatedPosts(): any {
    const arr: any = []
    for (let i = 0; i < 6; i++) {
      if (this.posts[i]) {
        arr.push(this.posts[i])
      } else {
        arr.push({
          id: i,
          placeholder: true,
          comment_count: 0,
          like_count: 0,
        })
      }
    }
    return arr
  }

  get gptAvdantages(): Array<{
    icon: string
    text: TranslateResult
  }> {
    return [
      {
        icon: '@/assets/icons/advantages-gpt-1.svg',
        text: this.$t('advantagesGpt.1'),
      },
      {
        icon: 'advantages-gpt-2',
        text: this.$t('advantagesGpt.2'),
      },
      {
        icon: 'advantages-gpt-3',
        text: this.$t('advantagesGpt.1'),
      },
      {
        icon: 'advantages-gpt-4',
        text: this.$t('advantagesGpt.4'),
      },
    ]
  }

  @Watch('isEditModePosts')
  isEditModePostsHandler(): void {
    if (this.isEditModePosts) {
      this.selectPostForEdit(this.posts[0], 0)
    }
  }

  @Emit('selectPost')
  selectPostForEdit(
    item: PostsAppModelType,
    index: number
  ): { item: PostsAppModelType; index: number } {
    this.selectedPostIndex = index
    return { item, index }
  }
}

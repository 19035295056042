




































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter } from '@/store/decorators'
import { UserAppModelType } from '@/services/api/User/UserTypes'
import NotificationProvider from '@/providers/NotificationProvider'
import { ResponseTypes } from '@/services/api/BaseApiService'

@Component({})
export default class Profile extends Vue {
  formData: {
    password: string
    passwordConfirm: string
  } = {
    password: '',
    passwordConfirm: '',
  }

  @VuexGetter('nApp') getAuthUser!: UserAppModelType

  // Vuex module action
  @VuexAction('nProfile') updateUser!: (...args) => Promise<ResponseTypes>

  async saveData(): Promise<void> {
    if (this.formData.password.includes(' ') || this.formData.passwordConfirm.includes(' ')) {
      NotificationProvider.add({
        type: 'error',
        message: this.$t('allertMessage.passwordSpaceError'),
      })
      return
    }
    if (this.formData.password !== this.formData.passwordConfirm) {
      NotificationProvider.add({
        type: 'error',
        message: this.$t('allertMessage.passwordMatchError'),
      })
      return
    }
    if (this.formData.password.length < 7) {
      NotificationProvider.add({
        type: 'error',
        message: this.$t('shortPassword'),
      })
      return
    }
    await this.updateUser({
      id: this.getAuthUser.id,
      data: {
        plainpassword: this.formData.password,
      },
      notification: {
        enableError: false,
        enableSuccess: true,
        successText: this.$t('allertMessage.passwordUpdateSuccess'),
      },
    })
  }
}

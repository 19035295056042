import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { BillingRequestAppModelType } from './BillingRequestTypes'

class BillingRequestService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.bilingRequest')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.BILLING_REQUEST)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 30,
    page: 1,
  }

  private usedListParams = [
    'itemsPerPage',
    'page',
    'created[after]',
    'created[before]',
    'app',
    'status',
    'id',
    'client',
    'tariff',
  ]

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public async transactionsUpdateItemCancel(params: any): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.BILLING_REQUEST}/${params.id}/cancel`
      const response = await this.api.put(requestUrl, params)
      const result = {
        data: response.data,
      }
      this.addNotification({
        message: `${i18n.t('allertMessage.recordUpdateSuccess', {
          model: this.modelName,
          id: params.id,
        })}`,
      })
      return { status: true, data: result.data }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordUpdateError', { model: this.modelName, id: params.id })}`
      )
      return { status: false, data: {}, error }
    }
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData): BillingRequestAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)

    if (incomingParams.created && incomingParams.created.length > 0) {
      params['created[after]'] = incomingParams.created[0]
      params['created[before]'] = `${incomingParams.created[1]} 23:59:59`
    }

    return params
  }
}
export default new BillingRequestService()

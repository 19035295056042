<template>
  <app-v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :items="items"
    :search-input.sync="searchAutocomplete"
    :loading="loading"
    :item-text="itemText"
    :item-value="itemValue"
    clearable
    hide-details
    hide-no-data
    :error="error"
    return-object
    :backgroundColor="backgroundColor"
  >
    <template #label>
      <slot name="label"></slot>
    </template>
  </app-v-autocomplete>
</template>

<script>
export default {
  name: 'AppVAutocompleteLoadData',
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    itemText: {
      type: [String, Function],
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    loadData: {
      type: Function,
      required: true,
    },
    error: {
      type: [String, Object, Boolean],
      default: null,
    },
    backgroundColor: {
      type: String,
      default: 'grey lighten-2',
    },
  },
  data() {
    return {
      items: [],
      searchAutocomplete: null,
      loading: false,
    }
  },
  watch: {
    async searchAutocomplete(val) {
      await this.load(val)
    },
  },
  async mounted() {
    if (this.value) {
      await this.load(this.value)
    }
  },
  methods: {
    async load(val) {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const { data } = await this.loadData(val)
        this.items = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>

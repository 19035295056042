import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import DeliveryMethodsService from '@/services/api/DeliveryMethods/DeliveryMethodsService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'

interface StoreModelAppTypes {
  app: string | number
  user: string | number
  name: string
  refundInstruction: string
  region: string
  city: string
  street: string
  house: string
  housing: string
  postcode: string
  phone: string
  workDays: [] | [number]
  deliveryMethods: [] | [number]
  deliveryRegions: [] | [number]
  deliveryDelay: number
  deliveryServices: number
}
interface StoreModelApiTypes {
  app: string
  user: string
  name: string
  refundInstruction: string
  region: string
  city: string
  street: string
  house: string
  housing: string
  postcode: string
  phone: string
  workDays: string
  deliveryMethods: []
  deliveryRegions: []
  deliveryDelay: number
}
interface RequestParams {
  app?: string
}

class StoresService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.storage')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: StoreModelAppTypes = {
    app: 0,
    user: 0,
    name: '',
    refundInstruction: '',
    region: '',
    city: '',
    street: '',
    house: '',
    housing: '',
    postcode: '',
    phone: '',
    deliveryMethods: [],
    deliveryRegions: [],
    deliveryDelay: 12,
    workDays: [],
    deliveryServices: 0,
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = { DeliveryMethodsService }
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.STORES)
  }

  public getEmptyItem(): StoreModelAppTypes {
    const data = cloneDeep(this.AppModelPropsDefault)
    return data
  }

  public transformDataForApi(incomingData): StoreModelApiTypes {
    const data = cloneDeep(incomingData)

    data.user = `${ENDPOINTS.USERS}/${data.user}`
    data.app = `${ENDPOINTS.APPS}/${data.app}`
    data.workDays = JSON.stringify(data.workDays)
    data.deliveryDelay = Number(data.deliveryDelay)
    data.postcode = Number(data.postcode)
    data.phone = data.phone.replace(/[^0-9]/g, '')

    data.deliveryMethods = data.deliveryMethods.map(
      (item) => `${ENDPOINTS.DELIVERY_METHODS}/${item.id}`
    )
    data.deliveryRegions = data.deliveryRegions.map(
      (item) => `${ENDPOINTS.DELIVERY_REGIONS}/${item.id}`
    )

    return data
  }

  public transformDataForApp(data): StoreModelAppTypes {
    data.workDays = this.jsonParse(data.workDays)
    data.workDays = Array.isArray(data.workDays) ? data.workDays : []
    data.postcode = data.postcode.toString()

    if (data.deliveryMethods.length) {
      data.deliveryMethods.map((item) =>
        this.Related.DeliveryMethodsService.transformDataForApp(item)
      )
      data.deliveryServices = data.deliveryMethods[0].deliveryService
    }
    return data
  }

  public transformRequestParams(params: RequestParams): RequestParams {
    const transformedParams = cloneDeep(params)
    if (params.app) {
      transformedParams.app = `${ENDPOINTS.APPS}/${params.app}`
    }
    return transformedParams
  }
}
export default new StoresService()

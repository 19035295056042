import Vue from 'vue'

import { DialoguesMutations } from './types'

const mutations = {
  setClientListItems(state, { items, pages, page }) {
    state.clients.list.items = items
    if (page === 1) {
      state.clients.list.itemsAll = items
    } else {
      state.clients.list.itemsAll.push(...items)
    }
    state.clients.list.params.pages = pages
    state.clients.list.params.page = page
  },
  clientFilterChangeHasMessage(state) {
    state.clients.list.params.has_message = !state.clients.list.params.has_message
  },
  clientListLoad(state, value) {
    state.clients.list.params.loading = value
  },
  setChannelListItems(state, { items }) {
    state.clients.list.items = items
  },
  setGroupChatsListItems(state, { items }) {
    state.groupChats.list.items = items
  },
  dialogCreate(state, { page = 1, channel = {}, client = {}, messages, active = true }) {
    const newMessages = messages.data.reverse()
    const pages = Math.ceil(messages.message_total / 10)
    let messagesItems: any = []
    if (page === 1) {
      messagesItems = newMessages
    } else {
      messagesItems = [...newMessages, ...state.dialog.messages.items]
    }
    state.dialog.active = active
    state.dialog.channel = channel
    state.dialog.client = client
    state.dialog.messages.page = page
    state.dialog.messages.pages = pages
    state.dialog.messages.items = messagesItems
    state.dialog.messages.total = messages.message_total
    state.dialog.messages.total_new = messages.message_total_new
  },
  dialogUpdateMessages(state, { messages, page }) {
    const newMessages = messages.data.reverse()
    const pages = Math.ceil(messages.message_total / 10)
    let messagesItems: any = []
    if (page === 1) {
      messagesItems = newMessages
    } else {
      messagesItems = [...newMessages, ...state.dialog.messages.items]
    }
    state.dialog.messages.items = messagesItems
    state.dialog.messages.total = messages.message_total
    state.dialog.messages.total_new = messages.message_total_new
    state.dialog.messages.page = page
    state.dialog.messages.pages = pages
  },
  dialogUpdateChannel(state, { channel }) {
    state.dialog.channel = channel.data
  },
  dialogMessageLoading(state, isLoading) {
    state.dialog.messages.loading = isLoading
  },
}

export default mutations

import { mapServerSideUser } from '@/utils/api.mapper'
import { getInstance } from '@/utils/axios'
import { ApiError } from '@/utils/axios.interceptors'
import { LocaleMessages } from 'vue-i18n'
import i18n from '@/plugins/i18n'
import { User } from './types'

export interface LoginRequest {
  email: string
  password: string
}

export interface LoginResponse {
  client_access_token: string
  user: User
  token: {
    accessToken: string
  }
}

export interface RegisterRequest {
  firstName: string
  lastName: string
  middleName: string
  email: string
  password: string
  passwordConfirmation: string
  phoneNumber: string
  positionId: string
  gender: string
}

export interface RegisterResponse {
  user: User
  client_access_token: string
  token: {
    accessToken: string
  }
}

export type validationRuleResult = boolean | string | LocaleMessages

const regexpNumber = new RegExp('^[0-9()+-]{11,}$')
const engLetter = new RegExp('^[a-zA-Z0-9@]+$')

export const rules = {
  required: (value: string): validationRuleResult => !!value || i18n.t('fieldIsRequired'),
  passwordLength: (value: string): validationRuleResult =>
    value.length >= 7 || i18n.t('shortPassword'),
  nameLength: (value: string): validationRuleResult => value.length >= 3 || i18n.t('shortName'),
  telegramLength: (value: string): validationRuleResult =>
    value.length >= 5 || i18n.t('shortTelegram'),

  email: (value: string): validationRuleResult =>
    (value.includes('@') && value.includes('.')) || i18n.t('invalidEmail'),
  trimSpaces: (value: string): validationRuleResult =>
    value.indexOf(' ') < 0 || i18n.t('rule_error_password_spaces'),
  phone: (value: string): validationRuleResult =>
    regexpNumber.test(value) || i18n.t('invalidPhone'),
  engLetterAndNumbers: (value: string): validationRuleResult =>
    engLetter.test(value) || i18n.t('valiationMessages.onlyEnglishLetterAndNumbers'),
}

export const login = async (request: LoginRequest): Promise<LoginResponse> => {
  const { data } = await getInstance().post('api/auth/login', request)

  if (!data.access_token) {
    throw new ApiError()
  }

  return {
    token: {
      accessToken: data.access_token,
    },
    user: mapServerSideUser(data.user),
    client_access_token: data.client_access_token,
  }
}

export const register = async (request: RegisterRequest): Promise<RegisterResponse> => {
  const { data } = await getInstance().post('api/auth/login', {
    first_name: request.firstName,
    last_name: request.lastName,
    middle_name: request.middleName,
    email: request.email,
    password: request.password,
    password_confirmation: request.passwordConfirmation,
    phone_number: request.phoneNumber,
    position_id: request.positionId,
    gender: request.gender,
  })

  return {
    user: mapServerSideUser(data.user),
    client_access_token: data.client_access_token,
    token: {
      accessToken: data.access_token,
    },
  }
}

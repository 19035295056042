



























































































































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import NotificationProvider from '@/providers/NotificationProvider'
import { ClientsAppModelType } from '@/services/api/Clients/ClientsTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'

@Component
export default class Links extends Vue {
  @VuexGetter('nApp') getAuthClient!: ClientsAppModelType

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  @VuexGetter('nBusinessReferral') getReferralStats!: any

  copyInputValue(value: string): any {
    return () => {
      NotificationProvider.add({
        type: 'success',
        message: this.$t('copyToBuffer'),
      })
      return value
    }
  }

  get currentAppLink(): string {
    return `https://${this.getCurrentApp.name.toLowerCase()}.${
      process.env.VUE_APP_INSTA_APP_DOMAIN
    }?ref-id=${this.clientId}`
  }

  get clientId(): any {
    return this.getAuthClient?.id
  }
}

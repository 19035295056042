import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'

interface ProductStoresModelAppTypes {
  id?: number
  store: {
    id?: number
    name: string
  }
  price: number
  rest: number
}
interface ProductStoresModelApiTypes {
  id?: number
  store: string
  product: string
  price: 0
  rest: number
}

class ProductStoresService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.productsStores')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ProductStoresModelAppTypes = {
    store: {
      name: '',
    },
    price: 0,
    rest: 0,
  }

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PRODUCT_STORES)
  }

  public getEmptyItem(): ProductStoresModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(incomingData): ProductStoresModelApiTypes {
    const data = cloneDeep(incomingData)
    data.store = `${ENDPOINTS.STORES}/${data.store.id}`
    if ('product' in data) {
      data.product = `${ENDPOINTS.PRODUCTS}/${data.product}`
    }
    data.price = Number(data.price)
    data.rest = Number(data.rest)
    return data
  }

  public transformDataForApp(data): ProductStoresModelAppTypes {
    return data
  }

  public transformRequestParams(params: unknown): unknown {
    return params
  }
}
export default new ProductStoresService()

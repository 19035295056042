import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isServer } from '@/utils/isServer'

type RequestInterceptor = (response: AxiosRequestConfig) => AxiosRequestConfig

type ResponseInterceptor<T = Record<string, unknown>> = (
  response: AxiosResponse<T>
) => AxiosResponse<T>

interface Violation {
  message: string
  propertyPath: string
}

export class ApiError extends Error {
  violations!: Record<string, string>

  static defaultMessage = 'Что-то пошло не так!'

  constructor(violations?: Violation[] | string) {
    if (Array.isArray(violations)) {
      super(
        violations.length
          ? `${violations[0].propertyPath}: ${violations[0].message}`
          : ApiError.defaultMessage
      )
      this.violations = violations.reduce(
        (agg, cur) => ({ ...agg, [cur.propertyPath]: cur.message }),
        {}
      )
    } else {
      super(violations ?? 'Что-то пошло не так!')
      this.violations = {}
    }
  }
}

export const withResponseLogging: ResponseInterceptor = (response) => {
  console.info(`RESPONSE::'${response.config.url}'`, response.data)

  return response
}

export const withRequestSubDomain: RequestInterceptor = (request) => {
  const { baseURL } = request
  const parts = isServer() ? [] : window.location.host.split('.')

  if (!baseURL) {
    return request
  }

  if (parts.length < 2) {
    return { ...request, baseURL: baseURL.replace('*', 'crm') }
  }

  return { ...request, baseURL: baseURL.replace('*', parts[0]) }
}

// eslint-disable-next-line
export const withResponseValidationErrors = (err: any) => {
  if (err?.response?.data?.violations) {
    return Promise.reject(new ApiError(err?.response?.data?.violations))
  }

  return Promise.reject(err)
}




















































































































































































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import { OfferAppModelType } from '@/services/api/Offer/OfferTypes'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'
import { ОfferSourcesAppModelType } from '@/services/api/ОfferSources/ОfferSourcesTypes'
import OfferDetailItem from '@/views/affiliate/offers/offersItem/components/OfferDetailItem.vue'
import StreamsList from '@/views/affiliate/offers/offersItem/components/StreamsList.vue'
import AppPreview from '@/components/appPreview/AppPreview.vue'

import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'

@Component({
  components: {
    OfferDetailItem,
    AppPreview,
    StreamsList,
  },
})
export default class OffersItem extends Vue {
  // Data
  isPreloaderShow = false

  // Vuex module action
  @VuexAction('nApp') fetchAppPreviews!: (...args) => Promise<void>

  @VuexAction('nAffiliateOffers') offersGetItem!: (...args) => Promise<void>

  @VuexAction('nAffiliateOffers') offerSourcesGetItems!: () => Promise<void>

  // Vuex module mutation
  @VuexMutation('nAffiliateOffers') setIsOpenCreateStreamFormStatus!: (...args) => void

  // Vuex module getter

  @VuexGetter('nApp') getAppPreviews!: PostsAppModelType[]

  @VuexGetter('nAffiliateOffers') getOffersCurrentItem!: OfferAppModelType

  @VuexGetter('nAffiliateOffers') getStreamsItems!: StreamAppModelType[]

  @VuexGetter('nAffiliateOffers') getOfferSoucesItems!: ОfferSourcesAppModelType[]

  // Computed
  get currentAppLink(): string {
    return `https://${
      this.getOffersCurrentItem.app.name ? this.getOffersCurrentItem.app.name.toLowerCase() : ''
    }.${process.env.VUE_APP_INSTA_APP_DOMAIN}`
  }

  get description(): string[] {
    return [
      this.getOffersCurrentItem.benefits,
      this.getOffersCurrentItem.aboutCompany,
      this.getOffersCurrentItem.workingConditions,
    ]
  }

  get filterPickedSourses(): { id: number; name: string; status: boolean }[] {
    return this.getOfferSoucesItems.map((item) => {
      return {
        id: item.id,
        name: item.name,
        status: this.getOffersCurrentItem.sources.some((s) => s.name === item.name),
      }
    })
  }

  // Methods
  openCreateStreamForm(): void {
    this.setIsOpenCreateStreamFormStatus(true)
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.offersGetItem(this.$route.params.id)
    await this.fetchAppPreviews(this.getOffersCurrentItem.app.id)
    await this.offerSourcesGetItems()
    this.isPreloaderShow = false
  }
}

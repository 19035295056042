














































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'

@Component({})
export default class PromoPagesTable extends Vue {
  // Data
  selectedItemId = 0

  money: any

  // Computed
  get tableHeaders(): any {
    return [
      { text: '№', value: 'id' },
      { text: this.$t('title'), value: 'name' },
      {
        text: `${this.$t('price')}/${this.$t('frequency')}`,
        value: 'tariffs',
        class: 'promo-pages-table__tariff',
      },
      {
        text: this.$t('pageAffiliateStats.header.activeSubscribes'),
        value: 'active',
        class: 'text-end',
      },
      { text: this.$t('totalIncome'), value: 'sum', class: 'text-end' },
      { text: '', value: '' },
      { text: '', value: 'actions', sortable: false, width: '112px' },
    ]
  }

  // vuex module getter
  @VuexGetter('nTariffs') getPromoPagesItems!: PromotionalPageAppModelType[]

  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nTariffs') getPromoPagesParams!: any

  @VuexGetter('nTariffs') getPromoPageLoading!: boolean

  @VuexGetter('nTariffs') getPromoPageLoadingItem!: boolean

  @VuexGetter('nApp') getAppCurrencySymbol!: string

  // Emit
  @Emit('openPromoPagePopUpForCreate')
  openPromoPagePopUpForCreate(): boolean {
    return true
  }

  @Emit('openPromoPagePopUpForEdit')
  openPromoPagePopUpForEdit(id: number): number {
    this.selectedItemId = id
    return id
  }

  @Emit('openPromoPagePopUpView')
  openPromoPagePopUpView(item: PromotionalPageAppModelType): PromotionalPageAppModelType {
    return item
  }

  @Emit('tableChangePage')
  tableChangePage(page: number): number {
    return page
  }
}



















































































































































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter, VuexMutation } from '@/store/decorators'

@Component({
  components: {},
})
export default class DialogControlPanel extends Vue {
  @VuexGetter('nChats') getDialogControlForm!: any

  @VuexGetter('nChats', 'getDialogControlFormSubmitProccess') isSubmitProccess!: any

  @VuexMutation('nChats') dialogControlFormBind!: (any) => void

  get controlFileInputs(): any {
    return [
      {
        label: this.$t('photo'),
        type: 'photo',
        accept: '.jpg,.jpeg,.png,.gif',
      },
      {
        label: this.$t('video'),
        type: 'video',
        accept: '.mp4',
      },
      {
        label: this.$t('file'),
        type: 'file',
        accept: '.doc,.docx,.pdf,.txt',
      },
    ]
  }

  get formData(): any {
    return this.getDialogControlForm
  }

  set formData(value) {
    this.dialogControlFormBind(value)
  }

  get isSubmitAvaiable(): boolean {
    return (
      (!!this.formData.message.length || !!this.formData.media.length) && !this.isSubmitProccess
    )
  }

  get mediaPhotoPreviews(): any {
    return this.getDialogControlForm.media
      .filter((item) => item.type === 'photo')
      .map((item) => {
        return {
          url: URL.createObjectURL(item.file),
          index: item.index,
        }
      })
  }

  get mediaVideoPreviews(): any {
    return this.getDialogControlForm.media
      .filter((item) => item.type === 'video')
      .map((item) => {
        return {
          url: URL.createObjectURL(item.file),
          index: item.index,
        }
      })
  }

  get mediaFilePreviews(): any {
    return this.getDialogControlForm.media
      .filter((item) => item.type === 'file')
      .map((item) => {
        return {
          name: item.file.name,
          index: item.index,
        }
      })
  }

  addAttachMedias(event: any, type): void {
    const files = Object.entries(event.target.files).map((file) => {
      return { type, file: file[1] }
    })
    this.$emit('addAttachMedias', files)
  }

  removeAttachMedia(index): void {
    this.$emit('removeAttachMedia', index)
  }

  formSubmit(e) {
    if (e.ctrlKey) {
      e.target.value += '\n'
      return false
    }
    if (this.isSubmitAvaiable) {
      this.$emit('messageSubmit')
    }
    return true
  }
}

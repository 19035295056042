<template>
  <v-switch
    hide-details
    class="switch"
    color="var(--v-primary-base)"
    background-color="#FFFFFF"
    v-model="storedVal"
    :falseValue="falseValue"
    :trueValue="trueValue"
    inset
    @change="onChange"
  />
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Toggle',
  props: {
    value: {},
    falseValue: {
      default: false,
    },
    trueValue: {
      default: true,
    },
  },
  data: () => ({
    storedVal: '',
  }),
  methods: {
    onChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
  created() {
    this.storedVal = this.value
  },

  watch: {
    storedVal() {
      // this.$emit('input', this.storedVal)
    },
  },
})
</script>

<style lang="scss">
.switch {
  margin-top: 0 !important;

  > .v-input__control > .v-input__slot {
    padding: 0 !important;
    background-color: inherit !important;
    border: 0 !important;

    > .v-input--selection-controls__input {
      width: 40px;
      margin-right: 0;
      .v-input--selection-controls__ripple {
        width: 0;
      }
      > .v-input--switch__track {
        width: 40px;
        height: 20px;
        background: #ffffff;
        border: 1px solid #dcd6e7;
      }

      > .v-input--switch__thumb {
        width: 12px;
        height: 12px;
        color: var(--v-primary-base);
      }
    }
  }
}
</style>

import { HttpApiCrm } from '@/services/HttpClientService'
import md5 from 'md5'

class AuthServise {
  protected API_TOKEN_KEY = 'API_TOKEN'

  protected API_PWA_TOKEN_KEY = 'CHAT_TOKEN'

  protected USER_KEY = 'USER'

  protected CLIENT_KEY = 'CLIENT'

  protected SOCKET_KEY = 'SOCKET_KEY'

  protected referralParamName = 'ref'

  // constructor() {}

  // local storage methods

  // CRM token

  public saveTokenCRM(token: string): void {
    localStorage.setItem(this.API_TOKEN_KEY, token)
  }

  public getTokenCRM(): string | null {
    return localStorage.getItem(this.API_TOKEN_KEY)
  }

  public deleteTokenCRM(): void {
    localStorage.removeItem(this.API_TOKEN_KEY)
  }

  // PWA token

  public saveTokenPWA(token: string): void {
    localStorage.setItem(this.API_PWA_TOKEN_KEY, token)
  }

  public getTokenPWA(): string | null {
    return localStorage.getItem(this.API_PWA_TOKEN_KEY)
  }

  public deleteTokenPWA(): void {
    localStorage.removeItem(this.API_PWA_TOKEN_KEY)
  }

  // user data

  public saveUser(user: any): void {
    // const data = {
    //   id: user.id,
    //   name: user.name,
    //   avatar: user.avatar,
    // }
    localStorage.setItem(this.USER_KEY, JSON.stringify(user))
  }

  public getUser(): any | null {
    const user = localStorage.getItem(this.USER_KEY)

    try {
      return user ? JSON.parse(user) : null
    } catch (e) {
      return null
    }
  }

  public deleteUser(): void {
    localStorage.removeItem(this.USER_KEY)
  }

  // client data

  public saveClient(clientId: any): void {
    localStorage.setItem(this.CLIENT_KEY, clientId)
    localStorage.setItem(this.SOCKET_KEY, md5(`${clientId}`))
  }

  public getClient(): string | null {
    return localStorage.getItem(this.CLIENT_KEY)
  }

  public deleteClient(): void {
    localStorage.removeItem(this.CLIENT_KEY)
  }

  public async login(data): Promise<any> {
    try {
      const response = await HttpApiCrm.post('api/auth/login', data)
      return {
        status: true,
        data: response.data,
        params: {},
      }
    } catch (error) {
      return { status: false, data: {}, params: {}, error }
    }
  }

  public async registerConfirmPhone(data): Promise<any> {
    try {
      const response = await HttpApiCrm.post('api/phone_confirm_data/add', data)
      return {
        status: true,
        data: response.data,
        params: {},
      }
    } catch (error) {
      return { status: false, data: {}, params: {}, error }
    }
  }

  public async apiUsersMe() {
    try {
      const response = await HttpApiCrm.get('api/users/me')
      return {
        status: response.status,
        data: response.data,
        params: {},
      }
    } catch (error) {
      return { status: false, data: {}, params: {}, error }
    }
  }

  public findReferralByQueryParam() {
    const queryParams = new URL(window.location.href).searchParams
    return queryParams.get(this.referralParamName)
  }

  public saveReferralToLocalStorage(id): boolean {
    if (Number(id > 0)) {
      localStorage.setItem(this.referralParamName, id)
      return true
    }
    return false
  }

  public getReferal(): number | boolean {
    const id = Number(localStorage.getItem(this.referralParamName))
    return id > 0 ? id : false
  }

  public checkAuthModerator() {
    const queryParams = new URL(window.location.href).searchParams
    const token = queryParams.get('token')
    const moderator = queryParams.get('moderator')
    return { token, moderator }
  }

  public checkGPTMode() {
    const queryParams = new URL(window.location.href).searchParams
    const gpt_auth = queryParams.get('gpt_auth')
    return !!gpt_auth
  }
}
export default new AuthServise()

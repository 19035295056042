














import { Component, Vue, Emit } from 'vue-property-decorator'
import { filterDataType } from '@/views/affiliate/tools/postbackLog/PostbackLog.vue'
import moment from 'moment'

@Component({})
export default class PostbackLogFilter extends Vue {
  // Data
  filterData = {
    created: [],
    url: null,
  }

  // Emits
  @Emit('filterTableData')
  filterTableData(): filterDataType {
    return this.filterData
  }
}

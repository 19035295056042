class LocalizationProvider {
  static instance: LocalizationProvider

  private plugin_$i18n

  private $store

  private localizationService

  constructor() {
    if (typeof LocalizationProvider.instance === 'object') {
      return LocalizationProvider.instance
    }
    LocalizationProvider.instance = this
    return LocalizationProvider.instance
  }

  public registerPlugin(i18nPlugin): void {
    this.plugin_$i18n = i18nPlugin
  }

  public registerService(service): void {
    this.localizationService = service
  }

  public registerStore(store): void {
    this.$store = store
  }

  public initLocale() {
    const value = this.localizationService.initLocale()
    this.plugin_$i18n.locale = value
    this.$store.commit('nApp/setLocale', value)
  }

  public changeLocale(value): string {
    const newValue = this.localizationService.changeLocale(value)
    this.plugin_$i18n.locale = newValue
    return newValue
  }
}

export default new LocalizationProvider()








































import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexGetter } from '@/store/decorators'
import PhysicalForm from '@/views/business/verification/components/forms/PhysicalForm.vue'
import MediaObjectsService from '@/services/api/MediaObjects/MediaObjectsService'
import { ClientVerificationsAppModelType } from '@/services/api/ClientVerifications/ClientVerificationsTypes'
import { clientTypeType, physicalDataFormType } from './types'

@Component({
  components: {
    PhysicalForm,
  },
})
export default class BusinessVerification extends Vue {
  // Data
  isPreloaderShow = false

  selectedClientType = 'Physical'

  // vuex module action

  @VuexAction('nBusinessVerification') clientVerificationGetItems!: () => Promise<boolean>

  @VuexAction('nBusinessVerification') clientVerificationCreateItem!: (...args) => Promise<boolean>

  @VuexAction('nBusinessVerification') clientVerificationUpdateItem!: (...args) => Promise<boolean>

  // vuex module getter
  @VuexGetter('nBusinessVerification') getClientVerification!: ClientVerificationsAppModelType

  // Computed
  get clientType(): clientTypeType[] {
    return [
      // {
      //   label: this.$t('verification.entity'),
      //   value: 'Entity',
      // },
      // {
      //   label: this.$t('verification.individual'),
      //   value: 'Individual',
      // },
      // {
      //   label: this.$t('verification.selfEmployed'),
      //   value: 'Selfemployed',
      // },
      {
        label: this.$t('verification.physical'),
        value: 'Physical',
      },
    ]
  }

  // Methods
  async imageConvertToMediaObject(file: File): Promise<number> {
    const response: any = await MediaObjectsService.createItem({
      data: file,
      params: {},
    })
    return response.data.id
  }

  async formSubmit({
    formData,
    mode,
  }: {
    formData: physicalDataFormType
    mode: string
  }): Promise<void> {
    if (formData.media_objects.length) {
      await Promise.all(
        formData.media_objects.map(async (item: File, index: number) => {
          if (typeof formData.media_objects[index] === 'object') {
            formData.media_objects[index] = await this.imageConvertToMediaObject(item)
          }
        })
      )
    }
    if (typeof formData.codeMedia === 'object') {
      formData.codeMedia = await this.imageConvertToMediaObject(formData.codeMedia)
    }
    if (mode === 'createNew') {
      await this.clientVerificationCreateItem(formData)
    } else {
      if (mode === 'updateNew') {
        await this.clientVerificationUpdateItem({
          data: { ...formData, status: 1 },
          id: this.getClientVerification.id,
          notification: { enableSuccess: true },
        })
        this.$router.push(`/business`)
      }
      if (mode === 'updateOld') {
        await this.clientVerificationUpdateItem({
          data: { ...formData, status: 4 },
          id: this.getClientVerification.id,
        })
      }
    }
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    if (!this.getClientVerification) {
      await this.clientVerificationGetItems()
    }
    this.isPreloaderShow = false
  }
}

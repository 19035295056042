var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
    left: function () { return _vm.swipeMenu('left'); },
    right: function () { return _vm.swipeMenu('right'); },
  }),expression:"{\n    left: () => swipeMenu('left'),\n    right: () => swipeMenu('right'),\n  }"},{name:"click-outside",rawName:"v-click-outside",value:({
    handler: _vm.hideMenu,
  }),expression:"{\n    handler: hideMenu,\n  }"}],staticClass:"sidebar-menu",class:[_vm.adaptiveClass, _vm.swipeHandlerClass, { absolute: _vm.$vuetify.breakpoint.width < 1360 }],on:{"mouseleave":function($event){$event.stopPropagation();return _vm.hideMenu.apply(null, arguments)},"click":function($event){_vm.adaptiveClass === 'mini' ? (_vm.swipeHandlerClass = 'open') : (_vm.swipeHandlerClass = 'close')}}},[_c('div',{staticClass:"sidebar-menu-wrap"},[_c('vue-custom-scrollbar',{staticClass:"scroll-area",attrs:{"settings":_vm.$root.$data.scrollbarSettings}},_vm._l((_vm.proccessedLinksList),function(item,i){return _c('div',{key:i},[(!item.children)?_c('div',[_c('v-list-item',{staticClass:"sidebar-menu-list__item primary--text",class:{
              active: _vm.routeCheck(item),
            },attrs:{"to":{ name: item.link, query: item.query || {} }}},[_c('icon',{attrs:{"data":item.icon}}),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1):_c('div',[(item.children)?_c('v-list-group',{ref:("group" + (item.link)),refInFor:true,staticClass:"sidebar-menu-sublist",attrs:{"value":_vm.listGroupStateValue,"active-class":"active","no-action":""},scopedSlots:_vm._u([{key:"appendIcon",fn:function(){return [_c('icon',{staticClass:"v-icon append-icon-sidebar",attrs:{"width":"10px","height":"6px","data":require("@/assets/icons/arrow-down.svg")}})]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"sidebar-menu-list__item primary--text",class:{
                  active: _vm.$route.name === item.link,
                },attrs:{"to":{ name: item.link, query: item.query || {} }}},[_c('icon',{attrs:{"data":item.icon}}),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true)},_vm._l((item.children),function(childItem){return _c('v-list-item',{key:childItem.title,staticClass:"sidebar-menu-sublist__item primary--text text-xsmall",class:{ active: _vm.routeCheck(childItem) },attrs:{"to":{ name: childItem.link, query: childItem.query || {} }}},[_c('v-list-item-title',[_vm._v(_vm._s(childItem.title))])],1)}),1):_vm._e()],1)])}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
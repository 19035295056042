<script>
import VAutocomplete from 'vuetify/lib/components/VAutocomplete/VAutocomplete'

export default {
  name: 'AppVAutocomplete',
  extends: VAutocomplete,
  props: {
    itemText: {
      type: [String, Array, Function],
      default: 'name',
    },
    itemValue: {
      type: [String, Array, Function],
      default: 'id',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: String,
      default: 'auto',
    },
    hideNoData: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: 'grey lighten-2',
    },
    attach: {
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        ...VAutocomplete.options.computed.classes.call(this),
        'is-show-label': this.showLabel,
        'is-prepend-inner-icon': this.prependInnerIcon,
      }
    },
  },
  methods: {
    // переопределяем дефолтное значение слота "selection"
    // eslint-disable-next-line consistent-return
    genCommaSelection(item, index, last) {
      if (index === 0) {
        return (
          item[this.itemText] +
          (this.selectedItems.length > 1 ? ` (+${this.selectedItems.length})` : '')
        )
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>

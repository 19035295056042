import i18n from '@/plugins/i18n'
import { TranslateResult } from 'vue-i18n'

class Validation {
  private rulesList = [
    'required',
    'strmin',
    'strmax',
    'nummin',
    'email',
    'phone',
    'digits',
    'onlyLetters',
    'noSymbols',
    'onlyEngLetters',
    'ERC20',
    'TRC20',
    'Yoomoney',
    'WMZ',
    'Qiwi',
    'Capitalist',
    'hostName',
  ]

  public getRules(rules: string[]): string {
    const vFormRules: any = []
    for (const rule of rules) {
      const ruleName = this.getRuleName(rule)
      switch (ruleName) {
        case 'required':
          vFormRules.push(this.vFormRuleRequired())
          break
        case 'strmin':
          vFormRules.push(this.vFormRuleStrMin(rule))
          break
        case 'strmax':
          vFormRules.push(this.vFormRuleStrMax(rule))
          break
        case 'nummin':
          vFormRules.push(this.vFormRuleNumMin(rule))
          break
        case 'nummax':
          vFormRules.push(this.vFormRuleNumMax(rule))
          break
        case 'email':
          vFormRules.push(this.vFormRuleEmail())
          break
        case 'phone':
          // vFormRules.push(this.vFormRulePhone())
          break
        case 'digits':
          vFormRules.push(this.vFormRuleDigits())
          break
        case 'noSymbols':
          vFormRules.push(this.vFormRuleNoSymbols())
          break
        case 'onlyEngLetters':
          vFormRules.push(this.vFormRuleOnlyEngLetters())
          break
        case 'onlyLettersAndDash':
          vFormRules.push(this.vFormRuleOnlyLettersAndDash())
          break
        case 'onlyLetters':
          vFormRules.push(this.vFormRuleSymbols())
          break
        case 'ERC20':
          vFormRules.push(this.vFormRuleERC20())
          break
        case 'TRC20':
          vFormRules.push(this.vFormRuleTRC20())
          break
        case 'Yoomoney':
          vFormRules.push(this.vFormRuleYoomoney())
          break
        case 'WMZ':
          vFormRules.push(this.vFormRuleWMZ())
          break
        case 'Qiwi':
          vFormRules.push(this.vFormRuleQiwi())
          break
        case 'Capitalist':
          vFormRules.push(this.vFormRuleCapitalist())
          break
        case 'hostName':
          vFormRules.push(this.vFormHostRule())
          break

        default:
          console.error(`Rule ${ruleName} not found`)
          break
      }
    }
    return vFormRules
  }

  private getRuleName(rule: string): string {
    return rule.split(':')[0]
  }

  public vFormRuleRequired(): (string) => string | boolean | TranslateResult {
    return (value) => !!value || i18n.t('valiationMessages.required')
  }

  public vFormRuleStrMin(rule: string): (string) => string | boolean | TranslateResult {
    const minCount = Number(rule.split(':')[1])
    return (value) =>
      (value && value.length >= minCount) || i18n.t('valiationMessages.minCharacters', { minCount })
  }

  public vFormRuleStrMax(rule: string): (string) => string | boolean | TranslateResult {
    const maxCount = Number(rule.split(':')[1])
    return (value) =>
      (value && value.length <= maxCount) || i18n.t('valiationMessages.maxCharacters', { maxCount })
  }

  public vFormRuleNumMin(rule: string): (string) => string | boolean | TranslateResult {
    const minCount = Number(rule.split(':')[1])
    return (value) =>
      Number(value) >= minCount || i18n.t('valiationMessages.minValue', { minCount })
  }

  public vFormRuleNumMax(rule: string): (string) => string | boolean | TranslateResult {
    const maxCount = Number(rule.split(':')[1])
    return (value) =>
      Number(value) <= maxCount || i18n.t('valiationMessages.maxValue', { maxCount })
  }

  public vFormRuleDigits(): (string) => string | boolean | TranslateResult {
    return (value) => /^[0-9]+$/.test(value) || i18n.t('valiationMessages.onlyNumbers')
  }

  public vFormRuleNoSymbols(): (string) => string | boolean | TranslateResult {
    return (value) =>
      /^([a-zA-Zа-яёА-ЯЁ0-9 ]*)$/.test(value) || i18n.t('valiationMessages.onlyNumbersAndLetters')
  }

  public vFormRuleSymbols(): (string) => string | boolean | TranslateResult {
    return (value) =>
      /^([a-zA-Zа-яёА-ЯЁ ]*)$/.test(value) || i18n.t('valiationMessages.onlyLetters')
  }

  public vFormRuleOnlyEngLetters(): (string) => string | boolean | TranslateResult {
    return (value) => /^([a-zA-Zа ]*)$/.test(value) || i18n.t('valiationMessages.onlyEnglishLetter')
  }

  public vFormRuleOnlyLettersAndDash(): (string) => string | boolean | TranslateResult {
    return (value) =>
      /^([a-zA-Zа-яёА-ЯЁ -]*)$/.test(value) || i18n.t('valiationMessages.onlyLettersAndDash')
  }

  public vFormRuleEmail(): (string) => string | boolean | TranslateResult {
    return (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || i18n.t('valiationMessages.noCorrectEmail')
    }
  }

  public vFormRuleERC20(): (string) => string | boolean | TranslateResult {
    return (value) =>
      /^0x[A-Za-z0-9]{40}$/.test(value) || i18n.t('valiationMessages.wrongValletFormat')
  }

  public vFormRuleTRC20(): (string) => string | boolean | TranslateResult {
    return (value) =>
      /^T[A-Za-z1-9]{33}$/.test(value) || i18n.t('valiationMessages.wrongValletFormat')
  }

  public vFormRuleYoomoney(): (string) => string | boolean | TranslateResult {
    return (value) => /^\d{11,20}$/.test(value) || i18n.t('valiationMessages.wrongValletFormat')
  }

  public vFormRuleWMZ(): (string) => string | boolean | TranslateResult {
    return (value) => /^Z\d{12}$/.test(value) || i18n.t('valiationMessages.wrongValletFormat')
  }

  public vFormRuleQiwi(): (string) => string | boolean | TranslateResult {
    return (value) => /^\d{6,16}$/.test(value) || i18n.t('valiationMessages.wrongValletFormat')
  }

  public vFormRuleCapitalist(): (string) => string | boolean | TranslateResult {
    return (value) => /^[A-Z]\d{7}$/.test(value) || i18n.t('valiationMessages.wrongValletFormat')
  }

  public vFormHostRule(): (string) => string | boolean | TranslateResult {
    return (value) => value.includes('.') || i18n.t('valiationMessages.hostName')
  }

  // vFormRulePhone() {
  //   return (value) => !!value || 'Phone.'
  // }
}
export default new Validation()

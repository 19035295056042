import {
  App,
  Channel,
  Chat,
  Client,
  Domain,
  MediaObject,
  Message,
  Offer,
  Order,
  Post,
  ServerSideApp,
  ServerSideChannel,
  ServerSideChat,
  ServerSideClient,
  ServerSideDomain,
  ServerSideMediaObject,
  ServerSideMessage,
  ServerSideOffer,
  ServerSideOrder,
  ServerSidePost,
  ServerSideTariff,
  ServerSideUser,
  Tariff,
  User,
} from '@/services/types'
import moment from 'moment'

export const mapServerSideUser = (source: ServerSideUser): User => ({
  id: source.id,
  email: source.email,
  name: source.name,
  avatar: source.avatar ? source.avatar.content_url : undefined,
})

export const mapServerSideChat = (source: ServerSideChat): Chat => ({
  id: source.id,
  users: source.chat_users.map((s) => ({
    id: s.UserId,
    joinedAt: moment(s.JoinedAt).toDate(),
  })),
  createdAt: new Date(source.created_at).toISOString(),
  closedAt: source.closed_at ? new Date(source.closed_at * 1000).toISOString() : null,
  deletedAt: source.deleted_at ? new Date(source.deleted_at * 1000).toISOString() : null,
})

export const mapServerSideClient = (source: ServerSideClient): Client => ({
  id: source.id,
  name: source.name,
  ip: source.ip,
  emulator: source.emulator,
  deviceLang: source.device_lang,
  deviceModel: source.device_model,
  screenHeight: source.screen_height,
  timezone: source.timezone,
  telegram: source.telegram,
  appName: source.app.name,
  referredBy: null,
  referredWho: null,
  rappsFlierStatus: null,
  rappsFlierType: null,
  googleReferer: null,
})

export const mapServerSideMediaObject = (source: ServerSideMediaObject): MediaObject => ({
  id: source.id,
  url: source.content_url,
})

export const mapServerSideMessage = (source: ServerSideMessage): Message => ({
  id: source.id,
  text: source.text,
  sentAt: source.created_at,
  userId: source.user_id,
  hasFile: Boolean(source.File),
  pinned: Math.floor(Math.random() * (2 - 1 + 1) + 1) === 1,
})

export const mapServerSideTariff = (source: ServerSideTariff): Tariff => ({
  id: source.id,
  type: source.type,
  name: source.name,
  amount: source.amount,
  period: source.period,
})

export const mapServerSideApp = (source: ServerSideApp): App => ({
  id: source.id,
  name: source.name,
  image: source.image ? mapServerSideMediaObject(source.image) : null,
  description: source.description,
  primaryColor: source.primary_color,
  headerImages: source.header_images.map(mapServerSideMediaObject),
  tariffs: source.tariffs.map(mapServerSideTariff),
  lang: source.lang,
})

export const mapServerSideDomain = (source: ServerSideDomain): Domain => ({
  id: source.id,
  name: source.name,
  description: source.description,
  app: {
    id: source.app.id,
    name: source.app.name,
  },
  created: source.created,
  updated: source.updated,
})

export const mapServerSideOrders = (source: ServerSideOrder): Order => ({
  id: source.id,
  date: moment(source.created).toDate(),
  orderNumber: source.id,
  orderType: source.type,
  productType: source.type,
  clientId: source.client.id,
  trackNumber: source.subscription,
  details: source.note,
  price: String(source.sum),
  status: source.status,
  created: source.created,
})
export const mapServerSideOffer = (source: ServerSideOffer): Offer => ({
  id: source.id,
  condition: source.rules,
  profile: source.user.name,
  geo: source.title,
  subscriptions: source.description,
  category: source.description,
})

export const mapServerSidePost = (source: ServerSidePost): Post => ({
  id: source.id,
  body: source.body,
  attachments: source.media_objects.map(mapServerSideMediaObject),
  tariffs: source.tariffs.map(mapServerSideTariff),
})

export const mapServerSideChannel = (source: ServerSideChannel): Channel => ({
  id: source.id,
  name: source.name,
  description: source.description,
  image: source.image ? mapServerSideMediaObject(source.image) : null,
  tariffs: source.tariffs.map(mapServerSideTariff),
})

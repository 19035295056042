import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { AppsAppModelType, AppsApiModelType } from './AppsTypes'

class AppsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.apps')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.APPS)
  }

  public async appsHasOffer<P>({ params }: { params?: P }): Promise<any> {
    try {
      const requestUrl = `${ENDPOINTS.APPS}/has_offer`
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const result = {
        data: response.data,
        params: {
          page: parseInt(response.headers['x-pagination-page']),
          pages: parseInt(response.headers['x-pagination-pages']),
        },
      }
      return { status: true, data: result.data, params: result.params }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, params: {}, error }
    }
  }

  public async toAuthUrl(id: number): Promise<any> {
    const requestUrl = `${ENDPOINTS.APPS}/${id}/to-auth-url`
    const response = await this.api.get(requestUrl)
    return response.data
  }

  public transformDataForApi(incomingData): AppsApiModelType {
    const data = cloneDeep(incomingData)

    if (incomingData.emptyBotChatLogo) {
      data.emptyBotChatLogo = `/api/media_objects/${incomingData.emptyBotChatLogo.id}`
    }
    if (incomingData.primaryColor) {
      data.primary_color = incomingData.primaryColor
    }
    delete data.primaryColor
    return data
  }

  public transformDataForApp(incomingData): AppsAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }
}
export default new AppsService()

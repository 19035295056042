<template>
  <div
    class="supster-ui--textfield__wrapper"
    :class="{
      _disabled: disabled,
      _readonly: readonly,
      _error: error,
      _text: type === 'text',
      _textarea: type === 'textarea',
    }"
    @click="$emit('click')"
    :style="style"
  >
    <slot name="before"></slot>
    <input
      v-if="type === 'text' || type === 'password'"
      class="supster-ui--textfield__field"
      :class="{ _disabled: disabled, _readonly: readonly }"
      type="text"
      :value="value"
      v-on:keyup="onKeyup"
      :readonly="readonly"
      :placeholder="placeholder"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      @focus="onFocus"
    />
    <textarea
      v-if="type === 'textarea'"
      class="supster-ui--textfield__field _textarea"
      :class="{ _disabled: disabled, _readonly: readonly }"
      type="text"
      :value="value"
      :readonly="readonly"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      @focus="onFocus"
    ></textarea>
    <slot name="after"></slot>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'InputText',
  data: () => ({}),
  props: {
    value: {
      default: '',
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    width: {
      default: false,
    },
    height: {
      default: false,
    },
    minHeight: {
      default: false,
    },
    error: {
      default: false,
    },
    type: {
      default: 'text',
    },
    placeholder: {
      default: '',
    },
  },
  computed: {
    style() {
      const style = {}
      if (this.width) {
        style.width = this.width
      }
      if (this.height) {
        style.height = this.height
      }
      if (this.height) {
        style['min-height'] = this.minHeight || this.height
      }
      return style
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    },
    onChange(event) {
      this.$emit('change', event)
      this.$emit('input', event.target.value)
    },
    onKeydown(event) {
      this.$emit('keydown', event)
    },
    onKeyup(event) {
      this.$emit('keyup', event)
    },
    onBlur(event) {
      this.$emit('blur', event)
    },
    onFocus(event) {
      this.$emit('focus', event)
    },
  },
})
</script>
<style lang="scss"></style>

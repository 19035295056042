import moment from 'moment'
import { cloneDeep } from 'lodash'
import ENDPOINTS from '@/services/api/Endpoints'
import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import i18n from '@/plugins/i18n'
import { MoneyOutcomesModelAppType, MoneyOutcomesModelApiType } from './MoneyOutcomesTypes'

class MoneyOutcomesService extends BaseApiService {
  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.MONEY_OUTCOMES)
  }

  protected modelName = i18n.t('servicesModelsName.moneyOutcomes')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 15,
    page: 1,
  }

  private usedListParams = [
    'itemsPerPage',
    'page',
    'performance_status',
    'created[after]',
    'created[before]',
    'type',
    'requisites',
    'app',
  ]

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData): MoneyOutcomesModelApiType {
    const data = cloneDeep(incomingData)
    delete data.payout_system

    if (data.currency) {
      data.currency = `/api/currencies/${data.currency.id}`
    }
    if (data.app) {
      data.app = `/api/apps/${data.app}`
    }
    if (data.payoutAccount) {
      data.payoutAccount = `/api/payout_accounts/${data.payoutAccount.id}`
    }
    return data
  }

  public transformDataForApp(incomingData): MoneyOutcomesModelAppType {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    if (incomingParams.status) {
      params.performance_status = incomingParams.status
    }
    if (incomingParams.created) {
      if (incomingParams.created.length) {
        params['created[after]'] = incomingParams.created[0]
        params['created[before]'] = `${incomingParams.created[1]} 23:59:59`
      }
    }

    return params
  }
}
export default new MoneyOutcomesService()





















import { Component, Vue } from 'vue-property-decorator'
import { VuexAction, VuexMutation } from '@/store/decorators'
import PostbackLogFilter from '@/views/affiliate/tools/postbackLog/components/PostbackLogFilter.vue'
import PostbackLogTable from '@/views/affiliate/tools/postbackLog/components/PostbackLogTable.vue'

export type filterDataType = {
  created: [] | string[]
  url: null | string
}
@Component({
  components: {
    PostbackLogFilter,
    PostbackLogTable,
  },
})
export default class PostbackLog extends Vue {
  // Data
  isPreloaderShow = false

  // vuex module action
  @VuexAction('nLogsPostback') logsPostbackGetItems!: (...args) => Promise<void>

  // vuex module mutation
  @VuexMutation('nLogsPostback') logsPostbackListParamsUpdate!: (...args) => void

  // Methods
  async filterTableData(filterData: filterDataType): Promise<void> {
    this.logsPostbackListParamsUpdate({ ...filterData })
    await this.logsPostbackGetItems()
  }

  async tableLoadItems(): Promise<void> {
    await this.logsPostbackGetItems()
  }

  async changePageTable(page: number): Promise<void> {
    this.logsPostbackListParamsUpdate({ page })
    await this.logsPostbackGetItems()
  }

  // Hooks
  async created(): Promise<void> {
    this.isPreloaderShow = true
    await this.tableLoadItems()
    this.isPreloaderShow = false
  }
}

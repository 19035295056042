<template>
  <v-data-table
    class="old-data-table"
    :class="{ rounded: rounded, bordered: bordered }"
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :page.sync="page"
    :custom-filter="customFilter"
    @click:row="$emit('click:row', $event)"
    search="search"
    :no-data-text="$t('noDataAvaiable')"
    hide-default-footer
    :disable-sort="disableSort"
    :loading="loading"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
    <template v-slot:footer>
      <v-pagination
        v-if="!hidePagination"
        class="pagination"
        v-model="page"
        :length="length"
        @next="nextPage"
        @previous="previousPage"
        @input="pageInput"
        :total-visible="15"
      ></v-pagination>
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'DataTable',
  props: {
    bordered: {
      type: Boolean,
      default: () => false,
    },
    rounded: {
      type: Boolean,
      default: () => false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    customFilter: {
      type: Function,
      default: () => true,
    },
    search: {
      type: Boolean,
    },
    length: {
      type: Number,
      default: 1,
    },
    section: {
      type: String,
      default: '',
    },
    hidePagination: {
      type: Boolean,
      default: () => false,
    },
    disableSort: {
      default: false,
    },
    loading: {
      default: false,
    },
  },
  data: () => ({
    pageCount: 0,
    page: 1,
  }),
  methods: {
    resetPagination() {
      this.page = 1
    },
    nextPage() {
      this.$emit('nextPage')
    },
    previousPage() {
      this.$emit('previousPage')
    },
    pageInput() {
      this.$emit('input', this.page)
    },
  },
  watch: {
    section(val) {
      if (val.length > 0) {
        this.resetPagination()
      }
    },
  },
})
</script>

<style lang="scss">
.v-data-table .pagination {
  margin-top: 3rem;
  background: var(--ground);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: inset 0px 1px 0px var(--light-gray);

  .v-pagination {
    position: relative;
    justify-content: start;

    li {
      &:first-of-type {
        position: absolute;
        right: 2.55rem;
      }
      &:nth-child(2) {
        > button {
          border-bottom-left-radius: 8px !important;
        }
      }

      &:last-of-type {
        position: absolute;
        right: 0;
        > button {
          border-bottom-right-radius: 8px !important;
        }
      }
    }
    li:first-of-type {
      right: 47px !important;
    }
    .v-pagination__item,
    .v-pagination__navigation {
      width: 48px !important;
      height: 48px !important;
      margin: 0;
      padding: 0.5rem 0 !important;
      color: var(--primary-light);
      background: none !important;
      border-left: 1px solid var(--light-gray) !important;
      border-radius: 0 !important;
      box-shadow: none;

      &:last-of-type {
        border-right: 1px solid var(--light-gray) !important;
      }

      &--active {
        color: white;
        background: var(--v-primary-base) !important;
      }
    }
  }
}
</style>

import moment from 'moment'
import { cloneDeep } from 'lodash'
import ENDPOINTS from '@/services/api/Endpoints'
import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import i18n from '@/plugins/i18n'
import { MoneyIncomesModelAppType, MoneyIncomesModelApiType } from './MoneyIncomesTypes'

class MoneyIncomesService extends BaseApiService {
  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.MONEY_INCOMES)
  }

  protected modelName = i18n.t('servicesModelsName.accruals')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 30,
    page: 1,
  }

  private usedListParams = [
    'itemsPerPage',
    'page',
    'created[after]',
    'created[before]',
    'incomeType.value[]',
    'app',
  ]

  public async getTotal(params) {
    try {
      const response = await this.api.get(`${ENDPOINTS.MONEY_INCOMES}/totals`, {
        params,
      })
      return { status: true, data: response.data }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordsGetError', { model: this.modelName })}`
      )
      return { status: false, data: {}, params: {}, error }
    }
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData): MoneyIncomesModelApiType {
    const data = cloneDeep(incomingData)

    return data
  }

  public transformDataForApp(incomingData): MoneyIncomesModelAppType {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    if (incomingParams.group) {
      let groupFilter: string[] = []
      incomingParams.group.forEach((item: never) => {
        if (item !== 'all') {
          groupFilter.push(item)
        }
        if (item === 'subscriptions') {
          groupFilter = groupFilter.filter((groupItem) => groupItem !== 'subscriptions')
          groupFilter.push('app_subscription')
          groupFilter.push('app_subscription_renewed')
        }
      })
      params['incomeType.value'] = groupFilter
    }
    if (incomingParams.created && incomingParams.created.length > 0) {
      params['created[after]'] = incomingParams.created[0]
      params['created[before]'] = `${incomingParams.created[1]} 23:59:59`
    }
    return params
  }
}
export default new MoneyIncomesService()

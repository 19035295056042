<template>
  <div class="supster-ui--preloader__wrapper">
    <v-progress-circular :size="size" :color="color" indeterminate></v-progress-circular>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Preloader',
  data: () => ({}),
  props: {
    size: {
      type: Number,
      default: 20,
    },
    color: {
      default: 'primary',
    },
  },

  computed: {},
  methods: {},
})
</script>
<style lang="scss"></style>

import { OrdersGetters } from './types'

const getters: OrdersGetters = {
  getOrders(state) {
    return state.orders
  },
  getOrdersList(state) {
    return state.orders.list
  },
  getIsLoaded(state) {
    return state.isLoaded
  },
}

export default getters

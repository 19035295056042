<template>
  <div class="supster-ui--radio__input" @click="change" v-bind:style="styles">
    <span class="supster-ui--radio__field" :class="{ _active: isChecked }"></span>
    <span
      class="supster-ui--radio__label"
      :class="{ _active: isChecked }"
      v-if="label && label.length"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'InputRadio',
  data: () => ({}),
  props: {
    value: {
      default: '',
    },
    label: {
      default: false,
    },
    val: {
      default: false,
    },
    actions: {
      default: true,
    },
    height: {
      default: '',
      type: String,
    },
    width: {
      default: '',
      type: String,
    },
  },
  computed: {
    isChecked() {
      return this.value === this.val
    },
    styles() {
      const styles = {}
      if (this.width.length) {
        styles.width = this.width
      }
      if (this.height.length) {
        styles.height = this.height
      }
      return styles
    },
  },
  methods: {
    change() {
      if (this.actions) {
        this.$emit('input', this.val)
      }
    },
  },
})
</script>
<style lang="scss"></style>

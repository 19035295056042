<template>
  <article class="page--gs-orders-detail">
    <page-header extra>
      <h4 class="d-inline-flex align-center">
        <icon
          class="mr-8"
          color="black"
          data="@/assets/icons/chevron-left.svg"
          height="32"
          width="16"
          @click="$router.push(`/business/goods_and_services/orders`)"
        />
        {{ $t('orderDetails') }}
      </h4>
      <v-btn color="accent" class="rounded-lg" elevation="0" height="40px">{{
        $t('downloadData')
      }}</v-btn>
    </page-header>
    <card class="mt-4 mb-6 mx-4 py-0 px-10">
      <v-row class="ma-0 mx-n10">
        <v-col class="page-col pa-10" cols="4">
          <h2 class="supster-ui--form__title">{{ $t('storages.generalInfo') }}</h2>
          <div class="mt-6 py-0">
            <span class="supster-ui--input__label">{{ $t('orderID') }}</span>
            <p class="supster-ui__text _bolder my-0">{{ page.Order.data.id }}</p>
          </div>
          <div class="mt-6 py-0">
            <span class="supster-ui--input__label">{{ $t('orderDate') }}</span>
            <p class="supster-ui__text _bolder my-0">
              {{ page.Order.data.created | moment('DD.MM.YYYY') }}
              <span :style="{ 'font-weight': 400 }">
                {{ $t('at') }} {{ page.Order.data.created | moment('h:mm:ss') }}
              </span>
            </p>
          </div>
          <div class="mt-6 py-0">
            <span class="supster-ui--input__label">{{ $t('storage') }}:</span>
            <p class="supster-ui__text _bolder my-0">{{ EmptyLine }}</p>
          </div>
          <div class="mt-6 py-0">
            <span class="supster-ui--input__label">{{ $t('logisticsCreate.steps.2') }}:</span>
            <p v-if="page.DeliveryMethod" class="supster-ui__text _bolder my-0">
              {{ page.DeliveryMethod.data.name }}
            </p>
          </div>
        </v-col>
        <v-col class="page-col pa-10" cols="4">
          <h2 class="supster-ui--form__title">{{ $t('deliveryAdress') }}</h2>
          <div>
            <span class="supster-ui--input__label"
              >{{ $t('country.0') }}, {{ $t('city') }}, {{ $t('street') }}, {{ $t('house') }}:</span
            >
            <p class="supster-ui__text _bolder my-0">
              {{ page.Order.data.recipientAddress || EmptyLine }}
            </p>
          </div>
          <div class="d-flex flex-wrap">
            <!-- <div class="col col-4 px-0 mt-6 py-0">
              <span class="supster-ui--input__label">Кв. / офис:</span>
              <p class="supster-ui__text _bolder my-0">{{ EmptyLine }}</p>
            </div>
            <div class="col col-4 px-0 mt-6 py-0">
              <span class="supster-ui--input__label"> {{$t('index')}}:</span>
              <p class="supster-ui__text _bolder my-0">{{ EmptyLine }}</p>
            </div>
            <div class="col col-4 px-0 mt-6 py-0">
              <span class="supster-ui--input__label">Подъезд:</span>
              <p class="supster-ui__text _bolder my-0">{{ EmptyLine }}</p>
            </div>
            <div class="col col-4 px-0 mt-6 py-0">
              <span class="supster-ui--input__label">Этаж:</span>
              <p class="supster-ui__text _bolder my-0">{{ EmptyLine }}</p>
            </div>
            <div class="col col-4 px-0 mt-6 py-0">
              <span class="supster-ui--input__label">Домофон:</span>
              <p class="supster-ui__text _bolder my-0">{{ EmptyLine }}</p>
            </div>
            <div class="col col-4 px-0 mt-6 py-0">
              <span class="supster-ui--input__label">:label="$t('house')":</span>
              <p class="supster-ui__text _bolder my-0">{{ EmptyLine }}</p>
            </div> -->
          </div>
          <div class="mt-6 py-0">
            <span class="supster-ui--input__label">{{ $t('comment') }}:</span>
            <p class="supster-ui__text _bolder my-0">{{ page.Order.data.note || EmptyLine }}</p>
          </div>
        </v-col>
        <v-col class="page-col pa-10" cols="4">
          <h2 class="supster-ui--form__title">{{ $t('recipientInfo') }}</h2>
          <div class="mt-6 py-0">
            <span class="supster-ui--input__label">{{ $t('nameAndSurname') }}</span>
            <p class="supster-ui__text _bolder my-0">
              {{ `${page.Order.data.recipientName} ${page.Order.data.recipientSurname}` }}
            </p>
          </div>
          <div class="mt-6 py-0">
            <span class="supster-ui--input__label">{{ $t('phone') }}:</span>
            <p class="supster-ui__text _bolder my-0">{{ page.Order.data.recipientPhone }}</p>
          </div>
        </v-col>
      </v-row>
    </card>
  </article>
</template>

<script>
import Vue from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'

import OrdersService from '@/services/api/Orders/OrdersService'
import DeliveryMethodsService from '@/services/api/DeliveryMethods/DeliveryMethodsService'

export default Vue.extend({
  name: 'OrdersDetail',
  components: { PageHeader, Card },
  data() {
    return {
      EmptyLine: this.$t('noDataAvaiable'),
      page: {
        Order: {
          data: {},
        },
        DeliveryMethod: null,
      },
    }
  },
  methods: {
    async apiGetOrder(id) {
      const response = await OrdersService.getItem({
        id: Number(id),
      })
      return response
    },
    async apiGetDeliveryMethod(id) {
      const response = await DeliveryMethodsService.getItem({
        id: Number(id),
      })
      return response
    },
  },
  async created() {
    this.page.Order = await this.apiGetOrder(this.$route.params.id)
    this.page.DeliveryMethod = await this.apiGetDeliveryMethod(this.page.Order.data.deliveryMethod)
  },
})
</script>

<style lang="scss">
.page--gs-orders-detail {
  .page-col {
    border-right: 1px solid var(--light-gray);
  }
}
</style>

// import store from '@/store/index'
import NotificationProvider from '@/providers/NotificationProvider'
import { TranslateResult } from 'vue-i18n'
import i18n from '@/plugins/i18n'

export interface EndpointsTypes {
  ITEMS: string
  CREATE: string
  ITEM: (number) => string
  UPDATE: (number) => string
  DELETE: (number) => string
}
export interface ResponseTypes {
  status: boolean
  data: any
  params: any
  error?: unknown
}
export interface NotificationTypes {
  enableSuccess?: boolean
  enableError?: boolean
  successText?: any
  errorText?: any
}

export abstract class BaseApi2Service {
  protected notificationProvider = NotificationProvider

  protected modelName: TranslateResult | string = ''

  protected abstract ENDPOINTS: EndpointsTypes

  protected abstract api

  abstract transformDataForApi(data: unknown, action: string): unknown

  abstract transformDataForApp(data: unknown, action: string): any

  abstract transformRequestParams(params)

  protected actions = {
    getItems: 'GET_ITEMS',
    getItem: 'GET_ITEM',
    updateItem: 'UPDATE_ITEM',
    createItem: 'CREATE_ITEM',
    deleteItem: 'DELETE_ITEM',
  }

  protected notificationDefaultSettings = {
    enableSuccess: false,
    enableError: true,
    successText: null,
    errorText: null,
  }

  public async getItems<P>({
    params,
    notification = this.notificationDefaultSettings,
  }: {
    params?: P
    notification?: NotificationTypes
  }): Promise<ResponseTypes> {
    try {
      const requestUrl = this.ENDPOINTS.ITEMS
      const response = await this.api.get(requestUrl, {
        params: this.transformRequestParams(params),
      })
      const data = response.data?.data?.map((el) =>
        this.transformDataForApp(el, this.actions.getItems)
      )
      const result = {
        data: data || [],
        params: this.transformPaginateParams({
          page_total: response.data.page_total,
          total: response.data.total,
        }),
      }
      return { status: true, data: result.data, params: result.params }
    } catch (error) {
      if (notification.enableError) {
        this.addBadRequestNotification(
          error,
          notification.errorText ??
            i18n.t('allertMessage.recordsGetError', { model: this.modelName })
        )
      }
      return { status: false, data: {}, params: {}, error }
    }
  }

  private transformPaginateParams(params) {
    const pages = Math.round(params.total / params.page_total)
    return { pages }
  }

  public async createItem<P, D>({
    params,
    data,
    notification = this.notificationDefaultSettings,
  }: {
    params?: P
    data: D
    notification?: NotificationTypes
  }): Promise<ResponseTypes> {
    try {
      const requestUrl = this.ENDPOINTS.CREATE
      const response = await this.api.post(
        requestUrl,
        this.transformDataForApi(data, this.actions.createItem)
      )
      if (notification.enableSuccess) {
        this.addNotification({
          message:
            notification.successText ??
            `${i18n.t('allertMessage.recordCreateSuccess', {
              model: this.modelName,
            })}`,
        })
      }
      return {
        status: true,
        data: this.transformDataForApp(response.data.data, this.actions.createItem),
        params: {},
      }
    } catch (error) {
      if (notification.enableError) {
        this.addBadRequestNotification(
          notification.errorText ??
            `${i18n.t('allertMessage.recordCreateError', { model: this.modelName })}`
        )
      }
      return { status: false, data: {}, params: {}, error }
    }
  }

  public async getItem({
    id = 0,
    notification = this.notificationDefaultSettings,
  }: {
    id: number | string
    notification?: NotificationTypes
  }): Promise<ResponseTypes> {
    try {
      const requestUrl = this.ENDPOINTS.ITEM(id)
      const response = await this.api.get(requestUrl, {})
      return {
        status: true,
        data: response.data.data,
        params: {},
      }
    } catch (error) {
      if (notification.enableError) {
        this.addBadRequestNotification(
          error,
          notification.errorText ??
            `${i18n.t('allertMessage.recordGetError', { model: this.modelName, id })}`
        )
      }
      return { status: false, data: {}, params: {}, error }
    }
  }

  public async updateItem<P, D>({
    data,
    id = 0,
    notification = this.notificationDefaultSettings,
  }: {
    data: D
    id: number
    notification?: NotificationTypes
  }): Promise<ResponseTypes> {
    try {
      const requestUrl = this.ENDPOINTS.UPDATE(id)
      const response = await this.api.put(
        requestUrl,
        this.transformDataForApi(data, this.actions.updateItem)
      )
      if (notification.enableSuccess) {
        this.addNotification({
          message:
            notification.successText ??
            `${i18n.t('allertMessage.recordUpdateSuccess', { model: this.modelName, id })}`,
        })
      }
      return {
        status: true,
        data: this.transformDataForApp(response.data, this.actions.updateItem),
        params: {},
      }
    } catch (error) {
      if (notification.enableError) {
        this.addBadRequestNotification(
          notification.errorText ??
            `${i18n.t('allertMessage.recordUpdateError', { model: this.modelName, id })}`
        )
      }
      return { status: false, data: {}, params: {}, error }
    }
  }

  public async deleteItem<P, D>({
    id = 0,
    notification = this.notificationDefaultSettings,
  }: {
    id: number
    notification?: NotificationTypes
  }): Promise<ResponseTypes> {
    try {
      const requestUrl = this.ENDPOINTS.DELETE(id)
      const response = await this.api.delete(requestUrl)
      if (notification.enableSuccess) {
        this.addNotification({
          message:
            notification.successText ??
            `${i18n.t('allertMessage.recordDeleteSuccess', { model: this.modelName, id })}`,
        })
      }
      return {
        status: true,
        data: response.data,
        params: {},
      }
    } catch (error) {
      if (notification.enableError) {
        this.addBadRequestNotification(
          error,
          notification.errorText ??
            `${i18n.t('allertMessage.recordDeleteError', { model: this.modelName, id })}`
        )
      }
      return { status: false, data: {}, params: {}, error }
    }
  }

  protected addNotification({
    type = 'success',
    message,
  }: {
    type?: string
    message: string
  }): void {
    this.notificationProvider.add({ type, message })
  }

  protected addBadRequestNotification(error: any, title = ''): void {
    let message
    if (typeof error === 'object' && error?.response) {
      message = [
        `code: ${error.response.status}`,
        `text: ${error.response.statusText} `,
        `url: ${error.response.config.url} `,
        `method: ${error.response.config.method}`,
      ]
    } else {
      message = error
    }
    this.notificationProvider.add({ type: 'error', message, title })
  }

  protected addParamsToRequest<P>(endpoint: string, params: P): string {
    const tranformedParams = this.transformRequestParams(params)
    const qs = Object.keys(tranformedParams)
      .map((key) => `${key}=${tranformedParams[key]}`)
      .join('&')
    return qs.length < 3 ? endpoint : `${endpoint}?${qs}`
  }

  protected convertEndpoints(endpointBasePath: string): EndpointsTypes {
    const serviceEndpoints = {
      ITEMS: endpointBasePath,
      CREATE: endpointBasePath,
      ITEM: (id: number): string => {
        return `${endpointBasePath}/${id}`
      },
      UPDATE: (id: number): string => {
        return `${endpointBasePath}/${id}`
      },
      DELETE: (id: number): string => {
        return `${endpointBasePath}/${id}`
      },
    }
    return serviceEndpoints
  }

  public jsonParse(value: string): string {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  protected getUsedListParams(incomingParams: any, usedParams: string[]): any {
    const params = {}
    for (const key of usedParams) {
      if (key in incomingParams) {
        params[key] = incomingParams[key]
      }
    }
    return params
  }
}

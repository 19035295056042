




















































































import { Component, Ref, Mixins } from 'vue-property-decorator'
import FaqAccordionPanel from '@/views/faq/components/FaqAccordionPanel.vue'
import SectionMixin from '@/views/faq/mixins/SectionMixin'

@Component({
  components: {
    FaqAccordionPanel,
  },
})
export default class SectionSubscriptions extends Mixins(SectionMixin) {
  // Refs
  @Ref('chapterZero') readonly chapterZero!: HTMLElement

  @Ref('chapterOne') readonly chapterOne!: HTMLElement

  @Ref('chapterTwo') readonly chapterTwo!: HTMLElement

  @Ref('chapterThree') readonly chapterThree!: HTMLElement

  @Ref('chapterFour') readonly chapterFour!: HTMLElement

  @Ref('chapterFive') readonly chapterFive!: HTMLElement

  @Ref('chapterSix') readonly chapterSix!: HTMLElement

  @Ref('chapterSeven') readonly chapterSeven!: HTMLElement

  @Ref('chapterEight') readonly chapterEight!: HTMLElement

  @Ref('chapterNine') readonly chapterNine!: HTMLElement

  @Ref('chapterTen') readonly chapterTen!: HTMLElement

  @Ref('chapterEleven') readonly chapterEleven!: HTMLElement

  @Ref('chapterTwelve') readonly chapterTwelve!: HTMLElement

  @Ref('chapterThirteen') readonly chapterThirteen!: HTMLElement
}

import { VerificationModule } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const Verification: VerificationModule = {
  state: () => ({ verification: null }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default Verification

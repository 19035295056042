



















import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {},
})
export default class GroupChannelsList extends Vue {
  @VuexGetter('nChats') getChannelsListData!: any[]

  @VuexGetter('nChats') getDialog!: any

  @VuexGetter('nChats') getClientsListLoading!: boolean

  get activeChannelId(): number {
    return this.getDialog?.channel?.id || 0
  }
}

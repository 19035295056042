var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-data-table',{attrs:{"pages":_vm.getStreamsForTableParams.pages,"currentPage":_vm.getStreamsForTableParams.page,"items":_vm.getStreamsForTableItems,"headers":_vm.tableHeaders,"loading":_vm.getStreamsListLoading},on:{"input":_vm.tableChangePage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.offerName",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.offer.title))])]}},{key:"item.streamName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(function () { return item.link; }),expression:"() => item.link"}],staticClass:"d-flex align-center",on:{"click":_vm.copyLink}},[_c('v-sheet',{staticClass:"pointer d-flex align-center justify-center bg-accent br-4 mr-2",attrs:{"height":"16","width":"56"}},[_c('b',{staticClass:"text-xsmall text-white"},[_vm._v("Copy")])]),_c('span',[_vm._v(_vm._s(item.link))])],1)]}},{key:"item.income",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.income || 0)+" "+_vm._s(_vm.getAppCurrencySymbol))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pr-0"},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"px-4 py-3 _bl pointer",on:{"click":function($event){return _vm.goToEditStream(item)}}},[_c('icon',{attrs:{"color":"var(--v-primary-base)","data":require("@/assets/icons/sorting-icon.svg")}})],1),_c('div',{staticClass:"px-4 py-3 _bl pointer",on:{"click":function($event){return _vm.openDeletePopUp(item.id)}}},[_c('icon',{attrs:{"color":"var(--v-primary-base)","data":require("@/assets/icons/delete-sorting-icon.svg")}})],1)])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
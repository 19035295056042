




































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { DomainAppModelType } from '@/services/api/Domains/DomainsTypes'
import { tableHeadersType } from '../types'

@Component({})
export default class DomainParkingTable extends Vue {
  moment: any

  // Computed
  get tableHeaders(): tableHeadersType[] {
    return [
      { text: 'ID', value: 'id' },
      { text: this.$t('status'), value: 'status' },
      { text: 'URL', value: 'url' },
      { text: this.$t('added'), value: 'created' },
      { text: '', value: 'actions' },
    ]
  }

  // vuex module getter
  @VuexGetter('nBusinessDomainParking') getDomainsItems!: DomainAppModelType[] | []

  @VuexGetter('nBusinessDomainParking') getDomainsListLoading!: boolean

  @VuexGetter('nBusinessDomainParking') getDomainsParams!: any

  // Emit
  @Emit('tableChangePage')
  tableChangePage(page: number): number {
    return page
  }

  @Emit('openDeletePopUp')
  openDeletePopUp(id: number): number {
    return id
  }
}

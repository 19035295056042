import i18n from '@/plugins/i18n'
import moment from 'moment'
import { StatsModule } from './types'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const Stats: StatsModule = {
  state: () => ({
    analytics: null,
    commonStats: null,
    reportsTableStats: null,
    metrics: null,
    affiliateStatTable: null,
    dateRange: [moment().add(-13, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    groupBy: { value: 'day', name: i18n.t('pageAnalyticsReports.metricContainer.groupBy.day') },
    menuTab: 'created',
    filterItems: [
      {
        check: true,
        isPercent: false,

        value: 'total_installs',
        chartLineActive: true,
        color: 'yellow',
      },
      {
        check: true,
        isPercent: false,

        value: 'CR',
        chartLineActive: false,
        color: 'green',
      },
      {
        check: true,
        isPercent: false,

        value: 'total_subscription_activation_count',
        chartLineActive: false,
        color: '#FFE4B5',
      },
      {
        check: true,
        isPercent: false,

        value: 'total_unsubscriptions',
        chartLineActive: false,
        color: 'blue',
      },
      {
        check: true,
        isPercent: false,

        value: 'sales',
        chartLineActive: false,
        color: 'brown',
      },

      {
        check: true,
        isPercent: false,

        value: 'rebill1_success_sum',
        chartLineActive: false,
        color: 'red',
      },

      {
        check: true,
        isPercent: false,

        value: 'rebill2_success_sum',
        chartLineActive: false,
        color: 'purple',
      },
      {
        check: true,
        isPercent: false,

        value: 'rebill3_success_sum',
        chartLineActive: false,
        color: 'lime',
      },
      {
        check: true,
        isPercent: false,

        value: 'rebill4_success_sum',
        chartLineActive: false,
        color: 'pink',
      },
      {
        check: true,
        isPercent: false,

        value: 'rebill5_success_sum',
        chartLineActive: false,
        color: 'orange',
      },
      {
        check: false,
        isPercent: false,

        value: 'rebill6_success_sum',
        chartLineActive: false,
        color: '#2F4F4F',
      },
      {
        check: false,
        isPercent: false,

        value: 'rebill7_success_sum',
        chartLineActive: false,
        color: '#B22222',
      },
      {
        check: false,
        isPercent: false,

        value: 'rebill8_success_sum',
        chartLineActive: false,
        color: '#1E90FF',
      },
      {
        check: false,
        isPercent: false,

        value: 'rebill9_success_sum',
        chartLineActive: false,
        color: '#CD5C5C',
      },
      {
        check: false,
        isPercent: false,

        value: 'rebill10plus_success_sum',
        chartLineActive: false,
        color: '#7CFC00',
      },
      {
        check: true,
        isPercent: false,

        value: 'revenue',
        chartLineActive: false,
        color: '#20B2AA',
      },
    ],
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default Stats

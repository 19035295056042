import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'

interface OrdersModelAppTypes {
  id?: number
}
interface OrdersModelApiTypes {
  id?: number
}

class OrdersService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.orders')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: OrdersModelAppTypes = {}

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.ORDERS)
  }

  public getEmptyItem(): OrdersModelAppTypes {
    const data = JSON.parse(JSON.stringify(this.AppModelPropsDefault))
    return data
  }

  public transformDataForApi(data): OrdersModelApiTypes {
    return data
  }

  public transformDataForApp(incomingData, action): OrdersModelAppTypes {
    // incomingData.created = moment(incomingData.created).format('DD.MM.YYYY HH:mm')
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    delete params.created
    delete params.client
    delete params.trackNumber
    delete params.app
    delete params.status
    delete params.positionsExists
    delete params.positionsName
    delete params.delivery
    if (incomingParams.created && incomingParams.created.length > 0) {
      params['created[after]'] = `${moment(incomingParams.created[0]).format(
        'DD.MM.YYYY'
      )} 00:00:00`
      params['created[before]'] = `${moment(incomingParams.created[1]).format(
        'DD.MM.YYYY'
      )} 23:59:59`
    }
    if (incomingParams.trackNumber && incomingParams.trackNumber.length) {
      params.trackNumber = incomingParams.trackNumber
    }
    if (incomingParams.client && incomingParams.client.length) {
      params.client = incomingParams.client
    }
    if (incomingParams.app) {
      params.app = `${ENDPOINTS.APPS}/${incomingParams.app.id}`
    }
    if (incomingParams.positionsExists) {
      params[`exists[order_positions]`] = true
    }
    if ('positionsName' in incomingParams && incomingParams.positionsName.length > 0) {
      params[`order_positions.product.name`] = incomingParams.positionsName
    }
    if ('delivery' in incomingParams && incomingParams.delivery.length) {
      params.delivery = Number(incomingParams.delivery)
    }
    if ('status' in incomingParams && incomingParams.status.length) {
      params.status = Number(incomingParams.status)
    }
    if ('type' in incomingParams) {
      params.type = Number(incomingParams.type)
    }
    params['order[created]'] = 'desc'
    return params
  }
}
export default new OrdersService()

import { RouteConfig } from 'vue-router'
import i18n from '@/plugins/i18n'
import EmptyPage from '@/views/EmptyPage.vue'
// import Finances from '@/views/Finances.vue'
import AffiliateRequisites from '@/views/affiliate/finances/Requisites/Requisites.vue'
import BusinessRequisites from '@/views/business/finances/Requisites/Requisites.vue'
import Finances from '@/views/affiliate/finances/Finances.vue'
import AffiliateOffersList from '@/views/affiliate/offers/offersList/OffersList.vue'
import AffiliateOffersItem from '@/views/affiliate/offers/offersItem/OffersItem.vue'
import Streams from '@/views/affiliate/streams/Streams.vue'
import News from '@/views/affiliate/news/News.vue'
import Orders from '@/views/business/orders/Orders.vue'
import Auth from '@/views/auth/Auth.vue'
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'
import RestorePassword from '@/views/auth/RestorePassword.vue'
import AffiliateMarketing from '@/views/business/affiliateMarketing/AffiliateMarketing.vue'
import Terms from '@/views/auth/Terms.vue'
import BusinessHome from '@/views/business/BusinessHome.vue'
import AffiliateHome from '@/views/affiliate/AffiliateHome.vue'
import AnalyticsDashboard from '@/views/business/analytics/AnaliticsDashboard/AnalyticsDashboard.vue'
import AnalyticsReports from '@/views/business/analytics/reports/Reports.vue'
import Faq from '@/views/faq/Faq.vue'
import Profile from '@/views/profile/Profile.vue'
import ChangePassword from '@/views/profile/ChangePassword.vue'
import AffiliateStatistics from '@/views/affiliate/statistics/AffiliateStatistics.vue'
import BusinessButtons from '@/views/business/buttons/BusinessButtons.vue'
import BusinessCustomers from '@/views/business/customers/BusinessCustomers.vue'
import BusinessAutoFunnels from '@/views/business/autoFunnels/BusinessAutoFunnels.vue'
import BusinessContent from '@/views/business/content/BusinessContent.vue'
import BusinessSalesChannels from '@/views/business/salesChannels/BusinessSalesChannels.vue'
import BusinessReferral from '@/views/business/referral/BusinessReferral.vue'
import BusinessSpecialOffers from '@/views/business/specialOffers/BusinessSpecialOffers.vue'
import BusinessPublication from '@/views/business/publication/BusinessPublication.vue'
import BusinessVerification from '@/views/business/verification/BusinessVerification.vue'
import BusinessVerificationStatus from '@/views/business/verification/verificationStatus/VerificationStatus.vue'
import BusinessSubscriptions from '@/views/business/subscriptions/BusinessSubscriptions.vue'
import Managment from '@/views/business/subscriptions/Managment/Managment.vue'
import Transactions from '@/views/business/subscriptions/transactions/Transactions.vue'
import Tariffs from '@/views/business/subscriptions/Tariffs/Tariffs.vue'
import Physical from '@/views/business/subscriptions/Physical/Physical.vue'
import GoodsAndServices from '@/views/business/goodsAndServices/GoodsAndServices.vue'
import LogisticsList from '@/views/business/goodsAndServices/logistics/LogisticsList.vue'
import LogisticsCreate from '@/views/business/goodsAndServices/logistics/LogisticsCreate.vue'
import LogisticsEdit from '@/views/business/goodsAndServices/logistics/LogisticsEdit.vue'
import AffiliateDomainParking from '@/views/affiliate/tools/domainParking/DomainParking.vue'
import BusinessDomainParking from '@/views/business/tools/domainParking/DomainParking.vue'
import Placement from '@/views/affiliate/tools/placement/Placement.vue'
import GlobalPostback from '@/views/affiliate/tools/globalPostback/GlobalPostback.vue'
import PostbackLog from '@/views/affiliate/tools/postbackLog/PostbackLog.vue'
import ProductsList from '@/views/business/goodsAndServices/products/ProductsList.vue'
import ProductsCreate from '@/views/business/goodsAndServices/products/ProductsCreate.vue'
import ProductsEdit from '@/views/business/goodsAndServices/products/ProductsEdit.vue'
import GSOrdersList from '@/views/business/goodsAndServices/orders/OrdersList.vue'
import GSOrdersDetail from '@/views/business/goodsAndServices/orders/OrdersDetail.vue'
import GSOrdersConfirm from '@/views/business/goodsAndServices/orders/OrdersConfirm.vue'
import ServicesList from '@/views/business/goodsAndServices/services/ServicesList.vue'
import ServicesCreate from '@/views/business/goodsAndServices/services/ServicesCreate.vue'
import ServicesEdit from '@/views/business/goodsAndServices/services/ServicesEdit.vue'
import Dialogs from '@/views/crm/Dialogs.vue'
import AffiliateAnalytics from '@/views/affiliate/analytics/AffiliateAnalytics.vue'
import AffiliateSupport from '@/views/affiliate/support/AffiliateSupport.vue'
import AffiliateInstructions from '@/views/affiliate/instructions/AffiliateInstructions.vue'

import Payments from '@/views/business/goodsAndServices/payments/Payments.vue'
import Returns from '@/views/business/goodsAndServices/returns/Returns.vue'

import AffiliateTools from '@/views/affiliate/tools/AffiliateTools.vue'
import BussinessAnalytics from '@/views/business/analytics/BussinessAnalytics.vue'

import BusinessAccruals from '@/views/business/finances/accruals/Accruals.vue'
import BusinessFinances from '@/views/business/finances/Finances.vue'
import Documents from '@/views/documents/Documents.vue'
import NoApps from '@/views/auth/NoApps.vue'

const navigation: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      isRequireAuth: true,
      title: 'Home',
    },
    redirect: '/business',
  },
  {
    path: '/business',
    name: 'Business_Home',
    component: BusinessHome,
    meta: {
      isRequireAuth: true,
      title: i18n.t('businessHome'),
    },
  },
  {
    path: '/business/orders',
    name: 'Business_Orders',
    component: Orders,
    meta: {
      isRequireAuth: true,
      title: i18n.t('orders'),
    },
  },
  {
    path: '/business/subscriptions',
    name: 'Business_Subscriptions',
    component: BusinessSubscriptions,
    meta: {
      isRequireAuth: true,
      title: i18n.t('busunessNav.subscriptions.home'),
    },
  },
  {
    path: '/business/subscriptions/physical',
    name: 'Business_Physical',
    component: Physical,
    meta: {
      isRequireAuth: true,
      title: i18n.t('busunessNav.subscriptions.physical'),
    },
  },
  {
    path: '/business/subscriptions/managment',
    name: 'Business_Managment',
    component: Managment,
    meta: {
      isRequireAuth: true,
      title: i18n.t('subscriptionManagment.title'),
    },
  },
  {
    path: '/business/subscriptions/transactions',
    name: 'Business_Transactions',
    component: Transactions,
    meta: {
      isRequireAuth: true,
      title: i18n.t('transactions.title'),
    },
  },
  {
    path: '/business/subscriptions/tariffs',
    name: 'Business_Tariffs',
    component: Tariffs,

    meta: {
      isRequireAuth: true,
      title: i18n.t('tariffs'),
    },
  },
  {
    path: '/business/buttons',
    name: 'Business_Buttons',
    component: BusinessButtons,
    meta: {
      isRequireAuth: true,
      title: i18n.t('buttons'),
    },
  },

  {
    path: '/business/goods_and_services',
    name: 'Business_GoodsAndServices',
    component: GoodsAndServices,
    meta: {
      isRequireAuth: true,
      title: i18n.t('goodsAndServices'),
    },
    redirect: { name: 'Business_GoodsAndServices.products' },
    children: [
      // products
      {
        path: 'products',
        name: 'Business_GoodsAndServices.products',
        component: ProductsList,
        meta: {
          title: i18n.t('products.myProducts'),
        },
      },
      {
        path: 'products/create',
        name: 'Business_GoodsAndServices.products.create',
        component: ProductsCreate,
        meta: {
          title: i18n.t('product.addTitle'),
        },
      },
      {
        path: 'products/:id',
        name: 'Business_GoodsAndServices.products.edit',
        component: ProductsEdit,
        meta: {
          title: i18n.t('product.editTitle'),
        },
      },

      // services
      {
        path: 'services',
        name: 'Business_GoodsAndServices.services',
        component: ServicesList,
        meta: {
          title: i18n.t('services.myServices'),
        },
      },
      {
        path: 'services/create',
        name: 'Business_GoodsAndServices.services.create',
        component: ServicesCreate,
        meta: {
          title: i18n.t('services.additingService'),
        },
      },
      {
        path: 'services/:id',
        name: 'Business_GoodsAndServices.services.edit',
        component: ServicesEdit,
        meta: {
          title: i18n.t('services.edit'),
        },
      },

      // orders
      {
        path: 'orders',
        name: 'Business_GoodsAndServices.orders',
        component: GSOrdersList,
        meta: {
          title: i18n.t('ordres.myOrders'),
        },
      },
      {
        path: 'orders/:id',
        name: 'Business_GoodsAndServices.orders.detail',
        component: GSOrdersDetail,
        meta: {
          title: i18n.t('orderDetails'),
        },
      },
      {
        path: 'orders/:id/confirm',
        name: 'Business_GoodsAndServices.orders.confirm',
        component: GSOrdersConfirm,
        meta: {
          title: i18n.t('orderConfirn'),
        },
      },

      {
        path: 'payments',
        name: 'Business_GoodsAndServices.payments',
        component: Payments,
        meta: {
          title: i18n.t('payouts'),
        },
      },
      {
        path: 'logistics',
        name: 'Business_GoodsAndServices.logistics',
        component: LogisticsList,
        meta: {
          title: i18n.t('storages.myStorages'),
        },
      },
      {
        path: 'logistics/create',
        name: 'Business_GoodsAndServices.logistics.create',
        component: LogisticsCreate,
        meta: {
          title: i18n.t('storages.createNewStorage'),
        },
      },
      {
        path: 'logistics/:id',
        name: 'Business_GoodsAndServices.logistics.edit',
        component: LogisticsEdit,
        meta: {
          title: i18n.t('storages.edit'),
        },
      },
      {
        path: 'returns',
        name: 'Business_GoodsAndServices.returns',
        component: Returns,
        meta: {
          title: i18n.t('returns'),
        },
      },
      {
        path: 'disputes',
        name: 'Business_GoodsAndServices.disputes',
        component: EmptyPage,
        meta: {
          title: i18n.t('controversy'),
        },
      },
    ],
  },
  {
    path: '/business/referral',
    name: 'Business_Referral',
    component: BusinessReferral,
    meta: {
      isRequireAuth: true,
      title: i18n.t('referral'),
    },
  },
  {
    path: '/business/customers',
    name: 'Business_Customers',
    component: BusinessCustomers,
    meta: {
      isRequireAuth: true,
      title: i18n.t('customers'),
    },
  },
  {
    path: '/business/auto_funnels',
    name: 'Business_AutoFunnels',
    component: BusinessAutoFunnels,
    meta: {
      isRequireAuth: true,
      title: i18n.t('autoFunnels'),
    },
  },
  {
    path: '/business/special_offers',
    name: 'Business_SpecialOffers',
    component: BusinessSpecialOffers,
    meta: {
      isRequireAuth: true,
      title: i18n.t('specialOffers'),
    },
  },
  {
    path: '/business/content',
    name: 'Business_Content',
    component: BusinessContent,
    meta: {
      isRequireAuth: true,
      title: i18n.t('content'),
    },
  },
  {
    path: '/business/publication',
    name: 'Business_Publication',
    component: BusinessPublication,
    meta: {
      isRequireAuth: true,
      title: i18n.t('pageBusinessPublish.title'),
    },
  },
  {
    path: '/business/sales_channels',
    name: 'Business_SalesChannels',
    component: BusinessSalesChannels,
    meta: {
      isRequireAuth: true,
      title: i18n.t('salesChannels'),
    },
  },
  {
    path: '/business/finances',
    name: 'Business_Finances',
    meta: {
      isRequireAuth: true,
      title: i18n.t('busunessNav.finances.finances'),
    },
    component: BusinessFinances,
  },
  {
    path: '/business/faq',
    name: 'Business_Faq',
    component: Faq,
    meta: {
      isRequireAuth: true,
      title: 'F.A.Q',
    },
  },
  {
    path: '/business/finances/requisites',
    name: 'Business_Requisites',
    meta: {
      isRequireAuth: true,
      title: i18n.t('affileateNav.finances.requisites'),
    },
    component: BusinessRequisites,
  },
  {
    path: '/business/finances/accruals',
    name: 'Business_Accruals',
    meta: {
      isRequireAuth: true,
      title: i18n.t('busunessNav.finances.accruals'),
    },
    component: BusinessAccruals,
  },
  {
    path: '/business/analytics',
    name: 'Business_Analytics',
    component: BussinessAnalytics,
    meta: {
      isRequireAuth: true,
      title: i18n.t('busunessNav.analytics.home'),
    },
    redirect: { name: 'Business_AnalyticsDashboard' },
    children: [
      {
        path: '/business/analytics/dashboard',
        name: 'Business_AnalyticsDashboard',
        meta: {
          isRequireAuth: true,
          title: i18n.t('analiticDashboard'),
        },
        component: AnalyticsDashboard,
      },
      {
        path: '/business/analytics/reports',
        name: 'Business_AnalyticsReports',
        meta: {
          isRequireAuth: true,
          title: i18n.t('analiticReports'),
        },
        component: AnalyticsReports,
      },
    ],
  },
  {
    path: '/business/affiliate-marketing',
    name: 'Business_AffiliateMarketing',
    meta: {
      isRequireAuth: true,
      title: i18n.t('busunessNav.affileate_marketing'),
    },
    component: AffiliateMarketing,
  },

  {
    path: '/business/verification',
    name: 'Business_Verification',
    meta: {
      isRequireAuth: true,
      title: i18n.t('verificationApp'),
    },
    component: BusinessVerification,
  },
  {
    path: '/business/verification/status',
    name: 'Business_VerificationStatus',
    component: BusinessVerificationStatus,
    meta: {
      isRequireAuth: true,
      title: i18n.t('acceptingOnline'),
    },
  },
  {
    path: '/business/tools/domain_parking',
    name: 'Business_Tools_DomainParking',
    component: BusinessDomainParking,
    meta: {
      isRequireAuth: true,
      title: i18n.t('domain.title'),
    },
  },
  {
    path: '/affiliate',
    name: 'Affiliate_Home',
    component: AffiliateHome,
    meta: {
      isRequireAuth: true,
      title: i18n.t('mainNav.affileate'),
    },
  },
  {
    path: '/affiliate/statistics',
    name: 'Affiliate_Statistics',
    component: AffiliateStatistics,
    meta: {
      isRequireAuth: true,
      title: i18n.t('affileateNav.statistics'),
    },
  },
  {
    path: '/affiliate/analytics',
    name: 'Affiliate_Analytics',
    component: AffiliateAnalytics,
    meta: {
      isRequireAuth: true,
      title: i18n.t('busunessNav.analytics.home'),
    },
  },
  {
    path: '/affiliate/tools',
    name: 'Affiliate_Tools',
    component: AffiliateTools,
    meta: {
      isRequireAuth: true,
      title: i18n.t('tools'),
    },
  },
  {
    path: '/affiliate/tools/domain_parking',
    name: 'Affiliate_Tools_DomainParking',
    component: AffiliateDomainParking,
    meta: {
      isRequireAuth: true,
      title: i18n.t('domain.title'),
    },
  },
  {
    path: '/affiliate/tools/placement',
    name: 'Affiliate_Tools_Placement',
    component: Placement,
    meta: {
      isRequireAuth: true,
      title: i18n.t('placement.title'),
    },
  },
  {
    path: '/affiliate/tools/global_postback',
    name: 'Affiliate_Global_Postback',
    component: GlobalPostback,
    meta: {
      isRequireAuth: true,
      title: i18n.t('globalPostBack.title'),
    },
  },
  {
    path: '/affiliate/tools/postback_log',
    name: 'Affiliate_Tools_PostbackLog',
    component: PostbackLog,
    meta: {
      isRequireAuth: true,
      title: i18n.t('postbackLogs.title'),
    },
  },
  {
    path: '/affiliate/support',
    name: 'Affiliate_Support',
    component: AffiliateSupport,
    meta: {
      isRequireAuth: true,
      title: i18n.t('support'),
    },
  },
  {
    path: '/affiliate/instructions',
    name: 'Affiliate_Instructions',
    component: AffiliateInstructions,
    meta: {
      isRequireAuth: true,
      title: i18n.t('instructions'),
    },
  },
  {
    path: '/affiliate/faq',
    name: 'Affiliate_Faq',
    component: Faq,
    meta: {
      isRequireAuth: true,
      title: 'F.A.Q',
    },
  },
  {
    path: '/affiliate/finances',
    name: 'Affiliate_Finances',
    meta: {
      isRequireAuth: true,
      title: i18n.t('affileateNav.finances.finances'),
    },
    component: Finances,
  },
  {
    path: '/affiliate/finances/requisites',
    name: 'Affiliate_Requisites',
    meta: {
      isRequireAuth: true,
      title: i18n.t('affileateNav.finances.requisites'),
    },
    component: AffiliateRequisites,
  },

  {
    path: '/affiliate/offers',
    name: 'Affiliate_Offers_List',
    meta: {
      isRequireAuth: true,
      title: i18n.t('affileateNav.offers'),
    },
    component: AffiliateOffersList,
  },
  {
    path: '/affiliate/offers/:id',
    name: 'Affiliate_Offers_Item',
    meta: {
      isRequireAuth: true,
      title: i18n.t('offer'),
    },
    component: AffiliateOffersItem,
  },
  {
    path: '/affiliate/streams',
    name: 'Affiliate_Streams',
    meta: {
      isRequireAuth: true,
      title: i18n.t('affileateNav.streams'),
    },
    component: Streams,
  },
  {
    path: '/affiliate/news',
    name: 'Affiliate_News',
    meta: {
      isRequireAuth: true,
      title: i18n.t('affileateNav.news'),
    },
    component: News,
  },
  {
    path: '/crm',
    name: 'Dialogs',
    meta: {
      isRequireAuth: true,
      title: i18n.t('dialogues'),
    },
    component: Dialogs,
  },
  {
    path: '/documents/:slug',
    name: 'Documents',
    meta: {
      documents: true,
      isRequireAuth: false,
      title: i18n.t('documents'),
    },
    component: Documents,
  },

  {
    path: '/auth',
    name: 'Auth',
    meta: {
      isRequireAuth: false,
    },
    component: Auth,
    redirect: { name: 'auth.login' },
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: Login,
        meta: {
          title: i18n.t('authorization'),
        },
      },
      {
        path: 'register',
        name: 'auth.register',
        component: Register,
        meta: {
          title: i18n.t('registration'),
        },
      },
      {
        path: 'restore_password',
        name: 'auth.restorePassword',
        component: RestorePassword,
        meta: {
          title: i18n.t('restorPassword.restor'),
        },
      },
    ],
  },

  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      terms: true,
      title: i18n.t('pageTerms.title'),
    },
  },

  {
    path: '/no-apps',
    name: 'NoApps',
    component: NoApps,
    meta: {
      title: i18n.t('restorPassword.restor'),
      isRequireAuth: true,
    },
  },

  {
    path: '/profile',
    name: 'profile.home',
    meta: {
      isRequireAuth: true,
      title: i18n.t('profil'),
    },
    component: Profile,
  },
  {
    path: '/profile/change_password',
    name: 'profile.change_password',
    meta: {
      title: i18n.t('passwordUpdate'),
      isRequireAuth: true,
    },
    component: ChangePassword,
  },
]

export default navigation

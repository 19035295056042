import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { ClientsAppModelType, ClientsApiModelType } from './ClientsTypes'

class ClientsService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.clients')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private AppModelPropsDefault: ClientsAppModelType = {}

  private defaultListParams = {
    itemsPerPage: 50,
    page: 1,
    pages: 1,
    pageLoaded: 0,
    loading: false,
  }

  private usedListParams = ['itemsPerPage', 'page', 'has_message', 'app_id', 'order[has_message]']

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.CLIENTS)
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    return incomingData
  }

  public transformDataForApp(incomingData): ClientsAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    return params
  }
}
export default new ClientsService()

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { merge } from 'lodash'
import * as Sentry from '@sentry/vue'
import AuthProvider from '@/providers/AuthProvider'
import { ProjectGetBaseURL } from '@/utils/project'
import { withRequestSubDomain, withResponseValidationErrors } from './axios.interceptors'

export const getInstance = (config: AxiosRequestConfig = {}): AxiosInstance => {
  const defaultConfig: AxiosRequestConfig = {
    baseURL: ProjectGetBaseURL(),
    withCredentials: false,
  }

  const instance = axios.create(merge(defaultConfig, config))

  // instance.interceptors.response.use((res) => res, withResponseValidationErrors)
  instance.interceptors.request.use(withRequestSubDomain)
  instance.interceptors.response.use(
    (res) => {
      return res
    },
    (err) => {
      Sentry.setTag('n-type', 'response')
      Sentry.captureException(err)
      return Promise.reject(err)
    }
  )
  instance.interceptors.request.use(
    (item) => {
      return item
    },
    (err) => {
      Sentry.setTag('n-type', 'request')
      Sentry.captureException(err)
      return Promise.reject(err)
    }
  )

  return instance
}

export const getInstanceWithToken = (config: AxiosRequestConfig = {}): AxiosInstance => {
  const token = AuthProvider.getToken('crm')

  return getInstance(
    merge(
      {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      },
      config
    )
  )
}

export const getAffiliateInstance = (): AxiosInstance =>
  getInstanceWithToken({ baseURL: process.env.VUE_APP_AFFILIATE_API_BASE_URL })

export const getChatInstanceWithToken = (): AxiosInstance =>
  getInstanceWithToken({ baseURL: process.env.VUE_APP_CHAT_API_BASE_URL })

export const ApiApp = (): AxiosInstance => {
  return getInstanceWithToken({ baseURL: process.env.VUE_APP_CHAT_API_BASE_URL })
}

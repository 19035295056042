import i18n from '@/plugins/i18n'
import { TranslateResult } from 'vue-i18n'
import { cloneDeep } from 'lodash'
import BillingRequestService from '@/services/api/BillingRequest/BillingRequestService'
import { BillingRequestAppModelType } from '@/services/api/BillingRequest/BillingRequestTypes'
import {
  nBusinessTransactionsManagmentMutations,
  nBusinessTransactionsManagmentActions,
  nBusinessTransactionsManagmentGetters,
  nBusinessTransactionsManagmentModule,
} from './types'

const defaultState = {
  lists: {
    transactions: {
      loading: false,
      items: [],
      item: null,
      params: BillingRequestService.getDefaultParams(),
      isOpenPopUpDetails: false,
      isOpenPopUpReturn: false,
    },
  },
}

const actions: nBusinessTransactionsManagmentActions = {
  async transactionsGetItems({ commit, state }): Promise<boolean> {
    commit('transactionsChangeLoadingList', true)
    const response = await BillingRequestService.getItems({
      params: state.lists.transactions.params,
    })
    if (response.status) {
      commit('transactionsListParamsUpdate', response.params)
      commit('transactionsListItemsUpdate', response.data)
    }
    commit('transactionsChangeLoadingList', false)
    return response.status
  },

  async transactionsGetItem({ commit }, id): Promise<boolean> {
    const response = await BillingRequestService.getItem({
      id,
    })
    if (response.status) {
      commit('transactionsListItemUpdate', response.data)
    }
    return response.status
  },
  async transactionsUpdateItem({ commit }, params): Promise<boolean> {
    const response = await BillingRequestService.transactionsUpdateItemCancel(params)
    return response.status
  },
}

const mutations: nBusinessTransactionsManagmentMutations = {
  transactionsChangeLoadingList(state, status: boolean): void {
    state.lists.transactions.loading = status
  },
  transactionsChangePopUpDetailsStatus(state, status: boolean): void {
    state.lists.transactions.isOpenPopUpDetails = status
  },
  transactionsChangePopUpReturnStatus(state, status: boolean): void {
    state.lists.transactions.isOpenPopUpReturn = status
  },
  transactionsListParamsUpdate(state, params): void {
    state.lists.transactions.params = { ...state.lists.transactions.params, ...params }
  },
  transactionsListItemsUpdate(state, items: Array<BillingRequestAppModelType>): void {
    state.lists.transactions.items = items
  },
  transactionsListItemUpdate(state, item: BillingRequestAppModelType): void {
    state.lists.transactions.item = item
  },
}

const getters: nBusinessTransactionsManagmentGetters = {
  getTransactionsListLoading(state): boolean {
    return state.lists.transactions.loading
  },
  getTransactionsListParams(state): any {
    return state.lists.transactions.params
  },
  getTransactionsPopUpDetailsStatus(state): boolean {
    return state.lists.transactions.isOpenPopUpDetails
  },
  getTransactionsPopUpReturnStatus(state): boolean {
    return state.lists.transactions.isOpenPopUpReturn
  },
  getTransactionsListItems(state): Array<BillingRequestAppModelType> | [] {
    return state.lists.transactions.items
  },

  getTransactionsListItem(state): BillingRequestAppModelType | null {
    return state.lists.transactions.item
  },
  getTransactionsStatuses(): [{ value: number; name: TranslateResult | string }] | unknown {
    return [
      {
        value: 2,
        name: i18n.t('transactions.status.2'),
      },
      {
        value: 3,
        name: i18n.t('transactions.status.3'),
      },
      {
        value: 4,
        name: i18n.t('transactions.status.4'),
      },
    ]
  },
}

const nBusinessTransactionsManagment: nBusinessTransactionsManagmentModule = {
  state: () => cloneDeep(defaultState),

  actions,
  mutations,
  getters,
  namespaced: true,
}

export default nBusinessTransactionsManagment

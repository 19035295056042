



























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AnalyticsHeader from '@/views/business/analytics/components/AnalyticsHeader.vue'
import CommonChartCard from '@/views/business/analytics/components/CommonChartCard.vue'
import CommonLineChart from '@/views/business/analytics/components/charts/CommonLineChart.vue'
import CommonBarChart from '@/views/business/analytics/components/charts/CommonBarChart.vue'
import { VuexGetter } from '@/store/decorators'

@Component({
  components: {
    CommonChartCard,
    AnalyticsHeader,
    CommonLineChart,
    CommonBarChart,
  },
})
export default class AnalyticsDashboard extends Vue {
  isPreloaderShow = false

  sumAdvertiser = 1

  firstRowData = [
    {
      key: 'install',
      additionalData: [],
    },
    {
      key: 'subscribe',
      additionalData: [],
    },
    {
      key: 'sale',
      additionalData: [],
    },
    {
      key: 'revenue',
      additionalData: [],
    },
  ]

  secondRowData = [
    {
      key: 'subscribe_conversation',
      additionalData: [],
    },
  ]

  subscribesChartData = [
    {
      datasetKey: 'subscribe',
      color: '#27C793',
    },
    {
      datasetKey: 'unsubscribe',
      color: '#271460',
    },
  ]

  subscribesAdditionalData: any = []

  buttonColors = ['green', 'yellow', 'blue', 'gray', 'red', 'white', 'purple', 'lime']

  // Vuex Getters

  @VuexGetter('nBusinessAnalytics') getCommonData!: any

  @VuexGetter('nBusinessAnalytics') getFilterItems!: any

  // Computed
  get chartTitles(): any {
    return {
      install: this.$t('pageAnalyticsDashboard.chartsTitles.install'),
      subscribe: this.$t('pageAnalyticsDashboard.chartsTitles.subscribe'),
      sale: this.$t('pageAnalyticsDashboard.chartsTitles.sale'),
      revenue: this.$t('pageAnalyticsDashboard.chartsTitles.revenue'),
      subscribe_conversation: this.$t('pageAnalyticsDashboard.chartsTitles.subscribeConversation'),
    }
  }

  updateAdditionalData(): void {
    const daysCount = this.getCommonData?.by_day?.length || 0
    this.firstRowData.forEach((p: any) => {
      const sum = this.getSumByKey(p.key)
      p.additionalData = []
      p.additionalData.push({
        label: 'pageAnalyticsDashboard.additionalTitles.total',
        value: sum.toFixed(0),
      })
      p.additionalData.push({
        label: 'pageAnalyticsDashboard.additionalTitles.averageDay',
        value: (sum / daysCount || 0).toFixed(0),
      })
    })
    this.secondRowData.forEach((p: any) => {
      const sum = this.getSumByKey(p.key) || 0
      p.additionalData = []
      p.additionalData.push({
        label: 'pageAnalyticsDashboard.additionalTitles.averagePeriod',
        value: `${(sum / daysCount || 0).toFixed(1)}%`,
      })
    })

    // this.sumAdvertiser = this.getCommonData.by_advertiser
    //  .map((z) => z.revenue)
    //  .reduce((p, acc) => {
    //    acc += +p
    //    return acc
    //  }, 0)

    this.subscribesAdditionalData = []
    this.subscribesChartData.forEach((p) => {
      const sum = this.getSumByKey(p.datasetKey)
      this.subscribesAdditionalData.push({
        // label: p.label + ': всего за период',
        label: 'pageAnalyticsDashboard.additionalTitles.total',
        value: sum.toFixed(0),
      })

      this.subscribesAdditionalData.push({
        // label: p.label + ': среднее за день',
        label: 'pageAnalyticsDashboard.additionalTitles.averageDay',
        value: (sum / daysCount || 0).toFixed(0),
      })
    })
  }

  getSumByKey(key): any {
    return (
      this.getCommonData?.by_day
        .map((z) => z[key])
        .reduce((p, acc) => {
          acc += p
          return acc
        }, 0) || 0
    )
  }

  // Watch
  @Watch('getCommonData')
  getCommonDataHandler(): void {
    this.updateAdditionalData()
  }

  // Hooks
  mounted(): void {
    this.updateAdditionalData()
  }
}

import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { PhoneConfirmDataApiModelType } from './PhoneConfirmDataType'

class PhoneConfirmData extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.smsConfirm')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PHONE_CONFIRM_DATA)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  public async create<D>({ data }: { data: D }): Promise<any> {
    try {
      const response = await this.api.post(this.ENDPOINTS.CREATE, data)
      this.addNotification({
        message: `${i18n.t('allertMessage.smsConfirmSuccess')}`,
      })
      return {
        status: true,
        data: this.transformDataForApp(response.data),
        params: {},
      }
    } catch (error) {
      this.addBadRequestNotification(error, `${i18n.t('allertMessage.smsConfirmError')}`)
      return { status: false, data: {}, params: {}, error }
    }
  }

  public async add<D>({ data }: { data: D }): Promise<any> {
    try {
      const response = await this.api.post(`${this.ENDPOINTS.CREATE}/add`, data)
      this.addNotification({
        message: `${i18n.t('allertMessage.recordCreateSuccess', { model: this.modelName })}`,
      })
      return {
        status: true,
        data: this.transformDataForApp(response.data),
        params: {},
      }
    } catch (error) {
      this.addBadRequestNotification(
        error,
        `${i18n.t('allertMessage.recordCreateError', { model: this.modelName })}`
      )
      return { status: false, data: {}, params: {}, error }
    }
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    return data
  }

  public transformDataForApp(incomingData) {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }
}
export default new PhoneConfirmData()

import { DialoguesModule } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const Dialogues = {
  state: () => ({
    isLoaded: false,
    clients: {
      list: {
        itemsAll: [],
        items: [],
        params: {
          itemsPerPage: 30,
          page: 1,
          pages: 1,
          loading: false,
          has_message: true,
        },
      },
    },
    groupChats: {
      list: {
        items: [],
        params: {
          limit: 100,
          total: 0,
        },
      },
    },
    channels: {
      current: {
        id: null,
      },
      list: {
        items: [],
        params: {
          limit: 20,
          total: 0,
        },
      },
    },
    dialog: {
      active: false,
      client: {},
      channel: {},
      messages: {
        loading: false,
        page: 1,
        pages: 1,
        total: 0,
        total_new: 0,
        items: [],
      },
    },
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default Dialogues

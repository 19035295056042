import { DeliveryModule } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const Delivery: DeliveryModule = {
  state: () => ({ deliveryServices: null }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default Delivery

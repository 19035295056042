































import { Component, Vue, Emit } from 'vue-property-decorator'
import { VuexGetter } from '@/store/decorators'
import { filterDataType } from '@/views/affiliate/streams/types'
import { StreamAppModelType } from '@/services/api/Stream/StreamTypes'

@Component({})
export default class StreamsFilter extends Vue {
  // Data
  filterData: filterDataType = {
    offer: null,
    subId: null,
  }

  @VuexGetter('nAffiliateStreams') getStreamsForTableParams!: any

  @VuexGetter('nAffiliateStreams') getStreamsForFilterItems!: [] | StreamAppModelType[]

  // Methods
  setSavedFilterData(): void {
    const keysFilterData = Object.keys(this.filterData)
    keysFilterData.forEach((i) => {
      if (this.getStreamsForTableParams[i]) {
        this.filterData[i] = this.getStreamsForTableParams[i]
      }
    })
  }

  filterHandler(): void {
    setTimeout(() => {
      this.filter()
    }, 100)
  }

  // Hooks
  created(): void {
    this.setSavedFilterData()
  }

  // Emit
  @Emit()
  filter(): filterDataType {
    return this.filterData
  }
}

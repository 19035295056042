









































































































import { Component, Vue, Ref, Emit, Watch, Prop } from 'vue-property-decorator'
import Validation from '@/utils/Validation'
import { VuexGetter } from '@/store/decorators'

@Component({})
export default class PayoutSystemFormCard extends Vue {
  // Refs
  @Ref('form') readonly form!: HTMLFormElement

  // Props
  @Prop(Boolean) readonly resetForm!: boolean

  @VuexGetter('nApp') getCommission!: number

  // Data
  formData: {
    checkingAccount: string | number
    bankName: string
    bankBik: string | number
    corrAccount: string | number
    userInn: string | number
    userFullName: string
  } = {
    checkingAccount: '',
    bankName: '',
    bankBik: '',
    corrAccount: '',
    userInn: '',
    userFullName: '',
  }

  valid = false

  rules: unknown = {
    checkingAccount: Validation.getRules(['strmin:20', 'strmax:20', 'required']),
    bankName: Validation.getRules(['strmin:3', 'required']),
    bankBik: Validation.getRules(['strmin:9', 'strmax:9', 'required']),
    corrAccount: Validation.getRules(['strmin:20', 'strmax:20', 'required']),
    userInn: Validation.getRules(['strmin:10', 'strmax:12', 'required']),
    userFullName: Validation.getRules(['strmin:3', 'required']),
  }

  // Emit
  @Emit()
  submit(): any {
    this.form.validate()
    return this.valid ? this.formData : false
  }

  // Watch
  @Watch('resetForm')
  resetFormHandler(): void {
    if (this.resetForm) {
      this.form.reset()
    }
  }
}

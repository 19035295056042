import { getChatInstanceWithToken } from '@/utils/axios'
import { BaseApi2Service, EndpointsTypes } from '@/services/api2/BaseApi2Service'
import ENDPOINTS from '@/services/api2/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { HttpApiPwa } from '@/services/HttpClientService'
import { ChannelsAppModelType, ChannelsApiModelType } from './ChannelsTypes'

class ChannelsService extends BaseApi2Service {
  constructor() {
    super()
    this.api = HttpApiPwa
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.CHANNELS)
  }

  protected modelName = i18n.t('servicesModelsName.channelsChats')

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  // private AppModelPropsDefault: ChannelsAppModelType = {}

  private defaultListParams = {
    limit: 50,
    page: 1,
    pages: 1,
    loading: false,
  }

  private usedListParams = [
    'page',
    'limit',
    'app_id',
    'type',
    'search',
    'promotional_page_id',
    'followed',
    'exists[new_message_count]',
    'exists[promotional_page]',
  ]

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    return incomingData
  }

  public transformDataForApp(incomingData): ChannelsAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = this.getUsedListParams(incomingParams, this.usedListParams)
    return params
  }

  public async getItemsByNewMessages(incomingParams) {
    const params = cloneDeep(incomingParams)

    params.limit = 30
    params.page = 1
    params['exists[new_message_count]'] = 'true'

    const response = await this.getItems({ params })
    return response
  }

  public async getItemByClient(id) {
    try {
      const requestUrl = `${ENDPOINTS.CHANNELS}/by_client/${id}`
      const response = await this.api.get(requestUrl, {})
      return {
        status: true,
        data: response.data.data,
        params: {},
      }
    } catch (error) {
      this.addBadRequestNotification(error, `Ошибка получения записи[${this.modelName}] ${id}!`)
      return { status: false, data: {}, params: {}, error }
    }
  }
}
export default new ChannelsService()

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"rounded",attrs:{"headers":_vm.headers,"items":_vm.tableItems,"hide-default-footer":"","hide-default-header":"","disable-pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":_vm.headers.length}},[_vm._v(_vm._s(_vm.$t('requisites.added')))])])])]},proxy:true},{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('td',{attrs:{"width":"104px"}},[_vm._v(_vm._s(index + 1)+".")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"font-weight-bold",attrs:{"width":"350px"}},[_c('div',{staticClass:"d-flex align-center"},[(item.payoutSystem.image)?_c('img',{staticClass:"table-img mr-3",attrs:{"src":item.payoutSystem.image.content_url,"alt":item.payoutSystem.name}}):_c('div',{staticClass:"table-img _empty mr-3"}),_c('span',{staticClass:"lh-28"},[_vm._v(" "+_vm._s(item.payoutSystem.name))])])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.number || item.data.checkingAccount)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"border-left",attrs:{"width":"48px"}},[_c('v-dialog',{attrs:{"overlay-color":"primary","overlay-opacity":"0.8","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('icon',_vm._g(_vm._b({attrs:{"width":"14px","height":"14px","data":require("@/assets/icons/delete-sorting-icon.svg")}},'icon',attrs,false),on))]}}],null,true),model:{value:(_vm.dialog[item.id]),callback:function ($$v) {_vm.$set(_vm.dialog, item.id, $$v)},expression:"dialog[item.id]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 text-center text--primary"},[_vm._v(" "+_vm._s(_vm.$t('requisites.delete'))+" ")]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('requisites.deleteItem'))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog[item.id] = false}}},[_vm._v(_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog[item.id] = false
                  _vm.deleteAccount(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { HttpApiCrm } from '@/services/HttpClientService'
import { BaseApiService, EndpointsTypes } from '@/services/api/BaseApiService'
import ENDPOINTS from '@/services/api/Endpoints'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/i18n'
import { PlacementAppModelType } from './PlacementTypes'

class PlacementService extends BaseApiService {
  protected modelName = i18n.t('servicesModelsName.placements')

  constructor() {
    super()
    this.api = HttpApiCrm
    this.Related = {}
    this.ENDPOINTS = this.convertEndpoints(ENDPOINTS.PLACEMENT)
  }

  protected ENDPOINTS: EndpointsTypes

  protected api

  protected Related

  private defaultListParams = {
    itemsPerPage: 10,
    page: 1,
    pages: 1,
  }

  public getDefaultParams() {
    return cloneDeep(this.defaultListParams)
  }

  public transformDataForApi(incomingData) {
    const data = cloneDeep(incomingData)
    data.type = `/api/placement_types/${data.type}`
    return data
  }

  public transformDataForApp(incomingData): PlacementAppModelType {
    return incomingData
  }

  public transformRequestParams(incomingParams: any): any {
    const params = cloneDeep(incomingParams)
    return params
  }
}
export default new PlacementService()

































import { Component, Vue } from 'vue-property-decorator'
import { VuexGetter, VuexMutation, VuexAction } from '@/store/decorators'
import { PromotionalPageAppModelType } from '@/services/api/PromotionalPage/PromotionalPageTypes'
import { AppsAppModelType } from '@/services/api/Apps/AppsTypes'
import { PostsAppModelType } from '@/services/api/Posts/PostsTypes'
import PostItem from './components/PostItem.vue'

@Component({
  components: { PostItem },
})
export default class Step3 extends Vue {
  // Data
  isPostsFetchLoading = false

  page = 1

  // vuex module action
  @VuexAction('nTariffs') postsGetItems!: () => Promise<void>

  // vuex module mutation
  @VuexMutation('nTariffs') setFormData!: (...args) => void

  @VuexMutation('nTariffs') postsParamsUpdate!: (...args) => void

  // vuex module getter
  @VuexGetter('nTariffs') getFormData!: any

  @VuexGetter('nTariffs') getPostsItems!: PostsAppModelType[]

  @VuexGetter('nTariffs') getPromoPageItem!: PromotionalPageAppModelType

  @VuexGetter('nTariffs') getStopFetchPosts!: boolean

  @VuexGetter('nApp') getCurrentApp!: AppsAppModelType

  // Methods

  async fetchPosts(): Promise<void> {
    if (!this.getStopFetchPosts) {
      this.page++
      this.isPostsFetchLoading = true
      this.postsParamsUpdate({
        page: this.page,
      })
      await this.postsGetItems()

      this.isPostsFetchLoading = false
    }
  }

  // Hooks
  async created(): Promise<void> {
    this.postsParamsUpdate({
      app: this.getCurrentApp.id,
      page: this.page,
      'exists[products]': false,
    })
    await this.postsGetItems()
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-data-table',{staticClass:"affiliate-statistics__table",attrs:{"items":_vm.getStatsAffiliateData.detail,"loading":_vm.getStatsAffiliateListLoading,"headers":_vm.tableHeaders,"disable-sort":false,"hidePagination":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"header-bordered"}),_c('th',{staticClass:"header-bordered",attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.$t('pageAffiliateStats.tableHeader.traffic'))+" ")]),_c('th',{staticClass:"header-bordered",attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t('pageAffiliateStats.tableHeader.payment'))+" ")]),_c('th',{staticClass:"header-bordered",attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$t('pageAffiliateStats.tableHeader.conversion'))+" ")]),_c('th',{staticClass:"header-bordered",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t('pageAffiliateStats.tableHeader.finance'))+" ")]),_c('th',{staticClass:"header-bordered",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t('pageAffiliateStats.tableHeader.revshare'))+" ")])])])]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"bordered"},[(item.date)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.date,'DD.MM.YYYY')))]):_vm._e()])]}},{key:"item.stream",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"bordered"},[(item.stream)?_c('span',[_vm._v(" "+_vm._s(item.stream.title))]):_vm._e()])]}},{key:"item.offer",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"bordered"},[(item.offer)?_c('span',[_vm._v(" "+_vm._s(item.offer.title))]):_vm._e()])]}},{key:"item.app",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"bordered"},[(item.app)?_c('span',[_vm._v(" "+_vm._s(item.app.name))]):_vm._e()])]}},{key:"item.epc",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"bordered"},[_vm._v(" "+_vm._s(item.epc)+" ")])]}},{key:"item.return",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"bordered"},[_vm._v(" "+_vm._s(item.return)+" ")])]}},{key:"item.conversion_decline",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"bordered"},[_vm._v(" "+_vm._s(item.conversion_decline)+" ")])]}},{key:"item.finance_accept",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"bordered"},[_vm._v(" "+_vm._s(item.finance_accept)+" ")])]}},{key:"body.append",fn:function(){return [(_vm.getStatsAffiliateData.detail.length)?_c('tr',{staticClass:"font-weight-bold bg-ground"},[_c('td',{staticClass:"bordered"},[_vm._v(" "+_vm._s(_vm.$t('pageAffiliateStats.tableHeader.totalHeader'))+" ")]),_vm._l((_vm.totalHeaders),function(header){return _c('td',{key:'sum' + header.value,class:{ bordered: header.isBordered }},[_vm._v(" "+_vm._s(_vm.getStatsAffiliateData.total[header.value] ? _vm.getStatsAffiliateData.total[header.value] : 0)+" ")])})],2):_vm._e()]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
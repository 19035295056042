




















import { Component, Vue } from 'vue-property-decorator'
import AnalyticsHeader from '@/views/business/analytics/components/AnalyticsHeader.vue'
import { VuexAction, VuexGetter, VuexMutation } from '@/store/decorators'
import ReportsTable from './components/ReportsTable.vue'
import ReportsChart from './components/ReportsChart.vue'

@Component({
  components: { AnalyticsHeader, ReportsChart, ReportsTable },
})
export default class Reports extends Vue {
  isPreloaderShow = false

  @VuexGetter('nApp') getCurrentApp!: any

  @VuexGetter('nBusinessAnalytics') getDateRange!: any

  // Vuex Actions
  @VuexAction('nBusinessAnalytics') getAnalyticsDataForChart!: () => Promise<boolean>

  @VuexMutation('nBusinessAnalytics') chartParamsUpdate!: (...args) => Promise<boolean>

  @VuexAction('nBusinessAnalytics') getAnalyticsDataForTable!: () => Promise<boolean>

  @VuexMutation('nBusinessAnalytics') tableParamsUpdate!: (...args) => Promise<boolean>

  async getAnalyticsForChart(filterData): Promise<void> {
    this.chartParamsUpdate({
      app_id: this.getCurrentApp.id,
      ...filterData,
    })
    await this.getAnalyticsDataForChart()
  }

  async getAnalyticsForTable(filterData): Promise<void> {
    this.tableParamsUpdate({
      app_id: this.getCurrentApp.id,
      ...filterData,
    })
    await this.getAnalyticsDataForTable()
  }
}

import { Module, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Article } from '@/store/types'
import { getInstanceWithToken } from '@/utils/axios'

interface PostsFetchRequestPayload {
  page: number
  app?: number
  itemsPerPage?: number
}

@Module({ namespaced: true, name: 'posts' })
export default class Posts extends VuexModule {
  posts = []

  pages = 1

  get getPosts() {
    return this.posts
  }

  get getPages(): number {
    return this.pages
  }

  @MutationAction({ mutate: ['posts', 'pages'] })
  async fetchPosts({ page = 1, app, itemsPerPage = 10 }: PostsFetchRequestPayload) {
    const response = await getInstanceWithToken().get(
      `/api/posts?app=${app}&page=${page}&itemsPerPage=${itemsPerPage}`
    )
    return { posts: response.data, pages: parseInt(response.headers['x-pagination-pages']) }
  }
}

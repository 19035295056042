



















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class FaqAccordionPanel extends Vue {
  // Props

  @Prop(Boolean) readonly secondary!: boolean

  @Prop(String) readonly title!: string
}























import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'BusinessContent',
})
export default class BusinessContent extends Vue {}

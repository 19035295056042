















































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Status5 extends Vue {
  @Prop(Object) readonly offerItem!: any
}
